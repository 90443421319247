import Step from 'Shared/components/Builder/Step'
import useAct from 'SurveyBuilder/hooks/useAct'

export default function SurveyBuilderStep ({
  children,
  onDiscardChanges,
  editor,
  save,
  ...rest
}) {

  const act = useAct()
  const surveyId = useSel(s => s.survey.id)
  const { currentEditor } = useSel(s => s.builder)

  const open = () => {
    act.builder.openEditor(editor)
  }

  const close = () => {
    if (onDiscardChanges) {
      onDiscardChanges()
    } else {
      act.survey.load(surveyId)
    }
    act.builder.closeEditor()
  }

  return (
    <Step
      {...rest}
      open={open}
      save={save}
      close={close}
      editor={editor}
      currentEditor={currentEditor}
      >
      {children}
    </Step>
  )
}
