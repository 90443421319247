import * as React from 'react'

function ActivityLogSms(props) {
  return (
    <svg width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>

      <path
        transform="scale(1.1) translate(2.5, 2.5)"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 6L19 6C 20.10457 6 21 6.8954306 21 8L21 8L21 17C 21 18.10457 20.10457 19 19 19L19 19L5 19C 3.8954306 19 3 18.10457 3 17L3 17L3 8C 3 6.8954306 3.8954306 6 5 6zM18.144424 7.839647L12 11.148183L5.855575 7.839647C 5.490872 7.643268 5.036025 7.779722 4.839647 8.144425C 4.643268 8.509129 4.779722 8.963975 5.144425 9.160354L5.144425 9.160354L11.644425 12.660354C 11.866407 12.779882 12.133593 12.779882 12.355575 12.660354L12.355575 12.660354L18.855576 9.160354C 19.220278 8.963975 19.356731 8.509129 19.160353 8.144425C 18.963974 7.779722 18.509129 7.643268 18.144424 7.839647z"
        fill="#191936"
      />
    </svg>
  )
}

export default ActivityLogSms
