import store from 'ManageBilling/store'
import { Provider } from 'react-redux'
import { HTML5Backend } from 'react-dnd-html5-backend'
import Billing from './Billing'

export default function SurveyBuilder(params) {
  return (
    <Provider store={store}>
      <ErrorBoundary>
        <Billing {...params} />
      </ErrorBoundary>
    </Provider>
  )
}

