import BackdropModal from 'Shared/components/BackdropModal/BackdropModal'

const YesNoModal = ({ show, onAccept, onDecline, title, body }) => {
  return (
    <BackdropModal title={title} open={show} onCancel={onDecline}>
      <div>
        {body && (
          <div>
            <p className="text-center">{body}</p>
          </div>
        )}
        <div>
          <button className="button primary margin-right" onClick={onAccept}>
            { i18n.t('shared.messages.yes') }
          </button>
          <button className="button secondary" onClick={onDecline}>
            { i18n.t('shared.messages.no') }
          </button>
        </div>
      </div>
    </BackdropModal>
  )
}

export default YesNoModal
