import * as React from 'react'

function SvgUsers(props) {
  return (
    <svg width="34" height="33" viewBox="0 0 34 33" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M8.21729 9.85614C8.21729 12.7366 10.5523 15.0716 13.4328 15.0716C16.3133 15.0716 18.6483 12.7366 18.6483 9.85614C18.6483 6.9757 16.3133 4.64062 13.4328 4.64062C10.5523 4.64062 8.21729 6.9757 8.21729 9.85614ZM21.2561 15.0716C21.2561 17.232 23.0074 18.9833 25.1677 18.9833C27.328 18.9833 29.0794 17.232 29.0794 15.0716C29.0794 12.9113 27.328 11.16 25.1677 11.16C23.0074 11.16 21.2561 12.9113 21.2561 15.0716Z" fill="#191936" />
      <path fillRule="evenodd" clipRule="evenodd" d="M13.4112 17.6794C7.25495 17.6794 2.20418 20.8433 1.69879 27.0663C1.67126 27.4053 2.31951 28.1104 2.64651 28.1104H24.1855C25.165 28.1104 25.1803 27.3222 25.165 27.0673C24.783 20.6694 19.6539 17.6794 13.4112 17.6794ZM32.2817 28.1104H27.254C27.254 25.1755 26.2843 22.467 24.6479 20.2879C29.0895 20.3366 32.7162 22.5822 32.9891 27.3281C33.0001 27.5193 32.9891 28.1104 32.2817 28.1104Z" fill="#191936" />
    </svg>
  )
}

export default SvgUsers
