import PropTypes from 'prop-types'
import './LabeledCard.scoped.scss'

// Card/box with a label in left column and content in right column
// Used for DesktopCaller questions for example
const LabeledCard = ({ label, children, className, ...rest }) => {
  const classNames = ['labeled-card', 'box']
  if (className) classNames.push(className)

  return (
    <div className={classNames.join(' ')} {...rest}>
      <div className="left">
        <div className="label">
          {label}
        </div>
      </div>
      <div className="content">
        {children}
      </div>
    </div>
  )
}

LabeledCard.propTypes = {
  label: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
}

export default LabeledCard
