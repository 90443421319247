import store from 'Shared/store'
import { Provider } from 'react-redux'

import PageContainer from './PageContainer'

export default function Reporting ({ }) {
  return <Provider store={store}>
    <ErrorBoundary>
      <PageContainer />
    </ErrorBoundary>
  </Provider>
}
