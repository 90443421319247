import useAct from 'DesktopCaller/useAct'
import ServerWaitButton from 'Shared/components/ServerWaitButton'

export default function AudioCheck() {
  const act = useAct()
  const [showAdvice, setShowAdvice] = useState(false)

  if (showAdvice) return <AudioAdvice />

  return <div className='box current'>
    <h2 className='no-margin-top no-margin-bottom'>{ i18n.t('user.campaigns.phone.check_sound_levels') }</h2>
    <p>{ i18n.t('user.campaigns.phone.pre_recorded_message') }</p>
    <div className='buttons'>
      <ServerWaitButton
        className='button primary small margin-right'
        onClick={() => act.desktopCaller.confirmAudio()}
      >{ i18n.t('user.campaigns.phone.yes_lets_go') }</ServerWaitButton>
      <button className='button red small' onClick={() => setShowAdvice(true)}>{ i18n.t('shared.messages.no') }</button>
    </div>
  </div>
}

function AudioAdvice() {
  const act = useAct()

  return <div className='box current'>
    <h2 className='no-margin-top no-margin-bottom'>{ i18n.t('user.campaigns.phone.check_sound_levels') }</h2>
    <p>{ i18n.t('user.campaigns.phone.audio_trouble.main') }</p>
    <ul>
      <li>{ i18n.t('user.campaigns.phone.audio_trouble.point1') }</li>
      <li>{ i18n.t('user.campaigns.phone.audio_trouble.point2') }</li>
      <li>{ i18n.t('user.campaigns.phone.audio_trouble.point3') }</li>
    </ul>
    <div className='buttons'>
      <button className='button red small' onClick={() => act.desktopCaller.disconnect()}>{ i18n.t('user.campaigns.phone.return_to_setup') }</button>
    </div>
  </div>
}
