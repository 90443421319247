import SettingsForm from 'CampaignBuilder/components/Steps/Settings/SettingsForm'
import Step from './Step'
import settingsConfig from 'CampaignBuilder/settingsConfig'
import parse from 'date-fns/parse'

function SettingsShortDescriptor({ mode }) {
  return (
    <div>
      <div>
        { mode === 'default' && <p className='no-margin-bottom'>This campaign is using recommended settings.</p> }
        { mode !== 'default' && <p className='no-margin-bottom'>This campaign is using custom settings.</p> }
      </div>
    </div>
  )
}

function validate(settings, campaignType) {
  const config = settingsConfig[campaignType]
  const validationErrors = []

  const customConfig = _.omitBy(config, (setting, key) => setting.dependsOn && !_.every(setting.dependsOn, (value, key) => _.includes(settings[key], value)))

  _.each(customConfig, (setting, key) => {
    if (setting.required) {
      if (setting.keys) {
        const keys = setting.keys
        keys.forEach((key) => {
          if (!settings[key]) {
            validationErrors.push(`You must set ${setting.label}`)
          }
        })
      } else if (!settings[key]) {
        validationErrors.push(`You must set ${setting.label}`)
      }
    }
  })

  if (settings['sendStartTime'] && settings['sendEndTime']) {
    // using date-fns, check the start time is before end time
    const startTime = parse(settings['sendStartTime'], 'HH:mm', new Date())
    const endTime = parse(settings['sendEndTime'], 'HH:mm', new Date())

    if (startTime > endTime) {
      validationErrors.push('Send start time must be before send end time')
    }
  }

  return validationErrors
}

const Settings = () => {
  const act = useAct()
  const { settings, campaignType } = useSel((s) => s.campaign)
  const settingsMode = settings.settingsMode

  const validationErrors = validate(settings, campaignType)

  return <Step
    name='Settings'
    editor='settings'
    complete={true}
    shortDescriptor={ <SettingsShortDescriptor mode={settingsMode} /> }
    canClickFinish={validationErrors.length === 0}
    validationErrors={validationErrors}
  >
    <SettingsForm />
  </Step>
}

export default Settings
