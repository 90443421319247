import findClearParameters from 'Shared/hooks/findClearParameters'

function getFilterTargets(client, searchId, params, callback) {
  if (searchId !== 'new') {
    return client
      .get(`/api/searches/${searchId}/filter_targets`, { params })
      .then(callback)
  }
}

const dGetFilterTargetIds = _.debounce(getFilterTargets, 1000)

export default (dis, store, client) => {
  function load(id) {
    return client.get(`/api/searches/${id}`)
      .then(({ data: { id, name, rules, status, stats, template, delegated, targetCount, updatedAt, lastRunAt } }) => {
        dis({ type: 'rules/setAll', payload: rules })
        dis({
          type: 'search/set', payload: {
            id,
            rules,
            name,
            stats,
            updatedAt,
            template,
            delegated,
            searchCount: targetCount,
            recalculating: status === 'recalculating',
            lastRunAt
          }
        })
      })
  }

  function startNew({ linkedCampaignId = null, linkedSurveyId = null, defaultRules = null }) {
    const defaultExcludeOperator = window.ENV.SETTINGS?.default_exclude_operator || 'OR'
    const rules = defaultRules || [
      { id: 'root', type: 'group', operator: 'AND', ruleIds: [] },
      { id: 'exclude-root', type: 'group', operator: defaultExcludeOperator, not: true, ruleIds: [] },
    ]
    dis({ type: 'rules/setAll', payload: rules })
    dis({
      type: 'search/set',
      payload: { id: 'new', name: '', linkedCampaignId, linkedSurveyId, status: 'draft' },
    })
  }

  function loadAvailableFilters() {
    return client.get(`/api/searches/available_filters`).then(({ data }) => {
      dis({ type: 'availableFilters/set', payload: data })
    })
  }

  function upsert(payload) {
    return dis({ type: 'search/upsert', payload })
  }

  function upsertRule(id, data) {
    dis({ type: 'rules/upsert', payload: { id, ...data } })
  }

  function addGroup(parentRuleId, data = {}) {
    const id = uuidv4()
    dis({
      type: 'rules/add',
      payload: { id, type: 'group', not: false, operator: 'AND', ruleIds: [], ...data },
    })
    dis({ type: 'rules/addToGroup', payload: { parentRuleId, id } })
  }

  function addFilter(parentRuleId, filter) {
    const id = uuidv4()
    const state = store.getState()
    const { fields } = state.availableFilters[filter]
    const defaultParameters = Object.fromEntries(
      Object.keys(fields).map((parameter) => [parameter, fields[parameter].default || ''])
    )
    dis({
      type: 'rules/add',
      payload: {
        id,
        type: 'filter',
        not: false,
        filter: filter,
        parameters: defaultParameters,
      },
    })
    dis({ type: 'rules/addToGroup', payload: { parentRuleId, id } })
    dis({ type: 'local/upsert', payload: { activeGroupId: null, showFilterList: false } })
  }

  function removeRule(id) {
    dis({ type: 'rules/removeNested', payload: { id } })
    dis({ type: 'rules/removeFromGroups', payload: { id } })
    dis({ type: 'rules/remove', payload: id })
  }

  function upsertFilterParameters(id, baseParams) {
    const state = store.getState()
    const rule = state.rules.entities[id]

    let parameters = baseParams
    if (rule) {
      const { filter } = rule
      const { fields } = state.availableFilters[filter]

      _.keys(parameters).forEach((param) => {
        const clearParams = findClearParameters(fields, param)
        clearParams.forEach((param) => (parameters[param] = null))
      })
    }

    dis({ type: 'rules/upsertFilterParameters', payload: { id, parameters } })
  }

  function removeFilterParameters(id, keys) {
    dis({ type: 'rules/removeFilterParameters', payload: { id, keys } })
  }

  function save(redirect = false, redirectUrl = null) {
    const { search, rules } = store.getState()
    const { id, name, linkedCampaignId, linkedSurveyId, status, template, delegated } = search

    // blank out the search count + description until we reload it
    dis({ type: 'search/upsert', payload: { searchCount: null, recalculating: true } })
    dis({ type: 'searchBuilder/upsert', payload: { description: null } })

    return client
      .put(`/api/searches/${id}`, {
        linkedCampaignId,
        linkedSurveyId,
        search: { name, template, delegated, rules: _.values(rules.entities) },
      })
      .then(
        ({
          data: {
            errors,
            url,
            search: { id },
          },
        }) => {
          if (redirect) {
            if (redirectUrl) {
              window.location = redirectUrl
            } else {
              window.location = url
            }
          } else if (typeof errors !== 'undefined') {
            console.log('errors', errors)
            dis({ type: 'searchBuilder/upsert', payload: { errors: errors } })
          } else {
            dis({ type: 'search/upsert', payload: { id } })
          }
        }
      )
  }

  function loadCount(id) {
    return client.get(`/api/searches/${id}/count`, { id }).then((response) => {
      const {
        data: { count, recalculating, stats, status },
      } = response
      dis({
        type: 'search/upsert',
        payload: { searchCount: count, recalculating, stats, status },
      })
      return response
    })
  }

  function clone(id) {
    return client.post(`/api/searches/${id}/clone`).then(({ data: { redirectUrl } }) => {
      window.location = redirectUrl
    })
  }

  function describe(id) {
    return client.get(`/api/searches/${id}/describe`).then(({ data }) => {
      dis({ type: 'searchBuilder/upsert', payload: { description: data } })
    })
  }

  function getFilterTargetIds(id) {
    const state = store.getState()
    const { filter, parameters } = state.rules.entities[id]
    const searchId = state.search.id

    dGetFilterTargetIds(client, searchId, { filter, parameters }, ({ data }) =>
      upsertRule(id, { targetIds: data })
    )
  }

  function refreshAudience(searchId) {
    return client.post(`/api/searches/${searchId}/refresh_audience`).then(({ data: { status, lastRunAt } }) => {

      dis({
        type: 'search/upsert',
        payload: { recalculating: status === 'recalculating', lastRunAt: lastRunAt },
      })
    })

  }

  return {
    addGroup, addFilter, describe, load, loadCount, loadAvailableFilters, removeRule, save, startNew,
    upsert, upsertFilterParameters, removeFilterParameters, upsertRule,
    clone, refreshAudience
  }
}
