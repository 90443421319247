import _ from "lodash"
import { renderToString } from 'react-dom/server'

const columns = [
  { data: 'external_id', width: '20%'},
  {
    data: 'name',
    width: '45%',
    className: 'bold',
    render: (data, type, row, meta) => renderToString(
      <a href={row['url']}>{_.unescape(data)}</a>
    )
  },
  { data: 'public_id', width: '20%' },
  { data: 'created_at', width: '15%'}
]

const headers = [
  'Membership Number',
  'Name',
  'Secure Submission Code',
  'Submission time'
]

const filters = [
]

const sorters = []

const order = [[ 1, "asc" ]]

export { columns, headers, filters, sorters, order }
