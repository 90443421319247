import * as React from 'react'

function SvgLogout(props) {
  return (
    <svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M9.334 4.667a.667.667 0 101.333 0V4A2.666 2.666 0 008 1.333H4.003A2.666 2.666 0 001.338 4v8a2.666 2.666 0 002.665 2.667h4.003A2.666 2.666 0 0010.67 12v-.667a.666.666 0 10-1.332 0V12c0 .736-.597 1.333-1.333 1.333H4.003A1.333 1.333 0 012.671 12V4c0-.736.596-1.333 1.332-1.333h3.998c.736 0 1.333.597 1.333 1.333v.667z"
        fill="#4C515B"
      />
      <rect
        x={13.333}
        y={7.333}
        width={1.333}
        height={8}
        rx={0.667}
        transform="rotate(90 13.333 7.333)"
        fill="#4C515B"
      />
      <path
        d="M11.529 6.471a.667.667 0 01.942-.942l2 2c.26.26.26.682 0 .942l-2 2a.667.667 0 01-.942-.942L13.057 8 11.53 6.471z"
        fill="#4C515B"
      />
    </svg>
  )
}

export default SvgLogout
