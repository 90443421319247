import { startCase } from 'Shared/functions/util'

export const Structure = ({ data, extra, block, identifier }) => (
  <Grid item container direction="row">
    <Grid item xs={5} className="dark-grey">
      <span className="capitalise">{startCase(identifier)}</span>
    </Grid>
    <Grid item xs={7}>
      {block ? (
        data
      ) : (
        <div>
          { data && <b>{data}</b> }
          { !data && <i>Not available</i> }
          { data && <span>{extra || ''}</span> }
        </div>
      )}
    </Grid>
  </Grid>
)
