import Button from 'Shared/components/Button'
import ServerWaitButton from 'Shared/components/ServerWaitButton'
import CopyToClipboardBig from 'Shared/components/CopyToClipboardBig'

export default function MembershipCardWidget() {
  const act = useAct()
  const membershipCardLink = useSel(s => s.target.membershipCardLink)
  
  function generateCard() {
    return act.target.generateCard()
  }

  return (
    <div className="widget">
      <p className="larger bold capitalise no-margin-top">Membership Card</p>
      { !membershipCardLink && <p>Generate a membership card for this person</p> }
      { membershipCardLink && <p>Copy the link below to share this person's membership card</p> }

      { !membershipCardLink && <div>
        <ServerWaitButton className='button small primary margin-right' onClick={generateCard}>
          Generate Membership Card
        </ServerWaitButton>
      </div> }

      { membershipCardLink && <CopyToClipboardBig text='Copy' url={membershipCardLink} /> }
    </div>
  )
}
