import {
    createEntityAdapter,
    createSlice,
    createReducer,
    configureStore,
    getDefaultMiddleware,
  } from '@reduxjs/toolkit'

const middleware = [...getDefaultMiddleware()]
const adapter = createEntityAdapter()

const builderSliceInitialState = {
  workflowId: null,
  workflow: {},
  loaded: false
}

const builderSlice = createSlice({
  name: 'builder',
  initialState: builderSliceInitialState,
  reducers: {
    set: (s, a) => a.payload,
    upsert: (s, a) => ({ ...s, ...a.payload }),
    reset: (s, a) => ({...builderSliceInitialState, searchId: s.searchId}),
  },
})

const workflowNodesSlice = createSlice({
  name: 'workflowNodes',
  initialState: adapter.getInitialState(),
  reducers: {
    upsert: adapter.upsertOne,
    upsertMany: adapter.upsertMany,
    add: adapter.addOne,
    setAll: adapter.setAll,
    remove: adapter.removeOne
  },
})

const rulesSlice = createSlice({
  name: 'rules',
  initialState: adapter.getInitialState(),
  reducers: {
    upsert: adapter.upsertOne,
    upsertMany: adapter.upsertMany,
    add: adapter.addOne,
    setAll: adapter.setAll,
    remove: adapter.removeOne,
    addToGroup(s, { payload: { parentRuleId, id } }) {
      s.entities[parentRuleId].ruleIds.push(id)
    },
    removeFromGroups(s, { payload: { parentRuleId, id } }) {
      s.entities = _.mapValues(s.entities, (rule) => {
        if (rule.ruleIds) {
          rule.ruleIds = rule.ruleIds.filter((rId) => rId !== id)
        }
        return rule
      })
    },
    upsertFilterParameters(s, { payload: { id, parameters } }) {
      s.entities[id].parameters = { ...s.entities[id].parameters, ...parameters }
    },
  },
})


const localSlice = createSlice({
  name: 'local',
  initialState: {
    workflowId: null,
    modal: {
      kind: null
    },
    sidebar: {
      kind: 'workflow'
    },
    nodeSizes: {},
    entryCount: null
  },
  reducers: {
    upsert: (s, a) => ({ ...s, ...a.payload }),
    setNodeSize: (s, a) => {
      s.nodeSizes[a.payload.id] = a.payload.size
    }
  },
})

const searchBuilderSlice = createSlice({
  name: 'searchBuilder',
  initialState: {
    errors: []
  },
  reducers: {
    upsert: (s, a) => ({ ...s, ...a.payload })
  },
})

const availableFiltersSlice = createSlice({
  name: 'availableFilters',
  initialState: {},
  reducers: {
    set: (s, a) => a.payload,
  },
})


const store = configureStore({
  middleware,
  reducer: {
    local: localSlice.reducer,
    builder: builderSlice.reducer,
    availableFilters: availableFiltersSlice.reducer,
    searchBuilder: searchBuilderSlice.reducer,
    workflowNodes: workflowNodesSlice.reducer,
    rules: rulesSlice.reducer
  },
})

export default store
