import Select from 'react-select'

const tagOptions = [
  { value: 'h1', label: 'Heading 1'},
  { value: 'h2', label: 'Heading 2'},
  { value: 'h3', label: 'Heading 3'},
  { value: 'h4', label: 'Heading 4'},
]

const HeadingBlockProperties = ({ block }) => {
  const act = useAct()
  const tagValue = tagOptions.find((o) => o.value === block.parameters.tag)

  const handleTagChange = (option) => {
    act.email.blocks.updateParameters(block.id, {tag: option.value})
  }

  return (
    <form>
      <h3 className='no-margin-top'>Edit heading</h3>
      <div className="field">
        <label>Type</label>
        <Select options={tagOptions} value={tagValue} onChange={handleTagChange} classNamePrefix="tag-select" />
      </div>
    </form>
  )
}
  
 export default HeadingBlockProperties
