import store from 'Shared/store'
import { Provider } from 'react-redux'
import './Analytics.scoped.scss'
import Filters from './shared/Filters'
import Tabs from 'Shared/components/Tabs/Tabs'
import ReportScreen from './ReportScreen'
import DateRangeSelect from './shared/DateRangeSelect'

const Analytics = ({ screen }) => {
  const tabs = [
    // { text: 'Overview', path: '/admin/analytics', current: screen === 'overview' },
    { text: 'Calling', path: '/admin/analytics/calling', current: screen === 'calling' },
    // { text: 'Texting', path: '/admin/analytics/texting', current: screen === 'texting' },
    // { text: 'Users', path: '/admin/analytics/users', current: screen === 'users' },
  ]

  return (
    <Provider store={store}>
      <div className="sectionn tabs-and-filters">
        <Tabs tabs={tabs} />
        <div>
          <DateRangeSelect />
        </div>
      </div>
      <Filters />

      <ReportScreen screen={screen} />
    </Provider>
  )
}

export default Analytics
