import './GoalBar.scoped.scss'

export default function GoalBar({ goalName, goalCompletionsCount }) {
  return <div className="box gbar">
    <p>Goal</p>
    <div>
      <h2>{goalName}</h2>
      <h2>{(goalCompletionsCount || 0).toLocaleString('en-US')} completed</h2>
    </div>
  </div>
}

