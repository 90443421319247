import { I18n } from "i18n-js"
import translations from "./translations.json"

const i18n = new I18n(translations)
i18n.locale = window.ENV.LOCALE

if (window.ENV.RAILS_ENV === 'development' || window.ENV.RAILS_ENV === 'test') {
  i18n.missingBehavior = "error"
} else {
  i18n.enableFallback = true
}

export default i18n
