import Chip from '@material-ui/core/Chip'
import classNames from 'classnames'
import { DEFAULTS } from './constants'
import Button from '../Button'
import RrIcon from '../RrIcon'
import { nextStep, prevStep } from './helpers'
import { startCase } from 'Shared/functions/util'

function filterColumns(uploadType, matchMode, columns) {
  // only use membership number for lists
  if (uploadType === 'upload-list') {
    return columns.filter((column) => column.required)
  } else {
    // for members/supporters, if the mode is set to uploading new people, pick fields
    // which are required for creation. Otherwise just pick the membership number
    if (matchMode === 'new') {
      return columns.filter((column) => column.requiredForCreation || column.name === 'Membership Number')
    } else {
      return columns.filter((column) => column.name === 'Membership Number')
    }
  }
}

const ColumnCheck = ({
  show,
  type,
  step,
  paragraph,
  columns,
  onComplete,
  confirmation = true,
  labels,
  confirmedColumns,
  setConfirmedColumns,
}) => {
  const [confirmed, setConfirmed] = useState(!confirmation)
  const optionalColumns = _.sortBy(columns.filter((column) => !column.required), ['name'])
  const [uploadType, setUploadType] = useState('')
  const shouldShowOptionalColumns = type !== 'upload-list' && uploadType !== ''
  const filteredColumns = filterColumns(type, uploadType, columns)
  const showColumnCheck = type === 'upload-list' || uploadType !== ''

  const isConfirmed = (newConfirmed) =>
    filteredColumns
      .filter((column) => !!column.required)
      .every((column) => newConfirmed.includes(column.name))

  const onClickColumn = (column) => {
    if (type === 'upload-list') {
      setConfirmedColumns([column])
      setConfirmed(true)
    } else {
      const newConfirmed = confirmedColumns.concat(column)
      setConfirmedColumns(newConfirmed)
      setConfirmed(isConfirmed(newConfirmed))
    }
  }

  if (!show) {
    return null
  }

  return (
    <div>
      <p>{paragraph || DEFAULTS.paragraphs.check}</p>
      {confirmation && <p>{DEFAULTS.paragraphs.checkWithConfirm}</p>}

      {type !== 'upload-list' && <div>
        <h3 className='margin-bottom'>Are you uploading new people, or just updating existing ones?</h3>
        <label className='margin-bottom'><input type='radio' checked={uploadType === 'new'} onClick={() => setUploadType('new')} />Uploading new people, and updating existing people</label>
        <label><input type='radio' checked={uploadType === 'update'} onClick={() => setUploadType('update')} />Just updating existing people</label>
      </div>}

      {showColumnCheck && <div className='cc-chiplist margin-top double'>
        <h3>{labels.requiredColumns}</h3>

        {type === 'upload-list' &&
          labels.requiredColumnsDescriptionLists.map((paragraph, i) => (
            <p key={i} dangerouslySetInnerHTML={{ __html: paragraph }} />
          ))}
        {type !== 'upload-list' &&
          labels.requiredColumnsDescription.map((paragraph, i) => (
            <p key={i} dangerouslySetInnerHTML={{ __html: paragraph }} />
          ))}

        <div className="chips">
          {filteredColumns.map((column) => {
            const clicked = confirmedColumns.includes(column.name)
            return (
              <Chip
                className={classNames('chip', 'required', { confirmed: clicked })}
                clickable={!clicked}
                key={column.name}
                label={startCase(column.name)}
                size="small"
                deleteIcon={
                  clicked ? (
                    <RrIcon iconName="done" classes="chip-icon --checked" />
                  ) : (
                    <RrIcon iconName="done" classes="chip-icon --unchecked" />
                  )
                }
                variant={!clicked ? 'outlined' : 'default'}
                onDelete={() => onClickColumn(column.name)}
                onClick={() => onClickColumn(column.name)}
              />
            )
          })}
        </div>
      </div>}

      {shouldShowOptionalColumns && (
        <div className="cc-chiplist margin-top double">
          <h3>{labels.optionalColumns}</h3>

          {labels.optionalColumnsDescription.map((paragraph, i) => (
            <p key={i} dangerouslySetInnerHTML={{ __html: paragraph }} />
          ))}

          <div className="chips" style={{ maxHeight: '100px', overflowY: 'scroll' }}>
            {optionalColumns.map((column) => (
              <Chip
                className="chip optional"
                key={column.name}
                label={startCase(column.name)}
                size="small"
              />
            ))}
          </div>
        </div>
      )}

      <Button
        onClick={onComplete}
        disabled={!confirmed}
        extraClass={'no-margin-left margin-top double'}
        primary
      >{nextStep(step)}</Button>
    </div>
  )
}

export default ColumnCheck
