import Button from 'Shared/components/Button'
import { CircularProgress } from '@material-ui/core'
import './EditableCustomField.scoped.scss'
import { CustomFieldInput } from './CustomFields'

const EditableCustomField = ({ field }) => {
  const [editing, setEditing] = useState(false)
  const [saving, setSaving] = useState(false)
  const [value, setValue] = useState(field.value)
  const act = useAct()

  useEffect(() => {
    setValue(field.value)
  }, [JSON.stringify(field)])

  const save = () => {
    setSaving(true)
    act.target
      .updateCustomField(field.slug, value)
      .then(() => {
        setSaving(false)
        setEditing(false)
      })
      .catch((e) => {
        console.error(e)
        setEditing(false)
        setSaving(false)
      })
  }

  const cancel = () => {
    setEditing(false)
    setValue(field.value)
  }

  return (
    <>
      <div item className="dark-grey field-name">
        {field.name}
      </div>
      <div item className="field-value" onDoubleClick={() => setEditing(true)}>
        {editing ? (
          <div className="field">
            <CustomFieldInput
              field={{ type: field.kind }}
              value={value}
              onChange={(e) => setValue(e.target.value)}
            />
            <div className="buttons">
              <Button small primary margin-right disabled={saving} onClick={save}>
                Save
              </Button>
              <Button small secondary onClick={cancel}>
                Cancel
              </Button>
            </div>
            {saving && <CircularProgress />}
          </div>
        ) : (
          <>
            <span className="editable">{field.value}</span>
            <span className="edit-marker" onClick={() => setEditing(true)}><SvgIconsEdit color="black" /></span>
          </>
        )}
      </div>
    </>
  )
}

export default EditableCustomField
