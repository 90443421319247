import * as React from 'react'

function SvgRocket(props) {
  return (
    <svg width={48} height={48} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect width={48} height={48} rx={6} />
      <rect x={4} y={4} width={40} height={40} rx={6} />
      <path
        d="M23.983 30c8.783-5.589 9.635-11.535 9.992-14.075a.81.81 0 00-.917-.917c-2.54.357-8.486 1.21-14.075 9.992"
        stroke="#191936"
        strokeWidth={2}
        strokeMiterlimit={10}
      />
      <path
        d="M27.63 20.647a.5.5 0 11.706.707.5.5 0 01-.707-.707zm-3 3a.5.5 0 11.706.707.5.5 0 01-.707-.707z"
        fill="#2100FF"
        stroke="#191936"
      />
      <path
        d="M23.983 30l-5-5m3.413 4.414c-.78.781-4.21 1.383-4.21 1.383s.602-3.43 1.383-4.21l2.828 2.827z"
        stroke="#191936"
        strokeWidth={2}
        strokeMiterlimit={10}
      />
      <path
        d="M18.27 21.5h2.024l-1.3 4H16.3a.792.792 0 01-.737-.492h0a.793.793 0 01.172-.87h0l.007-.007 2.529-2.63zm5.707 11.92h-.003a.79.79 0 01-.491-.736v-2.889l4-1.134v2.185l-2.656 2.418-.01.009-.008.008a.735.735 0 01-.52.202.808.808 0 01-.313-.062z"
        fill="#2100FF"
        stroke="#191936"
      />
    </svg>
  )
}

export default SvgRocket
