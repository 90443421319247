import { useEffect } from "react"


const runValidators = (validators, string) => {
  const failingValidator = validators.find((v) => !v.fn(string + ''))
  return failingValidator && failingValidator.msg
}
  

const useValidator = ({validators = [], onValidChange, ...props}) => {

  const [errorMessage, setErrorMessage] = useState()

  const handleChange = ({target: {value}}) => {
    if(props.onChange) {
      props.onChange(value)
    }
    const msg = runValidators(validators, value)
    if(onValidChange && !msg) {
      onValidChange(value)
    }
    setErrorMessage(msg)
  }

  const inputProps = {
    ...props,
    onChange: handleChange,
  }

  return [inputProps, errorMessage]
}

export default useValidator
