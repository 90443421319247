import { convertFromHTML } from 'draft-convert'

function extractColorFromStyle (style) {
  const styleRules = style.split(';')
  const colorRule = styleRules.find(rule => rule.trim().startsWith('color'))
  return colorRule ? colorRule.split(':')[1].trim() : ''
}

export default function (html) {
  // pass in a hook which runs on each text block
  // this creates a DraftJS Entity for each merge tag it finds
  return convertFromHTML({
    htmlToEntity: (nodeName, node, createEntity) => {
      if (nodeName === 'a') {
        const style = node.getAttribute('style')
        const color = style ? extractColorFromStyle(style) : ''
        return createEntity(
          'LINK',
          'MUTABLE',
          { url: node.getAttribute('href'), color } // if we call `node.href`, it will remove/sanitize tags from URL
        )
      }
    },
    textToEntity: (text, createEntity) => {
      const result = [];
      const regex = /{{([^}]*)}}|{%([^%]*)%}/g // match {{ }} or {% %}
      let match
      while ((match = regex.exec(text)) !== null) {
        const entityKey = createEntity(
          'PLACEHOLDER',
          'IMMUTABLE',
          { name }
        );
        result.push({
          entity: entityKey,
          offset: match.index,
          length: match[0].length,
          result: match[0]
        });
      };

      return result;
    }
  })(html)
}
