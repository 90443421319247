import store from 'Shared/store'
import { Provider } from 'react-redux'
import UploadAudienceButton from './UploadAudienceButton'

const UploadAudience = (props) => {
  return (
    <Provider store={store}>
      <ErrorBoundary>
        <UploadAudienceButton {...props} />
      </ErrorBoundary>
    </Provider>
  )
}

export default UploadAudience
