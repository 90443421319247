
import './SingleStatBox.scoped.scss'

export default function SingleStatBox({ label, value, change }) {
  return (
    <div className="box single-stat-box">
      <p className="single-stat-box-value">
        <strong>{value.toLocaleString()}</strong><br />
        {label}
      </p>
    </div>
  )
}
