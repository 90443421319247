import './summary-table.scoped.scss'

export default function WhatsappTable({ data }) {
  const campaigns = _.orderBy(
    _.map(_.groupBy(data, 'campaignId'), (rows, id) => {
      return {
        id,
        name: rows[0].name,
        messages: _.sumBy(rows, 'messagesCount'),
        whatsappBiMessages:
          rows.find((r) => r.billingKind === 'whatsapp_bi')?.messagesCount || 0,
        whatsappCiMessages:
          rows.find((r) => r.billingKind === 'whatsapp_ci')?.messagesCount || 0,
        whatsappMessages:
          rows.find((r) => r.billingKind === 'whatsapp')?.messagesCount || 0,
        charge: _.sumBy(rows, 'charge'),
      }
    }),
    'charge',
    'desc'
  )

  if (campaigns.length === 0) {
    return <div className="empty-table"> No whatsapp messages sent. </div>
  }

  return (
    <table>
      <thead>
        <tr>
          <th>Campaign</th>
          <th>Messages</th>
          <th>BI</th>
          <th>CI</th>
          <th>Additional</th>
          <th>Cost</th>
        </tr>
      </thead>
      <tbody>
        {campaigns.map((campaign) => (
          <tr>
            <td>
              <a
                href={`/admin/campaigns/${campaign.id}/overview`}
                target="_blank"
                rel="noreferrer"
              >
                {campaign.name}
              </a>
            </td>
            <td>{campaign.messages}</td>
            <td>{campaign.whatsappBiMessages}</td>
            <td>{campaign.whatsappCiMessages}</td>
            <td>{campaign.whatsappMessages}</td>
            <td>${campaign.charge.toFixed(2)}</td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}
