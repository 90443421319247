import FilterParameter from './FilterParameter'
import './Filter.scoped.scss'

const SubFilter = ({ ruleId, subFilter }) => {
  const act = useAct()
  const { parameters } = useSel((s) => s.rules.entities[ruleId])
  const [expanded, setExpanded] = useState(false)

  // decide whether this sub-filter has been added by checking if its parameters exist
  useEffect(() => {
    const isSubFilterActive = Object.keys(subFilter.fields).some((field) => {
      return parameters[field] !== undefined
    })
    setExpanded(isSubFilterActive)
  }, [JSON.stringify(parameters)])

  // activate this sub-filter by adding its parameters to the rule
  function activate() {
    const defaults = {}
    for (var key in subFilter.fields) {
      defaults[key] = ''
    }
    act.search.upsertFilterParameters(ruleId, defaults)
  }

  // remove this sub-filter by removing its parameters
  function remove() {
    act.search.removeFilterParameters(ruleId, Object.keys(subFilter.fields))
  }

  return expanded ? (
    <div className="sub-filter">
      <div className="left components">
        <p className="label bold">{subFilter.presentation.name}</p>
        {subFilter.presentation.layout.map(({ type, text, slug }) => {
          if (type === 'label') {
            return <p className="label">{text}</p>
          } else if (type === 'parameter') {
            return (
              <FilterParameter
                id={ruleId}
                name={_.camelCase(slug)}
                fields={subFilter.fields}
              />
            )
          }
        })}
      </div>
      <div className="right">
        <a className="link naked blue-link" onClick={remove}>
          Remove
        </a>
      </div>
    </div>
  ) : (
    <a className="button small secondary margin-right" onClick={activate}>
      {subFilter.presentation.name}
    </a>
  )
}

export default ({ id }) => {
  const { filter: filterName } = useSel((s) => s.rules.entities[id])
  const availableFilters = useSel((s) => s.availableFilters)
  const filter = availableFilters[filterName]
  const hasSubFilters = Object.keys(filter.subFilters).length > 0

  if (!filter) return null

  return (
    <div className="filter">
      <div className="components">
        {filter.presentation.layout.map(({ type, text, slug }) => {
          if (type === 'label') {
            return <p className="label bold">{text}</p>
          } else if (type === 'parameter') {
            return (
              <FilterParameter id={id} name={_.camelCase(slug)} fields={filter.fields} />
            )
          }
        })}
      </div>
      {hasSubFilters && (
        <div className="sub-filters">
          {Object.keys(filter.subFilters).map((subFilterName) => {
            const subFilter = filter.subFilters[subFilterName]
            return <SubFilter ruleId={id} subFilter={subFilter} />
          })}
        </div>
      )}
    </div>
  )
}
