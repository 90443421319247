const Detail = ({label, value}) => (
  <div className="detail">
    <div className="left">
      <p>{label}</p>
    </div>
    <div className="right field">{value}</div>
  </div>
)

export default function UserDetails ({ user, roles, inviteAcceptedAt }) {
  return (
    <div className="details">
      <Detail label="Name" value={`${user.firstName} ${user.lastName}`} />
      <Detail label="Organisation" value={user.organisationName} />
      <Detail label="Status" value={user.status} />
      <Detail label="Email" value={user.email} />
      <Detail label="Phone number" value={user.phoneNumber} />
      <Detail label="Roles" value={user.roles.map((r) => r.name).join(', ')} />
      {inviteAcceptedAt && <Detail label="Accepted invite at" value={inviteAcceptedAt} />}
    </div>
  )
}

