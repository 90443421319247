export default function QuestionIcon() {
  const style = { minWidth: '24px', minHeight: '24px' }

  return (
    <svg style={style} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle opacity="0.3" cx="12" cy="12" r="10" fill="#191936" />
      <path d="M12.1079 6C13.2124 6 14.1372 6.3365 14.8823 7.0095C15.6274 7.6825 16 8.51832 16 9.51696C16 9.9403 15.9472 10.3311 15.8415 10.6893C15.7358 11.0475 15.5957 11.3487 15.4213 11.5929C15.2522 11.8318 15.0646 12.0516 14.8585 12.2524C14.6577 12.4532 14.4516 12.6323 14.2402 12.7897C14.0341 12.9471 13.8439 13.0991 13.6695 13.2456C13.5004 13.3867 13.3604 13.5414 13.2494 13.7096C13.1437 13.8779 13.0882 14.0543 13.0829 14.2388H10.7445C10.7445 13.8318 10.7947 13.4681 10.8951 13.1479C11.0008 12.8223 11.1356 12.5563 11.2994 12.3501C11.4632 12.1438 11.6429 11.9566 11.8384 11.7883C12.0392 11.6201 12.2374 11.4681 12.4329 11.3324C12.6337 11.1913 12.8161 11.0529 12.9799 10.9172C13.1437 10.7761 13.2758 10.6106 13.3762 10.4206C13.4819 10.2307 13.5348 10.019 13.5348 9.78562C13.5348 9.346 13.3974 9.00678 13.1226 8.76798C12.853 8.52374 12.491 8.40163 12.0366 8.40163C11.524 8.40163 11.1223 8.57531 10.8317 8.92266C10.5463 9.27001 10.4089 9.71235 10.4195 10.2497H8.00181C7.98067 9.46269 8.1445 8.74355 8.49328 8.09227C8.84734 7.44098 9.34145 6.9308 9.9756 6.56174C10.615 6.18725 11.3258 6 12.1079 6ZM11.9573 18C11.5398 18 11.1884 17.8562 10.903 17.5685C10.623 17.2754 10.4829 16.9199 10.4829 16.502C10.4829 16.0841 10.623 15.7341 10.903 15.4518C11.1884 15.1642 11.5398 15.0204 11.9573 15.0204C12.3695 15.0204 12.7183 15.1642 13.0037 15.4518C13.2943 15.7395 13.4396 16.0896 13.4396 16.502C13.4396 16.9199 13.2943 17.2754 13.0037 17.5685C12.7183 17.8562 12.3695 18 11.9573 18Z" fill="#191936" />
    </svg>
  )
}
