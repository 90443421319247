import useAct from 'DesktopCaller/useAct'
import ServerWaitButton from 'Shared/components/ServerWaitButton'

export default function StartCalling () {
  const act = useAct()

  return <>
    <div className='begin box'>
      <h2>{ i18n.t('user.campaigns.phone.ready_to_call') }</h2>
      <p>{ i18n.t('user.campaigns.phone.get_ready') }</p>
      <ServerWaitButton className='button primary' onClick={act.desktopCaller.setupTwilio}>{ i18n.t('user.campaigns.phone.start_calling') }</ServerWaitButton>
    </div>
  </>
}
