import { useStore } from 'react-redux'
import createActions from './createActions'
import local from './local'
import user from 'Shared/hooks/user'
import restClient from 'Shared/hooks/restClient'

import Context from './Context';

const ActProvider = ({ children }) => {
  const store = useStore();

  const value = useMemo(() => ({
    ...createActions(store.dispatch, store, restClient),
    local: local(store.dispatch, store, restClient),
    user: user(store.dispatch, store, restClient),
  }), []);

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

export default ActProvider;
