import IconBox from 'Shared/components/IconBox'
import './SummaryItem.scoped.scss'

function SummaryItem({ icon, label, value, large = false, link = '' }) {
  const path = `/admin/searches/${link}`
  return (
    <div className={`summary-item ${large ? 'large' : 'small'}`}>
      <IconBox size={large ? 132 : 56}>{icon}</IconBox>
      <div>
        {!link && <h3 className={`${large ? 'large' : ''}`}>{value}</h3>}
        {link && (
          <a className={`${large ? 'large' : ''}`} href={path}>
            {value}
          </a>
        )}
        <p>{label}</p>
      </div>
    </div>
  )
}

export default SummaryItem
