import {
    createEntityAdapter,
    createSlice,
    createReducer,
    configureStore,
    getDefaultMiddleware,
  } from '@reduxjs/toolkit'

const middleware = [...getDefaultMiddleware()]
const adapter = createEntityAdapter()

const effortsSlice = createSlice({
  name: 'efforts',
  initialState: [],
  reducers: {
    set: (s, a) => a.payload,
    setCompletersCount: (s, a) => {
      const { effortId, count } = a.payload;
      s.forEach(effort => {
        if (effort.id === effortId) {
          return _.set({...effort}, 'primaryGoal.completersCount', count);
        }
      });
    }
  },
});

const store = configureStore({
  middleware,
  reducer: {
    efforts: effortsSlice.reducer,
  },
})

export default store
