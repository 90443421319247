import './Sidebar.scoped.scss'

import ParagraphBlockProperties from './blocks/paragraph/ParagraphBlockProperties'
import HeadingBlockProperties from './blocks/heading/HeadingBlockProperties'
import ImageBlockProperties from './blocks/image/ImageBlockProperties'
import HtmlBlockProperties from './blocks/html/HtmlBlockProperties'
import ButtonBlockProperties from './blocks/button/ButtonBlockProperties'
import OrderedListBlockProperties from './blocks/ordered-list/OrderedListBlockProperties'
import UnorderedListBlockProperties from './blocks/unordered-list/UnorderedListBlockProperties'
import QuoteBlockProperties from './blocks/quote/QuoteBlockProperties'
import TableBlockProperties from './blocks/table/TableBlockProperties'

const templateMap = {
  'paragraph': ParagraphBlockProperties,
  'heading': HeadingBlockProperties,
  'image': ImageBlockProperties,
  'button': ButtonBlockProperties,
  'ordered_list': OrderedListBlockProperties,
  'unordered_list': UnorderedListBlockProperties,
  'quote': QuoteBlockProperties,
  'html': HtmlBlockProperties,
  'table': TableBlockProperties
}

const SideBar = ({ setHidden }) => {
  const activeBlock = useSel((s) => s.blocks.present.entities[s.emailBuilder.activeBlockId])
  const template = useSel((s) => activeBlock && s.emailBuilder.blockTemplates.find((t) => t.id === activeBlock.blockTemplateId))
  const EditorComponent = activeBlock && templateMap[template.name]
  const className = activeBlock && EditorComponent ? '' : 'hidden'
  setHidden(!activeBlock || !EditorComponent)

  return (
    <div id="context-menu" className={className}>
      {activeBlock &&  EditorComponent && <EditorComponent block={activeBlock} />}
    </div>
  )
}

export default SideBar
