const CURRENCY_SYMBOLS = {
  USD: "$",
  EUR: "€",
  GBP: "£",
}

export default function formatCurrency(x, currency = false) {
  if (typeof x !== "number") return x
  const symbol = currency ? CURRENCY_SYMBOLS[currency] : ''
  return symbol + x.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
}

