import StartCalling from 'DesktopCaller/components/StartCalling'
import Redialling from 'DesktopCaller/components/Redialling'
import PhoneNumber from 'DesktopCaller/components/PhoneNumber'
import HangUpReminder from 'DesktopCaller/components/HangUpReminder'
import TargetInfo from "Shared/components/TargetInfoNew"
import AudioCheck from 'DesktopCaller/components/Script/AudioCheck'
import Dialling from 'DesktopCaller/components/Script/Dialling'
import Outcome from 'DesktopCaller/components/Script/Outcome'
import InboundCall from 'DesktopCaller/components/Script/InboundCall'
import Questions from 'DesktopCaller/components/Script/Questions'
import Terminate from 'DesktopCaller/components/Script/Terminate'
import Rating from 'DesktopCaller/components/Script/Rating'
import Redial from 'DesktopCaller/components/Script/Redial'
import ScheduleCallback from 'DesktopCaller/components/Script/ScheduleCallback'
import Completed from 'DesktopCaller/components/Script/Completed'
import NoMoreCalls from 'DesktopCaller/components/Script/NoMoreCalls'
import LinesClosed from 'DesktopCaller/components/Script/LinesClosed'
import { Scrollbars } from 'react-custom-scrollbars'
import useData from 'DesktopCaller/useData'
import useAct from 'DesktopCaller/useAct'
import './TwilioCaller.scoped.scss'
import { useWindowSize } from 'usehooks-ts'
import { MOBILE_SCREEN_WIDTH } from 'Shared/constants'

export default function TwilioCaller({ clientCallOutcomes, condensedCaller, tabIndex }) {
  const size = useWindowSize()
  const act = useAct()
  const { callStage, inputStage, settings } = useData((rs) => {
    const { callStage, inputStage } = rs.callSession
    const settings = rs.campaign?.settings
    return { callStage, inputStage, settings }
  })
  const [showHangUpReminder, setShowHangUpReminder] = useState(false)
  const [hangUpTimeout, setHangUpTimeout] = useState(null)

  const desktopSize = size.width >= MOBILE_SCREEN_WIDTH
  const showTargetInfo = desktopSize || tabIndex === 0
  const showScript = desktopSize || tabIndex === 1
  const showActions = desktopSize || tabIndex === 2

  function cleanupTimeout() {
    if (hangUpTimeout) {
      clearTimeout(hangUpTimeout)
      setHangUpTimeout(null)
    }
  }

  useEffect(() => {
    if (callStage === 'connected_to_target') {
      setHangUpTimeout(
        setTimeout(() => {
          setShowHangUpReminder(true)
        }, (settings.maximumCallTime || 1800) * 1000)
      )
    } else {
      cleanupTimeout()
      setShowHangUpReminder(false)
    }
    return () => {
      cleanupTimeout()
    }
  }, [callStage])

  const { currentQuestionId, outcome, inbound } = useData((rs) => rs.call)
  const scriptTargetId = useSel((s) => s.local.currentScript?.targetId)

  const callingMode = useSel((s) => s.local.callingMode)
  const pusherSubscribed = useSel((s) => s.local.pusherSubscribed)

  const targetId = useData((rs) => rs.target.id)
  const campaignId = useData((rs) => rs.campaign.id)

  const scrollRef = useRef()

  function scroll() {
    if (scrollRef.current) {
      setTimeout(() => {
        if (!scrollRef.current) return
        scrollRef.current.scrollTo({ top: scrollRef.current.scrollHeight, left: 0, behavior: 'smooth' })
      }, 250)
    }
  }
  useEffect(scroll, [currentQuestionId, inputStage, callStage, tabIndex])

  useEffect(() => {
    if (targetId && campaignId) {
      act.desktopCaller.fetchScript(campaignId, targetId)
    }
  }, [targetId, campaignId])

  const isCallAnswered = _.includes(['connected_to_target', 'finished_call'], callStage)
  const showTarget =
    scriptTargetId &&
    Number(targetId) === scriptTargetId &&
    ((_.includes(['ringing_target', 'connected_to_target'], callStage) &&
      inputStage !== 'failed') ||
      (callStage === 'finished_call' &&
        inputStage !== 'redial' &&
        inputStage !== 'failed'))

  const showQuestions =
    isCallAnswered &&
    _.includes(['answers', 'rate_call', 'redial'], inputStage) &&
    outcome === 'answer'
  const showScheduleCallback = inputStage === 'schedule_callback'
  const showTerminate =
    _.includes(['rate_call', 'redial'], inputStage) && callStage === 'connected_to_target'
  const showRating =
    inputStage === 'rate_call' && callStage === 'finished_call' && outcome !== 'no_answer'
  const showRedial =
    _.includes(['failed', 'redial', 'finished_call'], inputStage) &&
    callStage !== 'connected_to_target'
  const showCompleted = callStage === 'completed'
  const lastCallFailed = inputStage === 'failed'

  function getPreContent() {
    if (showCompleted) return <Completed />
    if (callStage === 'no_more_calls') return <NoMoreCalls />
    if (callStage === 'lines_closed') return <LinesClosed />
    if (callStage === 'confirm_audio') return <AudioCheck />
    if (showRedial) return <Redial failed={lastCallFailed} />
    if (!showRedial) return <Redialling />
  }

  function blocks() {
    return <div className="blocks">
      <Dialling />
      {!desktopSize && targetId && <div className="margin-bottom double"><TargetInfo targetId={targetId} minimal /></div>}
      {isCallAnswered && !inbound && (
        <Outcome key="outcome" clientCallOutcomes={clientCallOutcomes} />
      )}
      {isCallAnswered && inbound && <InboundCall key="inbound_call" />}
      {showQuestions && <Questions key="questions" />}
      {showScheduleCallback && <ScheduleCallback key="terminate" />}
      {showTerminate && <Terminate key="terminate" />}
      {showRating && <Rating key="rating" />}
    </div>
  }

  return (
    <div className="caller">
      {callStage === 'waiting_for_connection' && (
        <div className="pre-content">
          {pusherSubscribed && (
            <>
              {callingMode === 'desktop_web' && <StartCalling />}
              {callingMode === 'desktop_phone' && <PhoneNumber />}
            </>
          )}
          {!pusherSubscribed && <div>Loading...</div>}
        </div>
      )}

      {callStage !== 'waiting_for_connection' && (
        <div className="pre-content">
          {!showTarget && getPreContent()}
        </div>
      )}


      {!(callStage === 'finished_call' && inputStage === 'redial') && ['finished_call', 'ringing_target', 'connected_to_target'].includes(callStage) && (
        <div className="columns">

          {showTargetInfo && (
            <div className="left-sidebar">
              {targetId && <TargetInfo targetId={targetId} />}
            </div>
          )}

          {showScript && (
            <div ref={scrollRef} className={`caller-main${desktopSize ? ' box' : ''}`}>
              {showTarget && (
                <>
                  {showHangUpReminder && <HangUpReminder />}
                  <div className="script">{blocks()}</div>
                </>
              )}
            </div>
          )}

        </div>
      )}


    </div>
  )
}
