import ChooseCallingMode from 'DesktopCaller/components/ChooseCallingMode/ChooseCallingMode'
import ChooseCallingModeModal from 'DesktopCaller/components/ChooseCallingMode/ChooseCallingModeModal'
import TwilioCaller from 'DesktopCaller/components/TwilioCaller'
import IvrCaller from 'DesktopCaller/components/IvrCaller'
import TwilioCallerTopbar from 'DesktopCaller/components/Toolbar/TwilioCallerTopbar'
import MicSetup from './MicSetup'
import { isMobile } from 'react-device-detect'
import DetectRTC from 'detectrtc'
import useAct from 'DesktopCaller/useAct'
import useData from 'DesktopCaller/useData'
import BackButton from 'Shared/components/BackButton'
import MobileTabs from 'Shared/components/Tabs/MobileTabs'
import Tab from "Shared/components/Tabs/Tab"
import DialpadModal from './Modals/DialpadModal'
import { useStore } from 'react-redux'
import { useEffect } from 'react'
import { useWindowSize } from 'usehooks-ts'
import { MOBILE_SCREEN_WIDTH } from 'Shared/constants'

import './PageContainer.scoped.scss'

export default function PageContainer({
  campaignId,
  userId,
  enabledCallingModes,
  clientCallOutcomes,
  widgets,
}) {
  const size = useWindowSize()
  const act = useAct()
  const dis = useDis()
  const store = useStore()
  const {
    demo: localDemo,
    callSessionId,
    callingMode,
    avDevices,
    inputsConfigured,
    showInputSetup,
    pusherConnected,
    showDialpad,
  } = useSel((s) => s.local)
  const { clientName, firstName, lastName } = useData((rs) => rs.user)
  const { callStage, currentCallId, demo } = useData((rs) => rs.callSession)
  const { id, name, settings } = useData((rs) => rs.campaign)
  const targetName = useData((rs) => rs.target?.firstName && `${rs.target.firstName} ${rs.target.lastName}`)

  const { preferences } = useSel((s) => s.users.entities[userId] || {})
  const isMobileWidth = size.width < 768
  const condensedCaller = isMobile || isMobileWidth || window.innerHeight < 800
  const [tabIndex, setTabIndex] = useState(1)

  const state = useSel((s) => s)

  const showDemoBanner = !callStage ? localDemo : demo // Only use local demo state before Call Session initialised

  const modes = enabledCallingModes.map((mode) => mode.replace('calling_mode_', ''))

  useEffect(act.desktopCaller.startPresence, [])

  useEffect(() => {
    dis({ type: 'local/upsert', payload: { currentUserId: userId } })
    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    })
    if (params.demo) {
      act.local.upsert({ demo: true })
    }
    act.desktopCaller.getCampaign(campaignId)
  }, [])

  useEffect(() => {
    // If only one mode is enabled, use that mode, skipping the modal and disregarding the user's preference
    if (modes.length === 1) {
      act.local.upsert({ callingMode: modes[0] })
      return
    }
  }, [JSON.stringify(preferences), JSON.stringify(settings)])

  useEffect(() => {
    act.local.upsert({ userId, campaignId })
  }, [userId, campaignId])

  function returnScrollState() {
    document.getElementsByTagName('html')[0].style.overflow = 'initial'
  }

  const onInputSetup = (devices) => {
    act.local.upsert({ showInputSetup: false })
    if (!devices && !currentCallSessionId) {
      act.local.upsert({ callingMode: null })
    } else {
      act.desktopCaller.setDevices(devices)
    }
    returnScrollState()
  }

  const onCancelInputSetup = () => {
    act.local.upsert({ callingMode: null })
    act.local.upsert({ showInputSetup: false })
    returnScrollState()
  }

  useEffect(() => {
    if (callingMode) {
      act.desktopCaller.createCallSession(callingMode)
    }
  }, [callingMode])

  useEffect(() => {
    DetectRTC.load(() => {
      const deviceInfo = JSON.parse(JSON.stringify(DetectRTC))
      act.local.upsert({ deviceInfo })
    })
  }, [])

  useEffect(() => {
    if (!window.hj || !userId) return

    window.hj('identify', userId, {
      userId,
      userName: _.compact([firstName, lastName]).join(' '),
      callSessionId,
    })
  }, [userId, firstName, lastName, callSessionId])

  useEffect(() => {
    if (callStage !== 'connected_to_target') {
      act.local.upsert({ showDialpad: false })
    }
  }, [callStage])

  function getMainContent() {
    if (!callingMode) {
      return isMobileWidth ?
        <div className="box grow"><ChooseCallingMode modes={modes} /></div> :
        <ChooseCallingModeModal modes={modes} />
    }
    if (callingMode === 'desktop_web' && inputsConfigured && callSessionId)
      return (
        <TwilioCaller
          clientCallOutcomes={clientCallOutcomes}
          condensedCaller={condensedCaller}
          tabIndex={tabIndex}
        />
      )
    if (callingMode === 'desktop_phone' && callSessionId)
      return (
        <TwilioCaller
          clientCallOutcomes={clientCallOutcomes}
          condensedCaller={condensedCaller}
        />
      )
    if (callingMode === 'ivr') return <IvrCaller campaignId={campaignId} />
  }

  if (!id || !pusherConnected) return i18n.t('shared.messages.loading')

  return (
    <div className={`desktop-caller ${condensedCaller ? 'condensed-caller' : ''}`}>

      {showDemoBanner && (
        <div className="demo-warning show" style={{ zIndex: 2000 }}>
          <p>{i18n.t('user.campaigns.phone.demo_mode')}</p>
        </div>
      )}

      {(!inputsConfigured || showInputSetup) && callingMode === 'desktop_web' && (
        <MicSetup
          {...{
            show: showInputSetup,
            initConfig: avDevices,
            onComplete: onInputSetup,
            onCancel: onCancelInputSetup,
          }}
        />
      )}

      {showDialpad && <DialpadModal />}

      {callingMode && (
        <TwilioCallerTopbar
          condensedCaller={condensedCaller}
          widgets={widgets}
        />
      )}

      {getMainContent()}

      {targetName && size.width < MOBILE_SCREEN_WIDTH &&
        <MobileTabs value={tabIndex} onChange={setTabIndex}>
          <Tab label="Member Info" />
          <Tab label="Your script" />
          {/* <Tab label="Actions" /> */}
        </MobileTabs>
      }
    </div>
  )
}
