import * as React from 'react'

function SvgPersonBlue(props) {
  return (
    <svg width={34} height={34} viewBox={"0 0 34 34"} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.4 6a3 3 0 013-3h19.2a3 3 0 013 3v22a3 3 0 01-3 3H7.4a3 3 0 01-3-3V6zM17 15.6a2.8 2.8 0 110-5.6 2.8 2.8 0 010 5.6zm-.013 1.4c-3.672 0-6.685 2.123-6.986 6.3-.017.226.37.7.565.7h12.848c.585 0 .594-.53.585-.7-.228-4.294-3.288-6.3-7.012-6.3z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgPersonBlue
