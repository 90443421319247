export default function ContentVariant({ id, current }) {
  const act = useAct()
  const contentVariant = useSel(s => s.campaign.emailBlast.contentVariants.find(cv => cv.id === id))
  const subjectLines = useSel(s => s.campaign.emailBlast.subjectLines)
  const mergeTagTests = useSel(s => s.campaign.emailBlast.mergeTagTests)
  if (!contentVariant) return null

  const subjectLine = subjectLines.find(sl => sl.id === contentVariant.content.subjectLineId)?.subjectLine

  function setCurrentVariant() {
    act.local.upsert({ currentContentVariantId: id })
    act.campaignBuilder.renderTemplate()
  }

  const mergeTagContent = mergeTagTests.map(mt => {
    const content = mt.mergeTagTestVersions.find(v => v.id === contentVariant.content.mergeTags[mt.id])?.content || ''
    const trimmedContent = content.length > 30 ? `${content.substring(0, 30)}...` : content
    return { ...mt, trimmedContent }
  })

  return <div className={`content-variant-tab box grey-background half-padding cursor-pointer margin-bottom ${current ? 'dark-border' : ''}`} onClick={setCurrentVariant}>
    { subjectLines.length > 1 && <div><strong>Subject</strong><br />{subjectLine}</div> }
    { mergeTagContent.map((mt, idx) => <div key={mt.id} className={idx !== mergeTagContent.length - 1 ? 'margin-bottom' : '' }>
      <strong>{mt.name}</strong>
      <br />{mt.trimmedContent}
    </div>) }
  </div>
}
