export default function useData(stateFn) {
  // We first digest the state to something more ergonomic, then apply the passed stateFn to extract the relevant values
  return useSel(s => {
    const { userId, campaignId } = s.local
    const user = s.users.entities[userId] || {}
    const campaign = s.campaigns.entities[campaignId] || {}
    const conversation = s.conversations.entities[user.currentConversationId] || {}
    const cannedResponses = Object.values(s.cannedResponses.entities).filter(cr => cr.conversationId == user.currentConversationId)
    const campaignTarget = s.campaignTargets.entities[conversation.campaignTargetId] || {}
    const target = s.targets.entities[campaignTarget.targetId] || {}
    const firstMessage = _.values(s.textMessages.entities).filter(tm => tm.conversationId === conversation.id)[0]
    const whatsapp = firstMessage ? firstMessage.kind === 'whatsapp' : false
    const reducedState = { user, campaign, conversation, target, cannedResponses, whatsapp }

    return stateFn(reducedState)
  })
}
