import useAct from 'DesktopCaller/useAct'
import useData from 'DesktopCaller/useData'

export default function Completed() {
  const act = useAct()
  const callingMode = useSel(s => s.local.callingMode)
  const completedReason = useData(rs => rs.callSession.completedReason)

  return <div className={`box`}>
    <div className='part'>
      <div className='type'>
        { completedReason === 'hang_up' ? <h2 className='no-margin-top'>{ i18n.t('user.campaigns.phone.you_hung_up') }</h2> : <h2 className='no-margin-top'>{ i18n.t('user.campaigns.phone.you_finished_calling') }</h2> }
      </div>
      <div className='content'>
        { completedReason === 'hang_up' ? <p>{ i18n.t('user.campaigns.phone.hang_up_reason') }</p> : <p>{ i18n.t('user.campaigns.phone.thanks_for_calling') }</p> }
        <div className='buttons'>
          <button className='button primary small' onClick={() => act.desktopCaller.createCallSession(callingMode)}>{ i18n.t('user.campaigns.phone.start_calling_again') }</button>
        </div>
      </div>
    </div>
  </div>
}
