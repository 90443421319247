import Text from 'Survey/components/question-types/Text'
import MultipleChoice from 'Survey/components/question-types/MultipleChoice'
import MultipleSelect from 'Survey/components/question-types/MultipleSelect'
import Score from 'Survey/components/question-types/Score'
import SmsQuestion from 'DesktopCaller/components/Script/SmsQuestion'
import TransferQuestion from 'DesktopCaller/components/Script/TransferQuestion'

const types = {
  multiple_choice: {
    component: MultipleChoice,
    canUndo: true,
    answerable: true,
    showNext: true,
  },

  multiple_select: {
    component: MultipleSelect,
    canUndo: true,
    answerable: true,
    showNext: true,
  },

  text: {
    component: Text,
    canUndo: true,
    answerable: true,
    showNext: true,
  },

  score: {
    component: Score,
    canUndo: true,
    answerable: true,
    showNext: true,
  },

  instruction: {
    component: null,
    canUndo: true,
    answerable: false,
    showNext: true,
  },

  send_sms: {
    component: SmsQuestion,
    canUndo: false,
    answerable: false,
    showNext: false,
  },

  transfer: {
    component: TransferQuestion,
    canUndo: false,
    answerable: false,
    showNext: false,
  },
}


export default types;
