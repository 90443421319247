import './ScriptPreviewStep.scoped.scss'
import QuestionPhonePreview from 'Shared/components/QuestionPhonePreview'
import QuestionPreview from './QuestionPreview'
import * as constants from 'Shared/constants'

export default function ScriptPreviewStep({ questionId, index, includeOptOut }) {
  const campaignType = useSel(s => s.campaign.campaignType)

  const { questionType } = useSel(s => s.questions.entities[questionId])
  const label = (constants.QUESTION_LABELS[questionType] || constants.QUESTION_LABELS['default']).replace('{index}', index)

  return <div className='block'>
    <div className='left'>
      <p className={questionType === 'instruction' ? 'light-black' : ''}>{label}</p>
    </div>
    <div className='right'>
      { campaignType === 'sms_survey'
        ? <QuestionPhonePreview questionId={questionId} includeOptOut={includeOptOut} />
        : <QuestionPreview questionId={questionId} />
      }
    </div>
  </div>
}
