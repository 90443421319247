import useAct from 'DesktopCaller/useAct'
import useData from 'DesktopCaller/useData'
import DatetimeSelector from 'Shared/components/DatetimeSelector'
import LabeledCard from 'Shared/components/LabeledCard/LabeledCard'

export default function Redial() {
  const act = useAct()
  const inputStage = useData((rs) => rs.callSession.inputStage)
  const [callbackAt, setCallbackAt] = useState(null)

  return (
    <LabeledCard
      label={
        <>
          <SvgIconsCallerInstruction />
          <p>{i18n.t('user.campaigns.phone.schedule')}</p>
        </>
      }
    >

      <p className="no-margin-top">{i18n.t('user.campaigns.phone.when_receive_callback')}</p>

      <div className="flex">
        <DatetimeSelector value={callbackAt} onChange={setCallbackAt} />
      </div>

      <p><button
        className="button primary inline-block"
        disabled={!callbackAt}
        onClick={() => act.desktopCaller.scheduleCallback(callbackAt)}
      >
        {i18n.t('shared.messages.finish')}
      </button></p>

    </LabeledCard>
  )
}
