import './MobileDesktopToggle.scoped.scss'

export default function MobileDesktopToggle({ value, onChange }) {
  return (
    <ul className={`mobile-desktop-toggle`}>
      <li onClick={() => onChange('desktop')} className={value === 'desktop' ? 'active' : null}>
        <SvgIconsDesktop />
        Desktop
      </li>
      <li onClick={(() => onChange('mobile'))} className={value === 'mobile' ? 'active' : null}>
        <SvgIconsMobile />
        Mobile
      </li>
    </ul>
  )
}
