import { ManageMenuItem } from '..'
import { useCampaignActions, useCampaignMenuProps } from './campaign'
import { useSearchActions, useSearchMenuProps } from './search'
import { useTargetActions, useTargetMenuProps } from './target'
import { useSurveyActions, useSurveyMenuProps } from './survey'
import { ReactNode } from 'react'

export type ManageTargetTypes = 'campaign' | 'search' | 'target' | 'survey'
export type ManageMenuPropsHookResult = [ManageMenuItem[], ReactNode[]]

export const useManageActions = (type: ManageTargetTypes, data) => {
  switch (type) {
    case 'campaign':
      return useCampaignActions(data)
    case 'search':
      return useSearchActions(data)
    case 'target':
      return useTargetActions(data)
    case 'survey':
      return useSurveyActions(data)
  }
  return []
}
export const useManageMenuProps = (
  type: ManageTargetTypes,
  data: any
): ManageMenuPropsHookResult => {
  switch (type) {
    case 'campaign':
      return useCampaignMenuProps(data)
    case 'search':
      return useSearchMenuProps(data)
    case 'target':
      return useTargetMenuProps(data)
    case 'survey':
      return useSurveyMenuProps(data)
  }
  return []
}
