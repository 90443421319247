import axios from 'axios'
import './AjaxNumber.scoped.scss'

export default ({ className, endpoint, placeholderWidth, tail, wrapTag = 'span' }) => {
  const [state, setState] = useState({ endpoint, number: null, placeholderWidth, tail })

  useEffect(() => {
    axios.get(state.endpoint).then(({ data: { count } }) => {
      setState(d => {
        return { ...d, ...{ number: parseFloat(count).toLocaleString("en-US") } }
      })
    })
  }, [])

  if (wrapTag) {
    return (
      <span className={className || ''}>
        {state.number === null && (
          <span className="skeleton-box" style={{ width: state.placeholderWidth }}></span>
        )}
        {state.number !== null && (
          <span>
            {state.number}
            {state.tail}
          </span>
        )}
      </span>
    )
  } else {
    return state.number === null ? '...' : `${state.number}${state.tail ? state.tail : ''}`
  }
}
