import { useEffect } from 'react'
import { clamp } from 'Shared/functions/util'
import './ImageBlock.scoped.scss'
import SingleImage from './SingleImage'

const ImageBlock = ({ block, active }) => {
  const act = useAct()

  const onResize = (index, width) => {
    const images = [...block.parameters.images]
    images[index] = {...images[index], width}
    act.email.blocks.updateParameters(block.id, { images })
  }

  return (
    <div className={block.parameters.images?.length > 1 ? 'images' : 'image'}>
      {block.parameters.images.map((image, index) => (
        <SingleImage key={index} image={image} allowResizing={block.parameters.images?.length === 1} onResize={(newWidth) => onResize(index, newWidth)} active={active} />
      ))}
    </div>
  )
}

export default ImageBlock
