import PillEditor from 'Shared/components/PillEditor/PillEditor'
import { SMS_OPT_OUT_MESSAGE } from '../../../Shared/constants'

export default function ContentEditor ({ availableTags, header, initialValue, onUpdate }) {
  const { textMessageBlast: { enableOptOut, senderType, kind } } = useSel(s => s.campaign)
  const showOptIn = enableOptOut && senderType !== 'alphanumeric' && kind !== 'whatsapp' // sms blasts sent from a phone number

  return <div className='content-editor'>
    <div className='text-box'>
      { header && <p className='bold no-margin-top'>{header}</p> }
      <PillEditor initialValue={initialValue} onChange={body => onUpdate(body)} availableTags={availableTags} condensed />
      { showOptIn && <div className='footer'>
        <p className='legal-message'>{SMS_OPT_OUT_MESSAGE}</p>
      </div> }
    </div>
  </div>
}
