import Collapse from '@material-ui/core/Collapse'
import Grid from '@material-ui/core/Grid'
import LinearProgress from '@material-ui/core/LinearProgress'
import {
  FormGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  InputLabel,
  Radio,
  RadioGroup,
  OutlinedInput,
} from '@material-ui/core'
import MuiAlert from '@mui/material/Alert'
import { DEFAULTS } from './constants'
import RrButton from '../RrButton'
import CsvPreview from './CsvPreview'
import { getCsvSample, validateCsv, nextStep, prevStep } from './helpers'
import { ExportToCsv } from 'export-to-csv'

const Review = ({
  show,
  type,
  step,
  paragraph,
  csv,
  schema,
  onCancel,
  onValidated,
  listName,
  setListName,
  uploadSupporters,
  setUploadSupporters,
  needsList = false,
  confirmedColumns,
}) => {
  const [header, setHeader] = useState()
  const [preview, setPreview] = useState()
  const [processing, setProcessing] = useState(false)
  const [progress, setProgress] = useState(0)
  const [valid, setValid] = useState()
  const [showingErrors, setShowingErrors] = useState(false)
  const [errorRows, setErrorRows] = useState([])
  const [failed, setFailed] = useState(false)
  const [failedReason, setFailedReason] = useState(false)
  const [makeList, setMakeList] = useState(needsList)
  const actions = useAct()

  useEffect(() => {
    if (csv)
      getCsvSample(csv, 5).then((sample) => {
        setHeader(sample.data.slice(0, 1)[0])
        setPreview(sample.data.slice(1, 4))
      })
  }, [csv])

  useEffect(() => {
    if (processing && csv) {
      setTimeout(() => {
        // Run validation row by row
        validateCsv({ type, csv, schema, confirmedColumns })
          .then((result) => {
            setValid(!result.errorRows.length)
            setErrorRows(result.errorRows)
            setShowingErrors(result.errorRows.length > 0)
          })
          .catch((err) => {
            setFailed(true)
            setFailedReason(err.reason)
          })
          .finally(() => setTimeout(() => setProcessing(false), 500))
      }, 500)
    }
  }, [processing, csv])

  useEffect(() => {
    if (show) {
      setProcessing(true)
      setFailed()
      setValid()
      setProgress(0)
      setPreview()
      window.addEventListener('csvProcessing', (evt) => {
        setProgress((100 * evt.loaded) / evt.total)
      })
    }
  }, [show])

  function canNext() {
    if (!valid && !failed) return false

    if (needsList) {
      return makeList && listName
    } else {
      if (makeList) {
        return !!listName
      } else {
        return true
      }
    }
  }

  const shouldProceed = () => {
    return canNext() && (!failed || valid)
  }

  function formattedMessage() {
    if (valid) {
      return <p>{paragraph || DEFAULTS.paragraphs.review}</p>
    } else {
      return (
        <MuiAlert
          severity="error"
          variant="outlined"
          sx={{ marginBottom: 2, marginTop: 2 }}
        >
          {failed
            ? failedReason
            : `Something is not right with your data. ${
                errorRows.length
              } rows are invalid. The following ${Math.min(
                errorRows.length,
                10000
              )} rows are invalid, because they are either missing data, or the data is in the incorrect format. Please look closely at the preview and then try again:`}
        </MuiAlert>
      )
    }
  }

  function download() {
    const csvExporter = new ExportToCsv({ filename: 'invalid-rows' })
    csvExporter.generateCsv(errorRows.map((row) => row.data))
  }

  const handleRadioChange = (event) => {
    const { value } = event.target

    if (value === 'members') {
      setUploadSupporters(false)
    } else {
      setUploadSupporters(true)
    }
  }

  return (
    <Collapse in={show} className="cc-content-container">
      <Grid item container direction="column" spacing={2}>
        {!processing && (
          <Grid item>
            {formattedMessage()}
            <div>
              {show && !failed && !valid && (
                <a href="#" onClick={download}>
                  {' '}
                  Download invalid rows
                </a>
              )}
            </div>
          </Grid>
        )}
        {!processing && header && (
          <>
            <Grid item style={{ maxWidth: '100%' }}>
              <CsvPreview
                header={header}
                csv={showingErrors ? errorRows.slice(0, 10000) : preview}
                showingErrors={showingErrors}
              />
            </Grid>
            {valid && (
              <Grid item>
                <div>
                  { type === 'upload-people' && <>
                    <h3>Who's in this upload?</h3>

                    <p>
                      You can upload these people as either members or supporters. Members
                      will be added to your core membership list. Supporters are separated
                      from your membership list, so that you can contact them as part of
                      external campaigns or communications.
                    </p>
                    <RadioGroup
                      aria-labelledby="radio-buttons-group-label"
                      name="radio-buttons-group"
                      onChange={handleRadioChange}
                      value={uploadSupporters ? 'supporters' : 'members'}
                    >
                      <FormControlLabel
                        value="members"
                        control={<Radio />}
                        label="Upload people as members"
                      />
                      <FormControlLabel
                        value="supporters"
                        control={<Radio />}
                        label="Upload people as supporters"
                      />
                    </RadioGroup>
                  </> }

                  { (type === 'upload-people' || type === 'upload-custom') && <div>
                    <h3>Create a list from the people in this upload</h3>
                    <RadioGroup
                      aria-labelledby="radio-buttons-group-label"
                      name="radio-buttons-group"
                      onChange={(e) => setMakeList(e.target.value === 'true')}
                      value={makeList ? 'true' : 'false'}
                    >
                      <FormControlLabel
                        value="true"
                        control={<Radio />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value="false"
                        control={<Radio />}
                        label="No"
                      />
                    </RadioGroup>
                  </div> }

                  { makeList && (
                    <FormControl>
                      <InputLabel
                        htmlFor="list-name-input"
                        classes={{ root: '--custom-outline-label' }}
                      >
                        List name
                      </InputLabel>
                      <OutlinedInput
                        onChange={({ target: { value } }) => setListName(value)}
                        size="small"
                        placeholder="List name"
                        type="mui-input"
                        value={listName || ''}
                        classes={{
                          input: '--custom-outline-input',
                          notchedOutline: '--custom-outline-fieldset',
                        }}
                      />
                    </FormControl>
                  )}
                </div>
              </Grid>
            )}
          </>
        )}
        <Grid item style={{ padding: processing ? 16 : 0 }}>
          <Collapse in={processing}>
            <h4 style={{ marginBottom: 5 }}>Running data validation...</h4>
            <LinearProgress
              variant={progress ? 'determinate' : 'indeterminate'}
              value={progress}
              classes={{ root: 'avds-monitor', bar: 'avds-monitor-bar' }}
            />
          </Collapse>
        </Grid>
        <Grid
          item
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            paddingTop: 25,
          }}
        >
          <RrButton
            title={prevStep(step)}
            onClick={onCancel}
            type="secondary"
            icon="back"
            disabled={processing}
          />
          <RrButton
            title={nextStep(step)}
            onClick={shouldProceed() ? onValidated : () => {}}
            type="primary"
            disabled={!shouldProceed()}
            style={{
              pointerEvents: shouldProceed() ? 'auto' : 'none',
              opacity: shouldProceed() ? 1 : 0.5,
            }}
          />
        </Grid>
      </Grid>
    </Collapse>
  )
}

export default Review
