import PropTypes from 'prop-types'
import './Tab.scoped.scss'

function Tab({ label, desc, value, onClick, selected }) {

  const handleClick = (e) => {
    e.preventDefault()
    onClick(value)
  }

  return (
    <a href="#" className={`filter-tab ${selected ? 'selected' : ''}`} onClick={handleClick}>
      {label}<br /><p className="filter-desc">{desc}</p>
    </a >
  )
}

Tab.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.number,
  onClick: PropTypes.func,
  selected: PropTypes.bool
}

export default Tab

