import * as React from 'react'

function SvgAutomations(props) {
  return (
    <svg width={24} height={24} viewBox={"0 0 24 24"} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fillRule="evenodd" clipRule="evenodd" fill="currentColor">
        <path id="Vector" d="M6.74998 3.75C6.74998 3.55109 6.829 3.36032 6.96965 3.21967C7.1103 3.07902 7.30107 3 7.49998 3H16.5C16.6989 3 16.8897 3.07902 17.0303 3.21967C17.171 3.36032 17.25 3.55109 17.25 3.75C17.25 3.94891 17.171 4.13968 17.0303 4.28033C16.8897 4.42098 16.6989 4.5 16.5 4.5H7.49998C7.30107 4.5 7.1103 4.42098 6.96965 4.28033C6.829 4.13968 6.74998 3.94891 6.74998 3.75ZM21.6928 12.4631C21.6361 12.3261 21.54 12.2089 21.4166 12.1265C21.2933 12.044 21.1483 12 21 12H17.25V9.75C17.25 9.55109 17.171 9.36032 17.0303 9.21967C16.8897 9.07902 16.6989 9 16.5 9H7.49998C7.30107 9 7.1103 9.07902 6.96965 9.21967C6.829 9.36032 6.74998 9.55109 6.74998 9.75V12H2.99998C2.85156 11.9999 2.70644 12.0438 2.58299 12.1262C2.45955 12.2086 2.36333 12.3258 2.30651 12.4629C2.2497 12.6 2.23485 12.7509 2.26383 12.8965C2.29282 13.042 2.36435 13.1757 2.46936 13.2806L11.4694 22.2806C11.539 22.3504 11.6217 22.4057 11.7128 22.4434C11.8038 22.4812 11.9014 22.5006 12 22.5006C12.0985 22.5006 12.1961 22.4812 12.2872 22.4434C12.3782 22.4057 12.461 22.3504 12.5306 22.2806L21.5306 13.2806C21.6355 13.1757 21.7068 13.042 21.7357 12.8965C21.7646 12.751 21.7496 12.6002 21.6928 12.4631ZM7.49998 7.5H16.5C16.6989 7.5 16.8897 7.42098 17.0303 7.28033C17.171 7.13968 17.25 6.94891 17.25 6.75C17.25 6.55109 17.171 6.36032 17.0303 6.21967C16.8897 6.07902 16.6989 6 16.5 6H7.49998C7.30107 6 7.1103 6.07902 6.96965 6.21967C6.829 6.36032 6.74998 6.55109 6.74998 6.75C6.74998 6.94891 6.829 7.13968 6.96965 7.28033C7.1103 7.42098 7.30107 7.5 7.49998 7.5Z" fill="currentColor"/>
      </g>
    </svg>
  )
}

export default SvgAutomations
