import restClient from 'Shared/hooks/restClient'

export default function useActions() {
  const dis = useDis()
  const store = useStore()

  function loadMe() {
    const currentUser = store.getState().currentUser
    if (currentUser.id) return

    restClient.get('/api/users/me')
    .then(({ data }) => {
      dis({ type: 'currentUser/set', payload: data })
    })
  }

  loadMe()
}
