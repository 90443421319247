const user = (dis, store, client) => {
  function loadMe() {
    const currentUser = store.getState().currentUser
    if (currentUser.id) return
    client.get('/api/users/me')
      .then(({ data }) => {
        dis({ type: 'currentUser/set', payload: data })
      })
      .catch((res) => {
        dis({ type: 'currentUser/set', payload: {} })
      })
  }

  function ping() {
    client.post('/api/me/ping')
  }

  return { loadMe, ping }
}

export default user
