import Button from 'Shared/components/Button'
import { CircularProgress } from '@material-ui/core'
import restClient from 'Shared/hooks/restClient'
import Flash from 'Shared/components/Flash/components/Flash'
import { Formik, Field, Form, ErrorMessage } from 'formik';
import FormikField from 'Shared/components/FormikField/FormikField'
import AsyncSelect from 'Shared/components/AsyncSelect'
import Loading from 'Shared/components/Loading'
import { startCase } from 'Shared/functions/util'

const BuildSyncedAudience = ({ id, onReload = () => {} }) => {
  const [flash, setFlash] = useState(null)
  const [loading, setLoading] = useState(id !== 'new')
  const [syncedAudience, setSyncedAudience] = useState({})
  const [persisting, setPersisting] = useState(false)
  const [user, setUser] = useState(null)

  function load() {
    restClient.get(`/api/synced_audiences/${id}`).then((response) => {
      setSyncedAudience(response.data)
      setLoading(false)
    }).catch((e) => {
      setFlash({ message: 'Error loading synced audience', type: 'error' })
    })
  }

  function loadMe() {
    restClient.get(`/api/me`).then((response) => {
      setUser(response.data)
    }).catch((e) => {
      setFlash({ message: 'Error loading user', type: 'error' })
    })
  }

  useEffect(() => {
    loadMe()
    if (id === 'new') return
    load()
  }, [])

  const handleSubmit = (values, formik) => {
    const syncedAudience = values

    function handleFail(e) {
      formik.setSubmitting(false)
      if(e.response.status === 422) {
        const error = e.response.data?.error
        if (error === 'invalid_auth') {
          setFlash({ message: "You're not logged into the platform, or your login has expired. Log in again to continue.", type: 'error' })
        } else if (error === 'no_access') {
          setFlash({ message: "You don't have access to the ad account. You may need to get a user with the correct access to create the synced audience.", type: 'error' })
        } else {
          setFlash({ message: 'Please check the details you entered', type: 'error' })
        }
        formik.setErrors(e.response.data)
      } else {
        setFlash({ message: `Error ${id === 'new' ? 'creating' : 'updating'} audience`, type: 'error' })
        console.error(e)
      }
    }

    if (id === 'new') {
      restClient.post(`/api/synced_audiences`, { syncedAudience })
      .then((response) => {
        window.location = `/admin/synced_audiences/${response.data.id}`
      })
      .catch(handleFail)
    } else {
      restClient.put(`/api/synced_audiences/${id}`, { syncedAudience }).then((response) => {
        setFlash({ message: 'Synced audience updated', type: 'success' })
        formik.setSubmitting(false)
      })
      .catch(handleFail)
    }
  }

  function handleEditAudience() {
    window.location = `/admin/searches/${syncedAudience.searchId}/build`
  }

  function pause() {
    setPersisting(true)
    restClient.post(`/api/synced_audiences/${id}/pause`)
    .then(() => {
      load()
      onReload()
      setPersisting(false)
    })
    .catch((e) => {
      setPersisting(false)
      setFlash({ message: 'Error pausing audience', type: 'error' })
    })
  }

  function resume() {
    setPersisting(true)
    restClient.post(`/api/synced_audiences/${id}/resume`)
    .then(() => {
      load()
      onReload()
      setPersisting(false)
    })
    .catch((e) => {
      setPersisting(false)
      setFlash({ message: 'Error resuming audience', type: 'error' })
    })
  }

  function validate(values) {
    const errors = {};
  
    if (!values.platform) {
      errors.platform = 'You must select a platform';
    }
    if (values.platform === 'facebook' && !values.platformConfig?.adAccountId) {
      errors.platformConfig ||= {}
      errors.platformConfig.adAccountId = "You must select a Facebook Ad Account"
    }
    if (values.platform === 'google' && !values.platformConfig?.adAccountId) {
      errors.platformConfig ||= {}
      errors.platformConfig.adAccountId = "You must select a Google Ad Account"
    }
  
    return errors;
  };

  if (loading || !user) return <Loading text="Loading" />

  const requiresFacebookLogin = !_.includes(user.loggedInPlatforms, 'facebook')
  const requiresGoogleLogin = !_.includes(user.loggedInPlatforms, 'google')

  return (
    <Formik
      initialValues={syncedAudience}
      validate={validate}
      onSubmit={handleSubmit}
    >
      {({ values, isSubmitting, errors, touched, setFieldValue }) => {
        return <Form>
          {flash && <Flash message={flash.message} type={flash.type} onHide={() => setFlash(null)} />}

          <div className="details">
            <div className="detail">
              <div className="left">
                <p>Name</p>
              </div>
              <div className="right field" id="name-field">
                <FormikField name="name" type="text" id="name" />
              </div>
            </div>

            <div className="detail">
              <div className="left">
                <p>Audience</p>
              </div>
              <div className="right field" id="name-field">
                <Field
                  name="searchId"
                  render={({ field, form }) => {
                  const onChange = v => form.setFieldValue(field.name, v);

                  return <AsyncSelect
                    entity='search'
                    value={field.value}
                    onChange={onChange}
                  />
                  }}
                />
                { id !== 'new' && values.searchId === syncedAudience.searchId && <div className='margin-top'><span className="button naked" onClick={handleEditAudience}>Edit Audience</span></div>}
              </div>
            </div>

            <div className="detail">
              <div className="left">
                <p>Ads platform</p>
              </div>
              { id === 'new' && <div className="right field" id="name-field">
                { (requiresGoogleLogin || requiresFacebookLogin) && <div>You must <span className="button naked" onClick={() => window.location = '/admin/profile/edit'}>login</span> to a platform before you can choose it</div> }
                <label>
                  <Field name="platform" type="radio" value="facebook" disabled={requiresFacebookLogin} />
                  Meta (Facebook and Instagram)
                </label>
                <label>
                  <Field name="platform" type="radio" value="google" disabled={requiresGoogleLogin} />
                  Google
                </label>
              </div> }
              { id !== 'new' && <div className="right field" id="name-field">
                { startCase(syncedAudience.platform) }
              </div> }
            </div>

            { values.platform === 'facebook' && <div className="detail">
              <div className="left">
                <p>Facebook Ad Account</p>
              </div>
              <div className="right field" id="name-field">
                { id === 'new' && <Field
                  name="platformConfig.adAccountId"
                  render={({ field, form }) => {
                  const onChange = v => form.setFieldValue(field.name, v);

                  return <AsyncSelect
                    value={field.value}
                    onChange={onChange}
                    baseEndpoint='/api/advertising/facebook/ad_accounts'
                    loadStaticList
                  />
                  }}
                /> }
                { id !== 'new' && <div>{ syncedAudience.syncInfo.adAccountName }</div> }
              </div>
            </div> }

            { values.platform === 'google' && <div className="detail">
              <div className="left">
                <p>Google Ad Account</p>
              </div>
              <div className="right field" id="name-field">
                { id === 'new' && <Field
                  name="platformConfig.adAccountId"
                  render={({ field, form }) => {
                  const onChange = v => form.setFieldValue(field.name, v);

                  return <AsyncSelect
                    value={field.value}
                    onChange={onChange}
                    baseEndpoint='/api/advertising/google/ad_accounts'
                    loadStaticList
                  />
                  }}
                /> }
                { id !== 'new' && <div>{ syncedAudience.syncInfo.adAccountName }</div> }
              </div>
            </div> }
          </div>

          <div>
            { _.map(errors, (error, key) => {
              if ((typeof error) === 'object') {
                return <div>
                  { _.map(error, (error, key) => {
                    return <div key={key} className="error">{error}</div>
                  }) }
                </div>
              } else {
                return <div key={key} className="error">{error}</div>
              }
            })}
          </div>

          <div className="field save-changes margin-top">
            <Button type="submit" primary disabled={isSubmitting}>{ id === 'new' ? 'Create' : 'Save changes' }</Button>
            {isSubmitting && <CircularProgress />}

            { id !== 'new' && <>
              { syncedAudience.status === 'paused' && <button className='button light primary margin-left' onClick={resume} disabled={persisting}>Resume</button> }
              { syncedAudience.status === 'syncing' && <button className='button tertiary margin-left' onClick={pause} disabled={persisting}>Pause</button> }
            </> }
          </div>
        </Form>
      }}
    </Formik>
  )
}

export default BuildSyncedAudience
