import CharacterCounter from "./CharacterCounter"
import SmsPreview from 'CampaignBuilder/components/Steps/SmsPreview'

export default function QuestionPhonePreview({ questionId, showCharacterCount, includeOptOut }) {
  const campaignType = useSel(s => s.campaign.campaignType)
  const blastKind = useSel(s => s.campaign.textMessageBlast?.kind)
  const { question, questionType, digitOrder, settings } = useSel(s => s.questions.entities[questionId])

  const answers = useSel(s => _.orderBy(_.values(s.answers.entities).filter(a => a.questionId === questionId && a.active), 'digitOrder'))

  if (questionType === 'send_sms') {
    return <SmsPhonePreview showCharacterCount={showCharacterCount} includeOptOut={includeOptOut} content={settings.textMessageBody} />
  } else {
    if (blastKind === 'sms') {
      return <SmsPhonePreview showCharacterCount={showCharacterCount} includeOptOut={includeOptOut} content={buildQuestionString({ questionType, question, answers })} />
    } else if (blastKind === 'whatsapp') {
      return <WhatsappPhonePreview digitOrder={digitOrder} questionType={questionType} question={question} answers={answers} />
    }
    
  }
}

function SmsPhonePreview({ content, showCharacterCount, includeOptOut }) {
  const act = useAct()
  const [renderedContent, setRenderedContent] = useState('')

  function render() {
    act.campaignBuilder.renderArbitraryTemplate(content, text => setRenderedContent(text), includeOptOut)
  }

  useEffect(() => {
    render()
  }, [content])

  useEffect(() => {
    render()
  }, [includeOptOut])

  return <>
    <SmsPreview name='Test' content={{ body: renderedContent }} kind='sms' />
    { showCharacterCount && <CharacterCounter condensed={true} content={renderedContent} /> }
  </>
}

function WhatsappPhonePreview({ questionType, question, answers, digitOrder }) {
  const act = useAct()
  const [renderedBody, setRenderedBody] = useState('')
  const [renderedActions, setRenderedActions] = useState({})

  function render() {
    if (digitOrder === 1) {
      act.campaignBuilder.renderTemplate(data => setRenderedBody(data.body))
    } else {
      act.campaignBuilder.renderArbitraryTemplate2(question, text => setRenderedBody(text))
    }
    if (questionType === 'multiple_choice' && answers.length <= 3) {
      const payload = _.fromPairs(answers.map(({ digitOrder, answer }, index) => [index, answer]))
      act.campaignBuilder.renderArbitraryTemplate(payload, data => setRenderedActions(data))
    }
  }

  useEffect(() => {
    render()
  }, [question, JSON.stringify(answers)])

  const actions = answers.length <= 3 ? _.values(renderedActions).map(text => ({ text })) : [{ kind: 'list-picker', text: 'Choose an answer' }]

  return <>
    <SmsPreview name='Test' content={{ body: renderedBody }} kind='whatsapp' actions={actions} />
  </>
}

export function buildQuestionString({ questionType, question, answers }) {
  const answerStr = answers.filter(a => a.answer).
    map(({ digitOrder, answer }, index) => `${index + 1} for ${answer}`).join("\n")

  if (questionType === 'multiple_choice') {
    return `${question ?? ''}${answerStr.length > 0 ? ' Txt ' : ''}${answerStr}`
  }

  return question ?? ''
}
