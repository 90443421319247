import BackdropModal from 'Shared/components/BackdropModal/BackdropModal'
import restClient from "Shared/hooks/restClient"

const AddRoleModal = ({ open, onCancel, onConfirm }) => {

  const [name, setName] = useState('')
  const [errors, setErrors] = useState({})

  const addRole = () => {
    restClient.post('/api/roles', { role: { name } }).then((response) => {
      onCancel()
      onConfirm()
    }).catch((r) => {
      console.error(r)
    })
  }

  return (
    <BackdropModal title="Add a role" open={open} onCancel={onCancel}>
      <form onSubmit={(e) => { e.preventDefault() }}>
        <p className="field">
          <label>Name</label>
          <input type="text" value={name} onChange={(e) => setName(e.target.value)} />
        </p>
        <p>
          <button className="button primary" type="submit" onClick={() => addRole()}>Add</button>
        </p>
      </form>
    </BackdropModal>
  )
}

export default AddRoleModal
