import useAct from '../hooks/useAct'

// Ensure both the survey and surveyTaker are loaded
export default function useEnsureSurvey(surveyId, surveyTakerId) {
  const act = useAct()
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    act.survey.load(surveyId)
      .then(() => 
        surveyTakerId ? act.surveyTaker.load(surveyTakerId) : act.surveyTaker.begin()
      )
      .then(() => setLoaded(true))
  }, [])

  return loaded
}
