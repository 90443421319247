import * as React from 'react'

function SvgDelete(props) {
  return (
    <svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M4.836 6.168v10.167a1.5 1.5 0 001.5 1.5h7a1.5 1.5 0 001.5-1.5V6.168h-10z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.5 3.25V3a1 1 0 00-1-1H9.167a1 1 0 00-1 1v.25H4.5a.5.5 0 00-.5.5V4a.5.5 0 00.5.5h10.667a.5.5 0 00.5-.5v-.25a.5.5 0 00-.5-.5H11.5z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgDelete
