import './FilterList.scoped.scss'

export default function FilterList({ groupId, close }) {
  const act = useAct()
  const [groupIndex, setGroupIndex] = useState(0)

  const availableFilters = useSel((s) => s.availableFilters)
  const visibleFilters = Object.values(availableFilters).filter(
    (filter) => !filter.presentation.hidden
  )

  // group filters into categories
  // sort  both group + filter names alphabetically
  const filterGroups = [
    // First, find and extract the 'Quick' category if it exists
    ..._.sortBy(
      _.uniqBy(
        visibleFilters.map((filter) => ({
          name: filter.presentation.group,
          description: null,
        })),
        'name'
      ).map((group) => ({
        ...group,
        filters: _.sortBy(
          visibleFilters.filter((filter) => filter.presentation.group === group.name),
          (filter) => filter.presentation.name
        ),
      })),
      (group) => (group.name === 'Quick' ? -1 : group.name)
    ),
  ]

  const filters = filterGroups[groupIndex]?.filters
  if (!filters) return null // race can mean that the group is not yet loaded

  return (
    <div className="popup">
      <div className="group-names">
        {filterGroups.map((group, i) => {
          return (
            <div
              key={i}
              className={`${groupIndex === i ? 'active' : ''} ${i === 0 ? 'first' : ''}`}
              onClick={() => setGroupIndex(i)}
            >
              <h5>
                {group.name === 'Quick' && <SvgIconsStar width={16} height={16} />}
                {group.name}
                {groupIndex === i && <SvgIconsArrowRight />}
              </h5>
              {group.description && <p>{group.description}</p>}
            </div>
          )
        })}
      </div>
      <div className="group-filters">
        {filters.map((filter) => {
          return (
            <div
              key={filter.presentation.name}
              className="filter"
              onClick={() => {
                act.search.addFilter(groupId, filter.presentation.id)
                close()
              }}
            >
              {filter.presentation.name}
            </div>
          )
        })}
      </div>
    </div>
  )
}
