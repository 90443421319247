import useAct from 'DesktopCaller/useAct'
import Modal from 'Shared/components/Modal/Modal'
import Button from 'Shared/components/Button'
import CardPicker from 'Shared/components/CardPicker/CardPicker'
import Phone from './icons/Phone'
import Browser from './icons/Browser'


const desktopWebOption = {
  value: 'desktop_web',
  icon: <Browser />,
  title: i18n.t('user.campaigns.phone.calling_options.web.title'),
  description: i18n.t('user.campaigns.phone.calling_options.web.description')
}
const phoneOption = {
  value: 'phone',
  icon: <Phone />,
  title: i18n.t('user.campaigns.phone.calling_options.phone.title'),
  description: i18n.t('user.campaigns.phone.calling_options.phone.description')
}
const desktopPhoneOption = {
  value: 'desktop_phone',
  icon: <Browser />,
  title: i18n.t('user.campaigns.phone.calling_options.desktop_phone.title'),
  description: i18n.t('user.campaigns.phone.calling_options.desktop_phone.description')
}
const ivrOption = {
  value: 'ivr',
  icon: <Phone />,
  title: i18n.t('user.campaigns.phone.calling_options.ivr.title'),
  description: i18n.t('user.campaigns.phone.calling_options.ivr.description')
}

export default function ChooseCallingMode({ modes, useModal }) {
  const act = useAct()
  const [step, setStep] = useState('web_or_phone')
  const [callingMode, setCallingMode] = useState(null)
  const settings = useSel((s) => s.campaigns.entities[s.campaigns.ids[0]].settings)
  const twilioInitializeError = useSel(s => s.local.twilioInitializeError)
  const twilioDeviceError = useSel(s => s.local.twilioDeviceError)

  const webOrPhoneOptions = []
  const phoneOptions = []

  if (modes.includes('desktop_web')) {
    webOrPhoneOptions.push(desktopWebOption)
  }
  if (modes.includes('desktop_phone')) {
    webOrPhoneOptions.push(phoneOption)
    phoneOptions.push(desktopPhoneOption)
  }
  if (modes.includes('ivr')) {
    if (!webOrPhoneOptions.includes(phoneOption)) {
      webOrPhoneOptions.push(phoneOption)
    }
  }
  // phoneOptions.push(ivrOption)


  const handleContinue = () => {
    if (step === 'web_or_phone' && callingMode === 'phone') {
      setCallingMode(null)
      setStep('phone')
    } else {
      act.local.upsert({ callingMode })
    }
  }

  return (
    <>
      {twilioInitializeError && <div className='content warning'>
        {i18n.t('user.campaigns.phone.twilio_error')}
      </div>}

      {twilioDeviceError && <div className='content warning'>
        {i18n.t('user.campaigns.phone.twilio_device_error')}
      </div>}

      <div className='content'>
        <CardPicker
          options={step === 'web_or_phone' && webOrPhoneOptions.includes(desktopWebOption) ? webOrPhoneOptions : phoneOptions}
          value={callingMode}
          onChange={setCallingMode} />

        <p className='margin-top no-margin-bottom text-center'>
          <Button primary disabled={!callingMode} onClick={handleContinue}>{i18n.t('shared.messages.continue')}</Button>
        </p>
      </div>
    </>
  )
}

