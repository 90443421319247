import store from 'Shared/store'
import { Provider } from 'react-redux'
import PageContainer from './PageContainer'
import { ThemeProvider } from '@mui/material'
import { MiuTheme } from 'Shared/functions/util'

export default function SearchBuilder({
  returningUrl,
  searchId,
  searchesUrl,
  linkedCampaignId,
  linkedSurveyId,
  defaultFilters,
}) {
  return (
    <Provider store={store}>
      <ThemeProvider theme={MiuTheme}>
        <ErrorBoundary>
          <PageContainer
            returningUrl={returningUrl}
            searchId={searchId}
            searchesUrl={searchesUrl}
            linkedCampaignId={linkedCampaignId}
            linkedSurveyId={linkedSurveyId}
            defaultFilters={defaultFilters}
          />
        </ErrorBoundary>
      </ThemeProvider>
    </Provider>
  )
}
