import './Survey.scoped.scss'
import Button from 'Shared/components/Button'
import IntroGraphicSurvey from './IntroGraphicSurvey'
import IntroGraphicBallot from './IntroGraphicBallot'
import Question from './Question'
import { useParams, useNavigate } from 'react-router-dom';
import useAct from '../hooks/useAct'


function IntroSurvey({ targetFirstName, clientName }) {
  return (
    <div className="mvmt-survey-intro">
      <IntroGraphicSurvey />
      <h1>
        Hi, {targetFirstName}
      </h1>
      <p>You&rsquo;ve been invited to answer some questions by {clientName}</p>
      <p>This will take 5 minutes</p>
    </div>
  )
}

function IntroBallot({ targetFirstName, clientName }) {
  return (
    <>
      <IntroGraphicBallot />
      <h1>
        HI, {targetFirstName}<br />
        welcome to your ballot vote
      </h1>
      <p>You&rsquo;ve been invited to vote in a ballot by {clientName}</p>
      <p>This will only take a few minutes</p>
    </>
  )
}

export default function Intro({ targetFirstName }) {
  let { targetId, surveyId } = useParams();
  const navigate = useNavigate()
  const firstQuestionId = useSel((s) => s.survey.questions[0]?.id)
  const { surveyKind, client: { name: clientName } } = useSel((s) => s.survey)
  const currentQuestionId = useSel((s) => s.surveyTaker.currentQuestionId)

  const start = () => {
    navigate(`/target/surveys/${surveyId}/questions/${currentQuestionId}`)
  }

  return (
    <div className="intro-container">
      {surveyKind === 'ballot' ?
        <IntroBallot targetFirstName={targetFirstName} clientName={clientName} /> :
        <IntroSurvey targetFirstName={targetFirstName} clientName={clientName} />
      }
      <p>
        <Button large light primary onClick={start}>Start</Button>
      </p>
    </div>
  )
}

