import './MessageBubble.scoped.scss'
import { format } from "date-fns"
import MessageIcon from './MessageIcon'

export default function MessageBubble({
  senderName,
  direction,
  date,
  body,
  initialMessage = false,
  indent = false,
  media = null,
}) {

  const classNames = ['message-bubble', direction]

  if(initialMessage) {
    classNames.push('initial')
  }
  if(indent) {
    classNames.push('indent')
  }

  return (
    <div className={classNames.join(' ')}>
      <div className="meta">
        <MessageIcon initialMessage={initialMessage} direction={direction} />
        {initialMessage ?
        <p className="initial-message">Intro</p> : 
        <p className="sender-name">{senderName}</p>
        }
        <p className="date">{format(date,'HH:mm a')}</p>
      </div>

      <div className="message">{body}</div>

      {media && <div className="media">{media}</div>}
    </div>
  )
}

