import './MediaPreview.scoped.scss'

const IMAGE_EXTENSIONS = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp', 'svg']
const VIDEO_EXTENSIONS = ['mp4', 'avi', 'mov', 'mkv', 'webm', 'flv']

function extension(filename) {
  const parts = filename.split('.')
  return parts[parts.length - 1]
}

function ImagePreview({ url }) {
  return <img src={url} alt="media" />
}

function VideoPreview({ url }) {
  return <video src={url} controls />
}

export default function MediaPreview({ url }) {
  if (!url) {
    return null
  }

  const filename = url.split('/').pop()
  const isImage = IMAGE_EXTENSIONS.includes(extension(filename))
  const isVideo = VIDEO_EXTENSIONS.includes(extension(filename))

  return (
    <div className="media-preview">
      {isImage && <ImagePreview url={url} />}
      {isVideo && <VideoPreview url={url} />}
      {!isImage && !isVideo && <p>File: {filename}</p>}
    </div>
  )
}
