import './FloatingMenu.scoped.scss'


export default function FloatingMenu({ items }) {
  const [activeItem, setActiveItem] = useState(0)

  function move(index, tag) {
    const element = document.getElementById(tag)
    setActiveItem(index)
    element?.scrollIntoView({ behavior: 'smooth' })
  }

  useEffect(() => {
    const handleScroll = () => {
      var menu = document.querySelector('.floating-menu')
      if (window.pageYOffset > 100) {
        menu.style.top = '24px'
      } else {
        menu.style.top = '0px'
      }
    }
    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <div className="floating-menu">
      {items.map((item, index) => {
        return (
          <a
            className={`item ${activeItem === index ? 'active' : ''}`}
            onClick={() => move(index, item.tag)}
            key={index}
          >
            <span>{item.name}</span>
            {activeItem === index && <SvgIconsArrowRight />}
          </a>
        )
      })}
    </div>
  )
}
