import _ from "lodash"
import { renderToString } from 'react-dom/server'

const columns = [
  {
    data: 'external_id',
    className: 'bold',
    width: '20%',
    render: (data, type, row, meta) => renderToString(
      <a href={row['url']}>{_.unescape(data)}</a>
    )
  },
  {
    data: 'name',
    width: '30%',
    render: (data, type, row, meta) => renderToString(
      <a href={row['url']} className={(!data || data.length === 0) ? 'italic' : ''}>
        { (!data || data.length === 0) ? 'No name' : _.unescape(data) }
      </a>
    )
  },
  { data: 'phone_number', width: '17%' },
  { data: 'email', width: '33%'}
]

const headers = [
  'Membership Number',
  'Name',
  'Phone Number',
  'Email'
]

const filters = [
]

const sorters = []

const order = []

export { columns, headers, filters, sorters, order }
