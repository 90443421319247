import { createSlice } from '@reduxjs/toolkit'
import { campaignSlice } from 'Shared/store'
import { generateEntitySlices } from 'Shared/sliceHelpers'

const local = createSlice({
  name: 'local',
  initialState: {
    campaignId: window.campaignId,
    organisationHistory: []
  },
  reducers: {
    upsert: (s, a) => ({ ...s, ...a.payload }),
    addHistory: (s, a) => {
      s.organisationHistory.push(a.payload)
    },
    goBack: (s, a) => {
      s.organisationHistory.pop()
      s.chosenOrganisationId = s.organisationHistory.pop() || s.defaultOrganisationId
    }
  },
}).reducer

const entitySliceNames = ['questions', 'answers']

const reducers = {
  local,
  campaign: campaignSlice.reducer,
  ...generateEntitySlices(entitySliceNames)
}

export default reducers
