import './EditUser.scoped.scss'
import store from 'Shared/store'
import { Provider } from 'react-redux'
import restClient from 'Shared/hooks/restClient'
import { CircularProgress } from '@material-ui/core'
import EditUserForm from './EditUserForm'
import UserDetails from './UserDetails'
import Button from 'Shared/components/Button'
import CopyToClipboard from 'Shared/components/CopyToClipboard'
import { copyToClipboard } from 'Shared/functions/util'

const EditUser = ({id, reinvitePath, inviteStatus, regenerateInvitePath, inviteCreatedAt, inviteAcceptedAt, enableEditing}) => {
  let csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content')
  const [user, setUser] = useState(null)
  const [roles, setRoles] = useState(null)
  const [copied, setCopied] = useState(false)
  const showInviteBox = inviteStatus === 'invitation expired' || inviteStatus === 'invitation waiting'

  const regenerateInvite = () => {
    restClient.put(regenerateInvitePath).then(({ data: { invitationUrl } }) => {
      copyToClipboard(invitationUrl)
      setCopied(true)
      setTimeout(() =>  setCopied(false) , 3000)
    })
  }

  const InvitationStatus = ({ createdAt, expired }) => (
    <div className="tip no-margin-top margin-bottom">
      <SvgIconsTip />
      { !expired && <p className='normal-size'>This user was invited at { createdAt}, and hasn't accepted their invite yet.</p> }
      { expired && <p className='normal-size'>This user was invited at { createdAt }, but their invite has expired.</p> }
      <div className='full-width'>
        <div className="buttons margin-top">
          <form action={reinvitePath} method='POST'>
            <input type="hidden" name="authenticity_token" value={csrfToken} />
            <Button secondary small>Re-invite</Button>
            &nbsp;
            <span>or</span>
            &nbsp;
            {copied ?
              <span className='bold blue underlined'>Copied</span> :
              <>
                <a className='blue-link' onClick={regenerateInvite}>Copy a new invite link to the clipboard</a>
                &nbsp; (previous invite link will be invalidated)
              </>
            }
          </form>
        </div>
      </div>
    </div>
  )

  useEffect(() => {
    restClient.get(`/api/users/${id}`)
      .then(({ data }) => setUser(data))
    restClient.get(`/api/roles`)
      .then(({ data }) => setRoles(data))
  } , [])

  return (
    <Provider store={store}>
      <div className="EditUser">
        {user && showInviteBox ? <InvitationStatus createdAt={inviteCreatedAt} expired={inviteStatus === 'invitation expired'} /> : null}
        {!(user && roles) ? <CircularProgress /> : null}
        {user && roles && enableEditing ? <EditUserForm user={user} roles={roles} inviteAcceptedAt={inviteAcceptedAt} /> : null}
        {user && roles && !enableEditing ? <UserDetails user={user} roles={roles} inviteAcceptedAt={inviteAcceptedAt} /> : null}
      </div>
    </Provider>
  )
}

export default EditUser
