export default function MessageIcon({ direction, initialMessage }) {
  const icon = () => {
    if (initialMessage) {
      return <SvgIconsCallerGreeting />
    }

    if (direction === 'inbound') {
      return <SvgIconsConceptsUserBlue />
    } else {
      return <SvgIconsSmsOutgoing />
    }
  }
  return <div className="icon circle">{icon()}</div>
}
