import { LineChart as RechartLineChart, CartesianGrid, XAxis, YAxis, Line, ResponsiveContainer } from 'recharts'

const LineChart = ({ lines, xLabel, yLabel, data, height = 400 }) => {
  return (
    <ResponsiveContainer height={height}>
      <RechartLineChart width={500} height={300} data={data}>
        <XAxis
          dataKey="name"
          axisLine={false}
          height={70}
          label={{ value: xLabel, position: 'insideBottom' }}
          tickLine={false} />
        <YAxis
          width={80}
          axisLine={false}
          tickLine={false}
          label={{ value: yLabel, angle: -90, position: 'insideLeft' }} />
        <CartesianGrid
          strokeWidth={3}
          stroke="rgba(30, 30, 136, 0.05)"
          horizontal={false}
        />
        {lines.map((line, i) => <Line key={i} dot={false} type="monotone" dataKey={line.key} strokeWidth={5} stroke={line.color} />)}
      </RechartLineChart>
    </ResponsiveContainer>
  )
}

export default LineChart
