import _ from "lodash";

const columns = [
  {
    data: 'name',
    width: '27%',
    className: 'bold',
    render: function (data, type, row, meta) {
      const name = _.isEmpty(data.trim()) ? 'No name' : data
      const text = row.organisations === '' ? `${name} (deactivated)` : name
      return `<a href="/admin/users/${row.DT_RowId}">${text}</a>`
    },
  },
  { data: 'email', width: '31%'},
  {
    data: 'roles',
    width: '20%',
    render: (data, type, row, meta) =>
      `${row.roles} <span class='ellipsis accent'></span> ${row.organisations}`,
  },
  {
    data: 'name', width: '22%',
    render: (data, type, row, meta) => `<button data-user-id="${row.DT_RowId}" class="button red small smaller remove-user" type="button">Remove from group</button>`
  },
]

const headers = [
  'Name',
  'Email',
  'Access level',
  'Action',
]

const filters = [
]

const sorters = [
]

const order = [[ 1, "asc" ]]

export { columns, headers, filters, sorters, order }
