import { Provider } from 'react-redux'
import store from 'Survey/store'
import SurveyRoutes from './SurveyRoutes'

export default function Survey(props) {
  return <Provider store={store}>
    <ErrorBoundary>
      <div className="mvmt-survey">
        <SurveyRoutes {...props} />
      </div>
    </ErrorBoundary>
  </Provider>
}
