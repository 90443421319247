import deferredFetch from "Shared/utils/deferredFetch"

const usageData = (dis, store, client) => {
  async function load(month) {
    return deferredFetch("/api/billing/usage", { month })
      .then((data) => {
        dis({ type: 'usageData/set', payload: data })
      })
  }

  return { load }
}

export default usageData
