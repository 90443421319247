import PillEditor from 'Shared/components/PillEditor/PillEditor'
import './ListEditor.scoped.scss'

const Item = ({
  item,
  active,
  onClick,
  onChange,
  onRemove,
  onEditingComplete,
  fontStyle,
}) => {
  const mergeTags = useSel((s) => s.campaignBuilder.mergeTags)
  if (active) {
    return (
      <li>
        <PillEditor
          initialValue={item}
          richText
          onChange={(body) => {
            onChange(body.replace(/^<p>/, '').replace(/<\/p>$/, ''))
          }}
          condensed
          availableTags={mergeTags}
          singleLine
        />

        <div className="margin-top">
          <button
            type="button"
            className="button small red margin-right"
            onClick={onRemove}
          >
            Remove
          </button>
          <button
            type="button"
            className="button small primary"
            onClick={onEditingComplete}
          >
            Done
          </button>
        </div>
      </li>
    )
  }
  return (
    <li
      onClick={onClick}
      style={fontStyle}
      dangerouslySetInnerHTML={{ __html: item }}
    ></li>
  )
}

const ListEditor = ({ block, tag }) => {
  const act = useAct()
  const [activeIndex, setActiveIndex] = useState(null)

  const fontStyle = {
    fontFamily: block.parameters.fontFamily,
    fontSize: block.parameters.fontSize + 'px',
  }

  const updateItems = (fn) =>
    act.email.blocks.updateParameters(block.id, {
      items: fn(block.parameters.items.slice()),
    })

  const updateItem = (index, text) => {
    updateItems((items) => {
      items[index] = text
      return items
    })
  }

  const addItem = () => {
    updateItems((items) => {
      items.push('Item')
      setActiveIndex(items.length - 1)
      return items
    })
  }

  const removeItem = (index) => {
    setActiveIndex(null)
    updateItems((items) => {
      items.splice(index, 1)
      return items
    })
  }

  const items = () =>
    block.parameters.items.map((item, index) => (
      <Item
        active={activeIndex === index}
        key={index}
        item={item}
        onClick={() => setActiveIndex(index)}
        onChange={updateItem.bind(null, index)}
        onRemove={() => removeItem(index)}
        onEditingComplete={() => setActiveIndex(null)}
        fontStyle={fontStyle}
      />
    ))

  return (
    <>
      {tag === 'ol' && <ol>{items()}</ol>}
      {tag === 'ul' && <ul>{items()}</ul>}
      <button type="button" className="button small secondary" onClick={addItem}>
        Add list item
      </button>
    </>
  )
}

export default ListEditor
