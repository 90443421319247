import useValidator from 'Shared/hooks/useValidator'

const TextField = ({label, id, ...props}) => {
  const [inputProps, errorMessage] = useValidator(props)
  return (
    <div className="field">
      {label && <label htmlFor={id}>{label}</label>}
      <input {...inputProps} type="text" id={id} />
      {errorMessage && <span className="error">{errorMessage}</span>}
    </div>
  )
}

export default TextField
