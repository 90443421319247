import Select from 'react-select'
import { useField, Field } from 'formik';

// Wrasp react-select for Formik, handling translating between option objects and values and
// displaying error messages
const styles = {
  multiValue: (base, state) => {
    return state.data.isFixed ? { ...base, backgroundColor: 'gray' } : base;
  },
  multiValueLabel: (base, state) => {
    return state.data.isFixed
      ? { ...base, fontWeight: 'bold', color: 'white', paddingRight: 6 }
      : base;
  },
  multiValueRemove: (base, state) => {
    return state.data.isFixed ? { ...base, display: 'none' } : base;
  },
}

const FormikSelect = ({ name, options, ...props }) => {
  const [field, meta] = useField({name, ...props});
  const showError = !!(meta.touched && meta.error)

  const optionWithValue = (v) => options.find((o) => o.value === v)

  const getValue = (field) => Array.isArray(field.value) ?
    field.value?.map(optionWithValue) : 
    optionWithValue(field.value)

  return (
    <Field name={name}>
      {
        ({ field, form }) => (
          <>
            <Select
              value={getValue(field)}
              options={options}
              name={field.name}
              styles={styles}
              onChange={(option, actionMeta) => {
                switch (actionMeta.action) {
                  case 'remove-value':
                  case 'pop-value':
                    if (actionMeta.removedValue.isFixed) {
                      return;
                    }
                    break;
                }

                const value = Array.isArray(option) ? option.map((o) => o.value) : option?.value
                form.setFieldValue(field.name, value)
              }}
              isOptionDisabled={o => o.disabled}
              {...props}
            />
            {showError && <div className="error-message">{meta.error.join(', ')}</div>}
          </>
        )}
    </Field>
  )
}

export default FormikSelect
