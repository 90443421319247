export default function generateGraphColours(count) {
    if (count <= 3) {
        return ['#26264D99', '#00B9D2', '#00F399']
    } else if (count === 4) {
        return ['#20204d33', '#26264D99', '#00B9D2', '#00F399']
    } else if (count === 5) {
        return ['#20204d33', '#26264D99', '#00B9D2', '#00C6C5', '#00F399']
    } else {
        return ['#20204d33', '#26264D99', '#00B9D2', '#00C6C5', '#00E0AC', '#00F399']
    }
}