import { v4 as uuidv4 } from 'uuid'

import './Block.scoped.scss'

import ParagraphBlock from './paragraph/ParagraphBlock'
import ParagraphV2Block from './paragraph-v2/ParagraphV2Block'
import HtmlBlock from './html/HtmlBlock'
import HeadingBlock from './heading/HeadingBlock'
import ImageBlock from './image/ImageBlock'
import ButtonBlock from './button/ButtonBlock'
import SpacerBlock from './spacer/SpacerBlock'
import DividerBlock from './divider/DividerBlock'
import OrderedListBlock from './ordered-list/OrderedListBlock'
import UnorderedListBlock from './unordered-list/UnorderedListBlock'
import Button from 'Shared/components/Button'
import NewBlock from '../NewBlock'
import QuoteBlock from './quote/QuoteBlock'
import StaticBlock from 'EmailBuilder/components/blocks/StaticBlock'
import TableBlock from './table/TableBlock'

const templateMap = {
  paragraph: ParagraphBlock,
  paragraph_v2: ParagraphV2Block,
  heading: HeadingBlock,
  image: ImageBlock,
  button: ButtonBlock,
  spacer: SpacerBlock,
  divider: DividerBlock,
  ordered_list: OrderedListBlock,
  unordered_list: UnorderedListBlock,
  quote: QuoteBlock,
  html: HtmlBlock,
  table: TableBlock
}

export interface BlockType {
  id: string
  parameters: Record<string, any>
}

const blankBlock = ({ id, parameters }) => ({
  id: uuidv4(),
  blockTemplateId: id,
  parameters: parameters,
})

const Block = ({ id, active, onClick, first, last }) => {
  const act = useAct()
  const [newBlockPlacement, setNewBlockPlacement] = useState(null)

  const block = useSel((s) => s.blocks.present.entities[id])

  const error = useSel((s) =>
    s.emailBuilder.errors && s.emailBuilder.errors.find((e) => e.blockId === block.id)
  )

  useEffect(() => {
    setNewBlockPlacement(null)
  }, [active])

  const addBlock = (template) => {
    const newBlock = blankBlock(template)
    if (newBlockPlacement === 'before') {
      act.email.blocks.insertBefore(block.digitOrder, newBlock)
    } else {
      act.email.blocks.insertAfter(block.digitOrder, newBlock)
    }
    setNewBlockPlacement(null)
    setTimeout(() => {
      act.emailBuilder.setActiveBlockId(newBlock.id)
    })
  }

  const removeBlock = () => {
    setNewBlockPlacement(null)
  }

  const addBlocks = () => {
    return (
      <>
        <div className="add-block-above" onClick={() => setNewBlockPlacement('before')}>
          <SvgIconsPlusWhite />
        </div>
        <div className="add-block-below" onClick={() => setNewBlockPlacement('after')}>
          <SvgIconsPlusWhite />
        </div>
      </>
    )
  }

  const controls = () => (
    <div className="controls">
      <div className="buttons">
        <Button
          smallest
          red
          extraClass="small-square-button"
          onClick={() => act.email.blocks.remove(block.id)}
        >
          <SvgIconsDelete />
        </Button>
        <Button
          smallest
          primary
          disabled={first}
          extraClass="small-square-button"
          onClick={() => act.email.blocks.moveDigitOrder(block.id, block.digitOrder - 2)}
        >
          <SvgIconsUp />
        </Button>
        <Button
          smallest
          primary
          disabled={last}
          extraClass="small-square-button"
          onClick={() => act.email.blocks.moveDigitOrder(block.id, block.digitOrder)}
        >
          <SvgIconsDown />
        </Button>
      </div>
    </div>
  )

  const template = useSel((s) =>
    s.emailBuilder.blockTemplates.find((t) => t.id === block.blockTemplateId)
  )

  const BlockComponent = templateMap[template.name]
  if (!BlockComponent) return null

  const classNames = ['block-container']
  if (active) classNames.push('active')
  if (error) classNames.push('block-container-with-error')

  return (
    <>
      {newBlockPlacement === 'before' && (
        <NewBlock insert={'before'} close={close} handleClick={addBlock} onClose={removeBlock} />
      )}
      <div className={classNames.join(' ')} onClick={onClick}>
        {active && controls()}
        {active && addBlocks()}
        {active && (
          <div>
            <table className="container">
              <tbody>
                <tr>
                  <td>
                    <table className="row">
                      <tbody>
                        <tr>
                          <th className="small-12 large-12 columns first last border">
                            <BlockComponent block={block} active={active} />
                          </th>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        )}
        <StaticBlock block={block} blockTemplate={template.name} active={active} />
        {error && <div className="block-error">{error.message}</div>}
      </div>
      {newBlockPlacement === 'after' && (
        <NewBlock insert={'after'} close={close} handleClick={addBlock} onClose={removeBlock} />
      )}
    </>
  )
}

export default Block
