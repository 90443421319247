import Button from 'Shared/components/Button'
import Modal from 'Shared/components/Modal/Modal'
import CopyToClipboard from 'Shared/components/CopyToClipboard'
import restClient from 'Shared/hooks/restClient'
import AsyncSelect from 'Shared/components/AsyncSelect'
import Flash from 'Shared/components/Flash/components/Flash'
import ManageMenuComponent from 'Shared/components/ManageMenu/ManageMenuComponent'
import { ManageMenuItem } from 'Shared/components/ManageMenu'
import SubscriptionTopicsSelect from 'Shared/components/SubscriptionTopicsSelect'
import Select from 'react-select'

export default function AudienceActions({ searchId, canExport, canImport }) {
  const [exportPassword, setExportPassword] = useState(null)
  const [completeModalOpen, setCompleteModalOpen] = useState(false)
  const [subscriptionModalOpen, setSubscriptionModalOpen] = useState(false)
  const [removeModalOpen, setRemoveModalOpen] = useState(false)
  const [effortId, setEffortId] = useState(null)
  const [subscriptionStatus, setSubscriptionStatus] = useState(false)
  const [topic, setTopic] = useState('default')
  const [kind, setKind] = useState('phone')
  const [title, setTitle] = useState('phone calls')
  const [flash, setFlash] = useState(null)

  const setKindAndTopic = (value) => {
    const [newKind, newTopic] = value.split(':')
    setKind(newKind)
    setTopic(newTopic)
  }

  const bulkActions: ManageMenuItem[] = [
    {
      title: 'Complete Goal',
      onClick: () => {
        setCompleteModalOpen(true)
      },
    },
    {
      title: 'Update Subscription',
      onClick: () => {
        setSubscriptionModalOpen(true)
      },
    },
  ]

  if (canImport) {
    bulkActions.push({
      title: 'Permanently Delete',
      onClick: () => {
        setRemoveModalOpen(true)
      },
    })
  }

  const doExport = () => {
    restClient.post(`/api/searches/${searchId}/export`).then(({ data: { password } }) => {
      setExportPassword(password)
    })
  }

  const runBulkAction = (action, parameters) => {
    restClient
      .post(`/api/bulk_actions/launch`, {
        ids: [searchId],
        bulk_action: {
          actions: [{ action: action, parameters: parameters }],
        },
      })
      .then(() => {
        setCompleteModalOpen(false)
        setSubscriptionModalOpen(false)
        setRemoveModalOpen(false)
        setFlash({
          message: 'Thanks, this will happen over the next few minutes.',
          type: 'success',
        })
      })
  }

  const subscribedOptions = [
    { value: true, label: 'Subscribed' },
    { value: false, label: 'Unsubscribed' }
  ]

  return (
    <div className="actions">
      {canExport && (
        <>
          <Button extraClass="margin-right" primary onClick={doExport}>
            <SvgIconsDownload />
            Export Audience
          </Button>

          <Modal
            open={!!exportPassword}
            title="Export in progress"
            onCancel={() => setExportPassword(null)}
          >
            The audience is exporting. You'll receive an email when it's done. Use this
            password to open it: &nbsp;
            <CopyToClipboard
              text={`${exportPassword} (click to copy)`}
              url={exportPassword}
            />
          </Modal>
        </>
      )}

      <ManageMenuComponent items={bulkActions} components={null} title="Bulk Actions" />

      {flash && (
        <Flash message={flash.message} type={flash.type} onHide={() => setFlash(null)} addClass="flash-right-align" />
      )}

      <Modal
        open={!!completeModalOpen}
        title="Complete a goal for this audience"
        onCancel={() => setCompleteModalOpen(false)}
      >
        <div className="row">
          <div className="field">
            <label className="bold margin-bottom">Goal:</label>
            <AsyncSelect
              entity="effort"
              menuPosition="fixed"
              value={effortId}
              loadStaticList={true}
              onChange={(v) => setEffortId(v)}
            />
          </div>
        </div>
        <div className="row">
          <button
            className="button primary margin-top"
            type="submit"
            onClick={() => runBulkAction('complete_goal', { effortId: effortId })}
          >
            Confirm
          </button>
        </div>
      </Modal>

      <Modal
        open={!!subscriptionModalOpen}
        title="Update a subscription for this audience"
        onCancel={() => setSubscriptionModalOpen(false)}
      >
        <div className="row">
          <div className="field">
            <label className="bold margin-bottom">Subscription to update</label>
            <SubscriptionTopicsSelect
              onChange={(v) => {
                setKindAndTopic(v.value)
                setTitle(v.label === 'Phone' ? 'phone calls' : v.label === 'Email' ? 'emails' : v.label === 'SMS' ? 'SMS' : 'WhatsApp messages')
              }}
              value={`${kind}:${topic}`}
            />
          </div>
        </div>

        <div className="row margin-top">
          <div className="field">
            <label className="bold margin-bottom">Subscription Status</label>
            <Select
              options={subscribedOptions}
              value={subscribedOptions.find(option => option.value === subscriptionStatus)}
              onChange={(selectedOption) => setSubscriptionStatus(selectedOption.value)}
              classNamePrefix="subscription-topics-select"
              styles={{
                container: (baseStyles) => ({
                  ...baseStyles,
                  width: '200px',
                }),
              }}
            />
          </div>
        </div>

        <p className="bold">This will {subscriptionStatus ? 'subscribe' : 'unsubscribe'} everyone in the audience {subscriptionStatus ? 'to receive' : 'from receiving'} {title}.</p>

        <div className="row">
          <button
            className="button primary margin-top update-subscription-button"
            type="submit"
            onClick={() => runBulkAction('update_subscription', { subscriptionStatus: subscriptionStatus, kind, topic })}
          >
            Confirm
          </button>
        </div>
      </Modal>

      <Modal
        open={!!removeModalOpen}
        title="Permanently delete everyone in this audience"
        onCancel={() => setRemoveModalOpen(false)}
      >

        <p class="bold no-margin-top">
          This will permanently delete all people within this audience, including all their personal data.
          Please be sure this is what you want to do, as data is not recoverable. This will have the following effects:
        </p>
        <ul>
          <li>Deleted people's personal data will be removed</li>
          <li>Deleted people will not be able to reply to text messages, or WhatsApps, or surveys they've been sent</li>
        </ul>
        <p className='no-margin-bottom'>
          If you wish to recover a deleted person, you will need to upload their data again, with the same membership number.
        </p>
        <div className="row">
          <button
            className="button primary margin-top update-subscription-button"
            type="submit"
            onClick={() => runBulkAction('remove', {})}
          >
            Confirm
          </button>
        </div>
      </Modal>
    </div>
  )
}
