import Button from 'Shared/components/Button'
import { CircularProgress } from '@material-ui/core'
import restClient from 'Shared/hooks/restClient'
import Flash from 'Shared/components/Flash/components/Flash'
import { Formik, Field, Form, ErrorMessage } from 'formik';
import FormikField from 'Shared/components/FormikField/FormikField'
import useAct from 'Workflows/hooks/useAct'

export default function EditDelayNode({ nodeId }) {
  const act = useAct()
  const state = useSel(s => s.builder.workflow.state)

  const handleDelete = () => {
    act.builder.deleteNode({ nodeId })
  }

  if (state !== 'draft') return null

  return <div></div>
}