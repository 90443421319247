import * as React from 'react'

function SvgTick(props) {
  return (
    <svg width={16} height={12} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M13.738.352a1 1 0 011.524 1.296l-8.5 10a1 1 0 01-1.426.1l-4.5-4a1 1 0 011.328-1.495L5.9 9.573l7.838-9.22z"
        fill="#5DCEC1"
      />
    </svg>
  )
}

export default SvgTick
