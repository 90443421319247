import Tabs from 'Shared/components/Tabs/DynamicTabs'
import './MobileTabs.scoped.scss'

export default function MobileTabs(params) {
  return (
    <div className="mobile-tabs">
      <Tabs className="stretch" {...params} />
    </div>
  )
}

