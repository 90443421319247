import restClient from 'Shared/hooks/restClient'
import { useCallback, useMemo } from 'react'
import { ManageMenuPropsHookResult } from '../hooks'
import Modal from 'Shared/components/Modal/Modal'
import CopyToClipboard from 'Shared/components/CopyToClipboard'

export function useTargetActions(target) {
  return [
    {
      type: 'menu',
      props: {
        type: 'target',
        target,
      },
    },
  ]
}

export function useTargetMenuProps(target): ManageMenuPropsHookResult {
  const [exportPassword, setExportPassword] = useState(null)
  const [exportModalVisible, setExportModalVisible] = useState(false)
  const [contentType, setContentType] = useState('')

  const doExport = useCallback(() => {
    if (!contentType) return;

    restClient.post(`/api/targets/${target.id}/communications/export`, { content_type: contentType }).then((response) => {
      setExportPassword(response.data.password)
      setExportModalVisible(false)
    })
  }, [target.id, contentType])

  const items = useMemo(() => {
    if (!target) {
      // Handle null or undefined target here if necessary
      return [];
    }

    return [
      {
        title: 'Export Contact History',
        onClick: () => setExportModalVisible(true),
      },
      {
        title: 'Permanently Delete',
        confirmValue: 'delete',
        className: 'danger',
        onClick: () => {
          restClient.delete(`/api/targets/${target.id}`).then(() => {
            window.location.href = '/admin/audience';
          });
        },
      },
    ];
  }, [target?.id])

  const childrenComponents = useMemo(() => {
    if (!target) {
      return [];
    }

    const children = [
      <Modal
        key="export_modal"
        open={exportModalVisible}
        title="Export Results"
        onCancel={() => setExportModalVisible(false)}
      >
        <div className="form narrow">
          <div className="field">
            <label>Select content type to export:</label>
            <select
              value={contentType}
              onChange={(e) => setContentType(e.target.value)}
            >
              <option value="">Select type</option>
              <option value="text_messages">Text Messages / WhatsApps</option>
              <option value="calls">Call history</option>
              <option value="emails">Emails</option>
            </select>
          </div>
          <button
            className="button block primary no-margin-left"
            onClick={doExport}
            disabled={!contentType}
          >
            Export
          </button>
        </div>
      </Modal>
    ]

    if (exportPassword) {
      children.push(
        <Modal
          key="export_password_modal"
          open={!!exportPassword}
          title="Export in progress"
          onCancel={() => setExportPassword(null)}
        >
          The target data is exporting. You'll receive an email when it's done. Use
          this password to open it: &nbsp;
          <CopyToClipboard
            text={`${exportPassword} (click to copy)`}
            url={exportPassword}
          />
        </Modal>
      )
    }

    return children
  }, [target, exportPassword, exportModalVisible, contentType, doExport])

  return [items, childrenComponents]
}
