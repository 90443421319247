import Table from "Shared/components/DataTable/Table"
import AddRoleModal from "./AddRoleModal"

const ManageRoles = ({ }) => {

  const [modalOpen, setModalOpen] = useState(false)

  const handleTableClick = (e) => {
  }

  const tableProps = {
    table: 'roles',
    scope: {},
    language: { emptyTable: 'There are no roles yet' },
    onClick: handleTableClick,
  }

  const reloadTable = () => {
    window.$('table').DataTable().ajax.reload()
  }


  return (
    <>
      <div className="header">
        <h1>Roles</h1>
        <p><button className="button secondary" type="button" onClick={() => setModalOpen(true)}>Add a role</button></p>
      </div>
      <div className="section">
        <div className="box">
          <Table {...tableProps} />
        </div>
      </div>
      <AddRoleModal open={modalOpen} onCancel={() => setModalOpen(false)} onConfirm={reloadTable} />
    </>
  )
}

export default ManageRoles
