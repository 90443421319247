import store from 'Shared/store'
import { Provider } from 'react-redux'
import Builder from './Builder'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { DndProvider } from 'react-dnd'

const CampaignBuilder = ({
  campaignsIndexUrl,
  campaignId,
  searchesNewUrl,
  userPhoneNumber,
  userEmail,
}) => {
  return (
    <Provider store={store}>
      <DndProvider backend={HTML5Backend}>
        <ErrorBoundary>
          <Builder
            campaignsIndexUrl={campaignsIndexUrl}
            searchesNewUrl={searchesNewUrl}
            campaignId={campaignId}
            userPhoneNumber={userPhoneNumber}
            userEmail={userEmail}
          />
        </ErrorBoundary>
      </DndProvider>
    </Provider>
  )
}

export default CampaignBuilder
