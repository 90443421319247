import classNames from 'classnames'
import { Deactivate } from 'Shared/components'
import useAct from 'DesktopMessager/useAct'
import useData from 'DesktopMessager/useData'
import { useHotkeys } from 'react-hotkeys-hook'
import CannedResponses from './CannedResponses'
import { TABLET_SCREEN_WIDTH } from 'Shared/constants'
import { useWindowSize } from 'usehooks-ts'
import './MessageSender.scoped.scss'

export default function MessageSender() {
  const { draftMessage, sendBusy, isSuggested } = useSel((s) => s.local)
  const act = useAct()
  const campaign = useData((s) => s.campaign)
  const conversation = useData((s) => s.conversation)
  const textMessages = useSel(s => {
    return conversation ? _.values(s.textMessages.entities).filter(tm => tm.conversationId === conversation.id) : []
  })
  const cannedResponses = useData((s) => s.cannedResponses)
  const whatsapp = useData((s) => s.whatsapp)
  const targetDetailsUpdateEnabled = useData(
    (rs) => rs.campaign.settings?.targetDetailsUpdates === 'on'
  )
  useEffect(() => {
    if (campaign?.id && conversation?.id) {
      act.conversationManager.getConversationCannedResponses(campaign.id, conversation.id)
    }
  }, [campaign?.id, conversation?.id])

  // AI-auto responding
  useEffect(() => {
    if (campaign.settings?.enableAiReplies === 'suggest_canned_responses') {
      // when a new text message is added, check if it's inbound
      const newMessage = textMessages[textMessages.length - 1]
      if (newMessage.direction === 'inbound') {
        act.messageSender.suggestCannedResponse(newMessage.id)
      }
    }
  }, [campaign?.id, textMessages.length])

  const textareaRef = useRef()

  const clear = () => {
    textareaRef.current.value = null
    act.local.upsert({ draftMessage: '', isSuggested: false })
  }

  const onChange = (evt) => act.local.upsert({ draftMessage: evt.target.value })
  const onClickContainer = () => {
    textareaRef.current.focus()
  }
  const onClickClear = (evt) => {
    evt.stopPropagation()
    clear()
  }
  const onClickSend = async (evt) => {
    evt && evt.stopPropagation()
    act.messageSender.sendMessage()
  }
  const onClickDetailsLink = (evt) => {
    evt.stopPropagation()
    act.conversationManager.insertDetailsUpdateLink()
  }

  const size = useWindowSize()
  const desktopSize = size.width >= TABLET_SCREEN_WIDTH

  useHotkeys('ctrl+Enter', onClickSend, { enableOnFormTags: ['textarea'] })

  const showClear = !!draftMessage && !sendBusy

  return (
    <div className="message-sender" onClick={onClickContainer}>
      {isSuggested && <p className='suggested-response'>🤖 Suggested response</p>}
      <Deactivate show={sendBusy} progress fade>
        <textarea
          className="plain custom-scrollbar"
          placeholder="Type a response"
          rows={3}
          onChange={onChange}
          value={draftMessage}
          ref={textareaRef}
        />
      </Deactivate>

      <div className="controls">
        {!!cannedResponses?.length && (
          <CannedResponses
            onSelect={(draftMessage) => act.local.upsert({ draftMessage })}
          />
        )}

        {targetDetailsUpdateEnabled && (
          <a
            className={classNames('button secondary darken small margin-left margin-right', {
              disabled: sendBusy,
            })}
            onClick={onClickDetailsLink}
          >
            <SvgIconsUp
              alt={i18n.t('user.campaigns.text.alt.insert_details_update_link')}
            />
            <span>{i18n.t('user.campaigns.text.insert_details_update_link')}</span>
          </a>
        )}

        {showClear && (
          <button type="button" className="button small" onClick={onClickClear}>
            <SvgIconsClose alt={i18n.t('user.campaigns.text.alt.clear_message')} />
            <span className="">{i18n.t('shared.messages.clear')}</span>
          </button>
        )}

        {desktopSize && !whatsapp && <div className="char-count">
          {Math.ceil(draftMessage.length / 140) || 1}{' '}
          {i18n.t('user.campaigns.text.messages')}: {draftMessage.length || 0} / 140
        </div>}

        <button
          className={classNames('button small primary darken', {
            disabled: !draftMessage.length || sendBusy,
          })}
          onClick={onClickSend}
        >
          <SvgIconsSend alt={i18n.t('user.campaigns.text.alt.send_message')} />
          <span>{i18n.t('user.campaigns.text.send')}</span>
        </button>
      </div>

    </div>
  )
}
