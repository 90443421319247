import * as React from 'react'

function SvgHangUp(props) {
  return (
    <svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.489 6.664L4.31 5.485A.833.833 0 015.49 4.307l1.179 1.178 1.178-1.178a.833.833 0 111.179 1.178L7.846 6.664l1.179 1.178a.833.833 0 11-1.179 1.179L6.668 7.842 5.489 9.021A.833.833 0 014.31 7.842L5.49 6.664zM10.9 12.32l1.836-1.837a1.667 1.667 0 00.312-1.924l-.104-.208a1.666 1.666 0 01.312-1.924l2.277-2.278a.417.417 0 01.668.109l.92 1.84a3.656 3.656 0 01-.685 4.22l-5 5A4.523 4.523 0 016.81 16.41l-2.012-.671a.417.417 0 01-.163-.69l2.21-2.21a1.667 1.667 0 011.923-.312l.208.105c.642.32 1.417.194 1.924-.313z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgHangUp
