import React, { memo } from 'react';
import { Handle, Position } from 'reactflow';
import useAct from 'Workflows/hooks/useAct'
import useNodeSize from 'Workflows/hooks/useNodeSize'

function BranchNode({ id, data }) {
  const act = useAct()
  const ref = useNodeSize(id)

  const nodeId = parseInt(id.split('_')[0]) // we are passed the internal react flow ID which is like nodeId-branch-index
  const isSelected = useSel(s => s.local.sidebar.kind === 'editBranch' && s.local.sidebar.nodeId === nodeId && s.local.sidebar.branchId === data.branchId)

  function onClick() {
    if (data.else) {
      act.local.setSidebar({});
    } else {
      act.local.setSidebar({ kind: 'editBranch', branchId: data.branchId, nodeId });
    } // can't edit the else branch
  }

  function increasePriority(e) {
    e.stopPropagation()
    act.builder.updateBranchPriority({ nodeId, branchId: data.branchId, direction: 'up' })
  }

  function decreasePriority(e) {
    e.stopPropagation()
    act.builder.updateBranchPriority({ nodeId, branchId: data.branchId, direction: 'down' })
  }

  const { readOnly, isFirst, isLast, else: isElse } = data

  return <div className={`node node-branch node-branch-${id} ${isSelected ? 'selected' : ''} ${isElse ? 'else' : ''}`} onClick={onClick} ref={ref}>
    <div className='body'>
      { !isFirst && !readOnly && !isElse && <div className='branch-move-left' onClick={decreasePriority}>⬅️</div> }
      <div>{ data.else ? 'Default' : data.name }</div>
      { !isLast && !readOnly && !isElse && <div className='branch-move-right' onClick={increasePriority}>➡️</div> }
    </div>
    <Handle type="target" position={Position.Top} />
    <Handle type="source" position={Position.Bottom} />
  </div>
}

export default memo(BranchNode);
