import { BarChart as RechartBarChart, Bar, XAxis, YAxis, ResponsiveContainer } from 'recharts'

const BarChart = ({ data, bars, xLabel, yLabel, height = 400 }) => {
  return (
    <ResponsiveContainer height={height}>
      <RechartBarChart
        width={500}
        height={300}
        data={data}
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <XAxis
          dataKey="name"
          axisLine={false}
          height={70}
          label={{ value: xLabel, position: 'insideBottom' }}
          tickLine={false} />
        <YAxis
          width={80}
          axisLine={false}
          tickLine={false}
          label={{ value: yLabel, angle: -90, position: 'insideLeft' }} />
        {bars.map((line, i) => <Bar key={i} dot={false} stackId="a" dataKey={line.key} fill={line.color} />)}
      </RechartBarChart>
    </ResponsiveContainer>
  )
}

export default BarChart
