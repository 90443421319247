import SvgIcon from '@material-ui/core/SvgIcon'

const RrIcon = ({ iconName, classes = '' }) => {
  const getClass = () => `rr-icon ${classes || 'default'}`

  switch (iconName) {
    case 'up':
      return (
        <SvgIcon className={getClass()}>
          <path d="M7,15L12,10L17,15H7Z" />
        </SvgIcon>
      )
    case 'down':
      return (
        <SvgIcon className={getClass()}>
          <path d="M7,10L12,15L17,10H7Z" />
        </SvgIcon>
      )
    case 'left':
      return (
        <SvgIcon className={getClass()}>
          <path d="M15.41,16.58L10.83,12L15.41,7.41L14,6L8,12L14,18L15.41,16.58Z" />
        </SvgIcon>
      )
    case 'right':
      return (
        <SvgIcon className={getClass()}>
          <path d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z" />
        </SvgIcon>
      )
    case 'back':
      return (
        <SvgIcon className={getClass()}>
          <path d="M20,11V13H8L13.5,18.5L12.08,19.92L4.16,12L12.08,4.08L13.5,5.5L8,11H20Z" />
        </SvgIcon>
      )
    case 'play':
      return (
        <SvgIcon className={getClass()}>
          <path d="M8,5.14V19.14L19,12.14L8,5.14Z" />
        </SvgIcon>
      )
    case 'record':
      return (
        <SvgIcon className={getClass()}>
          <path d="M19,12C19,15.86 15.86,19 12,19C8.14,19 5,15.86 5,12C5,8.14 8.14,5 12,5C15.86,5 19,8.14 19,12Z" />
        </SvgIcon>
      )
    case 'close':
      return (
        <SvgIcon className={getClass()}>
          <path d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" />
        </SvgIcon>
      )
    case 'micSetup':
      return (
        <SvgIcon className={getClass()}>
          <path d="M19,10H17.3C17.3,13 14.76,15.1 12,15.1C9.24,15.1 6.7,13 6.7,10H5C5,13.41 7.72,16.23 11,16.72V20H13V16.72C16.28,16.23 19,13.41 19,10M15,24H17V22H15M11,24H13V22H11M12,13A3,3 0 0,0 15,10V4A3,3 0 0,0 12,1A3,3 0 0,0 9,4V10A3,3 0 0,0 12,13M7,24H9V22H7V24Z" />
        </SvgIcon>
      )
    case 'delete':
      return (
        <SvgIcon className={getClass()}>
          <path d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z" />
        </SvgIcon>
      )
    case 'done':
      return (
        <SvgIcon className={getClass()}>
          <path d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z" />
        </SvgIcon>
      )
    case 'help':
      return (
        <SvgIcon className={getClass()}>
          <path d="M10,19H13V22H10V19M12,2C17.35,2.22 19.68,7.62 16.5,11.67C15.67,12.67 14.33,13.33 13.67,14.17C13,15 13,16 13,17H10C10,15.33 10,13.92 10.67,12.92C11.33,11.92 12.67,11.33 13.5,10.67C15.92,8.43 15.32,5.26 12,5A3,3 0 0,0 9,8H6A6,6 0 0,1 12,2Z" />
        </SvgIcon>
      )
    case 'phone':
      return (
        <SvgIcon className={getClass()}>
          <path d="M6.62,10.79C8.06,13.62 10.38,15.94 13.21,17.38L15.41,15.18C15.69,14.9 16.08,14.82 16.43,14.93C17.55,15.3 18.75,15.5 20,15.5A1,1 0 0,1 21,16.5V20A1,1 0 0,1 20,21A17,17 0 0,1 3,4A1,1 0 0,1 4,3H7.5A1,1 0 0,1 8.5,4C8.5,5.25 8.7,6.45 9.07,7.57C9.18,7.92 9.1,8.31 8.82,8.59L6.62,10.79Z" />
        </SvgIcon>
      )
  }
}

export default RrIcon
