import { useStore } from 'react-redux'
import type appStore from 'Shared/store'
import campaign from 'Shared/hooks/campaign'
import campaignBuilder from 'Shared/hooks/campaignBuilder'
import createCampaign from 'Shared/hooks/createCampaign'
import emailBuilder from 'Shared/hooks/emailBuilder'
import notifications from 'Shared/hooks/notifications'
import reporting from 'Shared/hooks/reporting'
import scriptBuilder from 'Shared/hooks/scriptBuilder'
import searchBuilder from 'Shared/hooks/searchBuilder'
import search from 'Shared/hooks/search'
import sendBlast from 'Shared/hooks/sendBlast'
import shareCampaign from 'Shared/hooks/shareCampaign'
import targetProfile from 'Shared/hooks/targetProfile'
import target from 'Shared/hooks/target'
import local from 'Shared/hooks/local'
import user from 'Shared/hooks/user'
import restClient from 'Shared/hooks/restClient'
import email from 'Shared/hooks/email'
import history from 'Shared/hooks/history'
import emailTemplate from 'Shared/hooks/emailTemplate'
import analytics from 'Shared/hooks/analytics'
import cannedResponses from './cannedResponses'

const actionSlices = {
  campaign,
  campaignBuilder,
  createCampaign,
  emailBuilder,
  notifications,
  reporting,
  searchBuilder,
  search,
  sendBlast,
  shareCampaign,
  targetProfile,
  target,
  local,
  user,
  email,
  history,
  emailTemplate,
  analytics,
  cannedResponses,
}

export default () => {
  const dis = useDis()
  const store: typeof appStore = useStore()

  return useMemo(
    () =>
      _.mapValues(actionSlices, (fn) => {
        return fn(dis, store, restClient)
      }),
    []
  )
}
