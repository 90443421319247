import NodeSidebar from 'Workflows/components/Builder/Sidebars/NodeSidebar'
import BranchSidebar from 'Workflows/components/Builder/Sidebars/BranchSidebar'

export default function Sidebar() {
  const kind = useSel(s => s.local.sidebar.kind)

  return <div>
    { kind === 'editNode' && <NodeSidebar /> }
    { kind === 'editBranch' && <BranchSidebar /> }
  </div>
}
