import { useStore } from 'react-redux'
import billingData from 'ManageBilling/hooks/billingData'
import usageData from 'ManageBilling/hooks/usageData'
import restClient from 'Shared/hooks/restClient'

const actionSlices = {
  billingData,
  usageData,
}

export default () => {
  const dis = useDis()
  const store = useStore()

  return useMemo(
    () => _.mapValues(actionSlices, (fn) => {
      return fn(dis, store, restClient)
    }),
    []
  )
}

