import * as React from 'react'

function SvgClose(props) {
  return (
    <svg width={22} height={22} fill="current" stroke="current" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect
        x={4.813}
        y={15.813}
        width={15.556}
        height={1.945}
        rx={0.972}
        transform="rotate(-45 4.813 15.813)"
        fill="currentColor"
      />
      <rect
        x={6.188}
        y={4.813}
        width={15.556}
        height={1.945}
        rx={0.972}
        transform="rotate(45 6.188 4.813)"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgClose
