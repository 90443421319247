import RrIcon from './RrIcon'

const PAGE_SIZE = 10

const Paginator = ({ data, onChange, pageSize = PAGE_SIZE }) => {
  const [page, setPage] = useState(0)

  useEffect(() => {
    if (data) update()
  }, [data, page])

  const update = (clear) => {
    if (clear) return []
    else onChange(data.slice(page * pageSize, (page + 1) * pageSize))
  }

  if (!data || data.length === 0) return null
  const lastPage = page === Math.floor(data.length / pageSize) - 1

  return (
    <div className="paginator">
      <button
        className={`button naked no-outline ${page === 0 ? 'inactive' : ''}`}
        onClick={() => setPage(page - 1)}
      >
        <RrIcon iconName="left" classes={page === 0 ? 'light-grey' : 'blue'} />
      </button>
      <div>
        <b className="current-page">{page + 1}</b> <b className="dark-grey">of 10</b>
      </div>
      <button
        className={`button naked no-outline ${lastPage ? 'inactive' : ''}`}
        onClick={() => setPage(page + 1)}
      >
        <RrIcon iconName="right" classes={lastPage ? 'light-grey' : 'blue'} />
      </button>
    </div>
  )
}

export default Paginator
