import IconBox from 'Shared/components/IconBox'

export default function MoneyIcon({ size = 130 }) {
  return (
    <IconBox size={size}>
      <SvgIconsMoney />
    </IconBox>
  )
}

