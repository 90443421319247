import restClient from 'Shared/hooks/restClient'
import Loading from 'Shared/components/Loading'
import Context from 'CampaignVolunteer/Context'

export default function OrganisationChooser() {
  const { state, dispatch, actions } = useContext(Context)
  const { campaign, organisationKinds, externalType, user } = state
  const { chooseOrg } = actions

  useEffect(() => {
    restClient.get(`/api/organisations/schema`)
      .then((response) => dispatch({ organisationKinds: response.data }))
  }, [])

  // group into rows of 3
  const rows = _.chunk(organisationKinds, 4);

  return <div>
    <h2>{ i18n.t('user.campaigns.choose_organisation') }</h2>
    { !externalType && rows.map((row, i) => <div className='row margin-bottom' key={i}>
      { row.map((organisationKind) => {
        return <div className='column quarter' key={organisationKind.id}>
          <a className='card large selectable' onClick={() => dispatch({ externalType: organisationKind.externalType })}>
            <div className='icon large purple'><SvgIconsSmsBlast /></div>

            <p className='title'>{ organisationKind.label }</p>
          </a>
        </div>
      }) }
    </div>) }
    { !externalType && <div>
      <div className='margin-bottom'>{ i18n.t('user.campaigns.no_organisation_notice', { client_name: user.clientName }) }</div>
      <div><a className='button primary' onClick={() => dispatch({ stage: 'startCampaign' })}>Choose for me</a></div>
     </div> }
    { externalType && <p><a className='button naked' onClick={() => dispatch({ externalType: null })}>Back</a></p> }
    { externalType && <OrganisationSearcher /> }
  </div>
}

function OrganisationSearcher() {
  const { state, dispatch, actions } = useContext(Context)
  const { campaignId, externalType, organisations } = state

  const [query, setQuery] = useState('')
  const [loading, setLoading] = useState(false)

  const dSearch = useMemo(() => _.debounce((query) => {
    restClient.get(`/api/campaigns/${campaignId}/search_organisations`, { params: { query, externalType }})
      .then((response) => dispatch({ organisations: response.data }))
      .finally(() => setLoading(false))
  }, 500), [])

  useEffect(() => {
    if (query && query.length < 3) return
    setLoading(true)
    dSearch(query)
  }, [query])

  return <div>
    <p>{ i18n.t('user.campaigns.only_available_organisations') }</p>
    <input type='search' onChange={e => setQuery(e.target.value || '')} />
    { loading && <Loading /> }
    <table className='nice-table'>
      <tbody>
        { !loading && organisations.map((organisation) => {
          return <tr key={organisation.id}>
            <td>
              { organisation.name }
            </td>
            <td>
              <a className='button' onClick={() => actions.chooseOrg(organisation.id)}>{ i18n.t('shared.messages.choose') }</a>
            </td>
          </tr>
        }) }
      </tbody>
    </table>

  </div>
}
