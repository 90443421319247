import _ from 'lodash'

const columns = [
  {
    data: 'name',
    className: 'bold',
    width: '30%',
    orderable: false,
    render: (data, type, row, meta) => {
      const name = _.isEmpty(data.trim()) ? 'No name' : data
      const text = row.organisations === '' ? `${name} (deactivated)` : name
      return `<a href="/admin/users/${row.DT_RowId}">${text}</a>`
    },
  },
  { data: 'email', width: '33%' },
  {
    data: 'roles',
    width: '27%',
    orderable: false,
    render: (data, type, row, meta) => {
      if (row.organisations !== '') {
        return `${row.roles} <span class='ellipsis accent'></span> ${row.organisations}`
      } else {
        return row.roles
      }
    },
  },
  {
    data: 'status',
    width: '10%',
    orderable: false,
    render: function(data, type, row, meta) {
      const status = row.organisations === '' ? 'inactive' : 'active'
      function getClass() {
        if (status === 'active') return 'green'

        return 'accent'
      }
      return `<span class="bold ${getClass()} capitalize">${status}</span>`
    },
  },
]

const headers = ['Name', 'Email', 'Roles', 'Status']

const filters = [
  { name: 'User role', kind: 'async', entity: 'role' },
  { name: 'Organisation', kind: 'async', entity: 'organisation' },
  {
    name: 'Status',
    column: 3,
    kind: 'radio',
    options: [
      { value: '', label: 'All' },
      { value: 'active', label: 'Active' },
      { value: 'inactive', label: 'Inactive' },
    ],
  },
]

const sorters = []

const order = [[1, 'asc']]

export { columns, headers, filters, sorters, order }
