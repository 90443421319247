const columns = [
  {
    "data": "name",
    "className": "bold",
    "render": function(data, type, row, meta) {
      return `<a href='${row['url']}'>${data}</a>`;
    }
  },
  {
    "data": "phone_number",
  }
]

const headers = [
  'Name',
  'Phone Number'
]

const filters = [
]

const sorters = [
]

const order = [[ 0, "asc" ]]

export { columns, headers, filters, sorters, order }
