import useAct from 'SurveyBuilder/hooks/useAct'
import Loading from 'Shared/components/Loading'
import EditableName from 'Shared/components/EditableName/EditableName'
import Script from './Steps/Script'
import Audience from './Steps/Audience'
import PreviewScript from './Steps/PreviewScript'

export default function Builder({ surveyId, surveysIndexUrl }) {
  const act = useAct()

  useEffect(() => {
    act.survey.load(surveyId)
  }, [])

  const { loaded } = useSel((s) => s.builder)
  const { name, status } = useSel((s) => s.survey)


  if (!loaded) {
    return <Loading />
  }

  return (
    <div className="builder">
      <div className="header editable-name-container margin-bottom double">
        <EditableName
          modelName="survey"
          name={name}
          endpoint={`/api/surveys/${surveyId}`}
        />
        <div className="actions">
        </div>
      </div>

      <div className="steps">
      {status === 'draft' && <Audience />}
        <Script />
        {status === 'draft' && <PreviewScript />}
        {status !== 'draft' && <button className="button secondary margin-top" onClick={() => window.location.href = `overview`}>
            <SvgIconsBack style={{ minHeight: 12 }} />
            Back to survey
          </button>}
      </div>

    </div>
  )
}
