export default function Italic(props) {
    return (
        <svg
            width={4}
            height={10}
            viewBox="0 0 4 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M1.83996 3.00016H3.17329L1.70662 9.66683H0.373291L1.83996 3.00016ZM2.95996 0.333496C2.8281 0.333496 2.69921 0.372595 2.58958 0.44585C2.47994 0.519104 2.3945 0.623223 2.34404 0.745041C2.29358 0.866858 2.28038 1.0009 2.3061 1.13022C2.33182 1.25954 2.39532 1.37833 2.48855 1.47157C2.58179 1.5648 2.70058 1.6283 2.8299 1.65402C2.95922 1.67974 3.09326 1.66654 3.21508 1.61608C3.3369 1.56562 3.44102 1.48018 3.51427 1.37054C3.58752 1.26091 3.62662 1.13202 3.62662 1.00016C3.62662 0.823352 3.55639 0.653783 3.43136 0.528758C3.30634 0.403734 3.13677 0.333496 2.95996 0.333496Z"
                fill="currentColor"
            />
        </svg>
    )
}
