import restClient from 'Shared/hooks/restClient'

export default function Task({ id }) {
  const [data, setData] = useState({})
  const { status, progress, total, name } = data
  const percentage = total ? 100 * progress / total : 0

  useEffect(() => {
    function poll() {
      restClient.get(`/api/tasks/${id}`)
      .then(({ data }) => setData(data))
    }
    const interval = setInterval(poll, 1000)
    return () => clearInterval(interval)
  }, [id])

  return <div className="progress-bar margin-bottom">
      <div className="progress-bar-info">
        <div className="progress-bar-info-block --left">
          {name}: Processed {progress} out of {total}
        </div>
        <div className="progress-bar-info-block --right">
          {Math.round(percentage, 1)}%
        </div>
      </div>
      <div className="progress-bar-bar-container">
        <div
          className="bar"
          style={{
            width: `${percentage}%`,
          }}
        ></div>
      </div>
    </div>
}
