import * as React from 'react'

function SvgConversations(props) {
  return (
    <svg width={48} height={49} viewBox={'0 0 48 49'} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path fillRule="evenodd" clipRule="evenodd" d="M45.192 37.6421C45.5076 37.9577 46.0471 37.7331 46.0455 37.2868L46.0078 26.7105V11.5526C46.0078 8.76214 43.7457 6.5 40.9552 6.5H17.3763C14.5858 6.5 12.3236 8.76214 12.3236 11.5526V18.5H29C30.6569 18.5 32 19.8431 32 21.5V31.7632H39.313L45.192 37.6421Z" fill="currentColor"/>
      <path opacity="0.3" fillRule="evenodd" clipRule="evenodd" d="M3.9701 36.5V26.5C3.9701 24.2909 5.76096 22.5 7.9701 22.5H23.9701C26.1792 22.5 27.9701 24.2909 27.9701 26.5V36.5C27.9701 38.7091 26.1792 40.5 23.9701 40.5H8.20156L4.86813 43.6905C4.55013 43.9949 4.02241 43.7695 4.02241 43.3293V37.1485C3.98799 36.9374 3.9701 36.7208 3.9701 36.5ZM12 29C12 28.7239 12.2239 28.5 12.5 28.5H23.5C23.7761 28.5 24 28.7239 24 29V30C24 30.2761 23.7761 30.5 23.5 30.5H12.5C12.2239 30.5 12 30.2761 12 30V29ZM18.5 32.5C18.2239 32.5 18 32.7239 18 33V34C18 34.2761 18.2239 34.5 18.5 34.5H23.5C23.7761 34.5 24 34.2761 24 34V33C24 32.7239 23.7761 32.5 23.5 32.5H18.5Z" fill="currentColor"/>
    </svg>
  )
}

export default SvgConversations

