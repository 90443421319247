export default function LinesClosed() {
  return <div className={`box`}>
    <div className='part'>
      <div className='type'><h2 className='no-margin-top'>{ i18n.t('user.campaigns.phone.lines_are_closed') }</h2></div>
      <div className='content'>
        <p>{ i18n.t('user.campaigns.phone.lines_are_closed_desc') }</p>
        <div className='buttons'>
          <a className='button secondary' href="/">{ i18n.t('shared.messages.finish') }</a>
        </div>
      </div>
    </div>
  </div>
}
