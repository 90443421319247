import { FC } from 'react'
import ListEditor from '../../ListEditor'
import { BlockType } from '../Block'
import './UnorderedListBlock.scoped.scss'

const UnorderedListBlock: FC<{
  block: BlockType
  active?: boolean
}> = ({ block, active }) => {
  if (active) {
    return <ListEditor block={block} tag="ul" />
  }

  return (
    <ul>
      {(block.parameters.items || []).map((item, index) => (
        <li key={index} dangerouslySetInnerHTML={{ __html: item }}></li>
      ))}
    </ul>
  )
}

export default UnorderedListBlock
