import { newrelic } from 'lib/newrelic'

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  componentDidCatch(error, { componentStack }) {
    let boundaryError = new Error(error.message)
    boundaryError.name = `React ErrorBoundary ${boundaryError.name}`
    boundaryError.stack = componentStack

    error.cause = boundaryError

    newrelic?.noticeError(error)
  }

  render() {
    if (this.state.hasError) {
      return (
        <p>
          An error has occurred. Please try again, and if you continue to encounter the
          same issue, report this to support.
        </p>
      )
    }

    return this.props.children
  }
}
