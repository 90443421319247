const surveyTaker = (dis, store, client) => {

  function load(surveyTakerId) {
    const surveyId = store.getState().survey.id
    return client.get(`/api/target/surveys/${surveyId}/survey_takers/${surveyTakerId}`).then(({data}) => {
      upsert(data)
      return data
    })
  }

  function begin() {
    const surveyId = store.getState().survey.id
    return client.post(`/api/target/surveys/${surveyId}/survey_takers/begin`).then(({data}) => {
      upsert(data)
      return data
    })
  }

  function back() {
    const { surveyTaker: {id: surveyTakerId}, survey: {id: surveyId} } = store.getState()
    return client.post(`/api/target/surveys/${surveyId}/survey_takers/${surveyTakerId}/back`).then(({data}) => {
      upsert(data)
      return data
    })
  }

  function complete() {
    const { surveyTaker: {id: surveyTakerId}, survey: {id: surveyId} } = store.getState()

    return client.post(`/api/target/surveys/${surveyId}/survey_takers/${surveyTakerId}/complete`).then(({data}) => {
      upsert(data)
      return data
    })
  }

  function upsert(surveyTaker) {
    dis({ type: 'surveyTaker/upsert', payload: surveyTaker })
  }

  async function answerQuestion(questionId, answer) {
    const { surveyTaker: {id: surveyTakerId}, survey: {id: surveyId} } = store.getState()

    const path = `/api/target/surveys/${surveyId}/survey_takers/${surveyTakerId}/answer_question`
    return client.post(path, {questionId, answer}).then(({data}) => {
      dis({ type: 'surveyTaker/upsert', payload: data })
      return data
    })
  }

  return { load, begin, back, answerQuestion, complete }
}

export default surveyTaker
