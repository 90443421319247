import AjaxNumber from "Shared/components/AjaxNumber"

export default function Stat({ number, label, icon }) { 
  return <div className="stat">
    <div className="icon">
      { icon }
    </div>
    <div className="content">
      <div className='number'>
        <span className='big-number'>{ (number || 0).toLocaleString('en-US') }</span>
      </div>
      <div className="label">
        <p>{ label }</p>
      </div>
    </div>
  </div>
}
