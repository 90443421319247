import Step from './Step'
import validateSenderName from 'CampaignBuilder/validateSenderName'

function FromShortDescriptor({ complete, senderType, senderName }) {
  return (
    <div>
      {complete && (
        <p className="no-margin-bottom">
          {senderType === 'phone_number' && (
            <span>This text message will be sent from a phone number.</span>
          )}
          {senderType === 'alphanumeric' && (
            <span>
              From: <span className="bold">{senderName}</span>
            </span>
          )}
        </p>
      )}
      {!complete && (
        <p className="incomplete no-margin-bottom">Who does the text come from?</p>
      )}
    </div>
  )
}

const From = () => {
  const dis = useDis()
  const { textMessageBlast } = useSel((s) => s.campaign)
  const { senderName, senderType } = textMessageBlast
  const invalidName = senderType === 'alphanumeric' && !validateSenderName(senderName)
  const complete = !!senderType && !invalidName

  function preview() {
    return senderType === 'phone_number' ? (
      <span>You've received a message from 60108</span>
    ) : (
      <span>You've received a message from {senderName}</span>
    )
  }

  return (
    <Step
      name="From"
      editor="from"
      complete={complete}
      validationErrors={
        invalidName
          ? [
              'Sender names can not contain any special characters, and must be under 12 characters long.',
            ]
          : []
      }
      shortDescriptor={
        <FromShortDescriptor
          complete={complete}
          senderType={senderType}
          senderName={senderName}
        />
      }
    >
      <div className="row">
        <div className="column half">
          <div className="radio-list">
            <label
              className="radio"
              onClick={() =>
                dis({
                  type: 'campaign/upsert',
                  payload: {
                    textMessageBlast: {
                      ...textMessageBlast,
                      ...{ senderType: 'phone_number' },
                    },
                  },
                })
              }
            >
              <div className="control">
                <input
                  type="radio"
                  name="sender"
                  checked={senderType === 'phone_number'}
                />
              </div>
              <div className="label">
                <p className="bold">Phone number</p>
                <p className="thin">Use one of the phone numbers available to you.</p>
              </div>
            </label>
            <label
              className="radio"
              onClick={() =>
                dis({
                  type: 'campaign/upsert',
                  payload: {
                    textMessageBlast: {
                      ...textMessageBlast,
                      ...{ senderType: 'alphanumeric' },
                    },
                  },
                })
              }
            >
              <div className="control">
                <input
                  type="radio"
                  name="sender"
                  checked={senderType === 'alphanumeric'}
                />
              </div>
              <div className="label">
                <p className="bold">Named sender</p>
                <p className="thin">
                  Use a short label which will appear in the from field, instead of a
                  number. This can be a maximum of 11 characters. Note that people cannot
                  respond to a named sender, so it is only useful for one-way
                  communication.
                </p>

                {senderType === 'alphanumeric' && (
                  <div className="field margin-top">
                    <input
                      onChange={(e) =>
                        dis({
                          type: 'campaign/upsert',
                          payload: {
                            textMessageBlast: {
                              ...textMessageBlast,
                              ...{ senderName: e.target.value },
                            },
                          },
                        })
                      }
                      type="text"
                      maxLength="11"
                      value={senderName}
                    />
                  </div>
                )}
              </div>
            </label>
          </div>
          <div className="tip margin-top">
            <div className="icon">
              <SvgIconsTip />
            </div>
            <p>
              When you don't need to capture replies, using a named sender can make it
              clearer who's texting and improve click rates.<br />Using a sender name will 
              mean your text messages send slower, unless you've verified the sender name 
              ahead of time with Movement support. By default, each new sender name can 
              send 36,000 messages per hour.
            </p>
          </div>
        </div>
        <div className="column half">
          <div className="phone half">
            <SvgPhone />
            <div className="header">
              <span>Notifications</span>
            </div>
            <div className="preview">{preview()}</div>
          </div>
        </div>
      </div>
    </Step>
  )
}

export default From
