import count from '../../../lib/sms-length'
import Tip from '../../../images/icons/Tip'

export default function CharacterCounter({ content, condensed = false }) {
    const { length, characterPerMessage, remaining, messages:smsCount, encoding } = count(content)

    return <>
        <div className={`character-counter ${condensed ? 'condensed' : ''}`}>
            { !condensed && <label>Text message</label> }
            <p className='count'>
                {smsCount} message{smsCount > 1 ? 's' :''} <span className='ellipsis narrower'></span> {remaining} / {characterPerMessage} characters (est.)
            </p>
        </div>
        { (encoding === 'UTF16' && !condensed) && <p><Tip /> Using special characters such as emojis will reduce the available characters per message.</p> }
    </>
}
