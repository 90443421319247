import './SpinbuttonField.scoped.scss'
import AddIcon from '@material-ui/icons/Add'
import RemoveIcon from '@material-ui/icons/Remove'
import Button from './Button'

const SpinbuttonField = ({ label, onChange, id, ...props }) => {
  const inputRef = useRef(null);
  const handleChange = (e) => {
    onChange && onChange(e.target.value)
  }
  const stepDown = () => { 
    inputRef.current.stepDown() 
    inputRef.current.dispatchEvent(new Event('input', { bubbles: true }))
  }
  const stepUp = () => { 
    inputRef.current.stepUp()
    inputRef.current.dispatchEvent(new Event('input', { bubbles: true }))
  }

  return (
    <div className="field spinbutton-field" id={id}>
      {label && <label>{label}</label>}
      <div className="spinbutton-control">
        <Button type="button" extraClass="small-icon-button spin-button" small primary onClick={stepDown}><RemoveIcon /></Button>
        <input type="number" ref={inputRef} onChange={handleChange} {...props} />
        <Button type="button" extraClass="small-icon-button spin-button" small primary onClick={stepUp}><AddIcon /></Button>
      </div>
    </div>
  )
}

export default SpinbuttonField
