import { Provider } from 'react-redux'
import PageContainer from 'CampaignOverview/components/PageContainer'
import store from 'Shared/store'

export default function CampaignOverview(props) {
  return (
    <div>
      <Provider store={store}>
        <PageContainer {...props} />
      </Provider>
    </div>
  )
}
