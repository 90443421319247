import Step from './Step'
import EmailPreview from 'Shared/components/EmailPreview'
import useEmailPreview from 'Shared/hooks/useEmailPreview'
import './EmailContent.scoped.scss'

const EmailContentShortDescriptor = ({ complete }) => {
  return (
    <div>
      {complete &&
        <p className='no-margin-bottom'>
          Complete
        </p>
      }
      {!complete && <p className='incomplete no-margin-bottom'>What does the email say?</p>}
    </div>
  )
}

const EmailContent = () => {
  const emailBuilderPath = useSel((s) =>`/admin/campaigns/${s.campaign.id}/email_blasts/${s.campaign.emailBlast.id}/content`)
  const blockCount = useSel((s) => s.blocks.present.ids.length)
  const complete = !!blockCount

  const [html, loading] = useEmailPreview()

  return <Step
    name='Content'
    editor='emailContent'
    complete={complete}
    shortDescriptor={ <EmailContentShortDescriptor complete={complete} /> }
    showDiscard={false}
    showSave={false}
    showClose
  >
    <div className="main">
      <div className='row'>
        <div className='column half'>
          <div className="email-details">

            {complete && <p>
              You&rsquo;ve designed an email with {blockCount === 1 ? '1 block' : `${blockCount} blocks`} of content
            </p>}

            {!complete && <p>Your email hasn&rsquo;t been designed yet.</p>}

            <p><a href={emailBuilderPath} className="button primary">Launch email designer</a></p>
          </div>
        </div>
        { complete && <div className='column half'>
          <div className="preview-container">
            {!loading && <EmailPreview html={html} iframeStyle={{transform: 'scale(0.5)'}} />}
          </div>
        </div> }
      </div>
    </div>
  </Step>
}

export default EmailContent
