const columns = [
  {
    "data": "name",
    "width": "20%",
    "className": "bold",
    "render": function(data, type, row, meta) {
      return `<a href='${row['url_path']}'>${data}</a>`
    }
  },
  {"data": "url", "width": "40%",},
  {
    "data": "topics",
    "width": "30%",
    "render": function(data, type, row, meta) {
      if (data) {
        return data.split(', ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(', ').replace(/_/g, ' ')
      }
      return data
    }
  },
  {
    "data": "active",
    "width": "10%",
    "render": function(data, type, row, meta) {
      data = data.toLowerCase()
      return data === 'true' ? 'Yes' : 'No'
    }
  },
]

const headers = [
  'Name',
  'URL',
  'Topics',
  'Active',
]

const filters = [
]

const sorters = [
]

const order = [[ 0, "asc" ]]

export { columns, headers, filters, sorters, order }
