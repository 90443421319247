import Fade from '@material-ui/core/Fade'
import InfoSection from './InfoSection/InfoSection'
import Toggle from 'react-toggle'
import { Structure } from './InfoSection/Structure'
import Grid from '@material-ui/core/Grid'
import restClient from 'Shared/hooks/restClient'
import './ExtendedInfo.scoped.scss'

const Subscription = ({ id, value, kind, name, updatedAt }) => {
  const [checked, setChecked] = useState(value)
  const [saving, setSaving] = useState(false)
  const handleChange = (e) => {
    setSaving(true)
    setChecked(e.target.checked)
    restClient
      .put(`/api/subscriptions/${id}`, { subscription: { subscribed: e.target.checked } })
      .then((r) => {
        setChecked(r.data.subscribed)
        setSaving(false)
      })
      .catch((e) => {
        console.error(e)
        setSaving(false)
      })
  }
  const formattedDate = new Date(updatedAt).toLocaleDateString('default')
  return (
    <div className="toggle-container">
      <Toggle name={kind} checked={checked} disabled={saving} onChange={handleChange} />
      <span className="ml-2 text-sm text-gray-600">Last changed: {formattedDate}</span>
    </div>
  )
}

const renderSubscriptions = (subscriptionStatuses) => (
  <>
    <p className="larger bold capitalise no-margin-top">Subscriptions</p>
    {subscriptionStatuses.map((subscription, i) => (
      <Structure
        key={i}
        identifier={subscription.label}
        data={<Subscription {...subscription} />}
      />
    ))}
  </>
)

const ExtendedInfo = () => {
  const target = useSel((s) => s.target)
  const subscriptionStatuses = useSel((s) => s.target.subscriptionStatuses)

  return (
    <Fade in={!_.isEmpty(target)}>
      {_.isEmpty(target) ? (
        <div />
      ) : (
        <div className="box extended-info">
          {target.sections.map((section, idx) => (
            <Grid item container key={section.title}>
              <InfoSection section={section} />
              {idx < target.sections.length - 1 && (
                <div className="divider" style={{ margin: '40px 0 30px 0' }} />
              )}
            </Grid>
          ))}

          <div className="divider"></div>
          {renderSubscriptions(subscriptionStatuses)}
        </div>
      )}
    </Fade>
  )
}

export default ExtendedInfo
