const errorStyle = { color: '#ef1106' }
const helpLink = () => <a style={{ fontSize: 'inherit', ...errorStyle }} href="https://changelab.freshdesk.com/support/solutions/articles/101000461277-troubleshoot-issues-with-calling" target="_blank">More information</a>

const DeviceError = ({ error }) => {
  const getHelptext = () => {
    switch (error) {
      case 'DOMException: Permission denied':
      case 'NotAllowedError: Permission denied':
        return (
          <small>
            The browser can't access your device. Change your permissions and reload this
            page. {helpLink()}
          </small>
        )
      case 'DOMException: Could not start video source':
      case 'NotReadableError: Could not start video source':
        return (
          <small>
            Your device is being used by another program - close it then reload this page. {helpLink()}
          </small>
        )
      case 'NotAllowedError: The request is not allowed by the user agent or the platform in the current context.':
        return (
          <small>
            You must grant permission to use your microphone. {helpLink()}
          </small>
        )
      default:
        return (
          <small>
            There was a problem accessing your microphone. {helpLink()}
          </small>
        )
    }
  }

  return <div style={errorStyle}>{getHelptext()}</div>
}

export default DeviceError
