import * as React from 'react'

function SvgSearch(props) {
  return (
    <svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M11.91 13.923a.833.833 0 011.18-1.179l3.332 3.333a.833.833 0 11-1.178 1.179l-3.333-3.333z"
        fill="#8792A2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.333 9.167a5.833 5.833 0 1011.667 0 5.833 5.833 0 00-11.667 0zm10 0a4.167 4.167 0 11-8.333 0 4.167 4.167 0 018.333 0z"
        fill="#8792A2"
      />
    </svg>
  )
}

export default SvgSearch
