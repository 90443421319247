import restClient from 'Shared/hooks/restClient'

export default async function waitForDeferredResult(deferredResultId) {
  return new Promise((resolve, reject) => {
    function checkResult() {
      restClient.get(`/api/deferred_results/${deferredResultId}`)
        .then(({ data }) => {
          if (data.status === 'ready') {
            resolve(data.result)
          } else if (data.status === 'failed') {
            reject(data.error)
          } else {
            setTimeout(checkResult, 1000)
          }
        })
        .catch((err) => {
          reject(err.message)
        })
    }
    checkResult()
  })
}
