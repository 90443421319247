import React, { memo } from 'react';
import { Handle, Position } from 'reactflow';
import useAct from 'Workflows/hooks/useAct'
import restClient from 'Shared/hooks/restClient'
import BaseNode from './BaseNode'

function AddToCampaignNode({ id, data }) {
  const act = useAct()
  const [campaign, setCampaign] = useState({})
  const properties = useSel(s => s.workflowNodes.entities[parseInt(id)]?.properties || {})
  const { campaignId } = properties

  useEffect(() => {
    if (!campaignId) return
    restClient.get(`/api/campaigns/${campaignId}`).then(({ data }) => {
      setCampaign(data)
    })
  }, [campaignId])

  function onClick() {
    act.local.setSidebar({ kind: 'editNode', nodeId: parseInt(id) });
  }

  return <BaseNode id={id} kind={'addToCampaign'} onClick={onClick} showCount>
      <p>Add to campaign { campaign.name }</p>

      <Handle type="target" position={Position.Top} />
      <Handle type="source" position={Position.Bottom} />
  </BaseNode>
}

export default memo(AddToCampaignNode);