import Button from 'Shared/components/Button'
import Modal from 'Shared/components/Modal/Modal'
import CopyToClipboard from 'Shared/components/CopyToClipboard'
import restClient from 'Shared/hooks/restClient'


export default function Actions() {
  const [exportPassword, setExportPassword] = useState(null)

  const doExport = () => {
    restClient.post(`/api/users/export`)
      .then(({ data: { password } }) => { setExportPassword(password) })
  }

  return (
    <>
      <Button secondary onClick={doExport}>
        <SvgIconsDownload />
        Export
      </Button>

      <Modal open={!!exportPassword} title="Export in progress" onCancel={() => setExportPassword(null)}>
        The user export is in progress. You'll receive an email when it's done.
        Use this password to open it:
        &nbsp;<CopyToClipboard text={`${exportPassword} (click to copy)`} url={exportPassword} />
      </Modal>
    </>
  )
}
