import { formatDistanceToNow, parseISO } from 'date-fns'

export default function TimeAgo({ dateStr }) {
  const [counter, setCounter] = useState(0)
  useEffect(() => {
    const interval = setInterval(() => setCounter(prevCounter => prevCounter + 1), 5000)
    return () => clearInterval(interval);
  }, [])

  if (!dateStr) return null

  const data = parseISO(dateStr)
  const timeAgo = formatDistanceToNow(data, { addSuffix: true })

  return <span className="time-ago">{ timeAgo }</span>
}
