import useData from 'DesktopMessager/useData'
import './TopBar.scoped.scss'
import useAct from 'DesktopMessager/useAct'
import YesNoModal from 'Shared/components/YesNoModal'
import ButtonWithCheckMark from 'Shared/components/ButtonWithCheckMark'
import Button from 'Shared/components/Button'
import BackButton from 'Shared/components/BackButton'
import goalActions from 'Shared/components/GoalButton/actions'

export default function TopBar({ title, noMoreMessages = false }) {
  const act = useAct()
  const { clientName } = useData(rs => rs.user)
  const { id, instructions, name } = useData(rs => rs.campaign)
  const targetId = useData(rs => rs.target.id)
  const data = useData(rs => rs)
  const campaignTargetId = useData(rs => rs.conversation.campaignTargetId)
  const { name: targetName } = useSel(s => s.target || {})

  const goalId = useData(rs => rs.campaign.settings?.goalId)
  const goalName = useData(rs => rs.campaign.settings?.goalName)

  const [optOutOnDone, setOptOutOnDone] = useState(false)
  const [completeGoalOnDone, setCompleteGoalOnDone] = useState(false)
  const [goalComplete, setGoalComplete] = useState(null)

  useEffect(() => {
    if (!targetId) return
    setOptOutOnDone(false)
    if (goalId) {
      setCompleteGoalOnDone(false)
      goalActions.getGoalComplete(campaignTargetId, goalId)
        .then((complete) => setGoalComplete)
    }
  }, [targetId, goalId])

  const [showGoToNextTargetModal, setShowGoToNextTargetModal] = useState(false)

  const currentConversation = useData(s => s.conversation)
  const textMessages = useSel(s =>
    _.values(s.textMessages.entities).filter(
      tm => tm.conversationId === currentConversation?.id
    )
  )
  const lastMessageDirection = _.orderBy(textMessages, 'id', 'desc')[0]?.direction

  const handleAcceptModal = () => {
    setShowGoToNextTargetModal(false)
    goToNext()
  }

  const handleDoneClick = async () => {
    if (lastMessageDirection === 'outbound') {
      goToNext()
    } else {
      setShowGoToNextTargetModal(true)
    }
  }

  const handleSnoozeClick = async () => {
    await act.conversationManager.snooze()
  }

  const goToNext = async () => {
    if (completeGoalOnDone) {
      await goalActions.markGoalComplete(campaignTargetId, goalId)
    }
    if (optOutOnDone) {
      await act.conversationManager.optOut()
    } else {
      act.conversationManager.goToNext()
    }
  }

  return <>
    <YesNoModal
      title={i18n.t('shared.messages.are_you_sure')}
      body={i18n.t('user.campaigns.text.conversation_needs_reply')}
      show={showGoToNextTargetModal}
      onAccept={handleAcceptModal}
      onDecline={() => setShowGoToNextTargetModal(false)}
    />

    <div className={`top-bar ${noMoreMessages ? 'no-more-messages' : ''}`}>
      <BackButton onClick={() => window.history.back()} />
      <h1>{title}</h1>

      {!noMoreMessages && <>
        <div className="target">
          <div className="icon">
            <SvgIconsSms width={20} height={20} />
          </div>
          <h1>{targetName}</h1>
        </div>

        <div className="h-divider" />

        <div className="options">
          {goalId && <ButtonWithCheckMark tick onClick={() => setCompleteGoalOnDone(!completeGoalOnDone)} checked={completeGoalOnDone} disabled={goalComplete}>
            {goalName}
          </ButtonWithCheckMark>}
          <ButtonWithCheckMark cross onClick={() => setOptOutOnDone(!optOutOnDone)} checked={optOutOnDone}>
            {i18n.t('user.campaigns.text.opt_out')}
          </ButtonWithCheckMark>
        </div>

        <div className="h-divider" />

        <div className="snooze"><Button secondary onClick={handleSnoozeClick}>{i18n.t('user.campaigns.text.snooze')}</Button></div>

        <div className="done"><Button primary darken onClick={handleDoneClick}>{i18n.t('user.campaigns.text.done_go_next')}</Button></div>
      </>}
    </div>
  </>
}

