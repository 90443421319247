import { ButtonView, Plugin } from 'ckeditor5';

class MergeTagsUI extends Plugin {
    init() {
        const editor = this.editor;

        editor.ui.componentFactory.add('mergeTags', locale => {
            const view = new ButtonView(locale);

            view.set({
                label: 'Merge Tags',
                withText: true,
                tooltip: true,
                isEnabled: true
            });

            view.on('execute', () => {
                const { handleVisibleModal } = editor.config.get('mergeTagsUI');
                handleVisibleModal(true);
            });

            return view;
        });
    }
}

class MergeTagsEditing extends Plugin {
    init() {
        this.editor.commands.add('insertMergeTag', {
            modes: { wysiwyg: 1 },

            execute: (tagText) => {
                const editor = this.editor;
                const selection = editor.model.document.selection;
                const position = selection.getFirstPosition();

                editor.model.change(writer => {
                    const text = writer.createText(tagText);
                    editor.model.insertContent(text, position);
                });
            },

            forceDisabled: () => {
                this.forceDisabled()
            },

            clearForceDisabled: () => {
                this.isEnabled = true
            }
        });
    }
}

export default class MergeTagsPlugin extends Plugin {
    static get requires() {
        return [MergeTagsUI, MergeTagsEditing];
    }
}
