import restClient from 'Shared/hooks/restClient'
import Loading from 'Shared/components/Loading'
import BuildSyncedAudience from 'Advertising/components/BuildSyncedAudience'
import { Build } from '@material-ui/icons'
import ServerWaitButton from 'Shared/components/ServerWaitButtonStandalone'
import TimeAgo from 'Shared/components/TimeAgo'

export default function SyncedAudienceShow({ id }) {
  const [syncedAudience, setSyncedAudience] = useState(null)
  const [customAudience, setCustomAudience] = useState(null)
  const [loading, setLoading] = useState(true)
  const [saveError, setSaveError] = useState(null)

  function load() {
    return restClient.get(`/api/synced_audiences/${id}`)
    .then(({ data }) => {
      setSyncedAudience(data)
      setLoading(false)
    })
  }

  function updateAuthorization() {
    return restClient.post(`/api/synced_audiences/${id}/update_authorizing_user`)
    .then(({ data }) => {
      setSyncedAudience(data)
    }).catch((e) => {
      setSaveError('Error updating authorization')
    })
  }

  useEffect(() => {
    load()
    const interval = setInterval(load, 60000)

    return () => clearInterval(interval)
  }, [])

  if (loading) return <Loading text="Loading audience" />

  const { search, syncInfo, lastSyncedAt, name, status } = syncedAudience
  const targetCount = search.targetCount

  const fbStatus = (() => {
    if (!syncInfo.operationStatus) return "Audience is launching"

    const facebookCode =  syncInfo.operationStatus.code

    if (syncInfo.mostRecentCountLowerBound <= 0) {
      if (facebookCode === 200) {
        return "No one could be matched"
      } else {
        return "The audience is updating"
      }
    } else {
      return "delivering"
    }
  })()

  function Icon() {
    if (syncedAudience.platform === 'facebook') return <SvgIconsFacebook />
    if (syncedAudience.platform === 'google') return <SvgIconsGoogle />
  }

  const Stats = () => {
    const matchedCount = (syncInfo.mostRecentCountLowerBound + syncInfo.mostRecentCountUpperBound) / 2

    return <>
      <div className="stat smaller">
        <div className="icon">
          <Icon />
        </div>
        <div className="content">
          <div className="number">
            <span>
              <span className="dark-grey bold">{ matchedCount }</span> matched users on Facebook
            </span>
          </div>
        </div>
      </div>

      <div className="stat smaller">
        <div className="icon">
          <Icon />
        </div>
        <div className="content">
          <div className="number">
            <span>
              Last synced <span className="dark-grey bold">{ lastSyncedAt ? <TimeAgo dateStr={lastSyncedAt} /> : 'Never' }</span>
            </span>
          </div>
        </div>
      </div>
    </>
  }

  return <div>
    <div className="header editable-name-container">
      <a className='back' href="/admin/advertising"></a>
      <span><Icon /></span>
      <h1 className='align-center'>{ name }</h1>
    </div>

    { saveError && <div className="section banner error">
      { saveError }
    </div> }

    { (status === 'error') && <div className="section banner error">
      Something has gone wrong and the audience is not syncing
    </div> }

    { (status === 'paused') && <div className="section banner warning">
      Syncing is currently paused
    </div> }

    <div className="section">
      <div className="boxes two">
        <div className="box half-padding">
          <div className="stat large">
            <div className="icon">
              <SvgIconsAudience />
            </div>
            <div className="content">
              <div className="number">
                <span className="big-number">{ (targetCount || 0).toLocaleString('en-US') }</span>
              </div>
              <div className="label">
                <p>Number of people</p>
              </div>
            </div>
          </div>
        </div>
        <div className="box half-padding">
          { fbStatus === 'delivering' ? <Stats /> : <div className="stat large">
            <div className="icon">
              <SvgIconsQuestion />
            </div>
            <div className="content">
              <div className="number">
                <span className="big-number"></span>
              </div>
              <div className="label">
                <p>{ fbStatus }</p>
              </div>
            </div>
          </div> }
        </div>
      </div>
    </div>
    <div className="section">
      <div className="box">
        <div>Currently authorised by</div>
        <h3>{ syncedAudience.authorizingUser.name }</h3>
        <ServerWaitButton className="button primary" onClick={updateAuthorization}>Authorise as me</ServerWaitButton>
      </div>
    </div>
    <div className="section">
      <div className="box">
        <BuildSyncedAudience id={id} onReload={load} />
      </div>
    </div>
  </div>
}
