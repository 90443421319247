import { useStore } from 'react-redux'
import builder from './builder'
import survey from './survey'
import search from 'Shared/hooks/search'
import searchBuilder from 'Shared/hooks/searchBuilder'
import scriptBuilder from 'Shared/ScriptEditor/hooks/scriptBuilder'
import restClient from 'Shared/hooks/restClient'

const actionSlices = {
  builder,
  survey,
  scriptBuilder,
  search,
  searchBuilder
}

export default () => {
  const dis = useDis()
  const store = useStore()

  return useMemo(
    () => _.mapValues(actionSlices, (fn) => {
      return fn(dis, store, restClient)
    }),
    []
  )
}
