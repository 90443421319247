import { createSlice } from '@reduxjs/toolkit'
import { generateEntitySlices, generateSlices } from 'Shared/sliceHelpers'

const local = createSlice({
  name: 'local',
  initialState: {
    errors: [],
    sendBusy: false,
    draftMessage: '',
    loaded: false,
    status: null,
    done: false,
    showGoToNextTargetModal: false,
    showOptOutModal: false,
  },
  reducers: {
    upsert: (s, a) => ({ ...s, ...a.payload }),
  },
}).reducer

const entitySliceNames = ['calls', 'users', 'targets', 'campaigns', 'campaignTargets', 'conversations', 'textMessages', 'cannedResponses']
const sliceNames = ['target']

const reducers = {
  local,
  ...generateSlices(sliceNames),
  ...generateEntitySlices(entitySliceNames)
}

export default reducers
