import LineChart from "./LineChart"
import BarChart from "./BarChart"
import './Chart.scoped.scss'
import ChartLegend from "./ChartLegend"

const Chart = ({title, data, lines, setLines, xLabel, yLabel}) => {
  const [chartType, setChartType] = useState('line')

  return (
    <div className="box chart">
      <h3>{title}</h3>

      <ul className="chart-type-toggle">
        <li className={chartType === 'bar' ? 'active' : ''} onClick={() => setChartType('bar')}>
          <SvgIconsBarChart />
        </li>
        <li className={chartType === 'line' ? 'active' : ''} onClick={() => setChartType('line')}>
          <SvgIconsLineChart />
        </li>
      </ul>

      {chartType === 'line' &&
        <LineChart data={data} xLabel={xLabel} yLabel={yLabel} lines={lines.filter((l) => l.enabled)} />
      }
      {chartType === 'bar' &&
        <BarChart data={data} xLabel={xLabel} yLabel={yLabel} bars={lines.filter((l) => l.enabled)} />
      }

      <ChartLegend lines={lines} setLines={setLines} />
    </div>
  )
}

export default Chart
