export default function Underline(props) {
    return (
        <svg
            width={10}
            height={12}
            viewBox="0 0 10 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M9.66671 10.3335V11.6668H0.333374V10.3335H9.66671ZM7.66671 5.81016C7.64476 6.25015 7.51414 6.67785 7.28653 7.05504C7.05893 7.43222 6.74142 7.74714 6.36239 7.97166C5.98336 8.19617 5.5546 8.32328 5.11445 8.34164C4.67429 8.35999 4.23645 8.26901 3.84004 8.07683C3.38313 7.87916 2.99564 7.54958 2.72722 7.13032C2.45879 6.71105 2.32166 6.22119 2.33337 5.7235V0.33683H1.00004V5.81016C1.0226 6.43777 1.19261 7.05125 1.49632 7.60093C1.80003 8.15062 2.2289 8.62108 2.74822 8.97421C3.26754 9.32735 3.86271 9.55325 4.48556 9.63362C5.1084 9.71399 5.74142 9.64657 6.33337 9.43683C7.12078 9.17438 7.80394 8.6676 8.28353 7.99018C8.76311 7.31277 9.01412 6.50004 9.00004 5.67016V0.33683H7.66671V5.81016ZM7.66671 0.333496H9.00004H7.66671ZM2.33337 0.333496H1.00004H2.33337Z"
                fill="black"
            />
        </svg>
    )
}
