const columns = [
  {
    data: 'email',
    className: 'bold',
    render: (data, type, row, meta) => `<a href="/admin/users/${row.DT_RowId}">${data}</a>`,
  },
  {
    data: 'roles',
    render: (data, type, row, meta) =>
      `${row.roles} <span class='ellipsis accent'></span> ${row.organisations}`,
  },
]

const headers = [
  'Email',
  'Roles',
]

const filters = []

const sorters = []

const order = [[ 0, "asc" ]]

export { columns, headers, filters, sorters, order }
