import * as React from 'react'

function SvgSms({ width = 64, height = 64, ...props }) {
  return (
    <svg width={width} height={height} viewBox={"0 0 64 64"} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.334 11a3 3 0 013-3h47.333a3 3 0 013 3v29.026l.067-.026v16.252c0 .891-1.077 1.337-1.707.708L48.067 48H8.334a3 3 0 01-3-3V11zm11.854 13.448a2.667 2.667 0 00-.74 3.698c4.026 6.039 9.275 9.188 15.553 9.188 6.278 0 11.526-3.15 15.552-9.188a2.667 2.667 0 00-4.438-2.958C40.03 29.816 36.39 32 32.001 32c-4.389 0-8.03-2.184-11.115-6.812a2.667 2.667 0 00-3.698-.74z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgSms
