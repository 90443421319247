import "./ToggleNode.scoped.scss"

export default function ToggleNode({ expanded, onClick }) {
  const classNames = ['toggle-node']
  classNames.push(expanded ? 'expanded' : 'collapsed')

  const handleClick = (e) => {
    e.stopPropagation()
    onClick()
  }

  return (
    <div className={classNames.join(' ')} onClick={handleClick}>
      {expanded ? <SvgIconsMinus /> : <SvgIconsPlus />}
    </div>
  )
}

