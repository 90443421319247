export default (dis, store, client) => {
  function setStep(step) {
    return dis({ type: 'searchBuilder/upsert', payload: { step }})
  }

  function toggleTargetTable() {
    const { searchBuilder: { showTargetTable } } = store.getState()
    return dis({ type: 'searchBuilder/upsert', payload: { showTargetTable: !showTargetTable }})
  }

  return { setStep, toggleTargetTable }
}
