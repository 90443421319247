import { renderToString } from 'react-dom/server'

const columns = [
  {
    data: 'name',
    className: 'bold',
    width: '70%',
    render: function (data, type, row, meta) {
      return renderToString(<a href={row['url']}>{data}</a>)
    },
  },
  {
    data: 'state',
    width: '30%',
    render: function (data, type, row, meta) {
      function getClass() {
        const status = row['state']
        if (status === 'running') return 'accent'
        if (status === 'paused') return 'red'

        return 'purple-60'
      }
      return `<span class="bold ${getClass()} capitalize">${row['state']}</span>`
    },
  },
]

const headers = [
    'Name',
    'Status',
]

const filters = [
]

const sorters = [
]

const order = [[ 0, "asc" ]]

export { columns, headers, filters, sorters, order }
