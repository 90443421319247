import Container from 'react-modal-promise'
import Step from '../Step'
import CannedResponsesSettings from './CannedResponsesSettings'
import ListPreview from './ListPreview'

export default function CannedResponses() {
  const act = useAct()
  const {
    id,
    searchId,
    status,
    approvalStatus,
    textMessageBlast: { scheduledFor },
  } = useSel((s) => s.campaign)

  function finish() {
    return act.campaign.persist().then(() => {
      act.sendBlast.open()
    })
  }
  return (
    <Step
      name="Canned Responses"
      editor="cannedResponses"
      shortDescriptor={<ListPreview />}
      staticOpenButton={'Edit'}
      showDiscard={false}
      showSave={false}
      showClose
      complete={true}
    >
      <Container />
      <CannedResponsesSettings />
    </Step>
  )
}
