const NAMES = {
    'add_to_campaign': 'Add to campaign',
    'decision': 'Decision',
    'delay': 'Delay',
    'exit': 'Exit'
}

const ICONS = {
    'add_to_campaign': <SvgIconsMail />,
    'delay': <SvgIconsCalendar />,
    'exit': <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path id="Vector" d="M18.8856 4.44046C19.3149 3.91446 20.0722 3.85049 20.5771 4.2976C21.0821 4.74471 21.1435 5.53357 20.7143 6.05958L10.5143 18.5595C10.0784 19.0938 9.30589 19.1501 8.80278 18.6843L3.40278 13.6843C2.90744 13.2256 2.86282 12.4355 3.30312 11.9195C3.74342 11.4036 4.50191 11.3571 4.99724 11.8157L9.48026 15.9667L18.8856 4.44046Z" fill="#191936"/>
    </svg>,
    'decision': <SvgIconsAutomations />
}

const DESCRIPTIONS = {
    'add_to_campaign': 'Add people to a campaign',
    'decision': 'Create a condition for the next steps',
    'delay': 'Wait some time before the next step',
    'exit': 'End the automation'
}

export { NAMES, ICONS, DESCRIPTIONS }
