import { Popover } from '@varld/popover'
import ProgressBar from 'CampaignStats/components/ProgressBar'
import TimeAgo from 'Shared/components/TimeAgo'
import './Notifications.scoped.scss'

export default function Notifications() {
  const act = useAct()
  const notifications = useSel((s) => s.notifications.notifications || [])

  const showId = useSel((s) => s.notifications.showId)
  const notification = notifications.find((n) => n.id === showId)

  const unread = useSel((s) =>
    s.notifications.notifications
      ? s.notifications.notifications.filter((n) => {
          return n.read === false
        }).length
      : 0
  )
  const [popoverOpen, setPopoverOpen] = useState(false)

  useEffect(() => {
    if (showId && notification.read === false) {
      act.notifications.dismiss(showId)
    }
  }, [showId, notification])

  useEffect(() => {
    const pollInterval = popoverOpen ? 2000 : 10000

    const interval = setInterval(() => {
      act.notifications.refresh()
    }, pollInterval)

    // immediately refresh if popover is open
    if (popoverOpen) {
      act.notifications.refresh()
    }

    return () => clearInterval(interval)
  }, [popoverOpen])

  function dismissAll() {
    act.notifications.dismissAll().then(() => {
      act.notifications.refresh()
    })
  }

  return (
    <div className="notifications">
      <Popover
        popover={({ close, open }) => {

          useEffect(() => {
            setPopoverOpen(open)
          }, [open])

          const rightOffset = -20;
          const popoverStyle = {
            position: 'absolute',
            right: `${rightOffset}px`,
          };

          return (
            <div className="popup-list popup widest height" style={popoverStyle}>
              <div className="list">
                <div className="item title add-side-padding">
                  <SvgIconsBackCaret
                    onClick={(e) => {
                      e.preventDefault()
                      act.notifications.show(null)
                    }}
                    className={`back ${notification ? '' : 'hidden'}`}
                  />

                  <SvgIconsNotificationsClear
                    className={`margin-right margin-left half ${!notification ? '' : 'hidden'}`}
                  />
                  <p className="remove-bold">Notifications</p>
                  <a
                    className={`dismiss-all ${!notification ? '' : 'hidden'}`}
                    onClick={(e) => {
                      e.preventDefault()
                      dismissAll()
                    }}
                  >
                    Dismiss all
                  </a>
                </div>

                {notification && <Notification notification={notification} expanded={true} />}

                <div className={`list ${notification ? 'hidden' : ''}`}>
                  {notifications.map((notification) => <Notification key={notification.id} notification={notification} />) }
                  {notifications.length === 0 && (
                    <div className="item">
                      <div className="content empty">
                        <p className="smaller">
                          There's no notifications for you right now!
                        </p>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )
        }}
      >
        <div className="count" onClick={() => setPopoverOpen(true)}>
          <SvgIconsNotifications
            data-tip="Notifications"
            data-for="notifications"
            className="icon"
          />
          {unread > 0 && (
            <div className="circle">
              <p>{unread}</p>
            </div>
          )}
        </div>
      </Popover>
    </div>
  )
}

// expanded can be used in future to show more info
function Notification({ notification, expanded = false }) {
  const act = useAct()
  const { task } = notification
  const title = task ? `${task.name} - ${task.status}` : notification.name

  return <div
    className={`item clickable ${!expanded ? '' : 'hide-bottom-border'}`}
    href="#"
  >
    <div className="icon smaller margin-bottom double">
      <SvgIconsNotification />
    </div>
    <div className="content smaller">
      <div className="main">
        <div className="main-left">
          <p className={`${notification.read ? '' : 'bold'}`}>
            {title}
          </p>
          <p className="smaller">{task?.body || notification.body}</p>
          { task && task.total && <>
            <p className="smaller"><ProgressBar percent={100 * task.progress / task.total} kind={task.status === 'failed' ? 'failed' : 'normal'} /></p>
            <p className="smaller dark-grey">{task.progress} / { task.total }</p>
          </> }
        </div>
        <div className="main-right">
          <SvgIconsForwardCaret
            onClick={(e) => {
              e.preventDefault()
              act.notifications.show(notification.id)
            }}
            className={`forward ${!expanded ? '' : 'hidden'}`}
          />
        </div>
      </div>
      <div className="smaller text-right dark-grey"><TimeAgo dateStr={task?.createdAt || notification.createdAt} /></div>
    </div>
  </div>
}
