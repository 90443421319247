import ScriptEditor from 'Shared/ScriptEditor/components/ScriptEditor'
import Step from './Step'
import detectCycle from 'CampaignBuilder/detectCycle'
import _ from 'lodash'
import useScriptActions from 'Shared/ScriptEditor/hooks/useAct'
import Loading from 'Shared/components/Loading'
import validate from 'Shared/ScriptEditor/functions/validate'
import ShortDescriptor from 'Shared/ScriptEditor/components/ShortDescriptor'

export default function Script() {
  const act = useAct()
  const scriptActions = useScriptActions()
  const { id: campaignId, searchId, status, campaignType, surveyId } = useSel(s => s.campaign)
  const { mergeTags } = useSel(s => s.campaignBuilder)
  const {
    effort: { id: effortId, name: effortName } = {}
  } = useSel(s => s.campaign)
  const enableOptOut = useSel(s => s.campaign.textMessageBlast && s.campaign.textMessageBlast.enableOptOut)
  const blastKind = useSel(s => s.campaign.textMessageBlast?.kind)
  const questionCount = useSel(s => _.filter(_.values(s.questions.entities), o => _.includes(['multiple_choice', 'multiple_select', 'text', 'score', 'instruction', 'send_sms', 'transfer'], o.questionType)).length)
  const complete = questionCount > 0
  const [loading, setLoading] = useState(false)

  const [validationErrors, setValidationErrors] = useState([])
  const questions = useSel((s) => s.questions.entities)
  const answers = useSel((s) => s.answers.entities)

  useEffect(() => {
    _.defer(() => validate(Object.values(questions), Object.values(answers), setValidationErrors, campaignType))
  }, [questions, answers])

  useEffect(() => {
    setLoading(true)
    scriptActions.scriptBuilder.load(surveyId)
      .then(() => setLoading(false))

    act.campaignBuilder.loadMergeTags()
  }, [])

  function save() {
    if (validationErrors.length > 0) {
      return Promise.resolve(false)
    }

    if (detectCycle(
      Object.values(questions).filter(q => q.active),
      Object.values(answers).filter(a => a.active)
    )) {
      setValidationErrors(["Your script contains a loop which would mean someone could never finish"])
      return Promise.resolve(false)
    }

    return act.campaign.persist().then(function () {
      scriptActions.scriptBuilder.persist(surveyId).then(() => {
        if (searchId) {
          act.campaignBuilder.updateEstimates(searchId)
        }
        act.campaignBuilder.closeEditor()
      })
    })
  }

  function toggleOptOut() {
    act.campaign.upsertTextMessageBlast({ enableOptOut: !enableOptOut })
  }

  const handleDiscardChangesClick = () => {
    scriptActions.scriptBuilder.load(surveyId)
  }

  const missingTemplate = blastKind === 'whatsapp' && questionCount === 0

  const shortDescriptor = missingTemplate ?
    <p className='no-margin-bottom incomplete'>Please select a template first.</p> :
    <ShortDescriptor questionCount={questionCount} isLive={status === 'live'} />

  return <Step
    name='Script & Questions'
    editor='script'
    complete={complete}
    action={save}
    onDiscardChanges={handleDiscardChangesClick}
    shortDescriptor={shortDescriptor}
    validationErrors={validationErrors}
    canClickFinish={validationErrors.length === 0}
    showSave={status !== 'live'}
    showDiscard={status !== 'live'}
    editable={status !== 'live'}
    canOpen={!missingTemplate}
  >
    {campaignId && <>
      {status !== 'draft' && <div className='alert warning margin-bottom'>Editing questions once a campaign is live is not recommended, please keep changes to a minimum.</div>}
      {campaignType === 'sms_survey' && blastKind !== 'whatsapp' &&
        <label className='margin-bottom'><input type='checkbox' checked={enableOptOut} onChange={toggleOptOut} /> Show opt-out message in the first text message</label>
      }

      {loading ?
        <Loading /> :
        <ScriptEditor
          campaignType={campaignType}
          blastKind={blastKind}
          enableOptOut={enableOptOut}
          effortId={effortId}
          effortName={effortName}
          mergeTags={mergeTags}
          isCampaignControlled={true} />}

    </>}
  </Step>
}
