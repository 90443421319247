import { v4 as uuidv4 } from 'uuid'
import actionFactory from 'Shared/hooks/actionFactory'

export default (dis, store, client) => {
  const questionsActions = actionFactory(dis, store, 'question', {}, {
    add: (dis, store, entity) => (data = {}) => {
      const id = uuidv4()
      const state = store.getState()
      const digitOrderMax = _.max(_.values(state.questions.entities).filter(q => q.active).map(q => q.digitOrder))
      const digitOrder = isNaN(digitOrderMax) ? 1 : digitOrderMax + 1
      dis({ type: `questions/upsert`, payload: { id, digitOrder, active: true, ...data } })
      dis({ type: `scriptBuilder/upsert`, payload: { adding: false } })
    },
    markInactive: (dis, store, entity) => (id) => {
      dis({ type: `questions/upsert`, payload: { id, active: false } })

      // Mark the answers inactive too
      const answers = _.values(store.getState().answers.entities)
      answers.filter(a => a.questionId === id).forEach(({ id }) => {
        dis({ type: `answers/upsert`, payload: { id, active: false } })
      })

      // Mark any answers that are pointing at this as 'next_question'
      answers.filter(a => a.nextQuestionId === id && a.action === 'go_to_question').forEach(({ id }) => {
        dis({ type: `answers/upsert`, payload: { id, action: 'next_question', nextQuestionId: null } })
      })

      // Fix digit order
      dis({ type: `questions/fixDigitOrder`, payload: { parentFilter: { active: true } } })
    },
    upsertSettings: (dis, store, entity) => (id, settings) => {
      dis({ type: `questions/upsertSettings`, payload: { id, settings } })
    }
  })

  const answersActions = actionFactory(dis, store, 'answer', {}, {
    add: (dis, store, entity) => (data = {}) => {
      const id = uuidv4()
      const state = store.getState()
      const digitOrderMax = _.max(_.values(state.answers.entities).filter(a => a.active).map(q => q.digitOrder))
      const digitOrder = isNaN(digitOrderMax) ? 1 : digitOrderMax + 1
      dis({ type: `answers/upsert`, payload: { id, digitOrder, active: true, nextQuestionId: null, setsGoal: false, action: 'next_question', ...data } })
    },
    markInactive: (dis, store, entity) => (id) => {
      dis({ type: `answers/upsert`, payload: { id, active: false } })
    }
  })

  function persist(surveyId) {
    const { questions, answers } = store.getState()
    const campaignId = window?.campaignId || ''
    const identifier = window.btoa(campaignId)
    const params = {
      questions: _.values(questions.entities),
      answers: _.values(answers.entities),
      identifier
    }

    return client.post(`/api/surveys/${surveyId}/questions`, params).then(({ data: { questions, answers } }) => {
      questionsActions.setAll(questions)
      answersActions.setAll(answers)
    })
  }

  function load(surveyId) {
    const campaignId = window?.campaignId || ''
    const identifier = window.btoa(campaignId)

    return client.get(`/api/surveys/${surveyId}/questions`, { params: { identifier } })
    .then(({ data: { questions, answers } }) => {
      questionsActions.setAll(questions)
      answersActions.setAll(answers)
    })
  }

  function setAdding(adding) {
    dis({ type: `scriptBuilder/upsert`, payload: { adding } })
  }

  return {
    questions: questionsActions,
    answers: answersActions,
    load,
    persist,
    setAdding
  }
}
