import * as React from 'react'

function SvgQuestion(props) {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path opacity="0.3" fillRule="evenodd" clipRule="evenodd" d="M12.5 22C18.0228 22 22.5 17.5228 22.5 12C22.5 6.47715 18.0228 2 12.5 2C6.97715 2 2.5 6.47715 2.5 12C2.5 17.5228 6.97715 22 12.5 22Z" fill="#00AE6E" />
      <path fillRule="evenodd" clipRule="evenodd" d="M11.0909 13.2091V14.8681H12.3509C14.8919 14.8681 16.8869 12.9151 16.8869 10.3951C16.8869 7.87512 14.8919 5.92212 12.3509 5.92212C9.80994 5.92212 7.83594 7.87512 7.83594 10.3951H9.62094C9.62094 8.79912 10.7339 7.58112 12.3509 7.58112C13.9469 7.58112 15.1019 8.79912 15.1019 10.3951C15.1019 11.9911 13.9469 13.2091 12.3509 13.2091H11.0909ZM12.4999 16.0001C11.9477 16.0001 11.4999 16.4478 11.4999 17.0001C11.4999 17.5524 11.9477 18.0001 12.4999 18.0001C13.0522 18.0001 13.4999 17.5524 13.4999 17.0001C13.4999 16.4478 13.0522 16.0001 12.4999 16.0001Z" fill="#00AE6E" />
    </svg>
  )
}

export default SvgQuestion


