import Modal from 'Shared/components/Modal/Modal'
import CopyToClipboard from 'Shared/components/CopyToClipboard'

export default function ExportConfirmationModal({
  title = 'Export in progress',
  exportPassword,
  setExportPassword,
  entityName = 'report',
}) {

  return (
    <Modal
      open={!!exportPassword}
      title={title}
      onCancel={() => setExportPassword(null)}
    >
      The {entityName} is exporting. You&rsquo;ll receive an email when it&rsquo;s done. Use
      this password to open it: &nbsp;
      <CopyToClipboard
        text={`${exportPassword} (click to copy)`}
        url={exportPassword}
      />
    </Modal>
  )
}
