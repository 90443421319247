
import SmsPreview from './SmsPreview'
import count from 'lib/sms-length'
import './TextMessageContent.scoped.scss'
import ContentEditor from 'CampaignBuilder/components/ContentEditor/ContentEditor'
import CharacterCounter from 'Shared/components/CharacterCounter'
import calculateEstimate from './Audience/calculateEstimate'
import DynamicPrice from 'Shared/components/DynamicPrice'
const MAX_SMS_LENGTH = 1440

export default function TextMessageContentVariant({ id }) {
  const act = useAct()
  const { mergeTags, currentEditor, renderedContent, baseCost, templateRenderError } = useSel(s => s.campaignBuilder)
  const body = useSel(s => s.campaign.textMessageBlast.contentVariants.find(v => v.id === id).content.body)
  const contentVariantsCount = useSel(s => s.campaign.textMessageBlast.contentVariants.length)
  const { length, characterPerMessage, remaining, messages: smsCount } = count(renderedContent.body)

  const blastKind = useSel(s => s.campaign.textMessageBlast.kind)
  const enableOptOut = useSel(s => s.campaign.textMessageBlast.enableOptOut)
  const senderType = useSel(s => s.campaign.textMessageBlast.senderType)

  const senderName = useSel(s =>
    s.campaign.textMessageBlast.senderType === 'phone_number' ?
      s.campaign.client.name : s.campaign.textMessageBlast.senderName
  )
  const editing = currentEditor === 'text'

  const showCharacterWarning = smsCount >= 2 && remaining > 120
  const charactersOverSingleMessage = Math.abs(remaining - characterPerMessage)

  useEffect(() => {
    act.campaignBuilder.renderTemplate()
  }, [body, enableOptOut, senderType])


  function toggleOptOut() {
    act.campaign.upsertTextMessageBlast({ enableOptOut: !enableOptOut })
  }

  return <div className='row'>
    <div className='edit-message margin-bottom'>
      <CharacterCounter content={renderedContent.body} />
      {editing && <ContentEditor availableTags={mergeTags} initialValue={body} onUpdate={(body) => act.campaign.upsertTextMessageBlastContent(id, { body })} />}

      { templateRenderError ? <p className='error'>{templateRenderError}</p> : null }

      {showCharacterWarning &&
        <div className='tip margin-top'>
          <div className='icon'>
            <SvgIconsTip />
          </div>
          <p>Reduce your message by {charactersOverSingleMessage}&nbsp;
            {charactersOverSingleMessage === 1 ? 'character' : 'characters'}&nbsp;
            to save money on your send.</p>
        </div>
      }

      {length >= MAX_SMS_LENGTH &&
        <div className='error margin-top'>
          <p>Max message length is {MAX_SMS_LENGTH} characters.</p>
        </div>
      }

      <div className='margin-top'>
        <label><input type='checkbox' checked={enableOptOut} onChange={toggleOptOut} /> Show opt-out message</label>

        { !enableOptOut && senderType === 'alphanumeric' && <p className='bold error'>
          If you disable opt-out links, and send from a named sender, please make sure your members have an effective way to unsubscribe,
          which has been clearly communicated to them.
        </p> }
      </div>

      { baseCost && contentVariantsCount === 1 && <div className='margin-top'>
        <p className='bold'>Estimated cost: <DynamicPrice usdAmount={Math.round(baseCost * smsCount, 2)} /></p>
        <p className='smaller dark-grey'>
          This is calculated from:&nbsp;
          <DynamicPrice usdAmount={baseCost} textClasses={'smaller dark-grey'} />&nbsp;
          (cost of contacting your audience) x {smsCount} (number of segments)
        </p>
        <p className='smaller dark-grey'>
          Please note that if you use merge tags which include more text, this could increase the cost of your send.
        </p>
      </div> }
    </div>

    <div className='preview'>
      <SmsPreview name={senderName} content={renderedContent} kind={blastKind} />
    </div>
  </div>
}
