export const selectTransactions = (state) => {
  const topUps = state.billingData.creditTopUps.map((topUp) => ({
    id: `topUp-${topUp.id}`,
    originalId: topUp.id,
    date: topUp.paidAt || '9999999999999',
    type: 'credit',
    amount: Number(topUp.amountLocalCurrency),
    hasPdf: topUp.hasPdf,
    hasStripeReceipt: topUp.hasStripeReceipt,
    notes: topUp.notes,
    className: topUp.paidAt ? 'green-row' : '',
  }))

  const usages = state.billingData.creditUsages.map((usage) => ({
    id: `usage-${usage.id}`,
    date: usage.paidAt,
    type: 'debit',
    amount: Number(usage.amountLocalCurrency),
    notes: usage.notes,
  }))

  return [...topUps, ...usages]
}
