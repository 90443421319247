import React from 'react';
import { EditorView, basicSetup } from 'codemirror';
import { EditorState } from '@codemirror/state';
import { html } from '@codemirror/lang-html';
import { css } from '@codemirror/lang-css';
import { javascript } from '@codemirror/lang-javascript';
import { indentUnit } from '@codemirror/language';
import { keymap } from '@codemirror/view';
import { defaultKeymap, indentWithTab } from '@codemirror/commands';

const LINE_HEIGHT = 20; // pixels per line

const CodeEditor = ({
  value,
  onChange,
  mode = 'mixed',
  tabSize = 2,
  maxLines = 10 // New prop for maximum number of lines
}) => {
  const editorRef = useRef(null);
  const viewRef = useRef(null);

  useEffect(() => {
    if (!editorRef.current) return;

    // Choose language support based on mode
    const getLanguageSupport = () => {
      switch (mode) {
        case 'mixed':
          return html({
            // Configure nested language support
            documentFeatures: {
              fullTags: true,
              selfClosingTags: true,
              completeClosingTags: true,
            },
            matchClosingTags: true,
            autoCloseTags: true,
            cssLanguage: css(),
            jsLanguage: javascript(),
          });
        case 'css':
          return css();
        default:
          return html();
      }
    };

    const state = EditorState.create({
      doc: value,
      extensions: [
        basicSetup,
        getLanguageSupport(),
        // Add custom tab behavior
        keymap.of([
          ...defaultKeymap,
          indentWithTab
        ]),
        // Configure indentation
        indentUnit.of(" ".repeat(tabSize)),
        EditorView.lineWrapping,
        EditorView.updateListener.of((update) => {
          if (update.docChanged) {
            onChange(update.state.doc.toString());
          }
        }),
        // Add some basic styling
        EditorView.theme({
          '&': {
            fontSize: '14px',
            height: `${maxLines * LINE_HEIGHT}px`, // Set fixed height based on maxLines
            overflowY: 'auto',
            resize: 'vertical',
            border: '1px solid #ccc',
          },
          '.cm-scroller': {
            overflow: 'auto',
            lineHeight: `${LINE_HEIGHT}px`
          },
          '.cm-content': {
            fontFamily: 'ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, monospace',
            padding: '10px 0',
            tabSize: `${tabSize}`,
            minHeight: '100%'
          },
          '.cm-gutters': {
            backgroundColor: '#f5f5f5',
            color: '#6b7280',
            border: 'none',
            minHeight: '100%'
          },
          '.cm-activeLine': {
            backgroundColor: 'rgba(245, 245, 245, 0.5)'
          },
          // Style scrollbar
          '& ::-webkit-scrollbar': {
            width: '8px',
            height: '8px'
          },
          '& ::-webkit-scrollbar-track': {
            background: '#f1f1f1'
          },
          '& ::-webkit-scrollbar-thumb': {
            background: '#888',
            borderRadius: '4px'
          },
          '& ::-webkit-scrollbar-thumb:hover': {
            background: '#555'
          }
        })
      ],
    });

    const view = new EditorView({
      state,
      parent: editorRef.current,
    });

    viewRef.current = view;

    return () => {
      view.destroy();
    };
  }, [mode]); // Recreate editor when mode changes

  // Update editor content when value prop changes
   useEffect(() => {
    const view = viewRef.current;
    if (!view) return;

    const currentContent = view.state.doc.toString();
    if (currentContent !== value) {
      view.dispatch({
        changes: {
          from: 0,
          to: currentContent.length,
          insert: value
        }
      });
    }
  }, [value]);

  return (
    <div
      ref={editorRef}
    />
  );
};

export default CodeEditor;
