import { set, parseISO, formatISO, format, addDays, isValid } from 'date-fns'

export default function DatetimeSelector({ value, onChange, minDate = true }) {
  const handleDeadlineTimeChange = e => {
    const [hour, minute] = e.target.value.split(':')
    const oldValue = parseISO(value || new Date().toISOString())
    const newValue = set(oldValue, {
      hours: parseInt(hour),
      minutes: parseInt(minute),
    })

    if(isValid(newValue)) {
      onChange(formatISO(newValue))
    }
  }

  const handleDeadlineDateChange = e => {
    const [year, month, day] = e.target.value.split('-')
    const oldValue = parseISO(value || new Date().toISOString())
    const newValue = set(oldValue, {
      year: Math.min(+year, 9999),
      month: parseInt(month) - 1,
      date: parseInt(day),
    })

    if(isValid(newValue)) {
      onChange(formatISO(newValue))
    }
  }

  const valueDate = value ? format(parseISO(value), 'yyyy-MM-dd') : ''
  const valueTime = value ? format(parseISO(value), 'HH:mm') : ''
  const minimumDate = minDate ? format(addDays(new Date(), 1), 'yyyy-MM-dd') : ''

  return (
    <div className="datetime-selector">
      <input
        type="date"
        min={minimumDate}
        value={valueDate}
        onChange={handleDeadlineDateChange}
        className="margin-right half"
      />
      <span className="margin-right half bold">at</span>
      <input type="time" value={valueTime} onChange={handleDeadlineTimeChange} />
    </div>
  )
}
