import QuickSearches from 'QuickSearches/components/QuickSearches'
import UsageStats from 'Dashboard/components/UsageStats'
import useActions from 'Dashboard/useActions'

export default function PageContainer({ admin = false, manageAudiences = false }) {
  useActions()
  const firstName = useSel((s) => s.currentUser.firstName)

  return (
    <>
      <div className="title">
        <h1>Hello {firstName}</h1>
        <p className="larger dark-grey">
          There's nothing like some graphs to start your day <SvgIconsRocket />
        </p>
      </div>

      {manageAudiences && (
        <div className="section">
          <h2>Quick start</h2>
          <p className="larger dark-grey">
            Click below to quickly create a text or calling campaign to one of your
            favourite audiences.
          </p>
          <QuickSearches create={admin} />
        </div>
      )}

      <div className="section">
        <h2>Your Activity</h2>
        <UsageStats />
      </div>
    </>
  )
}
