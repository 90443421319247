import React from 'react'
import { EdgeProps, getBezierPath } from 'reactflow'
import NodeList from 'Workflows/components/Builder/Nodes/NodeList'
import { Popover } from '@varld/popover';

export default function WorkflowEdge({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style,
  data,
  markerEnd,
}: EdgeProps) {
  const [edgePath, edgeCenterX, edgeCenterY] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  function addButton() {
    return <g transform={`translate(${edgeCenterX}, ${edgeCenterY})`}>
      <Popover popover={({ close }) => {
        return <NodeList nodeId={id} />
      }}>
        <rect x={-10} y={-10} width={20} ry={4} rx={4} height={20} />
      </Popover>
      <text y={5} x={-4} color={'white'}>
        +
      </text>
    </g>
  }

  return (
    <>
      <path id={id} style={style} d={edgePath} markerEnd={markerEnd} />
      { !data.readOnly && addButton() }
    </>
  );
}
