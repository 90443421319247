import React, { FC, ReactNode, useState } from 'react'
import PropTypes from 'prop-types'
import { ConfirmationModal } from 'Shared/components/ConfirmationModal'

export interface PageActionProps extends HTMLButtonElement {
  onClick?: () => void
  confirmValue?: string
  confirmDescription?: string
  confirmationModalRender?: (data: {
    open: boolean
    toggle: (val: boolean) => void
  }) => ReactNode
}

const PageAction: FC<PageActionProps> = ({
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-empty-function
  onClick = () => {},
  confirmValue,
  confirmDescription,
  confirmationModalRender,
  ...restProps
}) => {
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)
  const handleClick = (e) => {
    if (confirmValue || confirmationModalRender || confirmDescription) {
      e.stopPropagation()
      e.preventDefault()
      setShowConfirmationModal(true)
    } else onClick()
  }

  return (
    <div
      onClick={(e) => {
        if (showConfirmationModal) {
          e.preventDefault()
          e.stopPropagation()
        }
      }}
    >
      <button type="button" onClick={handleClick} {...restProps}></button>
      {(!!confirmValue || !!confirmDescription) && (
        <ConfirmationModal
          confirmValue={confirmValue}
          description={confirmDescription}
          hide={() => setShowConfirmationModal(false)}
          onSubmit={onClick}
          open={showConfirmationModal}
        />
      )}
      {!!confirmationModalRender &&
        !!showConfirmationModal &&
        confirmationModalRender({
          open: showConfirmationModal,
          toggle: setShowConfirmationModal,
        })}
    </div>
  )
}

PageAction.propTypes = {
  onClick: PropTypes.func,
  confirmValue: PropTypes.string,
  confirmationModalRender: PropTypes.func,
}

export default PageAction
