import Button from 'Shared/components/Button'
import ServerWaitButton from 'Shared/components/ServerWaitButton'

const ActivateWidget = ({}) => {
  const act = useAct()
  const target = useSel((s) => s.target)
  const hasPhoneNumber = target.phoneNumber && target.phoneNumber.length > 0
  const [enabled, setEnabled] = useState(true)
  const customField = target.customFields
    ? target.customFields.find((cf) => cf.slug === 'activate_magic_link')
    : null

  function openProfile() {
    window.open(`https://neuactivate.com/users/${target.externalId}`, '_blank')
  }

  function sendMagicLink() {
    setEnabled(false)
    return act.target
      .sendSms(
        `Hi, this is the NEU. Your NEU Activate magic link is: ${customField.value}`
      )
      .then(() => {
        setTimeout(() => {
          setEnabled(true)
        }, 30000)
      })
  }

  return (
    <div className="widget">
      <p className="larger bold capitalise no-margin-top">Activate</p>
      {customField && (
        <p>
          <span className="bold">Activate magic link: </span> {customField.value}
        </p>
      )}
      {customField && hasPhoneNumber && (
        <ServerWaitButton
          disabled={!enabled}
          className="button small primary margin-right"
          onClick={sendMagicLink}
        >
          Send SMS with magic link {!enabled && ' (sent)'}
        </ServerWaitButton>
      )}
      {
        <Button small secondary onClick={openProfile}>
          View profile on Activate
        </Button>
      }
      {(!hasPhoneNumber || !customField) && (
        <p className="no-margin-bottom">
          This member doesn't have a phone number or magic link, so you can't send a link
          to them.
        </p>
      )}
    </div>
  )
}

export default ActivateWidget
