import * as React from 'react'

function SvgSend(props) {
  return (
    <svg width={20} height={22} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.5 3.895a.5.5 0 01.714-.452l14.999 7.105a.5.5 0 010 .904L3.214 18.556a.5.5 0 01-.714-.452v-5.788L15.833 11 2.5 9.684V3.895z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgSend
