import * as campaignEmails from 'Shared/components/DataTable/definitions/campaignEmails'
import * as campaigns from 'Shared/components/DataTable/definitions/campaigns'
import * as campaignsAwaitingApproval from 'Shared/components/DataTable/definitions/campaignsAwaitingApproval'
import * as customFields from 'Shared/components/DataTable/definitions/customFields'
import * as efforts from 'Shared/components/DataTable/definitions/efforts'
import * as emailSenders from 'Shared/components/DataTable/definitions/emailSenders'
import * as emailTemplates from 'Shared/components/DataTable/definitions/emailTemplates'
import * as groups from 'Shared/components/DataTable/definitions/groups'
import * as groupUsers from 'Shared/components/DataTable/definitions/groupUsers'
import * as inboundCalls from 'Shared/components/DataTable/definitions/inboundCalls'
import * as lists from 'Shared/components/DataTable/definitions/lists'
import * as roles from 'Shared/components/DataTable/definitions/roles'
import * as searches from 'Shared/components/DataTable/definitions/searches'
import * as supervisors from 'Shared/components/DataTable/definitions/supervisors'
import * as syncedAudiences from 'Shared/components/DataTable/definitions/syncedAudiences'
import * as tags from 'Shared/components/DataTable/definitions/tags'
import * as targets from 'Shared/components/DataTable/definitions/targets'
import * as targetDetailsUpdates from 'Shared/components/DataTable/definitions/targetDetailsUpdates'
import * as textMessageConversations from 'Shared/components/DataTable/definitions/textMessageConversations'
import * as textMessageReplies from 'Shared/components/DataTable/definitions/textMessageReplies'
import * as textMessages from 'Shared/components/DataTable/definitions/textMessages'
import * as users from 'Shared/components/DataTable/definitions/users'
import * as usersAwaitingApproval from 'Shared/components/DataTable/definitions/usersAwaitingApproval'
import * as usersRejected from 'Shared/components/DataTable/definitions/usersRejected'
import * as usersInvited from 'Shared/components/DataTable/definitions/usersInvited'
import * as webhooks from 'Shared/components/DataTable/definitions/webhooks'
import * as workflows from 'Shared/components/DataTable/definitions/workflows'
import * as surveys from 'Shared/components/DataTable/definitions/surveys'
import * as donations from 'Shared/components/DataTable/definitions/donations'

const definitions = {
  campaignEmails,
  campaigns,
  campaignsAwaitingApproval,
  customFields,
  efforts,
  emailSenders,
  emailTemplates,
  groups,
  groupUsers,
  inboundCalls,
  lists,
  roles,
  searches,
  supervisors,
  syncedAudiences,
  tags,
  targets,
  targetDetailsUpdates,
  textMessageConversations,
  textMessageReplies,
  textMessages,
  users,
  usersAwaitingApproval,
  usersRejected,
  usersInvited,
  webhooks,
  workflows,
  surveys,
  donations,
}

const URLS = {
  campaignEmails: '/api/emails/table',
  campaigns: '/api/campaigns/table',
  campaignsAwaitingApproval: '/api/campaigns/table/awaiting_approval',
  customFields: '/api/custom_fields/table',
  efforts: '/api/efforts/table',
  emailSenders: '/api/email_senders/table',
  emailTemplates: '/api/email_templates/table',
  groups: '/api/groups/table',
  groupUsers: '/api/users/table',
  inboundCalls: '/api/inbound_calls/table',
  lists: '/api/lists/table',
  roles: '/api/roles/table',
  searches: '/api/searches/table',
  supervisors: '/api/supervisors/table',
  syncedAudiences: '/api/synced_audiences/table',
  tags: '/api/tags/table',
  targets: '/api/targets/table',
  targetDetailsUpdates: '/api/target_details_updates/table',
  textMessageConversations: '/api/text_messages/conversations_table',
  textMessageReplies: '/api/text_messages/table',
  textMessages: '/api/text_messages/table',
  users: '/api/users/table',
  usersAwaitingApproval: '/api/users/table',
  usersRejected: '/api/users/table',
  usersInvited: '/api/users/table',
  webhooks: '/api/webhooks/table',
  workflows: '/api/workflows/table',
  surveys: '/api/surveys/table',
  donations: '/api/donations/table',
}

const COLUMNS = _.fromPairs(_.map(definitions, (v, k) => [k, v.columns || []] ))
const HEADERS = _.fromPairs(_.map(definitions, (v, k) => [k, v.headers || []] ))
const FILTERS = _.fromPairs(_.map(definitions, (v, k) => [k, v.filters || []] ))
const SORTERS = _.fromPairs(_.map(definitions, (v, k) => [k, v.sorters || []] ))
const ORDERS = _.fromPairs(_.map(definitions, (v, k) => [k, v.order || []] ))

export { COLUMNS, HEADERS, URLS, FILTERS, SORTERS, ORDERS }
