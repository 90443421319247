import * as React from 'react'

function SvgTickGray(props) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M16.769 7.31769C17.1458 6.91393 17.7786 6.89211 18.1823 7.26895C18.5861 7.64579 18.6079 8.27859 18.2311 8.68234L11.2311 16.1824C10.8655 16.5742 10.2561 16.6081 9.84923 16.2594L6.34923 13.2593C5.9299 12.8999 5.88134 12.2686 6.24076 11.8493C6.60018 11.4299 7.23148 11.3814 7.65081 11.7408L10.423 14.117L16.769 7.31769Z" fill="currentColor"/>
    </svg>
  )
}

export default SvgTickGray
