import Button from 'Shared/components/Button'

export default function ScheduleButton({ disabled = false }) {
  const act = useAct()
  const scheduledFor = useSel(s => s.campaign?.emailBlast?.scheduledFor || s.campaign?.textMessageBlast?.scheduledFor)

  return <Button
    secondary
    extraClass="margin-right js-finish"
    onClick={() => act.local.setModal('schedule')}
    disabled={disabled}
  >
    <SvgIconsCalendar /> { scheduledFor ? 'Change Schedule' : 'Schedule' }
  </Button>
}

