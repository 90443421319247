import dayjs from 'dayjs'

export const FORMAT = 'ddd, MMM DD, YYYY'

/**
 * @param {Array} items - the items to be chunked
 * @param {string} datetimeField - field name of datetime field. Default is `createdAt`
 * @param {boolean} reverse - reverse the order of the chunks. Default if descending of the days
 *  @param {string} sort - sort function to be used for sorting the items in each chunk. Default is descending of the datetimeField
 */
export interface DayChunksOptions<T> {
  items: Array<T>
  datetimeField?: keyof T
  reverse?: boolean
  sort: (a: T, b: T) => number
}
export const dayChunks: <T = Record<string, any>>(
  opt: DayChunksOptions<T>
) => [{ date: string; items: Array<T> }] = ({
  items,
  datetimeField = 'createdAt' as keyof typeof items[number],
  reverse = true,
  sort,
}) => {
  const sortFunction =
    sort ||
    ((item, nextItem) => {
      return item[datetimeField] < nextItem[datetimeField] ? 1 : -1
    })
  const getDateString = (item) => dayjs(item[datetimeField]).format(FORMAT)

  const chunks = _.uniq(items.map((item) => getDateString(item)))
    .sort((d1, d2) => (dayjs(d1, FORMAT).isAfter(dayjs(d2, FORMAT)) ? 1 : -1))
    .map((date) => ({
      date,
      items: items.filter((item) => getDateString(item) === date).sort(sortFunction),
    }))

  return (reverse ? chunks.reverse() : chunks) as [{ date: string; items: typeof items }]
}
