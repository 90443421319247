import './CampaignCard.scoped.scss'

/**
 * Frontend version of logic from file app/datatables/campaigns_datatable.rb#data
 * @param param0
 * @returns
 */
export const getCampaignViewUrl = ({
  status,
  id,
}: {
  status: string
  id: string | number
}): string => {
  // See app/models/campaign.rb#is_draft?
  if (['draft', 'scheduled'].indexOf(status) >= 0) {
    return `/admin/campaigns/${id}/build`
  }

  return `/admin/campaigns/${id}/overview`
}

export default function CampaignCard({ id, name, status, primaryType, secondaryType }) {
  return (
    <div className="card campaign-card">
      <div className="bookmark-star">
        <SvgIconsBookmarkStar solid />
      </div>
      <p className="name">{name}</p>
      <p className={`campaign-status campaign-status-${status}`}>{status}</p>
      <p className="type">
        {primaryType}
        {secondaryType && (
          <>
            <span className="dot"></span> {secondaryType}
          </>
        )}
      </p>
      <a className="button primary small" href={getCampaignViewUrl({ status, id })}>
        View campaign
      </a>
    </div>
  )
}
