import Fade from '@material-ui/core/Fade'
import { LinearProgress } from '@material-ui/core'
import Paginator from '../Paginator'
import ShieldIcon from './ShieldIcon'
import UserRanking from './UserRanking'
import Board from './Board'

/**
 *  Displays ordered lists of users ranked according to their calling stats
 * @param endpoint: String - URL for api endpoint that returns data
 * @param initialLists: [Object] - if mounting within a context where data is already available, or you want default data visible before endpoint fetch
 * @returns {JSX.Element}
 * @constructor
 */
export default function Leaderboard({ endpoint }) {
  const [lists, setLists] = useState([])
  const [activeList, setActiveList] = useState()
  const [people, setPeople] = useState([])

  const fetchLists = () => {
    axios.get(endpoint).then((res) => {
      setLists(res.data)
      if (!activeList) setActiveList(res.data[0].name)
    })
  }

  useEffect(() => {
    if (endpoint) {
      fetchLists()
    }
  }, [])

  const onClickTab = (name) => setActiveList(name)
  const getVisibleList = () => lists.filter((list) => list.name === activeList)[0]

  const tabButtons = () =>
    lists.map((list) => (
      <div
        key={list.name}
        className={`tab transition-ease-all ${
          activeList === list.name ? 'selected' : ''
        }`}
        onClick={() => onClickTab(list.name)}
      >
        {list.label}
      </div>
    ))

  return (
    <div className="box no-padding">
      <div className="box no-border flex-column-center">
        <ShieldIcon />
        <h3 className="text-center big-number margin-block-half">Leaderboard</h3>
      </div>
      <Fade in={!activeList}>
        <LinearProgress />
      </Fade>
      <Fade in={!!activeList}>
        <div>
          <div className="tabs fill">{tabButtons()}</div>
          <div className="box qtr-padding no-border no-gutter-bottom">
            <UserRanking
              rank={getVisibleList()?.user_rank}
              cutoff={getVisibleList()?.data.length}
            />
          </div>
          <div className="box qtr-padding no-border">
            <div className="card flat filled narrow-pad">
              {activeList && <Board people={people} />}
            </div>
          </div>
          <div className="box qtr-padding no-border">
            <Paginator
              data={getVisibleList()?.data}
              onChange={(newPageData) => setPeople(newPageData)}
            />
          </div>
        </div>
      </Fade>
    </div>
  )
}
