import Select from 'react-select'
import TextField from 'Shared/components/TextField'
import validator from 'validator'
import FileUploader from 'Shared/components/ImageUploader/FileUploader'
import PillEditor from "Shared/components/PillEditor/PillEditor"


const LinkFields = ({parameters, onChange}) => {
  const act = useAct()
  const handleAttributeChange = (attr) => (value) => onChange({[attr]: value})
  const handleLinkTypeChange = ({value}) => onChange({linkType: value})

  const { mergeTags } = useSel(s => s.campaignBuilder)

  const onUploaded = ([ blobId ], filename) => {
    onChange({blobId, filename})
  }

  const linkTypeOptions = [
    {value: 'web', label: 'Website'},
    {value: 'email', label: 'Email'},
    {value: 'phone', label: 'Phone number'},
    {value: 'file', label: 'File'},
  ]

  const webLinkFields = () => (
    // <TextField label="Web address" defaultValue={block.parameters.url}
    // onValidChange={handleAttributeChange('url')}
    // validators={[{ fn: validator.isURL, msg: 'Invalid web address' }]} />
    <div className="field">
      <label>Web address</label>
      <PillEditor webLink singleLine condensed initialValue={parameters.url} onChange={handleAttributeChange('url')} availableTags={mergeTags} />
    </div>
  )

  const emailLinkFields = () => (
    <TextField id="email-address" label="Email address" defaultValue={parameters.email}
      onValidChange={handleAttributeChange('email')}
      validators={[{ fn: validator.isEmail, msg: 'Invalid email address' }]} />
  )

  const phoneLinkFields = () => (
    <TextField label="Phone number" defaultValue={parameters.phoneNumber} onValidChange={handleAttributeChange('phoneNumber')} />
  )

  const fileLinkFields = () => (
    <div className="field">
      <label>File</label>
      <FileUploader onUploaded={onUploaded} existingFilename={parameters.filename} />
    </div>
  )

  return (
    <>
      <div className="field">
        <label>Link to</label>
        <Select
          value={linkTypeOptions.find((o) => o.value === parameters.linkType)}
          options={linkTypeOptions}
          onChange={handleLinkTypeChange}
          classNamePrefix="link-type-select" />
      </div>
      {parameters.linkType === 'web' && webLinkFields()}
      {parameters.linkType === 'email' && emailLinkFields()}
      {parameters.linkType === 'phone' && phoneLinkFields()}
      {parameters.linkType === 'file' && fileLinkFields()}
    </>
  )
}

export default LinkFields
