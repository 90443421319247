import Chip from '@material-ui/core/Chip'
import Container from '@material-ui/core/Container'

export const MuiChip = styled(Chip).attrs(({ $tiny, status }) => ({
  size: 'small',
  classes: {
    root: 'mui-chip' + ($tiny ? ' tiny' : '') + (status ? ` ${status}` : ''),
    label: 'mui-chip-label' + ($tiny ? ' tiny' : '') + (status ? ` ${status}` : ''),
  },
}))`
  &.MuiChip-root {
    border-radius: 4px !important;
    &.tiny {
      height: 20px !important;
      min-width: 70px !important;
      background-color: #f5f5f5 !important;
      margin-right: 10px !important;
      &.true {
        background-color: ${({ theme }) => theme.accentBackground} !important;
      }
      &.false {
        background-color: ${({ theme }) => theme.accentRed} !important;
      }
    }
    > .MuiChip-label {
      font-size: 12px !important;
      font-weight: 700 !important;
      &.tiny {
        color: ${({ theme }) => theme.darkGrey} !important;
        font-weight: 400 !important;
        &.true {
          color: ${({ theme }) => theme.green} !important;
          font-weight: 700 !important;
        }
        &.false {
          color: ${({ theme }) => theme.red} !important;
          font-weight: 700 !important;
        }
      }
    }
  }
`
export const Root = styled(Container)`
  &.sub {
    background-color: white;

    &.height {
      min-height: 300px;
    }
  }

  & .basic-info {
    height: 100%;
    padding-bottom: 60px;

    .section-top {
      padding-top: 40px;
    }
  }

  & .chip-container {
    & > * {
      margin: 3px;
    }
  }

  & .chip {
    border-radius: 4px !important;

    &-label {
      font-size: 12px;
      font-weight: 700;
    }
  }
`
