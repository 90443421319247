import restClient from "Shared/hooks/restClient"
import CampaignCard from "./CampaignCard"
import Tip from 'Shared/components/Tip/Tip'
import './BookmarkedCampaigns.scoped.scss'

const TIP_TEXT = `<span class='bold'>You can bookmark campaigns by clicking the star icon, in the table below.</span><br/ >
When you return to this page, they'll then appear up here so you have faster access.`

export default function BookmarkedCampaigns () {

  const [bookmarkedCampaigns, setBookmarkedCampaigns] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    restClient.get('/api/me/bookmarked_campaigns').then((r) => {
      setBookmarkedCampaigns(r.data)
      setLoading(false)
    }).catch((e) => {
      console.error(e)
      setLoading(false)
    })
  }, [])

  return (
    <div className={`horizontal-card-list ${bookmarkedCampaigns.length > 0 ? 'margin-bottom double' : ''}`}>
      { (!loading && bookmarkedCampaigns.length === 0) &&
        <Tip extraClass='margin-bottom double' slug='no-bookmarked-campaigns' tip={TIP_TEXT} />
      }
      { bookmarkedCampaigns.map((c) => <CampaignCard {...c} key={c.id} />) }
    </div>
  )
}
