import React from 'react'
import AlignField from 'EmailBuilder/components/AlignField'
import FileUploader from 'Shared/components/ImageUploader/FileUploader'
import LinkFields from '../../LinkFields'
import Divider from 'Shared/components/Divider'
import { Form, Formik } from 'formik'
import SingleImageProperties from './SingleImageProperties'
import SizingMode from './SizingMode'
import Button from 'Shared/components/Button'
import './ImageBlockProperties.scoped.scss'

const ImageBlockProperties = ({ block }) => {
  const act = useAct()

  const handleImageChange = (index, parameters) => {
    const images = [...block.parameters.images]
    images[index] = parameters
    act.email.blocks.updateParameters(block.id, { images })
  }

  const addImage = () => {
    const images = [...block.parameters.images, { src: '', alt: '', link: { url: '', target: '' } }]
    act.email.blocks.updateParameters(block.id, { images })
  }

  const deleteImage = (index) => {
    if(!confirm('Are you sure you want to delete this image?')) return
    const images = [...block.parameters.images]
    images.splice(index, 1)
    act.email.blocks.updateParameters(block.id, { images })
  }

  return (
    <div>
      {block.parameters.images.map((image, index) => (
        <div className="image" key={index}>
          <h3 className="no-margin-top">
            Edit image {index+1}
            {block.parameters.images.length > 1 && <Button naked onClick={() => deleteImage(index)}><SvgIconsDelete /></Button>}
          </h3>
          <SingleImageProperties multiple={block.parameters.images.length > 1} image={image} onChange={(parameters) => {handleImageChange(index, parameters)}} />
          <Divider />
        </div>
      ))}

      {block.parameters.images.length < 3 &&
        <Button primary onClick={addImage}>Add Image</Button>
      }
    </div>
  )
}

export default ImageBlockProperties
