import './PageContainer.scoped.scss'
import { LineChart, CartesianGrid, XAxis, YAxis, Line, ResponsiveContainer } from 'recharts'
import DateFilter from './Filters/DateFilter'
import { format } from 'date-fns';

const QUERIES = [
  { slug: 'calls_made', name: 'Calls made', availableFilters: ['date'] },
  { slug: 'text_messages_sent', name: 'Text Messages sent', availableFilters: ['date'] }
]

export default function PageContainer () {
  const act = useAct()
  const { currentQuery, points, start, end } = useSel(s => s.reporting)
  const query = currentQuery ? QUERIES.find((q) => q.slug === currentQuery) : null
  const filters = query ? query.availableFilters : []
  
  function loadQuery(name) {
    act.reporting.loadQuery(name, { start, end })
  }

  useEffect(() => {
    if ((start || end) && currentQuery) {
      loadQuery(currentQuery)
    }
  }, [start, end])

  return <div className='reporting'>
    <div className="header">
      <h1>Reporting</h1>
    </div>

    <div className='query-builder'>
      <div className="queries">
        { QUERIES.map((query) => {
          return <a className={`query ${currentQuery === query.slug ? 'selected' : ''}`} onClick={() => loadQuery(query.slug)}>
            { currentQuery === query.slug && <SvgIconsTick /> }
            <p>{ query.name }</p>
          </a>
        })}
      </div>

      <div className='results'>
        { query && <>
          <div className='filters'>
            { filters.map((filter) => {
              return <DateFilter />
            })}
          </div>

          <div className='graph'>
            { points.length > 0 && <ResponsiveContainer height={400}>
                <LineChart width={500} height={400} data={points}>
                    <XAxis axisLine={false} tickLine={false} dataKey="date" tickFormatter={time => format(new Date(time), 'dd/MM')} />
                    <YAxis axisLine={false} tickLine={false} allowDecimals={false} tickFormatter={(v) => v.toLocaleString()} />
                    <CartesianGrid vertical={false} stroke="#eee" strokeDasharray="5 5"/>
                    <Line type="monotone" dataKey="count" stroke="#AF8AF5" strokeWidth={3} />
                </LineChart>
            </ResponsiveContainer> }
          </div>
        </> }
        { !query && <h2>Select a query</h2> }
      </div>
    </div>
  </div>
}
