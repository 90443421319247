import Select from 'react-select'

const fonts = ['Arial', 'Helvetica', 'Optima', 'Futura']
const fontOptions = fonts.map((f) => ({ label: f, value: f }))

const FontFamilyField = ({value, onChange}) => {

  const fontValue = fontOptions.find((o) => o.value === value)

  const handleChange = (option) => {
    onChange(option.value)
  }

  return (
    <div className="field">
      <label>Font</label>
      <Select options={fontOptions} value={fontValue} onChange={handleChange} classNamePrefix="font-select" />
    </div>
  )

}

export default FontFamilyField
