import * as React from 'react'

function SvgStarEmpty(props) {
  return (
    <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 21l-5.445 2.864a1.333 1.333 0 01-1.935-1.406l1.04-6.064L1.254 12.1a1.333 1.333 0 01.74-2.275l6.088-.885 2.723-5.517a1.333 1.333 0 012.391 0l2.723 5.517 6.089.885a1.333 1.333 0 01.739 2.275l-4.406 4.294 1.04 6.064a1.333 1.333 0 01-1.935 1.406L12 21z"
        fill="#E9EDFB"
      />
    </svg>
  )
}

export default SvgStarEmpty
