import './Tabs.scoped.scss'

export default function DynamicTabs({ value, onChange, children, className = '', dropdown = false }) {

  const tabChildren = React.Children.map(children, (child, index) => {
    return child && React.cloneElement(child, {
      value: index,
      onClick: () => onChange(index),
      selected: index === value,
    })
  })

  return (
    <div className={`filter-tabs ${className} ${dropdown ? 'dropdown' : ''}`}>
      {tabChildren}
    </div>
  )
}
