import './summary-table.scoped.scss'

export default function SmsTable({ data }) {
  const campaigns = _.orderBy(
    _.map(_.groupBy(data, 'campaignId'), (rows, id) => {
      return {
        id,
        name: rows[0].name,
        outboundMessages:
          rows.find((r) => r.direction === 'outbound')?.messagesCount || 0,
        outboundSegments:
          rows.find((r) => r.direction === 'outbound')?.segmentsCount || 0,
        inboundMessages: rows.find((r) => r.direction === 'inbound')?.messagesCount || 0,
        charge: _.sumBy(rows, 'charge'),
      }
    }),
    'charge',
    'desc'
  )

  if (campaigns.length === 0) {
    return <div className="empty-table">No text messages sent.</div>
  }

  return (
    <table>
      <thead>
        <tr>
          <th>Campaign</th>
          <th>Messages</th>
          <th>Segments</th>
          <th>Replies</th>
          <th>Cost</th>
        </tr>
      </thead>
      <tbody>
        {campaigns.map((campaign) => (
          <tr>
            <td>
              <a
                href={`/admin/campaigns/${campaign.id}/overview`}
                target="_blank"
                rel="noreferrer"
              >
                {campaign.name}
              </a>
            </td>
            <td>{campaign.outboundMessages}</td>
            <td>{campaign.outboundSegments}</td>
            <td>{campaign.inboundMessages}</td>
            <td>${campaign.charge.toFixed(2)}</td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}
