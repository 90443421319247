import './Card.scoped.scss'

const Card = ({
  onClick,
  active,
  icon,
  title,
  description,
  interactive,
  children,
  noRenderEmpty = false,
}) => {
  const classNames = ['card-v2']
  if (active) {
    classNames.push('active')
  }
  if (interactive) {
    classNames.push('interactive')
  }

  return (
    <div className={classNames.join(' ')} onClick={onClick}>
      {(!noRenderEmpty || !!icon) && <p className="icon">{icon}</p>}
      {(!noRenderEmpty || !!title) && <p className="title">{title}</p>}
      {(!noRenderEmpty || !!description) && <p className="description">{description}</p>}
      {children}
    </div>
  )
}

export default Card
