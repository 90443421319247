import './OrganisationPicker.scoped.scss'
import ToggleNode from 'Shared/components/ToggleNode'
import Loading from 'Shared/components/Loading/Loading'
import restClient from 'Shared/hooks/restClient'

const TREE_URL = '/api/organisations/tree'
const SEARCH_URL = '/api/organisations/search'

async function getRootOrgs() {
  return restClient.get(TREE_URL).then((r) => r.data)
}

async function getChildren(orgId) {
  return restClient.get(`${TREE_URL}/${orgId}`).then((r) => r.data)
}

function formatSearchResult(org) {
  return {
    id: org.value,
    name: org.label.replace(/\s+\(.*\)$/, ''),
    hasChildren: false
  }
}

async function searchOrgs(query) {
  return restClient.get(`${SEARCH_URL}?query=${encodeURIComponent(query)}`)
    .then((r) => r.data.map(formatSearchResult))
}


function Node({ name, id, depth = 0, onClick, hasChildOrgs = false, ...props }) {
  const [expanded, setExpanded] = useState(false)
  const [loading, setLoading] = useState(false)
  const [childOrgs, setChildOrgs] = useState(null)

  const toggleNode = () => {
    setExpanded(!expanded)
    setLoading(true)
    if (childOrgs) return
    getChildren(id).then((data) => {
      setLoading(false)
      setChildOrgs(data)
    })
  }

  // Immediately load first level of children
  useEffect(() => {
    if (depth === 0) {
      toggleNode()
    }
  }, [])

  return (
    <div className={`org depth-${depth}`}>
      <div className="org-details" onClick={() => onClick(id, name)}>
        {depth > 0 && hasChildOrgs && <ToggleNode expanded={expanded} onClick={toggleNode} />}
        <span className="org-name">
          {name}
        </span>
        <div className="arrow"><SvgIconsArrowRight variant="light" /></div>
      </div>
      {childOrgs && expanded &&
        <div className="org-children">
          {childOrgs.map(({ name, id, hasChildren }) => (
            <Node depth={depth + 1} name={name} id={id} onClick={onClick} hasChildOrgs={hasChildren} key={id} />
          ))}
        </div>
      }
    </div>
  )
}


export default function OrganisationPicker({ onPick }) {
  const [loading, setLoading] = useState(true)
  const [rootOrgs, setRootOrgs] = React.useState(null)
  const [searchResultOrgs, setSearchResultOrgs] = React.useState(null)
  const searchInputRef = React.useRef(null)

  let displayOrgs = null
  if (rootOrgs && !searchResultOrgs) {
    displayOrgs = rootOrgs
  } else if (searchResultOrgs && searchResultOrgs.length > 0) {
    displayOrgs = searchResultOrgs
  }

  useEffect(() => {
    getRootOrgs().then((data) => {
      setRootOrgs(data)
      setLoading(false)
    })
  }, [])


  const search = (value) => {
    if (value === '') {
      setSearchResultOrgs(null)
      return
    }
    setLoading(true)
    searchOrgs(value).then((orgs) => {
      setLoading(false)
      setSearchResultOrgs(orgs)
    })
  }
  const debouncedSearch = _.debounce(search, 500)


  return (
    <div className="organisation-picker">
      <div>
        <input type="search" ref={searchInputRef} placeholder="Search&hellip;" className="full-width" onChange={(e) => debouncedSearch(e.target.value)} />
      </div>

      <div className="orgs">
        {loading && <Loading />}

        {!loading && searchResultOrgs && searchResultOrgs.length === 0 && <div>No matching organisations found</div>}

        {!loading && displayOrgs && displayOrgs.map(({ name, id, hasChildren }) => (
          <Node name={name} id={id} onClick={onPick} hasChildOrgs={hasChildren} key={id} />
        ))}
      </div>
    </div>
  )
}

