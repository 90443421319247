const Root = ({ children }) => {
  return (
    <Grid 
      container 
      direction="row" 
      className="root" 
    >
      {children}
    </Grid>
  )
}

export default Root
