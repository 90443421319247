import './NewDataTable.scoped.scss'

const identity = (v) => v

const headerCell = (col, currentSort, toggleSort) => (
  <th key={col.key} onClick={() => toggleSort(col.key)}>
    {col.label}
    {col.sortable ? (
      <SvgIconsSort
        asc={currentSort[col.key] === 'asc'}
        desc={currentSort[col.key] === 'desc'}
      />
    ) : null}
  </th>
)

const dataCell = (row, col, i, className) => (
  <td className={className} key={i}>
    {col.rawRender && (
      <div dangerouslySetInnerHTML={{ __html: col.rawRender(row[col.key], row) }} />
    )}
    {!col.rawRender && (
      (col.render || identity)(row[col.key], row)
    )}
  </td>
)

const dataRow = (columns, row, i) => {
  const className = row.className || ''
  return <tr key={i}>{columns.map((col, i) => dataCell(row, col, i, className))}</tr>
}

const NewDataTable = ({
  columns,
  data,
  perPage = 10,
  defaultSort = {},
  noDataMessage = null,
  ...rest
}) => {
  const [currentPage, setCurrentPage] = useState(0)
  const [currentSort, setCurrentSort] = useState(defaultSort)

  const sortedData = _.orderBy(data, Object.keys(currentSort), Object.values(currentSort))

  const numPages = Math.ceil(data.length / perPage)
  const dataPage = sortedData.slice(currentPage * perPage, (currentPage + 1) * perPage)

  const toggleSort = (key) => {
    setCurrentSort({
      [key]: currentSort[key] && currentSort[key] === 'asc' ? 'desc' : 'asc',
    })
  }

  return (
    <div className="new-data-table-container" {...rest}>
      <table>
        <thead>
          <tr>{columns.map((col) => headerCell(col, currentSort, toggleSort))}</tr>
        </thead>
        <tbody>
          {dataPage.map((row, i) => dataRow(columns, row, i))}
          {dataPage.length === 0 && noDataMessage && (
            <p className="no-data-message">{noDataMessage}</p>
          )}
        </tbody>
      </table>

      <ul className="new-data-table-pagination">
        <li className="prev" onClick={() => setCurrentPage(Math.max(currentPage - 1, 0))}>
          <SvgIconsBack />
        </li>
        {Array.from(Array(numPages)).map((_, i) => (
          <li
            key={i}
            className={i === currentPage ? 'active' : ''}
            onClick={() => setCurrentPage(i)}
          >
            {i + 1}
          </li>
        ))}
        <li
          className="next"
          onClick={() => setCurrentPage(Math.min(currentPage + 1, numPages - 1))}
        >
          <SvgIconsForward />
        </li>
      </ul>
    </div>
  )
}

export default NewDataTable
