import './DividerBlock.scoped.scss'

const DividerBlock = ({ block }) => {
  return (
    <div>
      <hr />
    </div>
  )
}

export default DividerBlock
