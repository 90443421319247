import restClient from 'Shared/hooks/restClient'
import BackdropModal from 'Shared/components/BackdropModal/BackdropModal'
import Wizard from 'Campaigns/CreateCampaign/components/Wizard'
import './QuickSearches.scoped.scss'
import Search from './Search'
import SupportCard from './SupportCard'

const LIMIT = 24

export default function QuickSearches({ create = false, edit = false, compact = false }) {
  const [loaded, setLoaded] = useState(false)
  const [searches, setSearches] = useState([])
  const [expanded, setExpanded] = useState(false)
  const [chosenSearchId, setChosenSearchId] = useState(null)
  const act = useAct()

  // Load
  useEffect(() => {
    restClient.get('/api/searches/templates').then(({ data }) => {
      setSearches(data)
      setLoaded(true)
    })
  }, [])

  const newCampaign = (searchId) => {
    act.createCampaign.reset()
    setChosenSearchId(searchId)
  }

  const useSearch = (searchId) => {
    restClient.post(`/api/searches/${searchId}/clone?use_search=y`).then(({ data }) => {
      window.location = data.redirectUrl
    })
  }

  if (!loaded) return <div />

  const displaySearches = expanded ? searches : _.take(searches, LIMIT)

  return (
    <div className={`quick-searches-${compact ? 'compact box' : 'normal'}`}>
      <div className="search-list">
        {displaySearches.map((s) => (
          <Search
            {...s}
            key={s.id}
            newCampaign={newCampaign}
            useSearch={useSearch}
            edit={edit}
            compact={compact}
          />
        ))}
        {!compact && <SupportCard create={create && displaySearches.length === 0} />}
        {chosenSearchId && (
          <Modal
            key={chosenSearchId}
            searchId={chosenSearchId}
            close={() => setChosenSearchId(null)}
          />
        )}
      </div>

      {displaySearches.length === 0 && compact && (
        <div>
          <p className="no-margin-top bold">
            You don't have any quick start audiences yet.
          </p>
          <div className="tip">
            <div className="icon">
              <SvgIconsTip />
            </div>
            <p>
              Quick start audiences are commonly used audiences, which you can quickly
              start campaigns from. You can make an audience a quick start audience when
              you create or edit it.
            </p>
          </div>
        </div>
      )}
    </div>
  )
}

function Modal({ searchId, close }) {
  return (
    <BackdropModal title="Create a new campaign" open={true} onCancel={close}>
      <Wizard searchId={searchId} cloneSearchId={searchId} />
    </BackdropModal>
  )
}
