import BackdropModal from 'Shared/components/BackdropModal/BackdropModal'
import Loading from '../../Shared/components/Loading/Loading'
import './PreviewModal.scoped.scss'
import useEmailPreview from 'Shared/hooks/useEmailPreview'
import EmailPreview from 'Shared/components/EmailPreview'
import EmailContentTabs from 'CampaignBuilder/components/Steps/EmailContentTabs'

const PreviewModal = ({ open, hide }) => {
  const { emailBlast = {}, search = {} } = useSel(s => s.campaign)
  const { emailSender = {} } = emailBlast
  const { senderName } = emailSender
  const { name: searchName } = search

  const contentVariantId = useSel(s => s.local.currentContentVariantId)
  const [html, subjectLine, loading] = useEmailPreview([open], null, null, contentVariantId)

  const iframeStyle = {
    border: '1px solid #999',
    width: '650px',
    height: '450px',
    padding: '20px',
    zIndex: 3000,
    position: 'relative'
  }


  return (
    <BackdropModal title="Email preview" open={open} onCancel={hide} tall scrollable={true}>
      <EmailContentTabs />
      <div className="main">
        {loading && <Loading text="Loading preview" />}
        {open && !loading && <EmailPreview html={html} subjectLine={subjectLine} senderName={senderName} searchName={searchName} iframeStyle={iframeStyle} maxHeight={false} autoHeight={true} />}
      </div>
    </BackdropModal>
  )
}

export default PreviewModal
