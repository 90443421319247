import { FC, ReactNode } from 'react'
import { PageActionProps } from 'Shared/components/PageAction'

import PropTypes from 'prop-types'
import { ManageTargetTypes, useManageMenuProps } from './hooks'
import ManageMenuComponent from './ManageMenuComponent'

export interface ManageMenuItem extends Omit<PageActionProps, 'className'> {
  title: string
  className?: 'name' | 'danger'
}

const ManageMenu: FC<{
  target: any
  type: ManageTargetTypes
  title?: ReactNode
}> = ({ target, type, title = 'Manage' }) => {
  const [items, components] = useManageMenuProps(type, target)

  return <ManageMenuComponent items={items} components={components} title={title} />
}

ManageMenu.propTypes = {
  target: PropTypes.any,
  type: PropTypes.string,
  title: PropTypes.element,
}

export default ManageMenu
