import BackdropModal from "Shared/components/BackdropModal/BackdropModal"
import TextField from "Shared/components/TextField"
import restClient from "Shared/hooks/restClient"
import ServerWaitButton from 'Shared/components/ServerWaitButton'

const BuyPhoneNumberModal = ({ open, onCancel, afterCreate }) => {
  const act = useAct()

  const [areaCode, setAreaCode] = useState('')
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const reset = () => {
    setAreaCode('')
    setErrorMessage('')
  }

  useEffect(reset, [open])

  const purchase = () => {
    setLoading(true)
    return restClient.post('/api/outbound_numbers/purchase', { areaCode }).then((res) => {
      if (res.data.success === true) {
        setLoading(false)
        afterCreate(res.data.phoneNumber)
      } else {
        setErrorMessage(res.data.message)
        setLoading(false)
      }
    }).catch(() => {
      setErrorMessage('There was an error purchasing the phone number.')
      setLoading(false)
    })
  }

  return (
    <BackdropModal open={open} title={"Buy Phone Number"} onCancel={onCancel}>
      <p>You can optionally enter an area code to attempt to buy a phone number for.</p>
      <TextField
        label="Area code"
        id="area-code-input"
        value={areaCode}
        onChange={(e) => setAreaCode(e)}
        disabled={loading}
      />
      {errorMessage && <div className='margin-bottom'><p className="error">{errorMessage}</p></div>}
      <div className="field">
        <ServerWaitButton
          onClick={() => purchase()}
          className="button primary margin-right"
        >
          Purchase
        </ServerWaitButton>
      </div>
    </BackdropModal >
  )

}

export default BuyPhoneNumberModal
