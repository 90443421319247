import Context from 'CampaignVolunteer/Context'

export default function OrganisationDescription() {
  const { state, dispatch, actions } = useContext(Context)
  const { organisationId, organisations, campaign, user } = state

  const { matchingMode, userChoiceMode } = campaign.settings
  if ((!matchingMode || matchingMode === 'default') && (!userChoiceMode || userChoiceMode === 'none')) return null

  if (organisationId) { // chosen
    var orgName = organisations.find((org) => org.id === organisationId)?.name
  } else { // otherwise user org
    var orgName = user.organisationName
  } 

  if (campaign.campaignType === 'phone') {
    var header = i18n.t('user.campaigns.phone.youre_calling', { organisation: orgName })
  } else if (campaign.campaignType === 'sms_conversations') {
    var header = i18n.t('user.campaigns.text.you_are_messaging_within', { organisation: orgName })
  }

  return <h2>{ header }</h2>
}
