import useData from 'DesktopMessager/useData'

import { Popover } from '@varld/popover'
import MessageBubble from 'DesktopMessager/components/MessageHistory/MessageBubble'
import cx from 'classnames'
import './CannedResponses.scoped.scss'
import MessageIcon from 'DesktopMessager/components/MessageHistory/MessageIcon'

export default function CannedResponses({
  onSelect,
}: {
    onSelect: (msg: string) => void
  }): JSX.Element {
  const [anchorEl, setAnchorEl] = useState(null)
  const user = useData((s) => s.user)
  const cannedResponses = useData((s) => s.cannedResponses) as Array<any>

  const [selectedResponse, setSelectedResponse] = useState(null)
  useEffect(() => {
    const s = cannedResponses.find((cr) => cr.id === selectedResponse?.id)
    if (selectedResponse !== s) {
      if (s) {
        setSelectedResponse(s)
      } else {
        setSelectedResponse(cannedResponses[0])
      }
    }
  }, [cannedResponses, selectedResponse])
  return (
    <>
      <Popover popover={ ({close}) => (
        <div className="popup canned-responses">
          <div className="canned-responses__list">
            <h4 className="no-margin-top no-margin-bottom">Select a message</h4>
            {cannedResponses.map((cr) => (
              <div
                className={cx('canned-responses__list-item', {
                  active: selectedResponse?.id === cr.id,
                })}
                onClick={() => setSelectedResponse(cr)}
              >
                {cr.name}
              </div>
            ))}
          </div>
          <div className="canned-responses__message">
            <h4 className="no-margin-top">Message preview</h4>
            {selectedResponse && (
              <>
                <MessageBubble 
                  initialMessage={false}
                  senderName={`${user.firstName} ${user.lastName}`}
                  direction='inbound'
                  date={new Date()}
                  body={selectedResponse.body}
                />

                <div className="controls">
                  <button
                    type="button"
                    className="button primary darken"
                    onClick={() => {
                      close()
                      onSelect(selectedResponse.body)
                      setAnchorEl(null)
                    }}
                  >
                    {i18n.t('shared.messages.select_message')}
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      )} >

        <button
          type="button"
          className="button small secondary"
          onClick={(e) => setAnchorEl(e.currentTarget)}
        >
          {i18n.t('shared.messages.choose_response')}
        </button>

      </Popover>
    </>
  )
}
