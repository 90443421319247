export default function ShortDescriptor({ questionCount, isLive=false }) {
  return (
    <>
      <p className='no-margin-bottom'>
        You've set up <span className='bold'>{questionCount}</span> questions and instructions.
      </p>
      { isLive && <p>Please pause this campaign to edit it.</p> }
    </>
  )
}
