import Intro from './Intro'
import Question from './Question'
import Finished from './Finished'
import Completed from './Completed'
import Loading from 'Shared/components/Loading/Loading'

import useEnsureSurvey from '../hooks/ensureSurvey'

import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";


export default function SurveyRoutes({surveyId, surveyTakerId, targetFirstName}) {
  const loaded = useEnsureSurvey(surveyId, surveyTakerId)

  if (!loaded) return <Loading />

  return <Router>
    <Routes>
      <Route path="/target/surveys/:surveyId/begin" element={<Intro targetFirstName={targetFirstName} />} />
      <Route path="/target/surveys/:surveyId/questions/:questionId" element={<Question />} />
      <Route path="/target/surveys/:surveyId/finished" element={<Finished />} />
      <Route path="/target/surveys/:surveyId/completed" element={<Completed />} />
    </Routes>
  </Router>
}
