/* eslint-disable react/prop-types */
import './CustomFields.scoped.scss'
import AsyncSelect from 'Shared/components/AsyncSelect'
import Button from 'Shared/components/Button'
import { CircularProgress } from '@material-ui/core'
import EditableCustomField from './EditableCustomField'
import { FC, HTMLProps } from 'react'

export const CustomFieldInput: FC<
  {
    field: {
      type: string
    }
  } & Omit<HTMLProps<HTMLInputElement>, 'type'>
> = ({ field, ...props }) => {
  const type = field.type === 'float' ? 'number' : field.type === 'date' ? 'date' : 'text'
  return <input type={type} {...props} />
}

const CustomFields: FC = () => {
  const act = useAct()
  const target = useSel((s) => s.target)
  const [newCustomField, setNewCustomField] = useState(null)
  const [newCustomFieldValue, setNewCustomFieldValue] = useState('')
  const [loading, setLoading] = useState(false)
  const customFields = target?.customFields || []
  const uniqueCategories = useMemo(() => {
    return [...new Set(customFields.map(field => field.category))]
  }, [customFields])
  const [selectedCategory, setSelectedCategory] = useState(null)
  useEffect(() => {
    if (uniqueCategories.length > 0 && !selectedCategory) {
      setSelectedCategory(uniqueCategories[0])
    }
  }, [uniqueCategories, selectedCategory])
  const [showAddForm, setShowAddForm] = useState(false)

  if (!target.customFields) {
    return null
  }

  const closeAddForm = () => {
    setShowAddForm(false)
    setNewCustomField(null)
  }

  const addField = () => {
    setLoading(true)
    act.target.addCustomField(newCustomField.value, newCustomFieldValue).then(() => {
      setLoading(false)
      closeAddForm()
    })
  }

  return (
    <div className="box custom-fields">
      <p className="larger bold capitalise no-margin-top">Custom fields</p>

      <div className="custom-field-categories">
        {uniqueCategories.map(category => (
          <div 
            key={category} 
            className={`custom-field-category ${category === selectedCategory ? 'active' : ''}`}
          >
            <p 
              className="bold no-margin selectable-category"
              onClick={() => setSelectedCategory(category)}
            >
              {category}
              {category === selectedCategory && <span className="margin-left"><SvgIconsArrowRight /></span>}
            </p>
            {category === selectedCategory ? (
              <div className="custom-fields-grid">
                {customFields
                  .filter(field => field.category === category)
                  .map(field => (
                    <EditableCustomField field={field} key={field.slug} />
                  ))
                }
              </div>
            ) : (
              <div className="custom-fields-link">
                <p className="no-margin dark-grey link" onClick={() => setSelectedCategory(category)}>
                  {customFields.filter(field => field.category === category).length} custom fields - view
                </p>
              </div>
            )}
          </div>
        ))}
      </div>

      <div className="margin-top double">
        {showAddForm && (
          <>
            <p className="bold">Add custom field</p>
            <div className="new-custom-field field margin-bottom">
              <AsyncSelect
                entity="customField"
                menuPosition="fixed"
                value={newCustomField?.value}
                onChange={(_id, field) => {
                  setNewCustomFieldValue('')
                  setNewCustomField(field)
                }}
                classNamePrefix="customFieldSelect"
              />
              <CustomFieldInput
                field={newCustomField ?? { type: 'text' }}
                placeholder="Value"
                value={newCustomFieldValue}
                onChange={(e) => setNewCustomFieldValue(e.target.value)}
              />
              <Button small primary onClick={addField} disabled={loading}>
                Add
              </Button>
              {loading && <CircularProgress />}
            </div>
            {!loading && (
              <a className="blue-link" onClick={closeAddForm}>
                Cancel
              </a>
            )}
          </>
        )}
        {!showAddForm && (
          <Button small secondary onClick={() => setShowAddForm(true)}>
            Add custom field
          </Button>
        )}
      </div>
    </div>
  )
}

export default CustomFields
