import OrganisationPickerButton from 'Shared/components/OrganisationPicker/OrganisationPickerButton'
import restClient from 'Shared/hooks/restClient'

export default function OrganisationField({
  value,
  setFieldValue,
  disabled,
  disabledReason = null,
  allowBlank = false,
}) {
  const [organisationName, setOrganisationName] = useState(null)

  useEffect(() => {
    if (!value) {
      setOrganisationName(null)
      return
    }
    restClient
      .get(`/api/organisations/${value}`)
      .then(({ data: { name } }) => setOrganisationName(name))
  }, [value])

  const handlePick = (id, name) => {
    setOrganisationName(name)
    setFieldValue('organisationId', id)
  }

  return (
    <div className="detail">
      <div className="left">
        <p>Organisation</p>
      </div>
      <div className="right field">
        {(organisationName || allowBlank) &&
          <OrganisationPickerButton
            disabled={disabled}
            onPick={handlePick}
            title={organisationName ? `${organisationName} (Change)` : 'Choose Organisation'}
          />
        }
        {disabledReason && <p className="smaller error margin-top">{disabledReason}</p>}
      </div>
    </div>
  )
}
