import './NewBlock.scoped.scss'
import { startCase } from 'Shared/functions/util'

const ICONS = {
  'button': <SvgIconsButton />,
  'heading': <SvgIconsHeading />,
  'ordered_list': <SvgIconsOrderedList />,
  'unordered_list': <SvgIconsUnorderedList />,
  'image': <SvgIconsImage />,
  'paragraph': <SvgIconsAlignLeft />,
  'paragraph_v2': <SvgIconsAlignLeft />,
  'quote': <SvgIconsQuote />,
  'spacer': <SvgIconsSpacer />,
  'divider': <SvgIconsDivider />,
  'table': <SvgIconsHtml />,
  'html': <SvgIconsHtml />,
}

const NAME_REPLACEMENTS = {
  'unordered_list': 'list',
  'paragraph_v2': 'Paragraph'
}

const PRIORITY_ORDER = ['paragraph', 'paragraph_v2', 'image', 'button']

const NewBlock = ({ canClose = true, handleClick, onClose = null }) => {
  const blockTemplates = useSel((s) => s.emailBuilder.blockTemplates || []);

  const visibleTemplates = blockTemplates.filter(template => !template.hidden);

  const sortedTemplates = [...visibleTemplates].sort((a, b) => {
    const aIndex = PRIORITY_ORDER.indexOf(a.name);
    const bIndex = PRIORITY_ORDER.indexOf(b.name);
    if (aIndex === -1 && bIndex === -1) return 0;
    if (aIndex === -1) return 1;
    if (bIndex === -1) return -1;
    return aIndex - bIndex;
  });

  return (
    <div className="new-block-container">
      <div id="new-block-menu" className='new-block'>
        <div className="new-block-header">
          <button className="button naked" onClick={onClose}>
            {canClose && <SvgIconsClose className="no-margin" />}
          </button>
        </div>
        <ul>
          {sortedTemplates.map((template) =>
            <li key={template.id} onClick={() => handleClick(template)}>
              {ICONS[template.name] ? ICONS[template.name] : null}
              <span>{startCase(NAME_REPLACEMENTS[template.name] || template.name)}</span>
            </li>
          )}
        </ul>
      </div>
    </div>
  )
}

export default NewBlock
