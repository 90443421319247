import applyCaseMiddleware from 'axios-case-converter'
import axios from 'axios'
import axiosRetry from 'axios-retry'
import { newrelic } from 'lib/newrelic'

const csrfToken = document.querySelector<HTMLMetaElement>(
  'meta[name=csrf-token]'
)?.content
axios.defaults.headers.common['X-Csrf-Token'] = csrfToken
const axiosInstance = axios.create()
axiosRetry(axiosInstance, {
  retryCondition: (e) => {
    return (
      (!e.response && !e.code) || [502, 503, 504, 520, 429].includes(e.response?.status)
    )
  },
  retryDelay: (retryCount, error) => {
    if (error.response) {
      const retry_after = error.response.headers['retry-after']
      if (retry_after) {
        return retry_after
      }
    }

    // Can also just return 0 here for no delay if one isn't specified
    return axiosRetry.exponentialDelay(retryCount)
  },
})
const restClient = applyCaseMiddleware(axiosInstance)

// Wrap get, post, put, delete in custom function to capture stack trace
const originalGet = restClient.get
restClient.get = function (url, config) {
  const stackTrace = new Error('Capture stack trace').stack

  const modifiedConfig = config || {}
  modifiedConfig.metadata = modifiedConfig.metadata || {}
  modifiedConfig.metadata.initiatingStackTrace = stackTrace

  const args = [url, modifiedConfig]

  return originalGet.apply(this, args)
}

const originalPost = restClient.post
restClient.post = function (url, data, config) {
  const stackTrace = new Error('Capture stack trace').stack

  const modifiedConfig = config || {}
  modifiedConfig.metadata = modifiedConfig.metadata || {}
  modifiedConfig.metadata.initiatingStackTrace = stackTrace

  const args = [url, data, modifiedConfig]

  return originalPost.apply(this, args)
}

const originalPut = restClient.put
restClient.put = function (url, data, config) {
  const stackTrace = new Error('Capture stack trace').stack

  const modifiedConfig = config || {}
  modifiedConfig.metadata = modifiedConfig.metadata || {}
  modifiedConfig.metadata.initiatingStackTrace = stackTrace

  const args = [url, data, modifiedConfig]

  return originalPut.apply(this, args)
}

const originalDelete = restClient.delete
restClient.delete = function (url, config) {
  const stackTrace = new Error('Capture stack trace').stack

  const modifiedConfig = config || {}
  modifiedConfig.metadata = modifiedConfig.metadata || {}
  modifiedConfig.metadata.initiatingStackTrace = stackTrace

  const args = [url, modifiedConfig]

  return originalDelete.apply(this, args)
}

restClient.interceptors.response.use(undefined, async (error) => {
  if (error.response) {
    // network error etc. don't have response
    if (error.response.status === 403 || error.response.status === 422) {
      // If a 403 is received from the server, check if the user is logged in
      const loginStatus = await restClient.get('/api/users/logged_in')
      if (loginStatus.data.loggedIn === false) {
        // If the user is logged out, redirect to the login page
        window.location.href = '/users/sign_in?client_side_logout=1'
        return new Promise(() => {}) // Prevent the rest of the chain from executing
      }
    }
  }

  return Promise.reject(error)
})

restClient.interceptors.response.use(undefined, (error) => {
  const config = error?.config || {}
  const response = error?.response || {}

  error.name = `${(config.method || '').toUpperCase()} ${config.url} ${response.status}`
    .replace(/\/\d+/g, '/[id]')
    .replace(/[a-f0-9]{32}/g, '[hash]')

  if (config.metadata?.initiatingStackTrace) {
    // Assuming error.stack and config.metadata.initiatingStackTrace are both strings
    const originalStackArray = error.stack.split('\n')
    const initiatingStackArray = config.metadata.initiatingStackTrace.split('\n')

    // Concatenate the arrays correctly using the spread operator or concat method
    const totalStack = [...originalStackArray, ...initiatingStackArray]
      .filter((line) => !line.includes('nrWrapper')) // Filter out 'nrWrapper' lines
      .filter((line) => !line.includes('Error: Capture stack trace')) // Filter out the custom error line

    error.stack = totalStack.join('\n')
  }

  newrelic?.noticeError(error)

  return Promise.reject(error)
})

export default restClient
