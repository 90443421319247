import RrIcon from 'Shared/components/RrIcon'
import RrIconButton from 'Shared/components/RrIconButton'
import './MicSetupTitle.scoped.scss'

const getTitle = (deviceType) => {
  switch (deviceType) {
    case 'audioinput':
      return i18n.t('user.campaigns.phone.device_setup.setup_mic')
  }
}

const MicSetupTitle = ({ deviceType, onClose }) => {
  return (
    <div className="box no-padding align-center avds-card-title">
      <h3 className="text-center full-width">{getTitle(deviceType)}</h3>
      <RrIconButton onClick={onClose}>
        <RrIcon iconName="close">
          <RrIcon iconName="close" />
        </RrIcon>
      </RrIconButton>
    </div>
  )
}

export default MicSetupTitle
