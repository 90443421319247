import ReactPinField from 'react-pin-field'
import restClient from "Shared/hooks/restClient"
import { CircularProgress } from "@material-ui/core"
import TextField from "Shared/components/TextField"

const CheckPin = ({email, verifiedEmailId, onValidate, back}) => {
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const codeRef = useRef(null)

  const reset = () => {
    setLoading(false)
    resetCodeField()
    setErrorMessage('')
  }

  const resetCodeField = () => {
    // Setting the component's value to '' doesn't work
    window.setTimeout(() => {
      codeRef.current.forEach(input => input.value = '')
    }, 0)
  }

  const confirmEmail = (code) => {
    setLoading(true)
    restClient.post(`/verified_emails/${verifiedEmailId}/confirm`, { code }).then((res) => {
      if (res.data.status === 'validated') {
        onValidate()
      } else {
        resetCodeField()
        setErrorMessage('Sorry, that code didn’t match. Please try again')
      }
      setLoading(false)
    }).catch(reset)
  }

  useEffect(() => {
    codeRef.current[0].focus()
  }, [])

  return (
    <>
      <TextField
        label="Email address"
        id="email-address-input"
        value={email}
        disabled />
      <div className="field">
        <p>A code has been sent to the email address. Please enter below.</p>
        <div className="pin-field-container">
          <ReactPinField
            className="pin-field"
            type='text'
            length={6}
            validate={/^[0-9]$/}
            onComplete={confirmEmail}
            ref={codeRef}
            onResolveKey={() => setErrorMessage('')} />
          <div className="progress-container">
            {loading && <CircularProgress size={20} />}
          </div>
        </div>
        <p className="error">{errorMessage}</p>
        <p><button className="button naked" onClick={back}>Back</button></p>
      </div>
    </>
  )

}

export default CheckPin
