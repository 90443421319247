import Grid from '@material-ui/core/Grid'
import dayJs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import InfoItem from './InfoItem'

dayJs.extend(relativeTime)

const InfoSection = ({ section }) => {
  return (
    <Grid item container direction="column">
      <p className="larger bold capitalise no-margin-top">{section.title}</p>
      <Grid item container direction="row">
        {section.columns.map((column, idx) => (
          <Grid
            {...{ xs: 12, md: 6, spacing: 2, direction: 'column', item: true }}
            container
            key={`${section.title}-${idx}`}
          >
            {column.map((row, i) => (
              <InfoItem
                key={i}
                type={row.type}
                identifier={row.label}
                content={row.value}
              />
            ))}
          </Grid>
        ))}
      </Grid>
    </Grid>
  )
}

export default InfoSection
