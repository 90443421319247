import './Text.scoped.scss'

export default function Text({response, setResponse, disabled = false}) {
  const handleChange = (event) => {
    setResponse(event.target.value);
  }

  return (
    <div className="answers grow">
      <textarea 
        placeholder="Type an answer..." 
        onChange={handleChange} 
        disabled={disabled}
        value={response || ''}>
      </textarea>
    </div>
  )
}
