import React, { useCallback, useEffect, useRef } from 'react'
import { getReadableDuration } from 'Shared/functions/util'
import './DurationInput.scoped.scss'

const DurationInput = React.memo((props) => <TimeInput {...props} />)

function TimeInput(props) {
  const minutesRef = useRef(null)
  const secondsRef = useRef(null)

  const reset = useCallback(() => {
    const duration = getReadableDuration(props.duration)

    minutesRef.current.value = duration.minutes
    secondsRef.current.value = duration.seconds
  }, [props.duration])

  useEffect(() => {
    if (minutesRef.current && secondsRef.current) {
      reset()
    }
  }, [reset])

  const setDuration = () => {
    const minutes = Math.max(parseInt(minutesRef.current.value), 0)
    const seconds = Math.max(Math.min(parseInt(secondsRef.current.value), 59), 0)
    const duration = minutes * 60 + seconds

    if (Number.isInteger(minutes) && Number.isInteger(seconds)) {
      props.onChange(duration)
    } else {
      reset()
    }
  }

  const renderMinute = () => {
    const handleKeyDown = (event) => {
      if (event.key === 'ArrowRight') {
        secondsRef.current && secondsRef.current.focus()
      }

      if (event.key === 'Enter') {
        event.stopPropagation()
        setDuration()
        props.onEnter?.()
      }
    }

    return (
      <input
        ref={minutesRef}
        type="number"
        min="0"
        placeholder="MM"
        onClick={() => {
          minutesRef.current && minutesRef.current.select()
        }}
        onBlur={setDuration}
        onChange={setDuration}
        onKeyDown={handleKeyDown}
      />
    )
  }

  const renderSecond = () => {
    const handleKeyDown = (event) => {
      if (event.key === 'ArrowLeft') {
        minutesRef.current && minutesRef.current.focus()
      }

      if (event.key === 'Enter') {
        event.stopPropagation()
        setDuration()
        props.onEnter?.()
      }
    }

    return (
      <input
        ref={secondsRef}
        type="number"
        min="0"
        max="59"
        placeholder="SS"
        onClick={() => {
          secondsRef.current && secondsRef.current.select()
        }}
        onBlur={setDuration}
        onChange={setDuration}
        onKeyDown={handleKeyDown}
      />
    )
  }

  return (
    <div className="duration-input__container fake-input">
      {renderMinute()}
      <span className="duration-input__divider">:</span>
      {renderSecond()}
    </div>
  )
}

export { DurationInput, TimeInput }
