import useAct from 'DesktopCaller/useAct'
import useData from 'DesktopCaller/useData'
import ServerWaitButton from 'Shared/components/ServerWaitButton'

export default function SmsQuestion({ id }) {
  const act = useAct()
  const script = useSel(s => s.local.currentScript)
  const currentQuestionId = useData(rs => rs.call.currentQuestionId)
  const inputStage = useData(rs => rs.callSession.inputStage)
  const current = Number(currentQuestionId) === Number(id) && inputStage === 'answers'
  const storedAnswerText = useSel(s => s.desktopCaller.answerTexts[id])

  function handleTransfer() {
    return act.desktopCaller.answerTransferQuestion(id, 'transfer')
  }

  function handleSkip() {
    return act.desktopCaller.answerTransferQuestion(id, 'skip')
  }

  return <div>
    { current && <div>
      <ServerWaitButton
        className="button primary margin-top margin-right"
        onClick={handleTransfer}
        >{ i18n.t('user.campaigns.phone.transfer_call') }
      </ServerWaitButton>
      <ServerWaitButton
        className="button secondary margin-top"
        onClick={handleSkip}
        >{ i18n.t('shared.messages.skip') }
      </ServerWaitButton>
    </div> }

    { !current && <div className="bold">
      <p>{storedAnswerText === 'transfer' ? i18n.t('user.campaigns.phone.transferred') : i18n.t('shared.messages.skipped') }</p>
    </div> }
  </div>
}
