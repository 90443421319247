import Controls from './Controls'
import BasicInfo from './BasicInfo'
import ExtendedInfo from './ExtendedInfo'
import CustomFields from './CustomFields'
import Widgets from './Widgets'
import ActivityLog from './ActivityLog/ActivityLog'
import Fade from '@material-ui/core/Fade'
import Boxes from 'Shared/components/Boxes'
import FloatingMenu from 'Shared/components/FloatingMenu/FloatingMenu'
import useSections from 'TargetProfile/hooks/useSections'
import './TargetProfile.scoped.scss'

function Discarded() {
  return (
    <div className="box">
      <p className="no-margin-top no-margin-bottom dark-grey">
        This member has been permanently deleted.
      </p>
    </div>
  )
}

const TargetProfile = ({ targetId, widgets }) => {
  const act = useAct()
  const target = useSel((s) => s.target)
  const discardedAt = useSel((s) => s.target.discardedAt)
  const showFullInfo = target && !discardedAt
  const sections = useSections(discardedAt, widgets)
  const extendedInfo = discardedAt ? <Discarded /> : <ExtendedInfo />

  useEffect(() => {
    act.target.load(targetId)
  }, [])

  if (!target) {
    return null
  }

  return (
    <>
      <Controls />

      <div className="target-profile">
        <div className="left">
          <FloatingMenu items={sections} />
        </div>
        <div className="right">
          <Fade in={!_.isEmpty(target)}>
            <Boxes
              id="summary"
              unbox={true}
              sizes={['third', 'two-thirds']}
              boxes={[<BasicInfo />, extendedInfo]}
              containerClass={'gutter-bottom'}
            />
          </Fade>

          {showFullInfo && (
            <>
              <Boxes
                id="custom_fields"
                unbox={true}
                sizes={['full-width']}
                boxes={[<CustomFields />]}
                containerClass={'gutter-bottom'}
              />

              <Widgets widgets={widgets} />

              <Boxes
                id="activity"
                unbox={true}
                sizes={['full-width']}
                boxes={[<ActivityLog target={target} />]}
              />
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default TargetProfile
