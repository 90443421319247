import Table from "Shared/components/DataTable/Table"
import restClient from 'Shared/hooks/restClient'
import './ManageCustomFields.scoped.scss'

const ManageCustomFields = () => {

  const handleTableClick = (e) => {
    if(e.target.classList.contains('remove-custom-field')) {
      remove(e.target.dataset.customFieldId)
    } else if (e.target.classList.contains('restore-custom-field')) {
      restore(e.target.dataset.customFieldId)
    }
  }

  const tableProps = {
    table: 'customFields',
    scope: {},
    language: { emptyTable: 'There are no custom fields yet' },
    onClick: handleTableClick,
  }

  const reloadTable = () => {
    window.$('table').DataTable().ajax.reload()
  }

  const remove = (id) => {
    if (confirm('Are you sure?')) {
      restClient
        .delete(`/api/custom_fields/${id}`, )
        .then(reloadTable)
    }
  }

  const restore = (id) => {
    restClient
      .post(`/api/custom_fields/${id}/restore`)
      .then(reloadTable)
  }

  return (
    <div className="section">
      <div className="box">
        <div className="header">
          <h2>Your custom fields</h2>
          <p><a className="button primary" type="button" href="/admin/custom_fields/new">Add a custom field</a></p>
        </div>

        <Table {...tableProps} />

      </div>
    </div> 
  )
}

export default ManageCustomFields
