import * as React from 'react'

function SvgTickGreen(props) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"  {...props}>
      <rect width="24" height="24" rx="6" fill="#36B2A4"/>
      <path d="M16.769 7.31769C17.1458 6.91393 17.7786 6.89211 18.1823 7.26895C18.5861 7.64578 18.6079 8.27857 18.2311 8.68233L11.2311 16.1823C10.8655 16.5741 10.2561 16.608 9.84923 16.2593L6.34923 13.2593C5.9299 12.8998 5.88134 12.2685 6.24076 11.8492C6.60018 11.4299 7.23148 11.3813 7.65081 11.7408L10.423 14.1169L16.769 7.31769Z" fill="white"/>
    </svg>
  )
}

export default SvgTickGreen

