const columns = [
  {
    data: 'name',
    className: 'bold',
    width: '55%',
    render: function (data, type, row, meta) {
      return `<a href='${row['url']}'>${data}</a>`
    },
  },
  {
    data: 'target_count',
    width: '25%',
    render: function (data, type, row, meta) {
      const count = Number(row['target_count']).toLocaleString()
      return `<span>${count}</span>`
    }
  },
  { data: 'created_at', width: '20%'}
]

const headers = [
  'Name',
  'Number of people',
  'Created'
]

let filters = []
if (window.ENV.CURRENT_USER_PERMISSIONS && window.ENV.CURRENT_USER_PERMISSIONS.includes('manage_client_admin')) {
  const organisationKinds = JSON.parse(window.ENV.ORGANISATION_KINDS).map(kind => ({
    label: kind.label,
    value: kind.external_type
  }));
  filters = [
    { name: 'Organisation', kind: 'async', entity: 'organisation' }
  ]

  if (organisationKinds.length > 1) {
    filters.push({
      name: 'Organisation type', kind: 'radio-async', entity: 'external_type', options: organisationKinds
    })
  }
}

const sorters = [
  { column: 2, label: 'Created', order: 'desc' },
  { column: 0, label: 'Alphabetically (A-Z)', order: 'asc' },
  { column: 0, label: 'Alphabetically (Z-A)', order: 'desc' }
]

const order = [[ 2, "desc" ]]

export { columns, headers, filters, sorters, order }
