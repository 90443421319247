import * as React from 'react'

function ActivityLogSms(props) {
  return (
    <svg width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>

      <path
        transform="scale(1.4) translate(-2, -4)"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 11C8.34315 11 7 12.3431 7 14V18C7 19.6569 8.34315 21 10 21H16.6667C16.7788 21 16.8895 20.9938 16.9985 20.9819L17.9762 21.9596C18.6062 22.5895 19.6833 22.1434 19.6833 21.2525V19L19.464 19.0862C19.5949 18.7494 19.6667 18.3831 19.6667 18V14C19.6667 12.3431 18.3235 11 16.6667 11H10Z"
        fill="#191936"
      />
      
      <path
        transform="scale(1.4) translate(-2, -4)"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 17C9.44772 17 9 16.5523 9 16C9 15.4477 9.44772 15 10 15C10.5523 15 11 15.4477 11 16C11 16.5523 10.5523 17 10 17ZM13.3333 17C12.781 17 12.3333 16.5523 12.3333 16C12.3333 15.4477 12.781 15 13.3333 15C13.8856 15 14.3333 15.4477 14.3333 16C14.3333 16.5523 13.8856 17 13.3333 17ZM16.6667 17C16.1144 17 15.6667 16.5523 15.6667 16C15.6667 15.4477 16.1144 15 16.6667 15C17.219 15 17.6667 15.4477 17.6667 16C17.6667 16.5523 17.219 17 16.6667 17Z"
        fill="white"
      />
    </svg>
  )
}

export default ActivityLogSms
