const columns = [
  {
    data: 'name',
    width: '35%',
    className: 'bold',
    render: (data, type, row, meta) => {
      return row.status === 'live'
        ? `<a href="/admin/surveys/${row.DT_RowId}/overview">${data}</a>`
        : `<a href="/admin/surveys/${row.DT_RowId}/build">${data}</a>`
    },
  },
  {
    data: 'status',
    width: '20%',
    orderable: false,
    render: function (data, type, row, meta) {
      function getClass() {
        const status = row['status']
        if (status === 'live' || status === 'sent') return 'accent'
        if (status === 'closed') return 'blue-100'

        return 'purple-60'
      }

      return `<span class="bold ${getClass()} capitalize">${row['status']}</span>`
    },
  },
  {
    data: 'created_at',
  },
]

const headers = ['Name', 'Status', 'Created']

const filters = [
  {
    name: 'Status', column: 1, kind: 'radio', options: [
      { value: 'draft', label: 'Draft' },
      { value: 'live', label: 'Live' },
      { value: 'closed', label: 'Closed' }
    ]
  }
]

const sorters = [
  { column: 2, label: 'Created', order: 'desc' },
  { column: 0, label: 'Alphabetically (A-Z)', order: 'asc' },
  { column: 0, label: 'Alphabetically (Z-A)', order: 'desc' }
]

const order = [[ 2, "desc" ]]

export { columns, headers, filters, sorters, order }
