const columns = [
  {
    data: 'name',
    width: '30%',
    className: 'bold',
    render: (data, type, row, meta) => `<a href="/admin/users/${row.DT_RowId}">${data}</a>`,
  },
  { data: 'email', width: '33%'},
  { data: 'membership_number', width: '15%'},
  { data: 'organisations', width: '12%'},
  {
    data: 'id',
    width: '10%',
    className: 'center',
    render: (data, type, row) => {
      if (row.rejected === 'false') {
        return `
          <button class="button secondary small approve-user-button" data-user-id="${data}">Approve</button>&nbsp;
          <button class="button red small margin-top half reject-user-button" data-user-id="${data}">Reject</button>
        `
      }
      return ''
    }
  },
  {
    data: 'rejected',
    visible: false,
  }
]

const headers = [
  'Name',
  'Email',
  'Membership Number',
  'Organisations',
  '',
]

const filters = []

const sorters = []

const order = [[1, "asc"]]

export { columns, headers, filters, sorters, order }
