import ContentVariant from './EmailContentTabs/ContentVariant'

export default function EmailContentTabs() {
  const act = useAct()
  const contentVariants = useSel(s => _.orderBy(s.campaign.emailBlast.contentVariants || [], 'id'))
  const currentContentVariantId = useSel(s => s.local.currentContentVariantId)

  if (contentVariants.length > 0 && !contentVariants.find(cv => cv.id === currentContentVariantId)) {
    act.local.upsert({ currentContentVariantId: contentVariants[0].id })
  }

  if (contentVariants.length <= 1) return null

  const rows = contentVariants.reduce((acc, cv, index) => {
    const rowIndex = Math.floor(index / 4);
    if (!acc[rowIndex]) {
      acc[rowIndex] = [];
    }
    acc[rowIndex].push(cv);
    return acc;
  }, []);

  return (
    <div className="tabs auto-height">
      {rows.map((row, rowIndex) => (
        <div className="row margin-bottom" key={`row-${rowIndex}`}>
          {row.map(cv => (
            <div className="column quarter" key={cv.id}>
              <ContentVariant
                id={cv.id}
                current={currentContentVariantId === cv.id}
              />
            </div>
          ))}
        </div>
      ))}
    </div>
  )
}

