import store from 'SurveyBuilder/store'
import { Provider } from 'react-redux'
import Builder from 'SurveyBuilder/components/Builder'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { DndProvider } from 'react-dnd'

export default function SurveyBuilder(params) {
  return (
    <Provider store={store}>
      <DndProvider backend={HTML5Backend}>
        <ErrorBoundary>
          <Builder {...params} />
        </ErrorBoundary>
      </DndProvider>
    </Provider>
  )
}

