import * as React from 'react'

function SvgBack(props) {
  return (
    <svg width={7} height={12} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.586 5.631l3.821-3.82a1.061 1.061 0 00-1.5-1.5L.293 4.925a.999.999 0 000 1.413l4.614 4.615a1.061 1.061 0 001.5-1.5l-3.82-3.821z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgBack
