import Collapse from '@material-ui/core/Collapse'
import Grid from '@material-ui/core/Grid'
import ActiveStorageProvider from 'react-activestorage-provider'
import LinearProgress from '@material-ui/core/LinearProgress'
import RrButton from '../RrButton'
import { DEFAULTS } from './constants'
import Task from 'Shared/components/Task'

const RailsUploader = ({ endpoint, file, onComplete, onError }) => {
  const buttonRef = useRef()

  useEffect(() => {
    buttonRef.current?.click()
  }, [])

  const display = (watching) => {
    switch (watching?.state) {
      case 'waiting':
      case 'uploading':
        return (
          <div>
            <LinearProgress variant={'determinate'} value={watching.progress || 0} />
          </div>
        )
      case 'error':
        return (
          <p>
            Error uploading {watching.file.name}: {watching.error}
          </p>
        )
      case 'finished':
        return
    }
  }

  return (
    <ActiveStorageProvider
      endpoint={endpoint}
      onSubmit={onComplete}
      onError={onError}
      render={({ handleUpload, uploads }) => {
        const watching = uploads[0]
        return (
          <div style={{ width: '100%', height: '100%' }}>
            {display(watching)}
            <button hidden ref={buttonRef} onClick={() => handleUpload([file])} />
          </div>
        )
      }}
    />
  )
}

const Upload = ({ show, upload, csv, listName, kind, uploadSupporters, confirmedColumns }) => {
  const [uploading, setUploading] = useState(true)
  const [taskId, setTaskId] = useState(null)
  const [processing, setProcessing] = useState(false)
  const [processingState, setProcessingState] = useState(null)
  const [error, setError] = useState(null)

  const onComplete = (uploaded) => {
    setUploading(false)
    setTaskId(uploaded.task_id)
    setProcessing(true)
    if (upload.forwardAuto) {
      setTimeout(() => (window.location.href = upload.forwardUrl), 1000)
    }
  }
  const onError = (error: Response) => {
    setUploading(false)
    setProcessing(false)
    error
      .json()
      .then((json) => {
        setError(json?.message || 'There was an error uploading your file')
      })
      .catch((err) => {
        setError('There was an error uploading your file')
      })
  }

  const percentage = (partialValue, totalValue) => {
    if (partialValue === 0 || totalValue === 0) {
      return 0
    }
    return Math.round((100 * partialValue) / totalValue)
  }

  const endpoint = {
    ...upload.endpoint,
    path: `${upload.endpoint.path}?kind=${kind}`
      + `&list_name=${listName}`
      + `&list_column=${
        confirmedColumns[0] 
          ? confirmedColumns[0].toLowerCase().replace(/ /g, '_')
          : ''
      }`
      + `&upload_supporters=${uploadSupporters ? 'y' : 'n'}`,
  }

  const getUploader = () => {
    switch (upload.method) {
      case 'rails':
      default:
        return (
          <RailsUploader
            endpoint={endpoint}
            file={csv.ref}
            onComplete={onComplete}
            onError={onError}
          />
        )
    }
  }

  return (
    <Collapse in={show} className="cc-content-container">
      <Grid item>
        <Grid item>
          <h4>
            {!processing && error
              ? error
              : DEFAULTS.paragraphs[`upload${uploading ? '' : 'Complete'}`]}
          </h4>
          {taskId && <Task id={taskId} />}
          <Collapse
            in={!uploading && !upload.forwardAuto && !error}
            style={{ textAlign: 'center' }}
          >
            <RrButton
              title={kind === 'list' ? 'Go to lists' : 'Go to the audience list'}
              type="primary"
              onClick={() => (window.location.href = upload.forwardUrl)}
            />
          </Collapse>
        </Grid>
        <Grid item container>
          {show && getUploader()}
        </Grid>
      </Grid>
    </Collapse>
  )
}

export default Upload
