import * as React from 'react'

function SvgSmsOutgoing(props) {
  return (
    <svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.492 11.809a.5.5 0 00.853-.355l-.01-2.717V3.684c0-.93-.754-1.684-1.684-1.684h-7.86c-.93 0-1.684.754-1.684 1.684V6h3.56a3 3 0 013 3v1.421h2.437l1.388 1.388z"
        fill="#2849D6"
      />
      <path
        opacity={0.3}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.324 12V8.667c0-.737.597-1.334 1.334-1.334H7.99c.736 0 1.333.597 1.333 1.334V12c0 .736-.597 1.333-1.333 1.333H2.735l-.548.524a.5.5 0 01-.845-.361v-1.28A1.344 1.344 0 011.324 12zM4 9.667c0-.185.15-.334.334-.334h3.333a.333.333 0 010 .667H4.334A.333.333 0 014 9.667zm2.334 1a.333.333 0 100 .666h1.333a.333.333 0 000-.666H6.334z"
        fill="#2849D6"
      />
    </svg>
  )
}

export default SvgSmsOutgoing
