import { startCase } from 'Shared/functions/util'
import './ActivityTopic.scoped.scss'

const Topic = styled.span`
  font-size: 16px;
  font-weight: 700;
  > span {
    opacity: 0.5;
    margin: 0 6px;
  }
`

export const ActivityTopic = ({ topic }) => {
  const formatAcronym = (string) => {
    const acronyms = ['sms']
    return acronyms.includes(string) ? string.toUpperCase() : startCase(string)
  }

  const [type, ...rest] = topic.split('-')
  return (
    <Topic>
      {formatAcronym(type)}
      {!!rest.length && <span className="purple-40 dot">{'●'}</span>}
      {rest.map((word) => startCase(word)).join(' ')}
    </Topic>
  )
}
