export default (dis, store, client) => {
  function create() {
    const { createCampaign } = store.getState()
    const { name, description, searchId, cloneSearchId, type, subType, cloneFromId, duplicateSearch, whatsApp } = createCampaign
    dis({ type: 'createCampaign/upsert', payload: { creating: true } })

    const payload = {
      campaignType: type,
      subType,
      name,
      description,
      searchId,
      cloneSearchId
    }

    // if it's an SMS-able campaign, make sure we indicate which channel was selected
    if (type.includes('sms')) {
      payload.blastKind = whatsApp ? 'whatsapp' : 'sms'
    }

    const request = cloneFromId ?
      client.post(`/api/campaigns/${cloneFromId}/clone`, { name, duplicateSearch }) :
      client.post(`/api/campaigns`, payload)
    request.then(({ data }) => { window.location = data.redirectUrl })
      .catch(() => {
        dis({ type: 'createCampaign/upsert', payload: { creating: false, error: true, errorMessage: "Something went wrong creating the campaign" } })
      })
  }

  function setSearchId(searchId) {
    return dis({ type: 'createCampaign/upsert', payload: { searchId } })
  }

  function setCloneSearchId(cloneSearchId) {
    return dis({ type: 'createCampaign/upsert', payload: { cloneSearchId } })
  }

  function updateDescription(description) {
    return dis({ type: 'createCampaign/upsert', payload: { description } })
  }

  function updateName(name) {
    return dis({ type: 'createCampaign/upsert', payload: { name } })
  }
  function updateField(field, value) {

    return dis({ type: 'createCampaign/upsert', payload: { [field]: value } })
  }

  function reset() {
    return dis({ type: 'createCampaign/reset' })
  }

  return { create, setSearchId, setCloneSearchId, updateDescription, updateName, updateField, reset }
}
