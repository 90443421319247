import './Search.scoped.scss'
import './SupportCard.scoped.scss'

const SupportCard = ({ create = false }) => {
  const supportEmail = useSel(s => s.currentUser.clientSupportEmail)
  return (
    <div className="card support-card search-card-normal">
      <div className="icon"><span>?</span></div>
      { create && <p>You've not set up any quick start audiences.</p> }
      { create && <a className='button primary small' href='/admin/audience'>Get started</a> }

      { !create && <p>Haven&rsquo;t found what you&rsquo;re looking for?</p> }
      { !create && <a href={`mailto:${supportEmail}`}>Let us know</a> }
    </div>
  )
}

export default SupportCard
