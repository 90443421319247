import count from 'lib/sms-length'
import TextMessageContentVariant from './TextMessageContentVariant'
import TextMessageContentTabs from './TextMessageContentTabs'
import Step from './Step'

const MAX_SMS_LENGTH = 1440

function TextMessageContentShortDescriptor({ complete }) {
  const contentVariants = useSel(s => s.campaign.textMessageBlast.contentVariants)
  const mergeTags = useSel(s => s.campaignBuilder.mergeTags)
  const body = contentVariants[0]?.content?.body

  let prettyBody = '<p>' + (body || '').replaceAll('\n', '</p><p>') + '</p>'
  for (const [slug, tag] of Object.entries(mergeTags)) {
    prettyBody = prettyBody.replaceAll(
      `{{${slug}}}`,
      `<span class="merge-tag">${tag}</span>`
    )
  }

  return <div>
    {complete && contentVariants.length === 1 && <p className='no-margin-bottom' dangerouslySetInnerHTML={{ __html: body }}></p> }
    {complete && contentVariants.length > 1 && <p className='no-margin-bottom'>You've added {contentVariants.length} versions</p> }
    {!complete && <p className='incomplete no-margin-bottom'>What does the text say?</p>}
  </div>
}

export default function TextMessageContent({ number }) {
  const currentContentVariantId = useSel(s => s.local.currentContentVariantId)
  const { renderedContent } = useSel(s => s.campaignBuilder)
  const contentVariants = useSel(s => s.campaign.textMessageBlast.contentVariants)
  const act = useAct()

  const complete = _.every(contentVariants, cv => cv.content.body && cv.content.body.length > 0)
  const { length, characterPerMessage, remaining, messages: smsCount } = count(renderedContent.body)
  const valid = length <= MAX_SMS_LENGTH

  function addVariant() {
    act.campaign.addTextMessageBlastContentVariant()
  }

  const headerActions = contentVariants.length <= 5 ? (
    <button className='button secondary' onClick={addVariant}>Add version</button>
  ) : (
    <button className='button secondary' disabled>Add version</button>
  )

  return <Step
    name='Content'
    editor='text'
    complete={complete}
    canClickFinish={valid}
    shortDescriptor={<TextMessageContentShortDescriptor complete={complete} />}
    headerActions={headerActions}
  >
    <TextMessageContentTabs showDelete />
    { contentVariants.find(cv => cv.id === currentContentVariantId) && <TextMessageContentVariant id={currentContentVariantId} key={currentContentVariantId} /> }
  </Step>
}
