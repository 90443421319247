import './Score.scoped.scss'

export default function Score({ response, setResponse, name, settings }) {
  const handleChange = (event) => {
    if (event.target.checked) {
      setResponse(+event.target.value);
    }
  }

  const options = (new Array((settings.max || 10) - (settings.min || 0)+1))
    .fill(0).map((item, index) => index + (settings.min || 0))

  return (
    <div className="answers">
      {options.map((option) => (
        <label key={option}>
          <input type="radio" 
            name={name}
            value={option} 
            checked={option === response}
            onChange={handleChange} />
          {option}
        </label>
      ))}
    </div>
  )
}
