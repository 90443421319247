const columns = [
    {
      data: 'name',
      width: '40%',
      className: 'bold',
      render: function (data, type, row, meta) {
        return `<a href='${row['url']}'>${data}</a>`
      },
    },
    {
      data: 'target_count',
      width: '18%',
      render: function (data, type, row, meta) {
        const count = Number(row['target_count']).toLocaleString()
        return `<span>${count}</span>`
      }
    },
    { data: 'created_at', width: '15%'},
    { data: 'last_synced_at', width: '15%'},
    { data: 'status', width: '12%'}
  ]

  const headers = [
    'Name',
    'Number of people',
    'Created',
    'Last synced',
    'Status'
  ]

  const filters = [

  ]

  const sorters = [
    { column: 2, label: 'Created', order: 'desc' },
    { column: 0, label: 'Alphabetically (A-Z)', order: 'asc' },
    { column: 0, label: 'Alphabetically (Z-A)', order: 'desc' }
  ]

  const order = [[ 2, "desc" ]]

  export { columns, headers, filters, sorters, order }