import NewDataTable from 'Shared/components/NewDataTable/NewDataTable'
import CircularProgress from '@material-ui/core/CircularProgress'
import formatCurrency from 'Shared/functions/formatCurrency'
import formatDateWithYear from 'Shared/functions/formatDateWithYear'
import { connect } from 'react-redux'
import { selectTransactions } from '../selectors'
import { tr } from 'date-fns/locale'

const NO_DATA_MESSAGE = "There's no transaction history to display."

const columns = [
  {
    key: 'date',
    label: 'Date',
    sortable: true,
    render: (column) => {
      if (column === '9999999999999') {
        return 'Unpaid'
      }
      return formatDateWithYear(column)
    },
  },
  {
    key: 'type',
    label: 'Type',
    sortable: true,
    render: (v) => (v === 'credit' ? 'Credit added' : 'Credit deducted'),
  },
  {
    key: 'notes',
    label: 'Notes',
    sortable: false,
    rawRender: (v, { hasPdf, hasStripeReceipt, originalId }) => {
      if (hasPdf) {
        const notes = v || 'Download invoice'
        return `<a href="${`/api/credit_top_ups/${originalId}/pdf`}" target="_blank">${notes}</a>`
      } else if (hasStripeReceipt) {
        const notes = v || 'View receipt'
        return `<a href="${`/api/credit_top_ups/${originalId}/receipt`}" target="_blank">${notes}</a>`
      }
      return `<span>${v || ''}</span>`
    }
  },
  {
    key: 'amount',
    label: 'Amount',
    sortable: true,
    render: (v, { type }) =>
      `${type === 'credit' ? '+' : '-'} ${formatCurrency(v, window.ENV.CREDIT_CURRENCY)}`,
  },
]

function Transactions({ transactions }) {
  return (
    <div className="box">
      {transactions.length > 0 && (
        <NewDataTable
          columns={columns}
          data={transactions}
          defaultSort={{ date: 'desc' }}
        />
      )}
      {transactions.length === 0 && <p>{NO_DATA_MESSAGE}</p>}
    </div>
  )
}

const mapStateToProps = (state) => ({
  transactions: selectTransactions(state),
})

export default connect(mapStateToProps)(Transactions)
