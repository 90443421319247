import restClient from 'Shared/hooks/restClient'
import BackdropModal from 'Shared/components/BackdropModal/BackdropModal'
import { ManageMenuPropsHookResult } from '../hooks'
import { create } from 'react-modal-promise'

export function useSearchActions(target) {
  return [
    {
      type: 'menu',
      props: {
        type: 'search',
        target,
      },
    },
  ]
}

export function useSearchMenuProps({ id: searchId }): ManageMenuPropsHookResult {
  const act = useAct()
  const search = useSel((s) => s.search)
  useEffect(() => {
    if (!search?.id == searchId) {
      act.search.load(searchId)
    }
  })
  const items = useMemo(() => {
    const items = [
      {
        title: 'Edit audience',
        onClick: () => {
          window.location.href = `/admin/searches/${searchId}/build`
        },
      },
      {
        title: 'Duplicate audience',
        onClick: () => {
          act.search.clone(searchId)
        },
      },
      search?.id != searchId || search?.recalculating
        ? null
        : {
            title: 'Refresh audience',
            onClick: () =>
              act.search
                .refreshAudience(searchId)
                .then(() => {
                  create(({ isOpen, onResolve }) => (
                    <BackdropModal
                      title="Refresh audience"
                      onCancel={onResolve}
                      open={isOpen}
                    >
                      <div className="wizard">
                        <div className="step">
                          <h3>
                            Your audience will be regenerated in the next few minutes.
                          </h3>
                          <button
                            onClick={onResolve}
                            type="button"
                            className="primary button no-margin-left"
                          >
                            Ok
                          </button>
                        </div>
                      </div>
                    </BackdropModal>
                  ))()
                })
                .catch(() => {
                  create(({ isOpen, onResolve }) => (
                    <BackdropModal
                      title="Refresh audience"
                      onCancel={onResolve}
                      open={isOpen}
                    >
                      <div className="wizard">
                        <div className="step">
                          <h3>Something went wrong. Try to do it later.</h3>
                          <button
                            onClick={onResolve}
                            type="button"
                            className="primary button no-margin-left"
                          >
                            Ok
                          </button>
                        </div>
                      </div>
                    </BackdropModal>
                  ))()
                }),
          },
      {
        title: 'Delete Audience',
        confirmValue: 'delete',
        className: 'danger',
        onClick: () => {
          restClient.delete(`/api/searches/${searchId}`).then(() => {
            window.location.href = '/admin/audience'
          })
        },
      },
    ]

    return items.filter((_) => _)
  }, [searchId, search])

  const childrenComponents = useMemo(() => {
    return []
  }, [searchId])
  return [items, childrenComponents]
}
