import React, { memo } from 'react';
import { Handle, Position } from 'reactflow';
import BaseNode from './BaseNode'

function StartNode({ data }) {
  return <BaseNode kind={'start'} id='start'>
      <Handle type="source" position={Position.Bottom} />
  </BaseNode>
}

export default memo(StartNode);
