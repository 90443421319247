import CannedResponse from './CannedResponse'

export default function CannedResponsesSettings() {
  const act = useAct()
  const campaign = useSel(({ campaign }) => campaign)
  const list = campaign.cannedResponses
  useEffect(() => {
    if (!campaign.cannedResponses) {
      act.cannedResponses.loadList()
    }
  }, [campaign.id])
  const [isCreating, setIsCreating] = useState(false)

  return (
    <>
      {!list ? (
        <div>Loading...</div>
      ) : (
        <div className="canned-responses">
          <div className="canned-responses__list">
            {list.map((cr, i) => (
              <CannedResponse key={cr.id} response={cr} />
            ))}
          </div>
          {isCreating && (
            <div className="canned-responses__new">
              <CannedResponse
                response={{ name: '', body: '' }}
                onSave={() => setIsCreating(false)}
              />
            </div>
          )}
          {!isCreating && (
            <button className="button primary margin-top" onClick={() => setIsCreating(true)}>
              Add Canned Response
            </button>
          )}
        </div>
      )}
    </>
  )
}
