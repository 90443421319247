import { useStore } from 'react-redux'
import restClient from 'Shared/hooks/restClient'
import scriptBuilder from './scriptBuilder'

const actionSlices = {
  scriptBuilder,
}

export default () => {
  const dis = useDis()
  const store: typeof appStore = useStore()

  return useMemo(
    () =>
      _.mapValues(actionSlices, (fn) => {
        return fn(dis, store, restClient)
      }),
    []
  )
}
