import _ from 'lodash'
import { v4 as uuidv4 } from 'uuid'

export default function detectCycle(questions, answers) {
    if (questions.length === 0) {
      return false
    }

    let graph = makeGraph(questions, answers)

    // Copy the graph, converting all node references to String
    graph = Object.assign(...Object.keys(graph).map( node =>
                ({ [node]: graph[node].map(String) })
    ));

    let queue = Object.keys(graph).map( node => [node] );
    while (queue.length) {
        const batch = [];
        for (const path of queue) {
            const parents = graph[path[0]] || [];
            for (const node of parents) {
                if (node === path[path.length-1]) return true;
                batch.push([node, ...path]);
            }
        }
        queue = batch;
    }

    return false
}

/*
  Get to [{
    questionId: [questionId, ...]
  }]
*/
function makeGraph(questions, answers) {
  function getQuestionIds(question, answers) {
    const { digitOrder } = question

    const questionIds = answers.map(answer => {
      const { action, nextQuestionId } = answer

      if (action === 'next_question') {
        return questions.find(q => q.digitOrder === digitOrder + 1)?.id
      } else if (action === 'go_to_question') {
        return nextQuestionId
      }
    })

    return _.compact(questionIds)
  }

  const questionIdsWithoutAnswers = _.difference(_.map(questions, 'id'), _.map(answers, 'questionId'))
  const fakeQuestionAnswers = questionIdsWithoutAnswers.map(id => ({ id: uuidv4(), questionId: id, action: 'next_question' }))
  const allAnswers = _.union(answers, fakeQuestionAnswers)

  return _.fromPairs(questions.map(q => [q.id, getQuestionIds(q, allAnswers.filter(a => a.questionId === q.id))]))
}
