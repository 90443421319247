import Wizard from './Wizard'
import BackdropModal from '../../../Shared/components/BackdropModal/BackdropModal'
import AddIcon from '@material-ui/icons/Add'

const CreateCampaignButton = ({ className = '', cloneSearchId, searchId }) => {
  const act = useAct()
  const [modalOpen, setModalOpen] = useState(false)

  const openModal = () => {
    act.createCampaign.reset()
    setModalOpen(true)
  }

  return (
    <>
      <button className={`button primary ${className}`} onClick={openModal}>
        <AddIcon /> Create campaign
      </button>
      <BackdropModal
        title="Create a new campaign"
        open={modalOpen}
        onCancel={() => setModalOpen(false)}
      >
        <Wizard searchId={searchId} cloneSearchId={cloneSearchId} />
      </BackdropModal>
    </>
  )
}

export default CreateCampaignButton
