export default function Link({ contentState, entityKey, children }) {
    const { url, color } = contentState.getEntity(entityKey).getData()
    const styles = {
        color: color,
    }
    return (
        <a href={url} style={styles}>
            {children}
        </a>
    )
}
