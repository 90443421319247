import React, { PureComponent, useRef } from 'react';
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, LabelList } from 'recharts';
import useAct from 'CampaignStats/useAct'
import { CircularProgress } from '@material-ui/core'

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    const { completedCalls, recyclableCalls, remainingCalls, name } = payload[0].payload || {}

    return (
      <div className="custom-tooltip box-shadow" style={{ background: 'white', padding: '8px', borderRadius: '4px' }}>
        <p className='no-margin-top larger'><strong>{name}</strong></p>
        <p><strong>Contacted</strong>: {completedCalls}</p>
        <p><strong>Not yet reached</strong>: {recyclableCalls}</p>
        <p><strong>Yet to be called</strong>: {remainingCalls}</p>
      </div>
    );
  }

  return null;
};

export default function OrganisationBreakdown() {
  const act = useAct()
  const campaignStats = useSel(s => s.local.campaignStats || {})
  const chosenOrganisationId = useSel(s => s.local.chosenOrganisationId)
  const organisationHistory = useSel(s => s.local.organisationHistory || [])
  const loading = useSel(s => s.local.loading)
  const loadedOrganisationId = useSel(s => s.local.loadedOrganisationId)
  const dataOrgIdRef = useRef(null)
  const dataRef = useRef(null)
  const chartRef = useRef();

  // Cache the old view data until the new one is ready so it animates smoothly
  if (dataOrgIdRef.current !== loadedOrganisationId) {
    dataOrgIdRef.current = loadedOrganisationId
    dataRef.current = _.orderBy(_.compact(_.map(campaignStats, (stats, organisationIdStr) => {
      const organisationId = parseInt(organisationIdStr)
      const { completedCalls = 0, recyclableCalls = 0, totalTargets = 0, name } = stats

      if (organisationId === chosenOrganisationId) return null
      if (totalTargets === 0) return null

      const remainingCalls = totalTargets - completedCalls - recyclableCalls
      const completedPerc = (totalTargets > 0 ? completedCalls / totalTargets : 0) * 100
      const recyclablePerc = (totalTargets > 0 ? recyclableCalls / totalTargets : 0) * 100
      const remainingPerc = (totalTargets > 0 ? remainingCalls / totalTargets : 0) * 100

      return {
        organisationId,
        name,
        completedCalls,
        recyclableCalls,
        remainingCalls,
        completedPerc,
        recyclablePerc,
        remainingPerc
      }
    })), 'name')
  }

  const showGraph = !loading && dataRef.current.length > 1

  function onClickBar(data, index) {
    act.campaignStats.setOrganisationId(data.organisationId)
  }

  const minHeight = (dataRef.current.length * 30) + 100

  return <>
    <h3 className='no-margin-top'>Organisation Breakdown</h3>
    { organisationHistory.length > 1 && <div>
      <button className='button naked' onClick={act.campaignStats.goBack}>Go back</button>
    </div> }
    { !showGraph && <>
      { loading && <CircularProgress /> }
      { !loading && <p>There's no sub-organisations to look at here.</p> }
    </> }
    { showGraph && <div>
      <div style={{ height: `${minHeight}px` }} ref={chartRef} className='recharts-clickable'>
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            width={500}
            data={dataRef.current}
            layout='vertical'
            margin={{
              top: 20,
              right: 20,
              left: 20,
              bottom: 20,
            }}
          >
            <XAxis type="number" interval={0} hide />
            <YAxis type="category" width={150} padding={{ left: 20 }} dataKey="name" />

            <Bar
                dataKey="completedPerc"
                name="Contacted"
                fill='#00D586'
                stackId="a"
                onClick={onClickBar}
              >
              </Bar>
              <Bar
              dataKey="recyclablePerc"
              name="Not yet reached"
              fill='#00B9D2'
              stackId="a"
              onClick={onClickBar}
              />
              <Bar
              dataKey="remainingPerc"
              name="Yet to be called"
              fill='#26264D99'
              stackId="a"
              onClick={onClickBar}
              />
            <Tooltip content={<CustomTooltip />} cursor={{fill: 'transparent'}} />
            <Legend />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div> }
  </>
}
