import React, { useCallback, useMemo, useState } from 'react'
import restClient from 'Shared/hooks/restClient'
import Modal from 'Shared/components/Modal/Modal'
import CopyToClipboard from 'Shared/components/CopyToClipboard'
import BackdropModal from 'Shared/components/BackdropModal/BackdropModal'
import { ManageMenuItem } from '..'
import { ManageMenuPropsHookResult } from '../hooks'


export function useSurveyActions(survey) {
  if (!survey?.id) {
    return []
  }
  return [
    {
      type: 'menu',
      props: {
        type: 'survey',
        target: survey,
      },
    },
  ]
}

export function useSurveyMenuProps(survey: any): ManageMenuPropsHookResult {
  const [exportPassword, setExportPassword] = useState(null)
  const [duplicateSurveyModalVisible, setDuplicateSurveyModalVisible] = useState(false)
  const nameInputRef = useRef(null)
  const attachAudienceInputRef = useRef(null)
  const dis = useDis()

  const close = () => {
    restClient.put(`/api/surveys/${survey.id}/close`)
      .then(navigateToIndex)
  }

  const archive = () => {
    restClient.delete(`/api/surveys/${survey.id}`)
      .then(navigateToIndex)
  }

  const navigateToIndex = () => {
    window.location = '/admin/surveys'
  }
  const doExport = useCallback(() => {
    restClient.post(`/api/surveys/${survey.id}/export`).then((response) => {
      setExportPassword(response.data.password)
    })
  }, [survey.id])

  const handleCloneSurvey = useCallback(() => {
    const newSurveyName = nameInputRef.current?.value.trim()
    if (!newSurveyName) {
      return
    }

    restClient.post(`/api/surveys/${survey.id}/clone`, { name: newSurveyName, duplicate_search: attachAudienceInputRef.current?.checked })
      .then((response) => {
        setDuplicateSurveyModalVisible(false)
        if (nameInputRef.current) {
          nameInputRef.current.value = ''
        }
        if (attachAudienceInputRef.current) {
          attachAudienceInputRef.current.checked = false
        }
        const { redirectUrl } = response.data
        if (redirectUrl) {
          window.location.href = redirectUrl
        }
      })
  }, [survey.id])

  const items = useMemo(() => {
    if (!survey?.id) {
      return []
    }

    const handleDuplicateSurveyClick = () => {
      dis({
        type: 'createSurvey/upsert',
        payload: { cloneFromId: survey.id },
      })
      setDuplicateSurveyModalVisible(true)
    }

    const items: ManageMenuItem[] = [
      {
        title: 'Edit survey',
        onClick: () => {
          window.location.href = `/admin/surveys/${survey.id}/build`
        },
      },
      { 
        title: 'Close', 
        onClick: close 
      },
      { 
        title: 'Archive', 
        onClick: archive 
      },
      {
        title: 'Duplicate survey',
        onClick: handleDuplicateSurveyClick,
      },
      {
        title: 'Export results',
        onClick: doExport,
      },
    ]

    return items
  }, [survey, doExport, dis])

  const childrenComponents = useMemo(() => {
    if (!survey?.id) {
      return []
    }

    const children = [
      <BackdropModal
        title="Duplicate Survey"
        open={duplicateSurveyModalVisible}
        onCancel={() => setDuplicateSurveyModalVisible(false)}
        key="duplicate_survey_backdrop"
      >
        <div className="wizard">
          <div className="step">
            <div className='form narrow'>
              <div className='fields'>
                <div className='field'>
                  <label>Survey name</label>
                  <input 
                    type='text' 
                    placeholder='Name your survey'
                    ref={nameInputRef}
                  />
                </div>
              </div>
              {survey?.searchId && (
                <div className='field margin-top half'>
                  <label className='align-center'>
                    <input type="checkbox" ref={attachAudienceInputRef} />
                    <p className='no-margin-top no-margin-bottom margin-left'>Attach the same audience to the duplicated survey</p>
                  </label>
                </div>
              )}
              <button
                className='button block primary no-margin-left'
                onClick={handleCloneSurvey}
              >Begin</button>
            </div>
          </div>
        </div>
      </BackdropModal>
    ]

    if (exportPassword) {
      children.push(
        <Modal
          key="export_modal"
          open={!!exportPassword}
          title="Export in progress"
          onCancel={() => setExportPassword(null)}
        >
          The survey is exporting. You'll receive an email when it's done. Use
          this password to open it: &nbsp;
          <CopyToClipboard
            text={`${exportPassword} (click to copy)`}
            url={exportPassword}
          />
        </Modal>
      )
    }

    return children
  }, [survey, exportPassword, duplicateSurveyModalVisible])

  return [items, childrenComponents]
}
