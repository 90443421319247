import * as React from 'react'

function SvgTotalCallers(props) {
  return (
    <svg width={24} height={24} viewBox={"0 0 24 18"} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
        opacity="0.3"
        d="M18 11C16.3431 11 15 9.65685 15 8C15 6.34315 16.3431 5 18 5C19.6569 5 21 6.34315 21 8C21 9.65685 19.6569 11 18 11Z"
        fill="currentColor"
    />
    <path
        d="M9 8C6.79086 8 5 6.20914 5 4C5 1.79086 6.79086 0 9 0C11.2091 0 13 1.79086 13 4C13 6.20914 11.2091 8 9 8Z"
        fill="currentColor"
    />
    <path
        opacity="0.3"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.6004 17.9994H23.4563C23.9989 17.9994 24.0073 17.546 23.9989 17.3994C23.7895 13.7595 21.0081 12.0372 17.6016 12C18.8567 13.6712 19.6004 15.7484 19.6004 17.9994Z"
        fill="currentColor"
    />
    <path
        d="M0.000651684 17.1992C0.388259 12.4265 4.26191 10 8.98334 10C13.7712 10 17.7049 12.2932 17.9979 17.2C18.0096 17.3955 17.9979 18 17.2467 18C13.5411 18 8.03472 18 0.727502 18C0.476712 18 -0.0204618 17.4592 0.000651684 17.1992Z"
        fill="currentColor"
    />
    </svg>
  )
}

export default SvgTotalCallers
