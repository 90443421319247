import Build from './Experiments/Build'
import './Experiments.scoped.scss'

const Experiments = ({}) => {
  const act = useAct()
  const [showBuilder, setShowBuilder] = useState(false)
  const [editingId, setEditingId] = useState(null)
  const mergeTagTests = useSel((s) => _.values(s.mergeTagTests.entities))

  function add() {
    const id = act.email.mergeTagTests.add({
      name: '',
      mergeTagTestVersions: [
        {
          id: uuidv4(),
          content: '',
        },
        {
          id: uuidv4(),
          content: '',
        },
      ],
    })
    setEditingId(id)
    setShowBuilder(true)
  }

  function edit(id) {
    setEditingId(id)
    setShowBuilder(true)
  }

  function remove(id) {
    if (confirm('Are you sure you want to delete this experiment?')) {
      act.email.mergeTagTests.remove(id)
      act.campaign.persist()
    }
  }

  return (
    <div className="experiments">
      <h3 className="no-margin-top">Experiments</h3>

      {mergeTagTests.map((experiment) => (
        <div key={experiment.id} className="experiment">
          <div className="title">
            <p className="bold">{experiment.name}</p>
            <a className="edit" onClick={() => edit(experiment.id)}>
              <SvgIconsEdit color={'currentColor'} width={24} height={24} />
            </a>
            <a className="remove" onClick={() => remove(experiment.id)}>
              <SvgIconsDelete color={'currentColor'} width={24} height={24} />
            </a>
          </div>

          {experiment.slug && <span className="slug">{`{{tests.${experiment.slug}}}`}</span>}
          <span className="variants">
            {experiment.mergeTagTestVersions?.length} variants
          </span>
        </div>
      ))}

      <a className="button small secondary" onClick={() => add()}>
        <SvgIconsExperiment />
        Create Experiment
      </a>

      <div className="tip margin-top">
        <div className="icon">
          <SvgIconsTip />
        </div>
        <p>
          We recommend creating only 1 or 2 experiments. Creating more may lead to
          inconclusive results.
        </p>
      </div>

      <Build
        isOpen={showBuilder}
        mergeTagTestId={editingId}
        close={() => setShowBuilder(false)}
      />
    </div>
  )
}

export default Experiments
