import Fade from '@material-ui/core/Fade'
import Timeline from 'Shared/components/Timeline/Timeline'
import Options from './Options/Options'
import ActivityCard from './ActivityCard/ActivityCard'
import { PERIODS } from './constants'
import './ActivityLog.scoped.scss'

const ActivityLog = ({ target }) => {
  const actions = useAct()
  const [loaded, setLoaded] = useState()
  const [channel, setChannel] = useState('All')
  const [daysToLoad, setDaysToLoad] = useState(PERIODS[0])
  const [targetActivity, setTargetActivity] = useState([])

  useEffect(() => {
    if (target && !_.isEmpty(target) && !loaded) {
      actions.target.loadActivity(daysToLoad)
      setLoaded(true)
    }
  }, [target])

  useEffect(() => {
    if (target && !_.isEmpty(target)) {
      actions.target.loadActivity(daysToLoad).then(() => {
        setTargetActivity(target.activity)
      })
    }
  }, [daysToLoad])

  const onLoadMore = () => {
    const idx = PERIODS.indexOf(daysToLoad)
    if (idx < PERIODS.length - 1) setDaysToLoad(PERIODS[idx + 1])
  }

  useEffect(() => {
    if (target?.activity) {
      if (channel && channel !== 'All') {
        setTargetActivity(
          target.activity.filter((activity) =>
            activity.topic.toLowerCase().includes(channel.toLowerCase())
          )
        )
      } else {
        setTargetActivity(target.activity)
      }
    }
  }, [target?.activity])

  const filterByChannel = (channel) => {
    setChannel(channel)
    if (channel === 'All') {
      setTargetActivity(target?.activity)
      return
    }
    setTargetActivity(
      target?.activity.filter((activity) =>
        activity.topic.toLowerCase().includes(channel.toLowerCase())
      )
    )
  }

  return (
    <Fade in={loaded}>
      <div className="root" item>
        <div>
          <Options
            channelSelection={(channel) => filterByChannel(channel)}
            channelToLoad={channel}
            daySelection={(days) => setDaysToLoad(Number(days))}
            daysToLoad={daysToLoad}
          />
        </div>
        <div className="timeline-container">
          {targetActivity.length === 0 && <p>There's no activity to show.</p>}
          <Timeline
            {...{
              items: targetActivity,
              height: 'auto',
              ItemComponent: ({ item }) => <ActivityCard activity={item} />,
              ItemComponentProps: {},
              options: {
                chunk: { highlight: false, reverse: true },
                fade: { height: 160, offset: 80 },
              },
            }}
          />
        </div>
        <div
          className="button secondary no-margin-left load-more"
          onClick={onLoadMore}
        >
          Load more
        </div>
      </div>
    </Fade>
  )
}

export default ActivityLog
