import Text from './question-types/Text'
import MultipleChoice from './question-types/MultipleChoice'
import MultipleSelect from './question-types/MultipleSelect'
import Score from './question-types/Score'

import ServerWaitButton from 'Shared/components/ServerWaitButtonStandalone'

import useAct from '../hooks/useAct'
import useNav from '../hooks/useNav'
import { useParams, useNavigate, useLocation } from 'react-router-dom';

import './Question.scoped.scss'
import { init } from 'linkifyjs'

const questionTypeComponents = {
  'text': Text,
  'multiple_choice': MultipleChoice, // radios
  'multiple_select': MultipleSelect, // checkboxes
  'score': Score
}

export default function Question() {
  const act = useAct()
  const nav = useNav()
  const navigate = useNavigate()
  let { targetId, surveyId, questionId } = useParams()
  const { initialResponse } = useLocation().state || {}
  const location = useLocation()

  const { questionType, question, instructions, answers, digitOrder, settings } =
    useSel((s) => s.survey.questions.find((q) => q.id === +questionId))


  const [response, setResponse] = useState(initialResponse || null)

  const { completedAt, currentQuestionId } = useSel((s) => s.surveyTaker)

  useEffect(() => {
    if (initialResponse) {
      setResponse(initialResponse)
    }
  }, [initialResponse])

  const QuestionType = questionTypeComponents[questionType]

  const isIncomplete = Array.isArray(response) ? response.length === 0 : !response

  const isFirstQuestion = useSel((s) => s.survey.questions[0].id === +questionId)

  const next = () => {
    return act.surveyTaker.answerQuestion(questionId, response).then(({ completedAt, currentQuestionId }) => {
      if (!currentQuestionId) {
        navigate(`/target/surveys/${surveyId}/finished`)
      } else {
        setResponse(null)
        nav.toQuestion({ surveyId, questionId: currentQuestionId })
      }
    })
  }

  const back = () => {
    return act.surveyTaker.back().then(({ currentQuestionId, lastValue }) => {
      nav.toQuestion({ surveyId, questionId: currentQuestionId, initialResponse: lastValue })
    })
  }

  return (
    <div className="box">

      <div className="question-mark">
        <SvgIconsQuestionMark />
      </div>

      <h1 className={`${!instructions ? 'margin-bottom double' : ''}`}>{question}</h1>
      {instructions && ( <p className="instructions dark-grey">{instructions}</p> )}

      <div className="question-fields">
        <QuestionType
          name={`question_${questionId}`}
          answers={answers}
          response={response}
          settings={settings}
          setResponse={setResponse} />
      </div>

      <div className="actions">
        {isFirstQuestion ? null : <ServerWaitButton large secondary dark onClick={back}>Back</ServerWaitButton>}
        <ServerWaitButton large dark primary onClick={next} disabled={settings.required && isIncomplete}>Next</ServerWaitButton>
      </div>
    </div>

  )
}
