import InlineCkEditor from 'Shared/components/CKEditor/InlineCkEditor'
import BlockStyles from "../../BlockStyles"

const ParagraphV2Block = ({ block, active }) => {
  const act = useAct()
  const { mergeTags } = useSel(s => s.campaignBuilder)
  const setText = (text) => act.email.blocks.updateParameters(block.id, { text })
  const setSourceMode = (sourceMode) => act.email.blocks.updateParameters(block.id, { sourceMode })

  return (
    <div id={`block_${block.id}`}>
      {!active && <div dangerouslySetInnerHTML={{ __html: block.parameters.text }} />}
      {active && <InlineCkEditor
        value={block.parameters.text}
        sourceMode={block.parameters.sourceMode}
        setSourceMode={setSourceMode}
        onUpdate={setText}
        mergeTags={mergeTags}
      />}

      <BlockStyles block={block} active={active} />
    </div>
  )
}

export default ParagraphV2Block
