import { useStore } from 'react-redux'
import createActions from './createActions'
import user from 'Shared/hooks/user'
import campaign from 'Shared/hooks/campaign'
import restClient from 'Shared/hooks/restClient'

export default () => {
  const dis = useDis()
  const store = useStore()

  return useMemo(
    () => ({
      ...createActions(dis, store, restClient),
      user: user(dis, store, restClient),
      campaign: campaign(dis, store, restClient),
    }),
    []
  )
}
