import restClient from 'Shared/hooks/restClient'

function getGoalComplete(campaignTargetId, goalId) {
  return new Promise((resolve, reject) => {
    restClient
      .get(`/api/campaign_targets/${campaignTargetId}/goals/${goalId}/complete`)
      .then(({ data }) => {
        resolve(data.complete)
      })
      .catch((err) => reject(err))
  })
}

function markGoalComplete(campaignTargetId, goalId) {
  return restClient.post(`/api/campaign_targets/${campaignTargetId}/goals/${goalId}/mark_complete`)
}

const actions = {
  getGoalComplete,
  markGoalComplete,
}

export default actions
