import React from 'react'
import Table from "Shared/components/DataTable/Table"

const DonationsWidget = () => {
  const target = useSel((s) => s.target)

  const isTargetValid = target && Object.keys(target).length > 0 && target.id

  if (!isTargetValid) {
    return <div>Loading...</div>
  }

  return (
    <>
      <p className="larger bold capitalise no-margin-top">Donations</p>
      <Table
        table="donations"
        language={{ emptyTable: "No donations made." }}
        scope={{ target_id: target.id }}
        hideSearch={true}
      />
    </>
  )
}

export default DonationsWidget
