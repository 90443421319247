import { useEffect } from 'react'
import { clamp } from 'Shared/functions/util'
import './ImageBlock.scoped.scss'

const SingleImage = ({ image, allowResizing = false, onResize, active }) => {
  const [width, setWidth] = useState(image.width || 400)

  useEffect(() => {
    setWidth((image.width || 400))
  }, [image.width])

  const style = {
    textAlign: image.textAlign,
  }

  const { imageBlobId, alt } = image
  const src = imageBlobId ? `/rails/active_storage/blobs/${imageBlobId}/image.png` : 'https://via.placeholder.com/400x300'

  const startDrag = (mouseDownEvent) => {
    const startWidth = width
    let newWidth = width
    const startX = mouseDownEvent.pageX
    const onMouseMove = (mouseMoveEvent) => {
      newWidth = startWidth - startX + mouseMoveEvent.pageX
      // If dragging center aligned image, we need double the offset
      if (image.textAlign === 'center') {
        newWidth = width - ((width - newWidth) * 2)
      }
      // If dragging right aligned image, we need to reverse the offset
      if (image.textAlign === 'right') {
        newWidth = width - ((width - newWidth) * -1)
      }
      // Limit width
      newWidth = clamp(newWidth, 50, 500)
      setWidth(() => newWidth)
    }
    const onMouseUp = () => {
      document.body.removeEventListener("mousemove", onMouseMove)
      onResize(newWidth)
    }
    document.body.addEventListener("mousemove", onMouseMove);
    document.body.addEventListener("mouseup", onMouseUp, { once: true })
  }

  // Set width by sizingMode
  let imgWidth = null
  switch (image.sizingMode) {
    case 'percent':
      imgWidth = `${image.width}%`
      break;
    default:
      imgWidth = width ? `${width}px` : '100%'
  }
  const imgStyle = {
    width: imgWidth,
    display: 'inline-block',
  }
  const isResizable = allowResizing && !image.sizingMode

  return (
    <div style={style}>
      <div className="image-container">
        <img src={src} alt={alt} style={imgStyle} />
        {active && isResizable && <div className={'drag-handle ' + image.textAlign} onMouseDown={startDrag} />}
      </div>
    </div>
  )
}

export default SingleImage

