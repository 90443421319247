import { useRef } from 'react'
import OutsideClickHandler from 'react-outside-click-handler'
import Tag from 'Shared/components/Tag/Tag'
import restClient from 'Shared/hooks/restClient'
import './Spotlight.scoped.scss'

const TYPE_LABELS = {
  target: 'People',
  user: 'Users',
  campaign: 'Campaigns',
  search: 'Searches',
}

function highlightQuery(text, query) {
  if (!query || typeof query !== 'string' || query.trim() === '') {
    return text;
  }

  try {
    // Escape special regex characters and ensure '+' is treated literally
    const escapedQuery = query.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
    const regex = new RegExp(`(${escapedQuery})`, 'gi');
    const parts = text.split(regex);
    return parts.map((part, i) =>
      regex.test(part) ? <mark key={i}>{part}</mark> : part
    );
  } catch (error) {
    console.error('Error in highlightQuery:', error);
    return text;
  }
}

function ItemTags({ type, item }) {
  let tags = []
  switch (type) {
    case 'Campaign':
      tags = item.organisationNames
      break
    case 'Target':
      tags = item.organisationNames
      break
    case 'User':
      tags = [item.roleString, item.organisationName]
      break
  }
  return (tags && tags.length > 0) ? <div className='tags'>
    <span className='ellipsis narrower'></span>
    {tags.map((tag) => <Tag color={'border'}>{tag}</Tag>)}
  </div> : null
}

export default function Spotlight({ url }) {
  const [state, setState] = useState({ url, results: [], supportResults: [], queried: false })
  const [loading, setLoading] = useState(false)
  const [query, setQuery] = useState('')
  const [open, setOpen] = useState(false)
  const inputRef = useRef(null)

  const focus = () => {
    inputRef.current.focus()
  }

  const dSearch = useMemo(
    () =>
      _.debounce((query) => {
        setOpen(true)
        setLoading(true)
        setState((d) => {
          return { ...d, ...{ count: 0, results: [], queried: false } }
        })
        restClient
          .get(`${state.url}?query=${query}`)
          .then(({ data: { count, results, supportResults } }) => {
            console.log(results)
            setState((d) => {
              return { ...d, ...{ count, results, supportResults: supportResults.slice(0, 3), queried: true } }
            })
          })
          .finally(() => setLoading(false))
      }, 500),
    []
  )

  useEffect(() => {
    if (query && query.length >= 3) {
      dSearch(query)
    }
  }, [query])

  return (
    <div className={`spotlight ${open ? 'open' : ''}`} onClick={focus}>
      <SvgIconsSearch className="icon" />

      <OutsideClickHandler
        onOutsideClick={() => {
          setOpen(false)
        }}
      >
        <input
          ref={inputRef}
          type="text"
          placeholder={'Search for people, users, campaigns, and help articles'}
          onChange={(e) => setQuery(e.target.value || '')}
          onClick={() => {
            if (state.queried) {
              setOpen(true)
            }
          }}
        />
        {open && (
          <div className="popup popup-list">
            {state.supportResults.length > 0 && (
              <div className="group">
                <div className="name">Help & solutions</div>
                {state.supportResults.map((item, index) => (
                  <a className="filter" key={index} href={item.url} target={'blank'}>{item.title}</a>
                ))}
              </div>
            )}
            {loading && (
              <p className="no-margin-top no-margin-bottom">Loading results.</p>
            )}
            {!loading && (state.count === 0 && state.supportResults.length === 0) && (
              <p className="no-margin-top no-margin-bottom">No results found.</p>
            )}
            {Object.entries(state.results).map(([type, items]) => {
              // Skip rendering if items array is empty
              if (items.length === 0) return null;

              return (
                <div key={type} className="group">
                  <div className="name">{TYPE_LABELS[type]}</div>
                  {items.map((item) => (
                    <a className="filter" href={item.url} key={item.id}>
                      <span>{highlightQuery(item.name, query)}</span>
                      {item.display.length > 0 && item.display.map((field) => (
                        <>
                          <span className='ellipsis narrower'></span>
                          <span>{item[_.camelCase(field)]}</span>
                        </>
                      ))}
                      <span><ItemTags type={type} item={item} /></span>
                    </a>
                  ))}
                </div>
              )
            })}
          </div>
        )}
      </OutsideClickHandler>
    </div>
  )
}
