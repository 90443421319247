const STEPS = [
  { id: 'check', label: 'check your data' },
  { id: 'select', label: 'select your file' },
  { id: 'review', label: 'review your data' },
  { id: 'upload', label: 'upload' },
]

const DEFAULTS = {
  labels: {
    title: 'Upload your new data',
    button: 'Upload CSV',
    prompt: 'Check your CSV - does it have all the required fields?',
    labelForRequired: 'Required fields',
    labelForOptional: 'Optional fields',
    requiredColumns: 'Required columns',
    optionalColumns: 'Optional columns',
    requiredColumnsDescription: [
      'Click on each required column to confirm that your CSV file includes them, then proceed to the next step.',
    ],
    requiredColumnsDescriptionLists: [
      'Your CSV file requires one of the following columns: Membership Number, Phone Number, or Email. Click on the required column to confirm that your CSV file includes it, then proceed to the next step.',
    ],
    optionalColumnsDescription: [
      'If your CSV file includes phone numbers, please make sure that numbers are valid and in the correct format.',
      'Phone numbers can either begin with a leading 0, or a country code such as +44. Both landline and mobile numbers are accepted, and these can contain spaces, hyphens and/or brackets.',
    ],
  },
  paragraphs: {
    check: 'Your CSV has to have a top row with column names.',
    checkWithConfirm: 'Please make sure that your file includes all required columns.',
    select: 'Upload a CSV from your computer, or directly from Google Sheets.',
    review:
      'Please review a sample data from your CSV file to make sure it’s correct before uploading the file.',
    upload: 'Beginning file upload...',
    uploadComplete:
      'Your file was successfully uploaded. It will be processed shortly, and you will receive an email when this is complete.',
  },
}

const ERRORS = {
  CSV_ROW_ERROR_COUNT:
    'All your rows are failing validation - maybe you selected the wrong file?',
  CSV_HEADER_MISSING: 'Your file has no header row',
  CSV_COLUMNS_MISSING: 'Your file is missing required columns',
  CSV_COLUMNS_LIST_INVALID:
    'Your file contains invalid columns. List uploads only support one column: Membership Number, Phone Number, or Email. Please remove any additional columns and try again.',
  CSV_CUSTOM_COLUMNS:
    'Your CSV includes columns that cannot be matched. Please remove the following columns [{columns}] and try again. Alternatively, you can create custom fields for these columns.',
  GOOGLE_PERMISSIONS:
    'It looks like your Google Sheet is private - create a share link and try again',
}

export { DEFAULTS, STEPS, ERRORS }
