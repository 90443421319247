import PageContainer from 'DesktopCaller/components/PageContainer'
import RingroundSnackbar from 'Shared/components/Snackbar'
import { Provider } from 'react-redux'
import store from 'DesktopCaller/store'
import ActProvider from 'DesktopCaller/ActProvider'

const DesktopCaller = ({
  campaignId,
  userId,
  enabledCallingModes,
  clientCallOutcomes,
  widgets = [],
}) => {
  return (
    <Provider store={store}>
      <ActProvider>
        <RingroundSnackbar />
        <ErrorBoundary>
          <PageContainer
            campaignId={campaignId}
            userId={userId}
            enabledCallingModes={enabledCallingModes}
            clientCallOutcomes={clientCallOutcomes}
            widgets={widgets}
          />
        </ErrorBoundary>
      </ActProvider>
    </Provider>
  )
}

export default DesktopCaller
