import React from 'react'
import AlignField from 'EmailBuilder/components/AlignField'
import FileUploader from 'Shared/components/ImageUploader/FileUploader'
import LinkFields from '../../LinkFields'
import Divider from 'Shared/components/Divider'
import { Form, Formik } from 'formik'

import SizingMode from './SizingMode'

const SingleImageProperties = ({ image, onChange, multiple }) => {
  const act = useAct()

  const handleTextAlignChange = (textAlign) => {
    onChange({ ...image, textAlign })
  }

  const handleAltChange = (e) => {
    onChange({ ...image, alt: e.target.value })
  }

  function onImageUploaded([imageBlobId], filename) {
    onChange({ ...image, imageBlobId, filename })
  }

  const onUpdateForm = (values) => {
    onChange({ ...image, ...values })
  }

  return (
    <form>
      <div className="field">
        <FileUploader
          onUploaded={onImageUploaded}
          existingFilename={image.filename}
        />
      </div>

      <Divider />

      <LinkFields parameters={image} onChange={onUpdateForm} />

      <Divider />

      {!multiple && (
        <>
          <Formik
            initialValues={{
              sizingMode: image.sizingMode || '',
              width: image.width ?? 0,
            }}
            onSubmit={onUpdateForm}
            enableReinitialize
          >
            <Form>
              <SizingMode />
            </Form>
          </Formik>

          <Divider />

          <AlignField
            label="Image Alignment"
            value={image.textAlign}
            onChange={handleTextAlignChange}
            type="image"
          />

          <Divider />
        </>
      )}

      <div className="field">
        <label>Alt text</label>
        <input type="text" value={image.alt} onChange={handleAltChange} />
      </div>
    </form>
  )
}

export default SingleImageProperties

