import { CircularProgress } from '@material-ui/core'
import Button from 'Shared/components/Button'
import restClient from 'Shared/hooks/restClient'
import Flash from 'Shared/components/Flash/components/Flash'
import { Formik, Field, Form, ErrorMessage } from 'formik';
import FormikField from 'Shared/components/FormikField/FormikField'
import FormikSelect from 'Shared/components/FormikSelect/FormikSelect'
import OrganisationField from 'Shared/components/OrganisationField'

export default function EditGroupForm({ groupId, hasChildOrganisations }) {
  const [flash, setFlash] = useState(null)
  const [group, setGroup] = useState(null)

  useEffect(() => {
    restClient.get(`/api/groups/${groupId}`)
      .then(({ data }) => {
        setGroup(data)
      })
  }, [groupId])

  function handleSubmit(group, formik) {
    restClient.put(`/api/groups/${groupId}`, { group }).then((response) => {
      setFlash({ message: 'Group updated successfully', type: 'success' })
      formik.setValues(response.data)
      formik.setSubmitting(false)
    }).catch((e) => {
      formik.setSubmitting(false)
      if (e.response.status === 422) {
        setFlash({ message: 'Please check the details you entered', type: 'error' })
        formik.setErrors(e.response.data)
      } else {
        setFlash({ message: 'Error updating group', type: 'error' })
      }
    })
  }

  if (!group) return null

  return (
    <Formik
      enableReinitialize
      onSubmit={handleSubmit}
      initialValues={group}
    >
      {({ values, isSubmitting, errors, setFieldValue }) => {
        return (
          <Form>
            {flash && <Flash message={flash.message} type={flash.type} onHide={() => setFlash(null)} />}

            <div className="EditGroupForm details">
              <div className="detail">
                <div className="left">
                  <p>Name</p>
                </div>
                <div className="right field">
                  <FormikField name="name" type="text" />
                </div>
              </div>

              <OrganisationField
                value={values.organisationId}
                setFieldValue={setFieldValue}
                disabled={!hasChildOrganisations}
                allowBlank={true}
              />

              <Button type="submit" primary disabled={isSubmitting}>Save changes</Button>
              {isSubmitting && <CircularProgress />}
            </div>
          </Form>
        )
      }}
    </Formik>
  )
}
