import { camelize } from 'humps'

const getOrdinal = function (d) {
  if (!d) return ''
  if (d > 3 && d < 21) return 'th'
  switch (d % 10) {
    case 1:
      return d.toString() + 'st'
    case 2:
      return d.toString() + 'nd'
    case 3:
      return d.toString() + 'rd'
    default:
      return d.toString() + 'th'
  }
}

/* intersperse: Return an array with the separator interspersed between
 * each element of the input array.
 */
const intersperse = (arr, sep) => {
  if (arr.length === 0) {
    return []
  }
  return arr.slice(1).reduce(
    function (xs, x, i) {
      return xs.concat([sep, x])
    },
    [arr[0]]
  )
}

const current = new Proxy(
  {},
  {
    get(target, propertyName) {
      const result = {}
      const prefix = `current-${propertyName}-`
      for (const { name, content } of document.head.querySelectorAll(
        `meta[name^=${prefix}]`
      )) {
        const key = camelize(name.slice(prefix.length))
        result[key] = content
      }
      return result
    },
  }
)

export { current, getOrdinal, intersperse }
