import { pollRequest } from "../functions/util"

export default (dis, store, client) => {
  const update = (payload) => {
    dis({ type: 'analytics/upsert', payload })
  }

  const load = () => {
    update({ loading: true })

    const { dateRange, screen } = store.getState().analytics
    const rules = store.getState().rules

    const payload = {
      query_type: screen,
      date_from: dateRange.from,
      date_to: dateRange.to,
      rules: _.values(rules.entities)
    }

    client.post(`/api/analytics/query`, payload)
      .then(({ data: { id } }) => pollRequest(() => client.get(`/api/analytics/query/${id}`)))
      .then(({ data }) => {
        update({ data, loading: false })
      })

  }

  function setDefaultRules() {
    const rules = [{ 'id': 'root', 'type': 'group', 'operator': 'AND', 'ruleIds': [] }]
    dis({ type: 'rules/setAll', payload: rules })
  }

  return { load, update, setDefaultRules }
}
