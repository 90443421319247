import createPusherManager from 'Shared/pusherManager'
import pluralize from 'pluralize'

const createActions = (dis, store, restClient) => {
  // these will be set on store initialization
  const campaignId = store.getState().local.campaignId
  const userId = store.getState().local.userId

  const pusherManager = createPusherManager(
    handlePusherPayload,
    handleLatePayload,
    handlePusherConnected
  )
  pusherManager.setChannelName(`conversation-session-${campaignId}-${userId}`)

  const upsertLocal = payload => dis({ type: 'local/upsert', payload })

  function handlePusherPayload(payload) {
    _.each(payload, (entities, name) => {
      if (name === 'sentAt') return

      const model = pluralize(name)
      if (model === 'textMessages') {
        // Payload can keep growing forever, so we limit it to last 10 messages
        dis({ type: `textMessages/upsertMany`, payload: entities })
      } else {
        dis({ type: `${model}/setAll`, payload: entities })
      }
    })
  }

  function handleLatePayload(channel, reason) {
    getConversationSession(reason)
  }

  function handlePusherConnected() {
    dis({ type: 'local/upsert', payload: { pusherConnected: true } })
  }

  function getCampaign() {
    return restClient
      .get(`/api/campaigns/${campaignId}/show_volunteer`)
      .then(({ data }) => {
        dis({ type: 'campaigns/setAll', payload: [data] })
      })
  }

  function getConversationCannedResponses(campaignId, conversationId) {
    return restClient
      .get(
        `/api/campaigns/${campaignId}/conversations/${conversationId}/canned_responses`
      )
      .then(({ data }) => {
        dis({ type: 'cannedResponses/setAll', payload: data })
      })
  }

  function getConversationSession(reason = '') {
    return restClient
      .get(`/api/campaigns/${campaignId}/conversations/full_session${reason.length > 0 ? `?reason=${reason}` : ''}`)
      .then(({ data }) => {
        handlePusherPayload(data)
      })
  }

  function insertDetailsUpdateLink() {
    const state = store.getState()
    const body = state.local.draftMessage

    const newBody = `${(body || '').trim()} %%TARGET_DETAILS_UPDATE_LINK%%`
    upsertLocal({ draftMessage: newBody })
  }

  function sendMessage() {
    upsertLocal({ sendBusy: true })
    const state = store.getState()
    const conversationId = state.users.entities[userId].currentConversationId
    const body = state.local.draftMessage

    restClient
      .post(`/api/campaigns/${campaignId}/conversations/${conversationId}/reply`, {
        body,
      })
      .then(() => {
        upsertLocal({ sendBusy: false, draftMessage: '', isSuggested: false })
      })
      .catch((e) => {
        upsertLocal({ sendBusy: false, errors: ['Something went wrong'] })
      })
  }

  function goToNext() {
    return restClient
      .post(`/api/campaigns/${campaignId}/conversations/allocate`)
      .then(() => {
        upsertLocal({ draftMessage: '' })
      })
      .catch((e) => {
        upsertLocal({ errors: ['Something went wrong'] })
      })
  }

  function snooze() {
    return restClient
      .post(`/api/campaigns/${campaignId}/conversations/allocate?snooze=y`)
      .then(() => {
        upsertLocal({ draftMessage: '' })
      })
      .catch((e) => {
        upsertLocal({ errors: ['Something went wrong'] })
      })
  }

  function suggestCannedResponse(textMessageId) {
    const state = store.getState()
    const conversationId = state.users.entities[state.local.userId].currentConversationId
    return restClient
      .get(`/api/campaigns/${campaignId}/conversations/${conversationId}/suggest_canned_response?text_message_id=${textMessageId}`)
      .then(({ data }) => {
        if (data.suggestion && data.suggestion.length > 0) {
          upsertLocal({ draftMessage: data.suggestion, isSuggested: true })
        }
      })
      .catch((e) => {
        upsertLocal({ errors: ['Something went wrong'] })
      })
  }

  function optOut() {
    const state = store.getState()
    const conversationId = state.users.entities[state.local.userId].currentConversationId
    return restClient
      .post(`/api/campaigns/${campaignId}/conversations/${conversationId}/do_not_contact`)
      .then(() => {
        upsertLocal({ draftMessage: '' })
      })
      .catch((e) => {
        upsertLocal({ errors: ['Something went wrong'] })
      })
  }

  return {
    conversationManager: {
      ensureAllocated() {
        return restClient.post(
          `/api/campaigns/${campaignId}/conversations/ensure_allocated`
        )
      },
      goToNext,
      snooze,
      optOut,
      getConversationSession,
      getCampaign,
      getConversationCannedResponses,
      insertDetailsUpdateLink
    },
    messageSender: {
      suggestCannedResponse,
      sendMessage,
    },
    local: {
      upsert(payload) {
        dis({ type: 'local/upsert', payload })
      },
    },
  }
}

export default createActions
