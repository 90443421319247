import { useInView } from 'react-intersection-observer'
import useAct from 'Efforts/hooks/useAct'
import { format } from 'date-fns'
import { CircularProgress } from '@material-ui/core'

import './EffortCard.scoped.scss'

function CampaignsLists(campaigns) {
  const initialCount = 4
  const [expanded, setExpanded] = useState(false)
  const visibleCampaigns = expanded ? campaigns : campaigns.slice(0, initialCount)
  return (
    <div className="campaigns-list">
      <ul>
        {visibleCampaigns.map(({ id, name, status }) => (
          <li key={id}>
            {status === 'draft' ? (
              <a href={`/admin/campaigns/${id}/build`}>{name} (draft)</a>
            ) : (
            <a href={`/admin/campaigns/${id}/overview`}>{name}</a>
            )}
          </li>
        ))}
      </ul>
      {campaigns.length > initialCount ? (
        <p>
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault()
              setExpanded(!expanded)
            }}
          >
            {expanded ? 'View less' : 'View more'}
          </a>
        </p>
      ) : null}
    </div>
  )
}

function CompleterStats({ target, count }) {
  const statusClass = count >= target ? 'completed' : 'in-progress'
  return (
    <>
      <div className={`stats ${!target ? 'no-margin-bottom' : ''}`}>
        <div>
          <strong>{(count || 0).toLocaleString('en-US')}</strong>
          <br />
          Completers
        </div>
        {target && (
          <div>
            <strong>{target}</strong>
            <br />
            Goal
          </div>
        )}
      </div>
      {target && <progress value={count} max={target} className={statusClass} />}
    </>
  )
}

export default function EffortCard({ effort, tag }) {
  const act = useAct()
  const { name, status, primaryGoal } = effort
  const { deadline, target, name: primaryGoalName, completersCount } = primaryGoal ?? {}

  const [completersLoaded, setCompletersLoaded] = useState(false)

  const liveCampaigns = effort.campaigns.filter(({ discardedAt }) => discardedAt === null)

  const { ref, inView, entry } = useInView({
    threshold: 0,
  })

  useEffect(() => {
    if (!primaryGoal) return
    if (inView && !completersLoaded) {
      act.efforts.loadCompleters(effort.id).then(() => {
        setCompletersLoaded(true)
      })
    }
  }, [inView])

  const statusLabel = {
    in_progress: 'In progress',
    completed: 'Completed',
    successful: 'Successful',
  }[status]

  const dateFormat = (dateString) => {
    try {
      const date = new Date(dateString)
      return format(date, 'dd/MM/yyyy')
    } catch (e) {
      return ''
    }
  }

  return (
    <div className="box" ref={ref} id={tag}>
      <div>
        <h3>{name}</h3>
        <span className={`label status_${status}`}>{statusLabel}</span>

        {primaryGoal ? (
          <>
            <p>
              {deadline && (
                <>
                  <strong>Deadline</strong>: {dateFormat(deadline)}
                </>
              )}
            </p>
            <p>
              <strong>Primary goal</strong>: {primaryGoalName}
            </p>
          </>
        ) : null}

        {primaryGoal ? (
          completersLoaded ? (
            <CompleterStats target={target} count={completersCount} />
          ) : (
            <CircularProgress />
          )
        ) : null}
      </div>

      <div>
        <h3>Campaigns</h3>
        {liveCampaigns.length === 0 ? (
          <p>No campaigns are currently working toward this goal.</p>
        ) : (
          CampaignsLists(liveCampaigns)
        )}
      </div>
    </div>
  )
}
