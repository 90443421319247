import { useWindowSize } from 'usehooks-ts'
import { MOBILE_SCREEN_WIDTH } from 'Shared/constants'
import StatList from 'Shared/components/StatList'
import GoalBar from './GoalBar'
import VariantStats from './VariantStats'
import './StatsOverview.scoped.scss'
import SummaryItem from './stats/SummaryItem'
import { calculateRate } from './utils/dateAndMathsUtils'
import SendBar from './stats/SendBar'
import SummaryItems from './stats/SummaryItems'

const StatsOverview = ({
  overviewPage,
  targetsCount,
  entityBlast,
  sendingInProgress,
  allFailed,
  goalCompletionsCount = 0,
  helpfulCount = 0,
  notHelpfulCount = 0,
  stats,
}) => {
  const isWorkflowControlled = useSel((s) => s.local.isWorkflowControlled)
  const { campaignType } = useSel((s) => s.campaign || {});
  const contentVariants = useSel(s => {
    if (campaignType === 'email') {
      return s.campaign?.emailBlast?.contentVariants || []
    } else {
      return s.campaign?.textMessageBlast?.contentVariants || []
    }
  })
  let { stats: campaignStats } = useSel((s) => s.campaign || {});
  // TODO: REMOVE when migrated
  // backwards compatibility with old stats saved in DB
  if (!_.isArray(campaignStats)) {
    campaignStats = [campaignStats]
  }
  const goalName = useSel((s) => s.campaign?.settings?.goalName)
  const size = useWindowSize()
  const showHelpfulStat = helpfulCount + notHelpfulCount > 0

  function processSendPhaseStats(sendPhaseStats) {
    return sendPhaseStats.map((stats, i) => {
      const {
        contentVariantId,
        textMessagesCount,
        emailsCount,
        repliedCount,
        openedCount,
        clickedCount,
        unsubscribeCount,
        unsubscribedCount,
        sentWider,
      } = stats

      const calculateRate = (count, overrideDenominator = null) => {
        if (overrideDenominator === 0) return '0'

        const denominator = overrideDenominator || (campaignType === 'email' ? emailsCount : textMessagesCount)
        return denominator > 0 ? parseFloat(((100 * count) / denominator).toFixed(1)).toString() : '0'
      }

      return {
        id: contentVariantId,
        clickRate: calculateRate(clickedCount),
        entityCount: campaignType === 'email' ? emailsCount : textMessagesCount,
        unsubscribeCount: campaignType === 'email' ? unsubscribedCount : unsubscribeCount,
        replyRate: calculateRate(repliedCount),
        openRate: calculateRate(openedCount),
        conversionRate: calculateRate(clickedCount, openedCount),
        sentWider,
      }
    })
  }

  const sendPhases = _.map(
    _.groupBy(campaignStats, 'sendPhase'),
    (sendPhaseStats, sendPhase) => {
      return {
        sendPhase,
        stats: processSendPhaseStats(sendPhaseStats),
      }
    }
  )

  const hasTests = contentVariants.length > 1 && _.some(
    _.values(sendPhases),
    (variantStats) => variantStats.stats.length > 1
  )

  return (
    <>
      {(size.width >= MOBILE_SCREEN_WIDTH || overviewPage === 1) && (
        <>
          {!isWorkflowControlled && <SendBar
            entityBlast={entityBlast}
            sendingInProgress={sendingInProgress}
            allFailed={allFailed}
          />}

          <SummaryItems entityBlast={entityBlast} targetsCount={targetsCount} />

          {!isWorkflowControlled && (
            <>
              {goalName && campaignType !== 'email' && (
                <GoalBar
                  goalName={goalName}
                  goalCompletionsCount={goalCompletionsCount}
                />
              )}
            </>
          )}
        </>
      )}

      {(size.width >= MOBILE_SCREEN_WIDTH || overviewPage === 2) && (
        <>
          <div id="analytics" className="box">
            <StatList stats={stats} />
          </div>
          {showHelpfulStat && (
            <div className="box">
              <SummaryItem
                label={`${helpfulCount} out of ${helpfulCount + notHelpfulCount} found this helpful`}
                value={`${calculateRate(helpfulCount + notHelpfulCount, helpfulCount)}%`}
                icon={<SvgIconsQuestion width={32} height={32} />}
              />
            </div>
          )}

          { hasTests && sendPhases.length === 0 && <div className="box">
            <h3>AB Tests</h3>
            <p>Stats will appear here shortly</p>
          </div> }

          {hasTests &&
            sendPhases.map(({ sendPhase, stats }) => {
              return (
                <div key={sendPhase}>
                  <div className="box">
                    <VariantStats variants={stats} sendPhase={sendPhase} campaignType={campaignType} />
                  </div>
                </div>
              )
            })}
        </>
      )}
    </>
  )
}

export default StatsOverview
