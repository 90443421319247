import * as React from 'react'

function SvgInstruction(props) {
  return (
    <svg width={15} height={19} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        opacity={0.3}
        d="M2.205.777h6.943a1.5 1.5 0 01.969.355l4.129 3.492a1.5 1.5 0 01.53 1.146V16.85c0 1.592-.017 1.704-1.65 1.704H2.206c-1.633 0-1.651-.112-1.651-1.704V2.481C.555.889.573.777 2.205.777z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgInstruction
