import store from 'Shared/store'
import { Provider } from 'react-redux'
import ServerWaitButton from 'Shared/components/ServerWaitButton'
import './SendProgress.scoped.scss'
import { CircularProgress } from '@material-ui/core'

export default function SendProgress(props) {
  if (props.selfContained) {
    return <SendProgressWithStore {...props} />
  } else {
    return <SendProgressInner {...props} />
  }
}

function SendProgressInner({ campaignId, entityType, entityId, leftAligned, embedded=false }) {
  const { open = 0, total = 0, sent = 0, status = 'loading', sendingStatus, settings = {} } = useSel(s => s.sendBlast)
  const complete = total === sent && total > 0
  const interval = useRef(null)
  const act = useAct()
  const [confirm, setConfirm] = useState(false)

  function updateSendStatus() {
    act.sendBlast.updateProgress(campaignId, entityType, entityId)
  }

  function abort() {
    act.sendBlast.abort(campaignId, entityType, entityId)
    .then(() => setConfirm(false))
  }

  function pause() {
    return act.sendBlast.pause(campaignId, entityType, entityId)
  }

  function resume() {
    return act.sendBlast.resume(campaignId, entityType, entityId)
  }

  useEffect(() => {
    updateSendStatus()
    interval.current = setInterval(updateSendStatus, 1000)
    return () => clearInterval(interval.current)
  }, [])

  useEffect(() => {
    if ((status === 'aborted' || complete) && interval.current) {
      clearInterval(interval.current)
    }
  }, [complete, status])

  if (status === 'loading') return null

  const { sendMode, sendEndTime, sendStartTime, maxMessagesPerHour } = settings

  function progressDescription() {
    if (sendMode === 'max_per_hour') {
      return `Sending ${maxMessagesPerHour.toLocaleString()} messages per hour, between ${sendStartTime} and ${sendEndTime}`
    }  else {
      return null
    }
  }

  function getText() {
    const headingSize = (embedded) ? 'reduced' : 'standard';
    if (status === 'aborted') return <h2 className={headingSize}>This blast has been aborted</h2>
    if (!complete && sent === 0) return <h2 className={headingSize}>
        Building lists
        <CircularProgress className="margin-left" size={25}/>
      </h2>
    if (!complete) return <div className="heading">
      <h2 className={headingSize}>{ sendingStatus === 'paused' ? "Sending currently paused" : "We're sending your messages right now" }</h2>
      <div>
        { !confirm && <div className={`${leftAligned ? '' : 'flex-items-center'}`}>
          <div>
            <button className='button small red' onClick={() => setConfirm(true)}>Abort</button>
            { sendMode === 'max_per_hour' && <>
              { sendingStatus === 'paused' && <ServerWaitButton className='button light primary margin-left' onClick={resume}>Resume</ServerWaitButton> }
              { sendingStatus === 'queueing' && <ServerWaitButton className='button tertiary margin-left' onClick={pause}>Pause</ServerWaitButton> }
            </> }
          </div>
        </div> }
        { confirm && <div className="abort-confirm">
          <span>Are you sure?</span>
          <button className='button small red' onClick={abort}>Yes</button>
          <button className='button small grey' onClick={() => setConfirm(false)}>No</button>
        </div> }
      </div>
    </div>
    if (complete) return <h2 className={headingSize}>All your messages have been sent.</h2>
  }

  return <div className={leftAligned ? 'with-background' : ''}>
    { getText() }
    <div className={`progress-bar ${ sendingStatus === 'paused' ? 'paused' : '' }`}>
      { sent > 0 && <>
          <div className="progress-bar-bar-container">
            <div className="bar" style={{width: `${percentage(sent, total)}%`}}></div>
          </div>
          <div className="progress-bar-info">
            <div className="progress-bar-info-block --left">Sent {sent.toLocaleString()} out of {total.toLocaleString()} messages</div>
            <div className="progress-bar-info-block --right">{percentage(sent, total)}%</div>
          </div>
        </> }
      { sendMode === 'max_per_hour' && <div className="progress-bar-info">
        <div className="progress-bar-info-block below --left">{ progressDescription() }</div>
      </div> }
    </div>
  </div>
}

function SendProgressWithStore(props) {
  return <Provider store={store}>
    <ErrorBoundary>
      <SendProgressInner {...props} />
    </ErrorBoundary>
  </Provider>
}

function percentage(partialValue, totalValue) {
  if (partialValue === 0 || totalValue === 0) {
    return 0
  }
  return Math.round((100 * partialValue) / totalValue)
}
