export default function ListPreview() {
  const act = useAct()
  const campaign = useSel(({ campaign }) => campaign)
  const list = campaign.cannedResponses
  useEffect(() => {
    if (!campaign.cannedResponses) {
      act.cannedResponses.loadList()
    }
  }, [campaign.id])
  if (!list) {
    return <div>Loading...</div>
  }
  return (
    <>
      {list.map((cr, i) => (
        <p key={cr.id}>
          <strong>{cr.name}</strong>
        </p>
      ))}
      {list.length === 0 && <div>This campaign doesn't have any canned responses set up yet.</div>}
    </>
  )
}
