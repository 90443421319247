import { Loading, RingroundSnackbar } from 'Shared/components'
import { MessageHistory, MessageSender } from '../../index'
import EmptyView from './EmptyView'
import TopBar from './TopBar'
import useData from 'DesktopMessager/useData'
import TargetInfo from "Shared/components/TargetInfoNew"
import './PageContainer.scoped.scss'
import MobileTabs from 'Shared/components/Tabs/MobileTabs'
import Tab from "Shared/components/Tabs/Tab"
import { useWindowSize } from 'usehooks-ts'
import { MOBILE_SCREEN_WIDTH } from 'Shared/constants'

// TODO: replace with redux / async calls to populate data
// import { elObserver } from './helpers'

const PageContainer = () => {
  const size = useWindowSize()
  const contentRef = useRef()
  const name = useData(rs => rs.campaign.name)
  const conversationId = useData(rs => rs.user.currentConversationId)
  const dataLoaded = useData(rs => {
    return (rs.user && rs.campaign && rs.target?.id) || (rs.user?.id && !rs.user.currentConversationId)
  })
  const pusherConnected = useSel(s => s.local.pusherConnected)
  const loaded = pusherConnected && dataLoaded
  const target = useData(rs => rs.target)
  const noMoreMessages = loaded && !conversationId
  const hasSurvey = false

  const [tabIndex, setTabIndex] = useState(0)

  const desktopSize = size.width >= MOBILE_SCREEN_WIDTH
  const showTargetInfo = size.width >= MOBILE_SCREEN_WIDTH || tabIndex === 0
  const showConversation = size.width >= MOBILE_SCREEN_WIDTH || tabIndex === 1
  const showSurvey = hasSurvey && (desktopSize || tabIndex === 2)


  return (
    <div className="messager-root">
      <TopBar title={name} noMoreMessages={noMoreMessages} />
      <RingroundSnackbar />

      <div className="main">
        {!loaded && <Loading text={i18n.t('user.campaigns.text.loading_conversations')} />}
        {loaded && !conversationId && <EmptyView />}

        {loaded &&
          <div className={hasSurvey ? 'columns has-survey' : 'columns no-survey'}>
            {showTargetInfo &&
              <div className="left-sidebar">
                {target && target.id && <TargetInfo targetId={target.id} icon={'sms'} />}
              </div>
            }

            {showConversation && target && target.id &&
              <div className={desktopSize ? 'conversations box' : 'conversations'}>
                <div class="scroll">
                  <MessageHistory />
                </div>
                <div class="wrap-message-sender">
                  <MessageSender />
                </div>
              </div>
            }

            {showSurvey &&
              <div className="right-sidebar">
              </div>
            }
          </div>
        }
      </div>

      {size.width < MOBILE_SCREEN_WIDTH &&
        <MobileTabs value={tabIndex} onChange={setTabIndex}>
          <Tab label="Member Info" />
          <Tab label="Messages" />
          {hasSurvey && <Tab label="Survey" />}
        </MobileTabs>
      }

    </div>
  )
}

export default PageContainer
