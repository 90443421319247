import Tooltip from "react-simple-tooltip"
import './DynamicPrice.scoped.scss'
import formatCurrency from 'Shared/functions/formatCurrency'

export default function DynamicPrice({ usdAmount, textClasses }) {
  if (usdAmount === 'NaN') return null

  const amountGbp = window.ENV.USD_GBP_EXCHANGE * usdAmount
  const tooltipContent = `$${formatCurrency(usdAmount || 0)} based on latest exchange rate of ${window.ENV.USD_GBP_EXCHANGE} pounds to the dollar`

  return <Tooltip
    content={tooltipContent}
    style={{whiteSpace: "nowrap"}}
    open={true}
    >
    <span className={`root ${textClasses}`}>~£{formatCurrency(amountGbp)}</span>
  </Tooltip>
}
