import SvgIcon from '@material-ui/core/SvgIcon'

const ShieldIcon = () => (
  <SvgIcon
    {...{ viewBox: '0 0 32 42', width: 32, height: 42 }}
    style={{ fontSize: '3rem' }}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 3.889L15.6364 0.998483C15.8768 0.954056 16.1232 0.954056 16.3636 0.998483L32 3.889V25.3519C32 29.1694 30.0207 32.714 26.7706 34.7167L16.5246 41.0302C16.2029 41.2284 15.7971 41.2284 15.4754 41.0302L5.22945 34.7167C1.97935 32.714 0 29.1694 0 25.3519L0 3.889Z"
      fill="#E5F0FF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.6667 32L23 18.8H18.3333V10L9 23.2H13.6667V32Z"
      fill="#2849D6"
    />
  </SvgIcon>
)

export default ShieldIcon
