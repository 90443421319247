import React from 'react'
import PropTypes from 'prop-types'
import store from 'Shared/store'
import { Provider } from 'react-redux'
import ManageActions from './ManageActions'

const ManageActionsWrapped: PropTypes.InferProps<typeof ManageActions> = (props) => (
  <Provider store={store}>
    <ManageActions {...props} />
  </Provider>
)

export default ManageActionsWrapped
