import './UserInfo.scoped.scss'
import store from 'Shared/store'
import { Provider } from 'react-redux'
import Notifications from './Notifications'
import User from './User'

export default ({ initial, name, organisation, logoutLink, profilePicUrl }) => {
  return <Provider store={store}>
    <ErrorBoundary>
      <div className='user-info'>
        <Notifications />
        <User organisation={organisation} logoutLink={logoutLink} profilePicUrl={profilePicUrl} />
      </div>
    </ErrorBoundary>
  </Provider>
}
