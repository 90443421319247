import './PreviewScript.scoped.scss'
import ScriptPreview from './Preview/ScriptPreview'
import ShareCampaign from 'Campaigns/ShareCampaign/components/ShareCampaign'
import Step from './Step'
import DynamicPrice from 'Shared/components/DynamicPrice'
import calculateEstimate from './Audience/calculateEstimate'
import useValidateCampaign from 'CampaignBuilder/hooks/useValidateCampaign'

function PreviewFooter({ baseCost, contactCost, timeEstimate = null }) {
  const kind = useSel((s) => s.campaign.campaignType)
  const costEstimate = calculateEstimate(baseCost, contactCost, kind)

  return (
    <div className="info">
      <p className="no-margin-top no-margin-bottom smaller bold">
        <span>Est. time and cost of your calling campaign</span>
        <span className="ellipsis accent"></span>
        {costEstimate && timeEstimate && (
          <>
            <span>
              <DynamicPrice usdAmount={costEstimate} />
            </span>
            <span className="ellipsis accent"></span>
            <span className="prevent-wrap">{timeEstimate} mins</span>
          </>
        )}
        {(!costEstimate || !timeEstimate) && <span>Not available</span>}
      </p>
    </div>
  )
}

export default function PreviewScript() {
  const act = useAct()
  const {
    id: campaignId,
    callUrl,
    overviewUrl,
    slug,
    status,
  } = useSel((s) => s.campaign, _.isEqual)
  const questions = useSel((s) => _.values(s.questions.entities))
  const { baseCost, contactCost, timeEstimate } = useSel((s) => s.campaignBuilder)
  const [complete, errors] = useValidateCampaign()
  const footer = complete ? (
    <PreviewFooter
      baseCost={baseCost}
      contactCost={contactCost}
      timeEstimate={timeEstimate}
    />
  ) : null

  function finish() {
    return act.campaign.persist().then(() => {
      return act.campaign
        .launch()
        .then(() => {
          act.shareCampaign.open()
        })
        .catch((e) => {
          const message = e.response.data.message
          if (message) {
            act.local.upsert({ alert: { message, severity: 'error' } })
          }
        })
    })
  }

  return (
    <Step
      name="Preview"
      editor="preview"
      shortDescriptor={
        <p className="incomplete no-margin-bottom">Check everything is OK.</p>
      }
      action={finish}
      staticOpenButton="Preview"
      canClickFinish={complete}
      finishButtonText={status === 'live' ? 'Save' : 'Launch'}
      validationErrors={errors}
      footerElements={footer}
    >
      <ScriptPreview questions={questions} />
      <ShareCampaign
        title="Your campaign is launching now"
        campaignId={campaignId}
        campaignUrl={callUrl}
        actionUrl={overviewUrl}
        actionText="View your campaign"
        slug={slug}
        canClose={false}
      />
    </Step>
  )
}
