import './EffortsList.scoped.scss'

import Loading from 'Shared/components/Loading'
import useAct from 'Efforts/hooks/useAct'
import EffortCard from 'Efforts/components/EffortCard'
import FloatingMenu from 'Shared/components/FloatingMenu/FloatingMenu'

function effortLinks(efforts) {
  return efforts.map(({ id, name }) => ({
    name,
    tag: `effort-${id}`,
  }))
}

export default function EffortsList() {
  const act = useAct()

  const [loading, setLoading] = useState(true)

  useEffect(() => {
    act.efforts.load().then(() => {
      setLoading(false)
    })
  }, [])

  const efforts = useSel((state) => state.efforts)

  if (loading) {
    return <Loading />
  }

  if (!loading && efforts.length === 0) {
    return <p>You're not currently working towards any goals.</p>
  }

  return (
    <div className="efforts-list">
      <div className="left">
        <FloatingMenu items={effortLinks(efforts)} />
      </div>
      <div className="right">
        {efforts.map((effort) => (
          <EffortCard tag={`effort-${effort.id}`} effort={effort} key={effort.id} />
        ))}
      </div>
    </div>
  )
}
