import * as React from 'react'

function SvgDownCaret(props) {
  return (
    <svg width={18} height={10} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M16.5 1L9 8.5L1.5 1" stroke="#191936" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

export default SvgDownCaret
