import {
  createEntityAdapter,
  createSlice,
  createReducer,
  configureStore,
  getDefaultMiddleware,
} from '@reduxjs/toolkit'

const middleware = [...getDefaultMiddleware()]
const adapter = createEntityAdapter()

const surveySliceInitialState = {
  questions: [],
}

const surveySlice = createSlice({
  name: 'survey',
  initialState: surveySliceInitialState,
  reducers: {
    set: (s, a) => a.payload,
    upsert: (s, a) => ({ ...s, ...a.payload }),
  },
})

const surveyTakerSlice = createSlice({
  name: 'surveyTaker',
  initialState: {
    id: null,
    responses: {
    }
  },
  reducers: {
    answerQuestion: (s, a) => ({
      ...s, 
      responses: { ...s.responses, [a.payload.questionId]: a.payload.answer }
    }),
    upsert(s, a) {
      return { ...s, ...a.payload }
    },
    complete(s, a) {
      return {...s, completed: true}
    }
  },
})


const store = configureStore({
  middleware,
  reducer: {
    survey: surveySlice.reducer,
    surveyTaker: surveyTakerSlice.reducer
  },
})

export default store
