import { convertToRaw } from 'draft-js'

export default function(contentState) {
  const raw = convertToRaw(contentState)

  return raw.blocks
    .map(b => {
      return b.text
    })
    .join('\n')
}
