import './PageContainer.scss'
import './blocks/Block.scoped.scss'
import Block from './blocks/Block'
import NewBlock from './NewBlock'
import StaticBlock from 'EmailBuilder/components/blocks/StaticBlock'
import HtmlBlock from 'EmailBuilder/components/HtmlBlock'
import EditHead from 'EmailBuilder/components/EditHead'

const blankBlock = ({ id, parameters }) => ({
  id: uuidv4(),
  blockTemplateId: id,
  parameters: parameters,
  digitOrder: 1,
})

const PageContainer = ({ emailTemplateId = null }) => {
  const act = useAct()
  const newBlockMenu = useRef(null)

  const blockIds = useSel((s) => {
    const blocks = s.blocks.present.ids.map((id) => s.blocks.present.entities[id])
    return _.orderBy(blocks, 'digitOrder').map((b) => b.id)
  })

  const activeBlockId = useSel((s) => s.emailBuilder.activeBlockId)
  const headerHtmlRendered = useSel((s) => s.emailBuilder.headerHtmlRendered)
  const footerHtmlRendered = useSel((s) => s.emailBuilder.footerHtmlRendered)
  const hasUnsubscribeBlock = useSel((s) => s.emailBuilder.hasUnsubscribeBlock)
  const enableFeedbackWidget = useSel(
    (s) => s.campaign.emailBlast && s.campaign.emailBlast.enableFeedbackWidget
  )

  const editBlock = (id) => {
    act.emailBuilder.setActiveBlockId(id)
  }

  const addBlock = (template) => {
    const newBlock = blankBlock(template)
    act.email.blocks.upsert(newBlock.id, newBlock)
    setTimeout(() => act.emailBuilder.setActiveBlockId(newBlock.id))
  }

  const noBlocks = () => (
    <div>
      <NewBlock handleClick={addBlock} canClose={false} />
    </div>
  )

  return (
    <div className="foundation-container" style={{ display: 'inline-block', height: '100%' }}>
      {emailTemplateId && <EditHead emailTemplateId={emailTemplateId} />}

      <HtmlBlock
        htmlRendered={headerHtmlRendered}
        emailTemplateId={emailTemplateId}
        element="header"
        borderBottom={true}
      />
      {blockIds.map((id) => (
        <Block
          key={id}
          id={id}
          active={id === activeBlockId}
          onClick={() => editBlock(id)}
          first={id === blockIds[0]}
          last={id === blockIds[blockIds.length - 1]}
        />
      ))}
      {!blockIds.length && noBlocks()}
      {enableFeedbackWidget && (
        <div className="block-container">
          <StaticBlock blockTemplate="feedback" />
        </div>
      )}
      <HtmlBlock
        htmlRendered={footerHtmlRendered}
        emailTemplateId={emailTemplateId}
        element="footer"
        borderTop={true}
      />
      {hasUnsubscribeBlock && (
        <div className="block-container">
          <StaticBlock blockTemplate="unsubscribe" />
        </div>
      )}
    </div>
  )
}

export default PageContainer
