import './Flash.scoped.scss'

const Flash = ({ onHide, message, type = 'notice', addClass='' }) => {
  const [timer, setTimer] = useState(null)

  useEffect(() => {
    if(timer) {
      clearTimeout(timer)
    }
    setTimer(window.setTimeout(() => {
      onHide()
    }, 5000))
  }, [])

  return (
    <div className={`alert ${type} ${addClass}`}>
      {message}
    </div>
  )
}

export default Flash
