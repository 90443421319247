import useData from 'DesktopCaller/useData'

export default function PhoneNumber () {
  const friendlyPhoneNumber = useData(rs => rs.campaign.friendlyPhoneNumber)

  return <>
    <div className='begin box'>
      <h2>{i18n.t('user.campaigns.phone.ready_to_call')}</h2>
      <p>{ i18n.t('user.campaigns.phone.get_ready') }</p>
      <p className='no-margin-bottom'>{ i18n.t('user.campaigns.phone.dial_number', { phone: friendlyPhoneNumber }) }</p>
    </div>
  </>
}
