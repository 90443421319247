import StarRating from 'DesktopCaller/components/Script/StarRating'
import LabeledCard from 'Shared/components/LabeledCard/LabeledCard'
import './Rating.scoped.scss'
import useAct from 'DesktopCaller/useAct'

const ratingReasons = [
  { slug: 'background_noise', label: 'Background noise' },
  { slug: 'bad_signal', label: 'Bad signal' },
  { slug: 'unresponsive_caller', label: 'Unresponsive caller' },
]

export default function Redial() {
  const act = useAct()
  const [star, setStar] = useState(0)
  const [ratingReason, setRatingReason] = useState('')
  const [notes, setNotes] = useState('')
  const showFollowUp = false // TODO: implement: star > 0 && star < 3

  return (
    <LabeledCard
      label={
        <>
          <SvgIconsCallerInstruction />
          {i18n.t('user.campaigns.phone.summary')}
        </>
      }
    >

      <p className="no-margin-top">{i18n.t('user.campaigns.phone.how_would_rate')}</p>

      <StarRating callback={setStar} />

      {showFollowUp && (
        <>
          <p>{i18n.t('user.campaigns.phone.why_would_rate')}</p>
          <div className="buttons margin-bottom half">
            {ratingReasons.map((reason) => {
              return (
                <a
                  className="small button black"
                  onClick={() => setRatingReason(reason.slug)}
                >
                  {reason.label}
                </a>
              )
            })}
          </div>
        </>
      )}

      <p>{i18n.t('user.campaigns.phone.anything_else')}</p>
      <textarea rows="3" onChange={(e) => setNotes(e.target.value)} value={notes}>
        {notes}
      </textarea>

      <p><button
        className="button primary inline-block"
        onClick={() => act.desktopCaller.rateCall(star, ratingReason, notes)}
      >
        {i18n.t('shared.messages.finish')}
      </button></p>

    </LabeledCard>
  )
}
