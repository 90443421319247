import './Outcomes.scoped.scss'

const OUTCOME_LABELS = {
    'answer': 'Answered',
    'noAnswer': 'Not answered',
    'busy': 'Busy',
    'leftMessage': 'Left message',
    'doNotCall': 'Do not call',
    'badNumber': 'Wrong number',
    'numberNotRecognised': 'Number not recognised'
}

export default function Outcomes({ outcomes }) {
    const labels = Object.keys(outcomes || {}).filter((key) => {
        return typeof OUTCOME_LABELS[key] !== 'undefined'
    }).sort()

    return <div className="flex column space-x-1 flex-grow">
        <h3 className='no-margin-top'>Outcomes</h3>
        <div className='outcomes'>
            { labels.map((label) => <div className='outcome'>
                    <p className='name'>{ OUTCOME_LABELS[label] }</p>
                    <p className='count'>{ outcomes[label] }</p>
                </div>
            )}
            { labels.length === 0 && <p className="no-margin">Key calling stats will appear here as this campaign gets underway.</p> }
        </div>
    </div>
}
