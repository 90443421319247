import FullWidthBackdropModal from 'Shared/components/BackdropModal/FullWidthBackdropModal'
import ServerWaitButton from 'Shared/components/ServerWaitButton'
import InlineCkEditor from 'Shared/components/CKEditor/InlineCkEditor'
import './Build.scoped.scss'

const Build = ({ isOpen, close, mergeTagTestId }) => {
  const act = useAct()
  const campaignId = useSel((s) => s.campaign.id)
  const { mergeTags } = useSel((s) => s.campaignBuilder)
  const mergeTagTest = useSel((s) => s.mergeTagTests.entities[mergeTagTestId])
  const variants = mergeTagTest?.mergeTagTestVersions || []
  const canDelete = variants.length > 2
  const isComplete =
    mergeTagTest &&
    mergeTagTest.name &&
    variants.length >= 2 &&
    variants.every((v) => v.content)

  function updateName(name) {
    act.email.mergeTagTests.upsert(mergeTagTestId, {
      ...mergeTagTest,
      name,
    })
  }

  function add() {
    const newVariant = {
      id: uuidv4(),
      content: '',
    }

    act.email.mergeTagTests.upsert(mergeTagTestId, {
      ...mergeTagTest,
      mergeTagTestVersions: [...variants, newVariant],
    })
  }

  function remove(variantId) {
    act.email.mergeTagTests.upsert(mergeTagTestId, {
      ...mergeTagTest,
      mergeTagTestVersions: variants.filter((v) => v.id !== variantId),
    })
  }

  function updateContent(variantId, content) {
    act.email.mergeTagTests.upsert(mergeTagTestId, {
      ...mergeTagTest,
      mergeTagTestVersions: variants.map((v) =>
        v.id === variantId ? { ...v, content } : v
      ),
    })
  }

  function cancel() {
    return act.campaign.load(campaignId).then(() => {
      close()
    })
  }

  function save() {
    return act.campaign.persist().then(() => {
      close()
    })
  }

  if (!mergeTagTest) return null

  return (
    <FullWidthBackdropModal
      title={'Edit experiment'}
      maxWidth={900}
      width={900}
      open={isOpen}
      onCancel={cancel}
    >
      <div className="modal-content">
        <div className="build">
          <p className="no-margin-top no-margin-bottom bold">Experiment name</p>
          <input
            type="text"
            className="full-width"
            value={mergeTagTest.name}
            onChange={(e) => updateName(e.target.value)}
          />

          <div className="variants">
            {variants.map((variant, index) => (
              <div key={variant.id} className="variant">
                <div className="variant-header">
                  <p className="bold no-margin-top">Variant {index + 1}</p>
                  {canDelete && (
                    <a className="delete" onClick={() => remove(variant.id)}>
                      <SvgIconsDelete />
                    </a>
                  )}
                </div>
                <textarea
                    rows={2}
                    className='full-width'
                    value={variant.content}
                    onChange={(e) => updateContent(variant.id, e.target.value)}
                />
              </div>
            ))}
          </div>

          <button className="button secondary small" onClick={add}>
            <SvgIconsExperiment /> Add Variant
          </button>
        </div>
      </div>

      <div className="actions align-horizontally-center margin-bottom double">
        <ServerWaitButton
          onClick={() => save()}
          className="button primary margin-right t-exp-save"
          disabled={!isComplete}
        >
          Save
        </ServerWaitButton>
        <ServerWaitButton onClick={() => cancel()} className="button naked link">
          Cancel
        </ServerWaitButton>
      </div>
    </FullWidthBackdropModal>
  )
}

export default Build
