import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TableBody from '@material-ui/core/TableBody'
import TableContainer from '@material-ui/core/TableContainer'

const CsvPreview = ({ header, csv, showingErrors = false }) => {
  const slice = showingErrors ? csv.map((errorRow) => [errorRow.index, ...errorRow.data]) : csv

  return (
    <TableContainer component={Paper} style={{ overflowY: 'auto', maxHeight: 325 }}>
      <Table size="small">
        <TableHead>
          <TableRow>
            { showingErrors && <TableCell key={'line'}>#</TableCell> }
            { header.map((heading) => (
              <TableCell key={heading}>
                <b>{heading}</b>
              </TableCell>
            )) }
          </TableRow>
        </TableHead>
        <TableBody>
          { slice.map((row, idx) => (
            <TableRow key={idx.toString()}>
              { row.map((cell, cellIdx) => {
                const hasError = showingErrors && csv[idx].errorCells.includes(cellIdx-1)
                return (
                  <TableCell className={hasError ? 'with-error' : null} key={cellIdx}>{cell}</TableCell>
                )
              }) }

            </TableRow>
          ))}
          { !showingErrors && <TableRow>
            { header.map((cell, idx) => (
              <TableCell key={idx}>...</TableCell>
            ))}
          </TableRow> }
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default CsvPreview
