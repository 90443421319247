import './OverviewStats.scoped.scss'
import restClient from 'Shared/hooks/restClient'
import { useEffect, useState } from 'react'
import { parseISO, format } from 'date-fns'
import useAct from 'CampaignStats/useAct'
import useScriptActions from 'Shared/ScriptEditor/hooks/useAct'
import store from 'CampaignStats/store'
const percent = require('percent-value')

import { Provider } from 'react-redux'

import GoalBar from 'CampaignOverview/components/GoalBar'
import ExportStats from 'CampaignStats/components/ExportStats'
import QuestionStats from 'Shared/Surveys/components/QuestionStats'
import Snackbar from 'Shared/components/Snackbar'
import Outcomes from './Phone/Outcomes'
import CallsMade from './Phone/CallsMade'
import CallsByAttempt from './Phone/CallsByAttempt'
import OrganisationBreakdown from './Phone/OrganisationBreakdown'
import Boxes from "Shared/components/Boxes"
import Stat from 'Shared/components/Stat'
import OrganisationPickerButton from 'Shared/components/OrganisationPicker/OrganisationPickerButton'
import SvgCircLoading from 'images/CircLoading'

function Launching() {
  return <div className='box'>
    <p className='no-margin-top no-margin-bottom'>Your campaign is launching now! Once it's ready, you'll be able to see information about how it's going.</p>
  </div>
}

const StatsBoxes = ({ totalTargets, totalCalls, callsToday, remainingPhoneCalls, status }) => {
  const boxes = status === 'launching' ? (
    <Boxes boxes={[
      <div className="flex">
        <SvgCircLoading margin="0 15px 0 0" /><h2>Launching now</h2>
      </div>
    ]} />
    ) : (
    <Boxes boxes={[
      <Stat
        icon={<SvgIconsAudience />}
        borderBottom={false}
        size={'vertical'} label={`Audience size`}
        primary={totalTargets}
      />,
      <Stat
        icon={<SvgIconsCallers />}
        borderBottom={false}
        size={'vertical'} label={`Number of calls`}
        primary={totalCalls}
        secondary={`${callsToday} made today`}
      />,
      <Stat
        icon={<SvgIconsTrendingDown />}
        borderBottom={false}
        size={'vertical'} label={`Remaining today`}
        primary={remainingPhoneCalls}
      />
    ]} />
  )
  return boxes
}

export default function OverviewStats({ onlyQuestions }) {
  return (
    <div>
      <Provider store={store}>
        <OverviewStatsContent onlyQuestions={onlyQuestions} />
      </Provider>
    </div>
  )
}

const OverviewStatsContent = ({ onlyQuestions }) => {
  const act = useAct()
  const scriptActions = useScriptActions()

  const campaignId = useSel(s => s.local.campaignId)
  const campaignStats = useSel(s => s.local.campaignStats || {})
  const orgCount = _.keys(campaignStats).length
  const { chosenOrganisationId, chosenOrganisationName } = useSel(s => s.local)
  const [workflows, setWorkflows] = useState([])
  const subscriptionTopic = useSel(s => s.campaign?.subscriptionTopic)
  const [status, setStatus] = useState('')
  if (subscriptionTopic !== 'default') {
    var subscriptionTopicLabel = window.ENV.AVAILABLE_SUBSCRIPTIONS.phone.find(sub => sub.topic === subscriptionTopic)?.label
  }

  const { goalName } = useSel((s) => s.campaign?.settings || {})

  useEffect(() => {
    if (!campaignId) return

    act.campaignStats.loadMe()
    act.campaign.load(campaignId)
    const interval = setInterval(act.campaignStats.reloadStats, 120 * 1000)
    return () => {
      clearInterval(interval)
    }
  }, [campaignId])

  const stats = useSel(s => s.campaign?.stats)
  const { launchedAt, deadline, autoRefreshSearch, campaignTargetsLastRefreshedAt } = useSel(s => {
    const { launchedAt, deadline, autoRefreshSearch, campaignTargetsLastRefreshedAt } = s.campaign || {}

    return {
      autoRefreshSearch: autoRefreshSearch,
      launchedAt: launchedAt ? parseISO(launchedAt) : null,
      deadline: deadline ? parseISO(deadline) : null,
      campaignTargetsLastRefreshedAt: campaignTargetsLastRefreshedAt ? parseISO(campaignTargetsLastRefreshedAt) : null
    }
  })
  const showCampaignTargetsLastRefreshedAt = autoRefreshSearch && campaignTargetsLastRefreshedAt
  const { name: searchName, id: searchId } = useSel(s => s.campaign.search || {})
  const { surveyId } = useSel(s => s.campaign || {})

  useEffect(() => {
    if (surveyId) {
      scriptActions.scriptBuilder.load(surveyId)
      const questionInterval = setInterval(() => scriptActions.scriptBuilder.load(surveyId), 5000)

      return () => {
        clearInterval(questionInterval)
      }
    }
  }, [surveyId])

  useEffect(() => {
    restClient.get(`/api/campaigns/${campaignId}/workflows`).then(({ data }) => setWorkflows(data))
  }, [])

  const pickOrganisation = (id, name) => {
    act.local.upsert({ chosenOrganisationId: id, chosenOrganisationName: name })
    act.campaignStats.setOrganisationId(id)
  }

  useEffect(() => {
    let intervalId

    const checkStatus = async () => {
      const response = await fetch(`/api/campaigns/${campaignId}/status`)
      const data = await response.json()
      setStatus(data.status)

      if (data.status !== 'launching') {
        clearInterval(intervalId)
      }
    }

    if (status === 'launching' || status === '') {
      checkStatus();
      intervalId = setInterval(checkStatus, 1000)
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId)
      }
    };
  }, [campaignId])

  if (onlyQuestions) return <div className="overview-stats">
    <ExportStats />
    <QuestionStats />
  </div>

  if (!campaignStats[chosenOrganisationId] && !stats) {
    return <Launching />
  }

  const {
    totalTargets = 0,
    pickupRate = 0,
    totalCallers = 0,
    activeCallers = 0,
    completedCalls = 0,
    totalCalls = 0,
    recyclableCalls = 0,
    spokenToToday = 0,
    remainingPhoneCalls = 0,
    callsToday = 0,
    outcomes = {},
    callsByAttempt = {},
    maxAttempts = 3,
    goalCompletionsCount = 0,
  } = (campaignStats[chosenOrganisationId] || stats || {})

  // check if the stats are awaiting generation
  if (typeof totalCalls === 'undefined') {
    return <Launching />
  }

  const path = `/admin/searches/${searchId}`;

  return (
    <div className="overview-stats">
      <div className="header">
        {chosenOrganisationId && <div className="actions">
          <label>Organisation: {chosenOrganisationName}</label>
          <OrganisationPickerButton title="Change" onPick={(id, name) => pickOrganisation(id, name)} />
        </div>}
      </div>

      {goalName &&
        <GoalBar goalName={goalName} goalCompletionsCount={goalCompletionsCount} />
      }

      <div className="headline-stats">
        <Boxes unbox={true} boxes={[
          <StatsBoxes
            totalTargets={totalTargets}
            totalCalls={totalCalls}
            callsToday={callsToday}
            remainingPhoneCalls={remainingPhoneCalls}
            status={status}
          />,
          <div className="square">
            <div className="row-with-icon">
              <div className="icon">
                <SvgIconsCalendar />
              </div>
              <div className='duration'>
                <div className='time'>
                  <h3>Start</h3>
                  <p className='description'>{launchedAt ? format(launchedAt, 'dd/MM/yyyy') : 'No start date'}</p>
                </div>
                <div className='time'>
                  <h3>End</h3>
                  <p className='description'>{deadline ? format(deadline, 'dd/MM/yyyy HH:mm') : 'No ending date'}</p>
                </div>
              </div>
            </div>

            <div className="row-with-icon">
              <div className="icon">
                <SvgIconsAudience />
              </div>
              <div>
                <div><a className='title' href={path}>{searchName}</a><span className='bold dark-grey'> (audience)</span></div>
                {showCampaignTargetsLastRefreshedAt &&
                  <p className="smaller dark-grey">
                    Members within this campaign are refreshed every hour.
                    The last refresh was at: {format(campaignTargetsLastRefreshedAt, 'dd/MM/yyyy HH:mm')}
                  </p>
                }
              </div>
            </div>

            {subscriptionTopicLabel && <div className="row-with-icon">
              <div className="icon">
                <SvgIconsQuote />
              </div>
              <div>
                <div><span className='title'>{subscriptionTopicLabel}</span><span className='bold dark-grey'> (subscription topic)</span></div>
              </div>
            </div>}

            {workflows?.map((workflow, _index) => {
              return <div className="row-with-icon" key={`workflow-${workflow.id}`}>
                <div className="icon">
                  <SvgIconsAutomations />
                </div>
                <div>
                  <a className='title' href={`/admin/workflows/${workflow.id}/overview`}>{workflow.name}</a><span className='bold dark-grey'> (workflow)</span>
                </div>
              </div>
            })
            }
          </div>
        ]} />
      </div>

      <Boxes boxes={[
        <CallsMade
          completed={completedCalls}
          remaining={remainingPhoneCalls}
          recyclable={recyclableCalls}
          spokenToToday={spokenToToday}
          callsToday={callsToday}
          callsByAttempt={callsByAttempt}
        />,
        <CallsByAttempt data={callsByAttempt} maxAttempts={maxAttempts} />,
      ]} sizes={['two-thirds', 'third']} />

      {orgCount > 1 && <>
        <div className="headline-stats margin-top double">
          <Boxes boxes={[
            <OrganisationBreakdown />,
            <Outcomes outcomes={outcomes} />
          ]} sizes={['half', 'half']} />
        </div>
        <div className="headline-stats margin-top double">
          <Boxes unbox={true} boxes={[
            <Boxes boxes={[
              <Stat
                icon={<SvgIconsPickupRate />}
                borderBottom={false}
                size={'vertical'} label={`Pickup rate`}
                primary={pickupRate.toFixed(1)}
              />,
              <Stat
                icon={<SvgIconsTotalCallers />}
                borderBottom={false}
                size={'vertical'} label={`Total callers`}
                primary={totalCallers}
              />,
              <Stat
                icon={<SvgIconsCallers />}
                borderBottom={false}
                size={'vertical'} label={`Live callers`}
                primary={activeCallers}
                showPulse={activeCallers === 0}
              />
            ]} />
          ]} />
        </div>
      </>}

      {orgCount <= 1 && <>
        <div className="headline-stats margin-top double">
          <Boxes boxes={[
            <Outcomes outcomes={outcomes} />,
            <Boxes boxes={[
              <Stat
                icon={<SvgIconsPickupRate />}
                borderBottom={false}
                size={'vertical'} label={`Pickup rate`}
                primary={pickupRate.toFixed(1)}
              />,
              <Stat
                icon={<SvgIconsTotalCallers />}
                borderBottom={false}
                size={'vertical'} label={`Total callers`}
                primary={totalCallers}
              />,
              <Stat
                icon={<SvgIconsCallers />}
                borderBottom={false}
                size={'vertical'} label={`Live callers`}
                primary={activeCallers}
                showPulse={activeCallers === 0}
              />
            ]} />
          ]} />
        </div>
      </>}

      <ExportStats phoneCampaign={true} />
      <QuestionStats />
      <Snackbar />
    </div>
  )
}
