/* eslint-disable react/prop-types */
import { FC } from 'react'
import { DayChunksOptions, dayChunks } from './helpers'
import Root from './Root'
import './Timeline.scoped.scss'

interface TimelineOptions<T> {
  autoScroll?: boolean
  fade?: boolean
  highlight?: boolean
  title?: string
  chunk?: Omit<DayChunksOptions<T>, 'items'>
}
interface TimelineProps<T = any> {
  items: Array<T>
  height: any
  ItemComponent: ({ item }: { item: T }) => JSX.Element
  ItemComponentProps: any
  options: TimelineOptions<T>
}
const Timeline: FC<TimelineProps> = ({
  items,
  height,
  ItemComponent,
  ItemComponentProps,
  options,
}) => {
  if (!items) return null
  const chunks = dayChunks({ items, ...options.chunk })
  const messagesRef = useRef()

  useEffect(() => {
    if (options.autoScroll) {
      setTimeout(() => {
        messagesRef.current.scrollTop = messagesRef.current.scrollHeight
      }, 200)
    }
  }, [items])

  const lineHeight =
    items.length > 2 ? '78%' : items.length > 1 ? '62%' : items.length > 0 ? '43%' : '0%'

  return (
    <Root>
      {options?.title && (
        <Grid item xs={12}>
          <h4>{options?.title}</h4>
        </Grid>
      )}
      <div className="timeline-line" style={{ '--line-height': lineHeight }} />
      <Grid item xs={12} className={`messages custom-scrollbar ${height === 'auto' ? 'auto-height' : ''}`} ref={messagesRef}>
        <div className="gradient gradient-top" />
        {chunks.map((chunk, cIdx) => {
          const active = cIdx === chunks.length - 1
          return (
            <div key={chunk.date}>
              <div className="chip">{chunk.date}</div>
              {chunk.items.map((item) => (
                <div key={item.createdAt} className="item-container">
                  <ItemComponent {...ItemComponentProps} item={item} />
                </div>
              ))}
            </div>
          )
        })}
      </Grid>
    </Root>
  )
}

export default Timeline
