import React, { memo } from 'react';
import { Handle, Position } from 'reactflow';
import NodeList from './NodeList';
import useAct from 'Workflows/hooks/useAct'
import useNodeSize from 'Workflows/hooks/useNodeSize'

function BranchPlaceholderNode({ id, data }) {
  const act = useAct()
  const ref = useNodeSize(id)

  const nodeId = parseInt(id.split('_')[0]) // we are passed the internal react flow ID which is like nodeId-branch-placeholder

  function onClick() {
    act.builder.addBranch({ nodeId })
  }

  return <div className={`node node-branch node-branch-placeholder`} onClick={onClick} ref={ref}>
    <div>Add</div>

    <Handle type="target" position={Position.Top} />
    <Handle type="source" position={Position.Bottom} />
  </div>
}

export default memo(BranchPlaceholderNode);
