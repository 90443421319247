import PillEditor from 'Shared/components/PillEditor/PillEditor'
import SpinbuttonField from 'Shared/components/SpinbuttonField'
import Divider from 'Shared/components/Divider'
import FontFamilyField from 'Shared/components/FontFamilyField'

const TableBlockProperties = ({ block }) => {
  const act = useAct()
  const { mergeTags } = useSel((s) => s.campaignBuilder)
  const { data, focussed, rows, columns, widths } = block.parameters
  const isFocussed = focussed && focussed.length > 0
  const focussedValue = isFocussed ? data[focussed[0]][focussed[1]] : ''
  const focussedWidth = isFocussed ? widths[focussed[1]] : ''
  const [widthOption, setWidthOption] = useState(
    focussedWidth === 'auto' ? 'auto' : 'custom'
  )

  const handleFontFamilyChange = (fontFamily) => {
    act.email.blocks.updateParameters(block.id, { fontFamily })
  }

  const handleFontSizeChange = (fontSize) => {
    act.email.blocks.updateParameters(block.id, { fontSize })
  }

  const handleAttributeChange = (value) => {
    if (focussed && focussed.length > 0) {
      const updatedData = data.map((row) => [...row])

      if (!updatedData[focussed[0]]) {
        updatedData[focussed[0]] = []
      }

      updatedData[focussed[0]][focussed[1]] = value
      act.email.blocks.updateParameters(block.id, { data: updatedData })
    }
  }

  const handleWidthOptionChange = (event) => {
    setWidthOption(event.target.value)
    if (event.target.value === 'auto') {
      handleColumnWidthChange('auto')
    }
  }

  const handleColumnWidthChange = (width) => {
    if (focussed && focussed.length > 0) {
      const updatedWidths = [...widths]
      updatedWidths[focussed[1]] = width

      act.email.blocks.updateParameters(block.id, { widths: updatedWidths })
    }
  }

  return (
    <form>
      <h3 className="no-margin-top">Table font</h3>
      <FontFamilyField
        value={block.parameters.fontFamily}
        onChange={handleFontFamilyChange}
      />

      <Divider />

      <SpinbuttonField
        label="Font size"
        id="font-size-field"
        max="24"
        min="8"
        step="1"
        value={block.parameters.fontSize}
        onChange={handleFontSizeChange}
      />

      <h3>Edit content</h3>
      {isFocussed ? (
        <>
          <div className="field">
            <PillEditor
              key={isFocussed ? `${focussed[0]}-${focussed[1]}` : 'no-focus'}
              richText
              singleLine
              condensed
              initialValue={focussedValue || ''}
              onChange={handleAttributeChange}
              availableTags={mergeTags}
            />
          </div>
          <div className="field">
            <h3 className="margin-top">Column width</h3>
            <div className="width-field">
              <div>
                <label>
                  <input
                    type="radio"
                    value="auto"
                    name="widthOption"
                    checked={widthOption === 'auto'}
                    onChange={handleWidthOptionChange}
                  />
                  Automatic
                </label>
              </div>
              <div>
                <label>
                  <input
                    type="radio"
                    value="custom"
                    name="widthOption"
                    checked={widthOption === 'custom'}
                    onChange={handleWidthOptionChange}
                  />
                  Enter a %
                </label>
              </div>
              {widthOption === 'custom' && (
                <input
                  type="number"
                  id="columnWidth"
                  value={focussedWidth || ''}
                  onChange={(e) => handleColumnWidthChange(e.target.value)}
                />
              )}
            </div>
          </div>
        </>
      ) : (
        <p>Click on a table cell to edit the contents.</p>
      )}
    </form>
  )
}

export default TableBlockProperties
