import './HelpPopover.scoped.scss'
import restClient from 'Shared/hooks/restClient'
import { CircularProgress } from '@material-ui/core'
import BugReport from 'Shared/components/BugReport/BugReport'

const Articles = ({articles}) => (
  <ul>
    {articles.map(article => (
      <li><a href={article.url} key={article.id} target="_blank" rel="noreferrer">
        {article.title}
      </a></li>
    ))}
  </ul>
)

const HelpPopover = () => {
  const [loading, setLoading] = useState(true)
  const [articles, setArticles] = useState([])
  const [open, setOpen] = useState(false)

  useEffect(() => {
    restClient.get(`/api/help_articles/search?route=${window.location.pathname}`)
      .then(({ data }) => {
        setArticles(data)
        setLoading(false)
      }
      )
  }, [])

  return (
    <div className="card popup">
      <p className='margin-bottom'>How can we help?</p>
      { loading && <div className="loading"><CircularProgress /></div> }
      { !loading && !!articles.length && <Articles articles={articles} /> }
      { !loading && !articles.length && <span>If you need help using this page, please visit our support centre.</span> }

      { window.ENV.HELP_CENTRE != '' &&
        <a className='button secondary margin-top' target='_blank' href={window.ENV.HELP_CENTRE} rel="noreferrer">Visit the support centre</a>
      }
      <div>
        <BugReport />
      </div>

    </div>
  )
}

export default HelpPopover
