import AsyncSelect from 'Shared/components/AsyncSelect'
import Step from './Step'

function EffortShortDescriptor({ complete, effortName }) {
  return (
    <div>
      {complete && <div>
        <p className='no-margin-bottom'>This campaign is part of the <b>{effortName}</b> goal.</p>
      </div>}
      {!complete && <p className='incomplete no-margin-bottom'>(Optional) Select a goal that this campaign is working toward.</p>}
    </div>
  )
}

const Effort = () => {
  const act = useAct()

  const {
    effort: { id, name } = {}
  } = useSel(s => s.campaign)

  const complete = !!name

  const updateEffort = (e, objs) => {
    act.campaign.upsert({ effortId: e })
    act.campaign.upsert({
      effortId: e,
      effort: { id: e, name: objs?.label },
    })
  }

  const clearEffort = () => {
    updateEffort(null, null)
  }

  return <Step
    name='Goal'
    editor='effort'
    complete={complete}
    shortDescriptor={<EffortShortDescriptor complete={complete} effortName={name} />}
  >
    <div className='row'>
      <div className='column two-thirds'>
        <AsyncSelect
          entity='effort'
          value={id}
          loadStaticList={true}
          queryParameters={{ kept: 1 }}
          onChange={updateEffort}
          classNamePrefix="email-sender-select"
        />
        {id && <>
          <br />
          <a href="#" onClick={clearEffort} className="blue-link">Clear</a>
        </>}
      </div>

      <div className='column third'>
        <p className='no-margin-top'>
          Goals optionally let you organise campaigns into long-running organisational initiatives.
          For example, a campaign might be part of an 'Indicative strike ballot' goal.
        </p>
      </div>
    </div>
  </Step>
}

export default Effort
