import { useStore } from 'react-redux'
import efforts from 'Efforts/hooks/efforts'
import restClient from 'Shared/hooks/restClient'

const actionSlices = {
  efforts,
}

export default () => {
  const dis = useDis()
  const store = useStore()

  return useMemo(
    () => _.mapValues(actionSlices, (fn) => {
      return fn(dis, store, restClient)
    }),
    []
  )
}
