import React from 'react'
import PropTypes from 'prop-types'
import { Provider } from 'react-redux'
import store from 'Shared/store'
import Menu from './Menu'

export default function SurveyActions({ id }) {
  return (
    <Provider store={store}>
      <div className="actions">
        <Menu surveyId={id} />
      </div>
    </Provider>
  )
}

SurveyActions.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}
