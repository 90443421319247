const survey = (dis, store, client) => {
  function load(surveyId) {
    return client.get(`/api/target/surveys/${surveyId}`).then(({data}) => {
      dis({ type: 'survey/set', payload: data})
    })
  }

  return { load }
}

export default survey
