import restClient from 'Shared/hooks/restClient'

import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import './Menu.scoped.scss'
import ManageActions from 'Shared/components/ManageMenu/ManageActions'
import React from 'react'

export default function Menu({ surveyId }) {
  const [survey, setSurvey] = useState({})

  useEffect(() => {
    restClient.get(`/api/surveys/${surveyId}`).then(({ data }) => setSurvey(data))
  }, [])
  return <ManageActions target={survey} type="survey" />
}

Menu.propTypes = {
  surveyId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}
