import classNames from 'classnames'
import './Rule.scoped.scss'
import validateRule from '../validateRule'
import Group from './Group'
import Filter from './Filter'

export default function Rule({ id, inset = false, showAnyAll = true }) {
  const act = useAct()
  const allRules = useSel((s) => Object.values(s.rules.entities))
  const rule = useSel((s) => s.rules.entities[id])
  const availableFilters = useSel((s) => s.availableFilters, _.isEqual)
  const isValid = validateRule(rule, availableFilters, allRules)
  const isDefault = rule.isDefault
  const { type } = rule

  return (
    <div
      className={classNames('rule', {
        default: isDefault,
        inset,
        invalid: !isValid,
      })}
    >
      {inset && (
        <div className="left">
          <button
            onClick={() => act.search.removeRule(id)}
            className="button naked remove-button"
          >
            <SvgIconsTrash />
          </button>
        </div>
      )}
      <div className="right">
        {type === 'group' && <Group id={id} inset={inset} showAnyAll={showAnyAll} />}
        {type === 'filter' && <Filter id={id} />}
      </div>
      {isDefault && (
        <p
          className="default-label"
          title="This search filter is added by default, to ensure you get the best possible audience. You can remove it if it's not applicable to this search."
        >
          Default search filter
        </p>
      )}
    </div>
  )
}
