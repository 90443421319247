import reducers from 'DesktopMessager/reducers'
import store from 'DesktopMessager/store'
import PageContainer from 'DesktopMessager/components/DesktopMessager/PageContainer'
import { useStore, Provider } from 'react-redux'
import useAct from 'DesktopMessager/useAct'
import ActProvider from 'DesktopMessager/ActProvider'

export default function DesktopMessager(props) {
  const { campaignId, userId } = props
  store.dispatch({ type: 'local/upsert', payload: { campaignId, userId } })
  window.__store__ = store

  return (
    <div>
      <Provider store={store}>
        <ActProvider>
          <Wrapper {...props} />
        </ActProvider>
      </Provider>
    </div>
  )
}

function Wrapper({ campaignId, userId }) {
  const store = useStore()
  const act = useAct()

  useEffect(() => {
    act.conversationManager.getCampaign()
    act.conversationManager.ensureAllocated().then(() => {
      act.conversationManager.getConversationSession()
    })

    function ping() {
      act.user.ping()
    }

    const interval = setInterval(() => {
      ping()
    }, 60000)
    ping()

    return () => clearInterval(interval)
  }, [])

  return <PageContainer />
}
