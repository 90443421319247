const columns = [
  {
    "data": "name",
    "className": "bold",
    "render": function(data, type, row, meta) {
      return `<a href='${row['url']}'>${data}</a>`;
    }
  },
  { "data": "slug" },
  { "data": "kind" },
  { "data": "scope" },
  { "data": "category" },
  { "data": "description" },
  { "data": "default_value" },
  {
    data: 'id',
    className: 'right',
    discarded: 'discarded',
    render: (data, type, row) => {
      return row.discarded === 'true'
        ? `<button data-custom-field-id="${data}" class="button small green restore-custom-field" type="button">Restore</button>`
        : `<button data-custom-field-id="${data}" class="button small red remove-custom-field" type="button">Archive</button>`
    }
  },
]

const headers = [
  'Name',
  'API name',
  'Kind',
  'Scope',
  'Category',
  'Description',
  'Default value',
  '',
]

const filters = [
  {
    name: 'Show archived only', customParam: 'show_archived', kind: 'radio', options: [
      { value: 1, label: 'Yes' },
    ]
  },
]

const sorters = [
]

const order = [[1, "asc"]]

export { columns, headers, filters, sorters, order }
