export default function SearchName() {
  const act = useAct()
  const { name, template, delegated } = useSel((s) => s.search)

  return (
    <div className="step">
      <div className="section">
        <div className="box">
          <div className="form narrow">
            <h2>Name your audience</h2>
            <p>
              Lastly, please name your target audience. You can return to this at any
              point to launch campaigns or to do analysis.
            </p>

            <div className="field">
              <label>Audience name</label>
              <input
                type="text"
                value={name}
                onChange={(e) => act.search.upsert({ name: e.target.value })}
              />
            </div>

            <div className="field">
              <label htmlFor="search[template]">
                Make this audience available as a template?
              </label>
              <p>
                This audience will appear on the home screen as a "quick audience" you can
                quickly copy and use in a campaign. Use this for commonly used selections
                of members.
              </p>
              <input
                type="checkbox"
                checked={!!template}
                onChange={(e) => act.search.upsert({ template: !template })}
                id="search[template]"
              />
            </div>

            <div className="field">
              <label htmlFor="search[delegated]">Share with sub-organisations?</label>
              <p>
                Organisations below yours will be able to use this audience in their own
                audiences. They will only be able to see and create campaigns to the
                people who match their own access level.
              </p>
              <input
                type="checkbox"
                checked={!!delegated}
                onChange={(e) => act.search.upsert({ delegated: !delegated })}
                id="search[delegated]"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
