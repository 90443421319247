import { renderToString } from 'react-dom/server'
import './textMessages.scoped.scss'

const tickOrCross = (data) => data === 'true' ?
  renderToString(<SvgIconsTickGreen />) :
  renderToString(<SvgIconsRedCross />)

const columns = [
  {
    data: 'name', width: '30%',
  },
  {
    data: 'email', width: '34%',
  },
  {
    data: 'opened', width: '12%',
    render: tickOrCross,
  },
  {
    data: 'clicked', width: '12%',
    render: tickOrCross,
  },
  {
    data: 'unsubscribed', width: '12%',
    render: tickOrCross,
  },
]

const headers = [
  'Name',
  'Email',
  'Opened',
  'Clicked',
  'Opted out',
]

const filters = [
  {
    name: 'Opened', kind: 'radio', column: 2, options: [
      { value: '', label: 'All' },
      { value: true, label: 'Opened' },
      { value: false, label: "Didn't open" }
    ]
  },
  {
    name: 'Clicked', kind: 'radio', column: 3, options: [
      { value: '', label: 'All' },
      { value: true, label: 'Clicked' },
      { value: false, label: "Didn't click" }
    ]
  },
  {
    name: 'Opted out', kind: 'radio', column: 4, options: [
      { value: '', label: 'All' },
      { value: true, label: 'Opted out' },
      { value: false, label: "Didn't opt out" }
    ]
  },
]


const sorters = []

const order = []

export { columns, headers, filters, sorters, order }
