export function formatDate(dateString) {
  if (!dateString) return '';
  const date = new Date(dateString);
  const formattedDate = date.toLocaleDateString('default');
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');
  return `${formattedDate} - ${hours}:${minutes}`;
}

export function calculateRate(total, number) {
  if (parseInt(total) === 0 || parseInt(number) === 0) return 0
  const rate = Math.round((parseFloat(number) / parseFloat(total)) * 100)
  return rate < 1 ? '< 1' : rate
}

export function formatPercent(value, total) {
  const percentage = total === 0 || !total ? 0 : (((value || 0) / total) * 100).toFixed()
  return `${percentage}%`
}
