import Table from "Shared/components/DataTable/Table"
import restClient from 'Shared/hooks/restClient'
import AddSenderModal from "Shared/components/EmailSender/AddSenderModal"

import store from 'Shared/store'
import { Provider } from 'react-redux'

const ManageEmailSenders = ({}) => {
  const [addSenderModalOpen, setAddSenderModalOpen] = useState(false)
  const [editingEmailSenderId, setEditingEmailSenderId] = useState(null)

  const handleTableClick = (e) => {
    if(e.target.classList.contains('delete-email-sender-button')) {
      deleteEmailSender(e.target.dataset.id)
    }
    if(e.target.classList.contains('edit-email-sender-button')) {
      editEmailSender(e.target.dataset.id)
    }
  }

  const deleteEmailSender = (id) => {
    if (confirm('Are you sure?')) {
      restClient
        .delete(`/api/email_senders/${id}`)
        .then(reloadTable)
    }
  }

  const editEmailSender = (id) => {
    setEditingEmailSenderId(id)
    setAddSenderModalOpen(true)
  }

  const addEmailSender = () => {
    setEditingEmailSenderId(null)
    setAddSenderModalOpen(true)
  }

  const afterUpsertEmailSender = (id) => {
    setAddSenderModalOpen(false)
    reloadTable()
  }

  const tableProps = {
    table: 'emailSenders',
    scope: {},
    language: { emptyTable: 'There are no email senders yet' },
    onClick: handleTableClick,
  }

  const reloadTable = () => {
    window.$('table').DataTable().ajax.reload()
  }

  return (
    <Provider store={store}>
      <div className="header">
        <h1>Email Senders</h1>
        <div className="actions">
          <button className="button primary" onClick={addEmailSender}>Add Sender</button>
        </div>
      </div>
      <div className="section form">
        <div className="box">
          <Table {...tableProps} />
        </div>
        <AddSenderModal
          open={addSenderModalOpen}
          onCancel={() => setAddSenderModalOpen(false)}
          afterCreate={afterUpsertEmailSender}
          afterUpdate={afterUpsertEmailSender}
          emailSenderId={editingEmailSenderId}
        />
      </div>
    </Provider>
  )
}

export default ManageEmailSenders
