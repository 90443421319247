import PropTypes from 'prop-types'
import './Tab.scoped.scss'

function Tab({ label, value, onClick, selected, dropdown=false }) {

  const handleClick = (e) => {
    e.preventDefault()
    onClick(value)
  }

  return (
    <a href="#" className={`tab ${selected ? 'selected' : ''} ${dropdown ? 'dropdown' : ''}`} onClick={handleClick}>
      {label}
      {dropdown && <SvgIconsDownCaret className="icon" />}
    </a >
  )
}

Tab.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.number,
  onClick: PropTypes.func,
  selected: PropTypes.bool
}

export default Tab

