import { Backdrop } from '@material-ui/core'
import './BackdropModal.scoped.scss'

const FullWidthBackdropModal = ({
  open,
  title,
  onCancel,
  children,
  maxWidth = 600,
  width = 600,
  hideClose = false,
}) => {
  return (
    <div className={`modal-container ${open ? 'visible' : ''}`} style={{ zIndex: 1000 }}>
      <div className="modal" style={{ width: `${width}px`, maxWidth: `${maxWidth}px` }}>
        <div className='header'>
          <h1>{title}</h1>
          {!hideClose && (
            <button className="button naked" onClick={onCancel}>
              <SvgIconsClose className="no-margin" />
            </button>
          )}
        </div>

        {children}
      </div>
    </div>
  )
}

export default FullWidthBackdropModal
