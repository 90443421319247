import store from 'Shared/store'
import { Provider } from 'react-redux'
import PageContainer from 'Dashboard/components/PageContainer'

export default function Dashboard({ admin = false, manageAudiences = false }) {
  return (
    <Provider store={store}>
      <ErrorBoundary>
        <PageContainer admin={admin} manageAudiences={manageAudiences} />
      </ErrorBoundary>
    </Provider>
  )
}
