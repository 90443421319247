// CKEditor
import { CKEditor } from '@ckeditor/ckeditor5-react'
import {
    ClassicEditor,
    AccessibilityHelp,
    Alignment,
    AutoLink,
    Autosave,
    Bold,
    Essentials,
    FontFamily,
    FontSize,
    GeneralHtmlSupport,
    Italic,
    Link,
    List,
    Paragraph,
    SelectAll,
    Underline,
    Undo,
    Mention,
} from 'ckeditor5';
import { MergeFields } from 'ckeditor5-premium-features'
import 'ckeditor5/ckeditor5.css'
import 'ckeditor5-premium-features/ckeditor5-premium-features.css'
import './InlineCkEditor.scoped.scss'

// CKEditor plugins
import MergeTagsPlugin from './MergeTagsPlugin'

// merge tags
import Modal from 'Shared/components/Modal/Modal'
import MergeTagList from 'Shared/components/PillEditor/MergeTagList.jsx'
import CodeMirrorEditor from 'Shared/components/CodeMirrorEditor'

export default function InlineCkEditor({ mergeTags, value, onUpdate, sourceMode, setSourceMode }) {
    const [showMergeTagsModal, setShowMergeTagsModal] = useState(false)
    const [editorInstance, setEditorInstance] = useState(null)

    const onReady = (editor) => {
        setEditorInstance(editor)
    }

    const handleChange = (_event, editor) => {
        onUpdate(editor.getData())
    }

    const handleInsertTag = (tagText) => {
        if (editorInstance) {
            editorInstance.execute('insertMergeTag', tagText)
        }
    }

    return <div className='editor-container'>
        <div className='row'>
            <div className='tabs'>
                <div className={`tab ${sourceMode ? '' : 'selected'}`} onClick={() => setSourceMode(false)}>Visual</div>
                <div className={`tab ${sourceMode ? 'selected' : ''}`} onClick={() => setSourceMode(true)}>HTML</div>
            </div>
        </div>
        { sourceMode && <CodeMirrorEditor value={value} onChange={onUpdate} /> }
        { !sourceMode && <CKEditor
            editor={ClassicEditor}
            onChange={handleChange}
            onReady={onReady}
            data={value}
            config={{
                width: '100%',
                placeholder: 'Type some content here!',
                toolbar: {
                    items: [
                        'fontSize',
                        'fontFamily',
                        '|',
                        'bold',
                        'italic',
                        'underline',
                        '|',
                        'link',
                        '|',
                        'alignment',
                        '|',
                        'bulletedList',
                        'numberedList',
                        '|',
                        'mergeTags'
                    ],
                    shouldNotGroupWhenFull: false
                },
                plugins: [
                    AccessibilityHelp,
                    Alignment,
                    AutoLink,
                    Autosave,
                    Bold,
                    Essentials,
                    FontFamily,
                    FontSize,
                    GeneralHtmlSupport,
                    Italic,
                    Link,
                    List,
                    Paragraph,
                    SelectAll,
                    Underline,
                    Undo,
                    MergeFields,
                    Mention,
                    MergeTagsPlugin,
                ],
                fontFamily: {
                    options: [
                        'Arial, sans-serif',
                        'Helvetica, sans-serif',
                        'Optima, sans-serif',
                        'Futura, sans-serif'
                    ],
                },
                fontSize: {
                    options: [10, 12, 14, 'default', 18, 20, 22, 24, 28, 32],
                    supportAllValues: true
                },
                htmlSupport: {
                    allow: [
                        {
                            name: /^.*$/,
                            styles: true,
                            attributes: true,
                            classes: true
                        }
                    ],
                    disallow: [
                        {
                            name: /^(div|section|article|script)$/
                        }
                    ]
                },
                link: {
                    addTargetToExternalLinks: true,
                    defaultProtocol: 'https://'
                },
                licenseKey: window.ENV.CKEDITOR_KEY,
                mention: {},
                mergeTagsUI: {
                    handleVisibleModal: bool => setShowMergeTagsModal(bool),
                },
            }}
        /> }
        <Modal open={showMergeTagsModal} title="Merge Tags" onCancel={() => setShowMergeTagsModal(false)} autoWidth autoHeight>
            <MergeTagList
                mergeTags={mergeTags}
                mode={'slug'}
                insert={handleInsertTag}
                close={() => setShowMergeTagsModal(false)}
            />
        </Modal>
    </div>
}
