import { addWeeks, startOfDay, format } from 'date-fns'

// key: default
// in future this could return from endpoint, unifying frontend and backend defaults
const commonSendSettings = {
  sendMode: {
    label: 'Sending mode',
    kind: 'select',
    default: 'blast',
    options: [
      { value: 'blast', label: 'All at once' },
      { value: 'max_per_hour', label: 'Send an amount per hour' },
    ],
  },
  sendStartTime: {
    label: 'Send after',
    kind: 'time',
    default: '09:00',
    dependsOn: {
      sendMode: ['max_per_hour'],
    },
    required: true,
  },
  sendEndTime: {
    label: 'Send before',
    kind: 'time',
    default: '19:00',
    dependsOn: {
      sendMode: ['max_per_hour'],
    },
    required: true,
  },
  maxMessagesPerHour: {
    label: 'Max messages per hour',
    kind: 'number',
    default: 1000,
    dependsOn: {
      sendMode: ['max_per_hour'],
    },
    required: true,
  },
  testMode: {
    label: 'Test mode',
    kind: 'select',
    default: 'off',
    hidden: true,
    options: [
      { value: 'off', label: 'Off' },
      { value: 'ab', label: 'A/B Test' },
    ],
  },
  testSizePerVariant: {
    label: "Messages to send per version",
    kind: 'number',
    default: 1000,
    dependsOn: {
      testMode: ['ab'],
    },
    required: true
  }
}

const matchingMode = {
  label: 'Volunteer matching',
  kind: 'select',
  default: 'default',
  options: [
    { value: 'default', label: 'None' },
    { value: 'branch', label: 'Match by branch' },
    { value: 'any_descendent', label: `Any organisation below user's organisation` },
  ],
  description:
    'You can optionally choose to match volunteers to the people they are calling, based on their organisational unit.',
}

const userChoiceMode = {
  label: 'Volunteer targeting',
  kind: 'select',
  default: 'none',
  options: [
    { value: 'none', label: 'None' },
    { value: 'all', label: 'Any organisation' },
    { value: 'accessible', label: `Any organisation beneath their own` },
  ],
  description:
    'You can optionally choose to let volunteers select which organisational unit they would like to call.',
}

const settingsConfig = {
  sms_conversations: {
    matchingMode,
    userChoiceMode,
    ...commonSendSettings,
    maxUnrepliedMessages: {
      label: 'Max unreplied messages',
      kind: 'number',
      default: 100,
      dependsOn: {
        sendMode: ['max_unreplied'],
      },
      required: true,
    },
  },
  sms_blast: {
    ...commonSendSettings,
  },
  sms_survey: {
    ...commonSendSettings,
  },
  email: {
    ...commonSendSettings,
  },
  phone: {
    deadline: {
      label: 'Deadline',
      kind: 'date',
      default: format(addWeeks(startOfDay(new Date()), 2), 'yyyy-MM-dd'),
      required: false,
      description:
        'Set the date when this campaign will end. After this, volunteers will not be able to participate.',
    },
    callingHours: {
      label: 'Calling hours',
      kind: 'time_range',
      default: ['09:00', '20:00'],
      keys: ['linesOpen', 'linesClose'],
      required: true,
      description: 'The hours that calls can be made between.',
    },
    daysOpen: {
      label: 'Calling days',
      kind: 'multi_checkbox',
      options: [
        { value: 'daysOpenMonday', label: 'Monday', default: true },
        { value: 'daysOpenTuesday', label: 'Tuesday', default: true },
        { value: 'daysOpenWednesday', label: 'Wednesday', default: true },
        { value: 'daysOpenThursday', label: 'Thursday', default: true },
        { value: 'daysOpenFriday', label: 'Friday', default: true },
        { value: 'daysOpenSaturday', label: 'Saturday', default: true },
        { value: 'daysOpenSunday', label: 'Sunday', default: true },
      ],
      description: 'The days that calls can be made on.',
    },
    areaCode: {
      label: 'Area code',
      kind: 'text',
      default: '',
      required: false,
      description: 'Optionally, an area code which calls will be made from.',
    },
    recallAttempts: {
      label: 'Calling attempts',
      kind: 'select',
      default: '1',
      options: [
        { value: '1', label: '1' },
        { value: '2', label: '2' },
        { value: '3', label: '3' },
        { value: '4', label: '4' },
        { value: '5', label: '5' },
      ],
      description: 'The amount of times each person will be tried.',
    },
    callingModes: {
      label: 'Calling methods',
      kind: 'multi_checkbox',
      options: [
        { value: 'callingModeDesktopWeb', label: 'Browser', default: true },
        { value: 'callingModeDesktopPhone', label: 'Browser + Phone', default: true },
      ],
    },
    callingOrder: {
      label: 'Calling order',
      kind: 'select',
      default: 'random',
      options: [
        { value: 'random', label: 'Default' },
        { value: 'lock_step', label: 'Lock step' },
        { value: 'a-z', label: 'A-Z' },
        { value: 'z-a', label: 'Z-A' },
      ],
    },
    matchingMode,
    userChoiceMode,
    callRecording: {
      label: 'Call recording',
      kind: 'select',
      default: 'off',
      options: [
        { value: 'off', label: 'Off' },
        { value: 'on', label: 'On' },
      ],
    },
    callFeedback: {
      label: 'Call feedback',
      kind: 'select',
      default: 'on',
      options: [
        { value: 'off', label: "Don't ask volunteers for feedback on how calls go" },
        { value: 'on', label: 'Ask volunteers for feedback' },
      ],
    },
    callTimes: {
      label: 'Min/max call time',
      kind: 'duration_range',
      default: [15, 30 * 60],
      keys: ['minimumCallTime', 'maximumCallTime'],
      required: true,
      description: 'The minimum and maximum lengths of calls.',
    },
    supervisorIds: {
      label: 'Supervisors',
      kind: 'async_select',
      entity: 'Supervisor',
      default: '',
      required: false,
    },
    from: {
      kind: 'string',
      default: 'random',
    }
  },
}

if (window.ENV.FEATURES['target_details_updates']) {
  settingsConfig['sms_conversations']['targetDetailsUpdates'] = {
    label: 'Display target details updates link',
    kind: 'select',
    default: 'off',
    options: [
      { value: 'off', label: "Don't display" },
      { value: 'on', label: 'Display' },
    ],
  }
}

export default settingsConfig
