import { copyToClipboard } from 'Shared/functions/util'

export default function CopyToClipboard({ text, url }) {
  const [copied, setCopied] = useState(false)

  function handleClick() {
    copyToClipboard(url)
    setCopied(true)
    setTimeout(function() {
      setCopied(false)
    }, 1500)
  }

  return <>
    { !copied && <a className='blue-link' onClick={handleClick}>{text}</a> }
    { copied && <span className='bold blue underlined'>Copied</span> }
  </>
}
