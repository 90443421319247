import ServerWaitButton from 'Shared/components/ServerWaitButton'
import useAct from 'Workflows/hooks/useAct'
import { Rules } from 'SearchBuilder/components/Description/Rules'

export default function ResumeConfirm() {
  const act = useAct()
  const [launchErrors, setLaunchErrors] = useState([])
  const state = useSel(s => s.builder.workflow.state)
  const workflowNodes = useSel(s => _.values(s.workflowNodes.entities))
  const workflow = useSel(s => s.builder.workflow)
  const entryCount = useSel(s => s.local.entryCount)
  const entryCountLoaded = entryCount !== null
  const entryWorkflowFilterDescription = useSel(s => s.local.entryWorkflowFilterDescription)

  useEffect(() => {
    act.builder.countEntry()
    act.builder.describeEntryWorkflowFilter()
  }, [])

  function resume() {
    return act.builder.resume()
      .then(() => {
        setLaunchErrors([])
        act.local.closeModal()
       })
      .catch((error) => {
        const data = error.response.data
        setLaunchErrors(data.errors)
      })
  }

  const errors = (() => {
    const errors = [...launchErrors]

    if (workflow.entryWorkflowFilter.rules.length <= 1) {
      errors.push('You must set entry rules for the automation')
    }

    if (workflowNodes.length === 0) {
      errors.push('You must add at least one node to this automation.')
    }

    _.each(workflowNodes, node => {
      if (node.kind === 'delay' && !node.properties.delayInSeconds) {
        errors.push('You must set a delay for the delay node.')
      }
      if (node.kind === 'add_to_campaign' && !node.properties.campaignId) {
        errors.push('You must select a campaign for the add to campaign node.')
      }
      if (node.kind === 'decision') {
        node.properties.branches.forEach(branch => {
          if (!branch.rules || branch.rules.length === 1) {
            errors.push(`No rules added for "${branch.name}" branch in the decision node`)
          }
          if (!branch.workflowNodeId) {
            errors.push(`No follow-on node added for "${branch.name}" branch in the decision node`)
          }
        })
      }
    })

    return errors
  })()

  const verb = state === 'paused' ? 'Resume' : 'Launch'
  const buttonEnabled = errors.length === 0 && entryCountLoaded

  return <div>
    <div className='content'>
      <h3 className='text-center no-margin-top'>
        { entryCountLoaded ? <>{ entryCount } people will be added to this automation.</> : <>Estimated count loading...</> }
      </h3>

      { errors.length > 0 && <div className='margin-top warning'>
        <h4 className='text-center'>There are some problems with your automation. Please correct these and try again:</h4>
        <ul className='error bold'>
          { errors.map((error, i) => <li key={i}>{ error }</li>) }
        </ul>
      </div> }

      <div className='buttons center margin-top margin-bottom double'>
        <ServerWaitButton className='button primary margin-left margin-right' onClick={resume} disabled={!buttonEnabled}>{verb}</ServerWaitButton>
        <button className='button naked' onClick={act.local.closeModal}>Cancel</button>
      </div>

      { entryWorkflowFilterDescription && <Rules description={entryWorkflowFilterDescription} marginBottom center={false} /> }
    </div>
  </div>
}
