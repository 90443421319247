import AsyncSelect from 'Shared/components/AsyncSelect'
import MenuListWithFooter from 'Shared/components/MenuListWithFooter'
import Step from './Step'
import CostEstimator from './Audience/CostEstimator'
import AudienceShortDescriptor from 'CampaignBuilder/components/Steps/AudienceShortDescriptor'
import useCountTargets from './Audience/useCountTargets'

export default function Audience({ searchesNewUrl }) {
  const act = useAct()
  const {
    id,
    adminUrl,
    search,
    searchId,
    autoRefreshSearch,
    status,
    campaignType,
    textMessageBlast,
    requiredSubscription,
    subscriptionTopic
  } = useSel((s) => s.campaign)
  const kind = textMessageBlast?.kind === 'whatsapp' ? 'whatsapp' : campaignType
  const isConstitutional = useSel(
    (s) => s.campaign.emailBlast && s.campaign.emailBlast.isConstitutional
  )
  const { baseCost, contactCost } = useSel((s) => s.campaignBuilder)
  const availableTopics = window.ENV.AVAILABLE_SUBSCRIPTIONS[requiredSubscription] || []
  const topicName = availableTopics.find((topic) => topic.topic === subscriptionTopic)?.label
  const { name, searchCount, recalculating } = useSel((s) => s.search)
  const searchIsEditable = status === 'draft'
  const complete = searchId !== null && search !== null
  const newSearchPath = `${searchesNewUrl}?returning_url=${adminUrl}&linked_campaign_id=${id}`
  const isPhoneCampaign = kind === 'phone'
  const showEstimate = (searchCount || 0) > 0 && kind !== 'email'
  const targetCount = useCountTargets()

  function loadCount() {
    act.search.loadCount(searchId).then(({ data: { recalculating } }) => {
      if (recalculating) {
        setTimeout(() => {
          loadCount()
        }, 2500)
      } else {
        act.campaignBuilder.updateEstimates(searchId)
      }
    })
  }

  useEffect(() => {
    if (searchId) {
      loadCount()
    }
  }, [searchId])

  function handleSubscriptionTopicChange(e) {
    act.campaign.upsert({ subscriptionTopic: e.target.value })
  }

  function finish() {
    return act.campaign.persist().then(function () {
      act.campaignBuilder.updateEstimates(searchId)
      act.campaignBuilder.closeEditor()
    })
  }

  return (
    <Step
      name="To"
      editor="audience"
      editable={true}
      complete={complete}
      action={finish}
      shortDescriptor={
        <AudienceShortDescriptor
          targetCount={targetCount}
          complete={complete}
          kind={kind}
          search={search}
          count={searchCount}
          recalculating={recalculating}
          baseCost={baseCost}
          contactCost={contactCost}
          topicName={topicName}
          key={searchId}
        />
      }
    >
      <div className="fields">
        <div className="row">
          <div className="column two-thirds">
            <div className="field">
              <label>Audience</label>
              {searchIsEditable && (
                <AsyncSelect
                  entity="search"
                  value={searchId}
                  components={{ MenuList: MenuListWithFooter }}
                  redirectUrl={newSearchPath}
                  queryParameters={{
                    activeOnly: true,
                    excludeTemplates: true,
                  }}
                  onChange={(v) => act.campaign.upsert({ searchId: v })}
                />
              )}
              {!searchIsEditable && (
                <div>
                  <p className="bold">{name}</p>
                  <p className="dark-grey">
                    You can't change this audience because this campaign is live.
                  </p>
                </div>
              )}
            </div>
            {
                availableTopics.length > 1 && <div className="field">
                  <label>{ window.ENV.CLIENT_NAME} subscription category</label>
                  <select value={subscriptionTopic} onChange={handleSubscriptionTopicChange}>
                    {availableTopics.map((topic) => (
                      <option key={topic.topic} value={topic.topic}>
                        {topic.label}
                      </option>
                    ))}
                  </select>
                </div>
              }
            {showEstimate && (
              <CostEstimator
                baseCost={baseCost}
                contactCost={contactCost}
                searchCount={searchCount}
                kind={kind}
              />
            )}
            {kind === 'email' && (
              <div className="field margin-top half">
                <label className="align-center">
                  <input
                    type="checkbox"
                    name="is_constitutional"
                    value="true"
                    checked={isConstitutional}
                    onClick={(e) => {
                      act.campaign.upsertEmailBlast({
                        isConstitutional: e.target.checked,
                      })
                    }}
                  />
                  <p className="no-margin-top no-margin-bottom margin-left">
                    Send to everyone in this audience, even if they're unsubscribed
                  </p>
                </label>
                <p className="small">
                  If you need to contact all your members about constitutional issues.
                </p>
              </div>
            )}
            {kind === 'phone' && (
              <div className="field margin-top half">
                <label className="align-center">
                  <input
                    type="checkbox"
                    name="auto_refresh_search"
                    value="true"
                    checked={autoRefreshSearch}
                    onChange={(e) => {
                      act.campaign.upsert({ autoRefreshSearch: e.target.checked })
                    }}
                  />
                  <p className="no-margin-top no-margin-bottom margin-left">
                    Automatically refresh this audience every hour
                  </p>
                </label>
                <p className="small">
                  This will pull new members in who have joined the audience, and exclude
                  anyone who has left it.
                </p>
              </div>
            )}
          </div>
          <div className="column third">
            <div className="tip">
              <div className="icon">
                <SvgIconsTip />
              </div>
              {!isPhoneCampaign && (
                <p>
                  More specific audience generally tend to engage more, so try only
                  texting people most likely to find this text useful.
                </p>
              )}
              {isPhoneCampaign && (
                <p>
                  More specific audiences tend to engage better with calling campaigns.
                  This way you can ensure your script is tailored to their interests.
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
    </Step>
  )
}
