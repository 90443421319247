import Button from './Button'
import './ButtonWithDropdown.scoped.scss'

export default function ButtonWithDropdown({ children, menuItems, ...props }) {
  const [showMenu, setShowMenu] = useState(false)

  const menuRef = useRef(null)

  const handleClickMenuItem = ((item) => {
    console.log('handleClickMenuItem')
    window.setTimeout(() => setShowMenu(false), 0)
    item.action()
  })

  const renderMenu = () => {
    return <ul className="box">
      {menuItems.map((item, index) => {
        return <li key={index} className={item.disabled ? 'disabled' : null} onClick={() => handleClickMenuItem(item)}>
          <span>{item.label}</span>
          <SvgIconsCaretRight />
        </li>
      })}
    </ul>
  }

  useEffect(() => {
    const closeMenu = (event) => {
      // Close menu if the click was on an element outside the button
      // 'node.contains' or other DOM traversal methods don't work with svg
      if (!event.composedPath().includes(menuRef.current)) {
        setShowMenu(false)
      }
    }
    document.addEventListener('click', closeMenu)
    return () => document.removeEventListener('click', closeMenu)
  }, [menuRef])


  const toggleMenu = () => {
    console.log('toggleMenu', !showMenu)
    setShowMenu(!showMenu)
  }

  return (
    <div className="button-with-dropdown" ref={menuRef}>
      <Button {...props} onClick={toggleMenu}>
        {children}
        {showMenu ? <SvgIconsCaretUp /> : <SvgIconsCaretDown />}
      </Button>
      {showMenu && renderMenu()}
    </div>
  )
}

