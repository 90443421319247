import './Boxes.scoped.scss'

export default function Boxes({
  id = '',
  boxes,
  sizes = null,
  containerClass = '',
  unbox = false,
}) {
  function equalClassWidth(count) {
    switch (count) {
      case 3:
        return 'third'
      case 2:
        return 'half'
      case 1:
        return 'full-width'
    }
  }

  function getColumnClass(index) {
    return sizes && sizes.length >= index ? sizes[index] : equalClassWidth(boxes.length)
  }

  function getClass() {
    // if we're doing third layouts, then use three even if there's diff box sizes
    if (sizes && sizes.includes('third')) {
      return 'three'
    }

    switch (boxes.length) {
      case 3:
        return 'three'
      case 2:
        return 'two'
    }
  }

  return (
    <div id={id} className={`boxes ${getClass()} ${containerClass}`}>
      {boxes.map((content, index) => {
        return (
          <div className={`${unbox ? '' : 'box'} ${getColumnClass(index)}`} key={index}>
            {content}
          </div>
        )
      })}
    </div>
  )
}
