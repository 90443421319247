import DynamicPrice from 'Shared/components/DynamicPrice'
import calculateEstimate from './calculateEstimate'
import './CostEstimator.scoped.scss'

const UPTAKE_LABELS = {
  sms_survey: 'reply',
  phone: 'answer the phone',
}

export default function CostEstimator({ baseCost, contactCost, kind, searchCount }) {
  const showUptake = _.includes(['sms_survey', 'phone'], kind)
  const [uptake, setUptake] = useState(0.25)
  const hasCost = baseCost != null && contactCost != null
  const adjustedContactCost = showUptake ? contactCost * uptake : contactCost
  const totalCost = calculateEstimate(baseCost, adjustedContactCost, null)

  return (
    <div className="cost-estimator">
      {hasCost && (
        <>
          <p className="lead">
            Estimated cost: <DynamicPrice usdAmount={totalCost} />
          </p>

          {showUptake && (
            <div className="uptake">
              If
              <select
                className="inline"
                value={uptake}
                onChange={(e) => setUptake(e.target.value)}
              >
                <option value={0.25}>25% ({Math.round(searchCount * 0.25)})</option>
                <option value={0.5}>50% ({Math.round(searchCount * 0.5)})</option>
                <option value={0.75}>75% ({Math.round(searchCount * 0.75)})</option>
                <option value={1}>100% ({Math.round(searchCount * 1)})</option>
              </select>
              of people {UPTAKE_LABELS[kind]}
            </div>
          )}

          {kind === 'phone' && (
            <p className="smaller">
              This cost is calculated by taking a time estimate per call of 2 minutes, and
              a further 30 seconds for each question in your script. Your actual call
              lengths may be lower or higher than this estimate.
            </p>
          )}

          {kind === 'sms_survey' && (
            <p className="smaller">
              This estimate is calculated by estimating each step in the survey as 1
              segment. Your actual cost may be higher than this estimate if your text
              messages are longer.
            </p>
          )}
        </>
      )}
      {!hasCost && <span className="skeleton-box" style={{ width: '100px' }}></span>}
    </div>
  )
}
