import Overview from "./Overview"
import Tabs from "Shared/components/Tabs/DynamicTabs"
import Tab from "Shared/components/Tabs/Tab"
import TabPanel from "Shared/components/Tabs/TabPanel"
import Transactions from "./Transactions"
import UsageSummary from "./UsageSummary"
import useAct from 'ManageBilling/hooks/useAct'
import Loading from 'Shared/components/Loading/Loading'
import './Billing.scoped.scss'

export default function Billing() {
  const [tabIndex, setTabIndex] = useState(0)
  const [loading, setLoading] = useState(true)
  const act = useAct()

  useEffect(() => {
    act.billingData.load().then(() => setLoading(false))
  }, [])

  return (
    <>
      <h1>Billing Overview</h1>
      <Overview />

      {loading ? <Loading /> :
        <>
          <Tabs value={tabIndex} onChange={setTabIndex}>
            <Tab label="Transactions" />
            <Tab label="Usage Summary" />
          </Tabs>
          <div className="spacer" />
          <TabPanel value={tabIndex} index={0}>
            <Transactions />
          </TabPanel>
          <TabPanel value={tabIndex} index={1}>
            <UsageSummary />
          </TabPanel>
        </>
      }
    </>
  )
}

