import smartEncodingMap from "./smartEncodingMap";
import { count } from "sms-length";

export const replaceSmartEncodingChars = (str) =>
  Object.entries(smartEncodingMap).reduce((str, pair) => {
    const pattern = new RegExp('\\' + pair[0], 'g');
    return String(str).replace(pattern, pair[1]);
  }, str);

/*
 Wrap count function from sms-length package to first perform the same replacements that Twillio will perform
 (https://www.twilio.com/docs/messaging/services/smart-encoding-char-list) so we don't return an unecessarily
 short character count.
*/
export default (str) => count(replaceSmartEncodingChars(str));
