import Select from 'react-select'
import SpinbuttonField from "Shared/components/SpinbuttonField"
import AlignField from "../../AlignField"
import Divider from 'Shared/components/Divider'


const fonts = ['Helvetica', 'Optima', 'Futura']
const fontOptions = fonts.map((f) => ({ label: f, value: f }))


const QuoteBlockProperties = ({ block }) => {
  const act = useAct()

  const fontValue = fontOptions.find((o) => o.value === block.parameters.fontFamily)

  const handleFontFamilyChange = (option) => {
    act.email.blocks.updateParameters(block.id, {fontFamily: option.value})
  }

  const handleFontSizeChange = (fontSize) => {
    act.email.blocks.updateParameters(block.id, {fontSize})
  }

  const handleTextAlignChange = (textAlign) => {
    act.email.blocks.updateParameters(block.id, {textAlign})
  }

  return (
    <form>
      <h3 className='no-margin-top'>Edit quote</h3>

      <div className="field">
        <label>Font</label>
        <Select options={fontOptions} value={fontValue} onChange={handleFontFamilyChange} classNamePrefix="font-select" />
      </div>

      <Divider />

      <SpinbuttonField label="Font size" id="font-size-field" max="24" min="8" step="1" value={block.parameters.fontSize} onChange={handleFontSizeChange} />

      <Divider />

      <AlignField label="Text alignment" value={block.parameters.textAlign} onChange={handleTextAlignChange} id="text-alignment-field" />

    </form>
  )
}

export default QuoteBlockProperties
