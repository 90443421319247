import PropTypes from 'prop-types'
import { DEFAULTS, STEPS } from './constants'
import ColumnCheck from './ColumnCheck'
import FileSelect from './FileSelect'
import Heading from './Heading'
import Review from './Review'
import Upload from './Upload'
import Loading from 'Shared/components/Loading'

function getKind(type) {
  if (type === 'upload-people') {
    return 'members'
  }
  if (type === 'upload-list') {
    return 'list'
  }
  if (type === 'upload-custom') {
    return 'custom_fields'
  }
}

const CommaChameleon = ({ type, schema, upload, matchMode = false, labels }) => {
  const config = { ...DEFAULTS.labels, ...labels }
  const [display, setDisplay] = useState(STEPS[0].id)
  const [mergedSchema, setMergedSchema] = useState(schema)
  const [csv, setCsv] = useState()
  const [listName, setListName] = useState('')
  const [uploadSupporters, setUploadSupporters] = useState(false)
  const [loaded, setLoaded] = useState(false)
  const [confirmedColumns, setConfirmedColumns] = useState([])
  const kind = getKind(type)

  const resetState = () => {
    setDisplay(STEPS[0].id)
    setCsv()
  }

  useEffect(() => {
    resetState()
  }, [])

  useEffect(() => {
    if (matchMode) {
      setLoaded(true)
    }

    const getCustomFields = axios.get('/api/custom_fields?only_visible=true')
    const getSubscriptions = axios.get('/api/subscriptions/schema')

    Promise.all([getCustomFields, getSubscriptions]).then((responses) => {
      const customFields = responses[0].data.map((r) => ({
        name: r.name,
        required: false,
        type: r.type,
        regex: /.*/,
      }))
      const subscriptions = _.flatten(_.map(responses[1].data, (rows, kind) => {
        return rows.map((r) => ({
          name: `Subscription ${_.startCase(kind)} ${r.topic}`,
          required: false,
          type: r.type,
          regex: /^$|y|n/,
        }))
      }))
      setMergedSchema(schema.concat(customFields).concat(subscriptions))
      setLoaded(true)
    })
  }, [])

  const onCsvSelected = ({ type, ref }) => {
    setCsv({ type, ref })
    setDisplay('review')
  }

  const onCancel = (goto) => {
    setCsv()
    setDisplay(goto)
  }

  const onCsvValidated = () => {
    setDisplay('upload')
  }

  if (!loaded) {
    return <Loading />
  }

  return (
    <>
      <div className="cc-content">
        <Heading step={display} />
        <ColumnCheck
          step="check"
          type={type}
          show={display === 'check'}
          labels={config}
          columns={mergedSchema}
          confirmedColumns={confirmedColumns}
          setConfirmedColumns={setConfirmedColumns}
          onComplete={() => setDisplay('select')}
        />
        <FileSelect
          show={display === 'select'}
          step="select"
          onCancel={() => onCancel('check')}
          onSubmit={onCsvSelected}
          type={type}
          confirmedColumns={confirmedColumns}
        />
        <Review
          show={display === 'review'}
          type={type}
          step="review"
          csv={csv}
          schema={mergedSchema}
          onCancel={() => onCancel('select')}
          onValidated={onCsvValidated}
          listName={listName}
          setListName={setListName}
          needsList={matchMode}
          uploadSupporters={uploadSupporters}
          setUploadSupporters={setUploadSupporters}
          confirmedColumns={confirmedColumns}
        />
        <Upload
          show={display === 'upload'}
          step="upload"
          upload={upload}
          csv={csv}
          onCancel={() => onCancel('review')}
          listName={listName}
          kind={kind}
          uploadSupporters={uploadSupporters}
          confirmedColumns={confirmedColumns}
        />
      </div>
    </>
  )
}

CommaChameleon.propTypes = {
  type: PropTypes.string.isRequired,
  schema: PropTypes.arrayOf(PropTypes.object).isRequired,
  upload: PropTypes.object.isRequired,
}

export default CommaChameleon
