import { useStore } from 'react-redux'
import builder from 'Workflows/hooks/builder'
import overview from 'Workflows/hooks/overview'
import campaigns from 'Workflows/hooks/campaigns'
import local from 'Workflows/hooks/local'
import search from 'Shared/hooks/search'
import restClient from 'Shared/hooks/restClient'

const actionSlices = {
  local,
  builder,
  campaigns,
  overview,
  search
}

export default () => {
  const dis = useDis()
  const store = useStore()

  return useMemo(
    () => _.mapValues(actionSlices, (fn) => {
      return fn(dis, store, restClient)
    }),
    []
  )
}
