import './TargetInfoNew.scoped.scss'

const ICONS = {
  default: <SvgIconsPersonBlue />,
  sms: <SvgIconsSms width={34} height={34} />,
}

export default function TargetInfo({ icon = 'default', targetId, minimal = false }) {
  const act = useAct()
  const { id, name, keyInfo, headline } = useSel((s) => s.target || {})
  const dataLoaded = targetId && id === targetId && (keyInfo || []).length > 0

  useEffect(() => {
    if (targetId) {
      act.target.loadBasic(targetId)
    }
  }, [targetId])

  return (
    <div className="box">
      {minimal && <p className="no-margin-top"><strong>You are calling</strong></p>}
      <div className="stat large">
        <div className="icon medium">{ICONS[icon]}</div>
        <div className="">
          {dataLoaded ? (
            <h2>{name}</h2>
          ) : (
            <span className="skeleton-box" style={{ width: '200px' }}></span>
          )}
        </div>
      </div>

      {!minimal && dataLoaded && (
        <div className="key-info">
          {keyInfo.map((row, i) => {
            const isLink = row.value && row.value.startsWith('https://')
            return (
              <div key={i} className="item">
                <div className="label">{row.label}</div>
                <div className="value">
                  {isLink ? (
                    <a href={row.value} target="_blank" rel="noopener noreferrer">
                      {_.truncate(row.value, { length: 60, omission: '...' })}
                    </a>
                  ) : (
                    row.value || i18n.t('shared.messages.not_available')
                  )}
                </div>
              </div>
            )
          })}
        </div>
      )}
    </div>
  )
}
