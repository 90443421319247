import CircularProgress from '@material-ui/core/CircularProgress';
import Button from './Button'

export default ({ className, onClick, children, disabled, message = null, ...rest }) => {
  const act = useAct()
  const saving = useSel(s => s.local.saving)
  const [me, setMe] = useState(false)
  const timeoutRef = useRef(null)
  const savingRef = useRef(saving)

  function doAction() {
    if (savingRef.current) {
      timeoutRef.current = setTimeout(doAction, 100)
    } else {
      act.local.upsert({ saving: true })
      setMe(true)
      // the function onClick must return a promise.
      onClick().then(() => {
        act.local.upsert({ saving: false })
        setMe(false)
      }).catch(() => {
        act.local.upsert({ saving: false })
        setMe(false)
      })
    }
  }

  useEffect(() => {
    savingRef.current = saving
  }, [saving])

  // Ensure cleanup timeouts when we unmount
  useEffect(() => () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }
  }, [])

  return <>
    <Button extraClass={className} onClick={doAction} type='button' disabled={disabled || (me && saving)} {...rest}>
      {children} { me && saving && <CircularProgress size={20} /> }
    </Button>
    { me && saving && message && <div><p className='no-margin-bottom error'>{message}</p></div> }
  </>
}
