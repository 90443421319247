import './HtmlBlock.scoped.scss'
import './blocks/Block.scoped.scss'
import CodeMirrorEditor from 'Shared/components/CodeMirrorEditor'

const HtmlBlock = ({ htmlRendered, emailTemplateId, element = '', borderBottom = false, borderTop = false }) => {
  const act = useAct()
  const { mergeTags } = useSel((s) => s.campaignBuilder)
  const html = element === 'header' ? useSel((s) => s.emailBuilder.headerHtml) : useSel((s) => s.emailBuilder.footerHtml)
  const [rawHtml, setRawHtml] = useState(html)
  const [editing, setEditing] = useState(false)

  useEffect(() => {
    setRawHtml(html)
  }, [html])

  const handleSave = async () => {
    act.emailTemplate.upsert({
      ...(element === 'header'
        ? { headerHtml: rawHtml }
        : { footerHtml: rawHtml }
      )
    })
    await act.emailTemplate.persist()
    await act.emailTemplate.load(emailTemplateId)
    setEditing(false)
  }

  const handleCancel = () => {
    setRawHtml(html)
    setEditing(false)
  }

  const handleEditorChange = (value) => {
    setRawHtml(value)
  }

  return (
    <>
      {!editing ? (
        <div className={`wipe-css header-container html-block-container ${borderTop ? 'dashed-top-border' : ''} ${borderBottom ? 'dashed-bottom-border' : ''}`}>
          <div className={`foundation-container padding-bottom padding-top`}>
            <table className="container">
              <tbody>
                <tr>
                  <td dangerouslySetInnerHTML={{ __html: htmlRendered }} />
                </tr>
              </tbody>
            </table>
            {emailTemplateId && (
              <div className="edit-button-container no-reset">
                <button
                  onClick={() => setEditing(true)}
                  className="button blue small secondary ">
                  Edit {element}
                </button>
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className="header-container block-container active">
          <div className="editing-container">
            <CodeMirrorEditor
              value={rawHtml}
              onChange={handleEditorChange}
            />
            <div className="buttons-container">
              <button
                className="button primary small t-save"
                onClick={handleSave}
              >
                Save
              </button>
              <button
                className="button secondary small margin-left t-cancel"
                onClick={handleCancel}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default HtmlBlock
