import Type from './Type'
import SubType from './SubType'
import CampaignName from 'Shared/components/CampaignName'
import './Wizard.scoped.scss'

const Wizard = ({ searchId, cloneSearchId }) => {
  const { step } = useSel(s => s.createCampaign)
  const act = useAct()

  useEffect(() => {
    if (searchId) {
      act.createCampaign.setSearchId(searchId)
    }
    if (cloneSearchId) {
      act.createCampaign.setCloneSearchId(cloneSearchId)
    }
  }, [])

  return <div className='wizard'>
    <div className='step'>
      { step === 'type' && <Type /> }
      { step === 'sub_type' && <SubType /> }
      { step === 'name' && <CampaignName /> }
    </div>
  </div>
}

export default Wizard
