import useAct from 'Workflows/hooks/useAct'

export default function useNodeSize(id) {
  const ref = useRef(null);
  const act = useAct()

  useEffect(() => {
    const observer = new ResizeObserver(entries => {
      // This will fire each time the element size changes
      const { width, height } = entries[0].contentRect;
      act.local.setNodeSize(id, { width, height })
    });
    observer.observe(ref.current);

    return () => observer.disconnect();
  }, []); // Empty array means this effect runs once when the component mounts, and not on updates

  return ref
}

