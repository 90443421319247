import { renderToString } from 'react-dom/server'

const columns = [
  {
    "data": "name",
    "className": "bold",
    "render": (data, type, row, meta) => renderToString(
      <a href={`/admin/users/groups/${row['id']}`}>{data}</a>
    )
  }
]

const headers = [
    'Name',
]

const filters = [
]

const sorters = [
]

const order = [[ 0, "asc" ]]

export { columns, headers, filters, sorters, order }
