import './EditUserForm.scoped.scss'
import Button from 'Shared/components/Button'
import { CircularProgress } from '@material-ui/core'
import restClient from 'Shared/hooks/restClient'
import Flash from 'Shared/components/Flash/components/Flash'
import { Formik, Field, Form, ErrorMessage } from 'formik'
import FormikField from 'Shared/components/FormikField/FormikField'
import FormikSelect from 'Shared/components/FormikSelect/FormikSelect'
import OrganisationField from 'Shared/components/OrganisationField'

const statusOptions = [
  { value: 'approved', label: 'Approved' },
  { value: 'awaiting_approval', label: 'Awaiting approval' },
  { value: 'rejected', label: 'Rejected' },
]

function makeRoleOptions(roles, userRoleIds, roleIdsCanInvite) {
  console.log({ roles, userRoleIds, roleIdsCanInvite })
  // For roles that can't be changed, hide them unles the user has them already, in which case show fixed
  return roles
    .map((role) => {
      const option = { value: role.id, label: role.name }

      if (!_.includes(roleIdsCanInvite, role.id)) {
        if (_.includes(userRoleIds, role.id)) {
          option.isFixed = true
        } else {
          option.remove = true
        }
      }

      return option
    })
    .filter((o) => !o.remove)
}

// If phone number is blank and the user has any role besides "Volunteer"
// the user will need to add a phone number later
function willRequirePhoneNumber(roles, { phoneNumber, roleIds }) {
  const volunteerRole = roles.find((r) => r.name === 'Volunteer')
  return (
    volunteerRole &&
    (phoneNumber || '').trim() === '' &&
    roleIds.some((id) => id !== volunteerRole.id)
  )
}

const EditUserForm = ({ user, roles, inviteAcceptedAt }) => {
  const [flash, setFlash] = useState(null)
  const isGlobalAdmin = useSel((s) => s.currentUser.globalAdmin)
  const roleIdsCanInvite = useSel((s) => s.currentUser.roleIdsCanInvite)

  const handleSubmit = (values, formik) => {
    const user = values
    if (!user.roleIds) {
      user.roleIds = [] // Formik doesn't pass otherwise
    }

    restClient
      .put(`/api/users/${user.id}`, { user })
      .then((response) => {
        setFlash({ message: 'User updated successfully', type: 'success' })
        formik.setValues(response.data)
        formik.setSubmitting(false)
      })
      .catch((e) => {
        formik.setSubmitting(false)
        if (e.response.status === 422) {
          setFlash({ message: 'Please check the details you entered', type: 'error' })
          formik.setErrors(e.response.data)
        } else {
          setFlash({ message: 'Error updating user', type: 'error' })
          console.error(e)
        }
      })
  }

  const canChangeOrg =
    isGlobalAdmin || !_.includes(user.allPermissions, 'manage_client_admin')
  const roleOptions = makeRoleOptions(roles, user.roleIds, roleIdsCanInvite)

  return (
    <Formik initialValues={user} onSubmit={handleSubmit}>
      {({ values, isSubmitting, errors, setFieldValue }) => {
        return (
          <Form>
            {flash && (
              <Flash
                message={flash.message}
                type={flash.type}
                onHide={() => setFlash(null)}
              />
            )}

            <div className="EditUserForm details">
              <div className="detail">
                <div className="left">
                  <p>Name</p>
                </div>
                <div className="right field" id="name-field">
                  <label htmlFor="first-name">First</label>
                  <FormikField name="firstName" type="text" id="first-name" />
                  <label htmlFor="last-name">Last</label>
                  <FormikField name="lastName" type="text" id="last-name" />
                </div>
              </div>

              <OrganisationField
                value={values.organisationId}
                setFieldValue={setFieldValue}
                disabled={!canChangeOrg}
                allowBlank={true}
                disabledReason={
                  !canChangeOrg
                    ? "You can't move this user as they have an administrator role."
                    : null
                }
              />

              <div className="detail">
                <div className="left">
                  <p>Status</p>
                </div>
                <div className="right field">
                  <FormikSelect
                    name="status"
                    classNamePrefix="status-select"
                    options={statusOptions}
                  />
                </div>
              </div>

              <div className="detail">
                <div className="left">
                  <p>Email</p>
                </div>
                <div className="right">{user.email}</div>
              </div>

              <div className="detail">
                <div className="left">
                  <p>Phone number</p>
                </div>
                <div className="right field">
                  <FormikField name="phoneNumber" type="text" />
                </div>
              </div>

              <div className="detail">
                <div className="left">
                  <p>Roles</p>
                </div>
                <div className="right field">
                  <FormikSelect
                    name="roleIds"
                    classNamePrefix="roles-select"
                    options={roleOptions}
                    isMulti
                  />
                  {willRequirePhoneNumber(roles, values) && (
                    <p className="incomplete">
                      The roles this user has will mean they need to add a phone number
                      before they can access their account.
                    </p>
                  )}
                </div>
              </div>

              {inviteAcceptedAt && (
                <div className="detail">
                  <div className="left">
                    <p>Accepted invite at</p>
                  </div>
                  <div className="right field">{inviteAcceptedAt}</div>
                </div>
              )}
            </div>
            <div className="field save-changes margin-top">
              <Button type="submit" primary disabled={isSubmitting}>
                Save changes
              </Button>
              {isSubmitting && <CircularProgress />}
            </div>
          </Form>
        )
      }}
    </Formik>
  )
}

export default EditUserForm
