import EditableName from 'Shared/components/EditableName/EditableName'
import ManageActions from 'Shared/components/ManageMenu/ManageActionsWrapped'
import CreateCampaign from 'Campaigns/CreateCampaign/components/CreateCampaign'
import SearchStat from 'Searches/components/SearchStat'
import { Rules } from 'SearchBuilder/components/Description/Rules'
import AudienceActions from 'AudienceActions/components/AudienceActions'
import Table from 'Shared/components/DataTable/Table'
import { CircularProgress } from '@material-ui/core'

const PageContainer = ({ search, rulesToString }) => {
  const act = useAct()

  useEffect(() => {
    act.search.load(search.id)
  }, [])

  function numberWithDelimiter(number, delimiter = ',') {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, delimiter)
  }

  return (
    <div>
      <div className="header busy editable-name-container">
        <a className="back" href="/admin/audience"></a>
        <div className="title">
          <EditableName modelName="search" endpoint={`/api/searches/${search.id}`} />
          {search.last_run_at && (
            <div className="description">{`Last refreshed: ${new Date(
              search.last_run_at
            ).toLocaleString()}`}</div>
          )}
        </div>
        {search.template ? (
          <div className="actions">
            <a href={`/admin/searches/${search.id}/build`} className="button secondary">
              <SvgIconsEdit color="black" /> <span>Edit Template Audience</span>
            </a>
          </div>
        ) : (
          <div className="actions">
            <ManageActions
              target={{ id: search.id, status: search.status }}
              type="search"
            />
            {window.ENV.CURRENT_USER_PERMISSIONS.includes('create_campaigns') && (
              <CreateCampaign className="margin-left" searchId={search.id} />
            )}
          </div>
        )}
      </div>

      <div className="section">
        <div className="boxes two">
          <div className="box half-padding">
            <SearchStat searchId={search.id} />
          </div>
          <div
            className={`box half-padding ${search.status !== 'recalculating' ? 'align-vertically-center' : ''
              }`}
          >
            {[
              { verb: 'email', value: 'emailable', icon: <SvgIconsMail /> },
              { verb: 'text', value: 'textable', icon: <SvgIconsSms /> },
              { verb: 'call', value: 'callable', icon: <SvgIconsPhone /> },
              { verb: 'whatsapp', value: 'whatsappable', icon: <SvgIconsWhatsApp /> },
            ].map((stat, index) => (
              <div
                key={index}
                className={`stat smaller ${stat.verb !== 'whatsapp' ? 'border-bottom' : ''
                  }`}
              >
                <div className="icon">{stat.icon}</div>
                <div className="content">
                  <div className="number">
                    <span>
                      <span className="dark-grey bold">
                        {numberWithDelimiter(
                          search.stats[`${stat.value}_targets`]?.default || 0
                        )}
                      </span>{' '}
                      people you can {stat.verb}
                    </span>
                  </div>
                </div>
              </div>
            ))}
            {search.status === 'recalculating' && (
              <div className="margin-left half" style={{ display: 'flex' }}>
                <CircularProgress className="margin-top margin-right" size={25} />
                <p className="dark-grey bold no-margin-bottom">
                  This audience is currently being updated. Please check back shortly.
                </p>
              </div>
            )}
            {search.status === 'stale' && (
              <p className="dark-grey bold no-margin-bottom">
                This audience is stale, and the results have expired. You can refresh it
                from the menu above.
              </p>
            )}
          </div>
        </div>
      </div>

      <div className="section">
        <div className="box">
          <div className="content">
            <Rules description={rulesToString} />
          </div>
        </div>
      </div>

      <div className="section">
        <div className="box">
          <div className="header">
            <h2>People in this audience</h2>
            {search.status === 'live' && (
              <AudienceActions
                searchId={search.id}
                canExport={window.ENV.CURRENT_USER_PERMISSIONS.includes('export_data')}
                canImport={window.ENV.CURRENT_USER_PERMISSIONS.includes('import_data')}
              />
            )}
          </div>
          {search.status === 'live' ? (
            <Table
              table="targets"
              scope={{ search_id: search.id }}
              language={{ emptyTable: 'There are no targets yet.' }}
            />
          ) : search.status === 'recalculating' ? (
            <p>
              This search is currently updating. You'll be able to see who's in it
              shortly.
            </p>
          ) : search.status === 'stale' ? (
            <p>
              This search is stale, and the results have expired. You can refresh it from
              the menu above.
            </p>
          ) : search.status === 'error' ? (
            <p>
              This search has been set up incorrectly, and won't generate. Please edit the
              search and correct any issues.
            </p>
          ) : null}
        </div>
      </div>
    </div>
  )
}

export default PageContainer
