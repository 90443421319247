import useAct from 'Workflows/hooks/useAct'
import Editor from 'Workflows/components/Builder/Editor'
import FloatingMenu from  'Shared/components/FloatingMenu/FloatingMenu'
import Insights from  'Shared/components/Insights/Insights'
import Loading from 'Shared/components/Loading'
import Boxes from "Shared/components/Boxes"
import Stat from 'Shared/components/Stat'
import Modal from 'Shared/components/Modal/Modal'
import CopyToClipboard from 'Shared/components/CopyToClipboard'
import { parseISO, format } from 'date-fns'
import { ReactFlowProvider } from 'reactflow'
import { Rules } from 'SearchBuilder/components/Description/Rules'
import './Overview.scoped.scss'

const items = [
    { name: 'Progress', tag: 'progress' },
    { name: 'Summary', tag: 'summary' },
    { name: 'Preview', tag: 'preview' },
]

export default function Overview({ workflowId }) {
    const act = useAct()
    const { loaded, workflow } = useSel(s => s.builder)
    const { id, name, state, lastStartedAt } = useSel(s => s.builder.workflow)
    const entryWorkflowFilterDescription = useSel(s => s.local.entryWorkflowFilterDescription || [])
    const [showRules, setShowRules] = useState(false)
    const [exportPassword, setExportPassword] = useState(null)
    const editLink = `/admin/workflows/${id}/build`

    useEffect(() => {
      act.local.upsert({ workflowId })
      act.builder.load(workflowId)
    }, [])

    useEffect(() => {
        if (id) {
            act.builder.describeEntryWorkflowFilter()
        }
    }, [id])

    useEffect(() => {
      act.builder.countEntry()
      act.builder.describeEntryWorkflowFilter()
    }, [])

    function exportCsv() {
        act.overview.csvExport().then(setExportPassword)
    }

    return <div className='workflow-overview'>
        <Modal open={!!exportPassword} title="Export in progress" onCancel={() => setExportPassword(null)}>
            The campaign is exporting. You'll receive an email when it's done. 
            Use this password to open it: 
            &nbsp;<CopyToClipboard text={`${exportPassword} (click to copy)`} url={exportPassword} />
        </Modal>
        
        <div className='left'>
        <FloatingMenu items={items} />
        <Insights positive={ [] } negative={ [] } />
        </div>
        <div className='right'>
            <div className='box' id='progress'>
                <div className='header'>
                    { state === 'running' && <h1 className='no-margin-top'>Automation is running</h1> }
                    { state === 'paused' && <h1 className='no-margin-top'>Automation is paused</h1> }
                    { state === 'draft' && <h1 className='no-margin-top'>This automation is currently a draft</h1> }

                    <div className='actions'>
                        <a href={editLink} className="button light primary">
                            Edit
                        </a>
                    </div>
                </div>
                { lastStartedAt && <p><span className='bold'>Started at: </span> { format(parseISO(lastStartedAt), 'dd/MM/yyyy HH:mm') }</p> }
                { entryWorkflowFilterDescription.length > 1 && <>
                    <p>
                        <span className='bold'>Entry requirements: </span>
                        You've set up { entryWorkflowFilterDescription.length } entry requirements.&nbsp;
                        <a className='bold link' onClick={() => setShowRules(!showRules)}>{ showRules ? 'Hide rules' : 'Show rules' }</a>
                    </p>
                    { showRules && <Rules description={entryWorkflowFilterDescription} marginBottom center={false} /> }
                </> }

                <a onClick={() => exportCsv()} className="button primary margin-right">
                <SvgIconsDownload /> Email me the results
                </a>
            </div>
            
            <div className='stats' id='summary'>
                <Boxes boxes={[
                    <Stat
                        icon={ <SvgIconsAudience /> }
                        borderBottom={false}
                        size={'vertical'}
                        label={`In automation`}
                        primary={ workflow?.stats?.targetsCount || 0 }
                    />,
                    <Stat
                        icon={ <SvgIconsCallers /> }
                        borderBottom={false}
                        size={'vertical'}
                        label={`Completed automation`}
                        primary={ workflow?.stats?.completedCount || 0 }
                    />,
                    <Stat
                        icon={ <SvgIconsTrendingDown /> }
                        borderBottom={false}
                        size={'vertical'}
                        label={`Exited early`}
                        primary={ workflow?.stats?.exitedCount || 0 }
                    />
                ]} />
            </div>

            <div className='box' id='preview'>
                <h1 className='no-margin-top'>Automation preview</h1>

                { !loaded && <Loading text="Loading" /> }
                { loaded && <div className='preview-container'>
                    <ReactFlowProvider>
                        <Editor readOnly={true} />
                    </ReactFlowProvider>
                </div> }
            </div>
        </div>
    </div>
}