import useAct from 'Workflows/hooks/useAct'
import { NAMES, DESCRIPTIONS, ICONS } from 'Workflows/constants'
import './NodeList.scoped.scss'

function NodeType({ kind, onClick }) {
  return <div className='node-type' onClick={() => onClick(kind)}>
    <div className='icon'>
      { ICONS[kind] }
    </div>
    <h1 className='title'>
      { NAMES[kind] }
    </h1>
    <p className='description'>
      { DESCRIPTIONS[kind] }
    </p>
  </div>
}

function NodeList({ nodeId }) {
    const act = useAct()
    const afterNodeId = nodeId.split('_')[1]
    const afterBranchId = nodeId.split('_')[2] === 'branch' ? nodeId.split('_')[3] : null

    const canAddExit = useSel(s => {
      if (afterNodeId === 'start') {
        return s.builder.workflow.firstWorkflowNodeId === null
      } else {
        const afterNode = s.workflowNodes.entities[parseInt(afterNodeId)]
        if (afterNode.kind === 'decision') {
          if (afterBranchId === 'else') {
            return !afterNode.properties.elseWorkflowNodeId
          } else {
            return !afterNode.properties['branches'].find(b => b.branchId === afterBranchId).workflowNodeId
          }
        } else {
          return afterNode.nextWorkflowNodeId === null
        }
      }
    })

    function onClick(kind) {
      return act.builder.addNode({ kind, afterNodeId, afterBranchId })
        .then(() => act.builder.setOpenPlaceholderId(null))
    }

    return <div className='node-list'>
      <NodeType kind='add_to_campaign' onClick={onClick} />
      <NodeType kind='delay' onClick={onClick} />
      <NodeType kind='decision' onClick={onClick} />
      { canAddExit && <NodeType kind='exit' onClick={onClick} /> }
    </div>
}

export default NodeList
