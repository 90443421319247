import useData from 'DesktopMessager/useData'
import { format, parseISO } from 'date-fns'
import _ from 'lodash'

// Hook to pull in chunked conversationIds
/* Return [{
  date: 'Wed, Nov 25, 2020',
  messageIds: [123,456] (sorted in date order)
}, ...]
*/

export default function useChunks() {
  const conversationId = useData(rs => rs.user.currentConversationId)
  const textMessages = useSel(s => {
    return _.values(s.textMessages.entities).filter(tm => tm.conversationId === conversationId)
  })

  return dayChunks(textMessages)
}

const getDateString = dateStr => format(parseISO(dateStr), 'yyyy-MM-dd')

function dayChunks(messages) {
  const dates = _.orderBy(_.uniq(_.map(messages, m => getDateString(m.createdAt))))

  return _.map(dates, date => {
    const filteredMessages = messages.filter(m => getDateString(m.createdAt) === date)

    return {
      date,
      messageIds: _.orderBy(filteredMessages, 'id').map(m => m.id)
    }
  })
}