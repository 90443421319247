import React, { useReducer } from 'react';
import Context from 'CampaignVolunteer/Context'
import PageContainer from 'CampaignVolunteer/components/PageContainer'

const initialState = {
  stage: 'chooseOrganisation',
  organisations: [],
};

function reducer(state, action) {
  console.log({ action })
  if (action.type) {
    // custom actions go here
    return { ...state }
  } else {
    // shallow merge by default
    return { ...state, ...action }
  }
}

export default function CampaignVolunteer({ campaignId }) {
  const [state, dispatch] = useReducer(reducer, { ...initialState, campaignId });

  function chooseOrg(organisationId) {
    dispatch({ organisationId, stage: 'startCampaign' })
  }

  function reset() {
    dispatch({ organisationId: null, stage: 'chooseOrganisation' })
  }

  const actions = {
    chooseOrg,
    reset
  }

  return (
    <Context.Provider value={{ state, dispatch, actions }}>
      <PageContainer />
    </Context.Provider>
  );
};
