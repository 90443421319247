import './PreviewScript.scoped.scss'
import ScriptPreview from './Preview/ScriptPreview'
import Step from './Step'
import ScheduleButton from 'CampaignBuilder/components/EditSchedule/components/ScheduleButton'
import getDescription from 'CampaignBuilder/components/EditSchedule/getDescription'
import useValidateCampaign from 'CampaignBuilder/hooks/useValidateCampaign'

export default function PreviewSmsSurvey() {
  const act = useAct()
  const {
    id,
    searchId,
    status,
    approvalStatus,
    isWorkflowControlled,
    textMessageBlast: { scheduledFor },
  } = useSel((s) => s.campaign)
  const questions = useSel((s) => {
    return _.values(s.questions.entities).filter((q) => q.active)
  })
  const allPermissions = useSel(s => s.currentUser.allPermissions || [])
  const allowSchedule = !isWorkflowControlled && allPermissions.includes('manage_campaigns')
  const [complete, errors] = useValidateCampaign()
  const finishText = status === 'live' ? 'Save' : isWorkflowControlled ? 'Finish' : 'Send'

  function finish() {
    return act.campaign.persist().then(() => {
      if (!isWorkflowControlled) {
        act.sendBlast.open()
      } else {
        act.campaign.launch().then(({ data }) => {
          window.location = data.redirectUrl
        })
      }
    })
  }

  return (
    <Step
      name="Preview"
      editor="preview"
      shortDescriptor={
        <p className="incomplete no-margin-bottom">{getDescription(scheduledFor)}</p>
      }
      action={finish}
      staticOpenButton={'Preview'}
      canClickFinish={complete}
      finishButtonText={finishText}
      additionalButtons={allowSchedule && <ScheduleButton disabled={!complete} />}
      complete={!!scheduledFor}
    >
      <ScriptPreview />
    </Step>
  )
}
