
import './ProgressBar.scoped.scss'

export default function ProgressBar({ percent, kind = 'normal' }) {
    return (
      <div className="progress-bar">
        <div className={`progress-bar-inner ${kind === 'failed' ? 'failed' : ''}`} style={{ width: `${percent}%` }}></div>
      </div>
    )
}
