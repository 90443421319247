import classNames from 'classnames'
import PropTypes from 'prop-types'
import toTitleCase from '../functions/toTitleCase'
import RrIcon from './RrIcon'

/**
 *
 * @param title
 * @param type - null, 'reverse', 'cancel'
 * @param icon
 * @param onClick
 * @param muiProps
 * @returns {JSX.Element}
 * @constructor
 */
const RrButton = ({ title, type = '', icon, onClick, disabled, loading, style }) => {
  let startIcon
  if (icon) {
    switch (icon) {
      case 'record':
        startIcon = (
          <RrIcon iconName="record" classes={type === 'primary' ? 'white' : 'blue'} />
        )
        break
      case 'cancel':
        startIcon = (
          type === 'primary' ? <SvgIconsClearWhite className="svg" /> : <SvgIconsClear className="svg" />
        )
        break
      case 'upload':
        startIcon = (
          type === 'primary' ? <SvgIconsPublishWhite className="svg" /> : <SvgIconsPublish className="svg" />
        )
        break
      case 'back':
        startIcon = (
          <RrIcon iconName="back" classes={type === 'primary' ? 'white' : 'blue'} />
        )
        break
      case 'add':
        startIcon = <SvgIconsPlus className="svg" />
        break
    }
  }
  return (
    <a
      onClick={() => { disabled ? null : onClick() }}
      className={classNames('button', type, { disabled })}
      style={style}
    >
      {icon && !loading && startIcon}
      {loading ? (
        <img
          src={type === 'primary' ? SvgCircLoadingWhite : SvgCircLoading}
          className="loading"
        />
      ) : (
        toTitleCase(title)
      )}
    </a>
  )
}

RrButton.propTypes = {
  type: PropTypes.oneOf(['primary', 'cancel', '', 'secondary']),
}

export default RrButton
