import {
  createEntityAdapter,
  createSlice,
  configureStore,
  getDefaultMiddleware,
} from '@reduxjs/toolkit'

const middleware = [...getDefaultMiddleware()]
const adapter = createEntityAdapter()


const billingDataSlice = createSlice({
  name: 'billingData',
  initialState: {
    creditTopUps: [],
    creditUsages: [],
  },
  reducers: {
    set: (s, a) => a.payload,
    upsert: (s, a) => ({ ...s, ...a.payload }),
  },
})
const usageDataSlice = createSlice({
  name: 'usageData',
  initialState: {
    "textMessages": [],
    "whatsappMessages": [],
    "calls": [],
    "transferCalls": [],
    "emails": [],
    "costs": {},
  },
  reducers: {
    set: (s, a) => a.payload,
    upsert: (s, a) => ({ ...s, ...a.payload }),
  },
})

const store = configureStore({
  middleware,
  reducer: {
    billingData: billingDataSlice.reducer,
    usageData: usageDataSlice.reducer,
  },
})

export default store
