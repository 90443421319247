import FullWidthBackdropModal from '../../../Shared/components/BackdropModal/FullWidthBackdropModal'
import useAct from 'Interface/Tutorial/hooks/useAct'

const Modal = ({ tutorial }) => {
  if (!tutorial) return null
  const [isOpen, setIsOpen] = useState(false)
  const [currentStep, setCurrentStep] = useState(0)

  const act = useAct()
  const canClose = currentStep === 0 || currentStep === tutorial.steps.length - 1
  const canNext = tutorial.steps.length > 1 && currentStep < tutorial.steps.length - 1
  const canPrev = currentStep > 0
  const steps = tutorial.steps || []

  useEffect(() => {
    const handleShowTutorial = () => {
      setIsOpen(true)
    };

    window.addEventListener('showTutorial', handleShowTutorial)

    return () => {
      window.removeEventListener('showTutorial', handleShowTutorial)
    }
  }, [])

  useEffect(() => {
    if (tutorial && tutorial.user_tutorial_exists === false) {
      act.tutorial.markComplete(tutorial.id)
      setIsOpen(true)
    }
  }, [tutorial])

  const close = () => {
    setIsOpen(false)
  }

  function next() {
    setCurrentStep(currentStep + 1)
  }

  function prev() {
    setCurrentStep(currentStep - 1)
  }

  return (
    <FullWidthBackdropModal
      title={tutorial?.name}
      maxWidth={600}
      open={isOpen}
      onCancel={close}
    >
      {(steps[currentStep].video || steps[currentStep].image) && (
        <div className="media">
          {steps[currentStep].video && (
            <div className="video">
              <iframe
                style={{ width: '100%', height: '300px' }}
                src={steps[currentStep].video}
                frameborder="0"
                webkitallowfullscreen
                mozallowfullscreen
                allowfullscreen
              ></iframe>
            </div>
          )}
          {steps[currentStep].image && (
            <div className="image">
              <img src={steps[currentStep].image} />
            </div>
          )}
        </div>
      )}
      <div className="modal-content align-horizontally-center">
        <div className="step align-vertically-center margin-top margin-bottom">
          <p className="dark-grey small no-margin-bottom">
            {' '}
            {currentStep + 1} / {steps.length}
          </p>
          <h3>{steps[currentStep].title}</h3>
          <p className="dark-grey">{steps[currentStep].text}</p>
        </div>
      </div>

      <div className="actions align-horizontally-center margin-bottom double">
        {canPrev && (
          <button className="button secondary margin-right" onClick={() => prev()}>
            Back
          </button>
        )}
        {canClose && (
          <button className="button secondary margin-right" onClick={() => close()}>
            Close
          </button>
        )}
        {canNext && (
          <button className="button primary" onClick={() => next()}>
            Next
          </button>
        )}
      </div>
    </FullWidthBackdropModal>
  )
}

export default Modal
