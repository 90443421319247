import * as React from 'react'

function SvgPhone({ width = 64, height = 64, ...props }) {
  return (
    <svg width={width} height={height} viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.88 39.424l5.877-5.877a5.333 5.333 0 00.999-6.157l-.333-.666a5.334 5.334 0 01.999-6.156l7.287-7.288a1.333 1.333 0 012.135.347l2.944 5.888a11.7 11.7 0 01-2.191 13.505L36.6 49.017a14.472 14.472 0 01-14.81 3.496l-6.438-2.146a1.333 1.333 0 01-.522-2.208l7.07-7.07a5.333 5.333 0 016.157-.999l.666.333a5.333 5.333 0 006.156-.999z"
        fill="currentColor"
      />
      <path
        opacity={0.3}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M38.169 10.703l.493-5.31a29.273 29.273 0 00-23.47 8.465 29.272 29.272 0 00-8.469 23.436l5.311-.488a23.94 23.94 0 016.929-19.177 23.941 23.941 0 0119.206-6.926zm-.95 10.625l.51-5.31A18.63 18.63 0 0022.734 21.4a18.63 18.63 0 00-5.386 14.942l5.31-.495a13.298 13.298 0 013.847-10.676 13.298 13.298 0 0110.713-3.843z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgPhone
