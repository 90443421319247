import Button from 'Shared/components/Button'
import useAct from 'Workflows/hooks/useAct'
import Rules from 'SearchBuilder/components/Rules'
import ServerWaitButton from 'Shared/components/ServerWaitButton'
import validateRule from 'SearchBuilder/validateRule'

export default function EditRules({ kind, nodeId, branchId }) {
  const act = useAct()
  const isValid = useSel(s => _.every(_.values(s.rules.entities), r => validateRule(r, s.availableFilters)))

  function persist() {
    if (kind === 'entry') return act.builder.updateEntryWorkflowFilter()
    if (kind === 'exit') return act.builder.updateExitWorkflowFilter()
    if (kind === 'branch') return act.builder.updateBranchRules({ nodeId, branchId })
  }

  return <div>
    <Rules hideHelp={true} />

    <div>
      <ServerWaitButton className='button primary margin-right' disabled={!isValid} onClick={persist}>Save</ServerWaitButton>
      <Button red onClick={() => act.local.closeModal()}>Cancel</Button>
    </div>
  </div>
}
