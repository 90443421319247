import useData from 'DesktopCaller/useData'
import LabeledCard from 'Shared/components/LabeledCard/LabeledCard'

export default function InboundCall() {
  const script = useSel(s => s.local.currentScript, _.isEqual)
  const introQuestionId = useSel(s => _.values(s.questions.entities).filter(q => q.questionType === 'intro')[0]?.id)
  const { callStage, inputStage } = useData(rs => rs.callSession)
  const targetName = useData(rs => rs.target.firstName)

  return (
    <LabeledCard
      label={
        <>
          <SvgIconsCallerGreeting />
          <p>{i18n.t('user.campaigns.phone.instructions')}</p>
        </>
      }
    >
      {callStage === 'connected_to_target' && <p className='margin-bottom'>
        <strong>You're speaking to {targetName}, who dialled in to the campaign.</strong>
      </p>}
      {callStage === 'connected_to_target' && <p>
        {script?.questions[parseInt(introQuestionId)]?.question}
      </p>}
      {callStage === 'finished_call' && <p>
        The call has ended.
      </p>}
    </LabeledCard>
  )
}
