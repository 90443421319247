const emailBuilder = (dis, store, client) => {
  function upsert(payload) {
    return dis({ type: 'emailBuilder/upsert', payload })
  }

  function setActiveBlockId(id) {
    return dis({ type: 'emailBuilder/setActiveBlockId', payload: id })
  }

  function loadBlockTemplates() {
    return client.get(`/api/block_templates`)
      .then(({ data }) => {
        return dis({ type: 'emailBuilder/setBlockTemplates', payload: data })
      })
  }

  return { upsert, setActiveBlockId, loadBlockTemplates }
}

export default emailBuilder
