import Grid from '@material-ui/core/Grid'
import Collapse from '@material-ui/core/Collapse'
import Fade from '@material-ui/core/Fade'
import Input from '@material-ui/core/Input'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import InputAdornment from '@material-ui/core/InputAdornment'
import GoogleSheetIcon from './GoogleSheetIcon'
import { getFileFromGoogleSheet, matchGoogleUrl, nextStep, prevStep } from './helpers'
import RrButton from '../RrButton'
import { DEFAULTS } from './constants'
import RrIconButton from '../RrIconButton'
import RrIcon from '../RrIcon'
import { makeStyles } from '@material-ui/core/styles'
import { func } from 'prop-types'

const useStyles = makeStyles({
  root: {
    '&-label': {
      '&-shrink': {
        transform: 'translate(5px, -6px) scale(0.75) !important',
      },
    },
    '&:focus': {
      backgroundColor: 'rgba(41, 73, 213, 0.04) !important',
    },
  },
  input: {
    height: '40px !important',
    padding: '0 5px !important',
  },
})

const FileSelect = ({ show, step, paragraph, onSubmit, onCancel, type, confirmedColumns }) => {
  const [localFile, setLocalFile] = useState()
  const [googleUrl, setGoogleUrl] = useState('')
  const [errors, setErrors] = useState({})
  const [firstMount, setFirstMount] = useState(false)
  const [downloading, setDownloading] = useState(false)
  const [textareaContent, setTextareaContent] = useState('')
  const [triggerReload, setTriggerReload] = useState(false)
  const fileInputRef = useRef()
  const actions = useAct()
  const classes = useStyles()

  useEffect(() => {
    if (show && !firstMount) {
      setFirstMount(true)
    } else {
      setGoogleUrl('')
      setLocalFile()
      fileInputRef.current.value = null
    }
  }, [show])

  useEffect(() => {
    // TODO: add event listener to detect if user goes to different tab/window to copy Google Sheets url
    //  and returns - then grab URL from clipboard
  }, [])

  const onFileSelected = (evt) => {
    if (evt.target.value) setLocalFile(fileInputRef.current.files[0])
  }

  const checkGoogleUrl = (url, noError) => {
    if (url) {
      const matched = matchGoogleUrl({ url })
      if (!matched && !noError) setErrors({ google: true })
      setGoogleUrl(matched)
      return matched
    } else {
      setGoogleUrl('')
    }
  }

  const onUpdateGoogleUrlInput = (evt) => {
    setErrors({})
    checkGoogleUrl(evt.target.value)
  }

  const onClearFile = () => {
    setLocalFile()
    fileInputRef.current.value = null
  }

  const onClickGetData = async () => {
    if (googleUrl) {
      // Download file here
      setDownloading(true)
      const googleCsv = await getFileFromGoogleSheet(googleUrl, (message) => {
        actions.local.upsert({
          alert: { message, severity: 'error' },
        })
      })
      if (googleCsv) {
        setLocalFile(googleCsv)
        onComplete(googleCsv)
      }
      setDownloading(false)
    } else onComplete()
  }

  const onComplete = (override) => {
    onSubmit({ type: 'file', ref: override || localFile })
  }

  const convertToCsv = (content) => {
    const header = confirmedColumns[0]
    const items = content.split(/[\s,\n]+/).map(item => item.trim()).filter(item => item !== '')
    const csvContent = [header, ...items].join('\n')
    return new Blob([csvContent], { type: 'text/csv' })
  }

  const attachTextareaContentToFileInput = () => {
    if (textareaContent) {
      const csvBlob = convertToCsv(textareaContent)
      const csvFile = new File([csvBlob], 'pasted_content.csv', { type: 'text/csv' })
      setLocalFile(csvFile)

      const dataTransfer = new DataTransfer()
      dataTransfer.items.add(csvFile)
      fileInputRef.current.files = dataTransfer.files
    }
  }

  useEffect(() => {
    if (triggerReload) {
      onClickGetData()
      setTriggerReload(false)
    }
  }, [triggerReload])

  const handleTextareaChange = (event) => {
    setTextareaContent(event.target.value)
  }

  function getContentMessage() {
    if (confirmedColumns[0] === 'Membership Number') {
      return '00001,00002,00003,00004'
    } else if (confirmedColumns[0] === 'Phone Number') {
      return '+447000000001,+447000000002'
    } else { // Email
      return 'johnsmith@email.com,lucyjones@email.com'
    }
  }

  return (
    <Collapse in={show} className="cc-content-container">
      <Grid item container direction="column" spacing={2}>
        <Grid item>
          <p>{paragraph || DEFAULTS.paragraphs.select}</p>
        </Grid>
        <Grid item container>
          <h3>Upload a CSV from your computer</h3>
          <Grid item container direction="row">
            <input type="file" hidden ref={fileInputRef} onChange={onFileSelected} />
            <Grid item>
              <RrButton
                title="select a file"
                icon="upload"
                type="secondary"
                disabled={!!googleUrl}
                onClick={() => fileInputRef.current.click()}
              />
            </Grid>
            <Grid item style={{ flex: 1, paddingLeft: 10 }}>
              {localFile && (
                <Input
                  fullWidth
                  value={localFile?.name}
                  type="mui-input"
                  classes={classes}
                  endAdornment={
                    <InputAdornment position="end">
                      <RrIconButton onClick={onClearFile}>
                        <RrIcon iconName="close" />
                      </RrIconButton>
                    </InputAdornment>
                  }
                />
              )}
            </Grid>
          </Grid>
        </Grid>
        { type === 'upload-list' &&
          <Grid item container>
            <div>
              <h3>Or enter CSV content directly</h3>
              <p>You can enter each {confirmedColumns[0]?.toLowerCase()} separated by a space, comma, or line break.</p>
            </div>
            <textarea
              className="input-content"
              value={textareaContent}
              onChange={handleTextareaChange}
              placeholder={`Enter CSV content here (e.g., ${getContentMessage()})`}
              rows={5}
              style={{ width: '100%', marginBottom: '10px' }}
            />
          </Grid>
        }
        <Grid
          item
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            paddingTop: 25,
          }}
        >
          <RrButton
            title={prevStep(step)}
            onClick={onCancel}
            type="secondary"
            icon="back"
            disabled={downloading}
          />
          <RrButton
            title={nextStep(step)}
            type="primary"
            onClick={() => {
              if (textareaContent) {
                attachTextareaContentToFileInput()
                setTriggerReload(true)
              } else {
                onClickGetData()
              }
            }}            
            disabled={((!localFile && !googleUrl) && !textareaContent) || downloading}
            loading={downloading}
          />
        </Grid>
      </Grid>
    </Collapse>
  )
}

export default FileSelect
