import { Popover } from '@varld/popover'
import HelpPopover from './HelpPopover'

import './Help.scoped.scss'

const Help = ({ userMode }) => {
  return (
    <div className={userMode ? 'need-help-user' : 'need-help'}>
      <Popover popover={HelpPopover}>
        <div>
          <span>{userMode ? '?' : 'Need help?'}</span>
        </div>
      </Popover>
    </div>
  )
}

export default Help
