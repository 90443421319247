import { DirectUploadProvider } from 'react-activestorage-provider'
import MediaPreview from 'Shared/components/MediaPreview'
import './FileUploader.scoped.scss'

export default function FileUploader({ onUploaded, existingFilename, existingUrl, enablePreview = false }) {

  const [filename, setFilename] = useState([])

  const fileInputRef = useRef(null)

  const [draggingOverTarget, setDraggingOverTarget] = useState(false)

  const handleClick = () => {
    fileInputRef.current.click()
  }

  const uploadedFilename = existingFilename || (existingUrl && existingUrl.split('/').pop())

  const handleDragEnter = (e) => {
    e.preventDefault()
    e.stopPropagation()
    setDraggingOverTarget(true)
  }

  const handleDragLeave = (e) => {
    e.preventDefault()
    e.stopPropagation()
    setDraggingOverTarget(false)
  }

  return <DirectUploadProvider
    onSuccess={(ids) => onUploaded(ids, filename)}
    render={({ handleUpload, uploads, ready }) => {

      const handleDrop = (e) => {
        setFilename(e.dataTransfer.files[0].name)
        e.preventDefault()
        e.stopPropagation()
        setDraggingOverTarget(false)
        handleUpload(e.dataTransfer.files)
      }

      return (
        <div className="image-uploader">
          <div className={draggingOverTarget ? 'drag-target active' : 'drag-target'}
            onClick={handleClick}
            onDrop={handleDrop}
            onDragOver={handleDragEnter}
            onDragEnter={handleDragEnter}
            onDragLeave={handleDragLeave}>
            <SvgIconsUpload />
            <span>Drag and drop or click to upload</span>
          </div>
          <input
            type="file"
            ref={fileInputRef}
            disabled={!ready}
            onChange={e => {
              setFilename(e.currentTarget.files[0].name)
              handleUpload(e.currentTarget.files)
            }
            }
          />

          {uploadedFilename &&
            <p className="no-margin-top">Existing file: {uploadedFilename}</p>
          }

          {enablePreview && existingUrl &&
            <MediaPreview url={existingUrl} />
          }

          {uploads.map(upload => {
            switch (upload.state) {
              case 'waiting':
                return <p key={upload.id}>Waiting to upload {upload.file.name}</p>
              case 'uploading':
                return (
                  <p key={upload.id}>
                    Uploading {upload.file.name}: {upload.progress}%
                  </p>
                )
              case 'error':
                return (
                  <p key={upload.id}>
                    Error uploading {upload.file.name}: {upload.error}
                  </p>
                )
              case 'finished':
                return (
                  <p key={upload.id}>Finished uploading {upload.file.name}</p>
                )
            }
          })}
        </div>
      )
    }}
  />
}

