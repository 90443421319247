import * as React from 'react'

function SvgUserBlue(props) {
  return (
    <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M12 11a4 4 0 110-8 4 4 0 010 8zM3 20.2c.388-4.773 4.262-7.2 8.983-7.2 4.788 0 8.722 2.293 9.015 7.2.012.195 0 .8-.751.8H3.727c-.25 0-.747-.54-.726-.8z"
        fill="#2849D6"
      />
    </svg>
  )
}

export default SvgUserBlue
