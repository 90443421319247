import { PieChart, Pie, Sector, Cell, ResponsiveContainer, Legend } from 'recharts'
import ProgressBar from '../ProgressBar'

const COLORS = ['#00D586', '#00B9D2', '#26264D99']

export default function CallsMade({ completed, remaining, recyclable, spokenToToday, callsToday, callsByAttempt }) {
    const pieData = [
        { name: 'Contacted', value: completed },
        { name: 'Not yet reached', value: recyclable },
        { name: 'Yet to be called', value: remaining }
    ]

    const contactedTodayPercent = callsToday > 0 ? (parseFloat(spokenToToday) / parseFloat(callsToday)) * 100 : 0
    const totalPeople = Object.values(callsByAttempt).reduce((accumulator, value) => {
        return accumulator + value;
    }, 0)

    return <div>
        <h3 className='no-margin-top'>At a glance</h3>
        <p>There are <span className='bold'>{ remaining }</span> more calls that can be made today.</p>

        <p className='bold'>Today</p>
        { callsToday > 0 && <>
            <p className='smaller dark-grey'>
                <span className='bold smaller dark-grey'>{ callsToday }</span> calls have been made today.
            </p>
            <p className='smaller green'>
                Out of those, your callers spoke to <span className='bold smaller green'>{ spokenToToday }</span> people ({ Math.round(contactedTodayPercent) }%).
            </p>
            <ProgressBar percent={contactedTodayPercent} />
        </> }
        { callsToday === 0 && <p className='smaller dark-grey'>No calls have been made today.</p> }

        <p className='bold'>Overall</p>
        <p className='smaller dark-grey'>Out of the { totalPeople } people in this campaign:</p>

        <ResponsiveContainer width="100%" height={180}>
            <PieChart width={400} height={150}>
                <Legend />
                <Pie
                    dataKey="value"
                    startAngle={180}
                    endAngle={0}
                    data={pieData}
                    cx="50%"
                    cy="75%"
                    outerRadius={80}
                    fill="#8884d8"
                    label
                >
                    { pieData.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                    ))}
                </Pie>
            </PieChart>
        </ResponsiveContainer>
    </div>
}

// At a glance
// Remaining calls today: 1,000
// Today:
// [stacked chart with 2 segments: contacted / not contacted]

// Overall:
// Out of the 8,000 people in this campaign:
// [stacked chart showing total contacted/total not contactable after x attempts/total not tried]
