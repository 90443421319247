import * as React from 'react'

function SvgQuestion(props) {
  return (
    <svg width={24} height={24} viewBox={"0 0 24 24"} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        opacity={0.3}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.59 13.209v1.659h1.26c2.542 0 4.537-1.953 4.537-4.473 0-2.52-1.995-4.473-4.536-4.473-2.541 0-4.515 1.953-4.515 4.473H9.12c0-1.596 1.113-2.814 2.73-2.814 1.596 0 2.75 1.218 2.75 2.814s-1.154 2.814-2.75 2.814h-1.26zM12 16a1 1 0 100 2 1 1 0 000-2z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgQuestion
