export default function createActions(dis, store, restClient) {
  function upsert(payload) {
    dis({ type: 'local/upsert', payload })
  }

  function setSidebar(sidebar) {
    upsert({ sidebar })
  }

  function closeSidebar() {
    upsert({ sidebar: {} })
  }

  function editEntryRules() {
    const rules = store.getState().builder.workflow.entryWorkflowFilter.rules

    dis({ type: 'rules/setAll', payload: rules })
    openModal({ kind: 'entryRules' })
  }

  function editExitRules() {
    const rules = store.getState().builder.workflow.exitWorkflowFilter.rules

    dis({ type: 'rules/setAll', payload: rules })
    openModal({ kind: 'exitRules' })
  }

  function editBranchRules({ nodeId, branchId }) {
    const rules = store.getState().workflowNodes.entities[nodeId].properties.branches.find(b => b.branchId === branchId).rules

    dis({ type: 'rules/setAll', payload: rules })
    openModal({ kind: 'branchRules', nodeId, branchId })
  }

  function openModal(modal) {
    upsert({ modal })
  }

  function closeModal() {
    upsert({ modal: {} })
  }

  function setNodeSize(id, size) {
    dis({ type: 'local/setNodeSize', payload: { id, size } })
  }

  return { upsert, setSidebar, closeSidebar, editEntryRules, editExitRules, editBranchRules, openModal, closeModal, setNodeSize }
}
