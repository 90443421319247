import BackdropModal from 'Shared/components/BackdropModal/BackdropModal'
import CreditCard from './TopUpModal/CreditCard'
import Invoice from './TopUpModal/Invoice'
import IconBox from 'Shared/components/IconBox'

export default function TopUpModal({ open = false, onCancel }) {
  const [method, setMethod] = useState(null)

  return <BackdropModal title="Top up" onCancel={onCancel} open={open}>
    <div className="section">
      { !method && <ChooseMethod setMethod={setMethod} /> }
      { method === 'creditCard' && <CreditCard /> }
      { method === 'invoice' && <Invoice onSuccess={onCancel} /> }
    </div>
  </BackdropModal>
}

function ChooseMethod({ setMethod }) {
  return <div className="row">
    <div className='column half'>
      <a
        className={`card large selectable`}
        onClick={() => setMethod('creditCard')}
        >

        <IconBox size={100}>
          <SvgIconsMoney />
        </IconBox>

        <p className="title">Credit Card</p>
        <p className="description">
          Pay with credit card
        </p>
      </a>
    </div>
    <div className='column half'>
      <a
        className={`card large selectable`}
        onClick={() => setMethod('invoice')}
        >
        <IconBox size={100}>
          <SvgIconsMail />
        </IconBox>

        <p className="title">Invoice</p>
        <p className="description">
          Issue an invoice and pay by bank transfer
        </p>
      </a>
    </div>
  </div>
}
