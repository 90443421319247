import * as React from 'react'

function SvgTrash(props) {
  return (
    <svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M5 6.667v10.166a1.5 1.5 0 001.5 1.5h7a1.5 1.5 0 001.5-1.5V6.667H5z"
        fill="#8792A2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.667 3.75V3.5a1 1 0 00-1-1H9.334a1 1 0 00-1 1v.25H4.667a.5.5 0 00-.5.5v.25a.5.5 0 00.5.5h10.667a.5.5 0 00.5-.5v-.25a.5.5 0 00-.5-.5h-3.667z"
        fill="#8792A2"
      />
    </svg>
  )
}

export default SvgTrash
