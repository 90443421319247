import store from 'Workflows/store'
import Overview from './Overview/Overview'
import { Provider } from 'react-redux'

const WorkflowOverview = ({ workflowId }) => {
  return <Provider store={store}>
    <ErrorBoundary>
      <Overview workflowId={workflowId} />
    </ErrorBoundary>
  </Provider>
}

export default WorkflowOverview