import './AudienceShortDescriptor.scoped.scss'
import Description from 'SearchBuilder/components/Description'
import DynamicPrice from 'Shared/components/DynamicPrice'
import calculateEstimate from './Audience/calculateEstimate'

export default function AudienceShortDescriptor({ targetCount, complete, kind, search, count, baseCost, contactCost, topicName }) {
  const act = useAct()
  const showEstimate = (count || 0) > 0 && _.isNumber(baseCost) && _.isNumber(contactCost)
  const recalculatingSearch = search && search.status === 'recalculating'
  const showCount = !recalculatingSearch && (count > 0 || count === 0)
  const estimate = showEstimate ? calculateEstimate(baseCost, contactCost, kind) : 0
  const searchHasError = search && search.status === 'error'

  useEffect(() => {
    let intervalId
    if (search && search.status === 'recalculating') {
      intervalId = setInterval(() => {
        act.campaignBuilder.loadSearch(search.id)
      }, 2500)
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId)
      }
    }
  }, [search?.status, search?.id, act.campaignBuilder])


  return <>
    {complete &&
      <div className='no-margin-bottom audience-description'>
        <span>
          <span className='bold'>{search && search.name}</span>
          { topicName && <>
            <span className='ellipsis'></span>
            <span>Subscribed to <strong>{ topicName }</strong></span>
          </>}
          { showCount && <>
            <span className='ellipsis'></span>
            <span>{ (targetCount || 0).toLocaleString() } recipients</span>
          </> }
          { showEstimate && <>
            <span className='ellipsis'></span>
            <span><DynamicPrice usdAmount={estimate} /> { kind === 'sms_blast' ? 'per segment' : '' }</span>
          </> }
        </span>
        <div className='margin-top'>{ search && <Description marginBottom={false} searchId={search.id} embedded={true} /> }</div>
      </div>
    }
    { recalculatingSearch && (
      <p className="bold no-margin-bottom">Recalculating audience...</p>
    )}
    { targetCount == 0 && !recalculatingSearch && (
      <p className="error no-margin-bottom">This audience has no people in it. You'll need to pick another audience to launch your campaign.</p>
    )}
    { searchHasError && (
      <p className="error no-margin-bottom">This audience contains an error, and you can't launch a campaign using it. Please correct this or choose another audience.</p>
    )}
    { complete === false &&
      <p className='incomplete no-margin-bottom'>{ kind === 'phone' ? 'Who are you calling?' : 'Who are you sending this to?' }</p>
    }
  </>
}
