import restClient from 'Shared/hooks/restClient'
import Loading from 'Shared/components/Loading'
import Phone from './Phone'
import Conversations from './Conversations'
import OrganisationChooser from './OrganisationChooser'
import Context from 'CampaignVolunteer/Context'

export default function CampaignVolunteer() {
  const { state, dispatch } = useContext(Context)
  const { campaignId, user, campaign, stage, organisationId } = state

  useEffect(() => {
    restClient.get(`/api/campaigns/${campaignId}/show_volunteer`)
      .then((response) => {
        dispatch({ campaign: response.data })

        if (!response.data.chooseOrganisation) {
          dispatch({ stage: 'startCampaign' })
        }
      })
    restClient.get(`/api/me`)
      .then((response) => dispatch({ user: response.data }))
  }, [])

  if (!campaign || !user) return (<Loading />)

  return (
    <div className="container">
      <div className="row">
        <div className="column remaining">
          <div className="box with-footer">
            <div className="content">
              <p className="larger dark-grey">
                { i18n.t('user.campaigns.hello', { first_name: user.firstName }) }
                <SvgIconsWave />
              </p>
              { stage === 'chooseOrganisation' && <OrganisationChooser /> }
              { stage === 'startCampaign' && <CampaignDetails campaign={campaign} organisationId={organisationId} /> }
            </div>
            <div className='footer'>
              <p>{ i18n.t('user.campaigns.need_help', { email: user.clientSupportEmail }) }</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function CampaignDetails({ campaign, organisationId }) {
  const { description, campaignType } = campaign
  
  return <>
    <h2>{ i18n.t('user.campaigns.get_started') }</h2>
    { !campaign && <p>{ i18n.t('user.campaigns.no_campaigning') }</p> }
    { campaign && <>
      { description && <>
        <p className="description">{ description }</p>
        <div className="divider"></div>
      </> }
    </> }
    { campaignType === 'phone' && <Phone /> }
    { campaignType === 'sms_conversations' && <Conversations /> }
  </>
}
