export default function BookmarkStar({ solid = false }) {
  return <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <defs>
      <path
        id="star"
        fillRule="evenodd"
        clipRule="evenodd" d="M9.99996 16.314L5.11528 18.8634C4.53065 19.1685 3.80756 18.9454 3.5002 18.365C3.37782 18.1339 3.33558 17.8692 3.38004 17.6118L4.31292 12.212L0.361156 8.38794C-0.111812 7.93024 -0.121491 7.17852 0.339539 6.70898C0.523127 6.52199 0.76368 6.40032 1.02397 6.36277L6.48517 5.57496L8.92747 0.662094C9.21979 0.07409 9.9369 -0.167331 10.5292 0.122869C10.7651 0.238424 10.9559 0.427949 11.0724 0.662094L13.5147 5.57496L18.9759 6.36277C19.6295 6.45706 20.0824 7.05956 19.9874 7.70843C19.9497 7.96684 19.827 8.20566 19.6387 8.38794L15.6869 12.212L16.6198 17.6118C16.7315 18.258 16.2942 18.8718 15.6433 18.9827C15.384 19.0268 15.1174 18.9849 14.8846 18.8634L9.99996 16.314Z"
        stroke="#191936"
        strokeWidth="4"
      />
      <clipPath id="clip">
        <use xlinkHref="#star" />
      </clipPath>
    </defs>
    <g>
      <use xlinkHref="#star" stroke="#191936" strokeWidth="4" fill={solid ? '#191936' : 'none'} clipPath="url(#clip)" />
    </g>
  </svg>
}
