import * as React from 'react'

function SvgNotificationsClear(props) {
  return (
    <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.4892 3C9.00394 3 6.98922 5.01472 6.98922 7.5V12H5.49414C4.66571 12 3.99414 12.6716 3.99414 13.5C3.99414 14.3284 4.66571 15 5.49414 15H18.4679C19.2963 15 19.9679 14.3284 19.9679 13.5C19.9679 12.6716 19.2963 12 18.4679 12H16.9728V7.5C16.9728 5.01472 14.9581 3 12.4728 3H11.4892Z"
      fill="#8792A2"
    />
    <rect x={10} y={16} width={4} height={4} rx={2} fill="#8792A2"/>
    <path
      d="M15.4744 8.5C17.1298 8.5 18.4703 7.15608 18.4703 5.5C18.4703 3.84392 17.1298 2.5 15.4744 2.5C13.8191 2.5 12.4785 3.84392 12.4785 5.5C12.4785 7.15608 13.8191 8.5 15.4744 8.5Z"
      fill="#FE3C55"
      stroke="white"
    />
    </svg>
  )
}

export default SvgNotificationsClear
