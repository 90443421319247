import { renderToString } from 'react-dom/server'
import './textMessages.scoped.scss'

function Status({ status }) {
  const labels = {
    read: 'Read',
    delivered: 'Delivered',
    sent: 'Sent',
    not_sent: 'Waiting',
    failed: 'Not sent'
  }

  return <div className="status">
    {status === 'failed' && <div className='icon'><SvgIconsRedCross /></div>}
    {labels[status]}
  </div>
}

const columns = [
  {
    data: 'name',
    width: '20%',
    className: 'bold',
    render: function(data, type, row, meta) {
      return `<a href='${row['url']}'>${data}</a>`
    },
  },
  { data: 'body' },
  {
    data: 'clicked',
    width: '15%',
    render: (data, type, row, meta) => {
      if (row['clicked'] === 'true') {
        return renderToString(<SvgIconsTickGreen />)
      } else {
        return renderToString(<SvgIconsRedCross />)
      }
    }
  },
  {
    data: 'triggered_opt_out',
    width: '15%',
    render: (data, type, row, meta) => {
      if (row['triggered_opt_out'] === 'true') {
        return renderToString(<SvgIconsTickGreen />)
      } else {
        return renderToString(<SvgIconsRedCross />)
      }
    }
  },
  {
    data: 'status',
    width: '15%',
    render: (data, type, row, meta) => {
      return renderToString(<Status status={row['status']} />)
    }
  }
]

const headers = [
  'Name',
  'Text',
  'Clicked',
  'Opted out',
  'Status'
]

const filters = [
  {
    name: 'Clicked', kind: 'radio', column: 2, options: [
      { value: '', label: 'All' },
      { value: false, label: "Didn't click" },
      { value: true, label: "Clicked" }
    ]
  },
  {
    name: 'Opted out', kind: 'radio', column: 3, options: [
      { value: '', label: 'All' },
      { value: false, label: "Didn't opt out" },
      { value: true, label: "Did opt out" }
    ]
  }

]

const sorters = [

]

export { columns, headers, filters, sorters }
