import ServerWaitButton from 'Shared/components/ServerWaitButton'

const STATUSES = {
  sent: 'Your sample has been sent.',
  error: "Sorry, we couldn't send a sample to that email.",
  invalid_email: "Please check the email you've entered. It doesn't look quite right.",
}

export default function SendSampleEmail({ open, close, onlyAllowEmail = false }) {
  const act = useAct()
  const sampleStatus = useSel((s) => s.campaignBuilder.sampleStatus)
  const userEmail = useSel((s) => s.local.userEmail)
  const [email, setEmail] = useState(userEmail || '')

  function sendSample() {
    return act.campaignBuilder.sendSampleEmail(email, onlyAllowEmail ? true : false)
  }

  return (
    <div className={classes('modal-container', { visible: open })}>
      <div className="modal">
        <div className="header">
          <h2>Send a sample email</h2>
          <a className="close js-close-modal" onClick={close}>
            X
          </a>
        </div>

        <div className="content">
          <div className="padded">
            <p className="no-margin-top bold">Send a sample to</p>
            <div className="input-with-button">
              <input
                type="text"
                value={email}
                placeholder={'Email address'}
                onChange={(e) => setEmail(e.target.value)}
              />
              <ServerWaitButton
                className="button primary"
                disabled={email.length === 0}
                onClick={() => sendSample()}
              >
                Send sample
              </ServerWaitButton>
            </div>
            {sampleStatus && (
              <p className={`${sampleStatus === 'sent' ? 'dark-grey' : 'error'}`}>
                {STATUSES[sampleStatus]}
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
