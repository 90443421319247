import Wizard from './Wizard'
import Backdrop from '@material-ui/core/Backdrop'
import RrButton from '../../Shared/components/RrButton'
import RingroundSnackbar from '../../Shared/components/Snackbar'

const UploadAudienceButton = ({ className = '', listOnly = false, minimumData = [] }) => {
  const act = useAct()
  const [modalOpen, setModalOpen] = useState(false)
  const [action, setAction] = useState(null)

  const open = (action) => {
    setAction(action)
    setModalOpen(true)
  }

  const dismiss = () => {
    setModalOpen(false)
  }

  return (
    <>
      <RingroundSnackbar />
      <Backdrop open={modalOpen} classes={{ root: 'cc-backdrop' }}>
        <Wizard isOpen={modalOpen} onDismiss={dismiss} minimumData={minimumData} action={action} />
      </Backdrop>
      <div className="cc-button">
        <RrButton title="Create a list" type="primary" icon="add" onClick={() => open('upload-list')} />
        {!listOnly && <RrButton title="Upload" type="primary" icon="upload" onClick={() => open('upload-people')} />}
      </div>
    </>
  )
}

export default UploadAudienceButton
