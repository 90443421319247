import SummaryItem from './SummaryItem'
import restClient from 'Shared/hooks/restClient'
import { formatDate } from '../utils/dateAndMathsUtils'
import './SummaryItems.scoped.scss'

const CAMPAIGN_LABELS = {
  whatsapp: {
    sms_blast: 'WhatsApp blast',
    sms_survey: 'WhatsApp survey',
    sms_conversations: 'WhatsApp conversations',
  },
  sms: {
    sms_blast: 'Text blast',
    sms_survey: 'Text survey',
    sms_conversations: 'Text conversations',
  },
}

const CampaignTypeSummaryItem = ({ campaignType, entityBlast }) => (
  <SummaryItem
    label="Campaign Type"
    value={campaignType !== 'email' ? CAMPAIGN_LABELS[entityBlast?.kind][campaignType] : 'email blast'}
    icon={<SvgIconsSmsBlast />}
  />
)

const SubscriptionTopicSummaryItem = ({ subscriptionTopicLabel }) => (
  <SummaryItem
    label="Subscription Topic"
    value={subscriptionTopicLabel}
    icon={<SvgIconsQuote />}
  />
)

const SenderSummaryItem = ({ entityBlast, campaignType }) => {
  const senderType = campaignType === 'email'
    ? entityBlast?.emailSender : entityBlast?.senderType || 'phone_number'
  const senderName = campaignType === 'email'
    ? entityBlast?.emailSender.name : entityBlast?.senderName || 'Phone number'

  return (
    <SummaryItem
      label={senderType === 'phone_number' ? 'From' : 'Sender Name'}
      value={senderName}
      icon={<SvgIconsUser />}
    />
  )
}

function SummaryItems({entityBlast, targetsCount}) {
  const isWorkflowControlled = useSel((s) => s.local.isWorkflowControlled)
  const { campaignType } = useSel((s) => s.campaign || {})
  const campaignId = useSel((s) => s.local.campaignId)

  const {
    name: searchName,
    id: searchId,
    targetCount,
  } = useSel((s) => s.campaign?.search || {})

  const [workflows, setWorkflows] = useState([])
  useEffect(() => {
    restClient
      .get(`/api/campaigns/${campaignId}/workflows`)
      .then(({ data }) => setWorkflows(data))
  }, [])

  const { subscriptionTopic } = useSel((s) => s.campaign || {})

  if (subscriptionTopic !== 'default') {
    const kind = campaignType ==='email' ? 'email' : entityBlast?.kind === 'whatsapp' ? 'whatsapp' : 'sms'
    var subscriptionTopicLabel = window.ENV.AVAILABLE_SUBSCRIPTIONS[kind].find(
      (sub) => sub.topic === subscriptionTopic
    )?.label
  }

  return (
    <div id="summary">
      <div className={`box ${isWorkflowControlled ? 'wider' : ''}`}>
        <SummaryItem
          label="Recipients"
          value={(targetsCount || targetCount || 0).toLocaleString('en-US')}
          icon={<SvgIconsPersonBlue />}
          large
        />
      </div>
      {!isWorkflowControlled ? (
        <>
          <div className="box">
            <CampaignTypeSummaryItem
              campaignType={campaignType}
              entityBlast={entityBlast}
            />
            <SummaryItem
              label="Audience"
              link={searchId}
              value={searchName}
              icon={<SvgIconsAudience />}
            />
            {subscriptionTopicLabel && (
              <SubscriptionTopicSummaryItem
                subscriptionTopicLabel={subscriptionTopicLabel}
              />
            )}
          </div>
          <div className="box">
            <SummaryItem
              label="Sent"
              value={
                entityBlast?.sentAt
                  ? formatDate(entityBlast?.sentAt)
                  : 'Now'
              }
              icon={<SvgIconsCalendar3 />}
            />
            <SenderSummaryItem
              campaignType={campaignType}
              entityBlast={entityBlast}
            />
          </div>
        </>
      ) : (
        <div className="box">
          <CampaignTypeSummaryItem
            campaignType={campaignType}
            entityBlast={entityBlast}
          />
          {subscriptionTopicLabel && (
            <SubscriptionTopicSummaryItem
              subscriptionTopicLabel={subscriptionTopicLabel}
            />
          )}
          <SenderSummaryItem
            campaignType={campaignType}
            entityBlast={entityBlast}
          />
          {workflows.length > 0 && (
            <>
              {workflows.map((workflow, i) => (
                <SummaryItem
                  key={i}
                  label="Automation"
                  value={workflow.name}
                  icon={<SvgIconsAutomations />}
                />
              ))}
            </>
          )}
        </div>
      )}
    </div>
  )
}

export default SummaryItems
