import Modal from 'Shared/components/Modal/Modal'
import ChooseCallingMode from './ChooseCallingMode'

export default function ChooseCallingModeModal(props) {
  return (
    <Modal className="box grow" open title={i18n.t('user.campaigns.phone.calling_options.how_to_call')} onCancel={() => window.location.href = '/'}>
      <ChooseCallingMode {...props} />
    </Modal>
  )
}

