import { TwilioComplianceEmbed } from "twilio-compliance-embed"
import Loading from "Shared/components/Loading"
import restClient from "Shared/hooks/restClient"

export default function TwilioBundleEmbed({ onComplete, phoneNumberType }) {
  const [complianceData, setComplianceData] = useState(null)
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    restClient.post('/api/compliance/twilio/initiate_compliance', { phoneNumberType }).then(({ data }) => setComplianceData(data))
  }, [])

  if (!complianceData) return <Loading />

  const { inquiryId, inquirySessionToken } = complianceData

  return <div className='margin-top'>
    <div style={{ visibility: loaded ? 'visible' : 'hidden' }}>
      <TwilioComplianceEmbed
        inquiryId={inquiryId}
        inquirySessionToken={inquirySessionToken}
        onReady={() => setLoaded(true)}
        onComplete={onComplete}
      />
    </div>
    { !loaded && <Loading text="This may take a few seconds" /> }
  </div>
}
