import React, { FC, MouseEvent } from 'react'
import { BlockType } from '../Block'
import BlockStyles from '../../BlockStyles'
import ReactDOM from 'react-dom'

function insertItemAfterIndex(array: any[], index: number, item: any): any[] {
  const firstPart = array.slice(0, index + 1)
  const secondPart = array.slice(index + 1)
  return [...firstPart, item, ...secondPart]
}

interface AddButtonProps {
  id: string,
  targetElement: HTMLElement | null,
  anchor: string | null,
  onClick?: () => void,
}

const AddButton: FC<AddButtonProps> = ({ id = '', targetElement, anchor = 'tr', onClick }) => {
  const [menuPosition, setMenuPosition] = useState({ top: 0, left: 0 })

  useEffect(() => {
    const updateMenuPosition = () => {
      if (targetElement) {
        const targetRect = targetElement.getBoundingClientRect()
        setMenuPosition({
          top: targetRect.top + window.pageYOffset + (['bl', 'br'].includes(anchor) ? targetRect.height : 0),
          left: targetRect.left + window.pageXOffset + (['tr', 'br'].includes(anchor) ? targetRect.width : 0)
        })
      }
    }
    updateMenuPosition()
    window.addEventListener('resize', updateMenuPosition)
    return () => {
      window.removeEventListener('resize', updateMenuPosition)
    }
  }, [targetElement])

  const styles = {
    position: 'absolute',
    height: 18,
    top: menuPosition.top,
    left: menuPosition.left,
    border: 'none',
    backgroundColor: '#191936',
    padding: 0,
    borderRadius: 4,
  }

  if(!targetElement) {
    return null
  }

  return ReactDOM.createPortal(
    <button id={id} style={styles} onClick={onClick}>
      <SvgIconsPlusWhite size={18} />
    </button>,
    document.body
  )
}

interface TableProps {
  data: any[][]
  editing: boolean
  focussed: [number, number]
  rows: number
  columns: number
  widths?: string[] // Add widths to props
  onCellClick?: (rowIndex: number, colIndex: number, tableCell: HTMLElement) => void
}

const HIGHLIGHT_COLOUR = '#00F399'

const Table: FC<TableProps> = ({
  data,
  editing,
  focussed = [],
  rows,
  columns,
  widths,
  onCellClick,
}) => {
  const handleCellClick = (rowIndex: number, colIndex: number) => (event: MouseEvent) => {
    if (editing && onCellClick) {
      const targetElement = event.currentTarget as HTMLElement
      onCellClick(rowIndex, colIndex, targetElement)
    }
  }

  const renderRow = (rowIndex: number) => {
    const row = data[rowIndex] || []
    return (
      <tr key={rowIndex}>
        {Array.from({ length: columns }, (_, colIndex) => {
          const columnWidth =
            widths && widths[colIndex] ? `${widths[colIndex]}%` : `${100 / columns}%`
          const isFocussed =
            focussed.length > 0
              ? focussed[0] === rowIndex && focussed[1] === colIndex
              : false
          return (
            <td
              key={colIndex}
              onClick={handleCellClick(rowIndex, colIndex)}
              dangerouslySetInnerHTML={{ __html: row[colIndex] || '<i>Empty</i>' }}
              style={{
                width: columnWidth,
                cursor: editing ? 'pointer' : 'default',
                backgroundColor: isFocussed ? HIGHLIGHT_COLOUR : 'transparent',
              }}
              onMouseOver={(e) => {
                if (editing) e.currentTarget.style.backgroundColor = HIGHLIGHT_COLOUR
              }}
              onMouseOut={(e) => {
                if (editing)
                  e.currentTarget.style.backgroundColor = isFocussed
                    ? HIGHLIGHT_COLOUR
                    : 'transparent'
              }}
            />
          )
        })}
      </tr>
    )
  }

  return (
    <table
      className="rendered-table injected-styles"
      style={{ tableLayout: 'fixed', width: '100%' }}
    >
      <tbody>
        {Array.from({ length: rows }, (_, rowIndex) => renderRow(rowIndex))}
        {rows === 0 && <i>Add a row to your table to get started.</i>}
      </tbody>
    </table>
  )
}

const TableBlock: FC<{
  block: BlockType
  active?: boolean
}> = ({ block, active }) => {
  const act = useAct()
  const { rows, columns, data, focussed, widths } = block.parameters
  const [activeTableCellElement, setActiveTableCellElement] = useState<HTMLElement | null>(null)

  const handleCellClick = (rowIndex: number, colIndex: number, tableCell: HTMLElement) => {
    setActiveTableCellElement(tableCell)
    act.email.blocks.updateParameters(block.id, {
      focussed: [rowIndex, colIndex],
    })
  }

  const addRow = () => {
    insertRowAfter(rows - 1)
  }

  const insertRowAfterCell = () => {
    insertRowAfter(focussed[0])
  }

  const insertRowAfter = (index: number) => {
    const newRow = new Array(columns).fill('')
    const updatedData = insertItemAfterIndex(data, index, newRow)
    act.email.blocks.updateParameters(block.id, {
      rows: rows + 1,
      data: updatedData,
    })
  }

  const insertColumnAfterCell = () => {
    insertColumnAfter(focussed[1])
  }

  const addColumn = () => {
    insertColumnAfter(columns - 1)
  }

  const insertColumnAfter = (index: number) => {
    const updatedData = data.map((row: any[]) => insertItemAfterIndex(row, index, ''))
    const updatedWidths = insertItemAfterIndex(widths, index, 'auto')
    act.email.blocks.updateParameters(block.id, {
      columns: columns + 1,
      data: updatedData,
      widths: updatedWidths, 
    })
  }

  const removeRow = () => {
    if (focussed?.length === 2) {
      const updatedData = data.filter((_: any, rowIndex: number) => rowIndex !== focussed[0])
      act.email.blocks.updateParameters(block.id, {
        rows: rows - 1,
        data: updatedData,
        focussed: [],
      })
    }
  }

  const removeColumn = () => {
    if (focussed?.length === 2) {
      const updatedData = data.map((row: any[]) =>
        row.filter((_, colIndex) => colIndex !== focussed[1])
      )
      const updatedWidths = widths.filter((_: any, colIndex: number) => colIndex !== focussed[1])
      act.email.blocks.updateParameters(block.id, {
        columns: columns - 1,
        data: updatedData,
        widths: updatedWidths,
        focussed: [],
      })
    }
  }

  if (!data) {
    return null
  }

  return (
    <div className="margin-top" id={`block_${block.id}`}>
      <Table
        data={data}
        editing={active}
        focussed={focussed}
        rows={rows}
        columns={columns}
        widths={widths}
        onCellClick={handleCellClick}
      />
      <BlockStyles block={block} active={active} />
      {active && (
        <div className="margin-top">
          <button className="button small secondary margin-right" onClick={addRow}>
            Add Row
          </button>
          <button className="button small secondary" onClick={addColumn}>
            Add Column
          </button>

          <AddButton id="insertColumnButton" targetElement={activeTableCellElement} anchor='tr' onClick={insertColumnAfterCell} />
          <AddButton id="insertRowButton" targetElement={activeTableCellElement} anchor='bl' onClick={insertRowAfterCell} />

          {focussed?.length === 2 && (
            <div className="margin-top">
              <button className="button smallest red margin-right" onClick={removeRow}>
                Remove Row
              </button>
              <button className="button smallest red" onClick={removeColumn}>
                Remove Column
              </button>
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default TableBlock
