export default (dis, store, client) => {
  function closeEditor() {
    return dis({ type: 'builder/upsert', payload: { currentEditor: null } })
  }

  function openEditor(editor) {
    return dis({ type: 'builder/upsert', payload: { currentEditor: editor } })
  }

  function upsert(payload) {
    dis({ type: 'builder/upsert', payload })
  }

  function loadMergeTags() {
    // create a new axios client which bypasses the case conversion - we want to
    // load merge tags in snake case
    return axios.create().get(`/api/render/available_merge_tags`)
      .then(({ data: mergeTags }) => {
        dis({ type: 'builder/upsert', payload: { mergeTags } })
      })
  }


  return {
    closeEditor,
    openEditor,
    loadMergeTags,
    upsert,
  }
}
