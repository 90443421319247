import waitForDeferredResult from "Shared/utils/waitForDeferredResult"

export default (dis, store, client) => {
    function upsertWorkflow(workflow) {
      // route everything except workflowNodes to the builder slice
      const { workflowNodes, ...properties } = workflow
      dis({ type: 'builder/upsert', payload: { workflow: properties } })
      dis({ type: 'workflowNodes/setAll', payload: workflowNodes })
    }

    function load(id) {
      return client.get(`/api/workflows/${id}`)
        .then(({ data }) => {
          upsertWorkflow(data)
          dis({ type: 'builder/upsert', payload: { loaded: true, workflowId: id }})
        })
    }

    function updateWorkflow(workflow) {
      const id = store.getState().local.workflowId

      return client.put(`/api/workflows/${id}`, { workflow })
        .then(({ data }) => {
          upsertWorkflow(data)
        })
    }

    function updateEntryWorkflowFilter() {
      const id = store.getState().local.workflowId
      const rules = _.values(store.getState().rules.entities)

      return client.put(`/api/workflows/${id}/entry_workflow_filter`, { rules })
        .then(({ data }) => {
          upsertWorkflow(data)
          dis({ type: 'local/upsert', payload: { modal: { kind: null }}})
        })
    }

    function describeEntryWorkflowFilter() {
      const id = store.getState().local.workflowId
      dis({ type: 'local/upsert', payload: { entryWorkflowFilterDescription: null }})

      return client.get(`/api/workflows/${id}/describe_entry_workflow_filter`)
        .then(({ data }) => {
          dis({ type: 'local/upsert', payload: { entryWorkflowFilterDescription: data }})
        })
    }

    function updateExitWorkflowFilter() {
      const id = store.getState().local.workflowId
      const rules = _.values(store.getState().rules.entities)

      return client.put(`/api/workflows/${id}/exit_workflow_filter`, { rules })
        .then(({ data }) => {
          upsertWorkflow(data)
          dis({ type: 'local/upsert', payload: { modal: { kind: null }}})
        })
    }

    function addBranch({ nodeId }) {
      const id = store.getState().local.workflowId
      const allBranchIds = _.values(store.getState().workflowNodes.entities[nodeId].properties.branches).map(b => b.branchId)

      return client.post(`/api/workflows/${id}/workflow_nodes/${nodeId}/add_branch`)
        .then(({ data }) => {
          dis({ type: 'workflowNodes/upsert', payload: data })
          dis({ type: 'local/upsert', payload: { modal: { kind: null }}})

          const newBranchId = _.values(data.properties.branches).map(b => b.branchId).find(b => !allBranchIds.includes(b))
          dis({ type: 'local/upsert', payload: { sidebar: { kind: 'editBranch', nodeId, branchId: newBranchId }}})
        })
    }

    function updateBranchRules({ nodeId, branchId }) {
      const id = store.getState().local.workflowId
      const rules = _.values(store.getState().rules.entities)
      const properties = { rules }

      return client.put(`/api/workflows/${id}/workflow_nodes/${nodeId}/branches/${branchId}`, { properties })
        .then(({ data }) => {
          dis({ type: 'workflowNodes/upsert', payload: data })
          dis({ type: 'local/upsert', payload: { modal: { kind: null }}})
        })
    }

    function updateBranchPriority({ nodeId, branchId, direction }) {
      const id = store.getState().local.workflowId
      const rules = _.values(store.getState().rules.entities)

      return client.put(`/api/workflows/${id}/workflow_nodes/${nodeId}/branches/${branchId}/update_priority`, { direction })
        .then(({ data }) => {
          dis({ type: 'workflowNodes/upsert', payload: data })
          dis({ type: 'local/upsert', payload: { modal: { kind: null }}})
        })
    }

    function deleteBranch({ nodeId, branchId }) {
      const id = store.getState().local.workflowId

      return client.delete(`/api/workflows/${id}/workflow_nodes/${nodeId}/branches/${branchId}`)
        .then(({ data }) => {
          dis({ type: 'workflowNodes/upsert', payload: data })
          dis({ type: 'local/upsert', payload: { modal: { kind: null }}})
        })
    }

    function addNode({ afterNodeId, afterBranchId, kind }) {
      const id = store.getState().local.workflowId

      return client.post(`/api/workflows/${id}/add_node`, { afterNodeId, afterBranchId, kind })
        .then(({ data }) => {
          const { workflow, node } = data
          const nodeId = node.id

          upsertWorkflow(workflow)
          dis({ type: 'local/upsert', payload: { sidebar: { kind: 'editNode', nodeId }}})
        })
    }

    function createLinkedCampaign({ nodeId, kind, blastKind = null }) {
      const id = store.getState().local.workflowId

      return client.post(`/api/workflows/${id}/create_linked_campaign`, { workflowNodeId: nodeId, kind, blastKind })
        .then(({ data }) => {
          const { url } = data
          window.location = url
        })
    }

    function updateNode({ nodeId, properties }) {
      const id = store.getState().local.workflowId

      return client.put(`/api/workflows/${id}/workflow_nodes/${nodeId}`, { properties })
        .then(({ data }) => {
          dis({ type: 'workflowNodes/upsert', payload: data })
          dis({ type: 'local/upsert', payload: { modal: { kind: null }}})
        })
    }

    function deleteNode({ nodeId }) {
      const id = store.getState().local.workflowId

      return client.delete(`/api/workflows/${id}/workflow_nodes/${nodeId}`)
        .then(({ data }) => {
          upsertWorkflow(data)
          dis({ type: 'local/upsert', payload: { sidebar: { kind: null, nodeId: null }}})
        })
    }

    function updateBranch({ nodeId, branchId, properties }) {
      const id = store.getState().local.workflowId

      return client.put(`/api/workflows/${id}/workflow_nodes/${nodeId}/branches/${branchId}`, { properties })
        .then(({ data }) => {
          dis({ type: 'workflowNodes/upsert', payload: data })
          dis({ type: 'local/upsert', payload: { modal: { kind: null }}})
        })
    }

    function resume() {
      const id = store.getState().local.workflowId

      return client.post(`/api/workflows/${id}/resume`)
        .then(({ data }) => {
          upsertWorkflow(data)
        })
        .catch(err => {
          console.log({ err })
          dis({ type: 'local/upsert', payload: { alert: {
            severity: 'error',
            message: "This workflow could not be resumed."
          }} })
          throw err
        })
    }

    function pause() {
      const id = store.getState().local.workflowId

      return client.post(`/api/workflows/${id}/pause`)
        .then(({ data }) => {
          upsertWorkflow(data)
        })
    }

    function countEntry() {
      const id = store.getState().local.workflowId
      dis({ type: 'local/upsert', payload: { entryCount: null } })

      return client.post(`/api/workflows/${id}/count_entry`)
        .then(({ data }) => {
          const { deferredResultId } = data
          waitForDeferredResult(deferredResultId)
          .then(data => {
            dis({ type: 'local/upsert', payload: { entryCount: data } })
          })
          .catch(err => {
            dis({ type: 'local/upsert', payload: { alert: {
              severity: 'error',
              message: err
            }} })
          })
        })
    }

    function setOpenPlaceholderId(openPlaceholderId) {
      dis({ type: 'builder/upsert', payload: { openPlaceholderId }})
    }

    return {
      load,
      updateWorkflow,
      updateEntryWorkflowFilter,
      describeEntryWorkflowFilter,
      updateExitWorkflowFilter,
      addBranch,
      updateBranchRules,
      updateBranchPriority,
      addNode,
      updateNode,
      deleteNode,
      updateBranch,
      deleteBranch,
      resume,
      pause,
      countEntry,
      setOpenPlaceholderId,
      createLinkedCampaign
    }
}
