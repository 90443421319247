import { format, parseISO } from "date-fns"

function getTimeZone(date) {
  const options = { timeZoneName: 'long'}
  const dateTimeFormat = new Intl.DateTimeFormat('en-US', options)
  const parts = dateTimeFormat.formatToParts(date)

  return parts[parts.length - 1].value
}

export default function getDescription(scheduledFor) {
  if (scheduledFor) {
    const date = parseISO(scheduledFor)
    const timezone = getTimeZone(date)
    const formattedDate = format(date, "h:mmaa EEEE do MMMM")

    return `Scheduled for ${formattedDate} (${timezone})`
  } else {
    return "Check everything is OK."
  }
}
