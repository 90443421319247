import parsePhoneNumber from 'libphonenumber-js/max'
import Papa from 'papaparse'
import { ERRORS, STEPS } from './constants'
import axios from 'axios'
import { parse, isValid, format } from 'date-fns'

const matchGoogleUrl = ({ type = 'sheet', url }) => {
  switch (type) {
    case 'sheet': {
      const googleSheetUrl =
        url && url.match(/(?:docs\.google\.com\/spreadsheets\/d)\/.*\//g)
      return googleSheetUrl && `https://${googleSheetUrl}export?format=csv`
    }
  }
}

const getFileFromGoogleSheet = (url, alerter) => {
  const noCsrf = axios.create()
  noCsrf.defaults.headers.common = {}
  return noCsrf
    .get(url, { responseType: 'blob', headers: {} })
    .then((res) => {
      return new File([res.data], 'google-sheet.csv', {
        type: res.data.type,
      })
    })
    .catch((err) => {
      if (err.toJSON().message.toLowerCase().includes('network error'))
        alerter(ERRORS.GOOGLE_PERMISSIONS)
      return false
    })
}

const getCsvSample = ({ type, ref }, length) => {
  return new Promise((resolve, reject) => {
    let rowN = 0
    let rows = { data: [] }
    Papa.parse(ref, {
      download: type === 'url',
      step: function (row, parser) {
        rows.data.push(row.data)
        if (rowN === length) parser.abort()
        rowN += 1
      },
      complete: () => {
        resolve(rows)
      },
      error: (err) => {
        reject(err)
      },
    })
  })
}

const isValidDate = (value, dateFormat) => {
  const parsedDate = parse(value, dateFormat, new Date())
  return isValid(parsedDate) && format(parsedDate, dateFormat) === value
}

const validateCsv = ({ type, csv, schema, confirmedColumns }) => {
  return new Promise((resolve, reject) => {
    /* Check data meets schema */
    let rowN = 0
    const errorRows = []
    let indexedSchema
    let abortMessage

    Papa.parse(csv.ref, {
      download: csv.type === 'url',
      worker: false,
      step: function (row, parser) {
        /* Check for end of file */
        if (!row.data.join()) {
          parser.abort()
          return
        }
        if (rowN === 0) {
          /* Check for required columns in header row */
          const fieldNames = schema.map((f) => f.name.trim().toLowerCase())
          let missing
          if (type === 'upload-list') {
            missing = confirmedColumns.filter(
              (field) => !row.data.some((f) => f.trim().toLowerCase() === field.trim().toLowerCase())
            )
          } else {
            missing = schema
              .filter(
                (field) =>
                  field.required === true &&
                  !row.data.some(
                    (f) => f.trim().toLowerCase() === field.name.trim().toLowerCase()
                  )
              )
              .map((field) => field.name)
          }

          const additional = row.data
            .filter((header) => !_.includes(fieldNames, header.trim().toLowerCase()))
            .map((header) => (!header.trim() ? '(empty column)' : header))

          let invalidColumns = []
          if (type === 'upload-list') {
            invalidColumns = row.data
            .filter((header) => header.trim().toLowerCase() !== confirmedColumns[0].trim().toLowerCase())
          }

          if (missing?.length) {
            abortMessage = `${ERRORS.CSV_COLUMNS_MISSING} [${missing.join(', ')}]`
            parser.abort()
          } else if (additional?.length && type !== 'upload-list') {
            abortMessage = ERRORS.CSV_CUSTOM_COLUMNS.replace('{columns}', additional.join(', '))
            parser.abort()
          } else if (invalidColumns?.length) {
            abortMessage = ERRORS.CSV_COLUMNS_LIST_INVALID
            parser.abort()
          } else {
            indexedSchema = schema.map((item) => {
              return {
                ...item,
                dataIndex: row.data.findIndex(
                  (f) => f.trim().toLowerCase() == item.name.toLowerCase()
                ),
              }
            })
          }
        }
        /* Validate each row */
        if (rowN > 0) {
          const errorCells = []
          const valid = indexedSchema.every((item) => {
            if (type === 'upload-list' && item.name !== confirmedColumns[0]) {
              return true
            }
            const result = validateField(item, row.data[item.dataIndex])
            if (!result) {
              errorCells.push(item.dataIndex)
            }
            return result
          })
          if (!valid) errorRows.push({ index: rowN, data: row.data, errorCells })
        }
        /* emit progress updates */
        if (rowN % 100 === 0) updateProgress(row.meta.cursor, csv.ref.size)
        rowN += 1
      },
      complete: () => {
        if (abortMessage) reject({ reason: abortMessage })
        else resolve({ perfect: !errorRows.length, errorRows })
      },
      error: (err) => {
        reject(err)
      },
    })
  })
}

const updateProgress = (n, N) => {
  if (n % 10 === 0) {
    const event = new ProgressEvent('csvProcessing', {
      lengthComputable: true,
      loaded: n,
      total: N,
    })
    window.dispatchEvent(event)
  }
}

const validateField = (schemaItem, value) => {
  switch (schemaItem.type) {
    case 'string':
    case 'name':
      if (!value && !schemaItem.required) {
        return true
      }
      return typeof value === 'string' && !!value.length
    case 'date':
      if (!value) {
        return !schemaItem.required
      }
      if (typeof value === 'string') {
        return isValidDate(value, "yyyy-MM-dd")
      }
      return false
    case 'phone': {
      if (!schemaItem.required && (!value || !value.trim())) return true

      const country = String(value)[0] === '+' ? '' : window.ENV.COUNTRY.toUpperCase()
      const parsed = parsePhoneNumber(value, country)

      if (parsed) {
        const allowedTypes = ["FIXED_LINE", "MOBILE", "VOIP", "PAGER", "FIXED_LINE_OR_MOBILE"];
        const phoneType = parsed.getType();
        const isValidType = phoneType ? allowedTypes.includes(phoneType) : false;

        return parsed && parsed.isValid() && isValidType;
      } else {
        return false
      }
    }
    default:
      if (!schemaItem.required && !value) {
        return true
      }
      return value && schemaItem.regex && value.match(schemaItem.regex)
  }
}

function getCurrentStep(identifier) {
  const idx = stepIdx(identifier)
  const current = STEPS[idx]
  return `Step ${idx + 1}: ${current.label}`
}

function stepIdx(identifier) {
  return STEPS.findIndex((obj) => obj.id === identifier)
}

function getStep(identifier, operator = '+') {
  const idx = stepIdx(identifier)
  const [other, prefix] =
    operator === '+' ? [STEPS[idx + 1], 'Next'] : [STEPS[idx - 1], 'Previous']
  return other ? `${prefix}: ${other.label}` : null
}

function nextStep(identifier) {
  return getStep(identifier, '+')
}

function prevStep(identifier) {
  return getStep(identifier, '-')
}

export {
  matchGoogleUrl,
  getFileFromGoogleSheet,
  validateCsv,
  getCsvSample,
  getCurrentStep,
  nextStep,
  prevStep,
}
