import { renderToString } from 'react-dom/server'
import './textMessages.scoped.scss'

function Status({ status }) {
  const labels = {
    delivered: 'Delivered',
    sent: 'Sent',
    not_sent: 'Waiting',
    failed: 'Not sent'
  }

  return <div className="status">
    {status === 'failed' && <div className='icon'><SvgIconsRedCross /></div>}
    {labels[status]}
  </div>
}

function Player({ url }) {
  return <audio controls>
    <source src={url} type="audio/mpeg" />
  </audio>
}

const columns = [
  {
    data: 'target_name',
    className: 'bold',
  },
  {
    data: 'user_name',
    className: 'bold',
  },
  {
    data: 'answered',
    render: (data, type, row, meta) => {
      if (row['answered'] === 'true') {
        return renderToString(<SvgIconsTickGreen />)
      } else {
        return renderToString(<SvgIconsRedCross />)
      }
    }
  },
  {
    data: 'twilio_recording_url',
    className: 'bold',
    render: (data, type, row, meta) => {
      if (!data) return null

      return renderToString(<Player url={data} />)
    }
  }
]

const headers = [
  'Target',
  'User',
  'Answered?',
  'Voicemail'
]

const filters = [
  {
    name: 'Answered', kind: 'radio', column: 2, options: [
      { value: '', label: 'All' },
      { value: false, label: "Didn't get answered" },
      { value: true, label: "Did get answer" }
    ]
  },
  {
    name: 'Voicemail', kind: 'radio', column: 3, options: [
      { value: '', label: 'All' },
      { value: false, label: "Didn't leave voicemail" },
      { value: true, label: "Left voicemail" }
    ]
  }

]

const sorters = [

]

export { columns, headers, filters, sorters }
