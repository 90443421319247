import AsyncSelect from 'Shared/components/AsyncSelect'
import MenuListWithFooter from 'Shared/components/MenuListWithFooter'
import Step from './SurveyBuilderStep'
import AudienceShortDescriptor from 'CampaignBuilder/components/Steps/AudienceShortDescriptor'
import useAct from 'SurveyBuilder/hooks/useAct'

function ShortDescriptor() {
  const {
    search,
    searchId,
    participationMode
  } = useSel((s) => s.survey)

  const { searchCount } = useSel((s) => s.search)
  const complete = participationMode === 'any_target' || (participationMode === 'closed' && (searchId !== null && search !== null))

  if (!complete){
    return <p className='incomplete no-margin-bottom'>Who is able to complete this survey?</p>
  }
  if (participationMode === 'any_target') {
    return <p className='no-margin-bottom'>Anyone can complete this survey</p>
  }

  return <AudienceShortDescriptor
    targetCount={searchCount}
    complete={true}
    search={search}
    count={searchCount}
    key={searchId}
    showEstimate={false}
  />
}

export default function Audience() {
  const act = useAct()
  const {
    id,
    search,
    searchId,
    autoRefreshSearch,
    participationMode
  } = useSel((s) => s.survey)
  const complete = participationMode === 'any_target' || (participationMode === 'closed' && (searchId !== null && search !== null))
  const adminUrl = window.location.pathname
  const newSearchPath = `/admin/searches/new?returning_url=${adminUrl}&linked_survey_id=${id}`

  function loadCount() {
    act.search.loadCount(searchId).then(({ data: { recalculating } }) => {
      if (recalculating) {
        setTimeout(() => {
          loadCount()
        }, 2500)
      }
    })
  }

  useEffect(() => {
    if (searchId) {
      loadCount()
    }
  }, [searchId])

  function save() {
    return act.survey.save().then(() => {
      act.builder.closeEditor()
    })
  }

  function handleParticipationModeChange(e) {
    act.survey.upsert({ participationMode: e.target.value })
  }

  return (
    <Step
      name="Audience"
      editor="audience"
      editable={true}
      complete={complete}
      save={save}
      shortDescriptor={<ShortDescriptor />}>
      <div className="fields">
        <div className="row">
          <div className="column remaining">
            <div className="field">
              <label>Who can take part?</label>
              <select value={participationMode} onChange={handleParticipationModeChange}>
                <option key='any_target' value='any_target'>Anyone</option>
                <option key='closed' value='closed'>A specific audience</option>
              </select>
            </div>

            { participationMode === 'closed' && <>
              <div className="field">
                <label>Audience</label>
                <AsyncSelect
                  entity="search"
                  value={searchId}
                  components={{ MenuList: MenuListWithFooter }}
                  redirectUrl={newSearchPath}
                  queryParameters={{
                    activeOnly: true,
                    excludeTemplates: true,
                  }}
                  onChange={(v) => act.survey.upsert({ searchId: v })}
                />
              </div>

              <div className="field margin-top half">
                <label className="align-center">
                  <input
                    type="checkbox"
                    name="auto_refresh_search"
                    value="true"
                    checked={autoRefreshSearch}
                    onChange={(e) => {
                      act.survey.upsert({ autoRefreshSearch: e.target.checked })
                    }}
                  />
                  <p className="no-margin-top no-margin-bottom margin-left">
                    Automatically refresh this audience every hour
                  </p>
                </label>
                <p className="small">
                  This will pull new members in who have joined the audience, and exclude
                  anyone who has left it.
                </p>
              </div>
            </> }
          </div>
        </div>
      </div>
    </Step>
  )
}
