import useAct from 'Workflows/hooks/useAct'
import EditDelayNode from 'Workflows/components/Builder/Forms/EditDelayNode'
import EditAddToCampaignNode from 'Workflows/components/Builder/Forms/EditAddToCampaignNode'
import EditDecisionNode from 'Workflows/components/Builder/Forms/EditDecisionNode'
import EditExitNode from 'Workflows/components/Builder/Forms/EditExitNode'
import { NAMES } from 'Workflows/constants'
import { current } from 'app/packs/lib/helpers'

export default function NodeSidebar() {
  const act = useAct()
  const { nodeId } = useSel(s => s.local.sidebar)
  const nodeKind = useSel(s => s.workflowNodes.entities[nodeId]?.kind)
  const currentTargetCount = useSel(s => s.workflowNodes.entities[nodeId]?.currentTargetCount || 0)
  const properties = useSel(s => s.workflowNodes.entities[nodeId]?.properties || {})
  const state = useSel(s => s.builder.workflow.state)
  const canDelete = (() => {
    if (!nodeKind) return false
    if (!(state === 'draft' || state === 'paused')) return false
    if (nodeKind === 'decision' && properties.branches.length > 0) {
      return false
    }

    return currentTargetCount === 0
  })()

  const handleDelete = () => {
    return act.local.openModal({ kind: 'deleteConfirm', nodeId })
  }
  const readOnly = !(state === 'draft' || state === 'paused')

  return <div className='box workflow-sidebar'>
    { nodeKind && <h3 className='no-margin-top'>{ NAMES[nodeKind] }</h3> }
    { nodeKind === 'delay' && <EditDelayNode nodeId={nodeId} key={nodeId} /> }
    { nodeKind === 'add_to_campaign' && <EditAddToCampaignNode nodeId={nodeId} key={nodeId} /> }
    { nodeKind === 'decision' && <EditDecisionNode nodeId={nodeId} key={nodeId} /> }
    { nodeKind === 'exit' && <EditExitNode nodeId={nodeId} key={nodeId} /> }

    { !readOnly && <>
      { canDelete && <div className='margin-top'>
        <a className='link dark-grey' onClick={handleDelete}>Delete</a>
      </div> }
      { !canDelete && <div className='margin-top'>
        { nodeKind === 'decision'
          ? <span className='dark-grey'>You can only delete a node with no targets or branches</span>
          : <span className='dark-grey'>You can only delete a node with no targets</span>
        }
      </div> }
    </>}

  </div>
}
