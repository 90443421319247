import PillEditor from "Shared/components/PillEditor/PillEditor"
import './HeadingBlock.scoped.scss'

const headingTag = (tag, content) => {
  if (tag === 'h2') return <h2>{content}</h2>
  if (tag === 'h3') return <h3>{content}</h3>
  if (tag === 'h4') return <h4>{content}</h4>
  return <h1>{content}</h1>
}

const HEADING_STYLES = {
  'h1': { fontSize: 34, fontFamily: 'Helvetica, Arial, sans-serif;' },
  'h2': { fontSize: 30, fontFamily: 'Helvetica, Arial, sans-serif;' },
  'h3': { fontSize: 28, fontFamily: 'Helvetica, Arial, sans-serif;' },
  'h4': { fontSize: 24, fontFamily: 'Helvetica, Arial, sans-serif;' },
}

const HeadingBlock = ({ block, active }) => {
  const { parameters: { text, tag } } = block
  const act = useAct()
  const { mergeTags } = useSel(s => s.campaignBuilder)
  const selector = `#block_${block.id} .injected-styles span`

  const updateText = (text) => act.email.blocks.updateParameters(block.id, {text})

  return <div id={`block_${block.id}`}>
    <style>
      {`${selector} {
        font-size: ${HEADING_STYLES[tag].fontSize}px !important;
        font-family: ${HEADING_STYLES[tag].fontFamily} !important;
      }`}
    </style>

    { active && <div className='editor-container'>
      <PillEditor condensed initialValue={block.parameters.text} onChange={updateText} availableTags={mergeTags} />
    </div> }
    { !active && headingTag(tag, text) }
  </div>
}

export default HeadingBlock
