import store from 'Shared/store'
import { Provider } from 'react-redux'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { DndProvider } from 'react-dnd'
import Builder from './Builder'

const EmailBuilder = ({ campaignId, emailBlastId, emailTemplateId }) => {
  return <Provider store={store}>
    <DndProvider backend={HTML5Backend}>
      <ErrorBoundary>
        <Builder
          campaignId={campaignId}
          emailBlastId={emailBlastId}
          emailTemplateId={emailTemplateId}
        />
      </ErrorBoundary>
    </DndProvider>
  </Provider>
}

export default EmailBuilder
