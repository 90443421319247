import restClient from 'Shared/hooks/restClient'

function callIntegration(targetId, integration) {
  return restClient.post(`/api/targets/${targetId}/integrations/${integration}/trigger`)
}

const actions = {
  callIntegration,
}

export default actions
