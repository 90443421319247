const htmlTag = (content) => {
  return <div dangerouslySetInnerHTML={{__html: content}} />
}

const HtmlBlock = ({ block, _active }) => {
  const act = useAct()

  return (
    <div id={`block_${block.id}`}>
      { htmlTag(block.parameters.text) }
    </div>
  )
}

export default HtmlBlock
