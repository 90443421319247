export default function Emoji(props) {
    return (
        <svg version="1.1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
            <g fill="none" fillRule="evenodd">
                <rect width="24" height="24" />
                <rect x="2" y="2" width="20" height="20" rx="10" fill="currentColor" />
            </g>
        </svg>
    )
}
