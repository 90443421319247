import { useStore } from 'react-redux'
import createActions from './createActions'
import local from 'Shared/hooks/local'
import user from 'Shared/hooks/user'
import restClient from 'Shared/hooks/restClient'

export default () => {
  const dis = useDis()
  const store = useStore()

  return useMemo(
    () => ({
      ...createActions(dis, store, restClient),
      local: local(dis, store, restClient),
      user: user(dis, store, restClient),
    }),
    []
  )
}
