import Grow from '@material-ui/core/Grow'
import RrIcon from '../RrIcon'

const Board = ({ people }) => {
  return people.map((person, idx) => {
    const direction = person.rank < person.previous_rank ? 'up' : 'down'
    return (
      <Grow key={idx} in timeout={idx * 75}>
        <div className="card smaller flat one-liner spacing-vertical-1">
          <div className="left">
            <b className="rank">{person.rank}.</b>
            {person.rank !== person.previous_rank ? (
              <RrIcon
                iconName={direction}
                classes={direction === 'up' ? 'better' : 'worse'}
              />
            ) : (
              <b className="no-change">-</b>
            )}
            <b>{person.name}</b>
          </div>
          <div className="right">
            <RrIcon iconName="phone" classes="phone-calls" />
            <span className="value dark-grey">{person.value}</span>
          </div>
        </div>
      </Grow>
    )
  })
}

export default Board
