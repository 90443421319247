import useAct from 'DesktopCaller/useAct'
import useData from 'DesktopCaller/useData'
import ServerWaitButton from 'Shared/components/ServerWaitButton'
import RenderBreaks from 'Shared/components/RenderBreaks'
import Radio from 'Shared/components/Radio'
import LabeledCard from 'Shared/components/LabeledCard/LabeledCard'

// Outcome
export default function Outcome({ clientCallOutcomes }) {
  const script = useSel(s => s.local.currentScript, _.isEqual)
  const { callStage, inputStage } = useData(rs => rs.callSession)
  const chosenOutcome = useData(rs => rs.call.outcome)
  const firstName = useData(rs => rs.user.firstName)
  const introQuestionId = useSel(s => _.values(s.questions.entities).filter(q => q.questionType === 'intro')[0]?.id)
  const hasAnsweredAnyQuestions = useData(rs => {
    return _.keys(rs.callSession.questionAnswers || {}).length > 0
  })

  const callAttempts = useData((rs) => rs.campaignTarget.callAttempts)

  const recallAttempts = useData((rs) => rs.campaign.settings.recallAttempts)
  const finalCallMessage = callAttempts >= Number(recallAttempts) && !window.ENV.SETTINGS.hide_final_call_message
  const act = useAct()

  const [localOutcome, setLocalOutcome] = useState(null)

  const handleValueChange = (e) => {
    const outcome = event.target.value
    setLocalOutcome(outcome)
  }

  const outcomes = _.fromPairs(
    clientCallOutcomes.map(outcome => [
      outcome, i18n.t(`user.campaigns.phone.outcomes.${outcome}`, { defaultValue: outcome })
    ])
  )

  function handleSubmit() {
    if (localOutcome === 'no_answer') {
      act.desktopCaller.hangUp()
    }
    return act.desktopCaller.answerOutcome(localOutcome)
  }

  function changeOutcome() {
    return act.desktopCaller.undoLast()
  }

  return (
    <>
      <LabeledCard
        className="intro"
        label={
          <>
            <SvgIconsCallerGreeting />
            {i18n.t('user.campaigns.phone.instructions')}
          </>
        }
      >
        {callStage === 'connected_to_target' && (
          <p className="no-margin-top">
            <RenderBreaks
              text={script?.questions[parseInt(introQuestionId)]?.question}
            />
          </p>
        )}
        {callStage === 'finished_call' && (
          <p className="no-margin-top">{i18n.t('user.campaigns.phone.call_ended')}</p>
        )}
      </LabeledCard>

      {finalCallMessage && (
        <LabeledCard
          label={
            <>
              <SvgIconsCallerQuestion />
              <p>{i18n.t('user.campaigns.phone.final_call')}</p>
            </>
          }
        >
          <p className="no-margin-top">{i18n.t('user.campaigns.phone.final_call_desc')}</p>
        </LabeledCard>
      )}

      <LabeledCard
        label={
          <SvgIconsCallerQuestion />
        }
      >
        <p className="no-margin-top">{i18n.t('user.campaigns.phone.did_you_speak')}</p>
        <div className="answers">
          {_.map(outcomes, (label, outcome) => {
            const disabled = chosenOutcome && chosenOutcome !== outcome
            return (
              <Radio
                key={outcome}
                name="outcome"
                value={outcome}
                checked={outcome === (chosenOutcome || localOutcome)}
                disabled={disabled}
                onChange={handleValueChange}
                label={label}
              />
            )
          })}
        </div>

        <p className="actions">
          {!chosenOutcome && (
            <ServerWaitButton
              className="button primary margin-top"
              disabled={chosenOutcome || !localOutcome}
              onClick={handleSubmit}
            >
              {i18n.t('shared.messages.next')}
            </ServerWaitButton>
          )}
          {chosenOutcome && (
            <ServerWaitButton
              className="button primary margin-top"
              disabled={hasAnsweredAnyQuestions}
              onClick={changeOutcome}
            >
              {i18n.t('user.campaigns.phone.change_outcome')}
            </ServerWaitButton>
          )}
        </p>
      </LabeledCard>
    </>
  )
}
