import { Structure } from './Structure'
import dayJs from 'dayjs'
import { MuiChip } from '../styled'
import { DATE_FORMAT } from 'Shared/constants'

const InfoItem = ({ type, identifier, content }) => {

  switch (type) {
    case 'dob':
      var date = dayJs(content)
      var data = date.isValid() ? date.format(DATE_FORMAT) : null
      return (
        <Structure
          identifier={identifier}
          data={data}
          extra={` (${dayJs().diff(content, 'year', false)} years old)`}
        />
      )
    case 'date':
      var date = dayJs(content)
      var data = date.isValid() ? date.format(DATE_FORMAT) : null
      return (
        <Structure identifier={identifier} data={data} />
      )
    case 'email':
      return (
        <Structure
          identifier={identifier}
          data={<span className="break-word">{content}</span>}
        />
      )
    case 'multiline':
      return (
        <Structure
          identifier={identifier}
          data={content.split(',').map((line) => (
            <span key={line} style={{ lineHeight: '24px' }}>
              {line}
              <br />
            </span>
          ))}
        />
      )
    case 'named_list':
      return (
        <Structure
          identifier={identifier}
          block
          data={content.map((item, i) => (
            <div key={i} style={{ marginBottom: 12 }}>
              {item.name && <MuiChip label={item.name} $tiny />}
              {item.value && <span className="bold">{item.value}</span>}
            </div>
          ))}
        />
      )
    case 'namedBoolean':
      return (
        <Structure
          identifier={identifier}
          data={
            <MuiChip
              label={content.label.toUpperCase()}
              $tiny
              status={content.value.toString()}
            />
          }
        />
      )
    case 'text':
      return <Structure identifier={identifier} data={content?.toString()} />
  }
}

export default InfoItem
