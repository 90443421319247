import * as React from 'react'

function SvgSmsBlast(props) {
  return (
    <svg width={48} height={49} viewBox={"0 0 48 49"} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        opacity={0.3}
        d="M9.75 42c-.48 0-.958-.192-1.342-.575l-3.833-3.833c-.767-.767-.767-1.917 0-2.684.767-.766 2.013-.766 2.683 0L9.75 37.4l6.325-6.325c.767-.767 1.917-.767 2.683 0 .767.767.767 1.917 0 2.683l-7.666 7.667A1.892 1.892 0 019.75 42z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 6.5a3 3 0 00-3 3v14.73a14.935 14.935 0 017-1.73c7.948 0 14.453 6.182 14.967 14H36.05l6.293 6.293c.63.63 1.707.184 1.707-.707V30.5l-.05.02V9.5a3 3 0 00-3-3H7zm4 8a1 1 0 00-1 1v2a1 1 0 001 1h20a1 1 0 001-1v-2a1 1 0 00-1-1H11z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgSmsBlast
