export default function useSections(discardedAt, widgets) {
  const baseItems = [{ name: 'Summary', tag: 'summary' }]

  if (!discardedAt) {
    baseItems.push({ name: 'Custom fields', tag: 'custom_fields' })

    if (widgets.includes('activate')) {
      baseItems.push({ name: 'Activate', tag: 'activate' })
    }

    if (widgets.includes('donations')) {
      baseItems.push({ name: 'Donations', tag: 'donations' })
    }

    if (widgets.includes('verify')) {
      baseItems.push({ name: 'Verify', tag: 'verify' })
    }

    baseItems.push({ name: 'Activity', tag: 'activity' })
  }

  return baseItems
}
