const COUNTABLE_KIND = {
  'sms_blast': 'textableTargets',
  'sms_survey': 'textableTargets',
  'sms_conversations': 'textableTargets',
  'whatsapp': 'whatsappableTargets',
  'phone': 'callableTargets',
  'email': 'emailableTargets'
}

export default function useCountTargets() {
  return useSel(s => {
    const { search, campaignType, textMessageBlast, subscriptionTopic } = s.campaign
    const kind = textMessageBlast?.kind === 'whatsapp' ? 'whatsapp' : campaignType

    const column = COUNTABLE_KIND[kind]
    if (!column || !search?.stats) return null

    const topics = search.stats[column]
    if (!topics) return null

    return topics[_.camelCase(subscriptionTopic)] || 0
  })
};
