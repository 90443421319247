import restClient from 'Shared/hooks/restClient'
import { Device, PreflightTest } from '@twilio/voice-sdk'

export default function twilioManager({ onConnected, onError, onDeviceError }) {
  let device
  let avDevices
  let call
  let locked = false

  async function connect() {
    if (locked) return

    locked = true

    if (window.ENV.CYPRESS) {
      console.info('Skipping Twilio connection in Cypress')
      applyDevices()
      onConnected()
      return Promise.resolve()
    }
    const token = await getToken()

    if (device) {
      device.disconnectAll()
    }
    device = new Device(token, {
      logLevel:1,
      edges: ['dublin', 'frankfurt'],
      codecPreferences: ["opus", "pcmu"]
    })
    device.on('error', (error) => {
      handleFail(onError, error, 'deviceError')
    })
    startRefreshToken()

    console.debug('Available devices', {
      input: device.audio.availableInputDevices,
      output: device.audio.availableOutputDevices,
      audio: device.audio,
      avDevices: avDevices,
    })

    return device
      .connect()
      .then((c) => {
        applyDevices()
        onConnected()
        call = c
        call.on('disconnect', () => {
          locked = false
        })
        call.on('error', (error) => {
          handleFail(onError, error, 'callError')
        })
      })
      .catch((error) => handleFail(onError, error, 'deviceConnectError'))
  }

  function disconnect() {
    device.disconnectAll()
  }

  function handleFail(fn, error, source) {
    if(device) {
      console.debug('Available devices', {
        input: device.audio.availableInputDevices,
        output: device.audio.availableOutputDevices,
        avDevices: avDevices,
      })
    }
    console.error('Twilio error', error, source)

    locked = false
    fn(error)
    disconnect()
  }

  async function applyDevices(attempt = 1) {
    if (!device) return

    const inputDevice = avDevices.filter((d) => d.kind === 'audioinput')[0]
    const outputDevice = avDevices.filter((d) => d.kind === 'audiooutput')[0]

    try {
      await navigator.mediaDevices.getUserMedia({ audio: true }); // In Safari the fact we have already done this to get microphone permissions seems to be ignored.

      await device.audio.setInputDevice(inputDevice.deviceId || 'default')
      if (outputDevice) {
        device.audio.speakerDevices.set(outputDevice.deviceId)
      }
    } catch (error) {
      if (attempt <= 4) {
        console.log(`Retry after attempt ${attempt}`)
        console.log(error)
        setTimeout(() => applyDevices(attempt + 1), attempt * 500)
      } else {
        if (error.name === 'InvalidArgumentError' && error.message.includes('Device not found')) {
          console.warn("Failed to apply chosen devices, likely due to lack of browser support", error)
        } else {
          handleFail(onDeviceError, error, 'applyDevices')
        }
      }
    }
  }

  function setDevices(devices) {
    avDevices = devices
    applyDevices()
  }

  async function startRefreshToken() {
    const ttl = 600000 // 10 minutes
    const refreshBuffer = 30000 // 30 seconds

    setInterval(async () => {
      const newToken = await getToken()
      device.updateToken(newToken)
    }, ttl - refreshBuffer)
  }

  async function getToken() {
    const res = await restClient.get('/api/voice/twilio/token').catch((error) => {
      handleFail(onError, error, 'getToken')
    })

    return res.data.token
  }

  function hangUp() {
    call.sendDigits('*')
  }

  function sendDigit(digit) {
    call.sendDigits(digit)
  }

  return {
    connect,
    disconnect,
    hangUp,
    sendDigit,
    setDevices,
  }
}
