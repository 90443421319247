export default function TextMessageContentTabs({ showDelete = false }) {
  const act = useAct()
  const contentVariants = useSel(s => _.orderBy(s.campaign.textMessageBlast.contentVariants, 'id'))
  const currentContentVariantId = useSel(s => s.local.currentContentVariantId)

  if (contentVariants.length > 0 && !contentVariants.find(cv => cv.id === currentContentVariantId)) {
    act.local.upsert({ currentContentVariantId: contentVariants[0].id })
  }

  function deleteVariant(e, id) {
    e.stopPropagation()
    act.campaign.deleteTextMessageBlastContentVariant(id)
  }

  function setCurrentVariant(id) {
    act.local.upsert({ currentContentVariantId: id })
  }

  if (contentVariants.length <= 1) return null

  return <div className='tabs auto-height margin-bottom'>
    { contentVariants.map((variant, i) => <div
      className={`tab ${variant.id === currentContentVariantId ? 'selected' : ''}`}
      key={variant.id}
      label={`Version ${i + 1}`}
      onClick={() => setCurrentVariant(variant.id)}
      ><div>
        <span>{ variant.name ? variant.name : `Version ${i+1}` }</span>
        { showDelete && <SvgIconsDelete className='action-icon' onClick={e => deleteVariant(e, variant.id)} /> }
      </div></div>)
    }
  </div>
}
