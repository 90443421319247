import ButtonGroupField from "Shared/components/ButtonGroupField"

const imageAlignOptions = [
  {
    value: 'left',
    label: <SvgIconsAlignImageLeft />,
  },
  {
    value: 'center',
    label: <SvgIconsAlignImageCenter />,
  },
  {
    value: 'right',
    label: <SvgIconsAlignImageRight />,
  },
]

const textAlignOptions = [
  {
    value: 'left',
    label: <SvgIconsAlignLeft />,
  },
  {
    value: 'center',
    label: <SvgIconsAlignCenter />,
  },
  {
    value: 'right',
    label: <SvgIconsAlignRight />,
  },
]

const AlignField = ({ value, onChange, label, type = 'text', ...rest }) => (
  <ButtonGroupField options={type === 'image' ? imageAlignOptions : textAlignOptions} value={value} onChange={onChange} label={label} {...rest} />
)

export default AlignField
