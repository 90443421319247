export default function Link(props) {
    return (
        <svg
            width={14}
            height={8}
            viewBox="0 0 14 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M6.33337 7.33317H3.66671C2.78265 7.33317 1.93481 6.98198 1.30968 6.35686C0.684563 5.73174 0.333374 4.88389 0.333374 3.99984C0.333374 3.11578 0.684563 2.26794 1.30968 1.64281C1.93481 1.01769 2.78265 0.666504 3.66671 0.666504H6.33337V1.99984H3.66671C3.13627 1.99984 2.62757 2.21055 2.25249 2.58562C1.87742 2.9607 1.66671 3.4694 1.66671 3.99984C1.66671 4.53027 1.87742 5.03898 2.25249 5.41405C2.62757 5.78912 3.13627 5.99984 3.66671 5.99984H6.33337V7.33317ZM10.3334 0.666504H7.66671V1.99984H10.3334C10.8638 1.99984 11.3725 2.21055 11.7476 2.58562C12.1227 2.9607 12.3334 3.4694 12.3334 3.99984C12.3334 4.53027 12.1227 5.03898 11.7476 5.41405C11.3725 5.78912 10.8638 5.99984 10.3334 5.99984H7.66671V7.33317H10.3334C11.2174 7.33317 12.0653 6.98198 12.6904 6.35686C13.3155 5.73174 13.6667 4.88389 13.6667 3.99984C13.6667 3.11578 13.3155 2.26794 12.6904 1.64281C12.0653 1.01769 11.2174 0.666504 10.3334 0.666504ZM9.66671 3.33317H4.33337V4.6665H9.66671V3.33317Z"
                fill="currentColor"
            />
        </svg>
    )
}
