import TextField from "Shared/components/TextField"
import validator from 'validator'
import restClient from "Shared/hooks/restClient"

const CreateVerifiedEmail = ({onCreated}) => {
  const [email, setEmail] = useState('')
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const handleEmailChange = (value) => {
    setEmail(value)
    setErrorMessage('') 
  }

  const reset = () => {
    handleEmailChange('')
  }

  const createVerifiedEmail = () => {
    setLoading(true)
    restClient.post('/verified_emails', { email }).then((res) => {
      if (res.data.verifiedEmail && res.data.status !== 'error') {
        setLoading(false)
        onCreated(res.data.verifiedEmail)
      } 
      if(res.data.status === 'error') {
        setErrorMessage(res.data.error)
        setLoading(false)
      }
    }).catch(reset)
  }

  return (
    <>
      <TextField
        label="Email address"
        id="email-address-input"
        value={email}
        onChange={handleEmailChange}
        disabled={loading}
        validators={[{ fn: validator.isEmail, msg: 'Invalid email address' }]} />
      {errorMessage && <div className='margin-bottom'><span className="error">{errorMessage}</span></div> }
      <div className="field">
        <button className="button primary" onClick={createVerifiedEmail} disabled={loading}>
          Verify Email
        </button>
      </div>
    </>
  )
}

export default CreateVerifiedEmail
