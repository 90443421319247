import './StatV2.scoped.scss'

export default function Stat({
  label,
  value,
}) {

  return <div className="stat-box">
    <h3>
      { Number.isInteger(value) ? value.toLocaleString() : value }
    </h3>
    <p className='label'>{ label }</p>
  </div>
}

