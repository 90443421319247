import $ from 'jquery'
import { v4 as uuid } from 'uuid'

export function replaceNodeWithReactComponent(element, reactComponent) {
  const parent = document.createElement('div')
  ReactDOM.render(reactComponent, parent, () => {
    element.replaceWith(...Array.from(parent.childNodes))
  })
}

export default (identifier, Component, replaceNode = true) => {
  $(document).ready(() => {
    var els = document.querySelectorAll(`[data-react=${identifier}]`)
    els.forEach((el) => {
      const identifier = uuid()
      const Tagname = el.tagName.toLowerCase()
      const component = (
        <ErrorBoundary>
          <Tagname id={identifier}>
            <Component {...JSON.parse(el.dataset.props)} />
          </Tagname>
        </ErrorBoundary>
      )

      if (replaceNode) {
        replaceNodeWithReactComponent(el, component)
      } else {
        ReactDOM.render(component, el)
      }
    })
  })
}
