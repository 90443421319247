const columns = [
  {
    data: 'name',
    className: 'bold',
    width: '50%',
    render: function (data, type, row, meta) {
      return `<a href='${row['url']}'>${data}</a>`
    },
  },
  {
    data: 'target_count',
    width: '20%',
    render: function (data, type, row, meta) {
      const count = Number(row['target_count']).toLocaleString()
      return `<span>${count}</span>`
    }
  },
  { data: 'created_at', width: '20%'},
  {
    data: 'id',
    className: 'right',
    width: '10%',
    discarded: 'discarded',
    render: (data, type, row) => {
      return row.discarded === 'true'
        ? `<button data-list-id="${data}" class="button small green restore-list" type="button">Restore</button>`
        : `<button data-list-id="${data}" class="button small red remove-list" type="button">Archive</button>`
    }
  }
]

const headers = [
  'Name',
  'Number of people',
  'Created',
  ''
]

const filters = [
  {
    name: 'Show archived only', customParam: 'show_archived', kind: 'radio', options: [
      { value: 1, label: 'Yes' },
    ]
  },
]

const sorters = [
  { column: 2, label: 'Created', order: 'desc' },
  { column: 0, label: 'Alphabetically (A-Z)', order: 'asc' },
  { column: 0, label: 'Alphabetically (Z-A)', order: 'desc' }
]

const order = [[ 2, "desc" ]]

export { columns, headers, filters, sorters, order }
