import Fade from '@material-ui/core/Fade'
import Avatar from 'boring-avatars'

const formatMembershipStatus = (status) => {
  if (status === 'active') {
    return 'Current member'
  }
  if (status === 'inactive') {
    return 'Ex-member'
  }
  return 'Unknown'
}

const BasicInfo = () => {
  const target = useSel((s) => s.target)
  const hasPositions = !_.isEmpty(target.positions)
  const isMember = target.membershipStatus !== 'supporter'

  if (!target) {
    return null
  }

  return (
    <Fade in={!_.isEmpty(target)}>
      <div className="box basic-info full-height">
        <Grid item className="section-top flex-items-center">
          <Avatar
            size={64}
            name={target.name}
            variant="marble"
            colors={['#92A1C6', '#146A7C', '#F0AB3D', '#C271B4', '#C20D90']}
          />

          <h1 className="section-head text-center half margin-bottom">{target.name}</h1>
          {hasPositions &&
            target.positions.map((position, idx) => {
              if (idx === 0)
                return (
                  <h3 key={position} className="h3 text-center dark-grey no-margin">
                    {position}
                  </h3>
                )
              else
                return (
                  <h4 key={position} className="no-margin">{`${position}${
                    idx === target.positions.length - 2 ? ',' : ''
                  }`}</h4>
                )
            })}
          {target.externalId && (
            <p className="margin-top double">
              <span>ID </span>
              <b>{target.externalId}</b>
            </p>
          )}
          {target.membershipStatus && (
            <p className="margin-top double no-margin-bottom">
              {isMember && (
                <>
                  <span>Membership status </span>
                  <b>{formatMembershipStatus(target.membershipStatus)}</b>
                </>
              )}
              {!isMember && (
                <>
                  <b>Supporter (non-member)</b>
                </>
              )}
            </p>
          )}
        </Grid>
        {target.joined && (
          <Grid item>
            <div
              className="divider"
              style={{ marginLeft: 16, marginRight: 16, width: 'auto' }}
            />

            <h4 className="full-width text-center no-margin">Joined</h4>
            <p className="text-center no-margin-bottom">{target.joined}</p>
          </Grid>
        )}
      </div>
    </Fade>
  )
}

export default BasicInfo
