const whatsappAdvanced = window.ENV.FEATURES['whatsapp_advanced'] || false

export default () => {
  const dis = useDis()
  const type = useSel(state => state.createCampaign.type)
  const enableSurvey = type !== 'whatsapp' || whatsappAdvanced
  const enableConversations = type !== 'whatsapp' || whatsappAdvanced

  function setType(type) {
    dis({ type: 'createCampaign/upsert', payload: { type, step: 'name' }})
  }

  return <div className='row'>
    <div className='column third'>
      <a className='card large selectable' onClick={() => setType('sms_blast')}>
        <div className='icon large purple'><SvgIconsSmsBlast /></div>

        <p className='title'>One way blast</p>
        <p className='description'>Send a personalisable broadcast message.</p>
      </a>
    </div>
    <div className='column third'>
      <a
        className={`card large selectable ${enableSurvey ? '' : 'disabled'}`}
        onClick={() => setType('sms_survey')}
      >
        <div className='icon large purple'><SvgIconsSurvey /></div>

        <p className='title'>Survey</p>
        <p className='description'>Create questions and collect information from you audience.</p>
      </a>
    </div>
    <div className='column third'>
      <a
        className={`card large selectable ${enableConversations ? '' : 'disabled'}`}
        onClick={() => setType('sms_conversations')}
      >
        <div className='icon large purple'><SvgIconsConversations /></div>

        <p className='title'>Conversations</p>
        <p className='description'>Send messages your audience can reply to and respond back to them</p>
      </a>
    </div>
  </div>
}
