import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import './css/IntervalInput.scoped.scss'

const options = [
  { value: 'minutes', label: 'minutes', factor: 60 },
  { value: 'hours', label: 'hours', factor: 3600 },
  { value: 'days', label: 'days', factor: 86400 },
];

export default function IntervalInput({ value, onChange }) {
  const [amount, setAmount] = useState(0);
  const [unit, setUnit] = useState(options[0]);

  const convertSecondsToUnit = (seconds) => {
    let selectedUnit = options[0];
    for (let i = options.length - 1; i >= 0; i--) {
      if (seconds % options[i].factor === 0) {
        selectedUnit = options[i];
        break;
      }
    }
    setAmount(seconds / selectedUnit.factor);
    setUnit(selectedUnit);
  };

  useEffect(() => {
    convertSecondsToUnit(value);
  }, []);

  const handleAmountChange = (e) => {
    const newAmount = e.target.value;
    setAmount(newAmount);
    onChange(newAmount * unit.factor);
  };

  const handleUnitChange = (selectedOption) => {
    setUnit(selectedOption);
    onChange(Math.round(amount * selectedOption.factor));
  };

  return (
    <div className="interval-input-container">
      <input type="number" value={amount} onChange={handleAmountChange} />
      <div className='unit-control'>
        <Select value={unit} onChange={handleUnitChange} options={options} />
      </div>
    </div>
  );
}
