import { useStore } from 'react-redux'
import tutorial from 'Interface/Tutorial/hooks/tutorial'
import restClient from 'Shared/hooks/restClient'

const actionSlices = {
  tutorial,
}

export default () => {
  const dis = useDis()
  const store = useStore()

  return useMemo(
    () =>
      _.mapValues(actionSlices, (fn) => {
        return fn(dis, store, restClient)
      }),
    []
  )
}
