import LinkFields from '../../LinkFields'
import PillEditor from 'Shared/components/PillEditor/PillEditor'
import ColorField from 'Shared/components/ColorField/ColorField'
import Divider from 'Shared/components/Divider'

const ButtonBlockProperties = ({ block }) => {
  const act = useAct()
  const { mergeTags } = useSel((s) => s.campaignBuilder)

  const handleColorChange = (col) =>
    act.email.blocks.updateParameters(block.id, { backgroundColor: col })
  const handleTextColorChange = (col) =>
    act.email.blocks.updateParameters(block.id, { textColor: col })
  const handleAttributeChange = (attr) => (value) =>
    act.email.blocks.updateParameters(block.id, { [attr]: value })
  const updateParameters = (parameters) => 
    act.email.blocks.updateParameters(block.id, { ...block.parameters, ...parameters})

  return (
    <form>
      <h3 className="no-margin-top">Edit button</h3>
      <div className="field">
        <PillEditor
          singleLine
          condensed
          initialValue={block.parameters.text}
          onChange={handleAttributeChange('text')}
          availableTags={mergeTags}
        />
      </div>
      <LinkFields parameters={block.parameters} onChange={updateParameters} />
      <Divider />
      <ColorField
        color={block.parameters.backgroundColor}
        onChange={handleColorChange}
        label={'Background colour'}
      />
      <ColorField
        color={block.parameters.textColor || '#FFFFFF'}
        onChange={handleTextColorChange}
        label={'Text colour'}
      />
    </form>
  )
}

export default ButtonBlockProperties
