import store from 'Interface/Tutorial/store'
import { Provider } from 'react-redux'
import Modal from './Modal'

const Tutorial = ({ tutorial }) => {
  return (
    <Provider store={store}>
      <ErrorBoundary>
        <Modal tutorial={tutorial} />
      </ErrorBoundary>
    </Provider>
  )
}

export default Tutorial
