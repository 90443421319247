function makePlaceHolderNode(fromId, toId) {
  return {
    id: `placeholder_${fromId}_${toId}`,
    position: { x: 0, y: 0 },
    data: {},
    type: 'placeholder'
  }
}

function makeEdge(fromId, toId, readOnly = false) {
  return {
    source: String(fromId),
    target: String(toId),
    type: 'smoothstep',
    animated: true,
    data: { readOnly },
    id: `${fromId}_${toId}`,
  }
}

// this function builds a graph from a workflow and workflowNodes
// if readOnly is specified, placeholders won't be added between nodes
export default function buildGraph({ workflow, workflowNodes, nodeSizes, readOnly = false }) {
  function connectFollowOnNode(fromNodeId, toNodeId) {
    if (readOnly) {
      if (toNodeId) {
        edges.push(makeEdge(fromNodeId, toNodeId, readOnly))
      }
    } else {
      const placeholderNode = makePlaceHolderNode(fromNodeId, toNodeId)
      nodes.push(placeholderNode)
      edges.push(makeEdge(fromNodeId, placeholderNode.id, readOnly))

      if (toNodeId) {
        edges.push(makeEdge(placeholderNode.id, toNodeId, readOnly))
      }
    }
  }

  const nodes = [
    { id: 'start', position: { x: 0, y: 0 }, data: { label: 'Start of workflow' }, type: 'start' }
  ]
  const edges = []
  const branchNodeIds = [] // we need to keep track of these to ensure they are laid out in the correct order

  connectFollowOnNode('start', workflow.firstWorkflowNodeId)

  // Append the other nodes and edges
  workflowNodes.forEach(n => {
    nodes.push({
      id: String(n.id),
      data: { ...n.properties, ...{ readOnly } },
      type: _.camelCase(n.kind)
    })

    if (n.kind === 'exit') return;

    if (n.kind === 'decision') {
      // make a node for each branch
      const numBranches = n.properties.branches.length
      n.properties.branches.forEach((branch, i) => {
        const branchNodeId = `${n.id}_branch_${branch.branchId}`
        branchNodeIds.push(branchNodeId)
        nodes.push({
          id: branchNodeId,
          data: { ...branch, readOnly, isFirst: i === 0, isLast: i === numBranches - 1 },
          type: 'branch'
        })

        // connect the decision node to it
        edges.push(makeEdge(n.id, branchNodeId, readOnly))

        // connect each node to its follow-on node, with placeholder if needed
        connectFollowOnNode(branchNodeId, branch.workflowNodeId)
      })

      // Add placeholder for adding more branches
      if (!readOnly) {
        const placeholderBranchId = `${n.id}_branch_placeholder`
        branchNodeIds.push(placeholderBranchId)
        nodes.push({
          id: placeholderBranchId,
          data: { ...{ readOnly }},
          type: 'branchPlaceholder'
        })
        edges.push(makeEdge(n.id, placeholderBranchId, readOnly))
      }

      // Add the else node
      const elseBranchId = `${n.id}_branch_else`
      branchNodeIds.push(elseBranchId)
      nodes.push({
        id: elseBranchId,
        data: { ...{ readOnly }, else: true },
        type: 'branch'
      })
      edges.push(makeEdge(n.id, elseBranchId, readOnly))
      connectFollowOnNode(elseBranchId, n.properties.elseWorkflowNodeId)

      // TODO Add a node for creating more options
    } else {
      connectFollowOnNode(n.id, n.nextWorkflowNodeId)
    }
  })

  return autoLayout({ nodes, edges, nodeSizes, branchNodeIds, readOnly })
}

import Dagre from 'dagre';
import { connect } from 'formik'

function autoLayout({ nodes, edges, nodeSizes, branchNodeIds, readOnly }) {
  // Helper function to get node size
  function getNodeSize(nodeId) {
    return nodeSizes[nodeId] || { width: 400, height: 48 };
  }

  const g = new Dagre.graphlib.Graph().setDefaultEdgeLabel(() => ({}));
  g.setGraph({ rankdir: 'vertical' });

  nodes.forEach(node => {
    let size = getNodeSize(node.id);
    if (node.type === 'branch') {
      size = { ...size, height: size.height + 64 };
    } else if (readOnly) {
      size = { width: size.width + 48, height: size.height + 48 };
    }
    g.setNode(node.id, { width: size.width, height: size.height });
  })

  edges.forEach(edge => {
    g.setEdge(edge.source, edge.target);
  })

  Dagre.layout(g, {
    keepNodeOrder: true,
    nodeOrder: branchNodeIds // an array of nodes's ID.
  });

  return {
    nodes: nodes.map((node) => {
      return { ...node, position: g.node(node.id) }
    }),
    edges,
  };
}
