import { MessageCard } from './styled'
import MessageActions from './MessageActions'
import MessageIcon from './MessageIcon'
import useData from 'DesktopMessager/useData'
import './css/Message.scoped.scss'
import MessageBubble from './MessageBubble'
import { parseISO } from "date-fns"

const Message = ({ id, initialMessage }) => {
  const { direction, body, createdAt, userId, campaignTargetId, sentAt, extraInfo: { media } } = useSel(
    s => s.textMessages.entities[id]
  )
  const targetFirstName = useData(rs => rs.target.firstName)
  const targetLastName = useData(rs => rs.target.lastName)
  const targetName = targetLastName ? `${targetFirstName} ${targetLastName}` : targetFirstName;

  const userName = useSel(s => {
    const { firstName, lastName } = s.users.entities[userId] || {}

    return _.compact([firstName, lastName]).join(' ') || i18n.t('user.campaigns.text.no_name')
  })

  const clear = initialMessage && direction === 'outbound'

  return <MessageBubble
    initialMessage={initialMessage}
    senderName={direction === 'inbound' ? targetName : userName}
    direction={direction}
    date={parseISO(createdAt)}
    body={body}
    indent={direction === 'outbound' && !initialMessage}
    media = { media && media.map(item => <Media key={item.url} url={item.url} contentType={item.contentType} /> )}
  />
}

function Media({ url, contentType = '' }) {
  const kind = contentType.split('/')[0]

  function Content() {
    if (kind === 'image') {
      return <img src={url} />
    } else if (kind === 'video') {
      return <video controls>
        <source src={url} type={contentType} />
      </video>
    } else if (kind === 'audio') {
      return <audio controls>
        <source src={url} type={contentType} />
      </audio>
    }
  }

  return <div className='media'>
    <Content />
  </div>
}

export default Message
