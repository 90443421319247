export default function NoMoreCalls() {
  return <div className={`box`}>
    <div className='part'>
      <div className='type'><h2 className='no-margin-top'>{ i18n.t('user.campaigns.phone.no_more_calls') }</h2></div>
      <div className='content'>
        <p>{ i18n.t('user.campaigns.phone.no_more_calls_desc') }</p>
        <div className='buttons'>
          <a className='button primary' href="/">{ i18n.t('shared.messages.finish') }</a>
        </div>
      </div>
    </div>
  </div>
}
