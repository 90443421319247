import SafeRenderHtml from "./SafeRenderHtml"
import Stat from './Stat'
import restClient from "Shared/hooks/restClient"
import Context from "CampaignVolunteer/Context"
import OrganisationDescription from "./OrganisationDescription"

export default function Conversations() {
  const { state, dispatch, actions } = useContext(Context)
  const { campaign, organisationId, remainingCount } = state

  useEffect(() => {
    restClient.get(`/api/campaigns/${campaign.id}/targets/remaining`, { params: { organisationIds: _.compact([organisationId]) } })
      .then((response) => dispatch({ remainingCount: response.data.count }))
  }, [])

  const startUrl = `/campaigns/${campaign.slug}/text_message?organisation_ids[]=${organisationId || ''}`
  const disabled = !remainingCount
  
  return <>
    { remainingCount !== null && <Stat icon={<SvgIconsSms />} number={remainingCount} label={i18n.t('user.campaigns.text.conversations_remaining')} /> }

    <OrganisationDescription />
    
    <p className="no-margin-bottom">
      <SafeRenderHtml html={campaign.instructions} />
    </p>
    
    <div>
      { remainingCount === 0 && <p className='bold'>{ i18n.t('user.campaigns.text.no_reply') }</p> }

      { remainingCount && <a className={`primary button margin-top ${disabled ? 'disabled' : ''}`} href={startUrl}>{ i18n.t('user.campaigns.text.start_messaging') }</a> }     

      { organisationId && <div className='margin-top'>
        <a className="naked button margin-top" onClick={actions.reset}>Go Back</a>
      </div> }
    </div>
  </>
}
