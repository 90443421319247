const smartEncodingMap = {
  u00ab: '"',
  u00bb: '"',
  u201c: '"',
  u201d: '"',
  u02ba: '"',
  u02ee: '"',
  u201f: '"',
  u275d: '"',
  u275e: '"',
  u301d: '"',
  u301e: '"',
  uff02: '"',
  u2018: '\'',
  u2019: '\'',
  u02bb: '\'',
  u02c8: '\'',
  u02bc: '\'',
  u02bd: '\'',
  u02b9: '\'',
  u201b: '\'',
  uff07: '\'',
  u00b4: '\'',
  u02ca: '\'',
  u0060: '\'',
  u02cb: '\'',
  u275b: '\'',
  u275c: '\'',
  u0313: '\'',
  u0314: '\'',
  ufe10: '\'',
  ufe11: '\'',
  u00f7: '/',
  u00bc: '1/4',
  u00bd: '1/2',
  u00be: '3/4',
  u29f8: '/',
  u0337: '/',
  u0338: '/',
  u2044: '/',
  u2215: '/',
  uff0f: '/',
  u29f9: '\\',
  u29f5: '\\',
  u20e5: '\\',
  ufe68: '\\',
  uff3c: '\\',
  u0332: '_',
  uff3f: '_',
  u20d2: '|',
  u20d3: '|',
  u2223: '|',
  uff5c: '|',
  u23b8: '|',
  u23b9: '|',
  u23d0: '|',
  u239c: '|',
  u239f: '|',
  u23bc: '-',
  u23bd: '-',
  u2015: '-',
  ufe63: '-',
  uff0d: '-',
  u2010: '-',
  u2022: '-',
  u2043: '-',
  ufe6b: '@',
  uff20: '@',
  ufe69: '$',
  uff04: '$',
  u01c3: '!',
  ufe15: '!',
  ufe57: '!',
  uff01: '!',
  ufe5f: '#',
  uff03: '#',
  ufe6a: '%',
  uff05: '%',
  ufe60: '&',
  uff06: '&',
  u201a: ',',
  u0326: ',',
  ufe50: ',',
  u3001: ',',
  ufe51: ',',
  uff0c: ',',
  uff64: ',',
  u2768: '(',
  u276a: '(',
  ufe59: '(',
  uff08: '(',
  u27ee: '(',
  u2985: '(',
  u2769: ')',
  u276b: ')',
  ufe5a: ')',
  uff09: ')',
  u27ef: ')',
  u2986: ')',
  u204e: '*',
  u2217: '*',
  u229b: '*',
  u2722: '*',
  u2723: '*',
  u2724: '*',
  u2725: '*',
  u2731: '*',
  u2732: '*',
  u2733: '*',
  u273a: '*',
  u273b: '*',
  u273c: '*',
  u273d: '*',
  u2743: '*',
  u2749: '*',
  u274a: '*',
  u274b: '*',
  u29c6: '*',
  ufe61: '*',
  uff0a: '*',
  u02d6: '+',
  ufe62: '+',
  uff0b: '+',
  u3002: '.',
  ufe52: '.',
  uff0e: '.',
  uff61: '.',
  uff10: '0',
  uff11: '1',
  uff12: '2',
  uff13: '3',
  uff14: '4',
  uff15: '5',
  uff16: '6',
  uff17: '7',
  uff18: '8',
  uff19: '9',
  u02d0: ':',
  u02f8: ':',
  u2982: ':',
  ua789: ':',
  ufe13: ':',
  uff1a: ':',
  u204f: ';',
  ufe14: ';',
  ufe54: ';',
  uff1b: ';',
  ufe64: '<',
  uff1c: '<',
  u0347: '=',
  ua78a: '=',
  ufe66: '=',
  uff1d: '=',
  ufe65: '>',
  uff1e: '>',
  ufe16: '?',
  ufe56: '?',
  uff1f: '?',
  uff21: 'a',
  u1d00: 'a',
  uff22: 'b',
  u0299: 'b',
  uff23: 'c',
  u1d04: 'c',
  uff24: 'd',
  u1d05: 'd',
  uff25: 'e',
  u1d07: 'e',
  uff26: 'f',
  ua730: 'f',
  uff27: 'g',
  u0262: 'g',
  uff28: 'h',
  u029c: 'h',
  uff29: 'i',
  u026a: 'i',
  uff2a: 'j',
  u1d0a: 'j',
  uff2b: 'k',
  u1d0b: 'k',
  uff2c: 'l',
  u029f: 'l',
  uff2d: 'm',
  u1d0d: 'm',
  uff2e: 'n',
  u0274: 'n',
  uff2f: 'o',
  u1d0f: 'o',
  uff30: 'p',
  u1d18: 'p',
  uff31: 'q',
  uff32: 'r',
  u0280: 'r',
  uff33: 's',
  ua731: 's',
  uff34: 't',
  u1d1b: 't',
  uff35: 'u',
  u1d1c: 'u',
  uff36: 'v',
  u1d20: 'v',
  uff37: 'w',
  u1d21: 'w',
  uff38: 'x',
  uff39: 'y',
  u028f: 'y',
  uff3a: 'z',
  u1d22: 'z',
  u02c6: '^',
  u0302: '^',
  uff3e: '^',
  u1dcd: '^',
  u2774: '{',
  ufe5b: '{',
  uff5b: '{',
  u2775: '}',
  ufe5c: '}',
  uff5d: '}',
  uff3b: '[',
  uff3d: ']',
  u02dc: '~',
  u02f7: '~',
  u0303: '~',
  u0330: '~',
  u0334: '~',
  u223c: '~',
  uff5e: '~',
  u00a0: '\'',
  u2000: '\'',
  u2001: ' ',
  u2002: ' ',
  u2003: ' ',
  u2004: ' ',
  u2005: ' ',
  u2006: ' ',
  u2007: ' ',
  u2008: ' ',
  u2009: ' ',
  u200a: ' ',
  u200b: ' ',
  u202f: ' ',
  u205f: ' ',
  u3000: ' ',
  ufeff: ' ',
  u008d: ' ',
  u009f: ' ',
  u0080: ' ',
  u0090: ' ',
  u009b: ' ',
  u0010: ' ',
  u0009: ' ',
  u0000: ' ',
  u0003: ' ',
  u0004: ' ',
  u0017: ' ',
  u0019: ' ',
  u0011: ' ',
  u0012: ' ',
  u0013: ' ',
  u0014: ' ',
  u2017: '_',
  u2014: '-',
  u2013: '-',
  u201a: '\'',
  u202f: ' ',
  u2039: '>',
  u203a: '<',
  u203c: '!!',
  u201e: '"',
  u201d: '"',
  u201c: '"',
  u201b: '\'',
  u2026: '...',
  u2028: ' ',
  u2029: ' ',
  u205f: ' ',
}

export default smartEncodingMap
