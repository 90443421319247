import * as React from 'react'

function SvgUser(props) {
  return (
    <svg width="26" height="25" viewBox="0 0 26 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.9999 11.1111C9.93164 11.1111 7.44434 8.62381 7.44434 5.55556C7.44434 2.48731 9.93164 0 12.9999 0C16.0681 0 18.5554 2.48731 18.5554 5.55556C18.5554 8.62381 16.0681 11.1111 12.9999 11.1111Z" fill="#191936"/>
      <path d="M0.500905 23.8878C1.03925 17.2591 6.41932 13.8889 12.9768 13.8889C19.6267 13.8889 25.0901 17.0739 25.4971 23.8889C25.5133 24.1604 25.4971 25 24.4538 25C19.3071 25 11.6593 25 1.51042 25C1.1621 25 0.471585 24.2489 0.500905 23.8878Z" fill="#191936"/>
    </svg>
  )
}

export default SvgUser

