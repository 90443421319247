import './BugReport.scoped.scss'
import ServerWaitButton from 'Shared/components/ServerWaitButton'
import restClient from 'Shared/hooks/restClient'

const ReturnIcon = () => <div>ReturnIcon</div>
const BugIcon = () => <div>BugIcon</div>

export default function BugReport() {
  const [open, setOpen] = useState(false)
  const [text, setText] = useState('')
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(null)

  function handleSubmit() {
    setLoading(true)
    restClient.post('/api/bug_reports' , { text })
    .then(() => {
      setLoading(false)
      setOpen(false)
      setSuccess(true)
    })
    .catch(() => {
      setLoading(false)
      setOpen(false)
      setSuccess(false)
    })
  }

  function toggleOpen() {
    if (open) {
      setOpen(false)
    } else {
      setOpen(true)
      setSuccess(null)
      setLoading(false)
    }
  }

  return (
    <div>
      <div className="bug-report-button-container">
        <div className="bug-report-button">
          <a className='button secondary margin-top' title={open ? 'Cancel' : 'Report a bug' }  onClick={toggleOpen}>
            <div className="icon-container">
              { open ? <SvgIconsBack /> : <SvgIconsFlag /> }
            </div>
            <div className="title">{ open ? 'Cancel' : 'Report a bug' }</div>
          </a>
        </div>
      </div>
      { success === true && <div>Your bug report has been submitted - thank you!</div> }
      { success === false && <div>Something went wrong submitting the report - please contact support.</div> }
      {open && (
        <div className="message-box">
          <div className="form-group">
            <label>Describe your problem</label>
            <textarea
              value={text || ''}
              onChange={e => setText(e.target.value || '')}
              placeholder="I clicked a button but nothing happened..."
            />
          </div>
          <small>
            Please give as much information as possible so we can improve the platform.
          </small>
          <div className="form-group">
            <button
              className="button primary"
              disabled={!text || text.length === 0 || loading}
              onClick={handleSubmit}
            >
              Report
            </button>
          </div>
        </div>
      )}
    </div>
  )
}
