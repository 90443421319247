import ReactFlow, { useReactFlow, ProOptions, Controls } from 'reactflow';

// Custom node types
import StartNode from 'Workflows/components/Builder/Nodes/StartNode';
import DelayNode from 'Workflows/components/Builder/Nodes/DelayNode';
import AddToCampaignNode from 'Workflows/components/Builder/Nodes/AddToCampaignNode';
import DecisionNode from 'Workflows/components/Builder/Nodes/DecisionNode';
import ExitNode from 'Workflows/components/Builder/Nodes/ExitNode';
import PlaceholderNode from 'Workflows/components/Builder/Nodes/PlaceholderNode';
import BranchNode from 'Workflows/components/Builder/Nodes/BranchNode';
import BranchPlaceholderNode from 'Workflows/components/Builder/Nodes/BranchPlaceholderNode';

import buildGraph from 'Workflows/buildGraph';

const nodeTypes = {
  start: StartNode,
  delay: DelayNode,
  addToCampaign: AddToCampaignNode,
  decision: DecisionNode,
  exit: ExitNode,
  placeholder: PlaceholderNode,
  branch: BranchNode,
  branchPlaceholder: BranchPlaceholderNode,
};

// Custom edge types
import WorkflowEdge from './Edges/WorkflowEdge';

const edgeTypes = {
  workflow: WorkflowEdge
}

const proOptions: ProOptions = { account: 'paid-pro', hideAttribution: true }
const fitViewOptions = {
  padding: 0.95,
};

const useBuildGraph = ({ workflow, workflowNodes, nodeSizes, readOnly }) => {
  const [graph, setGraph] = useState({ nodes: [], edges: [] });
  const debounceTimerRef = useRef(null);

  useEffect(() => {
    // Clear existing timer if the effect is called before the timer completes
    if (debounceTimerRef.current) {
      clearTimeout(debounceTimerRef.current);
    }

    // Set up a new timer
    debounceTimerRef.current = setTimeout(() => {
      // Call buildGraph only after 100ms have passed without any changes to the dependencies
      const newGraph = buildGraph({ workflow, workflowNodes, nodeSizes, readOnly });
      setGraph(newGraph);
    }, 10);

    // Cleanup function to clear the timer if the component unmounts or dependencies update
    return () => clearTimeout(debounceTimerRef.current);
  }, [JSON.stringify([workflow, workflowNodes, nodeSizes, readOnly])]); // Dependencies array

  return graph;
};


const Editor = ({ readOnly = false }) => {
  const reactFlowInstance = useReactFlow()
  const { workflow, loaded } = useSel(s => s.builder)
  const { state } = workflow
  const workflowNodes = useSel(s => _.values(s.workflowNodes.entities))

  const nodeSizes = useSel(s => s.local.nodeSizes, _.isEqual)
  const { nodes, edges } = useBuildGraph({ workflow, workflowNodes, nodeSizes, readOnly: readOnly || (state !== 'draft' && state !== 'paused') })
  const currentNodeId = useSel(s => s.local.sidebar?.nodeId)
  const currentPlaceholderId = useSel(s => s.builder.openPlaceholderId)

  function center(id) {
    if (id && reactFlowInstance) {
     var node = null
     if (typeof id === 'string') {
      node = reactFlowInstance.getNodes().find(n => String(n.id) === String(id))
     } else {
      node = reactFlowInstance.getNodes().find(n => parseInt(n.id) === parseInt(id))
     }

     if(node) {
       const {x, y} = node.position
       reactFlowInstance.setCenter(x, y, { zoom: 0.75, duration: 1000})
     }
    }
  }

  useEffect(() => {
    center(currentNodeId)
  }, [currentNodeId])

  useEffect(() => {
    center(currentPlaceholderId)
  }, [currentPlaceholderId])

  return (
    <div style={{ width: '100%', height: '100%' }}>
      <ReactFlow
        nodes={nodes}
        edges={edges}
        proOptions={proOptions}
        fitView
        nodeTypes={nodeTypes}
        edgeTypes={edgeTypes}
        fitViewOptions={fitViewOptions}
        minZoom={ readOnly ? 0.1 : 0.075}
        maxZoom={ readOnly ? 1 : 2 }
        nodesDraggable={false}
        nodesConnectable={false}
        zoomOnDoubleClick={false}
        nodeOrigin={[0.5, 0.5]}
      >
        <Controls />
      </ReactFlow>
    </div>
  )
}

export default Editor
