import Button from 'Shared/components/Button'
import OrganisationPickerModal from './OrganisationPickerModal'

export default function OrganisationPickerButton({ onPick, title = 'Choose Organisation', disabled }) {
  const [open, setOpen] = useState(false)

  function handlePick(id, name) {
    onPick(id, name)
    setOpen(false)
  }

  return (
    <>
      <Button small primary disabled={disabled} onClick={() => setOpen(true)}>{title}</Button>
      <OrganisationPickerModal open={open} hide={() => setOpen(false)} onPick={handlePick} />
    </>
  )
}

