import store from 'Workflows/store'
import { Provider } from 'react-redux'
import Builder from './Builder/Builder'

const WorkflowBuilder = ({ workflowId }) => {
  return <Provider store={store}>
    <ErrorBoundary>
        <Builder workflowId={workflowId} />
    </ErrorBoundary>
  </Provider>
}

export default WorkflowBuilder
