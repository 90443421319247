import * as React from 'react'

function SvgPublishWhite(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} {...props}>
      <path fill="#fff" d="M5 4v2h14V4H5m0 10h4v6h6v-6h4l-7-7-7 7z" />
    </svg>
  )
}

export default SvgPublishWhite
