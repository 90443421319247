import * as React from 'react'

function SvgMicrophoneSettings(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} {...props}>
      <path
        fill="#2849D6"
        d="M19 10h-1.7c0 3-2.54 5.1-5.3 5.1S6.7 13 6.7 10H5c0 3.41 2.72 6.23 6 6.72V20h2v-3.28c3.28-.49 6-3.31 6-6.72m-4 14h2v-2h-2m-4 2h2v-2h-2m1-9a3 3 0 003-3V4a3 3 0 00-3-3 3 3 0 00-3 3v6a3 3 0 003 3M7 24h2v-2H7v2z"
      />
    </svg>
  )
}

export default SvgMicrophoneSettings
