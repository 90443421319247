import BackdropModal from 'Shared/components/BackdropModal/BackdropModal'

function RefreshTargets({ campaignId, open, close }) {
  const act = useAct()
  const [state, setState] = useState('loading_preview')
  const [added, setAdded] = useState(0)
  const [removed, setRemoved] = useState(0)

  function poll(next) {
    act.campaign.targetsStatus(campaignId).then(({ data }) => {
      if (data.status === 'refreshing') {
        setTimeout(() => {
          poll(next)
        }, 1000)
      } else if (data.status === 'ready') {
        setAdded(data.result.added)
        setRemoved(data.result.removed)
        setState(next)
      }
    })
  }

  function commit() {
    setState('syncing')
    act.campaign.refreshTargets(campaignId, false).then(({ data }) => {
      poll('done')
    })
  }

  useEffect(() => {
    act.campaign.refreshTargets(campaignId, true).then(({ data }) => {
      poll('preview')
    })
  }, [])

  const buttonDisabled = added === 0 && removed === 0

  return <BackdropModal title="Refresh audience" onCancel={close} open={open}>
    <div className='wizard'>
      <div className='step'>
        {state === 'loading_preview' && <>
          <h3>Calculating changes</h3>
          <p>We're just working out how many people will be added to, and removed from this campaign.</p>
        </>}
        {state === 'preview' && <>
          <h3>Here's what will happen</h3>
          <p className='bold'>{added} people will be added to this campaign</p>
          <p className='bold'>{removed} people will be removed from this campaign</p>
          <button className='button primary no-margin-left' onClick={commit} disabled={buttonDisabled}>Continue</button>
        </>}
        {state === 'syncing' && <>
          <h3>Your campaign is being updated</h3>
        </>}
        {state === 'done' && <>
          <h3>All done</h3>
          <button onClick={close} className='primary button no-margin-left'>Back to my campaign</button>
        </>}
      </div>
    </div>
  </BackdropModal>
}

export default React.memo(RefreshTargets)
