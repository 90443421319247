import QuestionPhonePreview from 'Shared/components/QuestionPhonePreview'

export default function QuestionPreview({ questionId }) {
  const { question, questionType, settings } = useSel(s => s.questions.entities[questionId])
  const answers = useSel(s => _.orderBy(_.values(s.answers.entities).filter(a => a.questionId === questionId), 'digitOrder'))
  const stepType = questionType == 'instruction' ? 'instruction' : 'question'

  return <div>
    <p className="no-margin-top">{question}</p>
    {stepType === 'question' &&
      <div className='answers'>
        {answers.map(({ answer }, index) => <div key={index} className='answer'>
          <div className='fake-radio'></div> <p>{answer}</p>
        </div>)}
        {questionType === 'text' && <div className='fake-input'></div>}
      </div>
    }
    {questionType === 'score' &&
      <div className='score'>
        <div className='fake-radio'>
          <span className='bold'>{settings.min}</span> - <span className='bold'>{settings.max}</span> (default: <span className='bold'>{settings.default}</span>)
        </div>
      </div>
    }
    {questionType === 'send_sms' && <QuestionPhonePreview questionId={questionId} />}
  </div>
}
