import useActions from 'Interface/UserInfo/useActions'
import ProfilePic from './ProfilePic'
import { Popover } from '@varld/popover'

export default ({ organisation, logoutLink, profilePicUrl }) => {
  useActions()

  const { globalAdmin, masquerading, firstName, lastName } = useSel(
    (s) => s.currentUser,
    _.isEqual
  )
  const name = [String(firstName), String(lastName)].join(' ')
  const initial = name[0].toUpperCase()

  function stopMasquerading() {
    fetch(`/api/users/stop_masquerading_path`)
      .then((response) => response.json())
      .then((data) => (window.location = data.path))
      .catch((e) => {
        console.log(e)
      })
  }

  return (
    <div className="user">
      <Popover
        popover={({ close }) => {
          return (
            <div className='popup'>
            <div className="list">
              <div className="item large">
                <div className="left">
                  <ProfilePic
                    initial={initial}
                    profilePicUrl={profilePicUrl}
                    name={name}
                  />
                </div>
                <div className="details">
                  <div className="primary">
                    <p>{name}</p>
                  </div>
                  <div className="secondary">
                    <p>{organisation}</p>
                  </div>
                </div>
              </div>
              <div className="item">
                <SvgIconsEdit />
                <a className="black-link margin-left" href={`/admin/profile/edit`}>
                  Edit profile
                </a>
              </div>
              {masquerading && (
                <div className="item">
                  <SvgIconsLogout />
                  <a className="black-link margin-left" onClick={stopMasquerading}>
                    Stop Masquerading
                  </a>
                </div>
              )}
              {globalAdmin && (
                <div className="item">
                  <SvgIconsLogout />
                  <a className="black-link margin-left" href={`/global_admin`}>
                    Global Admin
                  </a>
                </div>
              )}
              <div className="item">
                <SvgIconsLogout />
                <a className="black-link margin-left" href={logoutLink}>
                  Logout
                </a>
              </div>
            </div>
            </div>
          )
        }}
      >
        <ProfilePic initial={initial} profilePicUrl={profilePicUrl} name={name} />
      </Popover>
    </div>
  )
}
