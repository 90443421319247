import useAct from 'Workflows/hooks/useAct'
import EditBranch from 'Workflows/components/Builder/Forms/EditBranch'

export default function BranchSidebar() {
  const act = useAct()
  const { nodeId, branchId } = useSel(s => s.local.sidebar)
  const state = useSel(s => s.builder.workflow.state)
  const properties = useSel(s => s.workflowNodes.entities[nodeId].properties.branches.find(b => b.branchId === branchId))

  if (!properties) return null
  const canDelete = !properties['workflowNodeId'] && (state === 'draft' || state === 'paused')

  const handleDelete = () => {
    return act.local.openModal({ kind: 'deleteBranchConfirm', nodeId, branchId })
  }

  const editRules = () => {
    act.local.editBranchRules({ nodeId, branchId })
  }

  const readOnly = !(state === 'draft' || state === 'paused')

  return <div className='box workflow-sidebar'>
    <h3 className='no-margin-top'>Branch</h3>
    <EditBranch nodeId={nodeId} branchId={branchId} key={`${nodeId}-${branchId}`} />
    <div className='margin-top'>
      <a className='link dark-grey' onClick={editRules}>Edit rules</a>
    </div>
    { !readOnly && <>
      { canDelete && <div className='margin-top'>
        <a className='link dark-grey' onClick={handleDelete}>Delete</a>
      </div> }
      { !canDelete && <div className='margin-top'>
        <span className='dark-grey'>You can only delete a branch with no nodes</span>
      </div> }
    </> }
  </div>
}
