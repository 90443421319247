import './summary-table.scoped.scss'

export default function SmsTable({ data }) {
  if (data.length === 0) {
    return <div className="empty-table"> No emails sent. </div>
  }

  return (
    <table>
      <thead>
        <tr>
          <th>Campaign</th>
          <th>Messages</th>
          <th>Cost</th>
        </tr>
      </thead>
      <tbody>
        {data.map((campaign) => (
          <tr>
            <td>
              <a
                href={`/admin/campaigns/${campaign.campaignId}/overview`}
                target="_blank"
                rel="noreferrer"
              >
                {campaign.name}
              </a>
            </td>
            <td>{campaign.messagesCount}</td>
            <td>${campaign.charge.toFixed(2)}</td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}
