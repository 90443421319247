import ManageActions from 'Shared/components/ManageMenu/ManageActions'

const Controls = () => {
  const target = useSel((s) => s.target)

  return (
    <>
      <div className="header margin-bottom double">
        <a className="back" onClick={() => window.history.back()}></a>
        <h1>{target.name}</h1>
        <ManageActions type="target" target={target} />
      </div>
    </>
  )
}

export default Controls
