const STAR_LABELS = {
  1: 'Very bad',
  2: 'Bad',
  3: 'Average',
  4: 'Good',
  5: 'Very good'
}

export default function StarRating({ callback }) {
  const [state, setState] = useState({ star: 0 })
  const rating = state.star >= 1 ? STAR_LABELS[state.star] : ''

  function onChange(star) {
    setState({ star })
    callback(star)
  }

  return <div className='star-rating'>
    <div className='stars'>
      <a className='star' onClick={() => onChange(1)}>{state.star >= 1 ? <SvgIconsStar />: <SvgIconsStarEmpty />}</a>
      <a className='star' onClick={() => onChange(2)}>{state.star >= 2 ? <SvgIconsStar />: <SvgIconsStarEmpty />}</a>
      <a className='star' onClick={() => onChange(3)}>{state.star >= 3 ? <SvgIconsStar />: <SvgIconsStarEmpty />}</a>
      <a className='star' onClick={() => onChange(4)}>{state.star >= 4 ? <SvgIconsStar />: <SvgIconsStarEmpty />}</a>
      <a className='star' onClick={() => onChange(5)}>{state.star >= 5 ? <SvgIconsStar />: <SvgIconsStarEmpty />}</a>
    </div>
    <p>{rating}</p>
  </div>
}
