import Table from "Shared/components/DataTable/Table"
import restClient from 'Shared/hooks/restClient'


const ManageUsers = ({ awaiting_approval, rejected, invited = false }) => {

  const approveUser = (id) => {
    restClient.put(`/api/users/${id}/approve`).then(reloadTable)
  }

  const rejectUser = (id, reason) => {
    restClient.put(`/api/users/${id}/reject`, {reason}).then(reloadTable)
  }

  const handleTableClick = (e) => {
    if(e.target.classList.contains('approve-user-button')) {
      approveUser(e.target.dataset.userId)
    }
    if(e.target.classList.contains('reject-user-button')) {
      rejectUser(e.target.dataset.userId, prompt('Reason for rejection'))
    }
  }

  const scope = {}
  let definition = 'users'
  if (awaiting_approval) {
    definition = 'usersAwaitingApproval'
    scope.awaiting_approval = true
  }
  if (rejected) {
    definition = 'usersRejected'
    scope.rejected = true
  }
  if (invited) {
    definition = 'usersInvited'
    scope.invited = true
  }

  const tableProps = {
    table: definition,
    scope: scope,
    language: { emptyTable: 'There are no users yet' },
    onClick: handleTableClick,
  }

  const reloadTable = () => {
    window.$('table').DataTable().ajax.reload()
  }

  return (
    <div className="section">
      <div className="box">
        <div>
          <h2 className='no-margin-top'>Users</h2>
        </div>
        <Table {...tableProps} />
      </div>
    </div> 
  )
}


export default ManageUsers
