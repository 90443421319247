import DesktopMessager from './DesktopMessager'
import { ThemeProvider } from 'styled-components'

function Themed(props) {
  return (

      <DesktopMessager {...props} />

  )
}

export default Themed
