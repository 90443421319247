import { ReactFlowProvider } from 'reactflow';

import useAct from 'Workflows/hooks/useAct'
import Editor from './Editor'
import Sidebar from './Sidebar'
import Modal from './Modal'
import Topbar from './Topbar'
import Loading from 'Shared/components/Loading'
import './Builder.scoped.scss';

function Builder({ workflowId }) {
  const act = useAct()
  const { loaded } = useSel(s => s.builder)
  const { kind: modalKind } = useSel(s => s.local.modal)
  const currentNodeKind = useSel(s => s.workflowNodes.entities[s.local.sidebar?.nodeId]?.kind)
  const sidebarKind = useSel(s => s.local.sidebar.kind)
  const showSidebar = currentNodeKind || sidebarKind === 'editBranch'

  useEffect(() => {
    act.local.upsert({ workflowId })
    act.builder.load(workflowId)
    act.search.loadAvailableFilters()

    // TODO: this is a hack to keep the workflow up to date for testing
    const interval = setInterval(() => {
      act.builder.load(workflowId)
    }, 5000)

    return () => clearInterval(interval)
  }, [])

  if (!loaded) return <Loading text="Loading" />

  return <div className="container">
    <Topbar />
    <div className='builder'>
      <div className='editor'>
        <ReactFlowProvider>
          <Editor />
        </ReactFlowProvider>
      </div>

      <div className={`properties ${showSidebar ? '' : 'hidden'}`}>
        <Sidebar />
      </div>
    </div>
    { modalKind && <Modal /> }
  </div>
}

export default Builder;
