import restClient from 'Shared/hooks/restClient'
import Loading from 'Shared/components/Loading'
import TwilioBundleEmbed from './TwilioBundleEmbed'
import ServerWaitButton from 'Shared/components/ServerWaitButtonStandalone'

export default function Compliance() {
  const [data, setData] = useState(null)

  useEffect(() => {
    restClient.get('/api/compliance/status').then(({ data }) => setData(data))
  }, [])

  if (!data) return <Loading />
  if (data.setupComplete) {
    return <div>
      <h1>Phone and Account Setup</h1>
      <div className='box'>
        <h2 className='no-margin-top'>Setup Complete</h2>
        <p>Your account is setup and ready to go.</p>
      </div>
    </div>
  }

  const canLaunch = data.mobileBundleStatus === 'twilio-approved' && data.localBundleStatus === 'twilio-approved' && data.toppedUp

  async function onTwilioBundleComplete() {
    restClient.get('/api/compliance/status').then(({ data }) => setData(data))
  }

  function setupAccount() {
    return restClient.post('/api/compliance/setup_account')
      .then(() => restClient.get('/api/compliance/status').then(({ data }) => setData(data)))
  }

  return <div>
    <h1>Phone and Account Setup</h1>

    <div className='box'>
      <h2 className='no-margin-top'>Register Organisation Details - Mobile Numbers</h2>
      <BundleVerifyStatus bundleStatus={data.mobileBundleStatus} phoneNumberType='mobile' onComplete={onTwilioBundleComplete} />
    </div>

    <div className='box margin-top'>
      <h2 className='no-margin-top'>Register Organisation Details - Local Numbers</h2>
      <BundleVerifyStatus bundleStatus={data.localBundleStatus} phoneNumberType='local' onComplete={onTwilioBundleComplete} />
    </div>

    <div className='box margin-top'>
      <h2 className='no-margin-top'>Top Up</h2>
      { data.toppedUp && <div><SvgIconsRocketBlue /> You've already got sufficient credit on your account.</div> }
      { !data.toppedUp && <div>
        <p>You need to top up your account before we can buy phone numbers.</p>
        <div>
          <a href="/admin/billing" className='button primary'>Top Up</a>
        </div>
      </div> }
    </div>

    <div className='box margin-top'>
      <h2 className='no-margin-top'>Finish Account Setup</h2>
      { !canLaunch && <div>Complete the steps above before you launch your account.</div> }
      <div className='margin-top'>
        <ServerWaitButton className='button primary' disabled={!canLaunch} onClick={setupAccount}>Launch</ServerWaitButton>
      </div>
      <div className='margin-top'>
        <small>This will purchase numbers for your account, costing rougly $3.45 per month.</small>
      </div>
    </div>
  </div>
};

function BundleVerifyStatus({ bundleStatus, phoneNumberType, onComplete }) {
  const [verifyOpen, setVerifyOpen] = useState(false)

  function onTwilioBundleComplete() {
    onComplete()
    setVerifyOpen(false)
  }

  return <div>
    { bundleStatus === 'twilio-approved' && <div><SvgIconsRocketBlue /> Your organisation details have been verified.</div> }
      { (bundleStatus === 'in-review' || bundleStatus === 'pending-review') && <div>
        <p>Your organisation is currently being verified. You'll be notified if there's more to do.</p>
      </div> }
      { bundleStatus === 'twilio-rejected'  && <div>
        <p>Unfortunately your details have been rejected. Please review and deal with the issues that have been identified.</p>
        <div>
        <button className='button primary' onClick={() => setVerifyOpen(true)}>Review</button>
        </div>
      </div> }
      { bundleStatus === 'draft'  && <div>
        <p>You started submitting details about your organisation but didn't finish. Please finish submitting your details.</p>
        <div>
          <button className='button primary' onClick={() => setVerifyOpen(true)}>Verify</button>
        </div>
      </div> }
      { !bundleStatus && <div>
        <p>You need to verify details about your organisation before we can buy phone numbers. It only takes a few minutes and you can fill the form out here.</p>
        <div>
          <button className='button primary' onClick={() => setVerifyOpen(true)}>Verify</button>
        </div>
      </div> }

      { verifyOpen && <TwilioBundleEmbed onComplete={onTwilioBundleComplete} phoneNumberType={phoneNumberType} /> }
  </div>
}
