import useAct from 'DesktopCaller/useAct'
import useData from 'DesktopCaller/useData'
import ServerWaitButton from 'Shared/components/ServerWaitButton'
import LabeledCard from 'Shared/components/LabeledCard/LabeledCard'
import './Dialing.scoped.scss'

export default function Dialling() {
  const act = useAct()
  const { callStage, settings } = useData((rs) => {
    const callStage = rs.callSession.callStage
    const settings = rs.campaign?.settings
    return { callStage, settings }
  })
  const isCallAnswered = _.includes(['connected_to_target', 'finished_call'], callStage)
  const [canHangUp, setCanHangUp] = useState(true)
  const cancelTimeout = useRef(null)
  const cancelInterval = useRef(null)
  const hideInterval = useRef(null)
  const [hangUpTimeRemaining, setHangUpTimeRemaining] = useState(null)
  const [visible, setVisible] = useState(true)

  function startCountdown() {
    cancelInterval.current = setInterval(() => {
      setHangUpTimeRemaining((oldTime) => oldTime - 1000)
    }, 1000)
  }

  function cancelCountdown() {
    if (cancelTimeout.current) {
      clearTimeout(cancelTimeout.current)
    }
    if (cancelInterval.current) {
      clearInterval(cancelInterval.current)
    }
  }

  // add in a dialling countdown to enforce the minimum call time requirement
  // this defaults to 5s but can be overridden
  // once a call moves out of the ringing_target state, these effects are disabled
  useEffect(() => {
    if (callStage === 'ringing_target') {
      setCanHangUp(false)

      // count down so the user knows how long is left
      const timeRemaining = parseInt(settings.minimumCallTime || 5) * 1000
      setHangUpTimeRemaining(timeRemaining)
      startCountdown(timeRemaining)

      // at the end, allow hang ups to occur via this component
      cancelTimeout.current = setTimeout(() => {
        setCanHangUp(true)
      }, timeRemaining)
    } else {
      cancelCountdown()
    }
    return cancelCountdown
  }, [callStage])

  useEffect(() => {
    if (visible && callStage === 'connected_to_target') {
      hideInterval.current = setTimeout(() => {
        setVisible(false)
      }, 3000)
    }
    return () => {
      clearTimeout(hideInterval.current)
    }
  }, [callStage])

  function disconnect() {
    return act.desktopCaller.hangUp()
  }

  if (!visible) return null

  return (
    <LabeledCard
      label={
        <div className="large-image">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 59.998 59.998">
            <g className={`${isCallAnswered ? '' : 'ringing'}`}>
              <path
                d="M42.594 58.999h-25.19a3.405 3.405 0 0 1-3.405-3.405V4.404A3.405 3.405 0 0 1 17.404.999h25.189a3.405 3.405 0 0 1 3.405 3.405v51.189a3.404 3.404 0 0 1-3.404 3.406z"
                fill="#e7eced"
              />
              <circle cx="29.999" cy="53.999" r="3" fill="#fff" />
              <path
                d="M29.999 4.999h-4a1 1 0 1 1 0-2h4a1 1 0 1 1 0 2zM33.999 4.999h-1a1 1 0 1 1 0-2h1a1 1 0 1 1 0 2z"
                fill="#424a60"
              />
              <path fill="#424a60" d="M13.999 6.999h32v42h-32z" />
            </g>
          </svg>
        </div>
      }
    >

      {!isCallAnswered && (
        <>
          <p className="no-margin-top">{i18n.t('user.campaigns.phone.dialling')}</p>
          {!canHangUp && hangUpTimeRemaining > 0 && (
            <span>
              {i18n.t('user.campaigns.phone.hang_up_in_time', {
                seconds: String(hangUpTimeRemaining / 1000),
              })}
            </span>
          )}
        </>
      )}

      {callStage === 'connected_to_target' && (
        <p className="no-margin-top">{i18n.t('user.campaigns.phone.connected')}</p>
      )}

      {callStage === 'finished_call' && (
        <p className="no-margin-top">{i18n.t('user.campaigns.phone.call_has_finished')}</p>
      )}

      {!isCallAnswered && (
        <p className="action">
          <ServerWaitButton
            className="button secondary full-width"
            onClick={disconnect}
            disabled={!canHangUp}
          >
            {i18n.t('user.campaigns.phone.disconnect')}
          </ServerWaitButton>
        </p>
      )}
    </LabeledCard>
  )
}
