import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'

const adapter = createEntityAdapter()

function generateEntitySlices(entitySliceNames) {
  return _.fromPairs(
    _.map(entitySliceNames, (name) => {
      return [
        name,
        createSlice({
          name,
          initialState: adapter.getInitialState(),
          reducers: {
            upsert: adapter.upsertOne,
            setAll: adapter.setAll,
            remove: adapter.removeOne,
            upsertMany: adapter.upsertMany,
            add: adapter.addOne,
            removeMany: adapter.removeMany,
          },
        }).reducer,
      ]
    })
  )
}

function generateSlices(sliceNames) {
  return _.fromPairs(
    _.map(sliceNames, (name) => {
      return [
        name,
        createSlice({
          name,
          initialState: { errors: [] },
          reducers: {
            upsert: (s, a) => ({ ...s, ...a.payload }),
            set: (s, a) => a.payload,
          },
        }).reducer,
      ]
    })
  )
}

function moveDigitOrder(s, { payload: { id, digitOrder, parentFilter } }) {
  // Get IDs and insert our item at the new index
  const affectedIds = _.orderBy(
    _.filter(_.values(s.entities), parentFilter).filter((e) => e.id !== id),
    'digitOrder'
  ).map((e) => e.id)
  affectedIds.splice(digitOrder, 0, id)

  // Apply update
  affectedIds.forEach((id, i) => (s.entities[id].digitOrder = i + 1))
}

function fixDigitOrder(s, { payload: { parentFilter } }) {
  // Don't do anything if no digitOrders
  if (typeof _.values(s.entities)[0]?.digitOrder === 'undefined') return s

  const affectedIds = _.orderBy(
    _.filter(_.values(s.entities), parentFilter),
    'digitOrder'
  ).map((e) => e.id)

  affectedIds.forEach((id, i) => (s.entities[id].digitOrder = i + 1))
}

function upsertSettings(s, { payload: { id, settings } }) {
  s.entities[id].settings = { ...s.entities[id].settings, ...settings }
}

export { generateEntitySlices, generateSlices, moveDigitOrder, fixDigitOrder, upsertSettings }
