import * as React from 'react'

function SvgSurvey(props) {
  return (
    <svg width={48} height={49} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        opacity={0.3}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 6.5h-6a2 2 0 00-2 2v36a2 2 0 002 2h28a2 2 0 002-2v-36a2 2 0 00-2-2h-6v1a3 3 0 01-3 3H19a3 3 0 01-3-3v-1z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.75 32c-.48 0-.958-.192-1.342-.575l-3.833-3.833c-.767-.767-.767-1.917 0-2.684.767-.766 2.012-.766 2.683 0L21.75 27.4l6.325-6.325c.767-.767 1.917-.767 2.683 0 .767.767.767 1.917 0 2.683l-7.666 7.667A1.892 1.892 0 0121.75 32zM26 4.5h3.5a.5.5 0 01.5.5v3a.5.5 0 01-.5.5h-11A.5.5 0 0118 8V5a.5.5 0 01.5-.5H22a2 2 0 114 0z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgSurvey
