import Popover from '@mui/material/Popover/Popover'
import './PopoverLink.scoped.scss'

export default function PopoverLink({ children, content, ...rest }) {
  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)

  return (
    <>
      <a href="#" onClick={handleClick} {...rest}>
        {children}
      </a>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <div className="popover-content">
          <div className="icon"><SvgIconsQuestion /></div>
          {content()}
        </div>
      </Popover>
    </>
  )
}


