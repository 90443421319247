import useAct from 'Workflows/hooks/useAct'
import ServerWaitButton from 'Shared/components/ServerWaitButton'
import Button from 'Shared/components/Button'
import BackLink from 'Shared/components/BackLink'
import './TopBar.scoped.scss'

export default function Topbar() {
  const act = useAct()
  const { id, name, state } = useSel(s => s.builder.workflow)
  const backLink = `/admin/workflows/${id}/overview`

  function resume() {
    return act.local.openModal({ kind: 'resumeConfirm' })
  }

  function pause() {
    return act.builder.pause()
  }

  return <div className='top-bar'>
    <div className='title'>
      <p>Designing automation</p>
      <div className='name'>
        <BackLink href={backLink} />
        <h2>{ name }</h2>
      </div>
      <div className='filters'>
        <a className='link dark-grey' onClick={act.local.editEntryRules}>Edit entry Rules</a>
        <div className='ellipsis'></div>
        <a className='link dark-grey' onClick={act.local.editExitRules}>Edit exit Rules</a>
      </div>
    </div>
    <div className='buttons'>
      { state === 'draft' && <Button primary onClick={resume}>Launch</Button> }
      { state === 'paused' && <Button primary onClick={resume}>Resume</Button> }
      { state === 'running' && <ServerWaitButton secondary onClick={pause}>Pause</ServerWaitButton> }
    </div>
  </div>
}
