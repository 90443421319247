import * as React from 'react'

function SvgTip(props) {
  return (
    <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        opacity={0.3}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 17a8 8 0 100-16 8 8 0 000 16z"
        fill="#2849D6"
      />
      <path
        opacity={0.3}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.54 9l4.029 10.362 1.408-.547 1.398.552L17.471 9H6.54zm2.923 2h5.068l-2.554 6.465L9.463 11z"
        fill="#2849D6"
      />
      <path opacity={0.3} d="M10 22h4a2 2 0 11-4 0z" fill="#2849D6" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 17a1 1 0 011-1h6a1 1 0 110 2 1 1 0 110 2 1 1 0 110 2H9a1 1 0 110-2 1 1 0 110-2 1 1 0 01-1-1z"
        fill="#2849D6"
      />
    </svg>
  )
}

export default SvgTip
