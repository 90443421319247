import * as React from 'react'

function SvgForwardCaret(props) {
  return (
    <svg width={30} height={29} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect opacity={0.099} x="29" y="29" width="29" height="29" transform="rotate(-180 29 29)" fill="white" fillOpacity="0.1"/>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M25.9921 14.9453L21.3751 19.5623C20.8748 20.0626 20.8748 20.8746 21.3751 21.3748C21.8753 21.8751 22.6873 21.8751 23.1876 21.3748L28.7628 15.7996C29.2353 15.3271 29.2353 14.5622 28.7628 14.091L23.1876 8.51573C22.6873 8.01548 21.8753 8.01548 21.3751 8.51573C20.8748 9.01598 20.8748 9.82798 21.3751 10.3282L25.9921 14.9453Z"
        fill="#20204D"
        fill-opacity="0.2"
    	/>
    </svg>
  )
}

export default SvgForwardCaret
