import SpinbuttonField from "Shared/components/SpinbuttonField"
import AlignField from "../../AlignField"
import Divider from 'Shared/components/Divider'
import FontFamilyField from 'Shared/components/FontFamilyField'


const ParagraphBlockProperties = ({ block }) => {
  const act = useAct()

  const handleFontFamilyChange = (fontFamily) => {
    act.email.blocks.updateParameters(block.id, {fontFamily})
  }

  const handleFontSizeChange = (fontSize) => {
    act.email.blocks.updateParameters(block.id, {fontSize})
  }

  const handleTextAlignChange = (textAlign) => {
    act.email.blocks.updateParameters(block.id, {textAlign})
  }

  return (
    <form>
      <h3 className='no-margin-top'>Edit paragraph</h3>

      <FontFamilyField value={block.parameters.fontFamily} onChange={handleFontFamilyChange} />

      <Divider />

      <SpinbuttonField label="Font size" id="font-size-field" max="24" min="8" step="1" value={block.parameters.fontSize} onChange={handleFontSizeChange} />

      <Divider />

      <AlignField label="Text alignment" value={block.parameters.textAlign} onChange={handleTextAlignChange} id="text-alignment-field" />

    </form>
  )
}

export default ParagraphBlockProperties
