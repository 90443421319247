import * as React from 'react'

function SvgPickupRate(props) {
  return (
    <svg width={24} height={24} viewBox={"0 0 17 16"} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.07896 9.95032L10.283 7.74629C10.8917 7.13757 11.0426 6.20763 10.6576 5.43765L10.5327 5.18789C10.1478 4.41791 10.2987 3.48797 10.9074 2.87925L13.6402 0.146447C13.8355 -0.0488155 14.152 -0.0488155 14.3473 0.146447C14.3853 0.184447 14.4169 0.228326 14.441 0.276393L15.5449 2.48434C16.3895 4.17345 16.0584 6.21348 14.7231 7.54884L8.72425 13.5477C7.27071 15.0012 5.12069 15.5088 3.17056 14.8587L0.756084 14.0539C0.494113 13.9666 0.352533 13.6834 0.439857 13.4214C0.464403 13.3478 0.505757 13.2809 0.560645 13.226L3.21192 10.5747C3.82064 9.966 4.75058 9.81509 5.52056 10.2001L5.77032 10.325C6.5403 10.7099 7.47024 10.559 8.07896 9.95032Z"
        fill="currentColor"
    />
    </svg>
  )
}

export default SvgPickupRate
