import ServerWaitButton from 'Shared/components/ServerWaitButtonStandalone'

import './Step.scoped.scss'

export default function Step ({
  name,
  complete,
  currentEditor,
  editor,
  shortDescriptor,
  children,
  canOpen = true,
  staticOpenButton = null,
  finishButtonText = 'Save',
  canClickFinish = true,
  finishLoadingText = null,
  footerElements = null,
  additionalButtons = null,
  validationErrors,
  editable = true,
  showDiscard = true,
  showSave = true,
  showClose = false,
  save,
  open,
  close,
  headerActions = null
}) {
  const editing = currentEditor === editor
  const isAnotherEditorOpen = currentEditor && currentEditor !== editor

  return (
    <div className={`step ${editor}`}>

      <div className="content">
        <div className="description">
          <div className={`tick ${complete ? 'complete' : ''}`}>
            <SvgIconsTickGray />
          </div>
          <p>{name}</p>
          { editing && headerActions && <div className='actions'>{ headerActions }</div> }
        </div>

        <div className="well">
          <div className="editor">
            <div className={`short ${editing ? 'invisible' : ''}`}>{shortDescriptor}</div>
            <div
              className={`long form ${
                editing ? 'visible animate__fadeIn animate__animated' : ''
              }`}
            >
              {children}
              <div className="divider"></div>
              {(validationErrors?.length || 0) > 0 && <div className="box danger-zone margin-bottom">
                <h3 className='no-margin-top'>There's some things to check before you can continue:</h3>
                { validationErrors.map(error => (
                  <p key={error} className="error">
                    {error}
                  </p>
                ))}
              </div> }
              <div className='footer'>
                { footerElements && footerElements }

                <div className="buttons">
                  {showSave && <ServerWaitButton
                    className="button primary margin-right js-finish"
                    onClick={save}
                    disabled={!canClickFinish}
                    message={finishLoadingText}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-save"
                      viewBox="0 0 16 16"
                    >
                      <path d="M2 1a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H9.5a1 1 0 0 0-1 1v7.293l2.646-2.647a.5.5 0 0 1 .708.708l-3.5 3.5a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L7.5 9.293V2a2 2 0 0 1 2-2H14a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h2.5a.5.5 0 0 1 0 1H2z" />
                    </svg>
                    {finishButtonText}
                  </ServerWaitButton>}

                  { additionalButtons && additionalButtons }

                  { showDiscard &&
                    <button type="button" onClick={close} className="button naked reset-line-height">Discard changes</button>
                  }
                  { showClose &&
                    <button type="button" className="button naked" onClick={close}>Close</button>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {editing === false && (
        <>
          {!staticOpenButton && (
            <div className="action">
              {complete && editable && (
                <p>
                  <button
                    className={`button link naked`}
                    onClick={open}
                    disabled={isAnotherEditorOpen || !canOpen}
                    title={isAnotherEditorOpen && 'Only one step can be edited at a time'}
                  >
                    Edit
                  </button>
                </p>
              )}
              {!complete && (
                <p>
                  <button
                    className={`button secondary`}
                    onClick={open}
                    disabled={isAnotherEditorOpen || !canOpen}
                    title={isAnotherEditorOpen && 'Only one step can be edited at a time'}
                  >
                    Add
                  </button>
                </p>
              )}
            </div>
          )}
          {staticOpenButton && (
            <div className="action">
              <button
                className={`button`}
                onClick={open}
                disabled={!canOpen}
                title={!canOpen ? "You can't do this yet" : ''}
              >
                {staticOpenButton}
              </button>
            </div>
          )}
        </>
      )}
    </div>
  )
}
