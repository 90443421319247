const HtmlBlockProperties = ({ block }) => {
  const act = useAct()

  const handleTextChange = (e) => {
    act.email.blocks.updateParameters(block.id, {text: e.target.value})
  }

  return (
    <form>
      <h3 className='no-margin-top'>Edit html</h3>

      <div className="field">
        <textarea rows={15} value={block.parameters.text} onChange={handleTextChange} />
      </div>
    </form>
  )
}
  
 export default HtmlBlockProperties
