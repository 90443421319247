import React, { memo } from 'react';
import { Handle, Position } from 'reactflow';
import NodeList from './NodeList';
import useAct from 'Workflows/hooks/useAct'
import useNodeSize from 'Workflows/hooks/useNodeSize'

function PlaceholderNode({ id, data }) {
  const act = useAct()
  const isOpen = useSel(s => s.builder.openPlaceholderId === id)
  const ref = useNodeSize(id)

  function open(open) {
    if (open) {
      act.builder.setOpenPlaceholderId(id)
    } else {
      act.builder.setOpenPlaceholderId(null)
    }
  }

  return <div className={`node node-placeholder node-placeholder-${id} ${open ? 'big' : ''}`} ref={ref}>
      { isOpen && <>
        <NodeList nodeId={id} />
        <a className='button naked' onClick={() => open(false)}>Close</a>
      </> }
      { !isOpen && <div className='plus' onClick={() => open(true)}><SvgIconsPlus /></div> }

      <Handle type="target" position={Position.Top} />
      <Handle type="source" position={Position.Bottom} />
  </div>
}

export default memo(PlaceholderNode);
