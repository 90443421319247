import ShareSmsConversations from 'CampaignBuilder/components/ShareSmsConversations'
import EditSchedule from 'CampaignBuilder/components/EditSchedule/components/EditSchedule'

const modals = {
  shareSmsConversations: ShareSmsConversations,
  schedule: EditSchedule,
}

export default function Modal() {
  const modal = useSel((s) => s.local.modal)
  const act = useAct()

  function handleClose() {
    act.local.clearModal()
  }

  if (!modal) return null
  const ModalContent = modals[modal]

  return (
    <div className="modal-container visible">
      <div className="modal">
        <ModalContent />
      </div>
    </div>
  )
}
