import FontFamilyField from 'Shared/components/FontFamilyField'
import SpinbuttonField from 'Shared/components/SpinbuttonField'
import Divider from 'Shared/components/Divider'

const UnorderedListBlockProperties = ({ block }) => {
  const act = useAct()

  const handleFontFamilyChange = (fontFamily) => {
    act.email.blocks.updateParameters(block.id, { fontFamily })
  }

  const handleFontSizeChange = (fontSize) => {
    act.email.blocks.updateParameters(block.id, { fontSize })
  }

  return (
    <form>
      <h3 className="no-margin-top">Edit unordered list</h3>

      <FontFamilyField
        value={block.parameters.fontFamily}
        onChange={handleFontFamilyChange}
      />

      <Divider />

      <SpinbuttonField
        label="Font size"
        id="font-size-field"
        max="24"
        min="8"
        step="1"
        value={block.parameters.fontSize}
        onChange={handleFontSizeChange}
      />
    </form>
  )
}

export default UnorderedListBlockProperties
