import * as React from 'react'

function SvgNotification(props) {
  return (
    <svg width={32} height={32} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <circle cx="16" cy="16" r="16" fill="#20204D" fillOpacity="0.2"/>
    <circle cx="16" cy="16" r="11" fill="#20204D" fillOpacity="0.2"/>
    <path
      fillRule="evenodd"
      clipRule="evenodd" 
      d="M4.25 4.79485C4.25 2.8831 5.76104 1.33331 7.625 1.33331H8.375C10.239 1.33331 11.75 2.8831 11.75 4.79485V9.02562H12.875C13.4963 9.02562 14 9.54224 14 10.1795C14 10.8167 13.4963 11.3333 12.875 11.3333H3.125C2.50368 11.3333 2 10.8167 2 10.1795C2 9.54224 2.50368 9.02562 3.125 9.02562H4.25V4.79485ZM8 12C8.73638 12 9.33333 12.5969 9.33333 13.3333C9.33333 14.0697 8.73638 14.6666 8 14.6666C7.26362 14.6666 6.66667 14.0697 6.66667 13.3333C6.66667 12.5969 7.26362 12 8 12Z" 
      fill="#191936"
      transform="translate(8 8)"/>
    </svg>
  )
}

export default SvgNotification
