import React, { FC, useCallback } from 'react'
import PropTypes from 'prop-types'
import { ManageTargetTypes, useManageActions } from './hooks'
import PageAction from '../PageAction'
import ManageMenu from '.'

const ManageActions: FC<{ target: any; type: ManageTargetTypes }> = ({
  target,
  type,
}) => {
  const actions = useManageActions(type, target)

  const renderAction = useCallback((a, i) => {
    const key = `${a?.type}-${i}`
    switch (a?.type) {
      case 'action':
        return (
          <PageAction key={key} {...a.props}>
            {a.title}
          </PageAction>
        )
      case 'menu':
        return <ManageMenu key={key} {...a.props} />
      default:
        return null
    }
  }, [])
  return <>{actions.map(renderAction)}</>
}
ManageActions.propTypes = {
  target: PropTypes.any,
  type: PropTypes.oneOf(['target', 'search', 'campaign']),
}

export default ManageActions
