export default function(text) {
  const lines = text.split(/\r?\n/)

  const entityMap = {}
  let currentKey = 0
  return {
    blocks: lines.map(line => {
      const entityRanges = []

      const regex = /{{([^}]*)}}|{%([^%]*)%}/g // match {{ }} or {% %}
      let match
      while ((match = regex.exec(line)) !== null) {
          entityRanges.push({
              offset: match.index,
              length: match[0].length,
              key: currentKey,
          })
          entityMap[currentKey] = {
              type: 'PLACEHOLDER',
              mutability: 'IMMUTABLE',
          }
          currentKey += 1
      }

      return {
        text: line,
        type: 'unstyled',
        entityRanges: entityRanges,
      }
    }),
    entityMap: entityMap,
  }
}
