import { parseISO, eachMonthOfInterval, format, startOfMonth } from 'date-fns'
import IconBox from 'Shared/components/IconBox'
import Select from 'react-select'
import SmsTable from './usage-tables/SmsTable'
import WhatsappTable from './usage-tables/WhatsappTable'
import EmailsTable from './usage-tables/EmailsTable'
import CallsTable from './usage-tables/CallsTable'
import Loading from 'Shared/components/Loading'

import useAct from '../hooks/useAct'
import formatCurrency from 'Shared/functions/formatCurrency'

import 'Shared/components/NewDataTable/NewDataTable.scoped.scss'
import './UsageSummary.scoped.scss'

function sumCharge(items) {
  return items.reduce((acc, item) => acc + item.charge, 0)
}

function UsageStatRow({ icon, label, cost, color = 'orange', detailsComponent = null }) {
  const [expanded, setExpanded] = useState(false)

  return (
    <div className="usage-stat-row">
      <div>
        <IconBox size={48} color={color}>
          {icon}
        </IconBox>
        <div>
          {label}&nbsp;
          {detailsComponent &&
            (expanded ? (
              <button className="button naked link" onClick={() => setExpanded(false)}>
                (Hide details)
              </button>
            ) : (
              <button className="button naked link" onClick={() => setExpanded(true)}>
                (Show details)
              </button>
            ))}
        </div>
        <div>{formatCurrency(cost, 'USD')}</div>
      </div>
      {expanded && detailsComponent}
    </div>
  )
}

function SummaryTable({ usageData }) {
  return (
    <div className="usage-stats-table">
      <UsageStatRow
        icon={<SvgIconsSms />}
        label={`${usageData.textMessages.length} text message campaigns`}
        cost={sumCharge(usageData.textMessages)}
        detailsComponent={
          usageData.textMessages.length > 0 && <SmsTable data={usageData.textMessages} />
        }
      />
      <UsageStatRow
        icon={<SvgIconsWhatsApp />}
        label={`${usageData.whatsappMessages.length} WhatsApp campaigns`}
        cost={sumCharge(usageData.whatsappMessages)}
        detailsComponent={
          usageData.whatsappMessages.length > 0 && <WhatsappTable data={usageData.whatsappMessages} />
        }
      />
      <UsageStatRow
        icon={<SvgIconsPhone />}
        label={`${usageData.calls.length} phone campaigns`}
        cost={sumCharge(usageData.calls)}
        detailsComponent={
          usageData.calls.length > 0 && (
            <CallsTable calls={usageData.calls} transferCalls={[]} />
          )
        }
      />
      <UsageStatRow
        icon={<SvgIconsMail />}
        label={`${usageData.emails.length} email campaigns`}
        cost={sumCharge(usageData.emails)}
        detailsComponent={
          usageData.emails.length > 0 && <EmailsTable data={usageData.emails} />
        }
      />
      <UsageStatRow
        icon={<SvgIconsPhone />}
        label={`${usageData.phoneNumbersCount} phone numbers`}
        cost={usageData.phoneNumbersCharge}
      />
      <UsageStatRow
        icon={<SvgIconsMoney />}
        label="Total usage"
        cost={usageData.charge}
        color="grey"
      />
    </div>
  )
}

export default function UsageSummary() {
  const act = useAct()
  const [loading, setLoading] = useState(true)
  const [month, setMonth] = useState(format(startOfMonth(new Date()), 'y-MM-dd'))

  useEffect(() => {
    setLoading(true)
    act.usageData.load(month).then(() => setLoading(false))
  }, [month])

  const usageData = useSel((s) => s.usageData)

  const months = eachMonthOfInterval({
    start: parseISO('2022-01-01 00:00:00'),
    end: new Date(),
  }).map((m) => {
    return {
      value: format(m, 'y-MM-dd'),
      label: format(m, 'LLL y'),
    }
  })

  const selectStyle = {
    control: (provided, state) => ({
      ...provided,
      width: 200,
    }),
  }

  return (
    <div className="box">
      <div className="filters">
        <div className="field">
          <label>Select a month</label>&nbsp;
          <Select
            id="monthSelect"
            options={months}
            value={months.find((m) => m.value === month)}
            onChange={(v) => setMonth(v.value)}
            styles={selectStyle}
          />
        </div>
      </div>

      {loading ? <Loading /> : <SummaryTable usageData={usageData} />}
    </div>
  )
}
