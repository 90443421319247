export default (dis, store, client) => {
  function dismiss(id) {
    return client.post(`/api/notifications/${id}/mark_read`)
      .then(({ data: { notifications } }) => {
        refresh()
      })
  }

  function dismissAll() {
    return client.post(`/api/notifications/mark_all_read`)
      .then(({ data: { notifications } }) => {
        dis({ type: 'notifications/upsert', payload: { notifications } })
      })
  }

  function refresh() {
    return client.get(`/api/notifications`)
      .then(({ data: { notifications } }) => {
        dis({ type: 'notifications/upsert', payload: { notifications } })
      })
  }

  function show(showId) {
    dis({ type: 'notifications/upsert', payload: { showId } })
  }

  return { dismiss, dismissAll, refresh, show }
}
