import Table from "Shared/components/DataTable/Table"
import restClient from 'Shared/hooks/restClient'

const ManageLists = () => {

  const handleTableClick = (e) => {
    if(e.target.classList.contains('remove-list')) {
      remove(e.target.dataset.listId)
    } else if(e.target.classList.contains('restore-list')) {
      restore(e.target.dataset.listId)
    }
  }

  const tableProps = {
    table: 'lists',
    scope: {},
    language: { emptyTable: 'There are no lists yet' },
    onClick: handleTableClick,
  }

  const reloadTable = () => {
    window.$('table').DataTable().ajax.reload()
  }

  const remove = (id) => {
    if (confirm('Are you sure?')) {
      restClient
        .delete(`/api/lists/${id}`, )
        .then(reloadTable)
    }
  }

  const restore = (id) => {
    restClient
      .post(`/api/lists/${id}/restore`)
      .then(reloadTable)
  }

  return (
    <div className="section">
      <div className="box">
        <div className="header">
          <h2>Your lists</h2>
        </div>

        <Table {...tableProps} />

      </div>
    </div> 
  )
}

export default ManageLists
