import ChartKey from './ChartKey'
import './ChartLegend.scoped.scss'

const ChartLegend = ({ lines, setLines }) => {

  const toggleEnabled = (key, value) => {
    const newLines = lines.map((line) => {
      if (line.key === key) {
        return { ...line, enabled: value }
      } else {
        return line
      }
    })
    setLines(newLines)
  }

  return (

    <div className="chart-legend">
      {
        lines.map((line, i) =>
          <ChartKey
            key={i}
            label={line.label}
            color={line.color}
            enabled={line.enabled}
            onChange={(value) => toggleEnabled(line.key, value)}
          />)
      }
    </div>
  )
}

export default ChartLegend
