import { PieChart, Pie, Sector, Cell, ResponsiveContainer, Legend } from 'recharts'
import generateGraphColours from 'CampaignStats/generateGraphColours'

export default function CallsByAttempt({ data, maxAttempts }) {
    const graphColours = generateGraphColours(maxAttempts + 1)
    const pieData = Object.keys(data).map(key => {
        return { name: `${key} attempts`, value: data[key] }
    })

    return <div style={ { height: 'auto' } }>
        <h3 className='no-margin-top'>Calls made to each person in this campaign</h3>
        <p className='dark-grey smaller'>Each person will be tried { maxAttempts } times.</p>
        <ResponsiveContainer width="100%" height={300}>
            <PieChart width={200} height={200}>
                <Legend />
                <Pie
                    dataKey="value"
                    data={pieData}
                    cx="50%"
                    cy="50%"
                    outerRadius={80}
                    fill="#8884d8"
                    label
                >
                    { pieData.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={graphColours[index % graphColours.length]} />
                    ))}
                </Pie>
            </PieChart>
        </ResponsiveContainer>
    </div>
}
