/* eslint-disable react/prop-types */
import { Skeleton } from '@mui/material'
import classNames from 'classnames'
import { isArray, isObject, isString } from 'lodash'
import { FC } from 'react'
import DoneIcon from '@mui/icons-material/Done'
import CloseIcon from '@mui/icons-material/Close'
import './Rules.scoped.scss'

type Rule = string | { exclude: boolean; rules: Rule[]; type: 'all' | 'any' } | Rule[]
const DescriptionItem: FC<{
  rule: Rule
  root?: boolean
  marginBottom?: boolean
  center?: boolean
  rootType: 'all' | 'any'
}> = ({ rule, root = false, marginBottom, center, rootType }) => {
  if (isArray(rule)) {
    return (
      <ul
        className={classNames('no-bullets', {
          'no-padding': root,
        })}
      >
        {rule.map((r, i) => (
          <DescriptionItem
            key={i}
            rule={r}
            root={root}
            rootType={rootType}
            marginBottom={marginBottom}
            center={center}
          />
        ))}
      </ul>
    )
  }
  // Group
  if (isObject(rule)) {
    if (!rule.rules?.length) {
      return null
    }
    return (
      <li>
        {(root && (
          <h5 className="main-rule">
            Your audience <b>{rule.exclude ? 'excludes' : 'includes'}</b> everyone who
            matches <span className='boolean'>{rule.type}</span> of the criteria as displayed below:
          </h5>
        )) || (
          <>
            <span className='boolean between-groups'>{rootType === 'all' ? 'AND' : 'OR'}</span>{' '}
            <p className="group-rule">
              <b>{rule.exclude ? 'Exclude' : 'Include'}</b> everyone who matches <span className='boolean'>{rule.type}</span>{' '}
              of criteria:
            </p>
          </>
        )}
        <ul
          className={classNames('no-bullets', {
            'no-padding': root,
          })}
        >
          {rule.rules.map((r, i) => (
            <DescriptionItem
              key={i}
              rule={r}
              marginBottom={marginBottom}
              rootType={rootType}
              center={center}
            />
          ))}
        </ul>
      </li>
    )
  }
  if (isString(rule)) {
    const [type, ...parts] = rule.split(':')
    return (
      <li className="rule">
        {type === 'exc' ? (
          <CloseIcon
            sx={{
              verticalAlign: 'top',
              color: '#E44061',
            }}
          />
        ) : (
          <DoneIcon
            sx={{
              verticalAlign: 'top',
              color: '#5DCEC1',
            }}
          />
        )}
        <span dangerouslySetInnerHTML={{ __html: renderRule(parts.join(':')) }} />
      </li>
    )
  }
  return <></>
}
/**
 * Replace ** with bolding (markdown style)
 * @param rule
 * @returns
 */
function renderRule(rule: string): string {
  return rule.replace(/\*\*(.*?)\*\*/g, '<b>$1</b>')
}

export const Rules = ({ description, marginBottom, center }) => {
  const filteredDescription = description?.filter((r) => !!r.rules?.length)
  return (
    <>
      {!!filteredDescription?.length && (
        <ul className="no-bullets no-padding">
          {filteredDescription.map((rule, i) => (
            <React.Fragment key={rule.id}>
              <DescriptionItem
                rule={rule}
                root
                rootType={rule.type}
                marginBottom={marginBottom}
                center={center}
              />
              {i < filteredDescription.length - 1 && <li className="divider"></li>}
            </React.Fragment>
          ))}
        </ul>
      )}
      {!description && (
        <>
          <Skeleton variant="text" sx={{ fontSize: '1rem' }} width="60%" />
          <span className="skeleton-box" style={{ width: '60%' }}></span>
          <span className="skeleton-box" style={{ width: '70%' }}></span>
          <span className="skeleton-box" style={{ width: '55%' }}></span>
          <span className="skeleton-box" style={{ width: '70%' }}></span>
        </>
      )}
    </>
  )
}
