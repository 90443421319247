import { CircularProgress } from '@material-ui/core'
import Button from 'Shared/components/Button'
import Flash from 'Shared/components/Flash/components/Flash'
import { Formik, Field, Form, ErrorMessage } from 'formik';
import IntervalInput from 'Shared/components/IntervalInput/IntervalInput';
import './Form.scoped.scss'
import useAct from 'Workflows/hooks/useAct'

export default function EditDelayNode({ nodeId }) {
  const act = useAct()
  const [flash, setFlash] = useState(null)
  const properties = useSel(s => s.workflowNodes.entities[nodeId].properties)
  const state = useSel(s => s.builder.workflow.state)

  const handleSubmit = (values, { setSubmitting }) => {
    act.builder.updateNode({ nodeId, properties: values })
    .then(() => setSubmitting(false))
  }

  return (
    <Formik
      initialValues={properties}
      onSubmit={handleSubmit}
    >
      {({ values, isSubmitting, errors, setFieldValue }) => {
        return (
        <Form>
          {flash && <Flash message={flash.message} type={flash.type} onHide={() => setFlash(null)} />}

          <div className="fields">
            <div className="field">
              <p>Wait for</p>
              <IntervalInput
                value={values.delayInSeconds}
                onChange={(value) => setFieldValue('delayInSeconds', value)}
              />
            </div>
            <div className="field">
              <Button type="submit" small primary extraClass='margin-right' disabled={isSubmitting}>Save changes</Button>
              {isSubmitting && <CircularProgress />}
              <Button small red onClick={() => act.local.setSidebar({ kind: 'workflow' })}>Cancel</Button>
            </div>
          </div >
        </Form>
      )}}
    </Formik>
  )
}
