import './textMessages.scoped.scss'
import { renderToString } from 'react-dom/server'

function TextMessage({ body, media_type, media_url }) {
  const kind = media_type && media_type.split('/')[0]

  function Content() {
    if (kind === 'image') {
      return <img src={media_url} />
    } else if (kind === 'video') {
      return <video controls>
        <source src={media_url} type={media_type} />
      </video>
    } else if (kind === 'audio') {
      return <audio controls>
        <source src={media_url} type={media_type} />
      </audio>
    }
  }

  if (!media_type) {
    return <span>{ body }</span>
  } else {
    return <div className='media'>
      <div className='margin-bottom'>{ _.compact(body.split("\n")).map(text => <p>{ text }</p>) }</div>
      <Content />
    </div>
  }
}

const columns = [
  {
    data: 'name',
    className: 'bold',
    render: function (data, type, row, meta) {
      return `<a href='${row['url']}'>${data}</a>`
    },
  },
  {
    data: 'body',
    render: function (data, type, row, meta) {
      return renderToString(<TextMessage {...row} />)
    },
  },
]

const headers = [
  'Name',
  'Text'
]

const filters = [

]

const sorters = [

]

export { columns, headers, filters, sorters }
