import SafeRenderHtml from "./SafeRenderHtml"
import Stat from './Stat'
import restClient from "Shared/hooks/restClient"
import Context from "CampaignVolunteer/Context"
import OrganisationDescription from "./OrganisationDescription"

export default function Phone() {
  const { state, dispatch, actions } = useContext(Context)
  const { campaign, organisationId, remainingCount } = state
  const { linesOpen, openingHour, closingHour } = campaign

  useEffect(() => {
    restClient.get(`/api/campaigns/${campaign.id}/targets/remaining`, { params: { organisationIds: _.compact([organisationId]) } })
      .then((response) => dispatch({ remainingCount: response.data.count }))
  }, [])

  const startUrl = `/campaigns/${campaign.slug}/call?organisation_ids[]=${organisationId || ''}`
  const demoUrl = `/campaigns/${campaign.slug}/call?demo=true`
  const disabled = !remainingCount || !linesOpen
  const buttonText = campaign.enabledCallingModes.length > 1 ? i18n.t('user.campaigns.phone.choose_how_to_call') : i18n.t('user.campaigns.phone.start_calling')

  function CallingContent() {
    return <div>
      { remainingCount === 0 && <p className='bold'>{ i18n.t('user.campaigns.text.no_reply') }</p> }

      { !linesOpen && <>
        <p className='bold'>{ i18n.t('user.campaigns.phone.lines_closed_desc') }</p>
        <p>{ i18n.t('user.campaigns.phone.lines_closed_hours', { opening_hour: openingHour, closing_hour: closingHour }) }</p>
      </> }

      <a className={`primary button margin-top margin-right ${disabled ? 'disabled' : ''}`} href={startUrl}>{ buttonText }</a>
      <a className="secondary button margin-top" href={demoUrl}>{ i18n.t('user.campaigns.phone.practice_calling') }</a>

      { organisationId && <div className='margin-top'>
        <a className="naked button margin-top" onClick={actions.reset}>{ i18n.t('shared.messages.go_back') }</a>
      </div> }
    </div>
  }

  return <>
    { remainingCount !== null && <Stat icon={<SvgIconsSms />} number={remainingCount} label={i18n.t('user.campaigns.phone.calls_remaining')} /> }

    <OrganisationDescription />

    <p className="no-margin-bottom">
      <SafeRenderHtml html={campaign.instructions} />
    </p>

    { campaign.enabledCallingModes.length > 0 ? <CallingContent /> : <NoPhoneNumber /> }
  </>
}

function NoPhoneNumber() {
  return <p>
    { i18n.t('user.campaigns.phone.no_phone_number') }
    <a href='button naked' href="/user/profile">{ i18n.t('user.campaigns.phone.set_one_up') }</a>
  </p>
}
