import Timer from 'react-compound-timer'
import useAct from 'DesktopCaller/useAct'
import useData from 'DesktopCaller/useData'
import { useEffect } from 'react'
import ServerWaitButton from 'Shared/components/ServerWaitButton'
import Button from 'Shared/components/Button'
import IntegrationButton from 'Shared/components/IntegrationButton/IntegrationButton'
import { isMobile } from 'react-device-detect'
import './TwilioCallerTopbar.scoped.scss'
import ButtonWithDropdown from 'Shared/components/ButtonWithDropdown'
import ButtonWithCheckMark from 'Shared/components/ButtonWithCheckMark'
import CircularProgress from 'Shared/components/progress/CircularProgress'
import RrIconButton from 'Shared/components/RrIconButton'
import RrIcon from 'Shared/components/RrIcon'
import goalActions from 'Shared/components/GoalButton/actions'
import { useWindowSize } from 'usehooks-ts'

export default function TwilioCallerTopbar({ condensedCaller, widgets }) {
  const act = useAct()
  const { deviceActive, connected, completeGoalOnDone, inputsConfigured } = useSel((s) => s.local)
  const size = useWindowSize()
  const isMobileWidth = size.width < 768
  const clientLogo = window.ENV.LOGO_URL

  const {
    callStage,
    callsMade,
    callingMode,
    settings,
    supervisorState,
    inputStage,
    targetId,
    name,
  } = useData((rs) => {

    const { callStage, callsMade, callingMode, supervisorState, inputStage } =
      rs.callSession
    const settings = rs.campaign?.settings || {}
    const name = rs.campaign?.name
    const targetId = rs.target.id

    return {
      callStage,
      callsMade,
      callingMode,
      settings,
      supervisorState,
      inputStage,
      targetId,
      name,
    }
  })
  const campaignTargetId = useData((rs) => rs.campaignTarget?.id)
  const { goalId, goalName } = settings

  const targetName = useData((rs) => rs.target?.firstName && `${rs.target.firstName} ${rs.target.lastName}`)

  const [canHangUp, setCanHangUp] = useState(true)
  const [cancelTimeout, setCancelTimeout] = useState(null)
  const [goalComplete, setGoalComplete] = useState(false)

  const setCompleteGoalOnDone = (value) => {
    act.local.upsert({ completeGoalOnDone: value })
  }

  function showDialpad() {
    act.local.upsert({ showDialpad: true })
  }

  useEffect(() => {
    if (!goalId || !targetId) return
    goalActions.getGoalComplete(campaignTargetId, goalId)
      .then((complete) => {
        setCompleteGoalOnDone(false)
        setGoalComplete(complete)
      })
  }, [targetId, goalId])

  useEffect(() => {
    if (callStage === 'ringing_target') {
      setCanHangUp(false)
      setCancelTimeout(
        setTimeout(() => {
          setCanHangUp(true)
        }, (settings.minimumCallTime || 5) * 1000)
      )
    } else {
      if (cancelTimeout) {
        clearTimeout(cancelTimeout)
        setCancelTimeout(null)
      }
      setCanHangUp(true)
    }
  }, [callStage])

  const enableSupervisor = callStage === 'connected_to_target' && settings.supervisors
  const showGoalButton = campaignTargetId && _.includes(['answers', 'rate_call'], inputStage) && goalId
  const showKeypadButton =
    callStage === 'connected_to_target' &&
    callingMode === 'desktop_web'
  const showIntegrationButton =
    _.includes(['answers', 'rate_call'], inputStage) && widgets && widgets.length === 1
  const showButtons = callingMode === 'desktop_web' ? connected : true

  const showToolbar =
    callStage !== 'waiting_for_connection' &&
    ['desktop_web', 'desktop_phone'].includes(callingMode)


  const endCallMenuItems = [
    {
      label: i18n.t('user.campaigns.phone.hang_up_and_next_call'),
      action: async () => {
        if (completeGoalOnDone) {
          await goalActions.markGoalComplete(campaignTargetId, goalId)
          setGoalComplete(true)
        }
        if (callStage === 'ringing_target') {
          act.desktopCaller.answerOutcome('no_answer')
        } else {
          act.desktopCaller.hangUp()
        }
      },
      disabled: !canHangUp
    },
    {
      label: i18n.t('user.campaigns.phone.hang_up_and_end_calling'),
      action: async () => {
        if (completeGoalOnDone) {
          await goalActions.markGoalComplete(campaignTargetId, goalId)
          setGoalComplete(true)
        }
        canHangUp && act.desktopCaller.disconnect()
      },
      disabled: !canHangUp
    },
  ]

  return (
    <div className="topbar">

      <a className="back" onClick={() => window.history.back()}></a>

      {(!isMobileWidth && clientLogo) && (
        <>
          <img src={clientLogo} className="logo" />{' '}
          <h1>{name}</h1>
        </>
      )}
      {!clientLogo && !isMobileWidth && <h1>{name}</h1>}
      {(isMobileWidth && targetName) && <h1>{targetName}</h1>}
      {(isMobileWidth && !targetName) && <h1></h1>}

      {showToolbar && <>

        {showButtons && (
          <div className="streak">
            <CircularProgress percent={100} label={callsMade} size={48} strokeWidth={5} />
            <p>{i18n.t(`user.campaigns.phone.calls${!isMobile ? '_made' : ''}`)}</p>
          </div>
        )}

        <div className="divider"></div>

        <p className="timer">
          {showButtons && (
            <Timer>
              <Timer.Minutes formatValue={(v) => `${String(v).padStart(2, '0')}`} /> :{' '}
              <Timer.Seconds formatValue={(v) => `${String(v).padStart(2, '0')}`} />
              &nbsp;min
            </Timer>
          )}
          {!showButtons && <span>0:00</span>}
        </p>

        <div className="divider"></div>


        <div className="break"></div>

        {showButtons && (
          <>
            <div className="actions">
              {(showKeypadButton) && (
                <Button secondary onClick={showDialpad} extraClass="grow">
                  <SvgIconsKeypad />
                  {i18n.t('user.campaigns.phone.keypad')}
                </Button>
              )}
              {enableSupervisor && (
                <ServerWaitButton
                  className="button small blue"
                  disabled={supervisorState !== 'none'}
                  onClick={() => act.desktopCaller.connectSupervisor()}
                >
                  {i18n.t('user.campaigns.phone.connect_to_supervisor')}
                </ServerWaitButton>
              )}
              {showGoalButton && (
                <ButtonWithCheckMark
                  tick
                  onClick={() => setCompleteGoalOnDone(!completeGoalOnDone)}
                  disabled={goalComplete}
                  extraClass="grow"
                  checked={completeGoalOnDone}>
                  {goalName}
                </ButtonWithCheckMark>
              )}
              {showIntegrationButton && (
                <IntegrationButton integration={widgets[0]} targetId={targetId} />
              )}
            </div>

            <div className="end-call">
              <ButtonWithDropdown red menuItems={endCallMenuItems}>
                <SvgIconsHangUp />
                {!isMobileWidth && <span>{i18n.t('user.campaigns.phone.end_call')}</span>}
              </ButtonWithDropdown>
            </div>
          </>
        )}

        {!connected && callingMode === 'desktop_web' && (
          <>
            <ServerWaitButton
              className="button small green"
              onClick={() => act.desktopCaller.setupTwilio()}
            >
              {i18n.t('user.campaigns.phone.start_calling')}
            </ServerWaitButton>
          </>
        )}

      </>}


      {
        !condensedCaller && (
          <div className="actions">
            {inputsConfigured && (
              <RrIconButton
                onClick={() => act.local.upsert({ showInputSetup: true })}
              >
                <RrIcon iconName="micSetup" />
              </RrIconButton>
            )}
          </div>
        )
      }
    </div >
  )
}
