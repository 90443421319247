import Avatar from "boring-avatars"
import Button from "Shared/components/Button"
import './Search.scoped.scss'
import userCan from "Shared/functions/userCan"

const Search = ({ id, name, targetCount, newCampaign, useSearch, edit, compact }) => (
  <div className={`card search-card-${compact ? 'compact' : 'normal'}`}>
    {!compact && <div className="icon">
      <Avatar
        size={34}
        name={name}
        variant="beam"
        colors={["#92A1C6", "#146A7C", "#F0AB3D", "#C271B4", "#C20D90"]}
      />
    </div>}
    <p>{name}</p>
    {edit && <a className="blue-link edit" href={`/admin/searches/${id}`}>Edit</a>}
    <span className="target-count">
      <SvgIconsMembers />
      {targetCount.toLocaleString()}
    </span>
    {userCan('create_campaigns') &&
    <>
      <button className="button primary small" onClick={() => newCampaign(id)}>New campaign</button>
      <button className="button secondary small margin-top half" onClick={() => useSearch(id)}>Use search</button>
    </>}
  </div>
)

export default Search
