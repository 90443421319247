export default (dis, store, client) => {
  function loadQuery(name, filters) {
    const params = {}
    Object.keys(filters).map((f) => {
      if (filters[f] !== null) {
        params[f] = filters[f]
      }
    })
    return client.get(`/api/reporting/query?query=${name}`, { params })
      .then(({ data: { points } }) => {
        dis({ type: 'reporting/upsert', payload: { points, currentQuery: name } })
      })
  }

  function upsert(payload) {
    dis({ type: 'reporting/upsert', payload })
  }

  return { loadQuery, upsert }
}
