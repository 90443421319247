import * as React from 'react'

function SvgPhone(props) {
  return (
    <svg
      width={142}
      height={294}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M135.781 20.406c0-7.635-6.184-13.82-13.82-13.82h-12.265a2.764 2.764 0 00-2.764 2.764v.346a7.58 7.58 0 01-7.601 7.6H42.669a7.58 7.58 0 01-7.6-7.6V9.35a2.764 2.764 0 00-2.765-2.764H20.04c-7.636 0-13.82 6.185-13.82 13.82v252.905c0 7.636 6.184 13.82 13.82 13.82H121.96c7.636 0 13.82-6.184 13.82-13.82V20.406zM20.039.367H121.96C133.017.367 142 9.35 142 20.407V273.31c0 11.056-8.983 20.039-20.039 20.039H20.039C8.983 293.35 0 284.367 0 273.311V20.406C0 9.35 8.983.367 20.039.367z"
        fill="#333"
      />
      <path
        opacity={0.6}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M48.025 282.639c0-.477.387-.864.864-.864h44.57a.864.864 0 010 1.728h-44.57a.864.864 0 01-.864-.864z"
        fill="#333"
      />
      <path
        d="M88.966 9.696a2.418 2.418 0 11-4.837 0 2.418 2.418 0 014.837 0zM63.226 8.66a1.036 1.036 0 000 2.072h15.202a1.036 1.036 0 100-2.073H63.226z"
        fill="#4F4F4F"
      />
    </svg>
  )
}

export default SvgPhone
