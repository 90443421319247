import * as React from 'react'

function SvgUp(props) {
  return (
    <svg width={24} height={24} version="1.1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="none" fillRule="evenodd">
      <polygon points="0 0 24 0 24 24 0 24"/>
      <rect x="11" y="5" width="2" height="14" rx="1" fill="currentColor" />
      <path d="m6.7071 12.707c-0.39052 0.39052-1.0237 0.39052-1.4142 0s-0.39052-1.0237 0-1.4142l6-6c0.37858-0.37858 0.98817-0.39182 1.3828-0.030047l6 5.5c0.40712 0.37319 0.43462 1.0058 0.06143 1.4129s-1.0058 0.43462-1.4129 0.06143l-5.2942-4.853-5.323 5.323z" fill="currentColor" fillRule="nonzero"/>
      </g>
    </svg>
  )
}

export default SvgUp
