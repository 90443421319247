import { Formik, Field as FormikField, Form, ErrorMessage, useFormikContext } from 'formik'
import AsyncSelect from 'Shared/components/AsyncSelect'
import restClient from 'Shared/hooks/restClient'
import Loading from 'Shared/components/Loading'

const required = value => (!value ? 'Required' : undefined)

function loadScript(src, callback) {
  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.src = src;
  script.onload = callback;
  document.body.appendChild(script);
}

function Field({ name, validate, ...props }) {
  const { values, errors, touched } = useFormikContext()

  const error = errors[name]

  return <FormikField name={name} validate={validate} {...props} className={`${error ? 'with-error' : ''}`} />
}

function Step1() {
  return <div className="field">
    <h3>About you</h3>
    <label className="bold">Your name</label>
    <div className="row">
      <div className="column half">
        <Field type="text" name="firstName" placeholder="First name" validate={required} />
        <ErrorMessage name="firstName" component="div" />
      </div>
      <div className="column half">
        <Field type="text" name="lastName" placeholder="Last name" validate={required} />
        <ErrorMessage name="lastName" component="div" />
      </div>
    </div>
    <div className="row margin-top">
      <div className="column remaining">
        <label className="bold">Your membership number (optional)</label>
        <p className="dark-grey">You can find this in your welcome email or on your membership card.</p>
        <Field type="text" name="membershipNumber" placeholder="Membership number" />
        <ErrorMessage name="membershipNumber" component="div" />
      </div>
    </div>
  </div>
}

function Step2({ content }) {
  return <div className="field">
    <label className="bold">Your registered address</label>
    <div dangerouslySetInnerHTML={{ __html: content.addressIntro }}/>
    <div className="row">
      <div className="column remaining">
        <Field type="text" name="address1" placeholder="Address line 1" validate={required} />
        <ErrorMessage name="address1" component="div" />
      </div>
    </div>
    <div className="row margin-top">
      <div className="column remaining">
        <Field type="text" name="address2" placeholder="Address line 2" />
        <ErrorMessage name="address2" component="div" />
      </div>
    </div>
    <div className="row margin-top">
      <div className="column half">
        <Field type="text" name="city" placeholder="City" validate={required} />
        <ErrorMessage name="city" component="div" />
      </div>
      <div className="column half">
        <Field type="text" name="postcode" placeholder="Postcode" validate={required} />
        <ErrorMessage name="postcode" component="div" />
      </div>
    </div>
    <div className="row margin-top">
      <div className="row">
        <div className="column remaining">
          <label className="align-center">Is this still your correct address?</label>
          <br />

          <label htmlFor="yes"><Field type="radio" name="addressConfirm" id="yes" value="yes" validate={required}  /> Yes</label>
          <label htmlFor="no"><Field type="radio" name="addressConfirm" id="no" value="no" validate={required}  /> No</label>
          <ErrorMessage name="addressConfirm" component="div" />
        </div>
      </div>
    </div>
  </div>
}

function Step3({ content, setFieldValue }) {
  const c2aRef = useRef(null)
  const [showFields, setShowFields] = useState(!window.ENV.FETCHIFY_KEY)

  useEffect(() => {
    if (window.ENV.FETCHIFY_KEY && !window.clickToAddress) {
      loadScript('https://cc-cdn.com/generic/scripts/v1/cc_c2a.min.js', initFetchify)
    }
  }, [])

  const initFetchify = () => {
    if (window.clickToAddress && !c2aRef.current) {
      c2aRef.current = new window.clickToAddress({
        accessToken: window.ENV.FETCHIFY_KEY,
        domMode: 'id',
        dom: {
          search: 'fetchify-search',
        },
        onResultSelected: function (_c2a, _elements, data) {
          setFieldValue('address1New', data.line_1 || "")
          setFieldValue('address2New', data.line_2 || "")
          setFieldValue('cityNew', data.locality || "")
          setFieldValue('postcodeNew', data.postal_code || "")
          setShowFields(true)
        }
      })
    }
  };

  return <div className="field">
    <label className="bold">Your new address</label>
    <p>{ content.addressNewIntro }</p>
    { window.ENV.FETCHIFY_KEY && <div className="row margin-bottom">
      <div className="column remaining">
        <div className='field'>
          <label className="bold">Search for your new address</label>
          <input type='text' id="fetchify-search" />
          { !showFields && <span className='link' onClick={() => setShowFields(true)}>Enter manually</span> }
        </div>
      </div>
    </div> }
    { showFields && <>
      <div className="row">
        <div className="column remaining">
          <label>Address details</label>
        </div>
      </div>
      <div className="row">
        <div className="column remaining">
          <Field type="text" name="address1New" placeholder="Address line 1" validate={required} />
          <ErrorMessage name="address1New" component="div" />
        </div>
      </div>
      <div className="row margin-top">
        <div className="column remaining">
          <Field type="text" name="address2New" placeholder="Address line 2" />
          <ErrorMessage name="address2New" component="div" />
        </div>
      </div>
      <div className="row margin-top">
        <div className="column half">
          <Field type="text" name="cityNew" placeholder="City" validate={required} />
          <ErrorMessage name="cityNew" component="div" />
        </div>
        <div className="column half">
          <Field type="text" name="postcodeNew" placeholder="Postcode" validate={required} />
          <ErrorMessage name="postcodeNew" component="div" />
        </div>
      </div>
      <div className="row margin-top">
        <div className="column remaining">
          <label className="align-center"><Field type="checkbox" name="consentUpdate" id="consentUpdate" value="yes" validate={required} /> <span className='margin-left'>{ content.addressUpdateConsent }</span></label>
            <br />
          <ErrorMessage name="consentUpdate" component="div" />
        </div>
      </div>
    </> }
  </div>
}

function Step4({ values }) {
  const validateOrgOther = (value) => {
    const { organisationId } = values
    if (!organisationId && !value) {
      return 'Required if you have not selected an employer'
    }
  }

  return <>
    <div className="field">
      <label className="bold">Name of employer</label>
      <div className="row">
        <div className="column remaining">
          <Field
            name="organisationId"
            render={({ field, form }) => {
            const onChange = v => form.setFieldValue(field.name, v);

            return <AsyncSelect
              entity='organisation'
              value={field.value}
              onChange={onChange}
              baseEndpoint='/api/target/organisations'
              isClearable
            />
            }}
          />
        </div>
      </div>
    </div>
    <div className='field'>
      <div className="row margin-top">
        <div className="column remaining">
          <label className="bold">If you can't find your employer in the list, enter it below</label>
          <Field type="text" name="organisationOther" placeholder="Your employer" validate={validateOrgOther} />
          <ErrorMessage name="organisationOther" component="div" />
        </div>
      </div>
    </div>
  </>
}

function Step5({ content }) {
  const yesRequired = value => value !== 'yes' ? content.consentRequiredMessage : ''

  return <div className="field">
    <div className="row margin-top">
      <div className="row">
        <div className="column remaining">
          <label className="bold">Email address</label>
          <Field type="text" name="email" placeholder="Email address" validate={required} />
          <ErrorMessage name="email" component="div" />
        </div>
      </div>
    </div>
    <div className="row margin-top">
      <div className="row">
        <div className="column remaining">
          <label className="bold">Phone number</label>
          <Field type="text" name="phoneNumber" placeholder="Phone number" validate={required} />
          <ErrorMessage name="phoneNumber" component="div" />
        </div>
      </div>
    </div>
    <div className="row margin-top">
      <div className="row">
        <div className="column remaining">
          <label className="align-center">{ content.consentLabel }</label>
          <br />

          <label htmlFor="yes"><Field type="radio" name="consent" id="yes" value="yes" validate={yesRequired}  /> Yes</label>
          <label htmlFor="no"><Field type="radio" name="consent" id="no" value="no" validate={yesRequired}  /> No</label>
          <ErrorMessage name="consent" component="div" />
        </div>
      </div>
    </div>
    <div className="row margin-top">
      <div className="row">
        <div className="column remaining">
          <div dangerouslySetInnerHTML={{ __html: content.consentDisclaimer }} />
        </div>
      </div>
    </div>
  </div>
}

export default function TargetDetailsUpdate({ targetId, firstName }) {
  const [step, setStep] = useState(1);
  const [content, setContent] = useState(null);

  useEffect(() => {
    restClient.get(`/api/content/target_details_update`)
    .then(({ data }) => setContent(data))
  }, [])

  const handleSubmit = (values, { setSubmitting }) => {
    if (step < 5) {
      if (step === 2 && values.addressConfirm === 'yes') {
        setStep(4);
      } else {
        setStep(step + 1);
      }

      setSubmitting(false);
    } else {
      restClient.post(`/target/details`, { data: values })
      .then(({ data }) => {
        const { publicId } = data

        window.location = `/target/details_success?public_id=${publicId}`
      })
      .catch(({ data }) => {
        console.log(data)
        setSubmitting(false);
      })
    }
  }

  function goBack(values) {
    if (step === 4 && values.addressConfirm === 'yes') {
      setStep(2)
    } else {
      setStep(step - 1)
    }
  }

  if (!content) return <Loading text="Loading" />

  return <div className='member-details'>
    <div className="logos margin-top align-horizontally-center">
      <img src={content.logoUrl} width="auto" height="75px" style={{ boxSizing: 'border-box' }} className='center' />
    </div>

    <div className="container pad-on-mobile">
      <h1>{ content.title }</h1>
      <p>{ content.intro }</p>
      <Formik
        initialValues={{ firstName: '', lastName: '', membershipNumber: '', address1: '', address2: '', city: '', postcode: '', addressConfirm: '', address1New: '', address2New: '', cityNew: '', postcodeNew: '', organisationId: '', organisationOther: '', email: '', phoneNumber: '', consent: '', consentUpdate: ''}}
        onSubmit={handleSubmit}
        validateOnBlur={false}
        validateOnChange={false}
      >
        {(formikProps) => {
          const { values, isSubmitting } = formikProps
          return <Form>
            { step === 1 && <Step1 {...formikProps} content={content} /> }
            { step === 2 && <Step2 {...formikProps} content={content} /> }
            { step === 3 && <Step3 {...formikProps} content={content} /> }
            { step === 4 && <Step4 {...formikProps} content={content} /> }
            { step === 5 && <Step5 {...formikProps} content={content} /> }
            <div>
            { step !== 1 && !isSubmitting && <button type="button" className='button secondary margin-right' onClick={() => goBack(values)}>{isSubmitting ? 'Please wait...' : 'Back'}</button> }
            { step !== 5 && <button className='button primary' type="submit">Next</button> }
            { step === 5 && <button className='button primary' type="submit">{isSubmitting ? 'Please wait...' : 'Submit'}</button> }
            </div>

          </Form>
        }}
      </Formik>

    </div>
    <div className="footer">
      <div className="container pad-on-mobile">
        <p>{ content.footerHelp }</p>
      </div>

      <div className="parts">
        <svg className="megaphone" width="183" height="202" viewBox="0 0 183 202" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M18.1973 167.942L0.987861 138.275C0.17791 136.879 0.28652 135.133 1.26325 133.848L101.531 1.92404C103.202 -0.274538 106.545 -0.158659 108.06 2.15034L182.056 114.944C183.471 117.101 182.505 120.014 180.081 120.897L102.389 149.207C100.907 149.747 99.8924 151.11 99.7162 152.676C97.3254 173.942 84.7432 190.505 73.6448 200.598C70.6396 203.33 66.507 199.831 67.5682 195.91C71.4869 181.431 68.1458 169.916 64.6814 163.964C63.9153 162.648 62.3639 162.076 60.8693 162.367L22.4225 169.861C20.749 170.187 19.0528 169.416 18.1973 167.942Z" fill="#191936"/>
        </svg>

        <svg className="spanner" width="147" height="145" viewBox="0 0 147 145" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M94.708 173.338C94.167 172.383 94.4975 171.17 95.4484 170.621L109.277 162.637L123.076 154.67C124.039 154.115 125.269 154.45 125.817 155.417L140.731 181.76C141.596 183.288 143.807 183.026 144.182 181.311C146.643 170.082 148.087 150.393 139.489 135.5C131.76 122.113 118.681 121.693 107.519 124.668C106.624 124.906 105.674 124.531 105.211 123.729L66.8044 57.2077C66.3593 56.4367 66.4805 55.4677 67.0767 54.8065C73.9969 47.1315 78.6952 36.7239 71.6387 24.9434C63.4219 11.226 46.8202 3.46681 36.5073 0.336648C34.8429 -0.168529 33.5414 1.60444 34.4226 3.10408L48.6199 27.2662C49.1762 28.2129 48.8652 29.431 47.9233 29.9952L35.2203 37.6044L22.5462 45.1962C21.5929 45.7672 20.357 45.4517 19.7941 44.4937L5.66753 20.452C4.77821 18.9385 2.57585 19.2348 2.23541 20.957C0.142873 31.5422 -0.816973 49.7661 7.37298 63.4388C14.6487 75.5851 26.5343 76.0252 36.7853 73.2683C37.6831 73.0269 38.6396 73.4012 39.1044 74.2063L77.075 139.973C77.5379 140.775 77.3876 141.786 76.734 142.442C68.5764 150.621 62.4002 162.157 70.1295 175.545C78.7542 190.483 96.6089 199.084 107.556 202.547C109.214 203.071 110.548 201.317 109.691 199.803L94.708 173.338Z" fill="#191936"/>
        </svg>
      </div>

      <svg width="100%" height="83" viewBox="0 0 1440 83" preserveAspectRatio="none"  fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M-4.70996e-06 60.2854L1440 0.785514L1440 943.498L-6.29087e-05 795.498L-4.70996e-06 60.2854Z" fill="#191936"/>
      </svg>
    </div>
  </div>
}
