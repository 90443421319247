import { CircularProgress } from '@material-ui/core'

const Container = styled.div`
  height: ${props => props.height};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const Loading = ({ text = "Loading", height = "30vh" }) => {
  return (
    <Container>
      <h2>{text + '...' || 'Loading...'}</h2>
      <CircularProgress />
    </Container>
  )
}

export default Loading
