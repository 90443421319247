import React, { useEffect } from 'react'
import { Field, useField, useFormikContext } from 'formik'

const SizingMode = () => {
  const { values, submitForm, setFieldValue } = useFormikContext<{
    sizingMode: string
    width: number
  }>()

  const [widthPercentsField, , { setValue: setWidthPercentsFieldValue }] = useField({
    name: 'width',
  })
  useEffect(() => {
    if (values.sizingMode == 'percent' && values.width < 0) {
      setWidthPercentsFieldValue(0)
    } else {
      submitForm()
    }
  }, [values.width, values.sizingMode])
  return (
    <>
      <div className="field">
        <label>Sizing mode</label>

        <label className="radio-input">
          <Field
            type="radio"
            name="sizingMode"
            value=""
            onChange={(e) => {
              setWidthPercentsFieldValue('')
              setFieldValue(e.target.name, e.target.value)
            }}
          />
          <span>pixel width</span>
        </label>
        <label className="radio-input">
          <Field
            type="radio"
            name="sizingMode"
            value="percent"
            onChange={(e) => {
              setWidthPercentsFieldValue(100)
              setFieldValue(e.target.name, e.target.value)
            }}
          />
          <span>percent width</span>
        </label>
      </div>
      {values.sizingMode === 'percent' && (
        <div className="field margin-bottom">
          <label>Width %</label>
          <Field type="number" name={widthPercentsField.name} min={0} step={0.1} />
        </div>
      )}
    </>
  )
}

export default SizingMode
