import { useContext } from 'react';
import Context from './Context'; // Import the context you created

const useAct = () => {
  const context = useContext(Context);
  if (context === undefined) {
    throw new Error('useAct must be used within a ActProvider');
  }
  return context;
};

export default useAct;
