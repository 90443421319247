export default function IntroGraphicBallot() {
  const style = {
    width: '100%',
    maxWidth: '416px',
    height: 'auto'
  }

  return (
    <svg style={style} width="416" height="259" viewBox="0 0 416 259" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.4">
        <path d="M0.254613 34.169C0.131056 33.6097 0.501516 33.0612 1.06642 32.9669L11.8109 31.1734C12.3758 31.0791 12.9043 31.4776 12.9691 32.0467L19.2638 87.3464C19.3239 87.875 18.9596 88.3582 18.4348 88.4458L13.4029 89.2857C12.8781 89.3733 12.3766 89.0346 12.2618 88.5151L0.254613 34.169Z" fill="#00F890" />
        <circle cx="18.2105" cy="102.594" r="6.5" transform="rotate(-9.47635 18.2105 102.594)" fill="#00F890" />
      </g>
      <path opacity="0.4" fill-rule="evenodd" clip-rule="evenodd" d="M149.64 32.9647C148.716 33.5578 147.486 33.295 146.885 32.3761L144.487 28.7082L142.107 25.0686C141.499 24.1386 141.766 22.8912 142.701 22.291L148.115 18.8158C149.593 17.8675 149.184 15.7621 147.431 15.6638C143.447 15.4404 138.284 15.9301 134.285 18.5451C130.075 21.2975 129.82 25.4296 130.789 29.0999C131.026 29.9949 130.713 30.9637 129.939 31.4703L108.806 45.2883C108.06 45.7754 107.092 45.6985 106.364 45.1851C103.618 43.2467 100.153 42.2525 96.4758 44.7423C92.8386 47.205 90.5663 51.3789 89.306 54.861C88.714 56.4965 90.4673 57.6888 91.9158 56.7259L96.6849 53.5552C97.5993 52.9473 98.8327 53.1903 99.4483 54.0994L101.675 57.3876L103.882 60.6477C104.505 61.5679 104.258 62.8194 103.333 63.4346L98.6804 66.5278C97.2185 67.4997 97.6634 69.5903 99.4181 69.6421C103.103 69.7507 107.767 69.1782 111.369 66.7391C115.151 64.1783 115.428 60.457 114.561 57.1142C114.327 56.2144 114.637 55.2401 115.415 54.7313L136.243 41.1126C137.018 40.606 138.031 40.7085 138.756 41.284C141.729 43.6443 145.617 45.0674 149.827 42.3149C153.861 39.6769 156.39 35.0866 157.775 31.3292C158.376 29.6972 156.635 28.4744 155.172 29.4139L149.64 32.9647Z" fill="#00F399" />
      <path opacity="0.4" fill-rule="evenodd" clip-rule="evenodd" d="M327.561 96.0113C326.587 95.4406 325.335 95.7882 324.794 96.7792L303.991 134.923C303.467 135.884 303.812 137.087 304.765 137.623L329.505 151.554L319.561 168.52C319.003 169.473 319.322 170.699 320.275 171.257L322.598 172.619C323.551 173.177 324.777 172.858 325.335 171.905L335.338 154.838L360.345 168.919C361.282 169.447 362.47 169.139 363.032 168.22L385.126 132.113C385.711 131.159 385.397 129.91 384.432 129.344L327.561 96.0113Z" fill="#00F399" />
      <path opacity="0.4" d="M46.5679 156.001L39.3791 143.608C38.9741 142.91 39.0284 142.037 39.5168 141.395L82.1867 85.2529C83.0222 84.1537 84.6939 84.2116 85.4513 85.3661L116.788 133.134C117.496 134.212 117.013 135.668 115.801 136.11L82.9702 148.073C82.2295 148.343 81.723 149.024 81.6297 149.807C80.6097 158.368 75.7469 165.139 71.2155 169.486C69.7499 170.892 67.6597 169.162 68.1015 167.18C69.3555 161.554 68.0772 157.071 66.7026 154.619C66.3303 153.955 65.555 153.671 64.8077 153.817L48.6805 156.96C47.8438 157.123 46.9957 156.738 46.5679 156.001Z" fill="#00F890" />
      <path opacity="0.4" d="M304.527 23L309.007 29.2175L315.188 24.6885L317.527 31.986L324.806 29.5889L324.775 37.252L332.438 37.2214L330.041 44.5L337.339 46.8389L332.81 53.0205L339.027 57.5L332.81 61.9795L337.339 68.1611L330.041 70.5L332.438 77.7786L324.775 77.748L324.806 85.4111L317.527 83.014L315.188 90.3114L309.007 85.7825L304.527 92L300.048 85.7825L293.866 90.3114L291.527 83.014L284.249 85.4111L284.279 77.748L276.616 77.7786L279.013 70.5L271.716 68.1611L276.245 61.9795L270.027 57.5L276.245 53.0205L271.716 46.8389L279.013 44.5L276.616 37.2214L284.279 37.252L284.249 29.5889L291.527 31.986L293.866 24.6885L300.048 29.2175L304.527 23Z" fill="#00F399" />
      <g opacity="0.4">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M376.247 27.9865C376.297 27.3936 376.819 26.9537 377.412 27.0039L414.986 30.1891C415.579 30.2394 416.019 30.7608 415.969 31.3537L410.872 91.4729C410.822 92.0658 410.301 92.5057 409.708 92.4555L372.133 89.2703C371.54 89.22 371.1 88.6986 371.151 88.1057L376.247 27.9865ZM395.199 40.4057C396.681 40.5313 397.984 39.4316 398.11 37.9493C398.236 36.467 397.136 35.1635 395.654 35.0379C394.171 34.9122 392.868 36.012 392.742 37.4943C392.617 38.9765 393.716 40.28 395.199 40.4057Z" fill="#00F399" />
      </g>
      <rect x="130.027" y="162.957" width="152" height="95.8261" rx="2" fill="#00F890" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M180.065 131.565C179.754 131.565 179.448 131.638 179.17 131.776L137.605 152.559C135.718 153.503 136.389 156.348 138.5 156.348H273.555C275.666 156.348 276.337 153.503 274.45 152.559L232.885 131.776C232.607 131.638 232.301 131.565 231.99 131.565H180.065ZM181.593 139.826C180.488 139.826 179.593 140.722 179.593 141.826V144.435C179.593 145.539 180.488 146.435 181.593 146.435H228.81C229.915 146.435 230.81 145.539 230.81 144.435V141.826C230.81 140.722 229.915 139.826 228.81 139.826H181.593Z" fill="#00F890" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M183.245 57.2174C182.14 57.2174 181.245 58.1128 181.245 59.2174V108.087C181.245 109.192 182.14 110.087 183.245 110.087H228.81C229.915 110.087 230.81 109.192 230.81 108.087V59.2174C230.81 58.1128 229.915 57.2174 228.81 57.2174H183.245ZM218.519 77.1437C219.487 76.1759 219.487 74.6067 218.519 73.6389C217.551 72.6711 215.982 72.6711 215.014 73.6389L201.071 87.5822L197.867 84.378C196.899 83.4102 195.33 83.4102 194.362 84.378C193.394 85.3459 193.394 86.915 194.362 87.8828L199.318 92.8394C200.286 93.8072 201.855 93.8072 202.823 92.8394L218.519 77.1437Z" fill="#00F890" />
    </svg>
  )
}
