import classNames from 'classnames'
import RrIconButton from '../RrIconButton'
import RrIcon from '../RrIcon'

const CcTitle = ({ title, onClose, className }) => (
  <div className={classNames('box no-padding align-center cc-card-title', className)}>
    <h1 className="text-center full-width">{title}</h1>
    <RrIconButton onClick={onClose}>
      <RrIcon iconName="close" />
    </RrIconButton>
  </div>
)

export default CcTitle
