import './HtmlBlock.scoped.scss'
import './blocks/Block.scoped.scss'
import CodeMirrorEditor from 'Shared/components/CodeMirrorEditor'

export default function EditHead({ emailTemplateId }) {
  const act = useAct()
  const [editing, setEditing] = useState(false)
  const styles = useSel((s) => s.emailBuilder.styles)
  const [rawStyles, setRawStyles] = useState(styles)

  const handleSave = async () => {
    act.emailTemplate.upsert({
      styles: rawStyles
    })
    await act.emailTemplate.persist()
    await act.emailTemplate.load(emailTemplateId)
    setEditing(false)
  }

  const handleEditorChange = (value) => {
    setRawStyles(value)
  }

  const handleCancel = () => {
    setRawStyles(styles)
    setEditing(false)
  }

  return (
    <>
      {!editing ? (
        <div className="wipe-css header-container html-block-container dashed-bottom-border edit-styles">
          <div className="foundation-container margin-bottom">
            {emailTemplateId && (
              <div className="edit-button-container no-reset">
                {rawStyles?.length > 0 &&
                  <p className='css-notice'>You've added custom CSS to this template.</p>}
                <button
                  onClick={() => setEditing(true)}
                  className="button blue small secondary ">
                  Edit CSS
                </button>
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className="header-container block-container active">
          <div className="editing-container">
            <CodeMirrorEditor
              value={rawStyles}
              onChange={handleEditorChange}
              mode="css"
            />
            <div className="buttons-container">
              <button
                className="button primary small t-save"
                onClick={handleSave}
              >
                Save
              </button>
              <button
                className="button secondary small margin-left t-cancel"
                onClick={handleCancel}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  )
}
