import * as React from 'react'

function SvgPlus(props) {
  return (
    <svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect x={3.333} y={9.167} width={13.333} height={1.667} rx={0.833} fill="currentColor" />
      <rect
        x={10.833}
        y={3.333}
        width={13.333}
        height={1.667}
        rx={0.833}
        transform="rotate(90 10.833 3.333)"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgPlus
