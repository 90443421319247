const BlockStyles = ({ block, active }) => {
  const selector = active
    ? `#block_${block.id} .injected-styles *`
    : `#block_${block.id} p, #block_${block.id} ol`

  return (
    <style>
      {`
      ${selector} {
        font-size: ${block.parameters.fontSize || 12}px !important;
        font-family: '${block.parameters.fontFamily || 'proxima-nova'}' !important;
        ${block.parameters.textAlign
          ? `text-align: ${block.parameters.textAlign || 'left'} !important;`
          : ''
        }
      ${selector} a span {
        text-decoration: underline;
        color: #2199e8;
      }
      `}
    </style>
  )
}

export default BlockStyles
