import { LinearProgress } from '@material-ui/core'

const Root = styled.div`
  div.children {
    filter: ${({ show, blur, gray }) =>
      show ? `${gray ? 'grayscale(1)' : ''} ${blur ? 'blur(5px)' : ''}` : ''};
    opacity: ${({ show, fade }) => (show && fade ? 0.3 : 1)};
    transition: all 750ms ease;
    transition-property: filter, opacity;
  }
  * {
    pointer-events: ${({ show }) => show && 'none'};
    user-select: ${({ show }) => show && 'none'};
  }
  [role='progressbar'] {
    margin-bottom: 8px;
    margin-top: -12px;
  }
`

const Deactivate = ({ children, show, blur = true, gray = true, fade, progress }) => {
  return (
    <Root {...{ show, blur, gray, fade }}>
      <div className="children">{children}</div>
      {show && progress && <LinearProgress />}
    </Root>
  )
}

export default Deactivate
