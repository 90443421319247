import { isoDateString } from 'Shared/functions/util'
import Calendar from 'react-calendar'
import './DateRange.scoped.scss'
import Button from 'Shared/components/Button'

const cloneDate = (date) => {
  return new Date(date.getTime())
}

const monthsAgo = (date, count) => {
  const newDate = cloneDate(date)
  newDate.setMonth(newDate.getMonth() - count)
  return newDate
}

const isSameDate = (date1, date2) => {
  return date1.getTime() === date2.getTime()
}

const DateRange = ({getValue, setValue}) => {
  const dateRange = getValue()[0]
  const [dateFrom, setDateFrom] = useState(new Date(dateRange.from))
  const [dateTo, setDateTo] = useState(new Date(dateRange.to))
  const [activeStartDateFrom, setActiveStartDateFrom] = useState(dateFrom)
  const [activeStartDateTo, setActiveStartDateTo] = useState(dateTo)

  const act = useAct()

  const today = new Date()
  today.setHours(0, 0, 0, 0)

  const last7Days = {
    from: new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7),
    to: today
  }
  const lastMonth = { from: monthsAgo(today, 1), to: today }
  const last3Months = { from: monthsAgo(today, 3), to: today }
  const last6Months = { from: monthsAgo(today, 6), to: today }
  const lastYear = { from: monthsAgo(today, 12), to: today }

  const setRange = (range)  => {
    setDateFrom(range.from)
    setDateTo(range.to)
    setActiveStartDateFrom(range.from)
    setActiveStartDateTo(range.to)
  }

  const rangeButton = (range, label) => {
    const classNames = ['button']
    if(isSameDate(dateFrom, range.from) && isSameDate(dateTo, range.to)) {
      classNames.push('active')
    }
    return (
      <button className={classNames.join(' ')} onClick={() => setRange(range)}>{label}</button>
    )
  }

  const handleFromChange = (v) => {
    setDateFrom(v)
    if(v > dateTo) {
      setDateTo(v)
    }
  }

  const handleToChange = (v) => {
    setDateTo(v)
    if(v < dateFrom) {
      setDateFrom(v)
    }
  }

  const apply = () => {
    const stringDateRange = { from: isoDateString(dateFrom), to: isoDateString(dateTo) }
    act.analytics.update({ dateRange: stringDateRange })
    act.analytics.load()
    setValue(stringDateRange)
  }

  return (
    <div className="date-range">
      <div className="date-range-presets">
        <span>Select a date range</span>
        {rangeButton(last7Days, '7 days')}
        {rangeButton(lastMonth, 'Last month')}
        {rangeButton(last3Months, 'Last 3 months')}
        {rangeButton(last6Months, 'Last 6 months')}
        {rangeButton(lastYear, 'Last year')}
      </div>
      <div className="date-range-calendars">
        <div>
          <h4>From</h4>
          <Calendar
            onChange={handleFromChange}
            value={dateFrom}
            activeStartDate={activeStartDateFrom}
            onActiveStartDateChange={({activeStartDate}) => setActiveStartDateFrom(activeStartDate)}
            showNeighboringMonth={false}
            maxDetail="month"
            minDetail="month" />
        </div>
        <div>
          <h4>To</h4>
          <Calendar
            onChange={handleToChange}
            value={dateTo}
            activeStartDate={activeStartDateTo}
            onActiveStartDateChange={({activeStartDate}) => setActiveStartDateTo(activeStartDate)}
            showNeighboringMonth={false}
            maxDetail="month"
            minDetail="month" />
        </div>
      </div>
      <Button small primary onClick={apply}>Apply</Button>
    </div>
  )
}

export default DateRange
