import Message from './Message'
import Chip from 'Shared/components/Chip'
import useChunks from 'DesktopMessager/useChunks'
import './MessageHistory.scoped.scss'
import { format, parseISO } from 'date-fns'

const MessageHistory = ({ height }) => {
  const act = useAct()
  const chunks = useChunks()
  const messagesRef = useRef()
  const messagesEndRef = useRef()

  // this notifies us a new message has been added to the last chunk
  useEffect(() => {
    // scroll it into view
    let timeout = setTimeout(function scroll() {
      if (messagesEndRef.current) {
        messagesEndRef.current.scrollIntoView({ behavior: 'smooth' })
      } else {
        timeout = setTimeout(scroll, 100)
      }
    }, 500)

    return () => {
      if (timeout) clearTimeout(timeout)
    }
  }, [JSON.stringify(chunks[chunks.length - 1])])

  if (chunks.length === 0) return null

  return (
    <div className="messages-container">
      <div ref={messagesRef}>
        {chunks.map(({ date, messageIds }, cIdx) => {
          const niceDate = format(parseISO(date), 'eee, MMM dd, yyyy')

          return (
            <div className={cIdx === chunks.length - 1 ? 'chunk last' : 'chunk'}>
              <div key={date} className="message-block message-block-date">
                <Chip>{niceDate}</Chip>
              </div>
              {messageIds.map((id, mIdx) => {
                const initialMessage = !cIdx && !mIdx
                return <div className="message-block">
                  <Message id={id} initialMessage={initialMessage} />
                </div>

              })}
              <div ref={messagesEndRef}></div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default MessageHistory
