import Card from "../Card/Card"
import './CardPicker.scoped.scss'

const CardPicker = ({value, options, onChange}) => {

  const renderCards = () => (
    options.map(({ value: cardValue, ...rest }, i) => (
      <Card key={i}
        interactive
        active={cardValue === value}
        onClick={() => onChange(cardValue)}
        {...rest}
      />
    )
    )
  )

  return (
    <div className="card-picker">
      {renderCards()}
    </div>
  )

}

export default CardPicker
