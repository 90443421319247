import Modal from 'Shared/components/Modal/Modal'
import useAct from 'DesktopCaller/useAct'
import './css/DialpadModal.scoped.scss'

const keys = [
  '1', '2', '3',
  '4', '5', '6',
  '7', '8', '9',
  '*', '0', '#'
]

export default function DialpadModal() {
  const act = useAct()
  const close = () => act.local.upsert({ showDialpad: false })

  return <Modal autoWidth autoHeight open title={i18n.t('user.campaigns.phone.keypad')} onCancel={close}>
    <div className='content'>
      {keys.map((key, i) =>
        <button className='text-center' onClick={() => act.desktopCaller.sendDigit(key)} disabled={key === '*'}>{key}</button>
      )}
    </div>
  </Modal>
}

