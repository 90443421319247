import useAct from 'DesktopCaller/useAct'
import useData from 'DesktopCaller/useData'
import goalActions from 'Shared/components/GoalButton/actions'
import LabeledCard from 'Shared/components/LabeledCard/LabeledCard'

export default function Redial() {
  const act = useAct()
  const { callStage, inputStage } = useData((rs) => rs.callSession)
  const campaignTargetId = useData((rs) => rs.campaignTarget?.id)
  const goalId = useData((rs) => rs.campaign?.settings?.goalId)
  const { completeGoalOnDone } = useSel((s) => s.local)

  const hangUp = async () => {
    if (completeGoalOnDone) {
      await goalActions.markGoalComplete(campaignTargetId, goalId)
    }
    act.desktopCaller.hangUp()
  }

  return (
    <LabeledCard
      label={
        <>
          <SvgIconsCallerInstruction />
          {i18n.t('user.campaigns.phone.are_you_finished')}
        </>
      }
    >
      <div className="horizontal-action">
        <p className="no-margin-top">{i18n.t('user.campaigns.phone.thanks_for_time')}</p>
        <button
          className="button red small"
          onClick={hangUp}
        >
          <SvgIconsCallerHangUp />{' '}
          <span>{i18n.t('user.campaigns.phone.end_call')}</span>
        </button>
      </div>
    </LabeledCard>
  )
}
