import restClient from 'Shared/hooks/restClient'
import { v4 as uuidv4 } from 'uuid'
import { useMemo, useState, useEffect } from 'react'

export default function StaticBlock({ block, blockTemplate, active }) {
  const [html, setHtml] = useState('<span>Loading...</span>')
  const campaignId = useSel(s => s.campaign.id)
  const styles = useSel(s => s.emailBuilder.styles)

  // Generate a stable scope ID
  const scopeId = useMemo(() => uuidv4(), [])

  // Scope styles to the unique class
  const scopeStyles = (css, scopeClass) => {
    return css.replace(/(^|}|;)\s*([^@{}]+)\s*{/g, (match, boundary, selector) => {
      if (selector.trim().startsWith('@')) {
        return match; // Skip @ rules
      }
      const scopedSelector = selector
        .split(',')
        .map(s => `${scopeClass} ${s.trim()}`)
        .join(', ');
      return `${boundary} ${scopedSelector} {`;
    });
  };

  const scopedStyles = useMemo(() => scopeStyles(styles || '', `.scoped-${scopeId}`), [styles, scopeId]);

  const renderBlock = useMemo(() => {
    function renderBlock(block) {
      restClient.post('/api/render/render_block', { campaignId, block, blockTemplate, styles })
        .then(({ data }) => { setHtml(data.html) })
    }

    const dRenderBlock = _.debounce(renderBlock, 250)
    let firstCall = true

    // Remove lag on first load
    return (block) => {
      if (firstCall) {
        firstCall = false
        renderBlock(block)
      } else {
        dRenderBlock(block)
      }
    }
  }, [campaignId, blockTemplate, styles])

  useEffect(() => {
    renderBlock(block)
  }, [block, renderBlock])

  const memoHtml = useMemo(() => {
    return (
      <div className='wipe-css'>
        {/* Scoped styles */}
        <style dangerouslySetInnerHTML={{ __html: scopedStyles }}></style>
        <div className={`foundation-container scoped-${scopeId}`} dangerouslySetInnerHTML={{ __html: html }} />
      </div>
    )
  }, [html, scopedStyles, scopeId])

  if (active) return null

  return memoHtml
}
