import * as React from 'react'

function SvgCallers(props) {
  return (
    <svg width={24} height={24} viewBox={"0 0 24 24"} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g>
        <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.079 14.7843L15.283 12.5803C15.8917 11.9716 16.0426 11.0416 15.6576 10.2716L15.5327 10.0219C15.1478 9.2519 15.2987 8.32196 15.9074 7.71324L18.6402 4.98043C18.8355 4.78517 19.152 4.78517 19.3473 4.98043C19.3853 5.01843 19.4169 5.06231 19.441 5.11038L20.5449 7.31833C21.3895 9.00744 21.0584 11.0475 19.7231 12.3828L13.7243 18.3816C12.2707 19.8352 10.1207 20.3427 8.17056 19.6927L5.75608 18.8879C5.49411 18.8005 5.35253 18.5174 5.43986 18.2554C5.4644 18.1818 5.50576 18.1149 5.56064 18.06L8.21192 15.4087C8.82064 14.8 9.75058 14.6491 10.5206 15.0341L10.7703 15.1589C11.5403 15.5439 12.4702 15.393 13.079 14.7843Z"
        fill="currentColor"
        />
        <path
        opacity="0.3"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.315 4.01369L14.5 2.02227C11.2625 1.72144 8.04293 2.85235 5.69862 5.19667C3.35754 7.53774 2.22643 10.7517 2.52302 13.9851L4.51466 13.8024C4.27177 11.1546 5.19639 8.52732 7.11283 6.61088C9.03192 4.69179 11.6637 3.76734 14.315 4.01369ZM13.9586 7.99794L14.15 6.00712C12.0832 5.80844 10.0242 6.5279 8.52704 8.02509C7.03476 9.51738 6.31496 11.5678 6.50721 13.6284L8.49856 13.4426C8.36103 11.9685 8.87438 10.5062 9.94126 9.43931C11.0116 8.36893 12.4801 7.85581 13.9586 7.99794Z"
        fill="currentColor"
        />
    </g>
    </svg>
  )
}

export default SvgCallers
