import SendProgress from 'SendBlast/components/SendProgress'
import Description from 'SearchBuilder/components/Description'
import useCountTargets from 'CampaignBuilder/components/Steps/Audience/useCountTargets'

export default function SendBlast ({ campaignId }) {
  const act = useAct()
  const [estimateLoaded, setEstimateLoaded] = useState(false)

  const { searchId, entityId, entityType, settings, entity } = useSel(s => {
    const { searchId, campaignType, textMessageBlast, emailBlast, settings } = s.campaign

    if (textMessageBlast) {
      var entityId = textMessageBlast.id
      var entityType = 'textMessageBlast'
    } else if (emailBlast) {
      var entityId = emailBlast.id
      var entityType = 'emailBlast'
    }
    const entity = textMessageBlast || emailBlast

    return { searchId, campaignType, entityId, entityType, settings, entity }
  })
  const { open, sending } = useSel(s => s.sendBlast)

  useEffect(() => {
    if (!searchId) return
    act.campaignBuilder.updateEstimates(searchId).then(() => setEstimateLoaded(true))
  }, [searchId])

  // we can't mount without a search
  const search = useSel(s => s.campaign.search)
  const allPermissions = useSel(s => s.currentUser.allPermissions || [])
  const { baseCost, contactCost, creditRemaining } = useSel(s => s.campaignBuilder)
  const insufficientCredit = window.ENV.FEATURES['budget_enforcement'] && (baseCost + contactCost) > creditRemaining
  const disabled = !estimateLoaded || insufficientCredit

  var targetCount = useCountTargets()
  if (settings.testMode === 'ab') {
    const testSendSize = parseInt(settings.testSizePerVariant) * entity.contentVariants.length
    targetCount = Math.min(testSendSize, targetCount)
  }

  if (!search) return null

  const canSend = allPermissions.includes('manage_campaigns') || (allPermissions.includes('manage_small_campaigns') && targetCount <= window.ENV.SMALL_CAMPAIGN_THRESHOLD)

  const handleSendClick = () => {
    act.sendBlast.send(campaignId, entityType, entityId)
  }

  const handleSendForApprovalClick = () => {
    act.sendBlast.sendForApproval(campaignId)
  }

  function redirect() {
    window.location = `/admin/campaigns/${campaignId}/overview`
  }

  return <div className={classes("modal-container", {visible: open})}>
    <div className="modal">
      <div className="header">
        {sending === false &&
          <>
            <h2>Final Confirmation</h2>
            <a className="close js-close-modal" onClick={() => act.sendBlast.close()}>X</a>
          </> }
        {sending === true &&
          <>
            <h2>You&lsquo;ve sent your blast - well done</h2>
            <a className="close js-close-modal" onClick={() => redirect()}>X</a>
          </> }
      </div>

      <div className='content'>
        {(sending === false && searchId) &&
          <>
            { canSend && <>
              <h2 className='text-center'>You’re going to send this message to { targetCount } people</h2>
              <Description searchId={searchId} center={true} embedded={true} />
              { !disabled && <h4 className='text-center no-margin-top'>If you're happy to send this message now, press 'Send'</h4> }
              { insufficientCredit && <h4 className='text-center no-margin-top error'>You don't have enough credit to send this message. Please <a href='/admin/budget'>top up your account</a> and try again.</h4> }
              <div className='buttons center margin-top margin-bottom double'>
                <button onClick={() => act.sendBlast.close()} className='button red large margin-right'>Go back</button>
                <button disabled={disabled} className='button large primary' onClick={handleSendClick}>Send</button>
              </div>
            </> }
            { !canSend && <>
              <h2 className='text-center'>This message is for { targetCount } people</h2>
              <Description searchId={searchId} center={true} embedded={true} />
              { !disabled && <h4 className='text-center no-margin-top'>This message requires approval to send. If approved it will send immediately. If you're happy to send this message for approval now, press 'Send for approval'.</h4> }
              { insufficientCredit && <h4 className='text-center no-margin-top error'>You don't have enough credit to send this message. Please contact your administrator to ensure there is enough credit.</h4> }
              <div className='buttons center margin-top margin-bottom double'>
                <button onClick={() => act.sendBlast.close()} className='button red large margin-right'>Go back</button>
                <button disabled={disabled} className='button large primary' onClick={handleSendForApprovalClick}>Send for approval</button>
              </div>
            </>}
          </>
        }
        { sending && canSend && <SendProgress campaignId={campaignId} entityType={entityType} entityId={entityId} embedded={true} /> }
        { sending && canSend && <div className='margin-top buttons center'>
          <button onClick={() => redirect()} className='button large primary'>Check out your new campaign</button>
        </div> }
        { sending && !canSend && <div className='margin-top buttons center'>
          <p>Your message has been sent for approval</p>
          <button onClick={() => window.location = `/admin/campaigns`} className='button naked blue-link'>Back to campaigns</button>
        </div> }
      </div>
    </div>
  </div>
}

