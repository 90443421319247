import { formatDuration } from 'Shared/functions/util'
import { DurationInput } from 'Shared/components/DurationInput'
import AsyncSelect from 'Shared/components/AsyncSelect'

import './Field.scoped.scss'

export default function Field({ value, onChange, setting }) {
  const { kind } = setting

  function handleChange(e) {
    onChange(e.target.value)
  }

  function handleAsyncSelectChange(v) {
    onChange(v.join(','))
  }

  const handleChangeStart = (v) => {
    onChange([v, value[1]]);
  };

  const handleChangeEnd = (v) => {
    onChange([value[0], v]);
  };

  const handleMultiCheck = (key, value) => {
    const strValue = value ? 'true' : 'false'
    onChange({ key: key, value: strValue })
  }

  if (kind === 'select') {
    return <select value={value} onChange={handleChange}>
      { setting.options.map((option) => <option key={option.value} value={option.value}>{option.label}</option>) }
    </select>
  } else if (kind === 'async_select') {
    return <AsyncSelect
      entity={setting.entity}
      multiple
      value={value ? (value || '').split(',') : null}
      loadStaticList={true}
      onChange={handleAsyncSelectChange}
    />
  } else if (kind === 'multi_checkbox') {
    return <div className="multi-checkbox">
      { setting.options.map((option, i) => <div key={option.value}>
        <label>
          <input type='checkbox' id={option.value} checked={value[i] === 'true'} onChange={(e) => handleMultiCheck(option.value, e.target.checked)} />
          {option.label}
        </label>
      </div>) }
    </div>
  } else if (kind === 'time') {
    return <input type='time' value={value} onChange={handleChange} />
  } else if (kind === 'time_range') {
    return <div className="time-range">
      <input type='time' className='margin-right' value={value[0]} onChange={(e) => handleChangeStart(e.target.value)} />
      <span>to</span>
      <input type='time' className='margin-left' value={value[1]} onChange={(e) => handleChangeEnd(e.target.value)} />
    </div>
  } else if (kind === 'duration_range') {
    return (
      <div className="duration-range">
        <DurationInput
          duration={value[0]}
          onChange={handleChangeStart}
          onEnter={handleChangeStart}
        />
        <span>-</span>
        <DurationInput
          duration={value[1]}
          onChange={handleChangeEnd}
          onEnter={handleChangeEnd}
        />
      </div>
    );
  } else if (kind === 'date') {
    return <input type='date' value={value || ''} onChange={handleChange} />
  } else if (kind === 'number') {
    return <input type='number' value={value || ''} onChange={handleChange} />
  } else if (kind === 'text') {
    return <input type='text' value={value || ''} onChange={handleChange} />
  } else {
    return null
  }
}
