import * as React from 'react'

function SvgNotifications(props) {
  return (
    <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.5888 3C9.10355 3 7.08883 5.01472 7.08883 7.5V12H5.59375C4.76532 12 4.09375 12.6716 4.09375 13.5C4.09375 14.3284 4.76532 15 5.59375 15H18.5675C19.3959 15 20.0675 14.3284 20.0675 13.5C20.0675 12.6716 19.3959 12 18.5675 12H17.0724V7.5C17.0724 5.01472 15.0577 3 12.5724 3H11.5888Z"
        fill="#8792A2"
      />
      <rect x={10} y={16} width={4} height={4} rx={2} fill="#8792A2" />
    </svg>
  )
}

export default SvgNotifications
