export default (dis, store, client) => {
    function csvExport() {
      const id = store.getState().local.workflowId

      return client.post(`/api/workflows/${id}/export`)
        .then(({ data: {password} }) => password)
    }

    return {
      csvExport
    }
}