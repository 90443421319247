import './Survey.scoped.scss'
import useAct from '../hooks/useAct'
import useNav from '../hooks/useNav'
import { useNavigate, useParams } from 'react-router-dom'

import ServerWaitButton from 'Shared/components/ServerWaitButtonStandalone'

export default function Finished() {
  const act = useAct()
  const navigate = useNavigate()
  const nav = useNav()
  let { surveyId } = useParams()

  function complete() {
    return act.surveyTaker.complete().then(() => {
      navigate(`/target/surveys/${surveyId}/completed`)
    })
  }

  const back = () => {
    return act.surveyTaker.back().then(({ currentQuestionId, lastValue }) => {
      nav.toQuestion({ surveyId, questionId: currentQuestionId, initialResponse: lastValue })
    })
  }

  return (
    <div className="intro-container">
      <p>All done? Click Finish to continue</p>
      <div className='actions'>
        <ServerWaitButton large secondary dark onClick={back}>Back</ServerWaitButton>
        <ServerWaitButton large light primary onClick={complete}>Finish</ServerWaitButton>
      </div>
    </div>
  )
}

