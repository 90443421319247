import { renderToString } from 'react-dom/server'
import './textMessages.scoped.scss'

function Status({ status }) {
  const labels = {
    delivered: 'Delivered',
    sent: 'Sent',
    not_sent: 'Waiting',
    failed: 'Not sent'
  }

  return <div className="status">
    {status === 'failed' && <div className='icon'><SvgIconsRedCross /></div>}
    {labels[status]}
  </div>
}

function HasReplied({ hasReplied, archived }) {
  return <div className="has-replied align-center">
    {hasReplied ? <SvgIconsTickGreen /> : <SvgIconsRedCross />}
    <p className='no-margin-top no-margin-bottom margin-left'>{archived ? '' : ' (awaiting reply)'}</p>
  </div>
}

const columns = [
  {
    data: 'name',
    width: '30%',
    className: 'bold',
    render: function(data, type, row, meta) {
      return `<a href='${row['url']}'>${data}</a>`
    },
  },
  {
    data: 'clicked',
    width: '14%',
    render: (data, type, row, meta) => {
      if (row['clicked'] === 'true') {
        return renderToString(<SvgIconsTickGreen />)
      } else {
        return renderToString(<SvgIconsRedCross />)
      }
    }
  },
  {
    data: 'triggered_opt_out',
    width: '14%',
    render: (data, type, row, meta) => {
      if (row['triggered_opt_out'] === 'true') {
        return renderToString(<SvgIconsRedCross />)
      } else {
        return renderToString(<SvgIconsRedCross />)
      }
    }
  },
  {
    data: 'status',
    width: '14%',
    render: (data, type, row, meta) => {
      return renderToString(<Status status={row['status']} />)
    }
  },
  {
    data: 'target_has_replied',
    render: (data, type, row, meta) => {
      return renderToString(<HasReplied hasReplied={row['target_has_replied'] === 'true'} archived={row['archived'] === 'true'} />)
    }
  }
]

const headers = [
  'Name',
  'Clicked',
  'Opted out',
  'Status',
  'Replied'
]

const filters = [
  {
    name: 'Clicked', kind: 'radio', column: 1, options: [
      { value: '', label: 'All' },
      { value: false, label: "Didn't click" },
      { value: true, label: "Clicked" }
    ]
  },
  {
    name: 'Opted out', kind: 'radio', column: 2, options: [
      { value: '', label: 'All' },
      { value: false, label: "Didn't opt out" },
      { value: true, label: "Did opt out" }
    ]
  },
  {
    name: 'Replied', kind: 'radio', column: 4, options: [
      { value: '', label: 'All' },
      { value: false, label: "Hasn't replied" },
      { value: true, label: "Has replied" }
    ]
  }
]

const sorters = [

]

export { columns, headers, filters, sorters }
