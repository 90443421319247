export default function createActions(dis, store, restClient, act) {
  return {
    campaignStats: {
      emailExport() {
        const campaignId = store.getState().local.campaignId
        return restClient.post(`/api/campaigns/${campaignId}/export`)
        .then(({ data: {password} }) => password)
        .catch(() => {
          dis({ type: 'local/upsert', payload: { alert: {
            severity: 'error',
            message: "Something went wrong and the campaign couldn't be exported."
          } } })
        })
      },
      emailConversationsExport() {
        const campaignId = store.getState().local.campaignId
        return restClient.post(`/api/campaigns/${campaignId}/export?conversations=true`)
        .then(({ data: {password} }) => password)
        .catch(() => {
          dis({ type: 'local/upsert', payload: { alert: {
            severity: 'error',
            message: "Something went wrong and the campaign couldn't be exported."
          } } })
        })
      }
    },
    local: {
      upsert(payload) {
        dis({ type: 'local/upsert', payload })
      },
    },
  }
}
