import './SmsPreview.scoped.scss'

const SmsPreview = ({ name, content, kind = 'sms', actions = [], short = false }) => {
  const { body, media = [] } = content

  return (
    <div className={`phone-preview ${short ? 'short' : ''}`}>
      <div className="name">{name}</div>
      <div className={`body ${kind === 'whatsapp' ? 'whatsapp' : ''}`}>
        <div className="screen">
          <div className="time">Sun, Jan 30 1:20PM</div>
          <div className="preview">
            {media && (
              <div className="media">
                {media.map((url, i) => (
                  <Media key={i} url={url} />
                ))}
              </div>
            )}
            <div className="content">
              <p
                className="flex-column"
                dangerouslySetInnerHTML={{
                  __html: (body || '').replaceAll('\n', '<br />'),
                }}
              ></p>
            </div>
            {actions.length > 0 && (
              <div className="actions">
                {actions.map((action, index) => (
                  <div key={index} className="action">
                    <div className="icon">
                      {action.kind === 'list-picker' ? (
                        <SvgIconsList />
                      ) : (
                        <SvgIconsReplyWhatsapp />
                      )}
                    </div>
                    <div className="text">{action.text}</div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
        <div className="input"></div>
      </div>
    </div>
  )
}

export default SmsPreview

function Media({ url }) {
  const type = determineFileType(url)

  switch (type) {
    case 'image':
      return <img src={url} />
    case 'audio':
      return <audio src={url} controls />
    case 'video':
      return <video src={url} controls />
    case 'document':
      return (
        <div className="document">
          <div className="icon">
            <SvgIconsDownload />
          </div>
          <div className="text">{url.split('/').pop()}</div>
        </div>
      )
    case 'contacts':
      return (
        <div className="document">
          <div className="icon">
            <SvgIconsContact />
          </div>
          <div className="text">{url.split('/').pop()}</div>
        </div>
      )
    default:
      return <div />
  }
}

function determineFileType(url) {
  const extensionToType = {
    jpg: 'image',
    jpeg: 'image',
    png: 'image',
    gif: 'image',
    bmp: 'image',
    webp: 'image',
    svg: 'image',
    amr: 'audio',
    '3gp': 'audio',
    aac: 'audio',
    mpeg: 'audio',
    pdf: 'document',
    doc: 'document',
    docx: 'document',
    pptx: 'document',
    xlsx: 'document',
    mp4: 'video',
    avi: 'video',
    mov: 'video',
    mkv: 'video',
    webm: 'video',
    flv: 'video',
    vcf: 'contacts',
  }

  // Extract the file extension
  const extension = url.split('?')[0].split('.').pop().toLowerCase()

  // Determine the file type based on the extension
  const fileType = extensionToType[extension] || 'unknown'

  return fileType
}
