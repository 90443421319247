import SendProgress from 'SendBlast/components/SendProgress'
import CopyToClipboard from 'Shared/components/CopyToClipboard'
import { Scrollbars } from 'react-custom-scrollbars'

export default function ShareSmsConversations() {
  const { id, textMessageBlast, slug } = useSel((s) => s.campaign)
  const act = useAct()
  const { open, email, success, error } = useSel((s) => s.shareCampaign)
  const scrollRef = useRef()

  return (
    <>
      <div className="header">
        <h2>Share Me</h2>
      </div>

      <div className="content">
        <Scrollbars
          renderTrackHorizontal={(props) => (
            <div {...props} style={{ display: 'none' }} className="track-horizontal" />
          )}
          autoHide
          style={{ width: '100%', height: '80vh' }}
          ref={scrollRef}
        >
          <SendProgress
            campaignId={id}
            entityType="textMessageBlast"
            entityId={textMessageBlast.id}
          />
          <div className="divider margin-bottom"></div>
          <div>
            <h2 className="text-center margin-bottom">
              Want to share it with your team?
            </h2>
            <p className="bold margin-top margin-bottom double text-center">
              <CopyToClipboard url={slug} text={'Copy'} />
              <span> the campaign link, or invite more people to help.</span>
            </p>
          </div>

          <div className="divider margin-bottom"></div>

          <a href={`/campaigns/${slug}`} className="button primary block margin-bottom">
            Start Replying
          </a>
          <div>
            <a href={`/campaigns/${id}/overview`} className="blue-link center">
              View this campaign
            </a>
          </div>
        </Scrollbars>
      </div>
    </>
  )
}
