export default (dis, store, client) => {
  function open() {
    return dis({ type: 'shareCampaign/upsert', payload: { open: true }})
  }

  function close() {
    return dis({ type: 'shareCampaign/upsert', payload: { open: false }})
  }

  function invite(campaignId, email) {
    const url = `/api/campaigns/${campaignId}/invite_user`
    dis({ type: 'shareCampaign/upsert', payload: { success: null, error: null } })
    return client.post(url, { email })
      .then(({ data }) => {
        dis({ type: 'shareCampaign/upsert', payload: { email: null, success: true } })
      }).catch(e => {
        dis({ type: 'shareCampaign/upsert', payload: { error: true } })
      })
  }

  function setEmail(email) {
    return dis({ type: 'shareCampaign/upsert', payload: { email } })
  }

  return { open, close, setEmail, invite }
}
