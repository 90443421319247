import * as React from 'react'

function SvgAudience(props) {
  return (
    <svg width={24} height={24} viewBox={"0 0 24 24"} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fillRule="evenodd" clipRule="evenodd" fill="currentColor">
        <path d="M5 7a4 4 0 108 0 4 4 0 00-8 0zm10 4a3 3 0 106 0 3 3 0 00-6 0zM8.983 13C4.262 13 .388 15.427.001 20.2c-.021.26.476.8.727.8h16.519c.75 0 .763-.605.75-.8-.292-4.907-4.226-7.2-9.014-7.2zm14.473 8H19.6c0-2.25-.744-4.328-1.999-6 3.407.038 6.188 1.76 6.398 5.4.008.147 0 .6-.543.6z" />
      </g>
    </svg>
  )
}

export default SvgAudience
