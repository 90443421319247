import Step from 'Shared/components/Builder/Step'

export default function CampaignBuilderStep({
  children,
  action = null,
  onDiscardChanges,
  editor,
  finishButtonText = "Save",
  ...rest
}) {
  const act = useAct()

  const campaignId = useSel(s => s.campaign.id)
  const { currentEditor } = useSel(s => s.campaignBuilder)

  const approvalStatus = useSel(s => s.campaign.approvalStatus)

  const open = () => {
    act.campaignBuilder.openEditor(editor)
  }

  const close = () => {
    if (onDiscardChanges) {
      onDiscardChanges()
    } else {
      act.campaign.load(campaignId)
    }
    act.campaignBuilder.closeEditor()
  }

  const save = () => {
    if (action) {
      return action()
    } else {
      return act.campaign.persist({ reloadSurvey: true }).then(function () {
        act.campaignBuilder.closeEditor()
      })
    }
  }
  return (
    <Step
      {...rest}
      save={save}
      open={open}
      close={close}
      editor={editor}
      currentEditor={currentEditor}
      approvalStatus={approvalStatus}
      finishButtonText={finishButtonText}
    >
      {children}
    </Step>
  )
}
