import * as React from "react"

const SvgHeading = (props) => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5 7V22" stroke="#191936" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M18 14.5H5" stroke="#191936" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M18 7V22" stroke="#191936" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M24.5 15.5L27.5 13.5V25" stroke="#191936" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
)

export default SvgHeading
