import actions from './actions'
import ServerWaitButton from 'Shared/components/ServerWaitButton'

const LABELS = {
  ssu: 'Skicka signering',
  fake: 'Trigger SMS',
}

export default function IntegrationButton({ integration, targetId, small = false }) {
  const [callComplete, setCallComplete] = useState(null)
  const label = LABELS[integration]
  const disabled = callComplete || !targetId
  const demo = useSel((s) => s.local.demo)

  useEffect(() => {
    if (!integration || !targetId) return

    setCallComplete(false)
  }, [targetId])

  function handleIntegrationClick() {
    if (demo) {
      return Promise.resolve().then(() => setCallComplete(true))
    } else {
      return actions
        .callIntegration(targetId, integration)
        .then(() => setCallComplete(true))
    }
  }

  return (
    <ServerWaitButton
      className={`button primary goal ${small ? 'small' : ''}`}
      onClick={handleIntegrationClick}
      disabled={disabled}
    >
      {label}
    </ServerWaitButton>
  )
}
