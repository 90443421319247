import Button from 'Shared/components/Button'
import { CircularProgress } from '@material-ui/core'
import Flash from 'Shared/components/Flash/components/Flash'
import { Formik, Field, Form, ErrorMessage } from 'formik'
import AsyncSelect from 'Shared/components/AsyncSelect'
import './Form.scoped.scss'
import useAct from 'Workflows/hooks/useAct'

export default function EditAddToCampaignNode({ nodeId }) {
  const act = useAct()
  const [flash, setFlash] = useState(null)
  const [campaign, setCampaign] = useState([])
  const properties = useSel((s) => s.workflowNodes.entities[nodeId].properties)
  const [loading, setLoading] = useState(false)
  const [editCampaign, setEditCampaign] = useState(true)
  const [campaignKind, setCampaignKind] = useState('sms_blast')

  const handleSubmit = (values, { setSubmitting }) => {
    act.builder
      .updateNode({ nodeId, properties: values })
      .then(() => setSubmitting(false))
  }

  useEffect(() => {
    if (properties.campaignId) {
      setEditCampaign(false)
      setLoading(true)
      loadCampaign(properties.campaignId)
    }
  }, [JSON.stringify(properties)])

  function loadCampaign(id) {
    act.campaigns.load(id).then(({ data }) => {
      setEditCampaign(false)
      setLoading(false)
      setCampaign(data)
    })
  }

  function createNewCampaign() {
    // transform any whatsapp campaign into an SMS one, but append a blastKind
    const params = { nodeId, kind: campaignKind.replace('whatsapp', 'sms') }
    if (campaignKind !== 'email') {
      if (campaignKind === 'whatsapp_survey' || campaignKind === 'whatsapp_blast' || campaignKind === 'whatsapp_conversations') {
        params.blastKind = 'whatsapp'
      }
    }
    act.builder.createLinkedCampaign(params)
  }

  if (loading) {
    return <CircularProgress />
  }

  return (
    <Formik initialValues={properties} onSubmit={handleSubmit}>
      {({ values, isSubmitting, errors, setFieldValue }) => {
        const clearSelection = () => {
          setEditCampaign(true)
          setFieldValue('campaignId', null)
        }

        const updateCampaign = (id) => {
          setFieldValue('campaignId', id)
          loadCampaign(id)
        }

        return (
          <Form>
            {flash && (
              <Flash
                message={flash.message}
                type={flash.type}
                onHide={() => setFlash(null)}
              />
            )}

            <div className="fields">
              {editCampaign && (
                <>
                  <div className="field">
                    <p className="smaller">Pick an existing campaign.</p>
                    <AsyncSelect
                      entity="campaign"
                      value={values.campaignId}
                      onChange={(value) => updateCampaign(value)}
                      queryParameters={{ status: ['live', 'sent'] }}
                    />
                  </div>

                  <div className="field">
                    <p className="smaller">Or create a new campaign</p>
                    <select onChange={(e) => setCampaignKind(e.target.value)}>
                      <option value="sms_blast">SMS blast</option>
                      <option value="sms_survey">SMS survey</option>
                      <option value="whatsapp_blast">WhatsApp blast</option>
                      <option value="whatsapp_survey">WhatsApp survey</option>
                      <option value="email">Email</option>
                    </select>
                    <a
                      className="button small secondary margin-top"
                      onClick={createNewCampaign}
                    >
                      Create
                    </a>
                  </div>
                </>
              )}
              {!editCampaign && (
                <div>
                  <label className="bold">Current campaign</label>
                  <p>{campaign.name}</p>
                  <p>
                    <span className="bold">Type:</span> {campaign.primaryType}
                  </p>
                  <p>
                    <span className="bold">Status:</span>{' '}
                    {campaign.status === 'draft' ? 'Draft' : 'Ready'}
                  </p>
                  <a
                    className="button small secondary margin-bottom"
                    href={campaign.adminUrl}
                  >
                    View/edit campaign
                  </a>
                  <br />
                  <a className="blue-link" onClick={() => clearSelection()}>
                    Change campaign
                  </a>
                  <div className="divider"></div>
                </div>
              )}

              <div className="field">
                <Button
                  type="submit"
                  small
                  primary
                  extraClass="margin-right"
                  disabled={isSubmitting}
                >
                  Save changes
                </Button>
                {isSubmitting && <CircularProgress />}
                <Button
                  small
                  red
                  onClick={() => act.local.setSidebar({ kind: 'workflow' })}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </Form>
        )
      }}
    </Formik>
  )
}
