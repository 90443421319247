import {
  createEntityAdapter,
  createSlice,
  createReducer,
  configureStore,
  getDefaultMiddleware,
} from '@reduxjs/toolkit'

const middleware = [...getDefaultMiddleware()]

const store = configureStore({
  middleware,
  reducer: {
    dummy: () => ({})
  },
})

export default store
