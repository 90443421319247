import { getOrdinal } from '../../../../lib/helpers'

const UserRanking = ({ rank, cutoff }) => {
  return rank ? (
    <p className="emphasis text-center smaller">
      {'You are in '}
      <span className="extra-bold lighter-black">{getOrdinal(rank)}</span>
      {' place'}
    </p>
  ) : (
    <p className="emphasis text-center smaller">
      {`You're not quite in the top ${cutoff} yet!`}
    </p>
  )
}

export default UserRanking
