import SingleStatBox from '../shared/SingleStatBox'
import Chart from '../shared/Chart'
import './ScreensCommon.scoped.scss'
import NewDataTable from 'Shared/components/NewDataTable/NewDataTable'
import formatDate from 'Shared/functions/formatDate'


const columns = [
  { key: 'date', label: 'Date', sortable: true, render: formatDate },
  { key: 'called', label: 'Called', sortable: true },
  { key: 'pickedUp', label: 'Picked up', sortable: true },
  { key: 'completed', label: 'Completed', sortable: true },
]

const Calling = () => {

  const callsByDate = useSel((s) => s.analytics.data.callsByDate)

  const { called, pickedUp, completed } = useSel((s) => s.analytics.data.callsBreakdownByStatus)

  const chartData = callsByDate.map((row) => {
    const date = new Date(row.date)
    return {
      name: date.toLocaleString('default', { month: 'short', day: 'numeric' }),
      called: row.called,
      pickedUp: row.pickedUp,
      completed: row.completed,
    }
  })

  const [lines, setLines] = useState([
    { label: 'Called', key: 'called', color: '#7FD1E0', enabled: true },
    { label: 'Picked up', key: 'pickedUp', color: '#5FD092', enabled: true },
    { label: 'Completed', key: 'completed', color: '#E69900', enabled: true },
  ])

  return <>
    <div className="headline-stats">
      <SingleStatBox label="Called" value={called} change={3.0} />
      <SingleStatBox label="Picked Up" value={pickedUp} change={-2.0} />
      <SingleStatBox label="Completed" value={completed} change={0.0} />
    </div>

    <Chart
      data={chartData}
      lines={lines}
      setLines={setLines}
      xLabel="Day"
      yLabel="Number of calls" />

    <NewDataTable columns={columns} data={callsByDate} />

  </>
}

export default Calling
