const Calendar = ({ width = 24, height = 24 }) => (
  <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fillRule="evenodd" clipRule="evenodd" d="M17 4H7C5.34315 4 4 5.34315 4 7V17C4 18.6569 5.34315 20 7 20H17C18.6569 20 20 18.6569 20 17V7C20 5.34315 18.6569 4 17 4ZM6 7C6 6.44772 6.44772 6 7 6H17C17.5523 6 18 6.44772 18 7V17C18 17.5523 17.5523 18 17 18H7C6.44772 18 6 17.5523 6 17V7Z" fill="#1F2532"/>
  <rect x="7" y="6.5" width="4" height="2" rx="0.5" transform="rotate(-90 7 6.5)" fill="#1F2532"/>
  <rect x="15" y="6.5" width="4" height="2" rx="0.5" transform="rotate(-90 15 6.5)" fill="#1F2532"/>
  <rect x="5" y="6" width="14" height="2" rx="1" fill="#1F2532"/>
  <path fillRule="evenodd" clipRule="evenodd" d="M9 12C9.55228 12 10 11.5523 10 11C10 10.4477 9.55228 10 9 10C8.44772 10 8 10.4477 8 11C8 11.5523 8.44772 12 9 12Z" fill="#1F2532"/>
  <path fillRule="evenodd" clipRule="evenodd" d="M12 12C12.5523 12 13 11.5523 13 11C13 10.4477 12.5523 10 12 10C11.4477 10 11 10.4477 11 11C11 11.5523 11.4477 12 12 12Z" fill="#1F2532"/>
  <path fillRule="evenodd" clipRule="evenodd" d="M15 12C15.5523 12 16 11.5523 16 11C16 10.4477 15.5523 10 15 10C14.4477 10 14 10.4477 14 11C14 11.5523 14.4477 12 15 12Z" fill="#1F2532"/>
  </svg>
)

export default Calendar
