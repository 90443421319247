import {
  createEntityAdapter,
  createSlice,
  createReducer,
  configureStore,
  getDefaultMiddleware,
} from '@reduxjs/toolkit'

import {
  generateEntitySlices,
  moveDigitOrder,
  fixDigitOrder,
  upsertSettings
} from 'Shared/sliceHelpers'


function generateSlices(entitySlices) {
  return _.fromPairs(
    _.map(entitySlices, (extraReducers, name) => {
      const reducer = createSlice({
        name,
        initialState: adapter.getInitialState(),
        reducers: {
          upsert: adapter.upsertOne,
          add: adapter.addOne,
          setAll: adapter.setAll,
          remove: adapter.removeOne,
          ...extraReducers,
        },
      }).reducer
      return [name, reducer]
    })
  )
}

const middleware = [...getDefaultMiddleware()]
const adapter = createEntityAdapter()

const builderSliceInitialState = {
  loaded: false,
  currentEditor: null,
  renderedContent: { body: '' },
  renderedBody: '',
}

const builderSlice = createSlice({
  name: 'builder',
  initialState: builderSliceInitialState,
  reducers: {
    set: (s, a) => a.payload,
    upsert: (s, a) => ({ ...s, ...a.payload }),
    reset: (s, a) => ({ ...builderSliceInitialState, searchId: s.searchId }),
  },
})

const surveySlice = createSlice({
  name: 'survey',
  initialState: {
    id: null,
    kind: null,
    questions: [],
  },
  reducers: {
    set: (s, a) => a.payload,
    upsert: (s, a) => ({ ...s, ...a.payload }),
  },
})

const scriptBuilderSlice = createSlice({
  name: 'scriptBuilder',
  initialState: {
    adding: false,
    questions: {},
  },
  reducers: {
    set: (s, a) => a.payload,
    upsert: (s, a) => ({ ...s, ...a.payload }),
  },
})

const entitySlices = {
  questions: { moveDigitOrder, fixDigitOrder, upsertSettings },
  answers: { moveDigitOrder, fixDigitOrder },
}

const searchSlice = createSlice({
  name: 'search',
  initialState: {},
  reducers: {
    set: (s, a) => a.payload,
    upsert: (s, a) => ({ ...s, ...a.payload }),
  },
})

const searchBuilderSlice = createSlice({
  name: 'searchBuilder',
  initialState: {
    activeGroupId: null,
    description: null,
    showFilterList: false,
    showTargetTable: false,
    step: 'rules',
  },
  reducers: {
    set: (s, a) => a.payload,
    upsert: (s, a) => ({ ...s, ...a.payload }),
  },
})

const store = configureStore({
  middleware,
  reducer: {
    builder: builderSlice.reducer,
    survey: surveySlice.reducer,
    scriptBuilder: scriptBuilderSlice.reducer,
    searchBuilder: searchBuilderSlice.reducer,
    search: searchSlice.reducer,
    ...generateSlices(entitySlices),
  },
  devTools: {
    name: 'Survey builder', 
  },
})

export default store
