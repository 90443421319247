import Step from './SurveyBuilderStep'
import QuestionPreview from 'CampaignBuilder/components/Steps/Preview/QuestionPreview'
import * as constants from 'Shared/constants'
import search from 'Shared/hooks/search'
import useAct from 'SurveyBuilder/hooks/useAct'

export default function PreviewScript() {
  const act = useAct()
  const {
    searchId,
    participationMode,
  } = useSel((s) => s.survey)
  const questions = useSel((s) => {
    return _.values(s.questions.entities).filter((q) => q.active)
  })

  const { currentEditor } = useSel(s => s.builder)

  const { complete, shortDescriptor } = (() => {
    if (questions.length === 0) {
      return { complete: false, shortDescriptor: <p>You're not asking any questions. You need to add some before you can launch.</p> }
    } else if (participationMode === 'closed' && !searchId) {
      return { complete: false, shortDescriptor: <p className="incomplete no-margin-bottom">You need to set up your audience before your can launch.</p> }
    } else {
      return { complete: true, shortDescriptor: <p className="incomplete no-margin-bottom">Your survey is ready to go</p> }
    }
  })()

  const finishText = 'Launch'

  function finish() {
    return act.survey
      .launch()
      .then(({ data: { redirectUrl }}) => {
        window.location.href = redirectUrl
      })
      .catch((e) => {
        const message = e.response.data.message
        if (message) {
          act.local.upsert({ alert: { message, severity: 'error' } })
        }
      })
  }

  return (
    <Step
      name="Preview"
      editor="preview"
      shortDescriptor={
        <p className="incomplete no-margin-bottom">{ shortDescriptor }</p>
      }
      save={finish}
      staticOpenButton={'Preview'}
      canClickFinish={complete}
      finishButtonText={finishText}
      complete={complete}
      canOpen={complete && !currentEditor}
    >
      <ScriptPreview />
    </Step>
  )
}

function ScriptPreview() {
  const questions = useSel(s => {
    const questions = _.values(s.questions.entities).filter(q => q.active)

    return _.orderBy(questions, 'digitOrder')
  })

  let i = 0

  return <div className='script-preview margin-bottom'>
    { questions.length === 0 && <p>You're not asking any questions.</p> }
    { questions.map(({ id, questionType }) => {
      if (_.includes(['text', 'multiple_choice', 'send_sms'], questionType)) i++

      return <ScriptPreviewStep questionId={id} index={i} key={id} />
    }) }
  </div>
}

function ScriptPreviewStep({ questionId, index }) {
  const { questionType } = useSel(s => s.questions.entities[questionId])
  const label = (constants.QUESTION_LABELS[questionType] || constants.QUESTION_LABELS['default']).replace('{index}', index)

  return <div className='block'>
    <div className='left'>
      <p className={questionType === 'instruction' ? 'light-black' : ''}>{label}</p>
    </div>
    <div className='right'>
      <QuestionPreview questionId={questionId} />
    </div>
  </div>
}
