import * as React from 'react'

function ActivityLogSms(props) {
  return (
		<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">

			<path
				transform="scale(0.72) translate(2.7, 2.7)"
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M8.71931 9.85596L10.1887 8.38661C10.5945 7.98079 10.6951 7.36083 10.4384 6.84751L10.3552 6.68101C10.0985 6.16769 10.1991 5.54773 10.6049 5.14191L12.4268 3.32004C12.557 3.18987 12.768 3.18987 12.8982 3.32004C12.9235 3.34538 12.9446 3.37463 12.9606 3.40667L13.6966 4.87864C14.2597 6.00471 14.039 7.36473 13.1487 8.25497L9.1495 12.2542C8.18048 13.2232 6.74713 13.5616 5.44704 13.1282L3.83739 12.5917C3.66274 12.5335 3.56836 12.3447 3.62657 12.17C3.64294 12.1209 3.6705 12.0763 3.7071 12.0397L5.47461 10.2722C5.88042 9.86641 6.50039 9.76581 7.01371 10.0225L7.18021 10.1057C7.69353 10.3624 8.31349 10.2618 8.71931 9.85596Z"
				fill="#191936"
			/>

			<path
				transform="scale(0.72) translate(2.5, 2.5)"
				opacity="0.3"
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M9.54266 2.6758L9.66602 1.34818C7.5077 1.14763 5.36131 1.90157 3.79843 3.46445C2.23771 5.02516 1.48363 7.16782 1.68136 9.32338L3.00912 9.20158C2.8472 7.43637 3.46361 5.68488 4.74124 4.40725C6.02063 3.12786 7.77518 2.51156 9.54266 2.6758ZM9.30509 5.33196L9.43268 4.00475C8.05479 3.87229 6.68218 4.35193 5.68404 5.35006C4.68919 6.34492 4.20932 7.71188 4.33749 9.08559L5.66505 8.96174C5.57337 7.97902 5.9156 7.00412 6.62685 6.29287C7.34044 5.57929 8.31939 5.23721 9.30509 5.33196Z"
				fill="#191936"
			/>
		</svg>
  )
}

export default ActivityLogSms


