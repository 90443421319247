import { LineChart, CartesianGrid, XAxis, YAxis, Line, ResponsiveContainer } from 'recharts'
import './UsageStats.scoped.scss'
import { format } from 'date-fns';

const ICONS = {
    'rocket-blue': <SvgIconsRocketBlue />,
    'trend-up': <SvgIconsTrendUp />
}

function Stat({ large = false, vertical = false, icon, label, number }) {
    const formattedNumber = number ? Number(number).toLocaleString() : '-'
    return <div className={`usage-stat ${vertical ? 'vertical' : ''}`}>
        { icon && <div className={`icon ${large ? '' : 'smaller'}`}>{ ICONS[icon] }</div> }
        <div className='content'>
            <div className="number">
                <p className={`${large ? 'larger' : 'smaller'}`}>{ formattedNumber }</p>
            </div>
            <p className={`label ${large ? 'larger' : ''}`}>
                { label }
            </p>
        </div>
    </div>
}

export default function UsageStats({ }) {
    const [stats, setStats] = useState(null)
    const [graphData, setGraphData] = useState(null)
    const showGraph = graphData && graphData.length > 0

    useEffect(() => {
      axios.get('/api/reporting/stats').then(({ data }) => {
        setStats(data)
        setGraphData(data.contacts_graph)
      })
    }, [])

    return <div className="row">
        <div className="column half">
            <div className="box full-height">
                <div className="row full-height">
                    { stats &&
                        <div className="column third border-right">
                            <Stat icon={'rocket-blue'} label={'People Contacted'} large={true} vertical={true} number={stats['contacts']} />
                        </div>
                    }

                    { stats && <>
                        <div className="column third border-right">
                            <p className='stat-header'>Calls</p>
                            <Stat icon={'trend-up'} label={'Calls Made'} vertical={false} number={stats['calls']} />
                            <Stat icon={'trend-up'} label={'Answered'} vertical={false} number={stats['calls_answered']} />
                            <Stat icon={'trend-up'} label={'Conversions'} vertical={false} number={stats['calls_conversions']} />
                        </div>
                        <div className="column third">
                            <p className='stat-header'>Text Messages</p>
                            <Stat icon={'trend-up'} label={'Texts Sent'} vertical={false} number={stats['texts']} />
                            <Stat icon={'trend-up'} label={'Clicks'} vertical={false} number={stats['texts_clicked']} />
                            <Stat icon={'trend-up'} label={'Replies'} vertical={false} number={stats['texts_replied']} />
                        </div>
                    </> }
                </div>
            </div>
        </div>

        <div className="column half">
            <div className="box">
                <p className='stat-header'>Contacts this month</p>
                { showGraph && <ResponsiveContainer height={220}>
                    <LineChart width={500} height={300} data={graphData}>
                        <XAxis axisLine={false} tickLine={false} dataKey="date" tickFormatter={time => format(new Date(time), 'dd/MM')} />
                        <YAxis axisLine={false} tickLine={false} allowDecimals={false} tickFormatter={(v) => v.toLocaleString()} />
                        <CartesianGrid vertical={false} stroke="#eee" strokeDasharray="5 5"/>
                        <Line type="monotone" dataKey="count" stroke="#00D586" strokeWidth={3} />
                    </LineChart>
                </ResponsiveContainer> }
                { !showGraph && graphData && <p className='no-margin-top no-margin-bottom'>
                    You've not made any contacts yet! Once you do, we'll show you more stats.
                </p> }
            </div>
        </div>
    </div>
}
