import useAct from 'DesktopCaller/useAct'
import useData from 'DesktopCaller/useData'
import ServerWaitButton from 'Shared/components/ServerWaitButton'
import RenderBreaks from 'Shared/components/RenderBreaks'
import LabeledCard from 'Shared/components/LabeledCard/LabeledCard'
import questionTypes from './question-types'
import './Question.scoped.scss'

export default function Question({ index, id }) {
  const script = useSel((s) => s.local.currentScript)
  const inputStage = useData((rs) => rs.callSession.inputStage)
  const previousQuestionId = useData((rs) => rs.callSession.previousQuestionId)
  const { questionType, settings } = useSel((s) => s.questions.entities[id])
  const questionTypeDefinition = questionTypes[questionType]

  const storedAnswerText = useSel((s) => s.desktopCaller.answerTexts[id])
  const storedAnswer = useSel((s) => s.desktopCaller.questionAnswers[id])

  const answers = useSel((s) =>
    _.values(s.answers.entities).filter((a) => a.active && a.questionId === id)
  ).map((answer) => (
    {
      ...answer,
      answer: script.answers[answer.id]?.answer
    }
  ))

  const questionAnswer = useData((rs) => (rs.callSession.questionAnswers || {})[id])
  const currentQuestionId = useData((rs) => rs.call.currentQuestionId)
  const current = currentQuestionId === id && inputStage === 'answers'

  const [response, setResponse] = useState(null)
  const [submitting, setSubmitting] = useState(false)

  const incomplete = Array.isArray(response) ? response.length === 0 : !response

  const act = useAct()

  // If an undo results in this question becoming active again, pre-populate with previous answer
  useEffect(() => {
    if (current && storedAnswerText) {
      setResponse(storedAnswerText)
    }
  }, [current, storedAnswerText])

  // Likewise for multiple select questions
  useEffect(() => {
    if (current && Array.isArray(storedAnswer)) {
      setResponse(storedAnswer)
    }
  }, [current, storedAnswer])

  const QuestionType = questionTypeDefinition.component


  const questionTypeSaveActions = {
    multiple_choice: act.desktopCaller.answerQuestion,
    multiple_select: act.desktopCaller.answerMultipleSelectQuestion,
    text: act.desktopCaller.answerQuestionWithText,
    score: act.desktopCaller.answerQuestionNumeric,
    instruction: act.desktopCaller.answerInstructionQuestion,
  }

  const next = () => {
    if (!current) return Promise.resolve()
    setSubmitting(true)
    return questionTypeSaveActions[questionType](id, response)
      .finally(() => setSubmitting(false))
  }

  function undo() {
    return act.desktopCaller.undoLast()
  }

  const typeLabel =
    questionType == 'instruction' ? (
      <span>{i18n.t('user.campaigns.phone.instruction')}</span>
    ) : (
      <span>
        {i18n.t('user.campaigns.phone.question')} {index}
      </span>
    )

  return (
    <LabeledCard
      className={current ? 'question current' : 'question'}
      label={<><SvgIconsCallerQuestion /> {typeLabel}</>}
    >

      <p className="no-margin-top">
        <RenderBreaks text={script.questions[id]?.question} />
      </p>

      {QuestionType &&
        <QuestionType
          id={id}
          name={`question_${id}`}
          settings={settings}
          response={response}
          disabled={submitting || !current}
          answers={answers}
          setResponse={setResponse}
        />
      }

      {current && questionTypeDefinition.showNext && 
        <ServerWaitButton
          type="button"
          className="button primary margin-top"
          onClick={next}
          disabled={questionTypeDefinition.answerable && settings.required && incomplete}
        >
          {i18n.t('shared.messages.next')}
        </ServerWaitButton>
      }

      {questionTypeDefinition.canUndo && id === previousQuestionId && (
        <div>
          <ServerWaitButton
            type="button"
            className="button primary margin-top"
            onClick={undo}
          >
            {i18n.t('shared.messages.undo')}
          </ServerWaitButton>
        </div>
      )}

    </LabeledCard>
  )
}
