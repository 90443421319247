import { isEqual } from 'lodash'
import Rule from './Rule'
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import './Rules.scoped.scss'

export default function Rules({ hideHelp = false }) {
  const [showGuidance, setShowGuidance] = useState(false)
  const { errors } = useSel((s) => s.searchBuilder)
  const act = useAct()
  const rootRules = useSel((s) => {
    const allEntities = Object.values(s.rules.entities)
    return allEntities.filter(
      ({ id }) => !allEntities.some(({ ruleIds }) => ruleIds?.includes(id))
    )
  }, isEqual)

  useEffect(() => {
    if (rootRules.length === 1) {
      act.search.upsertRule('exclude-root', {
        type: 'group',
        operator: 'AND',
        not: true,
        ruleIds: [],
      })
    }
  }, [rootRules])

  return (
    <>
      { !hideHelp && <Accordion
        sx={{
          borderRadius: 1,
        }}
        expanded={showGuidance}
        onChange={(_e, expanded) => setShowGuidance(expanded)}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <div className="icon margin-right half">
            <SvgIconsTip />
          </div>
          <div className="margin-right half underlined bold">
            {(showGuidance && 'Hide ') || 'View '}
            guidance on creating a new audience
          </div>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            paddingTop: 0,
          }}
        >
          <div>
            In <b>step 1</b>:
          </div>
          <ul>
            <li>
              Add criteria to create an audience that you can use in your campaigns.
            </li>
            <li>
              Select if the audience should match <b>all</b> or <b>any</b> of the
              criteria.
            </li>
            <li>
              You can add:
              <ol>
                <li>
                  A <b>filter</b> that is a simple condition.
                </li>
                <li>
                  A <b>nested group</b> that allows you to add multiple criteria and
                  select if
                  <b>all</b> or <b>any</b> of the criteria <b>within the group</b> have to
                  be met.
                </li>
              </ol>
            </li>
            <li>
              You can choose to <b>include</b> or <b>exclude</b> people who match the
              criteria you select (in a filter or a nested group).
            </li>
          </ul>
          <p>
            In <b>step 2</b>, you can see who is included or excluded and preview your
            audience.
          </p>
          <p>
            In <b>step 3</b>, you can name your audience, save it as a template and share
            it with your sub-organisations.
          </p>
        </AccordionDetails>
      </Accordion> }

      <div className="step">
        {rootRules.map((rule) => (
          <div className="section" key={rule.id}>
            <div className="box">
              <Rule id={rule.id} />
            </div>
          </div>
        ))}
        {errors?.map((error) => {
          ;<p>{error}</p>
        })}
      </div>
    </>
  )
}
