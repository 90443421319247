const billingData = (dis, store, client) => {

  async function load() {
    client.get("/api/billing/credit").then(({ data }) => {
      dis({ type: 'billingData/set', payload: data })
    })
  }

  return { load }
}

export default billingData
