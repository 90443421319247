import SafeRenderHtml from 'CampaignVolunteer/components/SafeRenderHtml.jsx'

export default function RenderBreaks({ text }) {
  if (!text) return null

  const els = text.split("\n").join("\n<br>\n").split("\n").map((line, index) => {
    if (line === '<br>') {
      return <br key={index} />
    } else {
      return <span key={index}>
        <SafeRenderHtml html={line} />
      </span>
    }
  })

  return <>{els}</>
}
