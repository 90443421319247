function getBaseUrl(campaignId, entityType, entityId) {
  if (entityType === 'textMessageBlast') {
    return `/api/campaigns/${campaignId}/text_message_blasts/${entityId}`
  } else if (entityType === 'emailBlast') {
    return `/api/campaigns/${campaignId}/email_blasts/${entityId}`
  }
}

export default (dis, store, client) => {
  function open() {
    return dis({ type: 'sendBlast/upsert', payload: { open: true }})
  }

  function close() {
    return dis({ type: 'sendBlast/upsert', payload: { open: false }})
  }

  function send(campaignId, entityType, entityId) {
    const baseUrl = getBaseUrl(campaignId, entityType, entityId)
    return client.post(`${baseUrl}/begin_send`).
      then(() => {
        return dis({ type: 'sendBlast/upsert', payload: { sending: true }})
      })
  }

  function sendForApproval(campaignId) {
    return client.post(`/api/campaigns/${campaignId}/send_for_approval`)
      .then(() => {
        return dis({ type: 'sendBlast/upsert', payload: { sending: true }})
      })
  }

  function abort(campaignId, entityType, entityId) {
    const baseUrl = getBaseUrl(campaignId, entityType, entityId)
    return client.post(`${baseUrl}/abort`)
  }

  function pause(campaignId, entityType, entityId) {
    const baseUrl = getBaseUrl(campaignId, entityType, entityId)
    return client.post(`${baseUrl}/pause`)
  }

  function resume(campaignId, entityType, entityId) {
    const baseUrl = getBaseUrl(campaignId, entityType, entityId)
    return client.post(`${baseUrl}/resume`)
  }

  function updateProgress(campaignId, entityType, entityId) {
    const baseUrl = getBaseUrl(campaignId, entityType, entityId)
    return client.get(`${baseUrl}/progress`, {}).
      then(({ data: payload }) => {
        return dis({ type: 'sendBlast/upsert', payload })
      })
  }

  return { open, close, send, sendForApproval, abort, pause, resume, updateProgress }
}
