import { copyToClipboard } from 'Shared/functions/util'
import './css/CopyToClipboardBig.scoped.scss'

export default function CopyToClipboardBig({ url }) {
  const [copied, setCopied] = useState(false)

  function handleClick() {
    copyToClipboard(url)
    setCopied(true)
    setTimeout(function() {
      setCopied(false)
    }, 1500)
  }

  return <div className="container">
    <input type='text' value={url} disabled className="full-width-input" />
    <button className='button small primary margin-right' disabled={copied} onClick={handleClick}>{copied ? 'Copied!' : 'Copy' }</button>
  </div>
}
