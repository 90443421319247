import useAct from 'DesktopCaller/useAct'

export default function IvrCaller({ campaignId }) {
  const act = useAct()
  const { phoneNumbers } = useSel((s) => s.campaign)
  const loaded = phoneNumbers && phoneNumbers.length > 0

  const steps = i18n.t('user.campaigns.phone.ivr_steps')

  useEffect(() => {
    act.desktopCaller.loadPhoneNumbers(campaignId)
  }, [])

  return <div className='box'>
    {loaded &&
      <div className='ivr-caller'>
        <div className='steps'>
          {steps.map((step, index) => {
            return <div className='step'>
              <div className='number'><p>{index}</p></div>
              <p className='prompt'>{step}</p>
            </div>
          })}
        </div>

        <div className='dial'>
          <p>{ i18n.t('user.campaigns.phone.dial_number', { phone: phoneNumbers[0].phoneNumber } ) }</p>
        </div>
      </div>
    }
    {loaded === false && <p>{ i18n.t('shared.messages.loading') }</p>}
  </div>
}
