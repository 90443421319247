import Backdrop from '@material-ui/core/Backdrop'
import AVDevicesSetup from '../../av-devices-setup'
import DeviceTypeIcon from './DeviceTypeIcon'
import RrButton from 'Shared/components/RrButton'
import MicSetupTitle from './MicSetupTitle'
import './MicSetup.scoped.scss'

const MicSetup = ({ show, initConfig, onComplete, onCancel }) => {
  const [micConfig, setMicConfig] = useState([])
  /** Fixes CSS document width flashing when show/hide component */
  useEffect(() => {
    document.getElementsByTagName('html')[0].style.overflow = show ? 'hidden' : 'initial'
  }, [show])

  /** Runs every time a device is configured within DeviceSetup component */
  const onDeviceUpdated = (devices) => {
    setMicConfig(devices)
  }

  const browserSupportsSetSinkId = () => {
    return 'setSinkId' in HTMLAudioElement.prototype
  }

  const requiredDevices = ['audioinput'];
  if (browserSupportsSetSinkId()) {
    requiredDevices.push('audiooutput');
  }

  const isValid = (() => {
    let allDevices = true
    requiredDevices.forEach(kind => {
      if (!micConfig.find(config => config.kind === kind)) {
        allDevices = false
      }
    })

    return allDevices
  })()

  return (
    show && (
      <Backdrop open={show} classes={{ root: 'avds-backdrop' }}>
        <div className="no-padding avds-card">
          <MicSetupTitle onClose={() => onCancel()} deviceType={'audioinput'} />
          <div className="box clear">
            <div className="row">
              <div className="column no-margin">
                <DeviceTypeIcon type={'audioinput'} />
              </div>
              <div className="column remaining desktop-padding">
                <AVDevicesSetup
                  {...{
                    persist: true,
                    initConfig,
                    requiredDevices,
                    onChange: onDeviceUpdated,
                    userOptions: {
                      soundMeter: { color: '#2849d6' },
                      container: { padding: 20 },
                      audioTest: { expanded: true },
                    },
                  }}
                />
              </div>
            </div>
            {!isValid && <div className="row justify-flex-end">{i18n.t('user.campaigns.phone.device_setup.select_required_devices')}</div>}
            <div className="row justify-flex-end">
              <RrButton
                title="save"
                type="primary"
                disabled={!isValid}
                onClick={() => onComplete(micConfig)}
              />
            </div>
          </div>
        </div>
      </Backdrop>
    )
  )
}

export default MicSetup
