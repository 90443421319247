import { components } from 'react-select';

const MenuListWithFooter = ({ children, selectProps, ...rest }) => {
  return (
    <div>
      <components.MenuList {...rest}>
        {children}
      </components.MenuList>
      <div className='block'>
        <a className='button secondary naked' onClick={selectProps.handleCreateNew} href={selectProps.redirectUrl}>
          <SvgIconsPlus /> {selectProps.createNewText || 'Create new'}
        </a>
      </div>
    </div>
  );
};

export default MenuListWithFooter
