import './IconBox.scoped.scss'

export default function IconBox({ size = 130, color = 'orange', children }) {
  const svgSize = Math.round(size * 0.6)


  const firstChild = React.Children.toArray(children)[0]
  const icon = React.cloneElement(firstChild, { width: svgSize, height: svgSize })

  return (
    <div style={{ width: size, height: size, minWidth: size, minHeight: size }} className={color}>
      {icon}
    </div>
  )
}

