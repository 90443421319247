import restClient from 'Shared/hooks/restClient'
import AsyncSelect from 'Shared/components/AsyncSelect'
import './EditableName.scoped.scss'
import Tag from 'Shared/components/Tag'

export default function EditableName({
  modelName,
  id,
  endpoint,
  status,
  noMarginBottom = false,
}) {
  const [editing, setEditing] = useState(false)
  const [name, setName] = useState('')
  const [newName, setNewName] = useState('')
  const [tags, setTags] = useState(false)
  const [newTagIds, setNewTagIds] = useState([])

  function fetchData() {
    return restClient.get(endpoint).then(({ data }) => {
      setName(data.name)
      setNewName(data.name)
      if (data.tags) {
        setTags(data.tags)
        setNewTagIds(data.tags.map((t) => t.id))
      }
    })
  }

  useEffect(() => {
    fetchData()
  }, [id])

  function saveName() {
    const params = {
      [modelName]: { name: newName },
    }
    if (tags) {
      params[modelName].tagIds = newTagIds
    }

    restClient
      .put(endpoint, params)
      .then(() => {
        fetchData().then(() => setEditing(false))
      })
      .catch((e) => {
        fetchData().then(() => setEditing(false))
        setError('Sorry, an error occurred. Please try that again!')
      })
  }

  const statusTag = {
    name: status,
    class: status === 'closed' ? 'red' : 'purple',
  }


  const showTags = (tags || []).length > 0

  const beginEditing = () => setEditing(true)
  const handleNameChange = (e) => setNewName(e.target.value)

  return (
    <div className="editable-name">
      {editing === true && (
        <div className="editor">
          <input type="text" value={newName} onChange={handleNameChange} />
          {tags && (
            <AsyncSelect
              entity="tag"
              value={newTagIds}
              onChange={setNewTagIds}
              multiple
              loadStaticList
              placeholder="Add tags"
            />
          )}
          <button className='button primary save' onClick={saveName}>Save</button>
        </div>
      )}
      {!editing && (
        <div onClick={beginEditing}>
          <div>
            <div className="flex items-center gap-10">
              <h1
                className={`${noMarginBottom ? 'no-margin-bottom' : ''}`}
              >
                {name}
              </h1>
              <span className="edit-icon"><SvgIconsEdit color="#8c8c9b" /></span>
              {status && <Tag color={statusTag.class} fixedHeight={true} uppercase={true}>{statusTag.name}</Tag> }
            </div>
            {showTags && (
              <div className="tag-list">
                {tags.map((t) => <Tag uppercase={true} color={t.class || 'green'}>{t.name}</Tag>)}
              </div>
            )}
          </div>
        </div>
      )
      }
    </div >
  )
}
