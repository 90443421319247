import { blockURL } from 'Shared/functions/blocks'

const ButtonBlock = ({ block }) => {
  const paragraphStyle = {
    fontFamily: block.parameters.fontFamily,
    fontSize: block.parameters.fontSize + 'px',
  }

  return (
    <div className="wipe-css" style={paragraphStyle}>
      <div className="foundation-container">
        <table className="container" align="center">
          <tbody>
            <tr>
              <td>
                <table className="row">
                  <tbody>
                    <tr>
                      <th className="small-12 large-12 columns first last">
                        <table>
                          <tbody>
                            <tr>
                              <th>
                                <center>
                                  <table className="button float-center" align="center">
                                    <tbody>
                                      <tr>
                                        <td>
                                          <table>
                                            <tbody>
                                              <tr>
                                                <td style={{ border: '0px' }}>
                                                  <a
                                                    style={{
                                                      backgroundColor:
                                                        block.parameters.backgroundColor,
                                                      color:
                                                        block.parameters.textColor ||
                                                        '#FFFFFF',
                                                    }}
                                                  >
                                                    {block.parameters.text}
                                                  </a>
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </center>
                              </th>
                              <th className="expander"></th>
                            </tr>
                          </tbody>
                        </table>
                      </th>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default ButtonBlock
