export default (dis, store, client) => {
  return {
    loadList: () => {
      return client
        .get(`/api/campaigns/${store.getState().campaign.id}/canned_responses`)
        .then(({ data }) => {
          dis({
            type: 'campaign/upsert',
            payload: { cannedResponses: data },
          })
        })
    },
    edit: (id: number, payload: { name: string; body: string }) => {
      return client
        .put(
          `/api/campaigns/${store.getState().campaign.id}/canned_responses/${id}`,
          payload
        )
        .then(({ data }) => {
          dis({
            type: 'campaign/upsert',
            payload: {
              cannedResponses: store
                .getState()
                .campaign.cannedResponses.map((cannedResponse) => {
                  if (cannedResponse.id === id) {
                    return data
                  } else {
                    return cannedResponse
                  }
                }),
            },
          })
        })
    },
    create: (payload: { name: string; body: string }) => {
      return client
        .post(`/api/campaigns/${store.getState().campaign.id}/canned_responses`, payload)
        .then(({ data }) => {
          dis({
            type: 'campaign/upsert',
            payload: {
              cannedResponses: [...store.getState().campaign.cannedResponses, data],
            },
          })
        })
    },
    delete: (id: number) => {
      return client
        .delete(`/api/campaigns/${store.getState().campaign.id}/canned_responses/${id}`)
        .then(({ data }) => {
          dis({
            type: 'campaign/upsert',
            payload: {
              cannedResponses: store
                .getState()
                .campaign.cannedResponses.filter(
                  (cannedResponse) => cannedResponse.id !== id
                ),
            },
          })
        })
    },
  }
}
