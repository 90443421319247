import * as React from 'react'

function SvgGreeting(props) {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect opacity="0.3" x="2.50391" y="2" width="20" height="20" rx="10" fill="#00AE6E" />
      <path fillRule="evenodd" clipRule="evenodd" d="M6.67196 14.5548C6.36561 14.0953 6.48978 13.4744 6.94931 13.1681C7.40884 12.8617 8.02971 12.9859 8.33606 13.4454C9.4931 15.181 10.8583 16.0001 12.504 16.0001C14.1497 16.0001 15.5149 15.181 16.672 13.4454C16.9783 12.9859 17.5992 12.8617 18.0587 13.1681C18.5182 13.4744 18.6424 14.0953 18.3361 14.5548C16.8264 16.8193 14.8583 18.0001 12.504 18.0001C10.1497 18.0001 8.18159 16.8193 6.67196 14.5548Z" fill="#00AE6E" />
    </svg>
  )
}

export default SvgGreeting
