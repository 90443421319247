import { v4 as uuidv4 } from 'uuid'
import actionFactory from 'Shared/hooks/actionFactory'

export default (dis, store, client) => {

  const factoryActions = actionFactory(dis, store, 'block', {}, {})
  const mergeTagTestsfactoryActions = actionFactory(dis, store, 'mergeTagTest', {}, {})

  const blocksActions = {
    ...factoryActions,
    insertBefore: (beforePos, newBlock) => {
      factoryActions.add(newBlock)
      factoryActions.moveDigitOrder(newBlock.id, beforePos - 1)
    },
    insertAfter: (beforePos, newBlock) => {
      factoryActions.add(newBlock)
      factoryActions.moveDigitOrder(newBlock.id, beforePos)
    },
    updateParameters: (id, parameters) => {
      const block = store.getState().blocks.present.entities[id]
      // Only fire the event if it would change the block's parameters
      if (!_.isEqual(block.parameters, { ...block.parameters, ...parameters })) {
        // Optimistically clear errors since we'll validate again on save
        dis({ type: 'emailBuilder/upsert', payload: { errors: null } })
        factoryActions.upsert(id, { parameters: { ...block.parameters, ...parameters } })
      }
    },
  }

  const mergeTagTestsActions = {
    ...mergeTagTestsfactoryActions,
  }

  return {
    blocks: blocksActions,
    mergeTagTests: mergeTagTestsActions,
  }

}
