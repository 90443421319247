import Step from './Step'
import TextMessageContentTabs from './TextMessageContentTabs'
import EntitySelector from 'Shared/components/EntitySelector/EntitySelector'
import SendSampleTextMessage from './SendSample/SendSampleTextMessage'
import SmsPreview from './SmsPreview'
import ScheduleButton from 'CampaignBuilder/components/EditSchedule/components/ScheduleButton'
import getDescription from 'CampaignBuilder/components/EditSchedule/getDescription'
import useValidateCampaign from 'CampaignBuilder/hooks/useValidateCampaign'
import './PreviewSms.scoped.scss'

const PreviewSms = ({}) => {
  const act = useAct()
  const {
    searchId,
    isWorkflowControlled,
    status,
    approvalStatus,
    textMessageBlast: { body, scheduledFor, kind, whatsappTemplateId },
  } = useSel((s) => s.campaign)
  const allPermissions = useSel(s => s.currentUser.allPermissions || [])
  const [complete, errors] = useValidateCampaign()
  const { previewTargetId, renderedContent } = useSel((s) => s.campaignBuilder)
  const [sendSampleModalOpen, setSendSampleModalOpen] = useState(false)
  const organisationName = useSel((s) => s.currentUser.organisationName)
  const allowSchedule = !isWorkflowControlled && allPermissions.includes('manage_campaigns')
  const finishText = status === 'live' ? 'Save' : isWorkflowControlled ? 'Finish' : 'Send'

  const senderName = useSel(s =>
    s.campaign.textMessageBlast.senderType === 'phone_number' ?
      s.campaign.client.name : s.campaign.textMessageBlast.senderName
  )

  function finish() {
    return act.campaign.persist().then(() => {
      if (!isWorkflowControlled) {
        act.sendBlast.open()
      } else {
        act.campaign.launch().then(({ data }) => {
          window.location = data.redirectUrl
        })
      }
    })
  }

  const canOpen = kind === 'whatsapp' ? !!whatsappTemplateId : (body && body.length > 0)

  return (
    <Step
      name="Preview"
      editor="preview"
      shortDescriptor={
        <p className="incomplete no-margin-bottom">{getDescription(scheduledFor)}</p>
      }
      action={finish}
      staticOpenButton={'Preview'}
      finishButtonText={finishText}
      canClickFinish={complete}
      canOpen={canOpen}
      validationErrors={errors}
      showDiscard={false}
      showClose={true}
      additionalButtons={allowSchedule && <ScheduleButton disabled={!complete} />}
      complete={!!scheduledFor}
    >
      <div className="row">
        <p className="dark-grey">
          Here you can look up the name of any contact in your account and see how the SMS
          would look with their details in the preview.
        </p>
      </div>
      <div className='row'>
        <TextMessageContentTabs />
      </div>
      <div className="row">
        <div className="preview-target">
          <EntitySelector
            entity="target"
            scope={searchId}
            value={previewTargetId}
            onChange={(id) => act.campaignBuilder.upsert({ previewTargetId: id })}
            placeholder="Start typing a target's name"
          />
        </div>
        <div>
          <SmsPreview name={senderName} content={renderedContent} kind={kind} />
          <a
            className="margin-top blue-link smaller text-center full-width underlined"
            onClick={() => setSendSampleModalOpen(true)}
          >
            Send a sample text message to a number you provide
          </a>
          <SendSampleTextMessage
            open={sendSampleModalOpen}
            renderedBody={renderedContent?.body}
            close={() => setSendSampleModalOpen(false)}
          />
        </div>
      </div>
    </Step>
  )
}

export default PreviewSms
