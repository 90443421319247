import Select from 'react-select'
import DateRange from './DateRange'
import './DateRangePicker.scoped.scss'

const customStyles = {
  container: (base, state) => ({
    ...base,
    'flexGrow': '1',
  }),
  menu: (base) => ({
    ...base,
    position: 'absolute',
    right: '0',
    width: '664px',
    zIndex: '999999',
  }),
}

const formatDate = (dateString) => {
  const date = new Date(dateString)
  const day = date.getDate()
  const month = date.getMonth() + 1
  const year = date.getFullYear()
  return `${day}/${month}/${year}`
}

const SingleValue = ({getValue}) => {
  const {from, to} = getValue()[0]
  return (
    <div className="single-value">
      <span>{formatDate(from)} - {formatDate(to)}</span>
      <SvgIconsCalendar />
    </div>
  )
}

const DateRangeSelect = () => {
  const dateRange = useSel((s) => s.analytics.dateRange)
  const [isOpen, setIsOpen] = useState(false)

  return (
    <div className="date-range-picker">
      <Select
        placeholder="Date range"
        styles={customStyles}
        value={dateRange}
        components={{ MenuList: DateRange, SingleValue }}
        // closeMenuOnSelect={false}
      />
    </div>
  )
}

export default DateRangeSelect
