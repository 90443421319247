import Table from "Shared/components/DataTable/Table"
import BackdropModal from 'Shared/components/BackdropModal/BackdropModal'
import Button from 'Shared/components/Button'
import restClient from 'Shared/hooks/restClient'

export default function CampaignApproval() {
  const [action, setAction] = useState(null)
  const [campaignId, setCampaignId] = useState(null)

  const hide = () => {
    setAction(null)
    setCampaignId(null)
  }

  const handleTableClick = (e) => {
    if(e.target.classList.contains('approve-campaign-button')) {
      setCampaignId(e.target.dataset.campaignId)
      setAction('approve')
    }
    if(e.target.classList.contains('reject-campaign-button')) {
      setCampaignId(e.target.dataset.campaignId)
      setAction('reject')
    }
  }

  const tableProps = {
    table: 'campaignsAwaitingApproval',
    scope: {},
    language: { emptyTable: 'There are no campaigns yet' },
    onClick: handleTableClick,
  }

  const onSubmit = ({ feedback }) => {
    if (action === 'approve') {
      restClient.post(`/api/campaigns/${campaignId}/approve`, { feedback }).then(reloadTable)
    } else {
      restClient.post(`/api/campaigns/${campaignId}/reject`, { feedback }).then(reloadTable)
    }
  }

  const reloadTable = () => {
    window.$('table').DataTable().ajax.reload()
  }

  return <div className="section">
    <div className="box">
      <div>
        <h2 className='no-margin-top'>Campaigns awaiting approval</h2>
      </div>
      <Table {...tableProps} />
      <ConfirmationModal open={!!action} hide={hide} action={action} campaignId={campaignId} onSubmit={onSubmit} />
    </div>
  </div>
}

const ConfirmationModal = ({
  open,
  hide,
  action,
  onSubmit,
  description,
}) => {
  const [feedback, setFeedback] = useState('')
  const disabled = action === 'reject' && feedback.length <= 5

  const handleSubmit = () => {
    if (!disabled) {
      hide()
      onSubmit({ feedback })
    }
  }

  const title = action === 'approve' ? 'Approve campaign' : 'Reject campaign'

  return (
    <BackdropModal onCancel={hide} open={open} maxWidth="sm" title={title}>
      <div>
        {open && (
          <div className="confirmation-modal__container">
            <p>
              { action === 'approve' && "Optionally provide some feedback for the user." }
              { action === 'reject' && "Explain why you are rejecting the campaign, and provide information on what the user can do to fix it." }
            </p>
            <div className='form-group'>
              <label>Reason</label>
              <textarea onChange={(e) => setFeedback(e.target.value)} value={feedback} className='full-width' />
            </div>
            <div className="confirmation-modal__action right margin-top">
              <Button red={action === 'reject'} primary={action === 'approve'} disabled={disabled} onClick={handleSubmit}>
                { action === 'approve' && 'Approve' }
                { action === 'reject' && 'Reject' }
              </Button>
            </div>
          </div>
        )}
      </div>
    </BackdropModal>
  )
}

export { ConfirmationModal }
