import './HangUpReminder.scoped.scss'

export default function HangUpReminder() {
  return <>
    <div className='hang-up-reminder'>
      <p className='bold no-margin-top'>{ i18n.t('user.campaigns.phone.hang_up_reminder') }</p>
      <p className='no-margin-top no-margin-bottom'>{ i18n.t('user.campaigns.phone.hang_up_reminder_desc') }</p>
    </div>
  </>
}
