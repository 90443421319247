import store from 'Shared/store'
import { Provider } from 'react-redux'
import TargetProfile from './components/TargetProfile'

const Index = ({ targetId, widgets }) => {
  const verify = typeof window.ENV.FEATURES['target_details_updates'] !== 'undefined'
  const membershipCard =  typeof window.ENV.FEATURES['membership_cards'] !== 'undefined'
  let additionalWidgets = []
  if (verify) {
      additionalWidgets.push('verify');
  }
  if (membershipCard) {
      additionalWidgets.push('membership_card');
  }
  const allWidgets = widgets.concat(additionalWidgets)

  return (
    <Provider store={store}>
      <ErrorBoundary>
        <TargetProfile targetId={targetId} widgets={allWidgets} />
      </ErrorBoundary>
    </Provider>
  )
}

export default Index
