import waitForDeferredResult from "Shared/utils/waitForDeferredResult"

export default function createActions(dis, store, restClient, act) {
  function setOrganisationId(organisationId, isBack = false) {
    dis({ type: 'local/upsert', payload: { loading: true } })
    restClient.get(`/api/campaigns/${campaignId}/stats?organisation_id=${organisationId}&include_children=1`)
    .then(({ data: { deferredResultId } }) => {
      waitForDeferredResult(deferredResultId)
        .then(data => {
          dis({ type: 'local/upsert', payload: { campaignStats: data, loading: false, loadedOrganisationId: organisationId } })
        })
        .catch(err => {
          dis({ type: 'local/upsert', payload: { alert: {
            severity: 'error',
            message: err
          }, loading: false } })
        })
    })
    .catch(() => {
      dis({ type: 'local/upsert', payload: { alert: {
        severity: 'error',
        message: "Something went wrong and the stats couldn't be fetched."
      } } })
    })

    dis({ type: 'local/upsert', payload: { chosenOrganisationId: organisationId } })

    if (!isBack) {
      dis({ type: 'local/addHistory', payload: organisationId })
    }
  }

  function reloadStats() {
    setOrganisationId(store.getState().local.chosenOrganisationId)
  }

  function goBack() {
    dis({ type: 'local/goBack' })
    setOrganisationId(store.getState().local.chosenOrganisationId, true)
  }

  function loadMe() {
    restClient.get('/api/users/me')
    .then(({ data }) => {
      const chosenOrganisationId = data.primaryOrganisationIds[0]
      dis({ type: 'local/upsert', payload: { currentUser: data, chosenOrganisationId, defaultOrganisationId: chosenOrganisationId, chosenOrganisationName: data.organisationName } })
      setOrganisationId(chosenOrganisationId)
    })
  }

  return {
    campaignStats: {
      emailExport(mode) {
        const campaignId = store.getState().local.campaignId
        var modeSuffix = ''
        if (mode === 'all_calls') {
          modeSuffix = '?calls=y'
        } else if (mode === 'caller_stats') {
          modeSuffix = '_caller_stats'
        }
        return restClient.post(`/api/campaigns/${campaignId}/export${modeSuffix}`)
        .then(({ data: {password} }) => password)
        .catch(() => {
          dis({ type: 'local/upsert', payload: { alert: {
            severity: 'error',
            message: "Something went wrong and the campaign couldn't be exported."
          } } })
        })
      },
      setOrganisationId,
      reloadStats,
      goBack,
      loadMe,
    },
    local: {
      upsert(payload) {
        dis({ type: 'local/upsert', payload })
      },
    },
  }
}
