import './Insights.scoped.scss'

const POSITIVE = <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M17.0312 5.69688C16.8553 5.49744 16.6388 5.33773 16.3964 5.22836C16.1539 5.11898 15.891 5.06245 15.625 5.0625H11.25V3.8125C11.25 2.9837 10.9208 2.18884 10.3347 1.60279C9.74866 1.01674 8.9538 0.6875 8.125 0.6875C8.00889 0.687417 7.89505 0.719681 7.79625 0.780673C7.69744 0.841666 7.61758 0.928976 7.56562 1.03281L4.61406 6.9375H1.25C0.918479 6.9375 0.600537 7.0692 0.366116 7.30362C0.131696 7.53804 0 7.85598 0 8.1875V15.0625C0 15.394 0.131696 15.712 0.366116 15.9464C0.600537 16.1808 0.918479 16.3125 1.25 16.3125H14.6875C15.1443 16.3127 15.5854 16.146 15.9281 15.844C16.2708 15.5419 16.4913 15.1251 16.5484 14.6719L17.4859 7.17188C17.5192 6.90783 17.4958 6.63974 17.4174 6.38542C17.339 6.1311 17.2074 5.89638 17.0312 5.69688ZM1.25 8.1875H4.375V15.0625H1.25V8.1875Z" fill="#09754D"/>
</svg>

const NEGATIVE = <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M17.4859 9.82812L16.5484 2.32813C16.4913 1.87491 16.2708 1.45812 15.9281 1.15604C15.5854 0.853951 15.1443 0.687343 14.6875 0.6875H1.25C0.918479 0.6875 0.600537 0.819196 0.366116 1.05362C0.131696 1.28804 0 1.60598 0 1.9375V8.8125C0 9.14402 0.131696 9.46196 0.366116 9.69638C0.600537 9.9308 0.918479 10.0625 1.25 10.0625H4.61406L7.56563 15.9672C7.61759 16.071 7.69744 16.1583 7.79625 16.2193C7.89505 16.2803 8.00889 16.3126 8.125 16.3125C8.9538 16.3125 9.74866 15.9833 10.3347 15.3972C10.9208 14.8112 11.25 14.0163 11.25 13.1875V11.9375H15.625C15.8911 11.9376 16.1541 11.8811 16.3967 11.7716C16.6392 11.6622 16.8557 11.5025 17.0317 11.3029C17.2077 11.1034 17.3392 10.8687 17.4176 10.6144C17.4959 10.3601 17.5192 10.0921 17.4859 9.82812ZM4.375 8.8125H1.25V1.9375H4.375V8.8125Z" fill="#E40145"/>
</svg>

function Insight({ insight, kind }) {
    return <div className={`insight ${kind}`}>
        <p className='title'>
            { kind === 'positive' && POSITIVE }
            { kind === 'negative' && NEGATIVE }
            Insight
        </p>
        <p className='description'>{ insight} </p>
    </div>
}

export default function Insights({ positive, negative }) {
    return <div className='insights'>
        { positive.map((item) => <Insight insight={item} kind='positive' />) }
        { negative.map((item) => <Insight insight={item} kind='negative' />) }
    </div>
}
