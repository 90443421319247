import React, { memo } from 'react'
import { Handle, Position } from 'reactflow'
import useAct from 'Workflows/hooks/useAct'
import BaseNode from './BaseNode'

function DecisionNode({ id, data }) {
  const act = useAct()

  function onClick() {
    act.local.setSidebar({ kind: 'editNode', nodeId: parseInt(id) });
  }

  return <BaseNode id={id} kind={'decision'} showCount onClick={onClick}>
      <p>Decision node</p>

      <Handle type="target" position={Position.Top} />
      <Handle type="source" position={Position.Bottom} />
  </BaseNode>
}

export default memo(DecisionNode);
