import Rules from './Rules'
import Details from './Details'
import SearchName from './SearchName'
import ServerWaitButton from 'Shared/components/ServerWaitButton'
import validateRule from '../validateRule'
import './PageContainer.scoped.scss'

export default function PageContainer({
  linkedCampaignId,
  linkedSurveyId,
  returningUrl,
  searchId,
  searchesUrl,
  defaultFilters = null,
}) {
  const act = useAct()
  const { step, errors } = useSel((s) => s.searchBuilder)
  const { name, recalculating, status } = useSel((s) => s.search)
  const rootRule = useSel((s) => s.rules.entities['root'])
  const rules = useSel((s) => _.values(s.rules.entities))
  const availableFilters = useSel((s) => s.availableFilters, _.isEqual)
  const hasAvailableFilters = Object.keys(availableFilters).length > 0
  const showBack = step !== 'rules'
  const nextDisabled =
    (hasAvailableFilters &&
      (rules.length <= 2 ||
        !_.every(_.values(rules), (r) => validateRule(r, availableFilters, rules)))) ||
    (step === 'details' && recalculating) ||
    (!recalculating && status === 'error' && step !== 'rules')
  const newAudience = searchId === 'new'

  useEffect(() => {
    if (searchId === 'new') {
      act.search.startNew({ linkedCampaignId, linkedSurveyId, defaultRules: camelizeKeys(defaultFilters) })
    } else {
      act.search.load(searchId)
    }
    act.search.loadAvailableFilters()
  }, [])

  function back() {
    if (step === 'details') {
      act.searchBuilder.setStep('rules')
    } else if (step === 'name') {
      act.searchBuilder.setStep('details')
    }
  }

  function next() {
    act.search.save().then(() => {
      if (step === 'rules') {
        if ((errors || []).length > 0) {
          return // validation failed
        }
        act.searchBuilder.setStep('details')
      } else if (step === 'details') {
        act.searchBuilder.setStep('name')
      }
    })
  }

  if (!rootRule || !hasAvailableFilters) return <>Loading...</>

  let nextButtonTitle = 'Next'

  if (step === 'rules') {
    nextButtonTitle = 'Next: confirm your audience'
  }

  if (step === 'details') {
    nextButtonTitle = 'Next: name your audience'
  }

  return (
    <div className="search-builder">
      <div className="header">
        {showBack && <a className="back" onClick={back}></a>}
        <h1>{newAudience ? 'Create New Audience' : name}</h1>
        <div className="actions">
          <a className="link smaller" href={returningUrl || searchesUrl}>
            Cancel
          </a>

          {(step === 'rules' || step === 'details') && (
            <button onClick={next} className="button primary" disabled={nextDisabled}>
              <span>{nextButtonTitle}</span> <SvgIconsNext className="smaller" />
            </button>
          )}
          {step === 'name' && (
            <ServerWaitButton
              onClick={() => act.search.save(true, returningUrl)}
              className="button primary"
              disabled={!name || name.length === 0}
            >
              {newAudience ? 'Create audience' : 'Save'}
            </ServerWaitButton>
          )}
        </div>
      </div>

      {step === 'rules' && <Rules />}
      {step === 'details' && <Details />}
      {step === 'name' && <SearchName />}

      <div className="actions">
        {step === 'details' && (
          <button onClick={back} className="button secondary">
            <SvgIconsNext className="smaller" style={{ transform: 'scaleX(-1)' }} />{' '}
            <span>Previous: audience criteria</span>
          </button>
        )}
        {(step === 'rules' || step === 'details') && (
          <button onClick={next} className="button primary" disabled={nextDisabled}>
            <span>{nextButtonTitle}</span> <SvgIconsNext className="smaller" />
          </button>
        )}
        {step === 'name' && (
          <>
            <button onClick={back} className="button secondary">
              <SvgIconsNext className="smaller" style={{ transform: 'scaleX(-1)' }} />{' '}
              <span>Previous: confirm your audience</span>
            </button>
            <ServerWaitButton
              onClick={() => act.search.save(true, returningUrl)}
              className="button primary"
              disabled={!name || name.length === 0}
            >
              {newAudience ? 'Create audience' : 'Save'}
            </ServerWaitButton>
          </>
        )}
        <a className="link smaller margin-right" href={returningUrl || searchesUrl}>
          Cancel
        </a>
      </div>
    </div>
  )
}
