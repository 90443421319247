import DatetimeSelector from 'Shared/components/DatetimeSelector'
import './DateFilter.scoped.scss'

export default function DateFilter ({ }) {
  const act = useAct()
  const { start, end } = useSel(s => s.reporting)

  return <div className='filter'>
    <p>Start</p>
    <DatetimeSelector value={start} onChange={(d) => act.reporting.upsert({ start: d })} minDate={false} />

    <p>End</p>
    <DatetimeSelector value={end} onChange={(d) => act.reporting.upsert({ end: d })} minDate={false} />
  </div>
}
