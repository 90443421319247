import Button from './Button'

import './ButtonGroupField.scoped.scss'

// Group of horizontal buttons that behave like radio buttons
const ButtonGroupField = ({ label, options, value, onChange, ...props }) => {
  const GroupButton = ({option}) => {
    const active = value=== option.value
    return (
      <Button
        extraClass="small-icon-button"
        primary={active}
        secondary={!active}
        small
        onClick={() => onChange && onChange(option.value)}>
        {option.label}
      </Button>
    )
  }

  return (
    <div className="field button-group-field" {...props}>
      {label && <label>{label}</label>}
      <div className="button-group-control">
        {options.map((o) => <GroupButton key={o.value} option={o} />)}
      </div>
    </div>
  )
}

export default ButtonGroupField
