import * as React from 'react'

function SvgTrendUp(props) {
  return (
    <svg width={25} height={25} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle cx={12.5} cy={12.5} r={12.5} fill="#EDFCF7" />
      <path d="M13.5 8.81l5.5-.12-.121 5.5L13.5 8.81z" fill="#5DCEC1" />
      <path
        d="M6 15.69l3.293-3.294a1 1 0 011.414 0l1.586 1.586a1 1 0 001.414 0L17 10.69"
        stroke="#5DCEC1"
      />
    </svg>
  )
}

export default SvgTrendUp
