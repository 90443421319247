import * as React from 'react'

function SvgPlusWhite({ size = 20, ...props }) {
  return (
    <div style={{ width: size, height: size }}>
      <svg width="100%" height="100%" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <rect x={3.333} y={9.702} width={13.333} height={1.754} rx={0.877} fill="#fff" />
        <rect
          x={10.833}
          y={3.561}
          width={14.035}
          height={1.667}
          rx={0.833}
          transform="rotate(90 10.833 3.561)"
          fill="#fff"
        />
      </svg>
    </div>
  )
}
export default SvgPlusWhite
