import * as React from 'react'

function SvgMoney({ width = 21, height = 20, ...props }) {
  return (
    <svg width={width} height={height} viewBox={"0 0 21 20"} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        opacity={0.3}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.391 3.165a1 1 0 00-1.224.707l-2.07 7.727a1 1 0 00.706 1.225l14.972 4.011A1 1 0 0018 16.128l2.07-7.727a1 1 0 00-.707-1.225L4.391 3.165zm9.412 7.698a3.333 3.333 0 11-6.44-1.726 3.333 3.333 0 016.44 1.726z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.833 5a1 1 0 00-1 1v8a1 1 0 001 1h15.5a1 1 0 001-1V6a1 1 0 00-1-1h-15.5zm11.084 5a3.333 3.333 0 11-6.667 0 3.333 3.333 0 016.667 0zm-3.334 1.667a1.667 1.667 0 100-3.334 1.667 1.667 0 000 3.334z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgMoney
