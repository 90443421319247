export default function useData(stateFn) {
  // We first digest the state to something more ergonomic, then apply the passed stateFn to extract the relevant values
  return useSel(s => {
    const { userId, campaignId, callSessionId } = s.local

    const user = s.users.entities[userId] || {}
    const campaign = s.campaigns.entities[campaignId] || {}
    const callSession = s.callSessions.entities[callSessionId] || {}
    const call = s.calls.entities[callSession.currentCallId] || {}
    const campaignTarget = s.campaignTargets.entities[call.campaignTargetId] || {}
    const target = s.targets.entities[campaignTarget.targetId] || {}
    const targetData = s.target || {}

    const reducedState = { user, campaign, callSession, campaignTarget, call, target, targetData }

    return stateFn(reducedState)
  })
}
