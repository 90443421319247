import './Filters.scoped.scss'
import Rule from 'SearchBuilder/components/Rule'
import Button from 'Shared/components/Button'
import validateRule from 'SearchBuilder/validateRule'

const Filters = ({ onUpdate }) => {
  const act = useAct()

  const rootRule = useSel(s => s.rules.entities['root'])
  const availableFilters = useSel(s => s.availableFilters, _.isEqual)
  const hasAvailableFilters = Object.keys(availableFilters).length > 0
  const rules = useSel(s => _.values(s.rules.entities))
  const isValid = rootRule && rules.length > 1 &&
    _.every(_.values(rules), (r) => validateRule(r, availableFilters, rules))
  const [dirty, setDirty] = useState(false)

  useEffect(() => {
    act.analytics.setDefaultRules()
    act.search.loadAvailableFilters()
    setDirty(false)
  }, [])

  useEffect(() => {
    console.log('new rules', rules)
    setDirty(true)
  }, [JSON.stringify(rules)])
  
  function update() {
    setDirty(false)
    act.analytics.load()
  }
  
  if (!rootRule || !hasAvailableFilters) return null

  return (
    <div className="analytics-filters">
      <Rule id='root' showAnyAll={false} />

      { dirty && <Button primary disabled={!isValid} onClick={update}>Update stats</Button> }
    </div>
  )
}

export default Filters
