const columns = [
  {
    data: 'name',
    className: 'bold',
  },
  { data: 'email' },
  {
    data: 'id',
    className: 'right',
    render: (data) => `
      <button class="button edit-email-sender-button" data-id="${data}">Edit</button>
      <button class="button delete-email-sender-button" data-id="${data}">Delete</button>
    `,
  },
]

const headers = [
  'Name',
  'Email',
  '',
]

const filters = [
]

const sorters = [
]

const order = [[ 1, "asc" ]]

export { columns, headers, filters, sorters, order }
