import './Tip.scoped.scss'

export default ({ slug, tip, extraClass='' }) => {
  const [show, setShow] = useState(false)
  const localStorageKey = `tip-${slug}`

  useEffect(() => {
    const stored = localStorage.getItem(localStorageKey)
    if (stored === null) {
        setShow(true)
    }
  }, [])

  function close() {
    localStorage.setItem(localStorageKey, 'true')
    setShow(false)
  }

  return show ? <div className={`tip ${extraClass}`}>
    <div className='icon'>
        <SvgIconsTip />
    </div>
    <p dangerouslySetInnerHTML={{
        __html: tip
    }}></p>
    <div className='close'>
        <SvgIconsClose onClick={() => close()} />
    </div>
  </div> : null
}
