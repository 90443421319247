import { FC } from 'react'
import ListEditor from '../../ListEditor'
import './OrderedListBlock.scoped.scss'
import { BlockType } from '../Block'

const OrderedListBlock: FC<{
  block: BlockType
  active?: boolean
}> = ({ block, active }) => {
  if (active) {
    return <ListEditor block={block} tag="ol" />
  }

  return (
    <ol>
      {(block.parameters.items || []).map((item, index) => (
        <li key={index} dangerouslySetInnerHTML={{ __html: item }}></li>
      ))}
    </ol>
  )
}

export default OrderedListBlock
