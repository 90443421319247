import './ChartKey.scoped.scss'

const ChartKey = ({ label, color, enabled, onChange }) => {
  return (
    <div className="chart-key">
      <span className="color-swatch" style={{ background: color }}></span>
      <span className="label">{label}</span>
      <input type="checkbox" checked={enabled} onChange={(e) => { onChange(e.target.checked) }} />
    </div>
  )
}

export default ChartKey
