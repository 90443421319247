const columns = [
  {
    data: 'name',
    className: 'bold',
    render: (data, type, row, meta) =>
      `<a href='/admin/settings/email_templates/${row['id']}/edit'>${data}</a>`,
  },
  {
    data: 'description',
  },
  {
    data: 'id',
    className: 'right',
    render: (
      data
    ) => `<button class="button small smaller primary clone-template-button" type="button" data-template-id="${data}">Clone</button>
    <button class="button small smaller red delete-template-button" type="button" data-template-id="${data}">Delete</button>`,
  },
]

const headers = ['Name', 'Description', '']

const filters = []

const sorters = [
  { column: 0, label: 'Alphabetically (A-Z)', order: 'asc' },
  { column: 0, label: 'Alphabetically (Z-A)', order: 'desc' },
]

export { columns, headers, filters, sorters }
