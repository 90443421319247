import Step from './Step'
import Select from 'react-select'
import BuyPhoneNumberModal from './PhoneNumbers/BuyPhoneNumberModal'

function FromShortDescriptor({ phoneNumber }) {
  return (
    <div>
      <p className="no-margin-bottom">
        <span>
          {!!phoneNumber ?
            <span>Calls will come from: <span className="bold">{phoneNumber}</span></span> :
            "Calls will come from a randomly assigned phone number"
          }
        </span>
      </p>
    </div>
  )
}

const PhoneFrom = () => {
  const act = useAct()

  const fromSetting = useSel((s) => s.campaign.settings.from)
  const outboundNumberId = useSel((s) => s.campaign.outboundNumberId)
  const [selectedOption, setSelectedOption] = useState(fromSetting)
  const [phoneNumberModal, setPhoneNumberModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [purchasedPhoneNumber, setPurchasedPhoneNumber] = useState('')

  const outboundNumbers = useSel((s) => s.campaignBuilder.outboundNumbers || [])
  const phoneNumber = outboundNumbers.find((number) => number.id === outboundNumberId)?.phoneNumber

  const outboundNumberOptions = outboundNumbers.map((number) => ({
    label: number.friendlyDisplay,
    value: number.id
  }))

  function reloadPhoneNumbers() {
    setLoading(true)
    return act.campaignBuilder.loadOutboundNumbers().then(phoneNumbers => {
      setLoading(false)
      return phoneNumbers
    })
  }

  useEffect(() => {
    reloadPhoneNumbers()
  }, [])

  const useRandomNumber = () => {
    setSelectedOption('random')
    act.campaign.updateSetting('from', 'random')
    act.campaign.upsert({ outboundNumberId: null })
  }

  const handleOutboundNumberChange = ({ value }) => {
    act.campaign.updateSetting('from', 'outbound_number')
    act.campaign.upsert({ outboundNumberId: value })
  }

  const handlePurchasedPhoneNumber = (phoneNumber) => {
    reloadPhoneNumbers().then(phoneNumbers => {
      act.campaign.updateSetting('from', 'outbound_number')
      setPurchasedPhoneNumber(phoneNumber)

      // locate and update phone number
      const number = phoneNumbers.find((number) => number.phoneNumber === phoneNumber)
      act.campaign.upsert({ outboundNumberId: number.id })

      setPhoneNumberModal(false)
    })
  }

  return (
    <Step
      name="From"
      editor="from"
      complete={true}
      validationErrors={{}}
      onDiscardChanges={() => { }}
      shortDescriptor={
        <FromShortDescriptor phoneNumber={phoneNumber} />
      }
    >
      <BuyPhoneNumberModal
        open={phoneNumberModal}
        onCancel={() => setPhoneNumberModal(false)}
        afterCreate={handlePurchasedPhoneNumber}
      />

      <div className="radio-list field">
        <label
          className="radio"
          onClick={() => { }}
        >
          <div className="control">
            <input
              type="radio"
              name="sender"
              checked={selectedOption === 'random'}
              onClick={useRandomNumber}
            />
          </div>
          <div className="label">
            <p className="bold">Assign a random phone number</p>
          </div>
        </label>
        <label
          className="radio"
          onClick={() => { }}
        >
          <div className="control">
            <input
              type="radio"
              name="sender"
              checked={selectedOption === 'outbound_number'}
              onClick={() => setSelectedOption('outbound_number')}
            />
          </div>
          <div className="label">
            <p className="bold">Pick one of my phone numbers</p>
          </div>
        </label>
      </div>

      {selectedOption === 'outbound_number' && (
        <div className="field">
          <Select
            id="outbound-number-select"
            value={outboundNumberOptions.find((option) => option.value === outboundNumberId)}
            options={outboundNumberOptions}
            disabled={loading}
            onChange={handleOutboundNumberChange}
          />
          {purchasedPhoneNumber && <p className='bold'>A new phone number has been purchased and assigned: {purchasedPhoneNumber}</p>}

          <a className='button secondary margin-top' onClick={() => setPhoneNumberModal(true)}>Buy a new phone number</a>
        </div>
      )}

    </Step>
  )
}

export default PhoneFrom


