import * as React from 'react'

export default function SvgEdit({ color = '#2849d6', ...props }) {
  return (
    <svg width={20} height={22} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M16.467 5.69c.57.6.57 1.574 0 2.175l-6.842 7.202a1.425 1.425 0 01-1.047.45l-2.652-.027c-.787-.009-1.426-.672-1.446-1.5l-.064-2.704c-.01-.421.144-.828.427-1.126l6.902-7.266c.57-.6 1.496-.6 2.067 0l2.655 2.796z"
        fill={color}
      />
      <rect x={4.157} y={17.866} width={12.52} height={1.757} rx={0.879} fill={color} />
    </svg>
  )
}

