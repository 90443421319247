export default function createActions(dis, store, restClient) {
  function load() {
    return restClient.get('/api/efforts?kept=y').then(({ data }) => {
      dis({ type: 'efforts/set', payload: data })
    })
  }

  function loadCompleters(effortId) {
    return restClient
      .get(`/api/efforts/${effortId}/completers`)
      .then(({ data: { count } }) => {
        dis({ type: 'efforts/setCompletersCount', payload: { effortId, count } })
      })
  }

  return { load, loadCompleters }
}
