import { ChromePicker } from 'react-color';
import './ColorField.scoped.scss'

const ColorField = ({color, onChange, label = 'Colour'}) => {
  const [pickerVisible, setPickerVisible] = useState(false)
  const pickerRef = useRef(null)
  const swatchRef = useRef(null)

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (pickerRef.current && 
          !pickerRef.current.contains(event.target) &&
          !swatchRef.current.contains(event.target)) {
        setPickerVisible(false)
      }
    }
    if (pickerVisible) {
      document.addEventListener('mousedown', handleClickOutside)
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [pickerVisible])

  return (
    <div className="field color-field">
      <label>{label}</label>
      <div>
        <span
          ref={swatchRef}
          className="color-field-swatch"
          style={{ backgroundColor: color }}
          onClick={() => setPickerVisible(!pickerVisible)}>&nbsp;</span>
        <input
          type="text"
          className="color-field"
          value={color}
          onChange={(e) => onChange(e.target.value)} />
      </div>
      {pickerVisible &&
        <div className="color-field-picker" ref={pickerRef} style={{ display: 'inline-block' }}>
          <ChromePicker disableAlpha color={color} onChange={(col) => onChange(col.hex)} />
        </div>
      }
    </div>
  )
}

export default ColorField
