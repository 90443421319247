import './Type.scoped.scss'

const Type = () => {
  const dis = useDis()
  const enableEmail = window.ENV.FEATURES['email'] || false
  const enablePhone = window.ENV.FEATURES['phone'] || false
  const enableSms = window.ENV.FEATURES['sms'] || false
  const enableWhatsApp = window.ENV.FEATURES['whatsapp'] || false
  const widthClass = enableWhatsApp ? 'quarter' : 'third'

  function setType(type) {
    if (type === 'email' && !enableEmail) return null
    if (type === 'phone' && !enablePhone) return null
    if (type === 'sms' && !enableSms) return null
    if (type === 'whatsapp' && !enableWhatsApp) return null

    const step = (type === 'sms' || type === 'whatsapp') ? 'sub_type' : 'name'
    dis({ type: 'createCampaign/upsert', payload: { type, step, whatsApp: type === 'whatsapp' } })
  }

  return (
    <div className="row">
      <div className={`column ${widthClass}`}>
        <a
          className={`card large selectable ${enablePhone ? '' : 'disabled'}`}
          onClick={() => setType('phone')}
          >
          <div className="icon large purple">
            <SvgIconsPhone />
          </div>

          <p className="title">Phone</p>
          <p className="description">
            Time to hit the phones. Set up a calling campaign.
          </p>
        </a>
      </div>
      <div className={`column ${widthClass}`}>
        <a
          className={`card large selectable ${enableSms ? '' : 'disabled'}`}
          onClick={() => setType('sms')}
          >
          <div className="icon large purple">
            <SvgIconsSms />
          </div>

          <p className="title">Text</p>
          <p className="description">
            Let's get messaging. Set up a one way text blast, send a survey, or start
            conversations.
          </p>
        </a>
      </div>
      { enableWhatsApp && <div className={`column ${widthClass}`}>
        <a
          className={`card large selectable  ${enableWhatsApp ? '' : 'disabled'}`}
          onClick={() => setType('whatsapp')}
        >
          <div className="icon large purple">
            <SvgIconsWhatsApp />
          </div>

          <p className="title">WhatsApp</p>
          <p className="description">
            Reach your members via WhatsApp. Set up a one way campaign, send a survey, or start
            conversations.
          </p>
        </a>
      </div> }
      <div className={`column ${widthClass}`}>
        <a
          className={`card large selectable  ${enableEmail ? '' : 'disabled'}`}
          onClick={() => setType('email')}
        >
          <div className="icon large purple">
            <SvgIconsMail />
          </div>

          <p className="title">Email</p>
          <p className="description">Set up and send a campaign email.</p>
        </a>
      </div>
    </div>
  )
}

export default Type
