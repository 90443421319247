import Button from './Button'
import './ButtonWithCheckMark.scoped.scss'

function Cross() {
  return <svg width="27" height="26" viewBox="0 0 27 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13.5 0C10.9288 0 8.41543 0.762437 6.27759 2.1909C4.13975 3.61935 2.47351 5.64968 1.48957 8.02512C0.505633 10.4006 0.248189 13.0144 0.749797 15.5362C1.25141 18.0579 2.48953 20.3743 4.30762 22.1924C6.1257 24.0105 8.44208 25.2486 10.9638 25.7502C13.4856 26.2518 16.0995 25.9944 18.4749 25.0104C20.8503 24.0265 22.8807 22.3603 24.3091 20.2224C25.7376 18.0846 26.5 15.5712 26.5 13C26.4964 9.5533 25.1256 6.24882 22.6884 3.81163C20.2512 1.37445 16.9467 0.00363977 13.5 0ZM18.2075 16.2925C18.3004 16.3854 18.3741 16.4957 18.4244 16.6171C18.4747 16.7385 18.5006 16.8686 18.5006 17C18.5006 17.1314 18.4747 17.2615 18.4244 17.3829C18.3741 17.5043 18.3004 17.6146 18.2075 17.7075C18.1146 17.8004 18.0043 17.8741 17.8829 17.9244C17.7615 17.9747 17.6314 18.0006 17.5 18.0006C17.3686 18.0006 17.2385 17.9747 17.1171 17.9244C16.9957 17.8741 16.8854 17.8004 16.7925 17.7075L13.5 14.4137L10.2075 17.7075C10.1146 17.8004 10.0043 17.8741 9.8829 17.9244C9.76151 17.9747 9.6314 18.0006 9.50001 18.0006C9.36861 18.0006 9.2385 17.9747 9.11711 17.9244C8.99572 17.8741 8.88542 17.8004 8.79251 17.7075C8.6996 17.6146 8.62589 17.5043 8.57561 17.3829C8.52533 17.2615 8.49945 17.1314 8.49945 17C8.49945 16.8686 8.52533 16.7385 8.57561 16.6171C8.62589 16.4957 8.6996 16.3854 8.79251 16.2925L12.0863 13L8.79251 9.7075C8.60486 9.51986 8.49945 9.26536 8.49945 9C8.49945 8.73464 8.60486 8.48014 8.79251 8.2925C8.98015 8.10486 9.23464 7.99944 9.50001 7.99944C9.76537 7.99944 10.0199 8.10486 10.2075 8.2925L13.5 11.5863L16.7925 8.2925C16.8854 8.19959 16.9957 8.12589 17.1171 8.07561C17.2385 8.02532 17.3686 7.99944 17.5 7.99944C17.6314 7.99944 17.7615 8.02532 17.8829 8.07561C18.0043 8.12589 18.1146 8.19959 18.2075 8.2925C18.3004 8.38541 18.3741 8.49571 18.4244 8.6171C18.4747 8.7385 18.5006 8.8686 18.5006 9C18.5006 9.1314 18.4747 9.2615 18.4244 9.3829C18.3741 9.50429 18.3004 9.61459 18.2075 9.7075L14.9138 13L18.2075 16.2925Z" fill="white" />
  </svg>
}

function Tick() {
  return <svg width="27" height="26" viewBox="0 0 27 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13.5 0C10.9288 0 8.41543 0.762437 6.27759 2.1909C4.13975 3.61935 2.47351 5.64968 1.48957 8.02512C0.505633 10.4006 0.248189 13.0144 0.749797 15.5362C1.25141 18.0579 2.48953 20.3743 4.30762 22.1924C6.1257 24.0105 8.44208 25.2486 10.9638 25.7502C13.4856 26.2518 16.0995 25.9944 18.4749 25.0104C20.8503 24.0265 22.8807 22.3603 24.3091 20.2224C25.7376 18.0846 26.5 15.5712 26.5 13C26.4964 9.5533 25.1256 6.24882 22.6884 3.81163C20.2512 1.37445 16.9467 0.00363977 13.5 0ZM19.2075 10.7075L12.2075 17.7075C12.1146 17.8005 12.0043 17.8742 11.8829 17.9246C11.7615 17.9749 11.6314 18.0008 11.5 18.0008C11.3686 18.0008 11.2385 17.9749 11.1171 17.9246C10.9957 17.8742 10.8854 17.8005 10.7925 17.7075L7.79251 14.7075C7.60486 14.5199 7.49945 14.2654 7.49945 14C7.49945 13.7346 7.60486 13.4801 7.79251 13.2925C7.98015 13.1049 8.23464 12.9994 8.50001 12.9994C8.76537 12.9994 9.01987 13.1049 9.20751 13.2925L11.5 15.5863L17.7925 9.2925C17.8854 9.19959 17.9957 9.12589 18.1171 9.07561C18.2385 9.02532 18.3686 8.99944 18.5 8.99944C18.6314 8.99944 18.7615 9.02532 18.8829 9.07561C19.0043 9.12589 19.1146 9.19959 19.2075 9.2925C19.3004 9.38541 19.3741 9.49571 19.4244 9.6171C19.4747 9.7385 19.5006 9.8686 19.5006 10C19.5006 10.1314 19.4747 10.2615 19.4244 10.3829C19.3741 10.5043 19.3004 10.6146 19.2075 10.7075Z" fill="white" />
  </svg>
}

function Radio() {
  return <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" width="24" height="24" rx="12" fill="white" />
    <rect x="1.5" y="1" width="22" height="22" rx="11" stroke="#1D1D47" stroke-opacity="0.4" stroke-width="2" />
  </svg>
}

export default function ButtonWithCheckMark({ children, checked, tick = false, cross = false, extraClass, ...rest }) {
  const classNames = ['button', 'button-mobile-flex-grow']
  if (extraClass) {
    classNames.push(extraClass)
  }

  if (!checked) {
    classNames.push('unchecked')
  }

  if (tick && checked) {
    classNames.push('green')
  }
  if (cross && checked) {
    classNames.push('red')
  }

  return (
    <button
      className={classNames.join(' ')}
      {...rest}
    >
      {!checked && <Radio />}
      {checked && tick && <Tick />}
      {checked && cross && <Cross />}
      {children}
    </button>
  )
}

