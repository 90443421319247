import * as React from 'react'

function SvgBackCaret(props) {
  return (
    <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect opacity={0.099} x="29" y="29" width="29" height="29" fill="white" fillOpacity="0.1"/>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.586 11.632l3.821-3.821a1.061 1.061 0 00-1.5-1.5l-4.614 4.614a.999.999 0 000 1.413l4.614 4.615a1.061 1.061 0 001.5-1.5l-3.82-3.821z"
        fill="#20204D"
        fillOpacity={0.2}
      />
    </svg>
  )
}

export default SvgBackCaret
