import './summary-table.scoped.scss'

export default function CallsTable({ calls, transferCalls }) {
  const campaigns = _.orderBy(
    _.map(_.groupBy(calls, 'campaignId'), (rows, id) => {
      return {
        id,
        name: rows[0].name,
        desktopMinutes:
          rows.find((r) => r.callingMode === 'desktop_web')?.minutesCount || 0,
        phoneMinutes:
          (rows.find((r) => r.callingMode === 'desktop_phone')?.minutesCount || 0) +
          (rows.find((r) => r.callingMode === 'ivr')?.minutesCount || 0),
        transferMinutes:
          transferCalls.find((tc) => tc.campaignId == id)?.minutesCount || 0,
        charge:
          _.sumBy(rows, 'charge') +
          (transferCalls.find((tc) => tc.campaignId == id)?.charge || 0),
      }
    }),
    'charge',
    'desc'
  )

  if (campaigns.length === 0) {
    return <div className="empty-table"> No calls made. </div>
  }

  return (
    <table>
      <thead>
        <tr>
          <th>Campaign</th>
          <th>Desktop Minutes</th>
          <th>Phone Minutes</th>
          <th>Transfer Minutes</th>
          <th>Cost</th>
        </tr>
      </thead>
      <tbody>
        {campaigns.map((campaign) => (
          <tr>
            <td>
              <a
                href={`/admin/campaigns/${campaign.id}/overview`}
                target="_blank"
                rel="noreferrer"
              >
                {campaign.name}
              </a>
            </td>
            <td>{campaign.desktopMinutes}</td>
            <td>{campaign.phoneMinutes}</td>
            <td>{campaign.transferMinutes}</td>
            <td>${campaign.charge.toFixed(2)}</td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}
