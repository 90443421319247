import sanitizeHtml from 'sanitize-html';
import linkifyHtml from 'linkify-html';

export function addLinks(text) {
  return linkifyHtml(text, {
    defaultProtocol: 'https',
    target: {
      url: "_blank",
    },
  });
}


export default function SafeRenderHtml({ html }) {
  const cleanHtml = sanitizeHtml(addLinks(html), {
    allowedTags: ['b', 'u', 'strong', 'i', 'em', 'p', 'br', 'a', 'ol', 'li', 'ul'],  // specify the tags you want to allow
    allowedAttributes: {
      'a': ['href', 'target'],
      '*': ['text-decoration']
    }
    // optionally add more options such as transforming tags etc.
  });

  return (
    <div dangerouslySetInnerHTML={{ __html: cleanHtml }} />
  );
}
