import PropTypes from 'prop-types'
import './SearchStat.scoped.scss'

const SearchStat = ({ searchId }) => {
  const [targetCount, setTargetCount] = useState(0)
  const [isRecalculating, setIsRecalculating] = useState(true)

  const fetchData = async () => {
    try {
      const response = await fetch('/api/searches/' + searchId)
      const data = await response.json()
      setTargetCount(data.target_count)
      setIsRecalculating(data.status === 'recalculating')
    } catch (error) {
      console.error('Error fetching data:', error)
    }
  }

  useEffect(() => {
    const fetchDataAndSetupInterval = async () => {
      await fetchData()

      if (isRecalculating) {
        const interval = setInterval(fetchData, 2500)
        return () => clearInterval(interval)
      }
    }

    fetchDataAndSetupInterval()
  }, [isRecalculating])

  return (
    <div className="stat">
      <div className="icon">
        <SvgIconsAudience height="60px" width="60px"/>
      </div>
      <div className="content">
        <div className={`number ${isRecalculating ? 'no-flex-column t-loading-svg' : ''}`}>
          {isRecalculating ? (
            <SvgCircLoading />
          ) : (
            <span className="big-number">
              {(targetCount || 0).toLocaleString('en-US')}
            </span>
          )}
        </div>
        <div className="label">
          <p>Number of people</p>
        </div>
      </div>
    </div>
  )
}

SearchStat.propTypes = {
  targetCount: PropTypes.number.isRequired,
  isRecalculating: PropTypes.bool.isRequired,
}

export default SearchStat;
