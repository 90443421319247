import { useNavigate } from 'react-router-dom';

export default function useNav() {
  const navigate = useNavigate()

  return useMemo(
    () => ({
      toQuestion: ({ surveyId, questionId, initialResponse = null }) => {
        navigate(`/target/surveys/${surveyId}/questions/${questionId}`, { state: { initialResponse } })
      },
    }),
    []
  )
}
