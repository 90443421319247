import { Provider } from 'react-redux'
import store from 'Efforts/store'
import EffortsList from 'Efforts/components/EffortsList'

export default function Efforts({}) {
  return <Provider store={store}>
    <ErrorBoundary>
      <EffortsList />
    </ErrorBoundary>
  </Provider>
}
