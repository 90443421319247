import React, { memo } from 'react';
import { Handle, Position } from 'reactflow';
import useAct from 'Workflows/hooks/useAct'
import BaseNode from './BaseNode'
import { intervalToDuration, formatDuration } from 'date-fns';

function DelayNode({ id, data }) {
  const act = useAct()
  const properties = useSel(s => s.workflowNodes.entities[parseInt(id)]?.properties || {})
  const { delayInSeconds } = properties

  function getDuration(seconds) {
    const duration = intervalToDuration({ start: 0, end: seconds * 1000 });
    return formatDuration(duration);
  }

  function onClick() {
    act.local.setSidebar({ kind: 'editNode', nodeId: parseInt(id) });
  }

  return <BaseNode id={id} kind={'delay'} onClick={onClick} showCount>
    { delayInSeconds && <strong>{ getDuration(delayInSeconds) }</strong> }

    <Handle type="target" position={Position.Top} />
    <Handle type="source" position={Position.Bottom} />
  </BaseNode>
}

export default memo(DelayNode);
