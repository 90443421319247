export default function ArrowRight({ variant = 'dark' }) {
  return (
    <svg
      width="12"
      height="11"
      viewBox="0 0 12 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.59111 0.307469C5.97359 -0.0909418 6.60662 -0.103861 7.00503 0.278614L11.6925 4.77861C11.889 4.96719 12 5.22771 12 5.5C12 5.7723 11.889 6.03282 11.6925 6.22139L7.00503 10.7214C6.60662 11.1039 5.97359 11.0909 5.59111 10.6925C5.20864 10.2941 5.22156 9.66109 5.61997 9.27861L8.51436 6.5H1C0.447715 6.5 0 6.05229 0 5.5C0 4.94772 0.447715 4.5 1 4.5H8.51436L5.61997 1.72139C5.22156 1.33891 5.20864 0.705881 5.59111 0.307469Z"
        fill="#191936"
        fillOpacity={variant === 'light' ? 0.2 : 1.0}
      />
    </svg>
  )
}

