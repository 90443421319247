import { useStore } from 'react-redux'
import survey from 'Survey/hooks/survey'
import surveyTaker from 'Survey/hooks/surveyTaker'
import restClient from 'Shared/hooks/restClient'

const actionSlices = {
  survey,
  surveyTaker
}

export default () => {
  const dis = useDis()
  const store = useStore()

  return useMemo(
    () => _.mapValues(actionSlices, (fn) => {
      return fn(dis, store, restClient)
    }),
    []
  )
}
