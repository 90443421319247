
import restClient from "Shared/hooks/restClient"
import Celebration from '@material-ui/icons/Cake'
import { CircularProgress } from "@material-ui/core"
import TextField from "Shared/components/TextField"

const UpsertEmailSender = ({emailSenderId, afterCreate, senderEmail, afterUpdate}) => {
  const [loading, setLoading] = useState(false)
  const [email, setEmail] = useState('')
  const [scope, setScope] = useState('user')
  const [name, setName] = useState('')

  const { clientName, organisationName } = useSel(s => s.currentUser)
  const canShareGlobal = useSel(s => _.includes(s.currentUser.allPermissions, 'manage_client_admin'))
  const canShareLocal = useSel(s => _.includes(s.currentUser.allPermissions, 'manage_admin'))

  const addSender = () => {
    restClient.post('/api/email_senders', {
      emailSender: { email: senderEmail, name, scope }
    }).then(({data}) => afterCreate(data)).
    catch((e) => {
      console.error(e)
    })
  }

  const updateSender = () => {
    restClient.put(`/api/email_senders/${emailSenderId}`, { emailSender: { name, scope } }).
    then(afterUpdate)
  }

  const handleScopeChange = (e) => {
    setScope(e.target.checked ? e.target.value : 'user')
  }

  const loadEmailSender = () => {
    restClient.get(`/api/email_senders/${emailSenderId}`).then(({ data }) => {
      setEmail(data.email)
      setName(data.name)
      setScope(data.scope)
      setLoading(false)
    })
  }

  useEffect(() => {
    if (emailSenderId) {
      setLoading(true)
      loadEmailSender()
    }
  }, [emailSenderId])

  if(loading) {
    return <CircularProgress />
  }

  return (
    <>
      <TextField
        label="Email address"
        id="email-address-input"
        value={email || senderEmail}
        disabled />

      <div className="field">
        <TextField
          label="Sender name"
          value={name}
          id="sender-name-input"
          onChange={setName}
        />
        <br /><span><Celebration /> Email address confirmed!</span>
      </div>

      { canShareGlobal &&
        <div className="field">
          <label className='align-center no-margin-bottom'>
            <input type="checkbox" name="shareWith" value="global" checked={scope === 'global'} onClick={handleScopeChange} />
            &nbsp;
            Share with all users in {clientName}
          </label>
        </div>
      }

      { canShareLocal &&
        <div className="field">
          <label className='align-center'>
            <input type="checkbox" name="shareWith" value="organisation" checked={scope === 'organisation'} onClick={handleScopeChange} />
            &nbsp;
            Share with all users in {organisationName}
          </label>
        </div>
      }

      <div className="field">
        {emailSenderId && <button className="button primary" onClick={updateSender}>Update</button>}
        {!emailSenderId && <button className="button primary" onClick={addSender}>Add Sender</button>}
      </div>
    </>
  )
}


export default UpsertEmailSender
