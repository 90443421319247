import store from 'Shared/store'
import { Provider } from 'react-redux'
import CreateCampaignButton from './CreateCampaignButton'

const CreateCampaign = (props) => {
  return <Provider store={store}>
    <ErrorBoundary>
      <CreateCampaignButton {...props} />
    </ErrorBoundary>
  </Provider>
}

export default CreateCampaign
