export default function survey(dis, store, client) {
  async function load(id) {
    return client.get(`/api/surveys/${id}`)
      .then(({ data }) => {
        dis({ type: 'survey/upsert', payload: data })
      })
  }

  async function loadStats(id) {
    return client.get(`/api/surveys/${id}/stats`)
      .then(({ data }) => {
        dis({ type: 'survey/upsert', payload: { stats: data } })
      })
  }

  return { load, loadStats } // emailExport }
}

