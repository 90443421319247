
export default function CircularProgress({
  percent = 100,
  label = null,
  size = 45,
  strokeWidth = 5,
}) {

  const innerRadius = size / 2 - strokeWidth
  const outerRadius = size / 2

  const circumference = Math.PI * innerRadius * 2
  const offset = circumference - (percent / 100) * circumference

  const svgStyle = {
    width: size,
    height: size,
  }

  const commonCircleAttributes = {
    cx: outerRadius,
    cy: outerRadius,
    r: innerRadius,
    fill: "none",
    strokeWidth,
  }

  return (
    <svg viewBox={`0 0 ${size} ${size}`} style={svgStyle}>
      <circle
        style={
          {
            strokeDasharray: circumference,
            stroke: "#EFF1F6",
          }
        }
        {...commonCircleAttributes} />
      <circle
        style={
          {
            strokeDasharray: circumference,
            strokeDashoffset: offset,
            stroke: "#00AE6E",
            transition: "stroke-dashoffset 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
          }
        }
        transform={`rotate(-90 ${outerRadius} ${outerRadius})`}
        {...commonCircleAttributes} />
      <text x={outerRadius} y={outerRadius} fill="black" fontSize="20" fontWeight="bold"
        textAnchor="middle"
        dy="0.35em">
        {label}
      </text>
    </svg>
  )

}
