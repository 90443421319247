import store from 'Shared/store'
import CopyToClipboard from 'Shared/components/CopyToClipboard'
import { Provider } from 'react-redux'

export const ShareCampaignModal = ({
  children = null,
  title = 'Share your campaign',
  actionUrl,
  actionText = 'Back to campaigns',
  campaignUrl,
  campaignId,
  showStart,
  startText,
  slug,
  canClose = true,
}) => {
  const act = useAct()
  const { open, email, success, error } = useSel((s) => s.shareCampaign)

  return (
    <div className={classes('modal-container', { visible: open })}>
      <div className="modal">
        <div className="header">
          <h2>{title}</h2>
          {canClose && (
            <a className="close js-close-modal" onClick={() => act.shareCampaign.close()}>
              X
            </a>
          )}
        </div>

        <div className="content">
          <div className="padded center">
            {children && <>{children}</>}
            <h2 className="text-center margin-bottom">
              Want to share it with your team?
            </h2>
            <p className="bold margin-top margin-bottom double">
              <CopyToClipboard url={campaignUrl} text={'Copy'} />
              <span> the campaign link, or invite more people to help.</span>
            </p>
            <div className="divider margin-bottom"></div>
            {showStart && (
              <a
                href={`/campaigns/${slug}`}
                className="button primary block margin-bottom"
              >
                {startText}
              </a>
            )}
            {actionUrl && (
              <a href={actionUrl} className="blue-link center">
                {actionText}
              </a>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default function ShareCampaign(props) {
  return (
    <Provider store={store}>
      <ErrorBoundary>
        <ShareCampaignModal {...props} />
      </ErrorBoundary>
    </Provider>
  )
}
