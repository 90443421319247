const Tab = ({ tab }) => (
  <a href={tab.path} className={tab.current ? 'tab selected' : 'tab'}>
    {tab.text}
  </a >
)

const Tabs = ({ tabs }) => {
  return (
    <div className="tabs">
      {tabs.map((tab, i) => <Tab key={i} tab={tab} />)}
    </div>
  )
}

export default Tabs
