import _ from 'lodash'

export default function findClearParameters(fields, param) {
  let clearParams = []

  _.map(fields, ({ dependsOn = [] }, paramName) => {
    if (!dependsOn[0]) return
    if (_.isObject(dependsOn[0])) {
      var reducedDependsOn = _.map(dependsOn, d => d.slug)
    } else {
      var reducedDependsOn = dependsOn
    }

    if (_.includes(reducedDependsOn, _.snakeCase(param))) {
      clearParams = [...clearParams, paramName, ...findClearParameters(fields, paramName)]
    }
  })

  return clearParams
}
