import ServerWaitButton from 'Shared/components/ServerWaitButton'
import useAct from 'Workflows/hooks/useAct'

export default function DeleteConfirm() {
  const act = useAct()
  const nodeId = useSel(s => s.local.modal.nodeId)
  const [error, setError] = useState(null)

  function confirm() {
    return act.builder.deleteNode({ nodeId })
    .then(act.local.closeModal)
    .catch((error) => {
      const data = error.response.data
      setError(data.message)
    })
  }

  return <div>
    <div className='content'>
      <h3 className='text-center no-margin-top'>
        Are you sure?
      </h3>

      { error && <div className='margin-top warning'>
        <ul className='error bold'>
          <li>{ error }</li>
        </ul>
      </div> }

      <div className='buttons center margin-top margin-bottom double'>
        <ServerWaitButton className='button primary margin-right margin-left' onClick={confirm}>Confirm</ServerWaitButton>
        <button className='button naked' onClick={act.local.closeModal}>Cancel</button>
      </div>
    </div>
  </div>
}
