import detectCycle from 'CampaignBuilder/detectCycle'
import Select from 'react-select'
import './NextAction.scoped.scss'

const NextAction = ({questionId, answerId, action, answers, nextQuestionId, onChange}) => {
  const questionsEntities = useSel(s => s.questions)
  const questions = useSel(s => _.values(s.questions.entities).filter(q => q.active))

  const initialOptions = useMemo(() => calcOptions(), [])
  const [options, setOptions] = useState(initialOptions)

  // // Any change to questions invalidates the list of options. It will regenerate when menu is opened again
  useEffect(() => {
    calcOptions()
  }, [])

  const selectedOption = options.find(o => _.isEqual(o.value, { action, nextQuestionId }))

  function calcOptions() {
    const newOptions = [
      { value: { action: 'next_question', nextQuestionId: null }, label: 'Continue to next question' }
    ]

    questions.forEach(q => {
      if (q.id === questionId) return
      let hypotheticalAnswers
      if (answerId) {
        // Pass in answers, 'as if' this one was chosen
        hypotheticalAnswers = _.unionBy([{ answerId, questionId, nextQuestionId: q.id, action: 'go_to_question' }], answers, 'id')
      } else {
        // for next action options on a question, use a fake answer 
        hypotheticalAnswers = [{ questionId, action: 'go_to_question', nextQuestionId: q.id }]
      }
      const willCreateCycle = detectCycle(questions, hypotheticalAnswers)
      newOptions.push({ value: { action: 'go_to_question', nextQuestionId: q.id }, label: `Go to question ${q.question}`, disabled: willCreateCycle })
    })

    newOptions.push({ value: { action: 'hang_up', nextQuestionId: null }, label: 'End the questions' })

    return newOptions
  }

  return (
    <div className='select'>
      <Select
        defaultValue={selectedOption}
        value={selectedOption}
        options={options}
        isOptionDisabled={o => o.disabled}
        onChange={onChange}
        onMenuOpen={() => {
          setOptions(calcOptions())
        }}
        styles={{
          menu: (provided) => ({
            ...provided,
            zIndex: 2,
          }),
        }}
      />
    </div>
  )
}

export default NextAction
