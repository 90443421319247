import { Rules } from './Description/Rules'

export default function Description({
  searchId = null,
  center = false,
  embedded = false,
  marginBottom = true,
}) {
  const act = useAct()
  const description = useSel((s) => s.searchBuilder.description)
  const search = useSel((s) => s.search)
  const collapse = typeof window.ENV.SETTINGS.collapse_search_rules !== 'undefined'
  const [expanded, setExpanded] = useState(embedded === false || !(collapse === true))
  const { id } = search

  useEffect(() => {
    if (!searchId) return

    act.search.upsert({ id: searchId })
  }, [searchId])

  useEffect(() => {
    if (id && id !== 'new') {
      act.search.describe(id)
    }
  }, [id])

  return (
    expanded ? <Rules
      description={description}
      marginBottom={marginBottom}
      center={center}
    /> : <div className={`${center ? 'text-center full-width margin-bottom' : ''}`}>
      <a className='link dark-grey bold' onClick={() => setExpanded(true)}>See who's in this audience</a>
    </div>
  )
}
