import SharedModal from 'Shared/components/Modal/Modal'
import useAct from 'Workflows/hooks/useAct'
import EditRules from './Forms/EditRules'
import ResumeConfirm from './ResumeConfirm'
import DeleteConfirm from './DeleteConfirm'
import DeleteBranchConfirm from './DeleteBranchConfirm'

export default function Modal() {
  const act = useAct()
  const { kind, nodeId, branchId } = useSel(s => s.local.modal)

  function title() {
    switch (kind) {
      case 'entryRules': return 'Editing entry rules'
      case 'exitRules': return 'Editing exit rules'
      case 'branchRules': return 'Editing branch rules'
      case 'resumeConfirm': return 'Resume this automation'
      case 'deleteConfirm': return 'Delete this node'
      case 'deleteBranchConfirm': return 'Delete this branch'
    }
  }

  return <SharedModal open={true} title={title()} onCancel={act.local.closeModal}>
    { kind === 'entryRules' && <EditRules kind='entry' /> }
    { kind === 'exitRules' && <EditRules kind='exit' /> }
    { kind === 'branchRules' && <EditRules kind='branch' nodeId={nodeId} branchId={branchId} />}
    { kind === 'resumeConfirm' && <ResumeConfirm /> }
    { kind === 'deleteConfirm' && <DeleteConfirm /> }
    { kind === 'deleteBranchConfirm' && <DeleteBranchConfirm /> }
  </SharedModal>
}
