export default function Bold(props) {
    return (
        <svg
            width={8}
            height={10}
            viewBox="0 0 8 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M6.16668 4.86683C6.4931 4.66806 6.76857 4.39578 6.97112 4.07169C7.17368 3.7476 7.29772 3.38068 7.33335 3.00016C7.33953 2.65609 7.27787 2.31416 7.15188 1.99392C7.0259 1.67367 6.83806 1.38139 6.59909 1.13376C6.36012 0.886121 6.07471 0.68799 5.75916 0.55068C5.44361 0.41337 5.1041 0.33957 4.76002 0.333496H0.43335V9.66683H5.10002C5.42745 9.66335 5.75099 9.5954 6.05217 9.46687C6.35334 9.33834 6.62625 9.15174 6.8553 8.91773C7.08436 8.68373 7.26507 8.40689 7.38713 8.10304C7.50919 7.79919 7.5702 7.47426 7.56668 7.14683V7.06683C7.56691 6.6049 7.4353 6.15251 7.1873 5.7628C6.9393 5.37309 6.58523 5.06226 6.16668 4.86683V4.86683ZM1.76668 1.66683H4.56668C4.8508 1.65804 5.1308 1.73635 5.36913 1.89126C5.60746 2.04618 5.79271 2.27028 5.90002 2.5335C6.0086 2.88535 5.97348 3.26589 5.80234 3.59193C5.6312 3.91798 5.33795 4.16301 4.98668 4.2735C4.85027 4.31347 4.70883 4.33368 4.56668 4.3335H1.76668V1.66683ZM4.83335 8.3335H1.76668V5.66683H4.83335C5.11746 5.65804 5.39747 5.73635 5.6358 5.89126C5.87413 6.04618 6.05937 6.27028 6.16668 6.5335C6.27526 6.88535 6.24015 7.26589 6.06901 7.59193C5.89786 7.91798 5.60462 8.16301 5.25335 8.2735C5.11694 8.31347 4.9755 8.33368 4.83335 8.3335V8.3335Z"
                fill="currentColor"
            />
        </svg>
    )
}
