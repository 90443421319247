import Rule from './Rule'
import { Popover } from 'react-tiny-popover'
import classNames from 'classnames'
import FilterList from './FilterList'
import React from 'react'
import './Group.scoped.scss'
import { Select, MenuItem } from '@mui/material'
// import {  } from '@material-ui/core'

{
  /*
    <div className={`count ${id === 'root' ? 'final' : ''}`}>{ count } targets</div>
    <div onClick={() => act.search.upsertRule(id, { not: !not })}>{ not ? 'EXCLUDE' : 'INCLUDE'}</div>
    <div onClick={() => act.search.upsertRule(id, { operator: operator === 'OR' ? 'AND' : 'OR' })}>
      { operator === 'OR' && !not && <div>Targets matching <strong>any</strong> of:</div> }
      { operator === 'AND' && !not && <div>Targets matching <strong>all</strong> of:</div> }
      { operator === 'OR' && not && <div>Targets who don't match <strong>any</strong> of:</div> }
      { operator === 'AND' && not && <div>Targets who don't match <strong>all</strong> of:</div> }
    </div>
    {  ruleIds && i < ruleIds.length - 1 && <div className='boolean'>{ operator }</div> }
*/
}

function getTargetIds(ids, operator, rules) {
  const idSets = ids.map((id) => {
    const { type, ruleIds, targetIds, operator } = rules[id]

    if (type === 'filter') {
      return targetIds
    } else {
      return getTargetIds(ruleIds, operator, rules)
    }
  })

  if (operator === 'OR') {
    return _.union(...idSets)
  } else {
    return _.intersection(...idSets)
  }
}
const groupTypes = [
  { id: false, label: 'Include' },
  { id: true, label: 'Exclude' },
]
export default function Group({ id, showAnyAll = true, inset }) {
  const { operator, not, ruleIds } = useSel((s) => s.rules.entities[id])
  const act = useAct()
  const [isPopoverOpen, setIsPopoverOpen] = useState(false)

  return (
    <div
      className={classNames('group-container', {
        inset,
      })}
    >
      {showAnyAll ? (
        <div className="filter naked">
          <div className="components">
            {!!inset && (
              <div className="parameter">
                <Select
                  value={!!not}
                  onChange={(v) => act.search.upsertRule(id, { not: v.target.value })}
                >
                  <MenuItem value={false}>Include</MenuItem>
                  <MenuItem value={true}>Exclude</MenuItem>
                </Select>
              </div>
            )}
            <p className="label">
              {!inset && <>{not ? <b>Exclude</b> : <b>Include</b>} </>}everyone who
              matches
            </p>
            <div className="parameter">
              <Select
                value={operator}
                onChange={(e) => act.search.upsertRule(id, { operator: e.target.value })}
              >
                <MenuItem value={'AND'}>all</MenuItem>
                <MenuItem value={'OR'}>any</MenuItem>
              </Select>
            </div>
            <p className="label">of the criteria as selected below</p>
          </div>
        </div>
      ) : null}

      {ruleIds.map((id, i) => (
        <>
          <Rule inset={true} id={id} key={id} />
        </>
      ))}

      <div className="actions">
        <Popover
          containerStyle={{ zIndex: 1010 }}
          containerClassName="popover-container"
          isOpen={isPopoverOpen}
          positions={['bottom', 'top']}
          padding={0}
          reposition={true}
          align={'start'}
          content={() => (
            <FilterList groupId={id} close={() => setIsPopoverOpen(false)} />
          )}
          onClickOutside={() => setIsPopoverOpen(false)}
        >
          <button
            id={`buttonRef-${id}`}
            className="button secondary small"
            type="button"
            onClick={() => setIsPopoverOpen(!isPopoverOpen)}
          >
            <SvgIconsPlus />
            Add filter
          </button>
        </Popover>

        <button
          className="button secondary small"
          type="button"
          onClick={() => act.search.addGroup(id)}
        >
          <SvgIconsPlus />
          Add nested group
        </button>
      </div>
    </div>
  )
}
