const columns = [
  {
    data: 'date_made',
    width: '50%',
  },
  {
    data: 'donation_amount',
    width: '50%',
  },
]

const headers = ['Date Made', 'Donation Amount']

const filters = []

const sorters = [
  { column: 0, label: 'Date Made', order: 'desc' },
  { column: 1, label: 'Donation Amount', order: 'desc' },
]

const order = [[ 0, "desc" ]]

export { columns, headers, filters, sorters, order }
