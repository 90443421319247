import * as React from 'react'

function SvgNext(props) {
  return (
    <svg width={24} height={16} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect
        x={3.333}
        y={8.667}
        width={1.333}
        height={9.333}
        rx={0.667}
        transform="rotate(-90 3.333 8.667)"
        fill="currentColor"
      />
      <path
        d="M7.529 11.529a.667.667 0 10.943.943l4-4a.667.667 0 00.02-.922l-3.667-4a.667.667 0 10-.983.9l3.236 3.53-3.55 3.549z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgNext
