export default function CharacterCounter({ content, max, condensed = false }) {
  const length = content.length
  const remaining = max - length

  return <>
    <div className={`character-counter ${condensed ? 'condensed' : ''}`}>
      { !condensed && <label>Message</label> }
      <p className={`count${ remaining <= 0 ? ' error' : ''}`}>
        <span className='ellipsis narrower'></span> {remaining} / {max} characters
      </p>
    </div>
  </>
}
