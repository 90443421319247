/* eslint-disable react/prop-types */
import Table from 'Shared/components/DataTable/Table'
import restClient from 'Shared/hooks/restClient'
import { ComponentProps, FC } from 'react'

const ManageEmailTemplates: FC<{ clientId: string }> = ({ clientId }) => {
  const handleTableClick = (e) => {
    if (e.target.classList.contains('delete-template-button')) {
      if (confirm('Are you sure?')) {
        restClient
          .delete(`/api/email_templates/${e.target.dataset.templateId}`)
          .then(reloadTable)
      }
    } else if (e.target.classList.contains('clone-template-button')) {
      if (confirm('Are you sure?')) {
        restClient
          .post(`/api/email_templates/${e.target.dataset.templateId}/clone`)
          .then(({ data: { id } }) => {
            window.location.href = `/admin/settings/email_templates/${id}/edit`
          })
          .catch(reloadTable)
      }
    }
  }

  const createTemplate = () => {
    restClient.post('/api/email_templates').then((res) => {
      window.location.href = `/admin/settings/email_templates/${res.data.id}/edit`
    })
  }
  const tableRef = useRef(null)

  const reloadTable = () => {
    $(tableRef.current).DataTable().ajax.reload()
  }

  const tableProps: ComponentProps<typeof Table> = {
    table: 'emailTemplates',
    scope: { clientId },
    language: { emptyTable: 'There are no email templates yet' },
    onClick: handleTableClick,
  }

  return (
    <>
      <div className="header">
        <h1>Email Templates</h1>
        <div className="actions">
          <button className="button primary" type="button" onClick={createTemplate}>
            Add
          </button>
        </div>
      </div>
      <div className="section">
        <div className="box">
          <Table {...tableProps} ref={tableRef} />
        </div>
      </div>
    </>
  )
}

export default ManageEmailTemplates
