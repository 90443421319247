import Table from "Shared/components/DataTable/Table"
import restClient from 'Shared/hooks/restClient'
import PickUserModal from "./PickUserModal"
import './ManageGroup.scoped.scss'
import EditGroupForm from './EditGroupForm'

const ManageGroup = ({ groupId, hasChildOrganisations }) => {
  const [modalOpen, setModalOpen] = useState(false)

  // Can't assign click handlers to datatable cell contents and React doesn't know when it
  // finishes loading so add a click handler to the whole table and filter for the event we want
  const handleTableClick = (e) => {
    if (e.target.classList.contains('remove-user')) {
      removeUser(e.target.dataset.userId)
    }
  }

  const tableProps = {
    table: 'groupUsers',
    scope: { groupId },
    language: { emptyTable: 'There are no users yet' },
    onClick: handleTableClick,
  }

  const reloadTable = () => {
    window.$('table').DataTable().ajax.reload()
  }

  const addUser = (userId) => {
    restClient
      .put(`/api/groups/${groupId}/add_user`, { userId })
      .then(() => {
        reloadTable()
        setModalOpen(false)
      })
  }

  const removeUser = (userId) => {
    if (confirm('Are you sure?')) {
      restClient
        .put(`/api/groups/${groupId}/remove_user`, { userId })
        .then(reloadTable)
    }
  }

  return <>
    <div className="section">
      <div className="box">
        <div>
          <h2>Users in this group</h2>
          <p><button className="button secondary" type="button" onClick={() => setModalOpen(true)}>Add a user</button></p>
        </div>

        <Table {...tableProps} />

        <PickUserModal open={modalOpen} onCancel={() => setModalOpen(false)} onConfirm={addUser} />
      </div>
    </div>
    <div className="section">
      <div className="box">
        <div>
          <h2>Settings</h2>
        </div>
        <EditGroupForm groupId={groupId} hasChildOrganisations={hasChildOrganisations} />
      </div>
    </div>
  </>
}


export default ManageGroup
