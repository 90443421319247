import './Survey.scoped.scss'
import IntroGraphicSurvey from './IntroGraphicSurvey'
import Button from 'Shared/components/Button'

export default function Completed() {
  return (
    <div className="intro-container">
      <IntroGraphicSurvey />
      <h1>
        Thanks!
      </h1>
      <p>Your responses have been submitted</p>
    </div>
  )
}
