import settingsConfig from 'CampaignBuilder/settingsConfig'
import Field from './Field'
import QuestionIcon from 'CampaignBuilder/components/QuestionIcon'

import './SettingsForm.scoped.scss'

export default function SettingsForm() {
  const act = useAct()
  const { status, settings, campaignType } = useSel((s) => s.campaign)

  const config = settingsConfig[campaignType]

  const settingsMode = settings.settingsMode

  useEffect(() => {
    if(settingsMode === 'default') {
      setDefaults()
    }
  }, [settingsMode])

  function setDefaults() {
    _.each(config, (setting, key) => {
      if (setting.kind === 'time_range' || setting.kind === 'duration_range') {
        act.campaign.updateSetting(setting.keys[0], setting.default[0]);
        act.campaign.updateSetting(setting.keys[1], setting.default[1]);
      } else if (setting.kind === 'multi_checkbox') {
        setting.options.forEach((v) => {
          if (v.default) {
            act.campaign.updateSetting(v.value, 'true')
          } else {
            act.campaign.updateSetting(v.value, 'false')
          }
        })
      } else {
        act.campaign.updateSetting(key, setting.default)
      }
    })
  }

  function settingValues(key, settingConfig) {
    const kind = settingConfig.kind

    if (kind === 'time_range' || kind === 'duration_range') {
      const keys = settingConfig.keys
      return keys.map(key => settings[key])
    } else if (kind === 'multi_checkbox') {
      const keys = settingConfig.options?.map(o => o.value)
      return keys.map(key => settings[key])
    } else {
      return settings[key]
    }
  }

  function updateValue(setting, key, value) {
    act.campaign.updateSetting('settingsMode', 'custom')
    if (setting.kind === 'time_range' || setting.kind === 'duration_range') {
      act.campaign.updateSetting(setting.keys[0], value[0]);
      act.campaign.updateSetting(setting.keys[1], value[1]);
    } else if (setting.kind === 'multi_checkbox') {
      act.campaign.updateSetting(value.key, value.value);
    } else {
      act.campaign.updateSetting(key, value);
    }
  }

  const defaultConfig = _.omitBy(config, (setting, key) => setting.dependsOn && !_.every(setting.dependsOn, (value, key) => _.includes(config[key].default, value)))

  const customConfig = _.omitBy(config, (setting, key) => setting.dependsOn && !_.every(setting.dependsOn, (value, key) => _.includes(settings[key], value)))

  return (
    <div className="campaign-settings">
      <fieldset>
        {_.map(customConfig, (setting, key) => {
          if (setting.hidden) return null
          return <div className="field" key={key}>
            <label>{setting.label}</label>
            <div className="input">
              <Field
                value={settingValues(key, setting)}
                onChange={v => updateValue(setting, key, v)}
                setting={setting}
              />
            </div>
            {setting.description ?
              <div className="description">
                <QuestionIcon /><span>{setting.description}</span>
              </div> : null}
          </div>
        })}
        <p><a className="link" onClick={() => act.campaign.updateSetting('settingsMode', 'default')}>Reset to default</a></p>
      </fieldset>
    </div>
  )
}
