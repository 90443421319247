export default function createActions(dis, store, restClient) {
  function upsert(payload) {
    dis({ type: 'local/upsert', payload })
  }

  function setModal(modal) {
    upsert({ modal })
  }

  function clearModal() {
    upsert({ modal: null })
  }

  function loadMergeTags() {
    return axios.create().get(`/api/render/available_merge_tags`)
  }

  return { upsert, setModal, clearModal, loadMergeTags }
}
