import { ReactNode } from 'react'
import Step from './Step'
import AsyncSelect from 'Shared/components/AsyncSelect'

// eslint-disable-next-line react/prop-types
function UsersShortDescriptor({
  campaignScope,
  groups,
}: {
  campaignScope: ReactNode
  groups: {
    name: string
  }[]
}) {
  const groupNames = groups.map((g) => g.name).join(', ')
  return groups.length === 0 ? (
    <p className="no-margin-bottom">
      Anyone with access within <span className="bold">{campaignScope}</span> can see and
      take part in this campaign.
    </p>
  ) : (
    <p>
      Anyone within the user groups <span className="bold">{groupNames}</span>, who is within{' '}
      <span className="bold">{campaignScope}</span> or any sub-organisation, can take part in this campaign.
    </p>
  )
}

export default () => {
  const act = useAct()
  const { groups, scope } = useSel((s) => s.campaign)

  return (
    <Step
      name="Users"
      editor="users"
      complete
      shortDescriptor={<UsersShortDescriptor campaignScope={scope} groups={groups} />}
    >
      <div className="row">
        <div className="column half">
          <div className="field">
            <label>Who can take part in this campaign?</label>
            {groups.length === 0 && (
              <p>
                Users within <span className="bold">{scope}</span>.
              </p>
            )}
            {groups.length > 0 && (
              <p>
                Users in <span className="bold">{scope}</span> or any sub-organisation, who belong to one
                of the following groups:
              </p>
            )}
            {groups.map((group) => {
              return (
                <p key={group.id}>
                  {group.name}{' '}
                  <a
                    className="button smallest"
                    onClick={() => act.campaign.removeGroup(group.id)}
                  >
                    Remove
                  </a>
                </p>
              )
            })}
          </div>
        </div>
        <div className="column half">
          <div className="field">
            <label>Available groups</label>
            <AsyncSelect
              entity="group"
              loadStaticList={true}
              getOptionLabel={(v) => v.name}
              queryParameters={{
                exclude: groups.map((g) => g.id),
              }}
              onChange={(_, group) => {
                act.campaign.addGroup(group?.id)
              }}
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="column remaining">
          <div className="tip margin-top">
            <div className="icon">
              <SvgIconsTip />
            </div>
            <p>
              By default, anyone who's a user within your tier of organisation has access
              to this campaign. If you need users at different tiers to have access, you
              can add them to user groups, and grant these user groups access above.
            </p>
          </div>
        </div>
      </div>
    </Step>
  )
}
