import useAct from 'DesktopCaller/useAct'
import useData from 'DesktopCaller/useData'
import ServerWaitButton from 'Shared/components/ServerWaitButton'

export default function SmsQuestion({ id }) {
  const act = useAct()
  const script = useSel(s => s.local.currentScript)
  const currentQuestionId = useData(rs => rs.call.currentQuestionId)
  const inputStage = useData(rs => rs.callSession.inputStage)
  const current = currentQuestionId === id && inputStage === 'answers'
  const storedAnswerText = useSel(s => s.desktopCaller.answerTexts[id])

  function handleSmsSend() {
    return act.desktopCaller.answerSendSmsQuestion(id, 'send')
  }

  function handleSkip() {
    return act.desktopCaller.answerSendSmsQuestion(id, 'skip')
  }

  return <div>
    { !script.hasMobile && <div className='bold'>{ i18n.t('user.campaigns.phone.sms.no_mobile') }</div> }
    { current && <div>
      <ServerWaitButton
        className="button primary margin-top margin-right"
        onClick={handleSmsSend}
        disabled={!script.hasMobile}
        >{ i18n.t('user.campaigns.phone.sms.send_sms') }
      </ServerWaitButton>
      <ServerWaitButton
        className="button secondary margin-top"
        onClick={handleSkip}
        >{ i18n.t('shared.messages.skip') }
      </ServerWaitButton>
    </div> }

    { !current && <div className="bold">
      <p>{storedAnswerText === 'send' ? i18n.t('user.campaigns.phone.sms.sent_sms') : i18n.t('shared.messages.skipped')}</p>
    </div> }
  </div>
}
