import useNodeSize from 'Workflows/hooks/useNodeSize'

const NODE_ICONS = {
  'start': <svg width="17" height="20" viewBox="0 0 17 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path id="Vector 22" d="M8.5 1V19M8.5 19L1.5 12M8.5 19L15.5 12" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>,
  'exit': <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path id="Vector" d="M18.8856 4.44046C19.3149 3.91446 20.0722 3.85049 20.5771 4.2976C21.0821 4.74471 21.1435 5.53357 20.7143 6.05958L10.5143 18.5595C10.0784 19.0938 9.30589 19.1501 8.80278 18.6843L3.40278 13.6843C2.90744 13.2256 2.86282 12.4355 3.30312 11.9195C3.74342 11.4036 4.50191 11.3571 4.99724 11.8157L9.48026 15.9667L18.8856 4.44046Z" fill="#191936"/>
    </svg>,
  'delay': <SvgIconsCalendar />,
  'addToCampaign': <SvgIconsMail />,
  'decision': <SvgIconsAutomations />
}

const NODE_LABELS = {
  'start': 'Entry',
  'exit': 'Exit',
  'delay': 'Delay',
  'addToCampaign': 'Add to Campaign',
  'decision': 'Decision'
}

export default function BaseNode({ id, children, kind, onClick= () => {}, showCount = false }) {
  const count = useSel(s => s.workflowNodes.entities[id]?.currentTargetCount || 0)
  const isSelected = useSel(s => s.local.sidebar.kind === 'editNode' && String(s.local.sidebar.nodeId) === id.split('_')[0])
  const ref = useNodeSize(id)

  return <div className={`node base-node node-${kind} ${isSelected ? 'selected' : ''}`} onClick={onClick} ref={ref}>
    { showCount && <div className="node-count">
      <div className="count">{ count }</div>
    </div> }
    <div className='node-icon'>
      <div className='icon'>
        { NODE_ICONS[kind] }
      </div>
    </div>
    <div className='node-content'>
      <p className='title'>{ NODE_LABELS[kind] || 'Node' }</p>
      { children }
    </div>
  </div>
}
