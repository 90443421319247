import './VariantStats.scoped.scss'
import BackdropModal from 'Shared/components/BackdropModal/BackdropModal';
import ServerWaitButton from 'Shared/components/ServerWaitButton';
import SmsPreview from 'CampaignBuilder/components/Steps/SmsPreview';
import ContentVariant from 'CampaignBuilder/components/Steps/EmailContentTabs/ContentVariant'
import EmailPreview from 'Shared/components/EmailPreview'
import useEmailPreview from 'Shared/hooks/useEmailPreview'

const VariantStats = ({ sendPhase, variants, campaignType }) => {
  const [widerVariantId, setWiderVariantId] = useState(null)
  const contentVariants = useSel(s => {
    if (campaignType === 'email') {
      return s.campaign?.emailBlast?.contentVariants || []
    } else {
      return s.campaign?.textMessageBlast?.contentVariants || []
    }
  })
  const [showConfirmation, setShowConfirmation] = useState(false)
  const currentSendPhase = useSel(s => s.campaign?.currentSendPhase)
  const error = useSel((s) => s.local.error)
  const { emailBlast = {}, search = {} } = useSel(s => s.campaign)
  const { campaignId } = useSel(s => s.local)
  const { name: searchName } = search
  const currentContentVariantId = useSel(s => s.local.currentContentVariantId)
  const [previewHtml, previewSubjectLine] = emailBlast 
  ? useEmailPreview([], campaignId, emailBlast.id, widerVariantId)
  : [null, null]
  const orderedVariants = _.orderBy(variants, 'id')
  const act = useAct()
  const maxClickRate = _.maxBy(variants, variant => parseFloat(variant.clickRate)).clickRate
  const maxReplyRate = _.maxBy(variants, variant => parseFloat(variant?.replyRate || 0))?.replyRate || 0
  const maxOpenRate = _.maxBy(variants, variant => parseFloat(variant?.openRate || 0))?.openRate || 0

  function setCurrentVariant(id) {
    act.local.upsert({ currentContentVariantId: id })
  }

  if (contentVariants.length > 0 && !contentVariants.find(cv => cv.id === currentContentVariantId)) {
    const sentWider = contentVariants.find(cv => cv.sentWider)
    if (sentWider) {
      act.local.upsert({ currentContentVariantId: sentWider.id })
    } else {
      act.local.upsert({ currentContentVariantId: contentVariants[0].id })
    }
  }

  function closeModal() {
    setWiderVariantId(null)
    setShowConfirmation(false)
  }

  function confirmSendWider() {
    if (campaignType === 'email') {
      var promise = act.campaignBuilder.sendEmailBlastWider(widerVariantId)
    } else if (_.startsWith(campaignType, 'sms_')) {
      var promise = act.campaignBuilder.sendTextMessageBlastWider(widerVariantId)
    }

    return promise.then(() => {
      setShowConfirmation(true)
    })
    .catch(() => {
      act.local.upsert({ error: 'An error occurred while sending the wider version' })
      closeModal()
    })
  }

  function redirect() {
    window.location.href = '/admin/campaigns'
  }

  const isFinal = variants.length === 1

  return (
    <div className="variant-list">
      {error && <div className="error">{error}</div>}
      <table>
        <thead>
          <tr>
            <th className="variant-header"></th>
            <th className="variant-header">{campaignType === 'email' ? 'Email' : 'Text'}s sent</th>
            <th className="variant-header">{campaignType === 'email' ? 'Open' : 'Click'} rate</th>
            <th className="variant-header">{campaignType === 'email' ? 'Click' : 'Reply'} rate</th>
            {campaignType === 'email' && <th className="variant-header">Conversion rate</th> }
            <th className="variant-header">Unsubscribes</th>
            <th className="variant-header"></th>
          </tr>
        </thead>
        <tbody>
          {orderedVariants.map((variant, index) => (
            <tr key={index} className="variant-row">
              <td className="left-border-radius">
                { campaignType === 'email' && <ContentVariant id={variant.id} current={variant.id === currentContentVariantId} /> }
                { campaignType !== 'email' && <h3 className="variant-name" onClick={() => setCurrentVariant(variant.id)}>
                  { isFinal ? "Final Version" : `Version ${index + 1}` }
                </h3> }
                { variant.sentWider && !isFinal && <p className="sent-wider">Sent wider</p>}
              </td>
              <td>
                <h1 className={`stat-label`}>{variant.entityCount.toLocaleString()}</h1>
              </td>
              <td>
                { campaignType === 'email' ? (
                  <h1 className={`stat-label ${variant.openRate > 0 && variant.openRate === maxOpenRate ? 'stat-highlight' : ''}`}>{variant.openRate}%</h1>
                ) : (
                  <h1 className={`stat-label ${variant.clickRate > 0 && variant.clickRate === maxClickRate ? 'stat-highlight' : ''}`}>{variant.clickRate}%</h1>
                )}
              </td>
              <td>
                { campaignType === 'email' ? (
                  <h1 className={`stat-label ${variant.clickRate > 0 && variant.clickRate === maxClickRate ? 'stat-highlight' : ''}`}>{variant.clickRate}%</h1>
                ) : (
                  <h1 className={`stat-label ${variant.replyRate > 0 && variant.replyRate === maxReplyRate ? 'stat-highlight' : ''}`}>{variant.replyRate}%</h1>
                )}
              </td>
              { campaignType === 'email' && <td><h1 className={`stat-label ${variant.clickRate > 0 && variant.clickRate === maxClickRate ? 'stat-highlight' : ''}`}>{variant.conversionRate}%</h1></td> }
              <td className="right-border-radius">
                <h1 className={`stat-label`}>{variant.unsubscribeCount.toLocaleString()}</h1>
              </td>
              <td>
                { !isFinal && String(currentSendPhase) === String(sendPhase) && <button className='button primary' onClick={() => setWiderVariantId(variant.id)}>Send wider</button> }
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <BackdropModal open={!!widerVariantId} title={"Send wider"} onCancel={closeModal} maxWidth='md'>
        { !showConfirmation && (
        <div className='row'>
          <div className='column half'>
            <p>Send this version to the rest of the audience</p>
            <ServerWaitButton
              onClick={confirmSendWider}
              className="button primary margin-right"
            >
              Send
            </ServerWaitButton>
          </div>
          { _.startsWith(campaignType, 'sms_') && <div className='column half'>
            <SmsPreview content={contentVariants.find(cv => cv.id === widerVariantId)?.content || {}} short />
          </div> }
          { _.startsWith(campaignType, 'email') && <div className='column half overflow-y'>
            <EmailPreview
              html={previewHtml}
              subjectLine={previewSubjectLine}
              senderName={emailBlast?.emailSender?.name}
              searchName={searchName}
              iframeStyle={{ transform: 'scale(0.7)', width: '100%' }}
              fromPreview={true}
              inline={true}
            />
          </div> }
        </div>
        )}
        { showConfirmation && (
          <div>
            <h3>This version will be sent to the rest of your campaign's audience shortly.</h3>
            <button className="button primary margin-right" onClick={closeModal}>Close window</button>
            <button className="button secondary" onClick={redirect}>Back to campaigns list</button>
          </div>
        )}
      </BackdropModal>
    </div>
  );
};

export default VariantStats;
