import useAct from 'DesktopCaller/useAct'
import useData from 'DesktopCaller/useData'
import ServerWaitButton from 'Shared/components/ServerWaitButton'

const headings = _.fromPairs([
  'no_answer',
  'left_message',
  'bad_number',
  'number_not_recognised',
  'busy'
].map(outcome => [outcome, i18n.t(`user.campaigns.phone.done_headings.${outcome}`)]))

export default function Redial({ failed }) {
  const act = useAct()
  const inputStage = useData(rs => rs.callSession.inputStage)
  const lastOutcome = useData(rs => rs.callSession.lastOutcome)

  const heading = headings[lastOutcome] || 'All done'

  return <div className={`box ${inputStage === 'redial' ? 'current' : ''}`}>
    <h2 className='no-margin-top no-margin-bottom'>{ heading }</h2>
    <p>{ i18n.t('user.campaigns.phone.call_someone_else') }</p>
    <div className='buttons'>
      <ServerWaitButton
        className="button primary small margin-right"
        onClick={() => act.desktopCaller.redial()}
        >{ i18n.t('shared.messages.yes') }</ServerWaitButton>
      <ServerWaitButton
        className="button red small"
        onClick={() => act.desktopCaller.disconnect()}
        >{ i18n.t('shared.messages.no') }</ServerWaitButton>
    </div>
  </div>
}

