import BackdropModal from 'Shared/components/BackdropModal/BackdropModal'
import Button from 'Shared/components/Button'
import './ConfirmationModal.scoped.scss'

const ConfirmationModal = ({
  open,
  hide,
  onSubmit,
  confirmValue,
  title = 'Are you absolutely sure?',
  description,
}) => {
  const [input, setInput] = useState('')
  const [disabled, setDisabled] = useState(!!confirmValue)

  const handleChange = (event) => {
    const {
      target: { value },
    } = event

    setInput(value)
    setDisabled(value && value.trim() !== confirmValue)
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.stopPropagation()
      onSubmit()
    }
  }

  const handleSubmit = () => {
    if (!disabled) {
      hide()
      onSubmit()
    }
  }

  return (
    <BackdropModal onCancel={hide} open={open} maxWidth="xs" title={title}>
      <div>
        {open && (
          <div className="confirmation-modal__container">
            {description ? (
              <p>{description}</p>
            ) : (
              <p>
                Please type <b>{confirmValue}</b> to confirm.
              </p>
            )}

            {confirmValue && (
              <input
                onChange={handleChange}
                onKeyDown={handleKeyDown}
                type="text"
                value={input}
              />
            )}
            <div className="confirmation-modal__action">
              <Button red disabled={disabled} onClick={handleSubmit}>
                Confirm
              </Button>
            </div>
          </div>
        )}
      </div>
    </BackdropModal>
  )
}

export { ConfirmationModal }
