import { useHotkeys } from 'react-hotkeys-hook'

const CampaignName = ({ askAttachSearch }) => {
  const act = useAct()
  const { creating, name, description, subType, type, error, errorMessage, duplicateSearch } = useSel(s => s.createCampaign)
  const hasDescription = _.includes(['phone', 'sms_conversations'], type)
  useHotkeys('Enter', (e) => {
    e.preventDefault()
    if (name && name.length > 0 && !creating) {
      act.createCampaign.create()
    }
  })

  return <div className='form narrow'>
    <div className='icon circle large margin-bottom'>
      {type === 'phone' && <SvgIconsPhone />}
      {type === 'email' && <SvgIconsMail />}
      {type === 'sms_blast' && <SvgIconsSmsBlast />}
      {type === 'sms_survey' && <SvgIconsSurvey />}
      {type === 'sms_conversations' && <SvgIconsConversations />}
    </div>

    <p className='intro'>First, name your campaign. This will help you find it later on so be sure to use a descriptive name.</p>

    <div className='fields margin-top'>
      <div className='field'>
        <label>Campaign name</label>
        <input type='text'
          placeholder='Name your campaign'
          value={name}
          onChange={(e) => act.createCampaign.updateName(e.target.value)}
        />
      </div>

      {hasDescription &&
        <div className='field'>
          <label>Overview <span className='thin grey'>(optional)</span></label>
          <p>Write a sentence or two to help people who will take part in this campaign to understand what the campaign is about and what it hopes to achieve.</p>
          <textarea
            placeholder='Describe your campaign'
            className='margin-top'
            value={description}
            rows='3'
            onChange={(e) => act.createCampaign.updateDescription(e.target.value)}
          ></textarea>
        </div>
      }
      {!!askAttachSearch &&
        <div className='field margin-top half'>
          <label className='align-center'>
            <input type="checkbox" value="true" checked={duplicateSearch} onClick={
              (e) => {
                act.createCampaign.updateField('duplicateSearch', e.target.checked)
              }
            } />
            <p className='no-margin-top no-margin-bottom margin-left'>Attach the same audience to the duplicated campaign</p>
          </label>
        </div>
      }
    </div>

    <button
      className='button block primary no-margin-left'
      onClick={() => act.createCampaign.create()}
      disabled={!name || name.trim().length === 0 || creating}
    >Begin</button>

    {error && <div className='warning'>{errorMessage}</div>}
  </div>
}

export default CampaignName
