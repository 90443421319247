import Button from 'Shared/components/Button'
import ServerWaitButton from 'Shared/components/ServerWaitButton'
import CopyToClipboardBig from 'Shared/components/CopyToClipboardBig'

export default function VerifyLinkWidget() {
  const act = useAct()
  const [url, setUrl] = useState('')
  
  function generateUrl() {
    return act.target.generateVerifyUrl().then((url) => {
      setUrl(url)
    })
  }

  return (
    <div className="widget">
      <p className="larger bold capitalise no-margin-top">Movement Verify</p>
      <p>Generate a link to allow this person to update their details in Movement. <strong>This will invalidate any previous link they have received</strong>.</p>

      { !url && <div>
        <ServerWaitButton className='button small primary margin-right' onClick={generateUrl}>
          Generate Secure Verify Link
        </ServerWaitButton>
      </div> }

      { url && <CopyToClipboardBig text='Copy' url={url} /> }
    </div>
  )
}
