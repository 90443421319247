import './ProfilePic.scoped.scss'

function ProfilePic({ name, initial, profilePicUrl }) {
  if (name === 'undefined undefined') {
    return null
  }

  return (
    <div className='profile-pic'>
      {profilePicUrl ? <img src={profilePicUrl} /> : <>
          <p className='fixed'>{initial}</p>
        </>
      }
    </div>
  )
}

export default ProfilePic
