import store from 'Shared/store'
import { Provider } from 'react-redux'
import PageContainer from 'Searches/components/PageContainer'

export default function Search({ search, rulesToString }) {
  return (
    <Provider store={store}>
      <ErrorBoundary>
        <PageContainer
          search={search}
          rulesToString={rulesToString}
        />
      </ErrorBoundary>
    </Provider>
  )
}
