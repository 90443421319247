import Overview from './screens/Overview'
import Calling from './screens/Calling'
import Texting from './screens/Texting'
import { CircularProgress } from '@material-ui/core'


const renderScreen = (screen) => {
  switch (screen) {
    // case 'overview':
    //   return <Overview />
    case 'calling':
      return <Calling />
    // case 'texting':
    //   return <Texting />
    default:
      return null
  }
}

const ReportScreen = ({screen}) => {
  const loading = useSel((s) => s.analytics.loading)

  const act = useAct()
  useEffect(() => {
    act.analytics.update({screen})
    act.analytics.load()
  }, [])

  return (
    <>
      {loading ? <CircularProgress /> : renderScreen(screen) }
    </>
  )
}

export default ReportScreen
