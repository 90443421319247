import BackdropModal from "Shared/components/BackdropModal/BackdropModal"
import './AddSenderModal.scoped.scss'
import { useEffect } from "react"
import CheckPin  from "./CheckPin"
import CreateVerifiedEmail from "./CreateVerifiedEmail"
import UpsertEmailSender from "./UpsertEmailSender"


const AddSenderModal = ({ open, onCancel, afterCreate, afterUpdate, emailSenderId }) => {
  const act = useAct()

  const [emailState, setEmailState] = useState('new')
  const [verifiedEmailId, setVerifiedEmailId] = useState(null)
  const [email, setEmail] = useState('')

  const reset = () => {
    setEmailState(emailSenderId ? 'validated' : 'new')
  }

  useEffect(reset, [open])

  const onVerifiedEmailCreated = ({ id, status, email }) => {
    setVerifiedEmailId(id)
    setEmailState(status)
    setEmail(email)
  }

  return (
    <BackdropModal open={open} title={emailSenderId ? 'Update Sender' : 'Add Sender'} onCancel={onCancel}>
      {emailState === 'new' && <CreateVerifiedEmail onCreated={onVerifiedEmailCreated} />}
      {emailState === 'validating' && <CheckPin back={reset} email={email} verifiedEmailId={verifiedEmailId} onValidate={() => setEmailState('validated')} />}
      {emailState === 'validated' && <UpsertEmailSender senderEmail={email} emailSenderId={emailSenderId} afterCreate={afterCreate} afterUpdate={afterUpdate} />}
    </BackdropModal >
  )

}

export default AddSenderModal
