import { createTheme } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

import debug from 'debug'
export const clamp = (num, min, max) => Math.min(max, Math.max(min, num))

export const isoDateString = (date) => {
  // Not using toISOString() because it can return the wrong data due to timezone issues
  return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
}

export const startCase = (str) => str.replace(/\w+/g, _.capitalize)

// Continue making a request with requestFn until data.ready is true,
// then resolve the Promise with the data
export const pollRequest = (requestFn, time = 1000) => {
  return new Promise((resolve) => {
    const interval = window.setInterval(() => {
      requestFn().then(({ data }) => {
        if (data.ready) {
          window.clearInterval(interval)
          resolve(data)
        }
      })
    }, time)
  })
}

export const copyToClipboard = (value) => {
  const el = document.createElement('textarea')
  el.value = value
  document.body.appendChild(el)
  el.select()
  document.execCommand('copy')
  document.body.removeChild(el)
}

export const ordinal = (n) => {
  const s = ['th', 'st', 'nd', 'rd']
  const v = n % 100
  return n + (s[(v - 20) % 10] || s[v] || s[0])
}

export function getReadableDuration(duration) {
  const minutes = Math.floor(duration / 60)
  const seconds = Math.floor(duration % 60)
  return {
    minutes: String(minutes).padStart(2, '0'),
    seconds: String(seconds).padStart(2, '0'),
  }
}

export function formatDuration(duration) {
  const time = getReadableDuration(duration)
  return `${time.minutes}:${time.seconds}`
}

export const debugLog = debug('Ringround')

export const MiuTheme = createTheme({
  components: {
    MuiAccordion: {
      defaultProps: {
        square: false,
      },
      styleOverrides: {
        root: {
          '&::before': {
            display: 'none',
          },
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        content: {
          alignItems: 'center',
          flexGrow: 'initial',
          '&.Mui-expanded': {
            margin: '12px 0',
          },
        },

        root: {
          justifyContent: 'flex-start',
          minHeight: 'auto',
          '&.Mui-expanded': {
            minHeight: 'auto',
          },
        },
      },
    },
    MuiSelect: {
      defaultProps: {
        IconComponent: ExpandMoreIcon,
      },
      styleOverrides: {
        select: {
          paddingBottom: 8,
          paddingTop: 8,
        },
        icon: {
          color: '#000000',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '&.Mui-focused > .MuiOutlinedInput-notchedOutline': {
            borderColor: '#00F399',
          },
          backgroundColor: '#FFFFFF',
        },
        notchedOutline: {
          borderWidth: 2,
          borderRadius: 6,
        },
      },
    },
  },
  shape: {
    borderRadius: 8,
  },
  typography: {
    fontFamily: `Gellix, sans-serif`,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 600,
  },
  shadows: 'none',
  spacing: 15,
})
