export default function validateRule(rule, availableFilters) {
  const { id, type, parameters, ruleIds, filter } = rule

  if (id === 'root' || id === 'exclude-root') return true

  if (type === 'filter') {
    const numFields = _.keys(availableFilters[filter].fields).length
    // If has no params, always valid
    if (_.values(parameters).length === 0) return true

    // If has some params missing, is invalid
    if (_.values(parameters).length < numFields) return false

    // Otherwise check if they're all truthy
    return _.every(_.values(parameters), (v) => !!v)
  }

  // if we have an empty group which is not the exclude group, it's invalid
  if (type === 'group') {
    return ruleIds.length > 0
  }
}
