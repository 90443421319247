import CurrencyInput from 'react-currency-input-field'
import ServerWaitButton from 'Shared/components/ServerWaitButtonStandalone'
import restClient from 'Shared/hooks/restClient'
import { CircularProgress } from '@material-ui/core'
import { on } from 'process'

function getAvailableCurrencies() {
  if (window.ENV.CREDIT_CURRENCY === 'GBP') {
    return ['GBP']
  } else if (window.ENV.CREDIT_CURRENCY === 'USD') {
    if (window.ENV.DISPLAY_CURRENCY === 'USD') {
      return ['USD']
    } else if (window.ENV.DISPLAY_CURRENCY === 'GBP') {
      return ['GBP', 'USD']
    }
  }
}

function downloadPdfInNewTab(url) {
  const newWindow = window.open(url, '_blank');
  if (window.ENV.CYPRESS) return // Don't allow override in tests as it breaks the test

  if (!newWindow || newWindow.closed || typeof newWindow.closed === 'undefined') {
    console.warn('Popup blocked. Attempting download in the same tab.');
    window.location.href = url;
  }
}

export default function Invoice({ onSuccess }) {
  const [amount, setAmount] = useState(50)
  const [remoteError, setRemoteError] = useState('')
  const [displayCurrency, setDisplayCurrency] = useState(window.ENV.DISPLAY_CURRENCY)
  const currencySymbol = {
    GBP: '£',
    USD: '$',
  }[displayCurrency]

  function validate(amount) {
    if (!amount) {
      return ''
    } else if (amount > 50000) {
      return `Maximum amount is ${currencySymbol}50,000`
    } else if (amount < 10) {
      return `Minimum amount is ${currencySymbol}10`
    }

    return null
  }

  // Debounce the error message appearing, but not the button disabling
  const [dError, dSetError] = useState(50)
  const ddSetError = useMemo(() => _.debounce(dSetError, 500))
  const error = validate(amount)
  ddSetError(error)

  function onSubmit() {
    return restClient
      .post('/api/billing/issue_invoice', { amountInCurrency: amount, displayCurrency })
      .then(({ data }) => {
        const url = `/api/credit_top_ups/${data.id}/pdf`
        onSuccess()
        downloadPdfInNewTab(url)
      })
      .catch(() => {
        setRemoteError('Something went wrong. Please try again.')
      })
  }

  return (
    <div className="form">
      <div className="field">
        <p>
          Enter the amount you want to top up. Once payment is received the credit will be available to spend.
        </p>
      </div>

      <div className='field margin-top'>
        <label>Choose currency</label>
        <div className="radio-group">
          {getAvailableCurrencies().map((c) => (
            <label key={c}>
              <input
                type="radio"
                name="currency"
                value={c}
                checked={displayCurrency === c}
                onChange={() => setDisplayCurrency(c)}
              />
              {c}
            </label>
          ))}
        </div>
      </div>

      <div className="field save-changes margin-top">
        <label>Amount to top up (Max {currencySymbol}50,000)</label>
        <CurrencyInput
          name="amount_local_currency"
          placeholder="Please enter an amount"
          value={amount}
          decimalsLimit={2}
          onValueChange={(v) => setAmount(v)}
          prefix={currencySymbol}
          limit={50000}
        />
        { displayCurrency === 'USD' && <small>
          This is the amount of credit you will receive, however the invoice will be issued in GBP. Any applicable taxes, such as VAT, will be added to the invoice.
        </small> }
        { displayCurrency === 'GBP' && <small>
          The amount of credit you receive will be determined in USD by the exchange rate. Any applicable taxes, such as VAT, will be added to the invoice.
        </small> }
      </div>

      {error && dError && (
        <div>
          <p className="error">{error}</p>
        </div>
      )}

      {remoteError && (
        <div>
          <p className="error">{remoteError}</p>
        </div>
      )}

      <div className="field save-changes margin-top">
        <ServerWaitButton
          className="margin-left"
          secondary
          small
          disabled={error !== null}
          onClick={onSubmit}
        >
          Issue Invoice
        </ServerWaitButton>
      </div>

    </div>
  )
}
