import BackdropModal from 'Shared/components/BackdropModal/BackdropModal'
import EntitySelector from "Shared/components/EntitySelector/EntitySelector"

const PickUserModal = ({ open, onCancel, onConfirm }) => {
  const [userId, setUserId] = useState(null)

  return (
    <BackdropModal title="Find a user" open={open} onCancel={onCancel}>
      <EntitySelector
        entity='user'
        value={userId}
        onChange={setUserId}
        additionalColumns={['email']}
        placeholder="Search for a user by name or email"
      />
      <p><button disabled={!userId} className="button primary" type="button" onClick={() => onConfirm(userId)}>Add selected user to group</button></p>
    </BackdropModal>
  )
}

export default PickUserModal
